import React from "react";
import {DateRow, PriceRow, Download} from "components";

export const paymentVerHead = [
    {name: '', title: 'Invoiced Date', custom: false},
    {name: '', title: 'Amount', custom: false},
    {name: '', title: 'Charges', custom: false},
    {name: '', title: 'Final Amount', custom: false},
    {name: '', title: 'Paid Amount', custom: false},
    {name: '', title: 'Balance', custom: false},
]

export const paymentVerBody = [
    {rowText: (item) => <DateRow date={item?.invoicedDate}/>},
    {rowText:  (item) => <PriceRow info={item?.amount}  />},
    {rowText:  (item) => <PriceRow info={item?.charges}  />},
    {rowText:  (item) => <PriceRow info={item?.finalAmount}  />},
    {rowText:  (item) => <PriceRow info={item?.paidAmount}  />},
    {rowText:  (item) => <PriceRow info={item?.finalAmount - item?.paidAmount}  />},
]

export const ACTION_TYPE = 'GET_PAST_PAYABLE'
export const PMT_VERIFICATION = 'PMT_VERIFICATION'