import React from "react";
import {
    Button,
    List,
    ListItem,
    ListItemText,
    Box,
    Drawer,
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {Images} from "utils";

export const SideMenu = ({anchor, saidOpen, toggleDrawer}) => {
    const history = useHistory();

    const links = [
        {name: "Home", link: "#home", route: "/"},
        {name: "About us", link: "#about", route: "/"},
        {name: "Services", link: "#services", route: "/"},
        {name: "Features", link: "#features", route: "/"},
    ];

    return (
        <Drawer
            anchor={anchor}
            open={saidOpen}
            onClose={toggleDrawer(anchor, false)}
        >
            <Box className="side-container">
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={1}
                >
                    <Link to={"/"}>
                        <img src={Images.LogoSvg} alt="Logo"/>
                    </Link>
                    <Button onClick={toggleDrawer("top", true)}>
                        <img src={Images.CloseIcon} alt="Close-Icon"/>
                    </Button>
                </Box>
                <Box
                    role="presentation"
                    onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}
                >
                    <List className="list-menu">
                        {links.map((text, index) => (
                            <a
                                className="drawer-link-styles"
                                onClick={toggleDrawer(anchor, false)}
                                key={index}
                                href={text?.link}
                            >
                                <ListItem disablePadding>
                                    <Button
                                        className="side-link"
                                        // onClick={() => history.push(text?.link)}
                                    >
                                        <ListItemText primary={text?.name} className="dark-link"/>
                                    </Button>
                                </ListItem>
                            </a>
                        ))}
                        <ListItem disablePadding>
                            <Button
                                className="side-link not-hover"
                                onClick={() => history.push("/logIn")}
                            >
                                <ListItemText
                                    color="success"
                                    primary={"Sign In"}
                                    className="blue-link "
                                />
                            </Button>
                        </ListItem>
                    </List>
                    <div className="social-box">
                        <a href="https://www.instagram.com/polo_tms/" target="_blank">
                            <img src={Images.Instagram} alt="Instagram"/>
                        </a>
                        <a href="https://www.facebook.com/polotms" target="_blank">
                            <img src={Images.Facebook} alt="Facebook"/>
                        </a>
                        <a href="https://www.linkedin.com/in/polo-tms-165180258" target="_blank">
                            <img src={Images.Linkedin} alt="Twitter"/>
                        </a>
                    </div>
                </Box>
            </Box>
        </Drawer>
    );
};
