import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Colors, SavePage, userInfo } from 'utils';
import { agentActions, officeActions } from 'store';
import { PriceConvertor, UserInputsDropdown } from 'components';

export const PayrollSummary = ({
                                 payrollSummary,
                                 checkUser,
                                 checkBranchAndOfficeManager,
                                 checkUserForList,
                                 handlePast,
                               }) => {
  const {
    officesList,
    officeBranchesList,
    branchAgentsList,
    agentList,
    branchesList,
    byList,
  } = useSelector((state) => ({
    officesList: state.offices.officesList,
    officeBranchesList: state.offices.officeBranchesList,
    branchAgentsList: state.agents.branchAgentsList,
    agentList: state.agents.agentList,
    branchesList: state.branches.branchesList,
    byList: state.agents.byList,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const info = history?.location?.state;
  const [inputs, setInputs] = useState({});
  const {
    charge,
    credit,
    serviceFees,
    finalProfit,
    grossProfit,
    netProfit,
    numberLoads,
    otherFees,
    totalAmount,
    agentPercent,
  } = payrollSummary;

  const handleChange = (option, name) => {
    const anotherInfo = {
      ...info,
    };
    anotherInfo.skip = 0;
    anotherInfo.page = 1;

    if (name === 'office') {

      if (option) {
        dispatch(officeActions.getOfficeBranches(option?.id));
        dispatch(agentActions.getBy(option?.id));
        anotherInfo.office = option?.id;
      } else {
        dispatch(officeActions.removeOfficeBranches());
        delete anotherInfo.office;
        delete anotherInfo.branch;
        delete anotherInfo.agent;
      }
    }
    if (name === 'branch') {
      if (option) {
        dispatch(agentActions.getAgentByBranch(option?.id));
        anotherInfo.branch = option?.id;
      } else {
        dispatch(agentActions.removeAgentByBranch());
        delete anotherInfo.branch;
        delete anotherInfo.agent;
      }
    }

    if (name === 'agent') {
      if (option) {
        anotherInfo.agent = option?.id;
      } else {
        delete anotherInfo.agent;
      }
    }
    SavePage(history, info, { ...anotherInfo });
  };

  const handleChangeDate = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (userInfo?.managedBranches?.length > 0) {
      dispatch(officeActions.getOfficeBranches(userInfo?.office?.id));
    }
  }, []);

  useEffect(() => {
    if (info) {
      if (info?.office) {
        dispatch(officeActions.getOfficeBranches(info?.office));
      }
      if (info?.branch) {
        dispatch(agentActions.getAgentByBranch(info?.branch));
      } else {
        if(info?.office) {
          dispatch(agentActions.getBy(info?.office));
        }
      }
      // info.date ? inputs.date = new Date(info.date) : ''
    }
  }, [info]);

  useEffect(() => {
    if (inputs) {
      const anotherInfo = {
        ...info,
      };
      anotherInfo.skip = 0;
      anotherInfo.page = 1;
      inputs.date ? anotherInfo.date = inputs.date : delete anotherInfo.date;
      // inputs.date ? anotherInfo.date = new Date(inputs.date) : delete anotherInfo.date
      SavePage(history, info, { ...anotherInfo });
    }
  }, [inputs]);


  return (
    <div>
      <div className="space-between-start">
        <h1 className="payroll-title">Summary</h1>
        <div className="only-flex">
          {checkUser &&
            <div className="only-flex">
              {checkBranchAndOfficeManager &&
                <UserInputsDropdown
                  dropdownOptions={officesList?.offices}
                  onPass={(option) => handleChange(option, 'office')}
                  selected={info?.office ? info?.office : ''}
                  noText={'All Offices'}
                  firstText={'name'}
                />
              }
              <UserInputsDropdown
                dropdownOptions={info?.office ? officeBranchesList && officeBranchesList?.branches :
                  branchesList?.branches ? branchesList?.branches :
                    []
                }
                onPass={(option) => handleChange(option, 'branch')}
                selected={info?.branch ? info?.branch : ''}
                noText={'All Branches'}
                firstText={'name'}
              />
              <UserInputsDropdown
                dropdownOptions={
                  info?.office && !info?.branch ? byList :
                    info?.branch ? branchAgentsList :
                      agentList?.agents ? agentList.agents : []
                }
                onPass={(option) => handleChange(option, 'agent')}
                selected={info?.agent ? info?.agent : ''}
                noText={'All Agents'}
                firstText={'firstName'}
                secondText={'lastName'}
                type={'agent'}
                handlePast={handlePast}
              />
            </div>
          }

          {/*{info?.viewType === 'details' &&*/}
          <input
            value={moment(info?.date).format('YYYY-MM')
              // ? moment(inputs?.date).format('YYYY-MM') : moment().format('YYYY-MM')
            }
            name="date"
            className="filter-date"
            type="month"
            onChange={handleChangeDate}
          />
          {/*}*/}
        </div>
      </div>
      {checkUserForList ?
        <div className="summary-head">
          <div>Number of Loads</div>
          <div>Gross Profit</div>
          <div><ArrowDownwardIcon style={{ fontSize: '16px', color: '#D72525', marginRight: '8px' }} />Charges
          </div>
          <div><ArrowUpwardIcon style={{ fontSize: '16px', color: '#00C851', marginRight: '8px' }} />Credit</div>
          <div>Service Fees</div>
          <div>Other Fees</div>
          <div>Total Amount</div>
        </div>
        :
        <div className="summary-head">
          <div>Number of Loads</div>
          <div>Gross Profit</div>
          <div>% Profit</div>
          <div>Final Profit</div>
          <div><ArrowDownwardIcon style={{ fontSize: '16px', color: '#D72525', marginRight: '8px' }} />Charges</div>
          <div><ArrowUpwardIcon style={{ fontSize: '16px', color: '#00C851', marginRight: '8px' }} />Credit</div>
          <div>Net Profit</div>
        </div>
      }
      {checkUserForList ?
        <div className="summary-body">
          <p>{numberLoads ? numberLoads : 0}</p>
          <p><PriceConvertor price={grossProfit} /></p>
          <p><PriceConvertor price={charge ? charge : 0} /></p>
          <p><PriceConvertor price={credit ? credit : 0} /></p>
          <p><PriceConvertor price={serviceFees ? serviceFees : 0} /></p>
          <p><PriceConvertor price={otherFees ? otherFees : 0} /></p>
          <p style={{ color: totalAmount < 0 ? Colors.ThemeRed : Colors.ThemeGreen }}>
            <PriceConvertor price={totalAmount ? totalAmount : 0} />
          </p>
        </div>
        :
        <div className="summary-body">
          <p>{numberLoads ? numberLoads : 0}</p>
          <p><PriceConvertor price={grossProfit ? grossProfit : 0} /></p>
          <p>{`${agentPercent ? agentPercent : 0}%`}</p>
          <p><PriceConvertor price={finalProfit ? finalProfit : 0} /></p>
          <p><PriceConvertor price={charge ? charge : 0} /></p>
          <p><PriceConvertor price={credit ? credit : 0} /></p>
          <p style={{ color: netProfit < 0 ? Colors.ThemeRed : Colors.ThemeGreen }}>
            <PriceConvertor price={netProfit ? netProfit : 0} />
          </p>
        </div>
      }
    </div>
  );
};