import {
    GET_DEFAULT_BRACKET_SUCCESS, GET_SYSTEM_DEFAULT_BRACKET_SUCCESS,


} from "./brackets.types";

const initialState = {
    systemBrackets:[]
};

export const bracketReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Payroll Brackets */

        case GET_SYSTEM_DEFAULT_BRACKET_SUCCESS:
            return {
                ...state,
                systemBrackets: action.payload
            }

        /** End */

        default:
            return state;
    }
};
