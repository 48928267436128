import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const reportTotalStyles = makeStyles(() => ({

    totalRowWrapper: {
        width: '100%',
        padding: '16px 32px',
        height: 'auto',
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        marginTop:'24px',
    },
    title: {
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2A374E',
        marginRight: '60px',
    },
    rowWrapper: {
        padding: '10px 16px',
        width: 'auto',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(230, 236, 243, 0.5)',
        borderRadius: '8px',
        marginRight: '16px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2A374E',

    },
    rowValue: {
        fontWeight:'600',
    },
    rowBody:{
        display:'flex',
        alignItems:'center',
    }

}))