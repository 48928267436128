import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const transactionStyles = makeStyles(() => ({
    addSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '32px',
    },

    lineAndTable: {
        display: 'flex',
    },

    itemsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        margin: '24px 0',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '8px',
        padding: '24px'
    },

    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '22px',
        color: Colors.TextSecondary,
        marginBottom: '16px',
    },

    items: {
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        height: '40px',
        padding: '0 16px',
        marginRight: '32px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: Colors.TextPrimary,
            fontSize: '16px',
        },
        '& span': {
            color: Colors.TextPrimary,
            fontSize: '16px',
            fontWeight: 'bold',
            marginLeft: '6px',
        }
    },

    factoredItems: {
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        height: '40px',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            whiteSpace:'nowrap',
            color: Colors.TextPrimary,
            fontSize: '16px',
        },
        '& span': {
            color: Colors.TextPrimary,
            fontSize: '16px',
            fontWeight: 'bold',
            marginLeft: '6px',
        }
    },

    collections:{
        width:'auto',
        display:'flex',
        marginTop:'10px',
    }

}))