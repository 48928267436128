import {makeStyles} from "@material-ui/core/styles";
import {OfficeManagement} from "./branchManagement";

export const officesInfoFragments = makeStyles (() => ({
    
    lineWrapper:{
        display:'flex',
    },

    managementHead:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    officeSelectInput:{
        marginBottom:'24px',
        width:'100%',
        marginTop:'32px',
    },


    inputsWrapper:{
        display:'flex',
        justifyContent:'space-between',
        width:'100%',
        marginTop:'24px',
    },

    officeManagement:{
        width:'100%',
    },

    officeManagementHead:{
        height:'230px',
        overflow:'auto',
        marginTop:'24px',
        "@media (min-width: 1920px)": {
            marginTop:'32px'
        }
    },
    authoritesManagementHead:{
        marginTop:'34px',
        "@media (min-width: 1920px)": {
            marginTop:'40px'
        }
    },
    officeManagementHeadTitle:{
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginBottom:'8px',
        justifyContent:'space-between',
        display:'flex',
        alignItems:'center',
        padding:'16px',

        '& div':{
            display:'flex',
            alignItems:'center'
        },
        '& button':{
            border:'none',
            outline:'none',
            background:'none'
        }
    },

    management:{
        marginRight: '16px',
        width: '100%'
    },

    managementFullWidth:{
        margin:'0 24px 0 0',
        width:'100%',
        "@media (max-width: 1919px)": {
            margin:'0 16px 0 0',
        }
    }
}))