import axios from "axios";

export const authService = {
  /** Create, Edit Carrier */

  createCarrierService: ( body ) => axios.post('/carriers', body, {auth:true}),

  inviteCarrierAppService: ( id ) => axios.post(`/carriers/${id}/invite`, null,  {auth:true}),

  reInviteCarrierAppService: ( id ) => axios.post(`/carriers/${id}/reInvite`, null,  {auth:true}),

  editCarrierService: ( id, body ) => axios.patch(`/carriers/${id}`, body, {auth:true}),

  editW9Service: ( id, body ) => axios.patch(`/carriers/${id}/editW9`, body, {auth:true}),

  /** End */

  /** Get Carriers */

  getCarriersService: ( params ) => axios.get('/carriers', {auth:true, params: { ...params }} ),

  searchCarriersService: ( params ) => axios.get('/carriers/search', {auth:true, params: { ...params }} ),

  getCarrierByIdService: ( id ) => axios.get(`/carriers/${id}`, {auth:true}),

  /** End */

  /** Activate or Inactivate Carriers */

  inactivateCarrierService: ( id, status ) => axios.patch(`/carriers/${id}/setStatus`, null, {auth:true, params:{ 'status': status }}),

  /** End */

  /** Carrier Info */

  carrierFactoringService: ( id, factoringId ) => axios.post(`/carriers/${id}/factoring/${factoringId}`, null, {auth:true}),

  carrierFuelService: ( id, data ) => axios.patch(`/carriers/${id}/fuel`, data, {auth:true}),

  carrierPaymentService: ( id, data ) => axios.patch(`/carriers/${id}/pmtInfo`, data, {auth:true}),

  deleteFactoringFromPmt: ( id ) => axios.delete(`/carriers/factoring/${id}`, {auth:true}),

  /** End */

  /** Carrier Access */

  getCarrierAccessService: ( id, type ) => axios.get(`/carriers/${id}/getAccessEntities?entityType=${type}`,{auth:true}),

  assignCarrierAccessService: ( id, entityId, type ) => axios.patch(`/carriers/${id}/manageAccess/${entityId}?operationType=${type}`,{},{auth:true}),

  /** End */

  /** Carrier Payment */

  getCarrierPaymentsService: ( info ) => axios.get(`/carrier-pmts`,{auth:true, params:{ ...info }}),

  getCarrierPaymentByIdService: ( id ) => axios.get(`/carrier-pmts/${id}`,{auth:true}),

  getCarrierPaymentsCarrierService: ( id ) => axios.get(`/carrier-pmts/carriers/${id}`,{auth:true}),

  getCarrierPaymentsCarrierLoadsService: ( id ) => axios.get(`/carrier-pmts/${id}/loadPmts`,{auth:true}),

  createCarrierPaymentService: ( info ) => axios.post(`/carrier-pmts`,{...info},{auth:true}),

  voidCarrierPaymentService: ( id ) => axios.delete(`/carrier-pmts/${id}`,{auth:true}),

  /** End */

  /** Carrier Payment */

  addCarrierDocumentService: ( info ) => axios.post(`/carriers/${info?.id}/documents`,info.dock, {auth:true}),

  editCarrierDocumentService: ({ id, params }) => axios.patch(`/carriers/${id}/documents/${params?.id}`, params, {auth:true}),

  deleteCarrierDocumentService: ( info ) => axios.delete(`/carriers/${info?.id}/documents/${info?.dockId}`, {auth:true}),

  /** End */

  /** Carrier Payment Fuel Card*/

  addCarrierFuelCardService: (id, info ) => axios.post(`/carriers/${id}/fuel`, info,{auth:true}),

  editCarrierFuelCardService: (id, fuelId, info ) => axios.patch(`/carriers/${id}/fuel/${fuelId}`, info,{auth:true}),

  deleteCarrierFuelCardService: (id, fuelId ) => axios.delete(`/carriers/${id}/fuel/${fuelId}`,{auth:true}),

  /** End */

  /** Carrier Do not load*/

  doNotLoadService: (id, info ) => axios.patch(`/carriers/${id}/doNotLoad`,{ }, {auth:true, params: { shouldLoad: info }}),

  /** End */

  /** Carrier Fee */

  feeExemptService: (id, info ) => axios.patch(`/carriers/${id}/setFeeExempt`,{ }, {auth:true, params: { isExempt: info }}),

  /** End */

  /** Alerts */

  getAlertsService: (id, params ) => axios.get(`/carriers/${id}/alerts`, {auth:true, params: { ...params }}),

  createAlertService: (id, params ) => axios.post(`/carriers/${id}/alerts`, params, {auth:true}),

  editAlertService: (id, alertId, params ) => axios.patch(`/carriers/${id}/alerts/${alertId}`, params,{auth:true}),

  deleteAlertService: (id, alertId ) => axios.delete(`/carriers/${id}/alerts/${alertId}` , {auth:true}),

  /** End */

  /** Reviews */

  getReviewsService: (id) => axios.get(`/carriers/${id}/reviews`, { auth:true }),

  createReviewService: (id, params) => axios.post(`/carriers/${id}/reviews`, params, {auth:true}),

  editReviewService: (id, params) => axios.patch(`/carriers/${id}/reviews`, params,{auth:true}),

  deleteReviewService: (id, reviewId) => axios.delete(`/carriers/${id}/reviews/${reviewId}` , {auth:true}),

  /** End */
};
