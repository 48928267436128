import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const modalsStyle = makeStyles(() => ({
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButtonWithPaddings: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 16px 0',
  },

  buttons: {
    display: 'flex',
  },

  createChancel: {
    marginTop: '30px',
  },

  deleteModalWrapper: {
    padding: '8px 40px 0 40px',
  },

  deleteInfo: {
    color: Colors.ThemeRed,
    fontSize: '18px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },

  deleteModal: {
    width: '500px',
    height: 'auto',
    background: Backgrounds.whiteModal,
    borderRadius: '8px',
    padding: '8px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  contactModal: {
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    background: Backgrounds.whiteModal,
    borderRadius: '8px',
    padding: '8px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  payroll: {
    width: '500px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },

  addLoadCustomerPmt: {
    width: '550px',
    background: 'white',
    borderRadius: '8px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '32px',
  },

  filterTypeText: {
    fontSize: '16px !important',
    fontWeight: 'bold !important',
    color: Colors.TextSecondary,
    padding: '8px 16px',
  },

  radioButton: {
    '& .MuiSvgIcon-root': {
      color: 'red',
    },
  },

  leftModalWrapper: {
    '& .MuiDialog-container': {
      position: 'absolute',
      right: 0,
    },
  },

  paymentInfo: {
    height: '230px',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '0 0 8px 8px',
    width: '100%',
    overflow: 'auto',
    padding: '16px',
  },

  editPayment: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  rows: {
    height: '48px',
    background: '#E6ECF380 0% 0% no-repeat padding-box',
    borderRadius: '4px 4px 0px 0px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    '& p': {
      fontSize: '14px',
      color: Colors.TextPrimary,
    },
    '& span': {
      fontSize: '14px',
      color: Colors.TextSecondary,
      fontWeight: 'bold',
    },
  },

  editSave: {
    display: 'flex',
    width: '100%',
  },

  inputTextFieldBlue: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },

    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-root': {
      height: '120px',
      minHeight: '120px',
    },
  },

  inputTextField: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiOutlinedInput-root': {
      height: '120px',
      minHeight: '120px',
    },
  },

  inputCustomeField: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },

    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },

    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },

  available: {
    color: Colors.TextPrimary,
    fontSize: '16px',
    lineHeight: '24px',
  },

  amountInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '& .MuiOutlinedInput-root': {
      color: 'rgba(0, 0, 0, 0.38)',
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
  },

  maxCharacters: {
    color: '#9C9EA9',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  loadCsvModal: {
    width: '400px',

    '& .MuiAutocomplete-tag': {
      display: 'flex !important',
    },

  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',

    '& p': {
      fontSize: '16px',
      fontWeight: '600',
      color: Colors.TextSecondary,
      marginBottom: '4px',
    },
  },
  exportCsvDate: {
    width: '100%',
    height: '48px',
    background: '#FFFFFF',
    border: '1px solid #9C9EA9',
    borderRadius: '4px',
    padding: '0 8px',
  },
  customClassWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '6px',
  },
  exportButton: {
    width: '100%',
    height: '48px',
    background: '#00C851',
    boxShadow: '0px 0px 6px rgba(0, 200, 81, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    fontWeight: '600',
  },
  addMoreBox: {
    borderRadius: '4px',
    border: '1px dashed #9C9EA9',
    background: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
    width: '100%',
    height: '36px',
    margin: '8px 0 24px 0'
  },
  addMoreLoad: {

    '& div': {
      width: '24px',
      height: '24px',
      borderRadius: '32px',
      background: '#438AFE',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background:'transparent',
    border: 'none',
    color: '#51566D',
    fontSize: '14px',
  },
  loadsListWrapper: {
    padding: '4px 0'
  },
}));
