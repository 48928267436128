import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './carrier.service';
import {
  ADD_ALERT,
  ADD_CARRIER_DOCUMENT,
  ADD_CARRIER_FACTORING,
  ADD_CARRIER_FACTORING_SUCCESS,
  ADD_CARRIER_FUEL_CARD,
  ADD_REVIEW,
  ASSIGN_CARRIER_ACCESS,
  CARRIER_FUEL,
  CARRIER_PAYMENT,
  CARRIER_PAYMENT_TERM,
  CREATE_CARRIER,
  DELETE_ALERT,
  DELETE_CARRIER_DOCUMENT,
  DELETE_CARRIER_FUEL_CARD,
  DELETE_REVIEW,
  DO_NOT_LOAD,
  EDIT_ALERT,
  EDIT_CARRIER, EDIT_CARRIER_DOCUMENT,
  EDIT_CARRIER_FUEL_CARD,
  EDIT_REVIEW, EDIT_W9,
  FEE_EXEMPT,
  GET_ALERTS,
  GET_ALERTS_SUCCESS,
  GET_CARRIER_AGENT_ACCESS,
  GET_CARRIER_AGENT_ACCESS_SUCCESS,
  GET_CARRIER_BRANCH_ACCESS,
  GET_CARRIER_BRANCH_ACCESS_SUCCESS,
  GET_CARRIER_BY_ID,
  GET_CARRIER_BY_ID_SUCCESS,
  GET_CARRIER_OFFICE_ACCESS,
  GET_CARRIER_OFFICE_ACCESS_SUCCESS,
  GET_CARRIER_PAYMENT_BY_ID,
  GET_CARRIER_PAYMENT_BY_ID_SUCCESS,
  GET_CARRIER_PAYMENT_CARRIER,
  GET_CARRIER_PAYMENT_CARRIER_SUCCESS,
  GET_CARRIER_PAYMENTS,
  GET_CARRIER_PAYMENTS_SUCCESS,
  GET_CARRIERS,
  GET_CARRIERS_SUCCESS,
  GET_PAYMENTS_CARRIER,
  GET_PAYMENTS_CARRIER_SUCCESS,
  GET_REVIEWS, GET_REVIEWS_SUCCESS,
  INACTIVATE_ACTIVATE_CARRIER, INVITE_CARRIER_TO_APP, RE_INVITE_CARRIER_TO_APP,
  SEARCH_CARRIERS,
} from './carrier.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

/** Create, Edit Carrier */

function* createCarrier({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.createCarrierService, payload.body);
    window.location.replace('/carriers');
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}

function* inviteCarrierApp({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.inviteCarrierAppService, payload.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* reInviteCarrierApp({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.reInviteCarrierAppService, payload.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editCarrier({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editCarrierService, payload.id, payload.body);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    if (err?.data?.message === 'A Carrier with this Authority Number already exists.') {
      yield put(httpRequestsOnErrorsActions.appendError('CARRIER_AUTHORITY_EXIST', err.data.message));
    } else {
      yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }

  }
}

function* editW9({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editW9Service, payload.id, payload.body);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

/** End */

/** Get Carriers */

function* getCarrier({ type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getCarriersService, payload?.params);
    yield put({
      type: GET_CARRIERS_SUCCESS,
      payload: res.data,
    });
    if (payload?.params?.searchKey) {
      yield put(httpRequestsOnLoadActions.removeLoading(payload?.params?.searchKey));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* searchCarrier({ type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.searchCarriersService, payload.params);
    yield put({
      type: GET_CARRIERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCarrierById({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  if (!payload.load) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getCarrierByIdService, payload.id);
    yield put({
      type: GET_CARRIER_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Activate or Inactivate Carrier */

function* inactivateCarrier(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.inactivateCarrierService, action.payload.id, action.payload.status);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    // yield call( authService.inactivateCarrierService, action.payload.id, action.payload.status );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

/** Carrier info */

function* carrierFactoring(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    yield call(authService.carrierFactoringService, action.payload.id, action.payload.factoringId);
    yield put({
      type: ADD_CARRIER_FACTORING_SUCCESS,
      payload: action.payload.id,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* getCarrierFactoring({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getCarrierByIdService, payload);
    yield put({
      type: GET_CARRIER_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* carrierFuel({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.carrierFuelService, payload.id, payload.data);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}

function* carrierPayment({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  try {
    yield call(authService.carrierPaymentService, payload.id, payload.data);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
  }
}

function* carrierPaymentTerm({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  try {
    yield call(authService.carrierPaymentService, payload.id, payload.data);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Carrier Access */

function* getCarrierOfficeAccess({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getCarrierAccessService, payload.id, payload.type);
    yield put({
      type: GET_CARRIER_OFFICE_ACCESS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* getCarrierAgentAccess({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getCarrierAccessService, payload.id, payload.type);
    yield put({
      type: GET_CARRIER_AGENT_ACCESS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* getCarrierBranchAccess({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getCarrierAccessService, payload.id, payload.type);
    yield put({
      type: GET_CARRIER_BRANCH_ACCESS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* assignCarrierAccess({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.assignCarrierAccessService, payload.id, payload.entityId, payload.type);

    yield put(httpRequestsOnLoadActions.removeLoading(type));
    if (payload.type === 'ASSIGN_OFFICE') {
      yield put({
        type: GET_CARRIER_OFFICE_ACCESS,
        payload: { id: payload.id, type: 'ASSIGNED_OFFICES' },
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('ASSIGN_OFFICE'));
    }
    if (payload.type === 'ASSIGN_AGENT') {
      yield put({
        type: GET_CARRIER_AGENT_ACCESS,
        payload: { id: payload.id, type: 'ASSIGNED_AGENTS' },
        successType: 'ASSIGN_AGENT',
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('ASSIGN_AGENT'));
    }
    if (payload.type === 'BLACKLIST_BRANCH') {
      yield put({
        type: GET_CARRIER_BRANCH_ACCESS,
        payload: { id: payload.id, type: 'BLACKLISTED_BRANHCES' },
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('BLACKLIST_BRANCH'));
    }
    if (payload.type === 'UNASSIGN_OFFICE') {
      yield put({
        type: GET_CARRIER_OFFICE_ACCESS,
        payload: { id: payload.id, type: 'ASSIGNED_OFFICES' },
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('UNASSIGN_OFFICE'));
    }
    if (payload.type === 'UNASSIGN_AGENT') {
      yield put({
        type: GET_CARRIER_AGENT_ACCESS,
        payload: { id: payload.id, type: 'ASSIGNED_AGENTS' },
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('UNASSIGN_AGENT'));
    }
    if (payload.type === 'UNBLACKLIST_BRANCH') {
      yield put({
        type: GET_CARRIER_BRANCH_ACCESS,
        payload: { id: payload.id, type: 'BLACKLISTED_BRANHCES' },
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess('UNBLACKLIST_BRANCH'));
    }
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Carrier Payment */

function* getCarrierPayments({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierPaymentsService, payload.params);
    yield put({
      type: GET_CARRIER_PAYMENTS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getCarrierPaymentById({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierPaymentByIdService, payload);
    yield put({
      type: GET_CARRIER_PAYMENT_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getPaymentsCarrier({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierPaymentsCarrierService, payload);
    yield put({
      type: GET_PAYMENTS_CARRIER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getCarrierPaymentCarrier({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCarrierPaymentsCarrierLoadsService, payload?.id);
    yield put({
      type: GET_CARRIER_PAYMENT_CARRIER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */


/** Carrier Document */

function* addCarrierDocument({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.addCarrierDocumentService, payload);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editCarrierDocument({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.editCarrierDocumentService, payload);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteCarrierDocument({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.deleteCarrierDocumentService, payload);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** Carrier Payment Fuel Card*/

function* addCarrierFuelCard({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.addCarrierFuelCardService, payload?.id, payload?.info);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editCarrierFuelCard({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.editCarrierFuelCardService, payload?.id, payload?.fuelId, payload?.info);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteCarrierFuelCard({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.deleteCarrierFuelCardService, payload?.id, payload?.fuelId);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** Carrier Do not load*/

function* doNotLoad({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.doNotLoadService, payload?.id, payload?.info);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** Carrier Fee */

function* feeExempt({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.feeExemptService, payload?.id, payload?.info);
    yield put({
      type: GET_CARRIER_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */


/** Alerts */

function* getAlerts({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getAlertsService, payload?.carrierId, payload?.params);
    yield put({
      type: GET_ALERTS_SUCCESS,
      payload: res?.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* createAlert({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.createAlertService, payload?.carrierId, payload?.params);
    yield put({
      type: GET_ALERTS,
      payload: { carrierId: payload?.carrierId, params: payload?.requestParams, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editAlert({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.editAlertService, payload?.carrierId, payload?.alertId, payload?.params);
    yield put({
      type: GET_ALERTS,
      payload: { carrierId: payload?.carrierId, params: payload?.requestParams, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteAlert({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.deleteAlertService, payload?.carrierId, payload?.alertId);
    yield put({
      type: GET_ALERTS,
      payload: { carrierId: payload?.carrierId, params: payload?.requestParams, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** Reviews */

function* getReviews({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getReviewsService, payload?.carrierId);
    yield put({
      type: GET_REVIEWS_SUCCESS,
      payload: res?.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* createReview({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.createReviewService, payload?.carrierId, payload?.params);
    yield put({
      type: GET_REVIEWS,
      payload: { carrierId: payload?.carrierId, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editReview({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.editReviewService, payload?.carrierId, payload?.params);
    yield put({
      type: GET_REVIEWS,
      payload: { carrierId: payload?.carrierId, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteReview({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));

  try {
    yield call(authService.deleteReviewService, payload?.carrierId, payload?.reviewId);
    yield put({
      type: GET_REVIEWS,
      payload: { carrierId: payload?.carrierId, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

export const watchCarrier = function* watchCarriergSaga() {
  /** Create, Edit Carrier */
  yield takeLatest(CREATE_CARRIER, createCarrier);
  yield takeLatest(INVITE_CARRIER_TO_APP, inviteCarrierApp);
  yield takeLatest(RE_INVITE_CARRIER_TO_APP, reInviteCarrierApp);
  yield takeLatest(EDIT_CARRIER, editCarrier);
  yield takeLatest(EDIT_W9, editW9);
  /** End */

  /** Get Carriers */
  yield takeLatest(GET_CARRIERS, getCarrier);
  yield takeLatest(SEARCH_CARRIERS, searchCarrier);
  yield takeLatest(GET_CARRIER_BY_ID, getCarrierById);
  /** End */

  /** Activate or Inactivate Carrier */
  yield takeLatest(INACTIVATE_ACTIVATE_CARRIER, inactivateCarrier);
  /** End */

  /** Carrier Info */
  yield takeLatest(ADD_CARRIER_FACTORING, carrierFactoring);
  yield takeLatest(ADD_CARRIER_FACTORING_SUCCESS, getCarrierFactoring);
  yield takeLatest(CARRIER_FUEL, carrierFuel);
  yield takeLatest(CARRIER_PAYMENT, carrierPayment);
  yield takeLatest(CARRIER_PAYMENT_TERM, carrierPaymentTerm);
  /** End */

  /** Carrier Access */
  yield takeLatest(ASSIGN_CARRIER_ACCESS, assignCarrierAccess);
  yield takeLatest(GET_CARRIER_OFFICE_ACCESS, getCarrierOfficeAccess);
  yield takeLatest(GET_CARRIER_AGENT_ACCESS, getCarrierAgentAccess);
  yield takeLatest(GET_CARRIER_BRANCH_ACCESS, getCarrierBranchAccess);
  /** End */

  /** Carrier Payment */
  yield takeLatest(GET_CARRIER_PAYMENTS, getCarrierPayments);
  yield takeLatest(GET_CARRIER_PAYMENT_BY_ID, getCarrierPaymentById);
  yield takeLatest(GET_PAYMENTS_CARRIER, getPaymentsCarrier);
  yield takeLatest(GET_CARRIER_PAYMENT_CARRIER, getCarrierPaymentCarrier);
  /** End */

  /** Carrier Document */
  yield takeLatest(ADD_CARRIER_DOCUMENT, addCarrierDocument);
  yield takeLatest(EDIT_CARRIER_DOCUMENT, editCarrierDocument);
  yield takeLatest(DELETE_CARRIER_DOCUMENT, deleteCarrierDocument);
  /** End */

  /** Carrier Payment Fuel Card*/
  yield takeLatest(ADD_CARRIER_FUEL_CARD, addCarrierFuelCard);
  yield takeLatest(EDIT_CARRIER_FUEL_CARD, editCarrierFuelCard);
  yield takeLatest(DELETE_CARRIER_FUEL_CARD, deleteCarrierFuelCard);
  /** End */

  /** Carrier Do not load*/
  yield takeLatest(DO_NOT_LOAD, doNotLoad);
  /** End */

  /** Carrier Fee */
  yield takeLatest(FEE_EXEMPT, feeExempt);
  /** End */

  /** Alerts */
  yield takeLatest(GET_ALERTS, getAlerts);
  yield takeLatest(ADD_ALERT, createAlert);
  yield takeLatest(EDIT_ALERT, editAlert);
  yield takeLatest(DELETE_ALERT, deleteAlert);
  /** End */

  /** Review */
  yield takeLatest(GET_REVIEWS, getReviews);
  yield takeLatest(ADD_REVIEW, createReview);
  yield takeLatest(EDIT_REVIEW, editReview);
  yield takeLatest(DELETE_REVIEW, deleteReview);
  /** End */
};
