import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors, Shadow} from "utils";

export const wrapperStyle = makeStyles(() => ({
    buttonsTabStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // marginBottom:'26px',

        "& .MuiButton-text": {
            marginRight: '0 !important',
        }
    },

    addButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },

    createOfficesBody: {
        padding: "24px 16px",
        "@media (min-width: 1920px)": {
            padding: "32px 24px",
        },
    },

    createLoadsBody: {
        padding: "24px 0",
        "@media (min-width: 1920px)": {
            padding: "32px 0",
        },
    },

    createOfficeTableHead: {
        display: "flex",
        alignItems: "center",

        "& p": {
            marginLeft: "4px",
            "@media (min-width: 1920px)": {
                marginLeft: "8px",
            },
        },
    },

    managementWrapper: {
        padding: '16px',
        boxShadow: Shadow.modalShadow,
        width: '100%',
        marginTop: '16px',
        height: '420px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        "@media (min-width: 1920px)": {
            padding: '24px',
            marginTop: '24px',
            height: '444px'
        },
    },
    smallManagementWrapper: {
        padding: '16px',
        boxShadow: Shadow.modalShadow,
        width: '100%',
        marginTop: '16px',
        height: '308px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',

        "@media (min-width: 1920px)": {
            padding: '24px',
            marginTop: '24px',
            height: '332px'
        },
    },
    docManagementWrapper: {
        padding: '0 16px',
        boxShadow: Shadow.modalShadow,
        width: '100%',
        // marginTop:'-8px',
        height: '244px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',

        "@media (min-width: 1920px)": {
            padding: '0 24px',
            height: '284px'
        },
    },

    accessManagementWrapper: {
        padding: '16px',
        boxShadow: Shadow.modalShadow,
        width: '100%',
        marginTop: '-8px',
        height: '380px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',

        "@media (min-width: 1920px)": {
            padding: '24px',
            height: '380px'
        },
    },

    bigManagementWrapper: {
        padding: '16px',
        boxShadow: Shadow.modalShadow,
        width: '100%',
        // marginTop:'16px',
        height: '632px',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        "@media (min-width: 1920px)": {
            padding: '24px',
            // marginTop:'24px',
            height: '688px'
        },
    },

    alignItemButton: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        background: 'transparent',
        marginRight: '24px'
    },

    alignCenter: {
        display: 'flex',
        alignItems: 'center'
    },

    renderCsv: {
        position: 'absolute',
        right: 15,
        marginTop: '5px'
    },


}));
