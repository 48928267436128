import {reportTotalStyles} from "./styles";
import {PriceConvertor} from "components";
import React from "react";

export const Row = ({name, value, minWidth}) => {
    const classes = reportTotalStyles()

    return (
        <div style={{minWidth: minWidth ? minWidth : '120px'}} className={classes.rowWrapper}>
            <p className={classes.rowTitle}>{name}:&nbsp;</p>
            <p className={classes.rowValue}>
                <PriceConvertor price={value}/>
            </p>
        </div>
    )
}