import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader, MinLoader, NoYet } from 'components';
import { Colors, DownloadFile, FindLoad, Images } from 'utils';
import { reportActions } from 'store';
import { reportStyles } from '../styles';

export const ApActivity = ({ apActivity }) => {
  const dispatch = useDispatch();
  const classes = reportStyles();
  const getLoader = FindLoad('GET_AP_ACTIVITY');
  const { loadDownload, handleDownload, } = DownloadFile()

  useEffect(() => {
    dispatch(reportActions.getApActivityReport());
  }, []);

  if (getLoader?.length) {
    return <Loader />;
  }

  return (
    <div className={classes.arActivityStyles}>
      {apActivity?.length ?
        apActivity.map((i, k) => (
          <div key={k} className={classes.arActivityRow}>
            <div className={classes.arActivityRowYear}>
              <img src={Images.exportCalendar} alt='' />
              <p>Report for {i?.year}</p>
            </div>
            <button
              onClick={() => handleDownload({ file:i?.file, name: 'APActivityReport.csv' })}
              className={classes.exportButton}
            >
              {loadDownload === i?.file?._id ?
                <MinLoader margin={'0 8px 0 0'} color={Colors.ThemeBlue} position={'relative'} />
                :
                <img src={Images.exportIcon} alt='' />
              }
              Export
            </button>
          </div>
        ))
        :
        <NoYet position={'center'} text={'No AP Activity Yet'} />
      }
    </div>
  );
};

