import { fork } from 'redux-saga/effects';
import { watchAuth } from '../auth';
import { watchPermission } from '../permissions';
import { watchOffice } from '../offices';
import { watchRole } from '../role';
import { watchAdmin } from '../admin';
import { watchBranch } from '../branches';
import { watchAgent } from '../agents';
import { watchCustomer } from '../customers';
import { watchFactoring } from '../factoring';
import { watchCarrier } from '../carriers';
import { watchMc } from '../mc';
import { watchEquipment } from '../equipment';
import { watchUpload } from '../upload';
import { watchLoad } from '../loads';
import { watchBracket } from '../brackets';
import { watchNotifications } from '../notifications';
import { watchCustomerPayments } from '../customerPayment';
import { watchHistory } from '../history';
import { watchTerm } from '../system';
import { watchPayroll } from '../payroll';
import { watchNotes } from '../notes';
import { watchReport } from '../reports';
import { watchRmis } from '../rmis';
import { watchTemplate } from '../templates';
import { watchDirectory } from '../directory';

/** Combined Sagas */
export const appSaga = function* startForman() {
  yield fork(watchAuth);
  yield fork(watchAdmin);
  yield fork(watchRole);
  yield fork(watchOffice);
  yield fork(watchPermission);
  yield fork(watchBranch);
  yield fork(watchAgent);
  yield fork(watchCustomer);
  yield fork(watchFactoring);
  yield fork(watchMc);
  yield fork(watchCarrier);
  yield fork(watchEquipment);
  yield fork(watchUpload);
  yield fork(watchLoad);
  yield fork(watchBracket);
  yield fork(watchNotifications);
  yield fork(watchCustomerPayments);
  yield fork(watchHistory);
  yield fork(watchTerm);
  yield fork(watchPayroll);
  yield fork(watchNotes);
  yield fork(watchReport);
  yield fork(watchRmis);
  yield fork(watchTemplate);
  yield fork(watchDirectory);
};
