import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {TableWrapper, FullTable} from "components";
import {officeActions} from "store";
import {PermissionsList} from "utils";
import {ACTION_TYPE, officesBody, officesHead} from "./constants";

export const Offices = () => {
    const {officesList} = useSelector((state) => ({
        officesList: state.offices.officesList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab

        return filteredInfo
    }

    useEffect(() => {
        dispatch(officeActions.getOffices(renderParams()))
    }, [info]);

    const handlePushDetailPage = (id) => {
        history.push({
            pathname: `/office/${id}`,
            state: {officeParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add Office"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createOffice'}
            link={true}
            createPermisson={PermissionsList.CREATE_UPDATE_OFFICE}
        >
            <FullTable
                head={officesHead}
                body={officesBody}
                loadingType={ACTION_TYPE}
                list={officesList?.offices}
                listCount={officesList?.count}
                handleClick={(id) => handlePushDetailPage(id)}
                noText={'Offices'}
            />
        </TableWrapper>
    );
}