import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reportActions} from "store";
import {ButtonsTab, CalendarInput, ExportButton, FullTable, GenerateButton, Loader, useWidth} from "components";
import {AP_DETAILED_REQUEST_TYPE, apDetailBody, apDetailHead} from "./constants";
import {reportStyles} from "../styles";
import { DownloadFile, FindLoad } from 'utils';
import {Row, TotalRow} from "../core";

export const ApDetailed = ({apDetailed}) => {
    const classes = reportStyles()
    const [date, selectedDate] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const loader = FindLoad(AP_DETAILED_REQUEST_TYPE)
    const info = history?.location?.state
    const width = useWidth();
    const max = width > 1900 ? width - 320 : width - 250
    const tableWidth = width > 1900 ? width + 650 : width + 1000
    const { loadDownload, handleDownload, } = DownloadFile()

    useEffect(() => {
        if (info?.status) {
            dispatch(reportActions.cleanApDetailReport())
        }
    }, [info])

    const handleGetDate = (sendInfo) => {
        dispatch(reportActions.getApDetailReport(sendInfo, info?.status))
    }

    const handleChangeDate = (e) => {
        selectedDate(e.target.value)
        const sendInfo = {}
        if (e.target.value) {
            sendInfo.month = e.target.value.slice(6, 8)
            sendInfo.year = e.target.value.slice(0, 4)
        } else {
            delete sendInfo.month
            delete sendInfo.year
        }
        handleGetDate(sendInfo)
    }

    return (
        <div>

            <div className={classes.actionsWrapper}>
                <ButtonsTab
                    maxWidth={'320px'}
                    width={'150px'}
                    first={'Current'}
                    second={'Past Reports'}
                    type={'report'}
                />

                <div className={classes.exportAndCalendar}>
                    {info?.status === 'Past Reports' ?
                        <CalendarInput
                            value={date}
                            handleChangeDate={handleChangeDate}
                        />
                        :
                        <GenerateButton handleGenerate={() => handleGetDate()}/>
                    }
                    {apDetailed?.file?.url &&
                      <ExportButton
                        loader={!!loadDownload}
                        handleExport={() => handleDownload({file:apDetailed?.file, name: 'APDetailCurrent.csv'})}
                      />
                    }
                </div>
            </div>
            {loader?.length ?
                <Loader/>
                :
                <div style={{maxWidth: max}} className={'report'}>
                    <div style={{width: tableWidth}} className={'report-detail-wrapper'}>
                        <TotalRow

                            rows={
                                <>
                                    <Row name={'Invoice Total'}
                                         value={apDetailed?.totalInvoiced ? apDetailed.totalInvoiced : 0}
                                    />
                                    <Row name={'Paid'}
                                         value={apDetailed?.totalPaid ? apDetailed.totalPaid : 0}
                                    />
                                    <Row name={'Current'}
                                         value={apDetailed?.totalCurrent ? apDetailed.totalCurrent : 0}
                                    />
                                    <Row name={'31-60 Days'}
                                         value={apDetailed?.total31to60 ? apDetailed.total31to60 : 0}
                                    />
                                    <Row name={'61-90 Days'}
                                         value={apDetailed?.total61to90 ? apDetailed.total61to90 : 0}
                                    />
                                    <Row name={'91+ Days'}
                                         value={apDetailed?.total90plus ? apDetailed.total90plus : 0}
                                    />
                                    <Row name={'Total'}
                                         value={apDetailed?.grandTotal ? apDetailed.grandTotal : 0}
                                    />
                                </>
                            }
                        />
                        <div className={'full-table'}>
                            <FullTable
                                head={apDetailHead}
                                body={apDetailBody}
                                list={apDetailed?.data?.length ? apDetailed?.data : []}
                                noText={'AP Detailed'}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}