import {Images} from "utils";
import {IconAndTitle, PhoneRow, TextRow} from "components";

export const mcsHead = [
    {name: 'name', title: 'Name',},
    {name: 'mcNumber', title: 'MC Number', custom: false},
    {name: 'address', title: 'Address', },
    {name: 'phone', title: 'Phone Number', custom: false},
]

export const mcsBody = [
    {rowText: (item) => <IconAndTitle icon={Images.authorityBlue} title={item?.name} textWidth={10}/>},
    {rowText:  (item) => <TextRow name={item?.mcNumber}  />},
    {rowText:  (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10}  />},
    {rowText: (item) => <PhoneRow phone={item?.phone}/>},
]

export const ACTION_TYPE = 'GET_MCS'