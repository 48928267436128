import {paginate} from "./paginate";
import moment from "moment";

export const searchByType = (query, text, type,) => {
    if(type === 'name') {
        return paginate(query.filter((el) => el.name.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'firstName') {
        return paginate(query.filter((el) => el.firstName.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'fullName') {
        return paginate(query.filter((el) => `${el.firstName} ${el.lastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'address') {
        return paginate(query.filter((el) => el.address.formattedAddress.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'email') {
        return paginate(query.filter((el) => el.email.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'phone') {
        return paginate(query.filter((el) => el.phoneNumber.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'phoneNumber') {
        return paginate(query.filter((el) => el.phone.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'establish') {
        return paginate(query.filter((el) => moment(el.establishedDate).format('MM/DD/YYYY').toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'mc'){
        return paginate(query.filter((el) => el.mc.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'mcNumber'){
        return paginate(query.filter((el) => el.mcNumber.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'id'){
        return paginate(query.filter((el) => el.id.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'type'){
        return paginate(query.filter((el) => el.type.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'customerType'){
        return paginate(query.filter((el) => el.type.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'companyName'){
        return paginate(query.filter((el) => el.companyName.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'carrierName'){
        return paginate(query.filter((el) => el.firstName.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'driverType'){
        return paginate(query.filter((el) => el.driverType.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'truckType'){
        return paginate(query.filter((el) => el.truckType.toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'size'){
        return paginate(query.filter((el) => el.size.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'billAmount'){
        return paginate(query.filter((el) => el.customRate.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'dateBooked'){
        return paginate(query.filter((el) => el.createdDate.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'mcId'){
        return paginate(query.filter((el) => el.mc.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'carrierTotalRate'){
        return paginate(query.filter((el) => el.carrierRate.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'startDate'){
        return paginate(query.filter((el) => el.createdDate.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'mcNumberId'){
        return paginate(query.filter((el) => el.mc._id.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'customer'){
        return paginate(query.filter((el) => el.customer.name.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'carrier'){
        return paginate(query.filter((el) => el.carrier.firstName.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'customerRate'){
        return paginate(query.filter((el) => el.customRate.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'loadStatus'){
        return paginate(query.filter((el) => el.status.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }
    if(type === 'loadBillable'){
        return paginate(query.filter((el) => el.billing && el.billing.status && el.billing.status.toString().toLowerCase().indexOf(text.toLowerCase()) > -1), 10);
    }

}
