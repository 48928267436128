import {receivablesFragments} from "./styles";

export const TotalItems = ({text, title, count, color }) => {
    const classes = receivablesFragments()
    return(
        <div className={classes.totalItemsWrapper} style={{background: color }}>
            <p className={classes.title}>{title} </p>
            <p className={classes.text}>{text}</p>
            <p className={classes.title}>{count}</p>
        </div>
    )
}