import {makeStyles} from "@material-ui/core/styles";


export const sectionStyle = makeStyles(() => ({
    circleAndTitleWrapper:{
        display:'flex',
        alignItems:'center',
        marginTop:'16px',
    }

}))