import axios from "axios";

export const adminAuthService = {
  /** Create, Edit Admin */
  createAdminService: ( body ) => axios.post(`/admins`, body, { auth:true } ),
  
  editAdmin: (id, body ) => axios.patch(`/admins/${id}`, body, {auth:true} ),

  resendInviteService: (id) => axios.post(`/authn/${id}/resendInvite`, null,{auth:true} ),
  /** End */

  /** Get Admins */
  getAdminsService: ( info ) => axios.get('/admins',{auth:true, params: { ...info }} ),

  getAdminByIdService: ( id ) => axios.get(`/admins/${id}` ,{auth:true}),

  getAdminInfoByIdService: ( id ) => axios.get(`/authn/${id}`, {auth:true}),
  /** End */

  /** Activate or Inactivate Admin */
  activateAdminService:( id ) => axios.patch(`/admins/${id.id}/activate`, null,{auth:true} ),

  inactivateAdminService:( id ) => axios.patch(`/admins/${id.id}/inactivate`, null, {auth:true} ),
  /** End */

  /** Add and Remove Role */
  addAdminRoleService: (id, roleId) => axios.patch(`/authn/${id}/${roleId}/addRole`, {auth:true}  ),

  removeAdminRoleService: (id, roleId) => axios.patch(`/authn/${id}/${roleId}/removeRole`, {auth:true} ),
  /** End */

  /** Documents */
  addHrDocument: (doc, id) => axios.post(`/admins/${id}/documents`, doc, {auth: true}),

  deleteHrDocument: (id, docId) => axios.delete(`/admins/${id}/documents/${docId}`, {auth: true}),
  /** End */

};
