import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FullTable, TableWrapper} from "components";
import {factoringActions} from "store";
import {factoringBody, factoringHead, ACTION_TYPE} from "./constants";
import {PermissionsList} from "../../utils";

export const Factoring = ({}) => {
    const {factoringsList} = useSelector((state) => ({
        factoringsList: state.factorings.factoringsList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        const filteredInfo = {
            ...info
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
        delete filteredInfo.page
        delete filteredInfo.activeTab
        dispatch(factoringActions.getFactorings({...filteredInfo}))
    }, [info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/factoring/${id}`,
            state: {factoringParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add Company"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createFactoring'}
            link={true}
            createPermisson={PermissionsList.CREATE_UPDATE_FACTORING}
        >
            <FullTable
                head={factoringHead}
                body={factoringBody}
                loadingType={ACTION_TYPE}
                list={factoringsList?.factorings}
                listCount={factoringsList?.count}
                handleClick={pushPageDetails}
                noText={'Factorings'}
            />
        </TableWrapper>
    );
};
