import {DateRow, PriceRow, TextRow, TypeRow} from "components";

export const apDetailHead = [
    {name: '', title: 'Name', custom: false},
    {name: '', title: 'Load ID', custom: false},
    {name: '', title: 'Invoice Date', custom: false},
    {name: '', title: 'Term', custom: false},
    {name: '', title: 'Payment Method', custom: false},
    {name: '', title: 'Due Date', custom: false},
    {name: '', title: 'Invoice Total', custom: false},
    {name: '', title: 'Final Amount', custom: false},
    {name: '', title: 'Paid', custom: false},
    {name: '', title: 'Paid Date', custom: false},
    {name: '', title: 'Current', custom: false},
    {name: '', title: '31-60 Days', custom: false},
    {name: '', title: '61-90 Days', custom: false},
    {name: '', title: '91+ Days', custom: false},
    {name: '', title: 'Total', custom: false},
]

export const apDetailBody = [
    {rowText:  (item) => <TextRow name={item?.name} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.loadDisplayId} textWidth={10}  />},
    {rowText: (item) => <DateRow date={item?.invoiceDate}/>},
    {rowText:  (item) => <TextRow name={item?.term} textWidth={10}  />},
    {rowText: (item) => <TypeRow text={item?.method}/>},
    {rowText: (item) => <DateRow date={item?.dueDate}/>},
    {rowText:  (item) => <PriceRow info={item?.invoiceTotal} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.finalAmount} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.paid} textWidth={10}  />},
    {rowText: (item) => <DateRow date={item?.paidDate}/>},
    {rowText:  (item) => <PriceRow info={item?.current} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging31to60} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging61to90} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging90plus} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.total} textWidth={10}  />},
]

export const AP_DETAILED_REQUEST_TYPE = 'GET_AP_DETAILED'

export const apSummaryHead = [
    {name: '', title: 'Name', custom: false},
    {name: '', title: 'Current', custom: false},
    {name: '', title: '31-60 Days', custom: false},
    {name: '', title: '61-90 Days', custom: false},
    {name: '', title: '91+ Days', custom: false},
]

export const apSummaryBody = [
    {rowText:  (item) => <TextRow name={item?.name} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.current} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging31to60} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging61to90} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.aging90plus} textWidth={10}  />},
]

export const AP_SUMMARY_REQUEST_TYPE = 'GET_AP_SUMMARY'

export const apApplicationHead = [
    {name: '', title: 'Load ID', custom: false},
    {name: '', title: 'Name', custom: false},
    {name: '', title: 'Paid', custom: false},
    {name: '', title: 'Balance', custom: false},
  ]

export const apApplicationBody = [
    {rowText:  (item) => <TextRow name={item?.loadDisplayId} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.customerName} textWidth={10}  />},
    {rowText:  (item) => <PriceRow info={item?.paidAmount} textWidth={10}  />},
    {rowText: (item) => <DateRow date={item?.paidDate}/>},
]

export const AP_APPLICATION_REQUEST_TYPE = 'GET_AP_APPLICATION'
