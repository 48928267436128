import {
  ACTIVATE_EQUIPMENT,
  CREATE_EQUIPMENT, DELETE_EQUIPMENT,
  EDIT_EQUIPMENT, EDIT_EQUIPMENT_FROM_LIST,
  GET_EQUIPMENT,
  GET_EQUIPMENT_BY_ID,
  INACTIVATE_EQUIPMENT,
  REMOVE_EQUIPMENT, SET_EQUIPMENT_STATUS, SET_FAVORITE, SET_UNSET_FAVORITE, UNSET_FAVORITE,
} from "./equipment.types";

                                           /** Equipment Requests, here is All requests for Equipment page */


/** Create, Edit Equipment */

export const createEquipment = (id, body, params ) => {
  return {
    type: CREATE_EQUIPMENT,
    payload: {id, body, params }
  }
}

export const editEquipment = (id, body, load, params ) => {
  return {
    type: EDIT_EQUIPMENT,
    payload: {id, body, load, params }
  }
}

export const editEquipmentList = (id, body, params ) => {
  return {
    type: EDIT_EQUIPMENT_FROM_LIST,
    payload: {id, body, params }
  }
}

/** End */

/** Get Equipment */

export const getEquipments = ( params ) => {
  return {
    type: GET_EQUIPMENT,
    payload: { params }
  }
}

export const getEquipmentById =( id )=>{
  return{
    type: GET_EQUIPMENT_BY_ID,
    payload: { id }
  }
}

/** End */

/** Activate or Inactivate Equipment */

export const setEquipmentStatus = ( id, status, params ) =>{
  return{
    type: SET_EQUIPMENT_STATUS,
    payload:{ id, status, params }
  }
}

/** End */

/** Delete Equipment */

export const deleteEquipment = ( id, params ) => {
  return {
    type: DELETE_EQUIPMENT,
    payload: { id, params }
  }
}

/** End */

/** Favorite */

export const setFavorite = ( id, params ) => {
  return {
    type: SET_FAVORITE,
    payload: { id, params }
  }
}

export const unsetFavorite = ( id, params ) => {
  return {
    type: UNSET_FAVORITE,
    payload: { id, params }
  }
}

/** End */
