import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, ValidationInput} from "components";
import {ErrorText, FindLoad, FindSuccess, useGlobalTextStyles, useModal,} from "utils";
import {httpRequestsOnSuccessActions} from "store";
import {modalsStyle} from "components/modal/styles";
import {noteActions} from "store/notes";

export const AddNotes = ({onResource, noteModalTypeInfo, handleSuccess}) => {
    const ACTION_TYPE = noteModalTypeInfo ? 'EDIT_GLOBAL_NOTE' : 'CREATE_GLOBAL_NOTE'
    const classes = modalsStyle();
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const [error, setError] = useState("");
    const [inputs, setInputs] = useState('');
    const {close} = useModal()

    useEffect(() => {
        if (noteModalTypeInfo) {
            setInputs(noteModalTypeInfo?.subject)
        }
    }, [noteModalTypeInfo])

    useEffect(() => {
        if (!!success.length) {
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));

            if(handleSuccess){
                handleSuccess()
            }

        }
    }, [success]);

    const handleChange = (e) => {
        setInputs(e.target.value);
        error === e.target.name && setError("");
    };

    const handleSubmit = () => {
        if (inputs) {
            const noteData = {
                text: '...',
                subject: inputs,
                resource: onResource,
            };
            if (noteModalTypeInfo) {
                const noteEditedData = {
                    text: '...',
                    subject: inputs,
                }
                dispatch(noteActions.editGlobalNote(onResource, noteModalTypeInfo.id, noteEditedData));
            } else {
                dispatch(noteActions.createGlobalNote(noteData))
            }

        } else {
            setError('subject');
        }
    };

    return (
        <div className='note-modal'>
            <p className={globalText.modalTitle}>{noteModalTypeInfo ? "Edit Note" : "Add Comment"}</p>
            <div style={{marginTop: '40px'}}>
                <ValidationInput
                    className={inputs ? classes.inputTextFieldBlue : classes.inputTextField}
                    variant={"outlined"}
                    name={"subject"}
                    label={"Add Notes"}
                    type={'text'}
                    multiline={true}
                    Length={300}
                    typeError={error === 'subject' && ErrorText.field}
                    onChange={handleChange}
                    value={inputs}
                />
                <div className={classes.maxCharacters}>
                    <p>{`Max ${inputs ? inputs?.length : 0}/300 characters`}</p>
                </div>
            </div>
            <AddModalButton
                loading={!!loader?.length}
                handleClick={handleSubmit}
                text={noteModalTypeInfo ? "Edit" : "Add"}
            />
        </div>
    );
};
