import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Line} from "components";
import {Images, PermissionsList, RolePermission} from "utils";
import {Checkbox} from "@material-ui/core";
import {equipmentActions} from "store";

export const zones = [
    {
        zoneName: 'Z0',
        zoneCount: 7,
        zonesList: ['CT', 'ME', 'MA', 'NH', 'NJ', 'RI', 'VT'],
        zoneColor: 'rgba(67, 138, 254, 0.10)',
        checkColor: '#438AFE',
        filter: 'invert(53%) sepia(96%) saturate(3269%) hue-rotate(200deg) brightness(101%) contrast(99%)',
    },
    {
        zoneName: 'Z1',
        zoneCount: 3,
        zonesList: ['DE', 'NY', 'PA'],
        zoneColor: 'rgba(236, 199, 17, 0.10)',
        checkColor: '#ECC711',
        filter: 'invert(72%) sepia(100%) saturate(398%) hue-rotate(359deg) brightness(93%) contrast(102%',
    },
    {
        zoneName: 'Z2',
        zoneCount: 5,
        zonesList: ['MD', 'NC', 'SC', 'VA', 'WV'],
        zoneColor: 'rgba(170, 128, 249, 0.10)',
        checkColor: '#7B61EA',
        filter: 'invert(39%) sepia(75%) saturate(603%) hue-rotate(212deg) brightness(96%) contrast(97%)',
    },
    {
        zoneName: 'Z3',
        zoneCount: 5,
        zonesList: ['AL', 'FL', 'GA', 'MS', 'TN'],
        zoneColor: 'rgba(0, 200, 81, 0.10)',
        checkColor: '#00C851',
        filter: 'invert(76%) sepia(52%) saturate(6985%) hue-rotate(107deg) brightness(97%) contrast(103%)',
    },
    {
        zoneName: 'Z4',
        zoneCount: 4,
        zonesList: ['IN', 'KY', 'MI', 'OH'],
        zoneColor: 'rgba(0, 188, 212, 0.10)',
        checkColor: '#00BCD4',
        filter: 'invert(46%) sepia(32%) saturate(3480%) hue-rotate(154deg) brightness(107%) contrast(101%)',
    },
    {
        zoneName: 'Z5',
        zoneCount: 6,
        zonesList: ['IA', 'MN', 'MT', 'ND', 'SD', 'WI'],
        zoneColor: 'rgba(127,0,212,0.1)',
        checkColor: 'rgba(127,0,212)',
        filter: 'invert(11%) sepia(99%) saturate(5872%) hue-rotate(271deg) brightness(98%) contrast(124%)',
    },
    {
        zoneName: 'Z6',
        zoneCount: 4,
        zonesList: ['IL', 'KS', 'MO', 'NE'],
        zoneColor: 'rgba(255,223,63,0.1)',
        checkColor: '#dcbf00',
        filter: 'invert(71%) sepia(68%) saturate(1280%) hue-rotate(8deg) brightness(97%) contrast(101%)',
    },
    {
        zoneName: 'Z7',
        zoneCount: 4,
        zonesList: ['AR', 'LA', 'OK', 'TX'],
        zoneColor: 'rgba(39,129,56,0.1)',
        checkColor: 'rgba(39,129,56,1)',
        filter: 'brightness(0) saturate(100%) invert(42%) sepia(11%) saturate(2882%) hue-rotate(79deg) brightness(92%) contrast(87%)',
    },
    {
        zoneName: 'Z8',
        zoneCount: 7,
        zonesList: ['AZ', 'CO', 'ID', 'NV', 'NM', 'UT', 'WY'],
        zoneColor: 'rgba(246,0,0,0.1)',
        checkColor: 'rgba(246,0,0, 1)',
        filter: 'brightness(0) saturate(100%) invert(9%) sepia(86%) saturate(7500%) hue-rotate(10deg) brightness(101%) contrast(117%)',
    },
    {
        zoneName: 'Z9',
        zoneCount: 6,
        zonesList: ['CA', 'OR', 'WA', 'AK', 'CA(N)', 'CA(S)'],
        zoneColor: 'rgba(206,0,252,0.1)',
        checkColor: 'rgba(206,0,252, 1)',
        filter: 'brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(3270%) hue-rotate(281deg) brightness(106%) contrast(130%)',
    },
    {
        zoneName: 'ZE',
        zoneCount: 4,
        zonesList: ['NB', 'NL', 'NS', 'PE'],
        zoneColor: 'rgba(131,255,0,0.1)',
        checkColor: 'rgba(131,255,0, 1)',
        filter: 'brightness(0) saturate(100%) invert(77%) sepia(68%) saturate(946%) hue-rotate(35deg) brightness(102%) contrast(108%)',
    },
    {
        zoneName: 'ZC',
        zoneCount: 2,
        zonesList: ['ON', 'PQ'],
        zoneColor: 'rgba(199,109,6,0.1)',
        checkColor: 'rgba(199,109,6, 1)',
        filter: 'brightness(0) saturate(100%) invert(42%) sepia(76%) saturate(2102%) hue-rotate(15deg) brightness(93%) contrast(95%)',
    },
    {
        zoneName: 'ZW',
        zoneCount: 4,
        zonesList: ['AB', 'BC', 'MB', 'SK'],
        zoneColor: 'rgba(12,79,129,0.1)',
        checkColor: 'rgba(12,79,129, 1)',
        filter: 'brightness(0) saturate(100%) invert(22%) sepia(37%) saturate(2939%) hue-rotate(184deg) brightness(93%) contrast(91%)',
    },
    {
        zoneName: 'ZM',
        zoneCount: 1,
        zonesList: ['MEXICO'],
        zoneColor: 'rgba(232,82,118,0.1)',
        checkColor: 'rgba(232,82,118, 1)',
        filter: 'brightness(0) saturate(100%) invert(40%) sepia(80%) saturate(524%) hue-rotate(295deg) brightness(97%) contrast(90%)',
    },

]

export const ZonesAndStates = ({}) => {
    const {equipmentById} = useSelector((state) => ({
        equipmentById: state.equipment.equipmentById,
    }));
    const dispatch = useDispatch()
    const [selectedZones, setSelectedZones] = useState([])

    useEffect(() => {
        if (equipmentById?.regions?.length) {
            setSelectedZones(equipmentById?.regions)
        }
    }, [equipmentById])

    const handleAssign = (zones) => {
        const params = {
            ...equipmentById,
            regions: zones
        }
        dispatch(equipmentActions.editEquipment(equipmentById?.id, params))
    }

    const handleSelectZone = (e, item) => {
        let newList = [
            ...selectedZones
        ]
        if (e.target.checked) {
            item?.zonesList?.filter((i) => {
                if (!newList?.includes(i)) {
                    newList?.push(i)
                }
            })
        } else {
            newList = newList?.filter(function (array_el) {
                return item?.zonesList.filter(function (anotherOne_el) {
                    return anotherOne_el === array_el;
                }).length === 0
            });
        }
        setSelectedZones(newList)
        handleAssign(newList)
        newList = []
    }

    const handleSelectZoneItem = (e, item, value) => {
        let newList = [
            ...selectedZones
        ]
        if (e.target.checked) {
            newList.push(value)
        } else {
            newList = newList?.filter((i) => value !== i)
        }
        setSelectedZones(newList)
        handleAssign(newList)
    }


    const checkAll = (item) => {
        const allList = item?.zonesList
        const list = []
        selectedZones?.filter((k) => {
            allList.filter((i) => {
                if (k === i) {
                    list.push(k)
                }
            })
        })

        return allList?.length === list?.length
    }

    return (
        <div className='flex-align-start'>
            <Line height={'100%'}/>
            <div className='zone-space-between'>
                <div className='zone-card-shadow'>
                    {zones?.map((i, j) => (
                        <div className='zone-card-wrapper' key={j}>
                            <Checkbox
                                style={{color: '#438AFE', padding: 0}}
                                onClick={(e) => handleSelectZone(e, i)}
                                checked={checkAll(i)}
                                disabled={
                                    !RolePermission([
                                        PermissionsList.ALL_ACCESS?.code,
                                        PermissionsList.CREATE_UPDATE_CARRIER?.code,
                                    ])
                                }
                            />
                            <div
                                className='zone-card'
                                style={{background: i?.zoneColor, height: i?.zoneCount * 56 - 8}}>
                                <p style={{color: i?.checkColor}}>{i?.zoneName}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='zone-card-shadow'>
                    {zones?.map((i) => (
                        i?.zonesList?.map((k, j) => (
                            <div
                                className='zone-items'
                                key={j}
                                style={{background: i?.zoneColor}}
                            >
                                <div className='zone-item-text-icon'>
                                    <img
                                        src={Images.location}
                                        alt="icon"
                                        style={{filter: i?.filter}}
                                    />
                                    <p>{k}</p>
                                </div>
                                <Checkbox
                                    style={{color: i?.checkColor, padding: 0}}
                                    onClick={(e) => handleSelectZoneItem(e, i, k)}
                                    checked={selectedZones?.includes(k) || false}
                                    disabled={!RolePermission([
                                        PermissionsList.ALL_ACCESS?.code,
                                        PermissionsList.CREATE_UPDATE_CARRIER?.code,
                                    ])}
                                />
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </div>
    )
}