import axios from "axios";

export const authService = {
  /** Create, Edit Customer */

  createCustomerService: ( body ) => axios.post('/customers', body, {auth:true}),

  editCustomerService: ( id, body ) => axios.patch(`/customers/${id}`, body, {auth:true}),

  /** End */

  /** Get Carriers */

  getCustomerService: ( info ) => axios.get('/customers', {auth:true, params: { ...info } } ),

  getCustomerSearchedService: ( info ) => axios.get('/customers/search', {auth:true, params: { ...info } } ),

  getCustomerByIdService: ( id ) => axios.get(`/customers/${id}`, {auth:true}),

  /** End */

  /** Activate or Inactivate Customer */

  activateCustomerService: ( id ) => axios.patch(`/customers/${id}/activate`, null, {auth:true}),

  inactivateCustomerService: ( id ) => axios.patch(`/customers/${id}/inactivate`, null,{auth:true}),

  nonFactoringCustomerService: ( id, nonFactoring) => axios.patch(`/customers/${id}/nonFactoring`, null,{auth:true, params:{nonFactoring: nonFactoring}}),

  noPodService: ( id, noPod) => axios.patch(`/customers/${id}/requirePOD`, null,{auth:true, params:{isRequired: noPod}}),

  /** End */


  /**Customer Mc BlackList */

  addMcToBlacklist: ( id, mcId, message ) => axios.patch(`/customers/${id}/mc/${mcId}/blacklist`, {reason : message},{auth:true}),

  removeMcFromBlacklistService: ( id, mcId ) => axios.delete(`/customers/${id}/mc/${mcId}/blacklist`, {auth:true}),

  getCustomerDebtService: ( id,mcId ) => axios.get(mcId ? `/customers/${id}/debt/${mcId}` : `/customers/${id}/debt`, {auth:true}),

  /** End */

  /** AutoDeny  */

  getCustomerDenyService: ( id, mcId ) => axios.get(mcId ? `/customers/${id}/${mcId}` :  `/customers/${id}` , {auth:true} ),

  activateDenyService: ( id ) => axios.patch(`/customers/${id}/activate/autoDeny`  ,{},{auth:true} ),

  inactivateDenyService: ( id ) => axios.patch(`/customers/${id}/inactivate/autoDeny`  ,{}, {auth:true} ),

  /** End */

  rateCon: ( id, statusType ) =>  axios.patch(`/customers/${id}/rateCon?rateCon=${statusType}`, null,{auth: true}),

  /** Customer Documents */

  addCustomerDocumentService: ( body ) => axios.post(`/customers/${body?.id}/documents`, { ...body.img }, {auth:true}),

  deleteCustomerDocumentService: ( id, dockId ) => axios.delete(`/customers/${id}/documents/${dockId}`, {auth:true}),

  editCustomerDocumentService: ( id, dockId, body ) => axios.patch(`/customers/${id}/documents/${dockId}`, body,{auth:true}),

  /** End */

  consignCustomerService: ( id, type ) => axios.patch(`/customers/${id}/consigment`, {},{auth:true, params:{isConsigned: type}}),

};
