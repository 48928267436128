import {paginate} from "utils";
import {GET_HISTORY_SUCCESS} from "./history.types";

const initialState = {
    historyList: null
};

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Factorings */

        case GET_HISTORY_SUCCESS:
            return {
                ...state,
                historyList: action.payload,
            }
        /** End */
        default:
            return state;
    }
};
