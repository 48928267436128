
/**Rmis Settings */
export const INVITE_RMIS = 'INVITE_RMIS';

export const REFRESH_RMIS = 'REFRESH_RMIS';

export const GET_RMIS_UPDATES = 'GET_RMIS_UPDATES';
export const GET_RMIS_UPDATES_SUCCESS = 'GET_RMIS_UPDATES_SUCCESS';

export const UPDATE_RMIS_SETUP = 'UPDATE_RMIS_SETUP';

export const UPDATE_RMIS_REQUIRED = 'UPDATE_RMIS_REQUIRED';