import {
    CREATE_LOAD,
    EDIT_LOAD,
    GET_LOAD_BY_ID,
    GET_LOAD_CUSTOMER,
    SEARCH_REC,
    FILTER_REC,
    FILTER_REC_DEFAULT,
    GET_LOAD_CARRIER,
    SEARCH_CARR,
    FILTER_CARR,
    FILTER_CARR_DEFAULT,
    SEARCH_LOAD,
    FILTER_LOAD,
    FILTER_LOAD_DEFAULT,
    GET_LOAD_TRANSACTIONS,
    GET_LOADS_BY_STATUS,
    SEND_TO_PAYABLE,
    APPROVE_PAYABLE,
    GET_LOADS_BY_PAYABLE,
    GET_LOADS_RECEIVABLES,
    GET_PAST_PAYABLE,
    GET_PAST_BILLS,
    CANCEL_LOAD,
    GET_SEARCH_LOAD_CUSTOMERS,
    REMOVE_SEARCHED_CUSTOMERS,
    RE_BILL,
    GET_CARRIER_LAST_LOAD,
    GET_LOAD_CARRIER_TRANSACTIONS,
    CREATE_LOAD_BY_TEMPLATE,
    GET_LOAD_OTHER_TRANSACTIONS,
    GET_LOAD_TOTALS,
} from './loads.types';

/** Loads Requests, here is All requests for Load page */

/** Create, Edit Load */

export const createLoad = (body) => {
    return {
        type: CREATE_LOAD,
        payload: {body}
    }
}

export const createLoadTemplate = (id) => {
    return {
        type: CREATE_LOAD_BY_TEMPLATE,
        payload: {id}
    }
}

export const editLoad = (body, id) => {
    return {
        type: EDIT_LOAD,
        payload: {body, id}
    }
}

export const cancelLoad = (id) => {
    return {
        type: CANCEL_LOAD,
        payload: {id}
    }
}

/** End */

/** Get Loads */

export const getLoadTotals = ( ) => {
    return {
        type: GET_LOAD_TOTALS,
    }
}
export const getLoadsByStatus = (params, load) => {
    return {
        type: GET_LOADS_BY_STATUS,
        payload: {params, load}
    }
}

export const getCustomerLoads = (id) => {
    return {
        type: GET_LOAD_CUSTOMER,
        payload: {id}
    }
}

export const getCarrierLoads = (id, params) => {
    return {
        type: GET_LOAD_CARRIER,
        payload: {id, params}
    }
}

export const getCarrierLastLoads = (id, params) => {
    return {
        type: GET_CARRIER_LAST_LOAD,
        payload: {id, params}
    }
}

export const getLoadById = (id, loading) => {
    return {
        type: GET_LOAD_BY_ID,
        payload: {id, loading}
    }
}

export const getLoadsByPayable = (id) => {
    return {
        type: GET_LOADS_BY_PAYABLE,
        payload: id,
    }
}

export const getReceivablesInfo = (params) => {
    return {
        type: GET_LOADS_RECEIVABLES,
        payload: params,
    }
}

/** End */

/** Carriers CarrierPayment */

export const searchCustomerReceivables = (value, type, from) => {
    return {
        type: from === 'carrier' ?
            SEARCH_CARR :
            from === 'load' ?
                SEARCH_LOAD :
                SEARCH_REC,
        payload: {value, type}
    }
}

export const filterByAlphabeticalReceivables = (name, from) => {
    return {
        type: from === 'carrier' ? FILTER_CARR : from === 'load' ? FILTER_LOAD : FILTER_REC,
        payload: {name}
    }
}

export const filterByDefaultReceivables = (name, from, id) => {
    return {
        type: from === 'carrier' ? FILTER_CARR_DEFAULT : from === 'load' ? FILTER_LOAD_DEFAULT : FILTER_REC_DEFAULT,
        payload: {name, id}
    }
}

/** End */


/** Load Transactions */

export const getLoadTransactions = (id, success, params) => {
    return {
        type: GET_LOAD_TRANSACTIONS,
        payload: {id, success, params}
    }
}

export const getLoadCarrierTransactions = (id, success, params) => {
    return {
        type: GET_LOAD_CARRIER_TRANSACTIONS,
        payload: {id, success, params}
    }
}

export const getLoadOtherTransactions = (id, success, params) => {
    return {
        type: GET_LOAD_OTHER_TRANSACTIONS,
        payload: {id, success, params}
    }
}

/** End */

/** Load Payable */

export const sendToPayable = (id, type) => {
    return {
        type: SEND_TO_PAYABLE,
        payload: {id, type}
    }
}

export const approvePayable = (id, type) => {
    return {
        type: APPROVE_PAYABLE,
        payload: {id, type}
    }
}

/** End */

/** Pasts */

export const getPastBill = (id) => {
    return {
        type: GET_PAST_BILLS,
        payload: id
    }
}

export const reBill = (id) => {
    return {
        type: RE_BILL,
        payload: id
    }
}

export const getPastPayable = (id) => {
    return {
        type: GET_PAST_PAYABLE,
        payload: id
    }
}
/** End */

export const searchList = (name) => {
    return {
        type: GET_SEARCH_LOAD_CUSTOMERS,
        payload: name
    }
}

export const removeSearchedList = () => {
    return {
        type: REMOVE_SEARCHED_CUSTOMERS,
    }
}