import { makeStyles } from "@material-ui/core/styles";

export const logoStyle = makeStyles(() => ({
  GlobalLogo: {
    "& img": {
      width: "35px",
      height: "46px",
      margin:'8px 0 0 10px',
    },
  },
}));
