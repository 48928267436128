import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Circle, Line} from "components";
import {Colors, useGlobalText} from "utils";
import {Alerts, ReviewModal} from "fragments";
import React from "react";
import {CarrierStyles} from "./styles";
import {useParams} from "react-router-dom";


export const Other = ({ }) => {
    const globalStyle = useGlobalText();
    const classes = CarrierStyles();
    const params = useParams()

    return(
        <div>
            <Accordion>
                <AccordionSummary
                    className={"accordion"}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="align-center">
                        <Circle number={1} back={Colors.TextPrimary} />
                        <p className={globalStyle.title}>Alerts</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>

                    <div className={classes.flexAble}>
                        <Line/>
                        <div className={classes.alertWrapper}>
                           <Alerts carrierId={params?.id}/>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    className={"accordion"}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="align-center">
                        <Circle number={2} back={Colors.TextPrimary} />
                        <p className={globalStyle.title}>Review</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>

                    <div className={classes.flexAble}>
                        <Line/>
                        <div className={classes.alertWrapper}>
                            <ReviewModal carrierId={params?.id}/>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>


        </div>
    )
}