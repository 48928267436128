import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {CreateChancel, ValidationInput} from "components";
import {FindLoad, FindSuccess, useGlobalStyles, useModal} from "utils";
import {carrierInfoFragments} from "../../../fragments/carriers/carrierInfo/core";
import {httpRequestsOnSuccessActions, templateActions} from "store";
import {ColorsArr} from "./colorsArr";
import {useHistory} from "react-router-dom";



export const CreateTemplate = ({template, byId}) => {
    const ACTION_TYPE = template?.id ? 'EDIT_TEMPLATE' : 'CREATE_TEMPLATE'
    const globalStyles = useGlobalStyles()
    const classes = carrierInfoFragments()
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const {close} = useModal()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        if (template?.id) {
            setInputs({
                name: template?.name,
                description: template?.description,
                color: template?.color,
            })
        }
    }, [template])

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            close()
        }
    }, [success])

    const handleChange = (ev) => {
        setInputs(prevState => ({...prevState, [ev.target.name]: ev.target.value,}),);
        ev.target.name === error && setError('')
    }

    const handleCreate = () => {
        let random = Math.floor(Math.random() * ColorsArr.length);
        if (inputs?.name) {
            const body = {
                name: inputs?.name,
                description: inputs?.description ? inputs?.description : null,
                color: template?.color ? template?.color : ColorsArr[random],
            }
            if(template?.id){
                dispatch(templateActions.editTemplate(template?.id, body, info, byId))
            }else{
                dispatch(templateActions.createTemplate(body, info))
            }
        } else {
            setError(
                !inputs?.name ? 'name' : ''
            )
        }
    }

    return (
        <div className='create-modal-wrapper'>
            <p className='create-template-title'>{template ? 'Edit Template' : 'Create Template'}</p>
            <p className='create-template-subtitle'>To create template please, fill out the fields below fields</p>
            <ValidationInput
                onChange={handleChange}
                typeError={error === 'name' ? 'Required field' : ''}
                value={inputs?.name}
                variant={"outlined"}
                name={"name"}
                label={"Write Template Name*"}
                type={"text"}
            />
            <ValidationInput
                onChange={handleChange}
                typeError={error === 'description'}
                className={classes.inputDescription}
                variant={"outlined"}
                name={"description"}
                Length={500}
                value={inputs?.description}
                label={"Description here..."}
                type={"text"}
                multiline={true}
            />
            <div className='max-characters'>
                <p>{`Max 500/${inputs?.description ? inputs?.description?.length : 0} characters`}</p>
            </div>
            <CreateChancel
                loader={!!loader.length}
                classes={globalStyles.buttonsStyleFullWidth}
                create={template ? 'Save' : "Create"}
                chancel={"Cancel"}
                onCreate={handleCreate}
                onClose={() => close()}
                width={'100%'}
            />
        </div>
    )
}