import React from "react";
import {DateRow, PriceRow, Download, TextRow} from "components";
import {Images} from "../../../../utils";

export const billingHisHead = [
    {name: '', title: 'Billed Date', custom: false},
    {name: '', title: 'Paid Date', custom: false},
    {name: '', title: 'Submitted By', custom: false},
    {name: '', title: 'Amount', custom: false},
    {name: '', title: 'Paid Amount', custom: false},
    {name: '', title: 'Billing Balance', custom: false},
    {name: '', title: 'Billing Docs', custom: false},
]

export const billingHisBody = [
    {rowText: (item) => <DateRow date={item?.invoicedDate}/>},
    {rowText: (item) => <DateRow date={item?.paidDate}/>},
    {rowText: (item) => <TextRow name={item?.submitterName ? item?.submitterName : 'N/A'}/>},
    {rowText: (item) => <PriceRow info={item?.amount}/>},
    {rowText: (item) => <PriceRow info={item?.paidAmount}/>},
    {rowText: (item) => <PriceRow info={item?.balance}/>},
    {rowText: (item) => item?.docs ? <div  className={'align-center'}>
            <a style={{marginRight:'12px', height:'10px'}} href={item?.docs?.url} target='_blank'>
                <img src={Images.show} alt={'icon'}/>
            </a>
            <Download fullFile={{file:item?.docs, name:'billing.pdf'}} text={' '}/>
    </div> : 'N/A'},
]

export const ACTION_TYPE = 'GET_PAST_BILLS'

export const NOT_BILLABLE = 'NOTBILLABLE'
export const CLOSE_BILL = 'CLOSE_BILL'
export const CORRECTION_SENDED = 'CORRECTION_SENDED'
export const BILLED = 'BILLED'