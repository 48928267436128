import {useDispatch, useSelector} from 'react-redux';
import {useNoteContStyles} from './styles';
import NotificationItem from './notificationItem';
import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import axios from 'axios';
import {FindLoad} from "utils";
import {notificationsActions} from "store";
import NotificationsIcon from "@material-ui/icons/Notifications";

export const NotificationWrapper = ({notifications, userInfo, handleClose}) => {
    const dispatch = useDispatch();
    const classes = useNoteContStyles();
    const [array, setArray] = useState(6);
    const [notificationsList, setNotifications] = useState([]);

    const {removeNotification} = useSelector((state) => ({
        removeNotification: state.notifications.removeNotification,
    }));

    const closeOpen = () => {
        handleClose()
    };

    useEffect(() =>{
        if(notifications){
            setNotifications(notifications)
        }

    }, [notifications])
    const params = {pageSize: array, page: 1,};
    const list = notificationsList.length ? notificationsList : notifications;

    const [load, setLoad] = useState(true);
    const [page, setPage] = useState(1);
    const loader = useRef(null);

    useEffect(() => {
        let options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, []);

    useEffect(() => {
        let config = {
            headers: {'access-token': localStorage.getItem('access-token')},
            params: params,
        };
        axios.get(`/notifications`, config).then((res) => {
            if (res.data.length >= notificationsList.length) {
                setArray(array + 6);
                setNotifications(res.data);
                dispatch(notificationsActions.getNotifications(params));
            } else {
                setLoad(false);
            }
        });
    }, [page]);

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1);
        }
    };

    const backLoad = FindLoad('GET_NOTIFICATIONS');

    return (
        <>
            {notifications && notifications.length ? (
                <>
                    <div className={classes.backdrop} onClick={closeOpen}/>
                    <div className={classes.notificationsCont}>

                        <div>
                            <p className={classes.headerText}>Notifications</p>

                        </div>
                        <div className={classes.markAll}>
                            <button
                                className={classes.headerText1}
                                onClick={() => dispatch(notificationsActions.markReadNotifications(params))}
                            >
                                Mark all as read
                            </button>
                        </div>

                        <div style={{height: '500px', overflow: 'auto'}}>
                            <div className="container">
                                <div className="post-list">
                                    {list.length &&
                                    list.map((n, i) => (
                                        <div className={classes.hoverStyle}>
                                            <div className='hoverBack'>
                                                <NotificationItem
                                                    removeNotification={removeNotification && removeNotification}
                                                    params={params}
                                                    setNotifications={setNotifications}
                                                    closeOpen={closeOpen}
                                                    loader={loader}
                                                    notifications={n}
                                                    key={i}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {load && (
                                        <div className="loading" ref={loader}>
                                            <div className={classes.infinitiScrollStyle}/>
                                        </div>
                                    )}
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {backLoad.length && (
                                        <CircularProgress
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                margin: '0 auto',
                                                color: '#387DFF',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.backdrop} onClick={closeOpen}/>
                    <div className={classes.notificationsCont}>
                        <div className={classes.noNotificationBody}>
                            <NotificationsIcon
                                style={{fontSize: '30px', color: '#387DFF80', width: '60px', height: '60px'}}/>

                            <p className={classes.noNotes}>No Notifications Yet</p>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
