
/**Create Admins */
export const CREATE_ADMIN = 'CREATE_ADMIN';

export const RESEND_ADMIN_INVITE = 'RESEND_ADMIN_INVITE';

/**Edit Admin */
export const EDIT_ADMIN = 'EDIT_ADMIN'

/**Get Admins */
export const GET_ADMINS = 'GET_ADMINS';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMIN_INFO_BY_ID = 'GET_ADMIN_INFO_BY_ID';
export const GET_ADMIN_INFO_BY_ID_SUCCESS = 'GET_ADMIN_INFO_BY_ID_SUCCESS';

/**Get Admin by id */
export const GET_ADMIN_BY_ID = 'GET_ADMIN_BY_ID';
export const GET_ADMIN_BY_ID_SUCCESS = 'GET_ADMIN_BY_ID_SUCCESS';

/**Activate Inactivate Admin */
export const SEARCH_BY_TYPE = 'SEARCH_BY_TYPE'

/**Activate Inactivate office */
export const ACTIVATE_ADMIN = 'ACTIVATE_ADMIN'
export const INACTIVATE_ADMIN = 'INACTIVATE_ADMIN'

// /**Admins Filters */
// export const FILTER_BY_ALPHABETICAL = 'FILTER_BY_ALPHABETICAL'
// export const FILTER_BY_DEFAULT = 'FILTER_BY_DEFAULT'

/** Add and Remove Role */
export const ADD_ADMIN_ROLE = 'ADD_ADMIN_ROLE'
export const ADD_ADMIN_ROLE_SUCCESS = 'ADD_ADMIN_ROLE_SUCCESS'
export const REMOVE_ADMIN_ROLE = 'REMOVE_ADMIN_ROLE'






