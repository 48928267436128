import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FullTable, TableWrapper} from "components";
import {mcActions} from "store";
import {mcsBody, mcsHead, ACTION_TYPE} from "./constants";
import {PermissionsList, RolePermission} from "../../utils";

export const Authorities = ({}) => {
    const { mcsList } = useSelector((state) => ({
        mcsList: state.mcs.mcsList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
            const filteredInfo = {
                ...info
            }
            filteredInfo.skip = info?.skip ? info?.skip : 0
            filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
            filteredInfo.limit = 100
            delete filteredInfo.page
            delete filteredInfo.activeTab
            dispatch(mcActions.getMcs( { ...filteredInfo } ))
    }, [info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/authority/${id}`,
            state: {authorityParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add Authority"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createAuthorities'}
            link={true}
            createPermisson={PermissionsList?.CREATE_UPDATE_MC}
        >
            <FullTable
                head={mcsHead}
                body={mcsBody}
                loadingType={ACTION_TYPE}
                list={mcsList?.mcs}
                listCount={mcsList?.count}
                handleClick={pushPageDetails}
                noText={'Authorities'}
            />
        </TableWrapper>
    );
}
