import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, TableWrapper} from "components";
import {adminActions} from "store";
import {ACTION_TYPE, adminsBody, adminsHead} from "./constants";
import {PermissionsList} from "utils";

export const HumanResources = ({}) => {
    const {adminsList} = useSelector((state) => ({
        adminsList: state.admins.adminsList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        const filteredInfo = {
            ...info
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
        filteredInfo.limit = 100
        if (filteredInfo?.sortType === 'firstName') {
            filteredInfo.sortType = 'name'
        }
        delete filteredInfo.page
        delete filteredInfo.activeTab
        dispatch(adminActions.getAdmins({...filteredInfo}))
    }, [info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/humanResource/${id}`,
            state: {humanResourceParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add User"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createHumanResources'}
            link={true}
            createPermisson={PermissionsList.CREATE_UPDATE_ADMIN}
        >
            <FullTable
                head={adminsHead}
                body={adminsBody}
                loadingType={ACTION_TYPE}
                list={adminsList?.admins}
                listCount={adminsList?.count}
                handleClick={pushPageDetails}
                noText={'Human Resources'}
            />
        </TableWrapper>
    );
};
