import {DELETE_TERM, CREATE_TERM, GET_TERMS, CREATE_QUICKBOOKS, GET_QUICKBOOKS} from "./terms.types";

/** Terms Requests, here is All requests for Terms And Conditions page */

/** Create, Edit, Get, Delete,  Terms */


export const createTerm = (body) => {
    return {
        type: CREATE_TERM,
        payload: {body}
    }
}

export const getTerms = ( ) => {
    return {
        type: GET_TERMS,
    }
}

export const deleteTerm = (id) => {
    return {
        type: DELETE_TERM,
        payload: {id}
    }
}

/** End */

/** Quickbook */

export const addQuickbook = ( ) => {
    return {
        type: CREATE_QUICKBOOKS,
    }
}

export const getQuickbook = ( ) => {
    return {
        type: GET_QUICKBOOKS,
    }
}

/** End */