import {Button} from "@material-ui/core";
import {buttonsStyle} from "./styles";
import {MinLoader} from "../loader";
import {Colors} from "utils";
import React from "react";

export const AddModalButton = ({text, handleClick, styles, loading, type, color, buttonStyle, disabled}) => {
    const classes = buttonsStyle();
    return (
        <div style={styles}>
            <Button
                disabled={loading === true ? true : disabled}
                style={{...buttonStyle}} className={color === 'blue' ? classes.addModalBlueButtonStyle : classes.addModalButtonStyle}
                onClick={handleClick}
            >
                {loading === true ?
                    <MinLoader margin={'0'} color={Colors.TextWhite}/>
                    :
                        text
                }
            </Button>
        </div>
    );
};
