import {errMessageStyle} from "./styles";

export const NoYet =({text, position, styles})=>{
    const classes = errMessageStyle();
    return(
        <span className={
            position === 'center' ? classes.notYetCenter:
                position === 'screenCenter' ? classes.notYetScreenCenter :
                '' }
        >
          <p   style={{...styles}} className={classes.notYetText}>{text}</p>
        </span>
    )
}