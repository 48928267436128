import React, {useState, useEffect} from "react";
import {CloseButton} from "../buttons";
import {Button, TextField, TextareaAutosize} from "@material-ui/core";
import {
    Colors,
    Images,
    FindLoad,
    FindError,
    FindSuccess,
} from "utils";
import {modalsStyle} from "./styles";
import {MinLoader} from "../loader";
import {
    ErrMessage,
} from "components";
import {useDispatch} from "react-redux";
import {authActions, httpRequestsOnSuccessActions} from "store";
import {Controller, useForm} from "react-hook-form";

const ACTION_TYPE = "CONTACT_US";

export const ContactModal = ({className, handleClose}) => {
    const classes = modalsStyle();
    const dispatch = useDispatch();
    const backError = FindError(ACTION_TYPE);
    const backLoad = FindLoad(ACTION_TYPE);
    const backSuccess = FindSuccess(ACTION_TYPE);
    const [error, setError] = useState("");

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: "onSubmit",
        defaultValues: {
            name: "",
            email: "",
            message: "",
        },
    });

    const onSubmit = (data) => {
        dispatch(authActions.contactUs(data));
    };

    useEffect(() => {
        if (backError) {
            setError(backError[0]?.error);
        }
    }, [backError]);

    useEffect(() => {
        const success = backSuccess[0]?.type;
        if (success === ACTION_TYPE) {
            // dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            handleClose();
        }
    }, [backSuccess]);

    return (
        <div className={className ? className.contactModal : classes.contactModal}>
            <div className={classes.closeButton}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className="contact-modal">
                <div className="banner-box">
                    <div className="baner-info">
                        <img src={Images.SendDateIcon} alt="Send-Data" className=""/>
                        <h5 className="title-modal">Send us message</h5>
                    </div>
                    <div className="banmer-text">
                        <p className="subtitle">
                            Our team is always ready to answer any questions you may have
                            about our logistics services. Please feel free to reach out to us
                            through the contact form bellow.
                        </p>
                    </div>
                </div>
                <div className="send-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <Controller
                                name={"name"}
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label={"First Name*"}
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                )}
                            />
                            {error?.message?.length && (
                                <ErrMessage
                                    text={
                                        error?.message[0] === "name should not be empty"
                                            ? "name should not be empty"
                                            : ""
                                    }
                                />
                            )}
                        </div>
                        <div className="form-group">
                            <Controller
                                name={"email"}
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label={"Email*"}
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                )}
                            />
                            {error?.message?.length && (
                                <ErrMessage
                                    text={
                                        error?.message?.[0] === "email must be an email"
                                            ? "email must be an email"
                                            : error?.message?.[1] === "email must be an email"
                                                ? "email must be an email"
                                                : error?.message?.[1] === "email should not be empty"
                                                    ? "email should not be empty"
                                                    : ""
                                    }
                                />
                            )}
                        </div>
                        <div className="form-group">
                            <Controller
                                name={"message"}
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <TextareaAutosize
                                        id="message"
                                        onChange={onChange}
                                        value={value}
                                        className={'text-area'}
                                        placeholder="Write message here..."
                                        minRows={6}
                                        fullWidth
                                        style={{
                                            width: "100%",
                                            padding: "12px",
                                            resize: "none",
                                            height: '125px',
                                        }}
                                    />
                                )}
                            />
                            {error?.message?.length && (
                                <ErrMessage
                                    text={
                                        error?.message?.[0] === "message should not be empty"
                                            ? "message should not be empty"
                                            : error?.message?.[1] === "message should not be empty"
                                                ? "message should not be empty"
                                                : error?.message?.[2] === "message should not be empty"
                                                    ? "message should not be empty"
                                                    : error?.message?.[3] === "message should not be empty"
                                                        ? "message should not be empty"
                                                        : ""
                                    }
                                />
                            )}
                        </div>
                        <div className="send-box">
                            <Button
                                type="button"
                                className="cancel-btn"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" className="send-btn">
                                {backLoad.length ? (
                                    <MinLoader margin={"0"} color={Colors.TextWhite}/>
                                ) : (
                                    <span>Send</span>
                                )}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
