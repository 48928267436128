import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createInputsWrapper, ErrorText, useGlobalStyles, userType } from 'utils';
import { Line, SelectInput, SelectTypeAutocomplete, ValidationInput } from 'components';
import { createLoadStyle } from './styles';
import { bookedAs, teamSolo } from './common';
import { loadTypeList } from '../../../../fragments/loads/createLoad/core/common';
import { loadTypeEnums } from '../../../../fragments/loads/createLoad/core/common/constants';

export const LoadDetails = ({ handleChange, inputs, disables, error, info, setInputs }) => {
  const { mcsList, assignedAgentMc } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    assignedAgentMc: state.mcs.assignedAgentMc,
  }));
  const globalScreens = createInputsWrapper();
  const globalStyles = useGlobalStyles();
  const classes = createLoadStyle();
  const [soldAs, setSoldAd] = useState([]);

  useEffect(async () => {
    if (mcsList?.mcs?.length) {
      if (userType === 'ADMIN') {
        const filteredSold = await mcsList?.mcs?.filter((i) => (i.authorityType !== 'CARRIER'));
        setSoldAd(filteredSold);
      }
    }
    if (assignedAgentMc?.length && userType === 'AGENT') {
      const filteredSold = await assignedAgentMc?.filter((i) => (i.authorityType !== 'CARRIER'));
      setSoldAd(filteredSold);
    }
  }, [mcsList, assignedAgentMc]);

  useEffect(() => {
    if (!info?.loadType && !info?.soldAsMC) {
      const inputDefault = {
        ...inputs,
        serviceBooked: 'SOLO',
        serviceGiven: 'SOLO',
        modeBooked: 'FULL',
        modeGiven: 'FULL',
      };
      if (soldAs) {
        const newList = soldAs?.filter((i) => (i.authorityType === 'DEFAULT'));
        if (newList && newList?.length) {
          inputDefault.soldAsMC = newList[0]?.id;
        }
      }
      setInputs(inputDefault);
    }
  }, [soldAs]);

  return (
    <div className="full-width-flex-able">
      <div className={'MuiAccordionDetails-root'}>
        <Line height={'240px'} />
      </div>
      <div className="load-row--column-wrapper" style={{ marginTop: '12px' }}>
        <div className={globalScreens.basicInfoInputs}>
          <div className={classes.inputsWrapper}>
            <SelectInput
              className={inputs?.loadType && globalStyles.inputTextFieldBlue}
              name={'loadType'}
              label={'Load Type*'}
              handleSelect={handleChange}
              style={globalStyles.loadSimpleInput}
              value={inputs.loadType}
              list={loadTypeList}
              typeError={error === 'loadType' ? ErrorText.field : ''}
              type={'mc'}
              disabled={disables}
            />
            {(inputs.loadType === loadTypeEnums.REEFER ||
                inputs.loadType === loadTypeEnums.REEFER_DRAYAGE ||
                inputs.loadType === loadTypeEnums.COLD_STORAGE ||
                inputs.loadType === loadTypeEnums.RAIL_REEFER) &&
              <ValidationInput
                style={globalStyles.loadSimpleInput}
                className={(inputs?.temperature === 0 || inputs?.temperature) && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'temperature'}
                label={'Temperature*'}
                type={'number'}
                typeError={error === 'temperature' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.temperature === 0 ? '0' : inputs.temperature}
                disabled={disables}
              />
            }
            {(inputs?.loadType === 'DRAYAGE' || inputs?.loadType === 'RAIL') &&
              <ValidationInput
                style={globalStyles.loadSimpleInput}
                className={inputs.containerNumber && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'containerNumber'}
                label={'Container number*'}
                type={'text'}
                typeError={error === 'containerNumber' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.containerNumber}
                disabled={disables}
              />
            }
            {inputs?.loadType === 'OTHER' &&
              <div className={classes.descriptionWrapper}>
                <ValidationInput
                  style={globalStyles.loadSimpleInput}
                  className={inputs.description && globalStyles.inputTextFieldBlue}
                  variant={'outlined'}
                  name={'description'}
                  label={'Description*'}
                  Length={50}
                  type={'text'}
                  typeError={error === 'description' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.description}
                  disabled={disables}
                />
                <p>{`${inputs.description ? inputs.description.length : 0}/50`}</p>
              </div>
            }
          </div>
          <div className={classes.halfScreen}>
            <ValidationInput
              style={globalStyles.loadSimpleInputFull}
              className={inputs.commodity && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'commodity'}
              label={'Commodity*'}
              type={'text'}
              typeError={error === 'commodity' ? ErrorText.field : ''}
              onChange={handleChange}
              value={inputs.commodity}
              disabled={disables}
            />
          </div>
        </div>
        <div className={globalStyles.spaceBetween} style={{ height: '65px', alignItems: 'flex-start' }}>
          <div className={classes.inputsWrapper}>
            <SelectInput
              className={inputs.serviceBooked && globalStyles.inputTextFieldBlue}
              name={'serviceBooked'}
              label={'Service Type*'}
              handleSelect={handleChange}
              style={globalStyles.loadSimpleInput}
              value={inputs.serviceBooked}
              list={teamSolo}
              typeError={error === 'serviceBooked' ? ErrorText.field : ''}
              type={'mc'}
              disabled={disables}
            />
            <SelectInput
              className={inputs.serviceGiven && globalStyles.inputTextFieldBlue}
              name={'serviceGiven'}
              label={'Service Given*'}
              handleSelect={handleChange}
              style={globalStyles.loadSimpleInput}
              value={inputs.serviceGiven}
              list={teamSolo}
              typeError={error === 'serviceGiven' ? ErrorText.field : ''}
              type={'mc'}
              disabled={disables}
            />
          </div>
          <div className={classes.halfScreen}>
            <SelectInput
              className={inputs.modeBooked && globalStyles.inputTextFieldBlue}
              name={'modeBooked'}
              label={'Booked As*'}
              handleSelect={handleChange}
              style={globalStyles.loadSimpleInput}
              value={inputs.modeBooked}
              list={bookedAs}
              typeError={error === 'modeBooked' ? ErrorText.field : ''}
              type={'mc'}
              disabled={disables}
            />
            <SelectInput
              style={globalStyles.loadSimpleInputFull}
              className={inputs.modeGiven && globalStyles.inputTextFieldBlue}
              name={'modeGiven'}
              label={'Sold As*'}
              handleSelect={handleChange}
              value={inputs.modeGiven}
              list={bookedAs}
              typeError={error === 'modeGiven' ? ErrorText.field : ''}
              type={'mc'}
              disabled={disables}
            />
          </div>
        </div>
        <div className={globalScreens.basicInfoInputs}>
          <div className={classes.inputsWrapper}>
            <div className={classes.weightFr}>
              <ValidationInput
                style={
                  (inputs?.modeBooked === 'PARTIAL' || inputs?.loadType === 'DRAYAGE' || inputs?.loadType === 'BOX_TRUCK')
                    ? globalStyles.loadSimpleInput : ''}
                className={inputs.weight && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'weight'}
                label={'Weight*'}
                type={'text'}
                typeError={error === 'weight' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.weight}
                disabled={disables}
              />
              {(inputs?.modeBooked === 'PARTIAL' || inputs?.loadType === 'DRAYAGE' || inputs?.loadType === 'BOX_TRUCK') &&
                <ValidationInput
                  className={inputs.weight && globalStyles.inputTextFieldBlue}
                  variant={'outlined'}
                  name={'ft'}
                  label={'FT'}
                  type={'number'}
                  typeError={error === 'ft' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.ft}
                  disabled={disables}
                />
              }
            </div>
            {soldAs?.length ?
              <SelectTypeAutocomplete
                loadType={userType === 'ADMIN' ? 'GET_MCS' : 'GET_AGENT_ASSIGNED_MCS'}
                style={globalStyles.loadSimpleInput}
                name={'soldAsMC'}
                label={'name'}
                type={'mcNumber'}
                title={'Sold As Authority*'}
                handleSelect={handleChange}
                defaultValue={inputs?.soldAsMC}
                typeError={error === 'soldAsMC'}
                list={soldAs ? soldAs : []}
                disabled={disables}
                error={error}
              />
              :
              <SelectTypeAutocomplete
                style={globalStyles.loadSimpleInput}
                title={'Sold As Authority*'}
                list={[]}
                disabled={disables}
                error={error}
              />
            }
          </div>
          <div className={classes.halfScreen}>
            <ValidationInput
              style={globalStyles.loadSimpleInput}
              className={inputs.contactName && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'contactName'}
              label={'Contact Name'}
              type={'text'}
              typeError={error === 'contactName' ? ErrorText.field : ''}
              onChange={handleChange}
              value={inputs.contactName}
              disabled={disables}
            />
            <ValidationInput
              style={globalStyles.loadSimpleInputFull}
              className={inputs.customerReference && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'customerReference'}
              label={'Customer Reference*'}
              type={'text'}
              typeError={error === 'customerReference' ? ErrorText.field : ''}
              onChange={handleChange}
              value={inputs.customerReference}
              disabled={disables}
            />
          </div>
        </div>
      </div>
    </div>
  );
};