import React from 'react';
import { useDispatch } from 'react-redux';
import { ChangePassword, myProfileFragment } from './core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Colors, FindLoad, Images } from 'utils';
import { Button } from '@material-ui/core';
import { authActions } from 'store';
import { MinLoader } from 'components';

export const MyProfile = ({ info }) => {
  const loader = FindLoad('LOG_OUT');
  const classes = myProfileFragment();
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(authActions.logOut());
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.avatar}>
        <AccountCircleIcon style={{ fontSize: 70, color: Colors.ThemeBlue }} />
        <p>{info && `${info.firstName} ${info.lastName}`}</p>
      </div>
      <div className={classes.userInfo}>
        <div className={classes.infoSections}>
          <img src={Images.email} alt={'icon'} />
          <p>{info?.email ? info.email : 'Not Set'}</p>
        </div>
        {info?.phoneNumber &&
          <div className={classes.infoSections}>
            <img src={Images.bluePhone} alt={'icon'} />
            <p>{info?.phoneNumber ? '+' + info.phoneNumber : 'Not Set'}</p>
          </div>
        }
        {info?.address?.formattedAddress &&
          <div className={classes.infoSections}>
            <img src={Images.location} alt={'icon'} />
            <p>{info?.address?.formattedAddress ? info.address.formattedAddress : 'Not Set'}</p>
          </div>
        }
      </div>
      <ChangePassword />
      <Button onClick={handleSignOut} className={classes.signOutButton}>
        {loader.length ?
          <MinLoader margin={'0'} color={Colors.TextWhite} />
          :
          <>
            <img src={Images.signOut} alt="signOut" />
            <p>Sign Out</p>
          </>
        }
      </Button>
    </div>
  );
};