import {
  activateBranch,
  createBranch,
  createBranchManager,
  editBranch,
  getBranchById,
  getBranches,
  inactivateBranch,
  removeBranchManager,
  selectBranchManager,
} from "./branches.action";

export { branchReducer } from './branches.reducer';
export { watchBranch } from './branches.saga';

export const branchActions = {
  /** Create, Edit Branch */
  createBranch,
  editBranch,
  /** End */

  /** Get Branches */
  getBranches,
  getBranchById,
  /** End */

  /** Activate or Inactivate Branch */
  activateBranch,
  inactivateBranch,
  /** End */

  /** Branch Management */
  createBranchManager,
  selectBranchManager,
  removeBranchManager,
  /** End */

}