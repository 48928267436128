import {createTemplate, deleteTemplate, editTemplate, getTemplateById, getTemplates} from "./templates.action";

export {templatesReducer} from './templates.reducer';
export {watchTemplate} from './templates.saga';

export const templateActions = {
    /** Template Actions */
    createTemplate,
    editTemplate,
    deleteTemplate,
    getTemplates,
    getTemplateById,
    /** End */
}

