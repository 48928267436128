import React, {useState} from "react";
import {navBarStyles} from "./style";
import {Images as images} from "utils";
import {SimpleModal} from "components";
import {MyProfile} from "../../myProfile";

export const UserInfo = ({ userInfo }) => {
    const classes = navBarStyles();
    const [open, setOpen] = useState(false)

    const handleOpenClose = () => {
        setOpen(!open)
    }

    return (
        <div style={{color: "black"}} className={classes.boxWrapper}>
            <div onClick={handleOpenClose} className={classes.userInfo}>
                <img src={images.userProfile} alt="Avatar"/>
                <p className={classes.userInfoText}>{userInfo ? `${userInfo?.firstName} ${userInfo?.lastName}` : ''}</p>
            </div>

            <div className={'my-profile'}>
                <SimpleModal
                    handleOpenClose={handleOpenClose}
                    backdropCustom={true}
                    openDefault={open}
                    disableScrollLock={true}
                    content={
                        <MyProfile
                            info={userInfo}
                        />
                    }
                />
            </div>
        </div>
    );
};
