import {
  addBracket, deleteBracket,
  editBracket,
  getDefaultBracket,
} from "./brackets.action";

export { bracketReducer } from './brackets.reducer';
export { watchBracket } from './brackets.saga';


export const systemActions = {
  /** Payroll Brackets */
  addBracket,
  editBracket,
  deleteBracket,
  getDefaultBracket,
  /** End */
}


