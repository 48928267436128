import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const receivablesFragments = makeStyles(() => ({
    blueButton: {
        width: '141px',
        height: '32px',
        background: '#438AFE 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    needed: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.TextPrimary,
    },

    sendTo: {
        display: 'flex',
        width: '160px',
        '& img': {
            marginRight: '8px',
        },
    },

    awaiting: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.ThemeRed,
    },

    totalWrapper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '8px',
        height: '72px',
        padding: '0 32px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '20px',
        alignItems:'center',
    },

    totalTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: Colors.TextSecondary,
        marginRight: '30px',
        "@media (min-width: 1919px)": {
            marginRight: '64px',
        },
    },

    totalItemsWrapper: {
        height: '40px',
        borderRadius: '8px',
        padding: '0 16px',
        display: 'flex',
        alignItems:'center',
        gap: '4px'
    },
    title: {
        fontSize: '16px',
        lineHeight: '22px',
        color: Colors.TextSecondary,
    },
    text: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '22px',
        color: Colors.TextSecondary,
    },
}));
