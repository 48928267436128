import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, TableWrapper} from "components";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import { ACTION_TYPE, navigateToLoad, rejectedBody, rejectedHead } from './constants';
import {REJECTED} from "../accounting/constants";

export const Rejected = ({}) => {
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.status = info?.status ? info?.status : REJECTED;
        filteredInfo.limit = 100;
        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        return filteredInfo;
    };

    useEffect(() => {
        handleGet('loading');
        const interval = setInterval(() => {
            handleGet('noLoad');
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad';
        const filteredInfo = renderParams();
        dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, loader));
    };

    const handleSubmit = async (item, name) => {
        dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item.id))
        if (name === 'NOTCONSIGN') {
            try {
                await LoadServices.rejectConsignment(item.id)
                handleGet('noLoad');
                dispatch(httpRequestsOnSuccessActions.appendSuccess('REMOVE_LOAD_SUCCESS'))
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'))
            } catch (e) {
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'))
            }
        }
        if (name === 'CONSIGN') {
            try {
                await LoadServices.requestConsignment(item.id)
                handleGet('noLoad');
                dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_STATUS'))
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'))
            } catch (e) {
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'))
            }
        }
    }

    return (
        <div>
            <TableWrapper
                buttonsTab={true}
                link={true}
                tab={'none'}
                buttonsTabAddButton={false}
            >
                <FullTable
                    head={rejectedHead}
                    body={rejectedBody}
                    loadingType={ACTION_TYPE}
                    list={loadsList?.loads}
                    listCount={loadsList?.count}
                    clickType={'item'}
                    handleClick={(item) => navigateToLoad(item, history, info, '/rejected')}
                    noText={'Credits'}
                    handleClickButton={handleSubmit}
                    dateFilter={true}
                />
            </TableWrapper>
        </div>
    )
}