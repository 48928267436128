import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    customerActions,
    httpRequestsOnSuccessActions,
    loadActions,
    mcActions,
} from "store";
import { ActiveInactive, History, Loader, NoteDrawer, Notes, SimpleModal, SimpleTabs, SlicedText } from 'components';
import {CustomerInfo} from "fragments";
import {
    AntSwitch,
    FindLoad,
    FindSuccessItem,
    Images,
    PermissionsList,
    RenderStatus,
    RolePermission,
    useGlobalStyles,
} from "utils";

export const CustomerInfoPage = () => {
    const {customerById} = useSelector((state) => ({
            customerById: state.customers.customerById,
        })
    )
    const dispatch = useDispatch()
    const params = useParams()
    const loader = FindLoad('GET_CUSTOMER_BY_ID')
    const globalStyle = useGlobalStyles()
    const [switchBoolean, setSwitchBoolean] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [open, setOpen] = useState(false)
    const name = customerName ? customerName : customerById && customerById.name
    const activeInactive = switchBoolean === 'ACTIVE' ? 'Inactivate' : 'Activate'
    const activeSuccess = FindSuccessItem('ACTIVATE_CUSTOMER')
    const inactiveSuccess = FindSuccessItem('INACTIVATE_CUSTOMER')
    const activeLoader = FindLoad('ACTIVATE_CUSTOMER')
    const inactiveLoader = FindLoad('INACTIVATE_CUSTOMER')
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        if (customerById) {
            setSwitchBoolean(customerById.status)
        }
    }, [customerById])

    useEffect(() => {
        dispatch(mcActions.getMcs())
        dispatch(customerActions.getCustomerById(params.id))
        dispatch(loadActions.getCustomerLoads(params.id))
        dispatch(customerActions.getCustomerDebt(params.id))
    }, [])

    useEffect(() => {
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_CUSTOMER'))
            setSwitchBoolean('ACTIVE')
            setOpen(false)
        }
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_CUSTOMER'))
            setSwitchBoolean('INACTIVE')
            setOpen(false)
        }
    }, [activeSuccess, inactiveSuccess])

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSwitch = () => {
        if (switchBoolean === 'ACTIVE') {
            dispatch(customerActions.inactivateCustomer(customerById.id))
        } else {
            dispatch(customerActions.activateCustomer(customerById.id))
        }
    }

    const tabsLabels = [
        {
            label: 'Details'
        },
        {
            label: 'History'
        },
        // {
        //     label: 'Notes'
        // },
    ]

    const tabsContent = [
        {
            tabComponent: <CustomerInfo  setCustomerName={setCustomerName}/>,
            tab: 'Details'
        },
        {
            tabComponent: <History onModel={'customer'} resourceId={params.id}/>,
            tab: 'History'
        },
        // {
        //     tabComponent: <Notes/>,
        //     tab: 'Notes'
        // }
    ];

    if (loader?.length) return <Loader/>

    return (
        <>
            <SimpleTabs
                head={
                    <div className="space-between" style={{ gap: 15 }}>
                        <div className={globalStyle.centerItem}>
                            {RenderStatus(activeInactive)}
                            {RolePermission([PermissionsList.CUSTOMER_STATUS?.code]) &&
                              <AntSwitch
                                onClick={handleOpenClose}
                                checked={switchBoolean === 'ACTIVE'}
                              />
                            }
                        </div>
                        <NoteDrawer
                          title={'Customer'}
                        />
                        <div className={globalStyle.centerItem}>
                            <img style={{ marginRight: '4px' }} src={Images.customerBold} alt={'customer'} />
                            <SlicedText type={'tableName'} size={20} data={name && name} />
                        </div>
                    </div>
                }
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                parentLink={info?.parent ? info?.parent : '/customers'}
                parent={'Customers'}
                child={'Customer Info'}
                defaultTab={'Details'}
                paramsName={'customerParams'}
            />
            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ActiveInactive
                        loading={!!activeLoader?.length || !!inactiveLoader?.length}
                        text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
                        textInfo={activeInactive}
                        name={name}
                        handleClose={handleOpenClose}
                        handleDel={handleSwitch}
                    />
                }
            />
        </>
    )
}