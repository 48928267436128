import React, {useEffect, useState} from "react";
import {useGlobalStyles} from "utils";
import {FullTable, ItemPriceTitle} from "components";
import {collectionsBody, collectionsHead} from "./constants";

export const CollectionsTable = ({info}) => {
    const globalStyle = useGlobalStyles();
    const [total, setTotal] = useState( 0 )

    useEffect(() =>{
        if(info && info.debt){
            let totalAmount = 0
            info.debt.forEach((i) => totalAmount = totalAmount + i.amount)
            setTotal(totalAmount)
        }
    }, [info])

    return (
        <div style={{ marginBottom: '16px'}} className={globalStyle.tableWrapperNoShadow}>
            <div style={{marginBottom:'20px'}} className={globalStyle.spaceBetween}>
                <div/>
                <div>
                    <ItemPriceTitle name={'Total Charges:'} value={`$ ${total}`}/>
                </div>
            </div>
            <div>
                <FullTable
                    head={collectionsHead}
                    body={collectionsBody}
                    loadingType={'GET_PAYMENTS_CARRIER'}
                    list={info?.debt}
                    noText={'Collections'}
                />
            </div>
        </div>
    );
};