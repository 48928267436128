import React from "react";

export const renderIcon = (color) => {
    const fillColor = color ? color : '#438AFE'

    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="write 1" clip-path="url(#clip0_2577_123746)">
            <g id="Group">
                <g id="Group_2">
                    <g id="Group_3">
                        <g id="Group_4">
                            <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M1.86654 1.3667C1.40935 1.3667 1.0332 1.74284 1.0332 2.20003V29.8C1.0332 30.2572 1.40935 30.6334 1.86654 30.6334H21.9332C22.3904 30.6334 22.7665 30.2572 22.7665 29.8V21C22.7665 20.7239 22.9904 20.5 23.2665 20.5C23.5427 20.5 23.7665 20.7239 23.7665 21V29.8C23.7665 30.8095 22.9427 31.6334 21.9332 31.6334H1.86654C0.857061 31.6334 0.0332031 30.8095 0.0332031 29.8V2.20003C0.0332031 1.19056 0.857061 0.366699 1.86654 0.366699H16.9999C17.276 0.366699 17.4999 0.590557 17.4999 0.866699V7.1667H23.2665C23.5427 7.1667 23.7665 7.39056 23.7665 7.6667V13.4667C23.7665 13.7428 23.5427 13.9667 23.2665 13.9667C22.9904 13.9667 22.7665 13.7428 22.7665 13.4667V8.1667H16.9999C16.7237 8.1667 16.4999 7.94284 16.4999 7.6667V1.3667H1.86654Z" fill={fillColor}/>
                            <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd" d="M16.6612 0.498963C16.8642 0.311826 17.1805 0.324737 17.3677 0.5278L23.6343 7.3278C23.8215 7.53086 23.8086 7.84718 23.6055 8.03432C23.4024 8.22146 23.0861 8.20854 22.899 8.00548L16.6323 1.20548C16.4452 1.00242 16.4581 0.686099 16.6612 0.498963Z" fill={fillColor}/>
                        </g>
                        <path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 5.19995C2.6333 4.92381 2.85716 4.69995 3.1333 4.69995H11.1333C11.4094 4.69995 11.6333 4.92381 11.6333 5.19995C11.6333 5.47609 11.4094 5.69995 11.1333 5.69995H3.1333C2.85716 5.69995 2.6333 5.47609 2.6333 5.19995Z" fill={fillColor}/>
                        <path id="Vector (Stroke)_4" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 8.26666C2.6333 7.99052 2.85716 7.76666 3.1333 7.76666H14.3333C14.6094 7.76666 14.8333 7.99052 14.8333 8.26666C14.8333 8.5428 14.6094 8.76666 14.3333 8.76666H3.1333C2.85716 8.76666 2.6333 8.5428 2.6333 8.26666Z" fill={fillColor}/>
                        <path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 11.4C2.6333 11.1238 2.85716 10.9 3.1333 10.9H20.6666C20.9428 10.9 21.1666 11.1238 21.1666 11.4C21.1666 11.6761 20.9428 11.9 20.6666 11.9H3.1333C2.85716 11.9 2.6333 11.6761 2.6333 11.4Z" fill={fillColor}/>
                        <path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 26.8C2.6333 26.5238 2.85716 26.3 3.1333 26.3H11.1333C11.4094 26.3 11.6333 26.5238 11.6333 26.8C11.6333 27.0761 11.4094 27.3 11.1333 27.3H3.1333C2.85716 27.3 2.6333 27.0761 2.6333 26.8Z" fill={fillColor}/>
                        <g id="Group_5">
                            <path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 14.4666C2.6333 14.1905 2.85716 13.9666 3.1333 13.9666H6.9333C7.20944 13.9666 7.4333 14.1905 7.4333 14.4666C7.4333 14.7428 7.20944 14.9666 6.9333 14.9666H3.1333C2.85716 14.9666 2.6333 14.7428 2.6333 14.4666Z" fill={fillColor}/>
                            <path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd" d="M9.1001 14.4666C9.1001 14.1905 9.32396 13.9666 9.6001 13.9666H18.6668C18.9429 13.9666 19.1668 14.1905 19.1668 14.4666C19.1668 14.7428 18.9429 14.9666 18.6668 14.9666H9.6001C9.32396 14.9666 9.1001 14.7428 9.1001 14.4666Z" fill={fillColor}/>
                        </g>
                    </g>
                    <g id="Group_6">
                        <path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 17.5333C2.6333 17.2572 2.85716 17.0333 3.1333 17.0333H14.2C14.4761 17.0333 14.7 17.2572 14.7 17.5333C14.7 17.8095 14.4761 18.0333 14.2 18.0333H3.1333C2.85716 18.0333 2.6333 17.8095 2.6333 17.5333Z" fill={fillColor}/>
                    </g>
                    <g id="Group_7">
                        <path id="Vector (Stroke)_10" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 23.7333C2.6333 23.4571 2.85716 23.2333 3.1333 23.2333H6.9333C7.20944 23.2333 7.4333 23.4571 7.4333 23.7333C7.4333 24.0094 7.20944 24.2333 6.9333 24.2333H3.1333C2.85716 24.2333 2.6333 24.0094 2.6333 23.7333Z" fill={fillColor}/>
                        <path id="Vector (Stroke)_11" fill-rule="evenodd" clip-rule="evenodd" d="M9.1001 23.7333C9.1001 23.4571 9.32396 23.2333 9.6001 23.2333H11.8001C12.0762 23.2333 12.3001 23.4571 12.3001 23.7333C12.3001 24.0094 12.0762 24.2333 11.8001 24.2333H9.6001C9.32396 24.2333 9.1001 24.0094 9.1001 23.7333Z" fill={fillColor}/>
                    </g>
                    <g id="Group_8">
                        <g id="Group_9">
                            <path id="Vector (Stroke)_12" fill-rule="evenodd" clip-rule="evenodd" d="M8.56641 20.6C8.56641 20.3238 8.79026 20.1 9.06641 20.1H13.2664C13.5425 20.1 13.7664 20.3238 13.7664 20.6C13.7664 20.8761 13.5425 21.1 13.2664 21.1H9.06641C8.79026 21.1 8.56641 20.8761 8.56641 20.6Z" fill={fillColor}/>
                        </g>
                        <g id="Group_10">
                            <path id="Vector (Stroke)_13" fill-rule="evenodd" clip-rule="evenodd" d="M2.6333 20.6C2.6333 20.3238 2.85716 20.1 3.1333 20.1H7.3333C7.60944 20.1 7.8333 20.3238 7.8333 20.6C7.8333 20.8761 7.60944 21.1 7.3333 21.1H3.1333C2.85716 21.1 2.6333 20.8761 2.6333 20.6Z" fill={fillColor}/>
                        </g>
                    </g>
                </g>
                <g id="Group_11">
                    <path id="Vector (Stroke)_14" fill-rule="evenodd" clip-rule="evenodd" d="M26.1233 9.96981C26.3188 9.7852 26.6252 9.78822 26.817 9.97664L30.617 13.71C30.7122 13.8035 30.766 13.9311 30.7666 14.0644C30.7672 14.1978 30.7145 14.3259 30.6202 14.4202L29.4202 15.6202C29.2249 15.8155 28.9083 15.8155 28.713 15.6202L24.913 11.8202C24.8175 11.7247 24.7647 11.5946 24.7667 11.4595C24.7686 11.3244 24.8251 11.1959 24.9233 11.1031L26.1233 9.96981ZM25.984 11.4769L29.0666 14.5595L29.5564 14.0698L26.4598 11.0275L25.984 11.4769Z" fill={fillColor}/>
                    <path id="Vector (Stroke)_15" fill-rule="evenodd" clip-rule="evenodd" d="M30.3688 10.2086C29.5725 9.35921 28.2318 9.34226 27.3538 10.2202L27.3528 10.2212L15.6417 21.8676L18.6679 24.8939L30.381 13.2455C30.3845 13.242 30.388 13.2386 30.3916 13.2352C31.241 12.4389 31.258 11.0982 30.38 10.2202C30.3782 10.2184 30.3765 10.2166 30.3747 10.2148C30.3727 10.2127 30.3708 10.2107 30.3688 10.2086ZM31.0928 9.51879C29.8888 8.24102 27.9008 8.25963 26.6473 9.51252C26.6471 9.51271 26.6469 9.5129 26.6467 9.5131L14.581 21.5121C14.4868 21.6058 14.4338 21.7331 14.4336 21.866C14.4334 21.9988 14.4861 22.1263 14.58 22.2202L18.3134 25.9535C18.5083 26.1484 18.8241 26.1489 19.0195 25.9545L31.0812 13.9595C32.3575 12.7571 32.3408 10.7727 31.0928 9.51879Z" fill={fillColor}/>
                    <path id="Vector (Stroke)_16" fill-rule="evenodd" clip-rule="evenodd" d="M26.2871 14.2464C26.4824 14.4417 26.4824 14.7583 26.2871 14.9535L18.4204 22.8202C18.2252 23.0155 17.9086 23.0155 17.7133 22.8202C17.5181 22.6249 17.5181 22.3084 17.7133 22.1131L25.58 14.2464C25.7753 14.0512 26.0919 14.0512 26.2871 14.2464Z" fill={fillColor}/>
                    <path id="Vector (Stroke)_17" fill-rule="evenodd" clip-rule="evenodd" d="M15.0516 21.3808C15.3199 21.4461 15.4845 21.7165 15.4192 21.9848L14.4134 26.12L18.5486 25.1141C18.8169 25.0489 19.0873 25.2135 19.1526 25.4818C19.2178 25.7501 19.0532 26.0205 18.7849 26.0858L13.8516 27.2858C13.682 27.327 13.5032 27.2769 13.3799 27.1535C13.2565 27.0301 13.2063 26.8513 13.2476 26.6818L14.4476 21.7485C14.5128 21.4801 14.7833 21.3155 15.0516 21.3808Z" fill={fillColor}/>
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_2577_123746">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}