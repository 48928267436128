import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrMessage, Line, SelectTypeAutocomplete, ValidationInput} from "components";
import {ErrorText, useGlobalStyles, userType} from "utils";
import {loadActions} from "store";

export const CustomerCredit = ({handleChange, inputs, disables, error, backError,}) => {
    const {mcsList, customers, customerById, assignedAgentMc} = useSelector((state) => ({
        mcsList: state.mcs.mcsList,
        customers: state.loads.customers,
        customerById: state.customers.customerById,
        assignedAgentMc: state.mcs.assignedAgentMc,
    }));
    const globalStyles = useGlobalStyles();
    const dispatch = useDispatch();

    const handle = (e) => {
        if (e.target?.value?.length > 3) {
            setTimeout(() => {
                dispatch(loadActions.searchList(e.target.value))
            }, 500)
        }
    }

    const list =
        customers?.customers?.length ?
            customers?.customers :
            [
                {
                    name: customerById?.name,
                    id: customerById?.id,
                    mc: customerById?.mc,
                }
            ]

    return (
        <div className='full-width-flex-able'>
            <div className={'MuiAccordionDetails-root'}>
                <Line height={'100px'}/>
            </div>
            <div className='load-row-wrapper'>
                {mcsList?.mcs?.length || assignedAgentMc?.length ?
                    <SelectTypeAutocomplete
                        style={globalStyles.simpleInput}
                        name={'bookedAsMC'}
                        label={'name'}
                        type={'mcNumber'}
                        title={'Booked As Authority*'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.bookedAsMC}
                        list={
                            userType === 'ADMIN' ? mcsList?.mcs?.filter((i) => i?.authorityType !== "DEFAULT") :
                                assignedAgentMc?.length ? assignedAgentMc : []
                        }
                        disabled={disables}
                        typeError={
                            error === 'bookedAsMC' ? ErrorText.field :
                                error === 'notAssigned' ? 'The MC is not assigned to the agent' :
                                    ''}
                        error={error === 'bookedAsMC' && 'bookedAsMC' || error === 'notAssigned' ? 'bookedAsMC' : ''}
                    />
                    :
                    <SelectTypeAutocomplete
                        style={globalStyles.loadSimpleInput}
                        title={'Booked As Authority*'}
                        list={[]}
                        disabled={disables}
                        error={error}
                    />
                }
                {customers?.customers || customerById ?
                    <SelectTypeAutocomplete
                        style={globalStyles.simpleInput}
                        loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
                        name={'customer'}
                        label={'name'}
                        type={'mc'}
                        title={'Customer (Name/MC)*'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.customer}
                        list={
                            list
                            // info ? selectedCustomer : customers?.length ? customers : []
                        }
                        handleType={handle}
                        disabled={disables}
                        typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
                        error={error === "customer" ? 'customer' :
                            backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
                        }
                    />
                    :
                    <SelectTypeAutocomplete
                        style={globalStyles.loadSimpleInput}
                        title={'Customer (Name/MC)*'}
                        list={[]}
                        disabled={disables}
                        error={error}
                        handleType={handle}
                    />
                }
                <ValidationInput
                    className={inputs.customerRate && globalStyles.inputTextFieldBlue}
                    variant={"outlined"}
                    name={"customerRate"}
                    label={"Customer Rate*"}
                    type={'number'}
                    typeError={
                        error === 'customerRate' ? ErrorText.field : ''}
                    onChange={handleChange}
                    value={inputs.customerRate}
                    disabled={disables}
                />
                {backError && <ErrMessage text={backError}/>}
            </div>
        </div>
    )
}