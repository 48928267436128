import axios from "axios";

export const authService = {
    /** Templates */

    createTemplateService: (body) => axios.post('/template', body, {auth: true}),

    editTemplateService: (id, body) => axios.patch(`/template/${id}`, body, {auth: true}),

    deleteTemplatesService: (id) => axios.delete(`/template/${id}`, { auth: true}),

    getTemplatesService: (params) => axios.get('/template', {params: {...params}, auth: true}),

    getTemplateByIdService: (id) => axios.get(`/template/${id}`, { auth: true}),



    createLoadStops: (id, info) => axios.post(`/template/${id}/stops`, info, {auth: true}),

    editLoadStops: (id, dataId, info) => axios.patch(`/template/${id}/stops/${dataId}`, info, {auth: true}),

    deleteLoadStops: (id, dataId, info) => axios.delete(`/template/${id}/${dataId}`, info, {auth: true}),

    /** End */
};
