import React, {useEffect, useState} from "react";
import {createInputsWrapper, ErrorText, FindError, useGlobalStyles,} from "utils";
import {ErrMessage, Line, SelectTypeAutocomplete, ValidationInput,} from "components";
import {createLoadStyle} from "./styles";
import {useSelector} from "react-redux";

export const RequestACarrier = ({info, disables, handleChange, inputs, error, setInputs}) => {
    const {carriersList} = useSelector((state) => ({
        carriersList: state.carriers.carriersList,
    }));
    const globalScreens = createInputsWrapper();
    const globalStyles = useGlobalStyles();
    const classes = createLoadStyle();
    const err = FindError('NOT_CARRIER')
    const [carrierFiltered, setCarrierFiltered] = useState([])

    useEffect(() => {
        if (carriersList?.carriers?.length) {
            const newList = carriersList?.carriers?.filter((i) => i?.doNotLoad === false && (i?.rmisRequired ? i?.rmisStatus === "ACTIVE" : true))
            setCarrierFiltered(newList)
        }
    }, [carriersList])

    // useEffect(() => {
    //     if (disables && info?.carrier?.id) {
    //         const carrierInfo = {
    //             carrierId: info.carrier.id,
    //             rate: info.carrierRate,
    //             instruction: info.carrierInstruction,
    //         }
    //         info?.carrierReference ? carrierInfo.carrierReference = info?.carrierReference : ''
    //         setInputs(carrierInfo)
    //     }
    // }, [info])








    // const currentCarrier = [
    //     {
    //         id: info?.carrier?.id,
    //         companyName: info?.carrier?.name,
    //         docketNumber: info?.carrier?.mc,
    //     }
    // ]

    // const handleChange = e => {
    //     if (e.target.name === 'rate') {
    //         if (+e.target.value >= 0) {
    //             setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),)
    //         }
    //     } else {
    //         setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),)
    //     }
    //     error === e.target.name && setError('')
    //     error === 'noLoadSub' && setError('')
    //     err.length && dispatch(httpRequestsOnErrorsActions.removeError('NOT_CARRIER'))
    // };

    // const handleCreate = async () => {
    //     if (inputs.carrierId && inputs.rate) {
    //         const list = {...inputs}
    //         list.rate = +inputs.rate
    //         setLoader(true)
    //         try {
    //             await LoadServices.AssignCarrier(id, list)
    //             dispatch(loadActions.getLoadById(id, 'noLoad'))
    //             dispatch(httpRequestsOnSuccessActions.appendSuccess('REQUEST_CARRIER'))
    //             setLoader(false)
    //             handleChangeScreen()
    //             setDisabled(true)
    //         } catch (e) {
    //             setLoader(false)
    //         }
    //     } else {
    //         setError(
    //             !inputs.carrierId ? 'carrierId' :
    //                 !inputs.rate ? 'rate' :
    //                     ''
    //         )
    //     }
    // }

    // const handleAwait = async () => {
    //     setLoadAwait(true)
    //     try {
    //         await LoadServices.AwaitCarrier(id)
    //         dispatch(loadActions.getLoadById(id, 'noLoad'))
    //         dispatch(httpRequestsOnSuccessActions.appendSuccess('AWAIT_CARRIER'))
    //         setLoadAwait(false)
    //     } catch (e) {
    //         setLoadAwait(false)
    //     }
    // }


    return (
        <div className='full-width-flex-able'>
            <div className={'MuiAccordionDetails-root'}>
                <Line height={'250px'}/>
            </div>
                <div className='load-row-wrapper' style={{marginTop:'12px'}}>
                    <div className={globalScreens.basicInfoInputs} style={{width:'100%'}}>
                        <div className={classes.basicInfoInputsWrapper}>
                            {carrierFiltered?.length ?
                                <SelectTypeAutocomplete
                                    loadType={'GET_CARRIERS'}
                                    style={globalStyles.simpleInput}
                                    name={'carrier'}
                                    label={'companyName'}
                                    type={'docketNumber'}
                                    title={'Carrier/MC*'}
                                    handleSelect={handleChange}
                                    defaultValue={inputs.carrier}
                                    list={carrierFiltered}
                                    disabled={disables}
                                    error={error}
                                />
                                :
                                <SelectTypeAutocomplete
                                    style={globalStyles.simpleInput}
                                    title={'Carrier/MC*'}
                                    list={[]}
                                    disabled={disables}
                                    error={error}
                                />
                            }
                            <div className={classes.carrierRate}>
                                <ValidationInput
                                    style={globalStyles.simpleInput}
                                    className={inputs.carrierRate && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"carrierRate"}
                                    label={"Rate*"}
                                    type={'number'}
                                    typeError={error === 'carrierRate' && ErrorText.field}
                                    onChange={handleChange}
                                    value={inputs.carrierRate}
                                    disabled={disables}
                                />
                            </div>

                            <ValidationInput
                                style={globalStyles.simpleInput}
                                className={inputs.carrierReference && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"carrierReference"}
                                label={"Carrier Reference"}
                                type={'text'}
                                typeError={error === 'carrierReference' && ErrorText.field}
                                onChange={handleChange}
                                value={inputs?.carrierReference}
                                disabled={disables}
                            />

                        </div>
                        <div style={{width: '50%'}}>
                            <ValidationInput
                                style={globalStyles.simpleInput}
                                className={inputs.carrierInstruction ? classes.inputTextFieldBlue : classes.inputTextField}
                                variant={"outlined"}
                                name={"carrierInstruction"}
                                label={"Specific Instruction"}
                                type={'text'}
                                multiline={true}
                                Length={200}
                                typeError={error === 'carrierInstruction' && ErrorText.field}
                                onChange={handleChange}
                                value={inputs.carrierInstruction}
                                disabled={disables}
                            />
                            <div className={classes.maxCharacters}>
                                <p>Max 200 characters</p>
                            </div>
                        </div>
                    </div>
                    {err && err.length && error === 'noLoadSub' ?
                        <ErrMessage type={"Pass"} text={'Please add carrier'}/> :
                        ''
                    }
            </div>
        </div>
    )
}