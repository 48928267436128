import {GET_QUICKBOOKS_SUCCESS, GET_TERMS_SUCCESS} from "./terms.types";

const initialState = {
    term: null,
    quickbook: null,
};

export const termsReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Mcs */
        case GET_TERMS_SUCCESS:
            return {
                ...state,
                term: action.payload
            }
        /** End */

        /** Quickbooks */
        case GET_QUICKBOOKS_SUCCESS:
            return {
                ...state,
                quickbook: action.payload
            }
        /** End */

        default:
            return state;
    }
};
