import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    CheckboxesTags,
    DeleteButton,
    DeleteElement,
    EditSaveButtons,
    Line,
    Management, NoYet,
    SimpleModal
} from "components";
import {FindLoad, FindSuccessItem, useGlobalStyles} from "utils";
import {agentsInfoFragments} from "./styles";
import {httpRequestsOnSuccessActions, mcActions} from "store";

export const Authorities = ({info}) => {
    const {assignedAgentMc, assignedBranchMc} = useSelector((state) => ({
        assignedBranchMc: state.mcs.assignedBranchMc,
        assignedAgentMc: state.mcs.assignedAgentMc,
    }));
    const classes = agentsInfoFragments()
    const globalClass = useGlobalStyles()
    const params = useParams()
    const dispatch = useDispatch()
    const [disables, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)
    const [role, setRole] = useState('')
    const [item, setItem] = useState('')
    const success = FindSuccessItem('DELETE_ASSIGNED_MC')
    const loader = FindLoad('DELETE_ASSIGNED_MC')

    const deleteRole = () => {
        const officeId = params?.id
        const mcId = role?.id
        dispatch(mcActions.deleteAssignedMs(officeId, mcId, 'AGENT'))
    }

    useEffect(() => {
        if (info && info.branchId) {
            dispatch(mcActions.getAssignedMcs(info && info.branchId, 'BRANCH'))
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            setOpen(!open)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_ASSIGNED_MC'))
        }
    }, [success])

    const handleCreate = () => {
        setDisabled(!disables)
    }

    const handleOpenClose = (id) => {
        setOpen(!open)
        setRole(id)
    }

    const addMc = (mc) => {
        if (mc.length) {
            const agent = params.id
            const mcId = mc[mc.length - 1].id
            dispatch(mcActions.assignMs(agent, mcId, 'AGENT'))
        }
    }

    const newList = assignedBranchMc.filter(function (array_el) {
        return assignedAgentMc.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    return (
        <div className={classes.authoritiesWrapper}>
            <Line height={'375px'}/>

            <div className={classes.itemsWrapper}>
                <Management
                    type={'access'}
                    head={
                        <div>
                            <div className={classes.authoritiesHead}>
                                <div className={classes.tittle}>
                                    <p>Agent Authorities Management</p>
                                </div>
                                <EditSaveButtons
                                    butt={'done'}
                                    type={'EDIT_ADMIN'}
                                    handleChancel={() => setDisabled(true)}
                                    handleSetEdit={() => setDisabled(false)}
                                    handleSaveInfo={handleCreate}
                                />
                            </div>
                            <div className={classes.officeSelectInput}>
                                <CheckboxesTags
                                    type={'mc'}
                                    handleChange={addMc}

                                    permissionsList={newList && newList.length ? newList : []}
                                    // permissionsList={assignedBranchMc && assignedBranchMc.length ? assignedBranchMc : []}
                                    label={"Add Authorities"}
                                    // placeholder={'Add mccc'}
                                />
                            </div>
                        </div>
                    }
                    body={
                        <>

                            <div className={classes.accessBody}>
                                <div className={classes.fullWidthWrapper} >
                                    {assignedAgentMc.length ? assignedAgentMc.map((i, j) => (
                                            <div key={j}
                                                 className={item === '1' ? globalClass.activeItem : globalClass.item}>
                                                <p>{i.name} - <span style={{fontWeight: 'bold'}}>{i.mcNumber}</span></p>
                                                <div>
                                                    {!disables &&
                                                        <DeleteButton
                                                            toolTipTitle={"Remove Role"}
                                                            handleClick={() => handleOpenClose(i)}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )) :
                                        <NoYet position={'center'} text={'No Authorities Yet'}/>
                                    }
                                </div>
                            </div>
                        </>
                    }
                />
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader?.length}
                        text={'Remove Mc?'}
                        handleClose={handleOpenClose}
                        handleDel={deleteRole}
                        info={role?.name}
                    />
                }
            />
        </div>
    )
}
