import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {EditSaveButtons} from "components";
import RichTextEditorEdit from "./textEditor";
import {httpRequestsOnSuccessActions, termActions} from "store";
import {FindLoad, FindSuccessItem, PermissionsList, RolePermission} from "utils";
import {Paper, Tab, Tabs} from "@material-ui/core";
import {buttonsStyle} from "../../../components/buttons/styles";

export const McConfigurations = () => {
    const {term} = useSelector((state) => ({
        term: state.terms.term
    }))
    const classes = buttonsStyle();
    const dispatch = useDispatch()
    const loader = FindLoad('CREATE_TERM')
    const success = FindSuccessItem('CREATE_TERM')
    const forEdit = FindSuccessItem('CREATE_TERM_EDIT')
    const [text, setText] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [type, setType] = useState('tcDefault')

    useEffect(() => {
        if (term) {
            setText(term[type] ? term[type] : '')
        }
    }, [term])

    useEffect(() => {
        if (success) {
            setDisabled(true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CREATE_TERM'))
        }
        if (forEdit) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CREATE_TERM_EDIT'))
        }
    }, [success, forEdit])

    const handleCreate = () => {
        const info = {
            tcDefault: type === 'tcDefault' ? text : term?.tcDefault,
            tcBroker: type === 'tcBroker' ? text : term?.tcBroker,
        }
        dispatch(termActions.createTerm(info))
    }

    const handleChangeType = (mcType) => {
        setType(mcType)
        dispatch(httpRequestsOnSuccessActions.appendSuccess('CREATE_TERM_EDIT'))

        if (term) {
            setText(term[mcType] ? term[mcType] : '')
        }else{
            setText('')
        }
    }

    return (
        <div className='system-page'>
            <Paper
                style={{
                    width: "242px",
                    border: 'none',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 0px 6px #8A8A8A29',
                    borderRadius: '18px',
                }}
                square
            >
                <Tabs
                    className={classes.buttonsTab}
                    value={type}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="disabled tabs example"
                >
                    <Tab style={{width: 117}} onClick={() => handleChangeType('tcDefault')} value='tcDefault'
                         label={'Default'}/>
                    <Tab style={{width: 117}} onClick={() => handleChangeType('tcBroker')} value='tcBroker'
                         label={'Broker'}/>
                </Tabs>
            </Paper>

            <div className='title-and-button'>
                <p className='details-title'>Terms And Conditions</p>
                {RolePermission([PermissionsList.CREATE_UPDATE_MY_CONF?.code]) &&
                    <EditSaveButtons
                        type={'CREATE_TERM'}
                        loadLoading={!!loader.length}
                        handleChancel={() => setDisabled(true)}
                        handleSetEdit={() => setDisabled(false)}
                        handleSaveInfo={handleCreate}
                    />
                }
            </div>
            <RichTextEditorEdit
                success={success}
                forEdit={forEdit}
                disabled={disabled}
                type={'org'}
                text={text}
                onChange={(ev) => setText(ev)}
            />
        </div>
    )
}