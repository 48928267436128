import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {authActions, equipmentActions, httpRequestsOnSuccessActions} from 'store';
import {CheckboxesTags, FullTable, SelectTypeAutocomplete, ValidationInput} from 'components';
import {ACTION_TYPE, truckBoardBody, truckBoardHead} from './fragments/constants';
import { Images, SavePage, Status, useGlobalStyles, useModal, userType } from 'utils';
import {TruckModal} from './fragments/modals';
import {equipmentType} from '../carriers/fragments/equipments/core/addEquipmentModal';
import {Popover} from '@material-ui/core';
import WidgetTree from "./fragments/modals/checkboxTree";
import {zones} from "../carriers/fragments/equipments/core/zonesAndStates";
import {MultipleSelect} from "../../components/inputs/autocompleteSelectInput";
export const Truck = ({}) => {
    const {equipmentList, myProfile} = useSelector((state) => ({
        equipmentList: state.equipment.equipmentList,
        myProfile: state.auth.myProfile,
    }));
    const {open} = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const ref = useRef();
    const searches = localStorage.getItem('searches') ? JSON.parse(localStorage.getItem('searches')) : [];
    const [searchName, setSearchName] = useState('');
    const [addSearch, setAddSearch] = useState(false);
    const [currentSearch, setCurrentSearch] = useState({});
    const [inputs, setInputs] = useState({});
    const [selectedDestination, setSelectedDestination] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState([]);
    const globalStyles = useGlobalStyles();
    const [editSelected, setEditSelected] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchor, setAnchor] = React.useState(null);
    const [anchorBase, setAnchorBase] = React.useState(null);
    const openDes = Boolean(anchor);
    const idDes = openDes ? 'simple-popper' : undefined;
    const openBase = Boolean(anchorBase);
    const idBase = openBase ? 'simple-popper' : undefined;
    const openPopper = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickDes = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchor(null);
    };

    useEffect(() => {
        if (!searches?.length) {
            localStorage.setItem('searches', JSON.stringify([{
                name: 'Default',
                id: 0,
            }, ...searches]));
        }
    }, []);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const renderParams = () => {
        const sendParams = {
            ...info,
            ...currentSearch,
            status: Status.active,
        };
        sendParams.skip = info?.skip ? info?.skip : 0;
        sendParams.limit = 100;
        sendParams.favorites = inputs?.favorites ? inputs?.favorites : false;
        sendParams.assigned = inputs?.assigned ? inputs?.assigned : false;

        inputs?.availableDate ? sendParams.availableDate = inputs?.availableDate : delete sendParams.availableDate;
        // inputs?.equipmentType?.length ? sendParams.equipmentType = inputs?.equipmentType : delete sendParams.equipmentType;


        let arr = []
        if (inputs?.equipmentType?.length) {
            inputs?.equipmentType?.map((i) => (
                arr.push(i?.id)
            ))
            sendParams.equipmentType = arr
            arr = []
        } else {
            delete sendParams.equipmentType
        }

        delete sendParams.page;
        delete sendParams.activeTab;
        delete sendParams.carrierParams;
        delete sendParams.id;
        delete sendParams.name;

        let newArr = [];

        if (selectedDestination?.length) {
            selectedDestination?.map((i) => {
                newArr.push(i);
            });
            sendParams.destinations = newArr;
        } else {
            delete sendParams.destinations;
        }

        if (selectedOrigin?.length) {
            selectedOrigin?.map((i) => {
                newArr.push(i);
            });
            sendParams.originState = newArr;
        } else {
            delete sendParams.originState;
        }

        newArr = [];
        return sendParams;
    };

    useEffect(() => {
        dispatch(authActions.getMyProfile());
        handleGetEquipments();
    }, [dispatch, info]);

    const openModal = (item) => {
        open(<TruckModal item={item} activeTab={'xxx'}/>);
    };

    const handleAction = (e, name) => {
        if (name === 'edit') {
            history.push(`/equipment/${e?.id}/carrier/${e?.carrierId}`);
        }
        if (name === 'set') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', e?.id));
            dispatch(equipmentActions.setFavorite(e?.carrierId, renderParams()));
        }
        if (name === 'unset') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', e?.id));
            dispatch(equipmentActions.unsetFavorite(e?.carrierId, renderParams()));
        }
    };

    const handleGetEquipments = (params) => {
        const sendParams = params ? params : renderParams();
        dispatch(equipmentActions.getEquipments(sendParams));
    };

    const handleChange = (e) => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleChangeDestination = (item) => {
        setSelectedDestination(item);
    };

    const handleChangeOrigin = (item) => {
        setSelectedOrigin(item);
    };

    const handleSave = () => {
        if (searchName) {
            localStorage.setItem('searches', JSON.stringify([...searches, {
                name: searchName,
                id: searches?.length ? searches?.length + 1 : 1,
            },]));
            setAddSearch(false);
            setSearchName('');
        } else {
            ref.current.focus();
        }
    };
    const handleSelectSearch = (e, item) => {
        setCurrentSearch(item);
        const params = {};
        item?.availableDate ? params.availableDate = item?.availableDate : '';
        item?.pickUp ? params.pickUp = item?.pickUp : '';
        item?.dropOff ? params.dropOff = item?.dropOff : '';
        item?.equipmentType?.length ? params.equipmentType = item?.equipmentType : '';
        params.assigned = item?.assigned ? item?.assigned : false;
        params.favorites = item?.favorites ? item?.favorites : false;
        setSelectedDestination(item?.destinations ? params.destinations = item?.destinations : []);
        setSelectedOrigin(item?.originState ? params.originState = item?.originState : []);
        setInputs(params);

        const sendParams = {
            ...params,
        };

        let newArr = [];

        if (item?.destinations?.length) {
            item?.destinations?.map((i) => {
                newArr.push(i);
            });
            sendParams.destinations = newArr;
        } else {
            delete sendParams.destinations;
        }


        let arr = []
        if (item?.equipmentType?.length) {
            item?.equipmentType?.map((i) => (
                arr.push(i?.id)
            ))
            sendParams.equipmentType = arr
            arr = []
        } else {
            delete sendParams.equipmentType
        }
        newArr = [];

        sendParams.skip = 0;
        sendParams.limit = 100;
        sendParams.status = Status.active;

        SavePage(history, info, {
            skip : 0,
            limit : 100,
            page: 1
        })

        handleGetEquipments({...sendParams});
    };

    const handleSearchAction = () => {
        if (currentSearch) {
            let newArr = [];
            searches?.filter((i) => {
                    if (i?.id === currentSearch?.id) {
                        newArr.push({
                            ...i, ...inputs,
                            destinations: selectedDestination,
                            originState: selectedOrigin,
                        });
                    } else {
                        newArr.push(i);
                    }
                },
            );
            localStorage.setItem('searches', JSON.stringify(newArr));
            newArr = [];
        }
        SavePage(history, info, {
            skip : 0,
            limit : 100,
            page: 1
        })
        // handleGetEquipments();
    };

    const handleAssign = () => {
        const inputParams = {
            ...inputs,
            assigned: inputs?.assigned !== true,
            favorites: false,
        };
        setInputs(inputParams);
        if (currentSearch) {
            let newArr = [];
            searches?.filter((i) => {
                    if (i?.id === currentSearch?.id) {
                        let params = {
                            ...i,
                            ...inputs,
                            favorites: false,
                        };
                        params.assigned = inputs?.assigned !== true;
                        newArr.push(params);
                    } else {
                        newArr.push(i);
                    }
                },
            );
            localStorage.setItem('searches', JSON.stringify(newArr));
            newArr = [];
        }
        const sendParams = {
            ...renderParams(),
        };
        sendParams.assigned = inputs?.assigned !== true;
        sendParams.favorites = false;
        handleGetEquipments(sendParams);
    };

    const handleFavorite = () => {
        const inputParams = {
            ...inputs,
            favorites: inputs?.favorites !== true,
            assigned: false,
        };
        setInputs(inputParams);
        if (currentSearch) {
            let newArr = [];
            searches?.filter((i) => {
                    if (i?.id === currentSearch?.id) {
                        let params = {
                            ...i,
                            ...inputs,
                            assigned: false,
                        };
                        params.favorites = inputs?.favorites !== true;
                        newArr.push(params);
                    } else {
                        newArr.push(i);
                    }
                },
            );
            localStorage.setItem('searches', JSON.stringify(newArr));
            newArr = [];
        }
        const sendParams = {
            ...renderParams(),
        };
        sendParams.favorites = inputs?.favorites !== true;
        sendParams.assigned = false;
        handleGetEquipments(sendParams);
    };

    const deleteSelected = () => {
        let newArr = searches?.filter((i) => i?.id !== currentSearch?.id);
        localStorage.setItem('searches', JSON.stringify(newArr));
        handleClose();
    };

    const handleEdit = () => {
        setEditSelected(true);
        setSearchName(currentSearch?.name);
    };

    const handleCLickIcon = (e) => {
        handleClick(e);
        setEditSelected(false);
        setSearchName('');
    };

    const handleSaveCurrent = () => {
        if (searchName) {
            const newList = searches?.filter((i) => i?.id !== currentSearch?.id);
            const changedSearch = {
                ...currentSearch,
            };
            changedSearch.name = searchName;
            localStorage.setItem('searches', JSON.stringify([...newList, changedSearch,]));
            handleClose();
            setEditSelected(false);
            setSearchName('');
        } else {
            ref.current.focus();
        }
    };

    const translateTree = (data, type) => {

        const newTree = [];
        for (let i = 0; i < data.length; i++) {

            let children;
            if (data[i].zonesList?.length > 0) {
                children = translateTree(data[i].zonesList, type);
            }
            let label;

            label = `${data[i].zoneName ? data[i].zoneName : data[i]} `;

            newTree.push({
                value: data[i].zoneName ? data[i].zoneName : data[i],
                label,
                children,
            });

        }
        return newTree;
    };

    const handleAddType = (list) => {
        setInputs(prevState => ({...prevState, ['equipmentType']: list}));
    }

    return (
        <div>
            <div>
                <div className='truck-search-wrapper'>
                    {searches?.length ? searches?.map((i, j) => (
                            editSelected && i?.id === currentSearch?.id ?
                                <div className='add-search-wrapper'>
                                    <input
                                        type='text' onChange={(e) => setSearchName(e.target.value)}
                                        ref={ref}
                                        value={searchName}
                                    />
                                    <div className='buttons-wrapper'>
                                        <button className='check-btn' onClick={handleSaveCurrent}>
                                            <img src={Images.checkGreen} alt='icon'/>
                                        </button>
                                        <button onClick={() => {
                                            setEditSelected(false);
                                            setSearchName('');
                                        }}>
                                            <img src={Images.clear} alt='icon'/>
                                        </button>
                                    </div>
                                </div>
                                :
                                <button
                                    aria-describedby={id}
                                    key={j}
                                    className={currentSearch?.id === i?.id ? 'truck-search-active-btn' : 'truck-search-btn'}
                                    onClick={(e) => handleSelectSearch(e, i)}
                                >
                                    <p> {i?.name}</p>
                                    <img
                                        onClick={(e) => handleCLickIcon(e)}
                                        src={currentSearch?.id === i?.id ? Images.upWhite : Images.downBlue}
                                        alt='icon'
                                    />
                                </button>
                        ))
                        :
                        ''
                    }
                    {addSearch &&
                        <div className='add-search-wrapper'>
                            <input type='text' onChange={(e) => setSearchName(e.target.value)} ref={ref}/>
                            <div className='buttons-wrapper'>
                                <button className='check-btn' onClick={handleSave}>
                                    <img src={Images.checkGreen} alt='icon'/>
                                </button>
                                <button onClick={() => setAddSearch(false)}>
                                    <img src={Images.clear} alt='icon'/>
                                </button>
                            </div>
                        </div>
                    }
                    <button className='add-new-search' onClick={() => setAddSearch(true)}>
                        <img src={Images.pluseIcon} alt='icon'/>
                        <p>Add New Search</p>
                    </button>
                </div>

                <div className='search-options-wrapper'>
                    <div className='search-inputs-wrapper'>
                        <div style={{marginRight: '8px'}}>
                            <ValidationInput
                                className={inputs.availableDate && globalStyles.inputTextFieldBlue}
                                noErrMessage={true}
                                onChange={handleChange}
                                value={inputs.availableDate}
                                label={'Available Date'}
                                type={'date'}
                                variant={'outlined'}
                                name={'availableDate'}
                            />
                        </div>
                        <div className='checkbox-tree'>
                            <div className='destination-select-box'
                                 onClick={(event) => setAnchorBase(anchorBase ? null : event.currentTarget)}>
                                <input
                                    readOnly
                                    type='text'
                                    placeholder={selectedOrigin?.length ? `${selectedOrigin[0]} +${selectedOrigin?.length}` : 'Origin Base'}/>
                                {!!selectedOrigin?.length &&
                                    <button
                                        className='remove-btn'
                                        onClick={() => setSelectedOrigin([])}>
                                        <img src={Images.CloseIcon} alt='iocn'/>
                                    </button>
                                }
                                <img src={Images.dropdownArrow} alt='icon'/>
                            </div>
                            <Popover
                                onClose={() => setAnchorBase(null)}
                                id={idBase}
                                open={openBase}
                                anchorEl={anchorBase}
                                style={{zIndex: '9999', marginTop: '52px'}}
                            >
                                <div className='destination-list'>
                                    <WidgetTree
                                        orgCateg={translateTree(zones, 'type')}
                                        handleNode={handleChangeOrigin}
                                        selectedId={selectedOrigin}
                                    />
                                </div>
                            </Popover>
                        </div>
                        <div className='checkbox-tree'>
                            <div className='destination-select-box' onClick={handleClickDes}>
                                <input
                                    readOnly
                                    type='text'
                                    placeholder={selectedDestination?.length ? `${selectedDestination[0]} +${selectedDestination?.length}` : 'Destination'}/>
                                {!!selectedDestination?.length &&
                                    <button
                                        className='remove-btn'
                                        onClick={() => setSelectedDestination([])}>
                                        <img src={Images.CloseIcon} alt='iocn'/>
                                    </button>
                                }
                                <img src={Images.dropdownArrow} alt='icon'/>
                            </div>
                            <Popover
                                onClose={handleCloseAnchor}
                                id={idDes}
                                open={openDes}
                                anchorEl={anchor}
                                style={{zIndex: '9999', marginTop: '52px'}}
                            >
                                <div className='destination-list'>
                                    <WidgetTree
                                        orgCateg={translateTree(zones, 'type')}
                                        handleNode={handleChangeDestination}
                                        selectedId={selectedDestination}
                                    />
                                </div>
                            </Popover>
                        </div>
                        <MultipleSelect
                            selectedList={inputs?.equipmentType}
                            handleChange={handleAddType}
                            permissionsList={equipmentType}
                            label={"Equipment Type"}
                            renderName={(item) => item?.name}
                        />
                        <button onClick={handleSearchAction} className='search-btn'>
                            <img src={Images.searchIconWhite} alt='icon'/>
                            <p>Search</p>
                        </button>
                    </div>
                    {userType === 'AGENT' &&
                        <div className='assigned-Favorites-btn'>
                            <button
                                onClick={() => handleAssign()}
                                className={inputs?.assigned ? 'assigned-and-favorite-active-btn' : 'assigned-and-favorite-btn'}>Assigned
                            </button>
                            <button
                                onClick={() => handleFavorite()}
                                className={inputs?.favorites ? 'assigned-and-favorite-active-btn' : 'assigned-and-favorite-btn'}>Favorites
                            </button>
                        </div>
                    }
                </div>
            </div>
            <FullTable
                head={truckBoardHead}
                body={truckBoardBody(open, equipmentList, myProfile)}
                loadingType={ACTION_TYPE}
                list={equipmentList?.items}
                listCount={equipmentList?.count}
                handleClick={openModal}
                clickType={'item'}
                handleClickButton={(e, name) => handleAction(e, name)}
                noText={'Items'}
            />
            <Popover
                id={id}
                open={openPopper}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='more-actions-wrapper'>
                    <button
                        onClick={handleEdit}
                        className='action-edit-btn'>
                        <img src={Images.edit} alt='icon'/>
                        <p>Rename</p>
                    </button>
                    {currentSearch?.id !== 0 &&
                        <button
                            onClick={deleteSelected}
                            className='action-delete-btn'
                        >
                            <img src={Images.trash} alt='icon'/>
                            <p>Delete</p>
                        </button>
                    }
                </div>
            </Popover>
        </div>
    );
};