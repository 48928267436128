import { call, put, takeLatest } from "redux-saga/effects";

import {GET_HISTORY, GET_HISTORY_SUCCESS} from "./history.types";
import {authService as historyService} from "./history.service";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";

function* getHistory(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  if(action.payload.type === 'search'){
    yield put(httpRequestsOnLoadActions.appendLoading('SEARCH_HISTORY'));
  }else{
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  }

  try {
    const res = yield call(
        historyService.getHistoryService,
        action.payload.onModel,
        action.payload.queryParams
    );
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading('SEARCH_HISTORY'));
    yield put({
      type: GET_HISTORY_SUCCESS,
      payload: { history: res.data },
    });
  } catch (err) {
    yield put({
      type: GET_HISTORY_SUCCESS,
      payload: { history: [] },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading('SEARCH_HISTORY'));
    if (err?.data?.message === "Internal server error") {
      yield put(
          httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
      );
    }
  }
}

export const watchHistory = function* watchHistorySaga() {
  yield takeLatest(GET_HISTORY, getHistory);
};
