import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {loadCommonStyle} from "./styles";
import {Images} from "utils";
import {DeleteElement, SimpleModal} from "components";
import {templateActions} from "store";
import {authService} from "../../../../../store/templates/templates.service";

export const Items = ({type, item, handleEditOpen, info}) => {
    const classes = loadCommonStyle()
    const params = useParams()
    const dispatch = useDispatch()
    let add = item?.address && item?.address?.formattedAddress ? item.address.formattedAddress : ''
    const [openDel, setOpenDel] = useState(false)
    const [loader, setLoader] = useState(false)

    const handleOpenEdit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        handleEditOpen(item, type)
    }

    const handleOpenCloseDel = (e) => {
        e?.stopPropagation()
        e?.preventDefault()
        setOpenDel(!openDel)
    }

    const handleRemove = async () => {
        setLoader(true)
        if (item.id) {
            try {
                await authService.deleteLoadStops(params.id, item.id)
                dispatch(templateActions.getTemplateById(params.id, 'noLoad'))
                handleOpenCloseDel()
                setLoader(false)
            } catch (e) {
                setLoader(false)
            }
        }
    }

    return (
        <div className='loadItems'>
            <Accordion>
                <AccordionSummary
                    className={classes.accordion}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={classes.accordionHead}>
                        <div className={classes.accordionHeadLeftSections}>
                            <div>
                                <img src={type === 'pickup' ? Images.pickup : Images.dropoff} alt='icon'/>
                            </div>
                            <p>{add}</p>
                        </div>
                        <div className={classes.accordionHeadRightSections}>
                            <button onClick={(e) => handleOpenEdit(e)}><img src={Images.edit} alt='icon'/></button>
                            <button onClick={(e) => handleOpenCloseDel(e)}>
                                <img src={Images.clear} alt='icon'/>
                            </button>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.detail}>
                    <div>

                        <div className={classes.itemWrapper}>
                            <img src={Images.loadLocation} alt="icon"/>
                            <div className={classes.itemText}>
                                <p>Address</p>
                                <span>{add}</span>
                            </div>
                        </div>
                        {item?.name &&
                            <div className={classes.itemWrapper}>
                                <img src={Images.name} alt="icon"/>
                                <div className={classes.itemText}>
                                    <p>Name</p>
                                    <span>{item.name}</span>
                                </div>
                            </div>
                        }
                        <div className={classes.itemWrapper}>
                            <img src={Images.calendar} alt="icon"/>
                            <div className={classes.itemText}>
                                <p>Date</p>
                                <span>{moment.utc(item?.date).format('MM-DD-YYYY')}</span>
                            </div>
                        </div>
                        <div className={classes.itemWrapper}>
                            <img src={Images.time} alt="icon"/>
                            <div className={classes.itemText}>
                                <p>Time</p>
                                <span>{moment.utc(item?.time).format('HH:mm')}</span>
                            </div>
                        </div>
                        {item.instruction &&
                            <div className={classes.itemWrapper}>
                                <img src={Images.instruction} alt="icon"/>
                                <div className={classes.itemText}>
                                    <p>Instructions</p>
                                    <span>
                                {item.instruction}
                               </span>
                                </div>
                            </div>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <SimpleModal
                handleOpenClose={handleOpenCloseDel}
                openDefault={openDel}
                content={
                    <DeleteElement
                        loading={loader}
                        text={'Delete File?'}
                        handleClose={handleOpenCloseDel}
                        handleDel={handleRemove}
                        info={'delivery'}
                    />
                }
            />
        </div>
    )
}