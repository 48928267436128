import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, ValidationInput, CloseButton} from "components";
import {ErrorText, FindLoad, FindSuccess, globalModals, useGlobalTextStyles,} from "utils";
import {httpRequestsOnSuccessActions} from "store";
import {modalsStyle} from "components/modal/styles";

export const DynamicNote = ({handleClose, title, handleRequest, ACTION_TYPE, buttonText, subTitle}) => {
    const classes = modalsStyle();
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const [error, setError] = useState("");
    const [inputs, setInputs] = useState('');

    useEffect(() => {
        if (!!success?.length) {
            handleClose();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs(e.target.value);
        error === e.target.name && setError("");
    };

    const handleSubmit = () => {
        if (inputs) {
            handleRequest(inputs)
        } else {
            setError('subject');
        }
    };

    return (
        <div>
            <div className={globalModalsClasses.smallModalWrapper}>
                <div className={globalModalsClasses.smallModalClose}>
                    <CloseButton handleCLic={handleClose}/>
                </div>
                <div className={globalModalsClasses.modalWrapperContent}>
                    <p className={globalText.modalTitle}>{title}</p>
                    {subTitle && <p className={globalText.modalText}>{subTitle}</p>}

                    <div style={{marginTop: '40px'}}>
                        <ValidationInput
                            className={inputs ? classes.inputTextFieldBlue : classes.inputTextField}
                            variant={"outlined"}
                            name={"subject"}
                            label={"Add Notes"}
                            type={'text'}
                            multiline={true}
                            Length={300}
                            typeError={error === 'subject' && ErrorText.field}
                            onChange={handleChange}
                            value={inputs}
                        />
                        <div className={classes.maxCharacters}>
                            <p>{`Max ${inputs ? inputs?.length : 0}/300 characters`}</p>
                        </div>
                    </div>

                    <AddModalButton
                        loading={!!loader?.length}
                        handleClick={handleSubmit}
                        text={buttonText}
                    />
                </div>
            </div>
        </div>
    );
};
