import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
    Circle,
    CloseBill,
    DeleteElement, Items,
    Line,
    SimpleModal, SlicedText,
} from "components";
import {Colors, PermissionsList, RolePermission, useGlobalStyles, useGlobalText} from "utils";
import {carrierActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from "store";
import {Carriers} from "./core";
import {customerPaymentInfo} from "../../customerPayment/customerPaymentInfo/core/styles";
import {authService} from '../../../store/carriers/carrier.service'
import useWindowDimensions from "../../../utils/hooks/width";

const PAYMENT_ACTION_FAIL = "VOID_CARRIER_PAYMENT_FAIL"
const PAYMENT_ACTION_SUCCESS = "VOID_CARRIER_PAYMENT_SUCCESS"
export const CarrierPaymentInfoFragment = ({info, carrierPmtCarriers}) => {
    const classes = customerPaymentInfo()
    const globalStyles = useGlobalStyles()
    const globalText = useGlobalText()
    const history = useHistory()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [loadVoid, setLoadVoid] = useState(false)

    const handleOpenCloseDelModal = () => {
        setOpen(!open)
    }

    const handleVoidPayment = async () => {
        setLoadVoid(true)
        dispatch(httpRequestsOnErrorsActions.removeError(PAYMENT_ACTION_FAIL))
        try {
            await authService.voidCarrierPaymentService(info.id)
            setLoadVoid(false)
            dispatch(httpRequestsOnSuccessActions.appendSuccess(PAYMENT_ACTION_SUCCESS))
            dispatch(carrierActions.getCarrierPayments())
            history.push('/carrierPayments')
            setOpen(false)
        } catch (e) {
            setLoadVoid(false)
            dispatch(httpRequestsOnErrorsActions.appendError(PAYMENT_ACTION_FAIL))
            dispatch(carrierActions.getCarrierPayments())
            history.push('/carrierPayments')
            setOpen(false)
        }
    }

    return (
        <div>
            <div>
                <div className={globalStyles.spaceBetween}>
                    <div style={{width: '100%'}} className={globalStyles.centerItem}>
                        <Circle number={1} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Payment Info</p>
                    </div>
                    {info?.status !== "VOIDED" &&
                        RolePermission([PermissionsList.VOID_CARRIER_PMT?.code]) &&
                        <div className={globalStyles.flexAble}>
                            <CloseBill
                                handleCLick={() => handleOpenCloseDelModal('voidPayment')}
                                text={'Void'} width={'77px'}
                            />
                        </div>
                    }
                </div>

                <div className={globalStyles.flexAndFullWidth}>
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'180px'}/>
                    </div>
                    <div className={globalStyles.fullWidth}>
                        <div className={classes.spaceBetween}>
                            <Items title='ID:' text={info?.id} width={20}/>
                            <Items title='Payee:' text={info?.payeeName} width={40}/>
                            <Items title='Created Date:'
                                   text={info?.createdDate ? moment.utc(info?.createdDate).format('MM/DD/YYYY') : 'N/A'}
                                   width={95}/>
                            <Items styles={{marginRight: 0}} title='Amount:'
                                   text={info?.amount ? info.amount : 0} width={56} price={true}/>
                        </div>
                        <div className={classes.spaceBetween}>
                            <Items title='Payment Method:' text={info?.method} width={124}/>
                            <Items title='Reference Number:' text={info?.referenceNumber} width={135}/>
                            <Items title='Created by:' text={info?.payeeName} width={80}/>
                            {info?.remittance &&
                                <Items
                                    styles={{marginRight: 0}}
                                    title='Remittance:'
                                    type='download'
                                    file={{file:info?.remittance, name:'remittance.pdf'}}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={globalStyles.centerItem}>
                    <Circle number={2} back={Colors.ThemeAqua}/>
                    <p className={globalText.title}>Carriers</p>
                </div>
                <div>
                    <Carriers carrierPmts={carrierPmtCarriers}/>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenCloseDelModal}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={loadVoid}
                        type={'void'}
                        text={'Void Payment'}
                        handleClose={handleOpenCloseDelModal}
                        handleDel={handleVoidPayment}
                        info={'Bill'}
                        modalText={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text.'}
                    />
                }
            />
        </div>
    )
}