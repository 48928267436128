import {historyStyle} from "./core";
import {HistoryCard} from "./core";
import {Colors, useGlobalStyles} from "utils";
import HistoryIcon from '@material-ui/icons/History';
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const History = () => {
    const globalStyle = useGlobalStyles();
    const classes = historyStyle()
    const data = {
        date: '',
        correctDate: '',
        time: '',
        title: '',
    }

    return (
        <div style={{background: 'white',}} className={globalStyle.tableWrapper}>
            <div className={classes.cardsWrapper}>

                <div className={classes.tittleWrapper}>
                    <div className={classes.flaxAble}>
                        <HistoryIcon style={{fontSize: '24px'}}/>
                        <p className={classes.historyTitle}>History</p>
                    </div>

                    <div className={classes.flaxAble}>
                        <TextField
                            id="date"
                            label="Birthday"
                            type="date"
                            variant={'outlined'}
                            // defaultValue="2017-05-24"x/
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            style={{
                                textTransform: "capitalize", background: Colors.ThemeBlue, marginLeft: '24px',
                                height: '36px', borderRadius: '18px', color: 'white', padding: '0 24px',
                            }}>
                            Search
                        </Button>

                    </div>
                </div>
                <HistoryCard data={data} key={1}/>
                <HistoryCard data={data} key={1}/>
                <HistoryCard data={data} key={1}/>
            </div>
        </div>
    )
}