import axios from "axios";

export const authService = {
  /** Create, Edit Office */

  createOfficeService: ( body ) => axios.post('/offices', body, { auth:true }),

  editOfficeService: ( body, id ) => {
    const data ={
      "name": body?.name,
      "email": body?.email,
      "phoneNumber": body?.phoneNumber,
      "address": body?.address,
      "establishedDate": body?.establishedDate,
      "payrollFixedCost": body?.payrollFixedCost,
      "payrollPercent": body?.payrollPercent,
    }
    return axios.patch(`/offices/${id}`, data, {auth:true});
  },

  /** End */

  /** Get Offices */

  getOfficesService: ( info ) => axios.get('/offices',{ auth:true, params: { ...info } } ),

  getOfficeByIdService: ( id, info ) => axios.get(`/offices/${id}`, { auth:true }),

  /** End */

  /** Activate or Inactivate Office */

  activateOfficeService: ( id ) => axios.patch(`/offices/${id}/activate`,null,{ auth:true }),

  inactivateOfficeService: ( id ) => axios.patch(`/offices/${id}/inactivate`, null,{ auth:true }),

  /** End */

  /** Office Management */

  createOfficeManagerService: ( data ) => axios.post(`/agents`, data, {auth:true }),

  officeManagerCreateService: ( data ) => axios.post(`/agents/officeManager`, data, {auth:true }),

  selectOfficeManagerService: ( agentId, officeId ) => axios.post(`/agents/${agentId}/managedOffice/${officeId}`, {}, {auth:true}),

  removeOfficeManagerService: ( data ) => axios.delete(`/agents/${data.managerId}/managedOffice/${data.officeId}`,{auth:true }),

  /** End */

  /** Get Office branches  */

  getOfficeBranchesService: ( officeId, info ) => axios.get(`/branches/byOffice/${officeId}`,{auth:true, params: { ...info } }),

  /** End */

  /** Office Expenses */

  addExpensesService: ( id, info ) => axios.patch(`/offices/${id}/addExpense`,{ ...info },{ auth:true }),

  addSubtractExpensesService: ( id, info ) => axios.patch(`/offices/${id}/subtractExpenses`,{ ...info }, { auth:true }),

  /** End */

};
