import {
  activateAgent,
  addBracket, changeBracket,
  createAgent,
  deleteBracket,
  editAgent,
  editBracket,
  filterByAlphabetical,
  filterByDefault,
  getAgentByBranch,
  getAgentById,
  getAgentByOffice,
  getAgents,
  getBracket,
  getBy,
  getDefaultBracket,
  getManagersByBranch,
  getManagersByOffice,
  inactivateAgent,
  removeAgentByBranch, resendAgent,
  searchAgent
} from "./agent.action";

export { agentReducer } from './agent.reducer';
export { watchAgent } from './agent.saga';

export const agentActions = {
  /** Create, Edit Agent, Resend */
  createAgent,
  editAgent,
  resendAgent,
  /** End */

  /** Get Agents */
  getBy,
  getAgents,
  getAgentById,
  /** End */

  /** Activate or Inactivate Office */
  activateAgent,
  inactivateAgent,
  /** End */

  /** Get Agents and Managers */
  getAgentByOffice,
  getManagersByOffice,

  getAgentByBranch,
  removeAgentByBranch,
  getManagersByBranch,
  /** End */

  /** Agents Filters */
  searchAgent,
  filterByAlphabetical,
  filterByDefault,
  /** End */

  /** Payroll Brackets */
  addBracket,
  editBracket,
  deleteBracket,
  getBracket,
  getDefaultBracket,
  changeBracket,
  /** End */
}


