import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AntSwitch, FindLoad, Images} from "utils";
import {rmisActions} from "store";
import {Loader} from "components";

const ACTION_TYPE = 'GET_RMIS_UPDATES'

export const RmisUpdates = () => {
    const {rmisSetups} = useSelector((state) => ({
        rmisSetups: state.rmis.rmisSetups,
    }));
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)
    const [cron, setCron] = useState(false)
    const [cleanUp, setCleanUp] = useState(false)

    useEffect(() => {
        dispatch(rmisActions.getRMISSetup())
    }, [])

    useEffect(() => {
        if (rmisSetups) {
            setCron(rmisSetups?.enableCron)
            setCleanUp(rmisSetups?.enableCleanup)
        }
    }, [rmisSetups])

    const handleSwitch = (type) => {
        if (type === 'cron') {
            setCron(!cron)
        } else {
            setCleanUp(!cleanUp)
        }
        const sendInfo = {
            "enableCron": type === 'cron' ? !cron : cron,
            "enableCleanup": type === 'cleanUp' ? !cleanUp : cleanUp,
        }
        dispatch(rmisActions.updateRMISSetup(sendInfo))
    }

    if (loader?.length) {
        return <Loader/>
    }

    return (
        <div className='rmis-updates-wrapper'>
            <div className='rmis-tab-wrapper'>
                <div className='rmis-tab-image-text'>
                    <img src={Images.rmisActive} alt='icon'/>
                    <p>RMIS Cron</p>
                </div>
                <AntSwitch
                    onClick={() => handleSwitch('cron')}
                    checked={cron}
                />
            </div>
            <div style={{marginLeft: '24px'}} className='rmis-tab-wrapper'>
                <div className='rmis-tab-image-text'>
                    <img src={Images.rmisActive} alt='icon'/>
                    <p>RMIS Cleanup</p>
                </div>
                <AntSwitch
                    onClick={() => handleSwitch('cleanUp')}
                    checked={cleanUp}
                />
            </div>
        </div>
    )
}