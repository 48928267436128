import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Images, SavePage } from 'utils';
import { inputsStyle } from './styles';

export const SearchInput = ({}) => {
  const classes = inputsStyle();
  const [value, setValue] = useState('');
  const ref = useRef();
  const history = useHistory();
  const info = history?.location?.state;

  const handleSend = () => {
    SavePage(history, info, { loadDisplayId: value });
  };

  return (
    <div>
      <div style={value ? { borderColor: '#438AFE' } : {}} className={classes.searchInputWrapper}>
       <div>
        <input ref={ref} type="search" onChange={(e) => setValue(e.target.value)} placeholder={'Search for Load ID'} />
       </div>
        <button onClick={handleSend} style={value ? { borderColor: '#438AFE' } : {}}>
          <img src={Images.searchIcon} alt={'icon'} />
        </button>
      </div>
    </div>
  );
};