import React, { useEffect } from 'react';
import { Images } from 'utils';
import {
  ClickAwayListener,
  FormControlLabel,
  Grow,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { bigSizeText, smallSizeText } from '../inputs/constants';
import { TextRow } from 'components';
// import { TextRow } from '../table';

export const MenuTool = ({
                           handleFilter,
                           width,
                           item,
                         }) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState('');
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    if (info && info[item?.name]) {
      setSelected(info[item?.name]);
    }
  }, [info]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleChange = (e) => {
    setSelected(e?.target?.value);
    handleFilter && handleFilter(e);
    setOpen(false);
  };

  return (
    <div style={width ? { width: width } : {}} className="filter-menu">
      <div className="search-input-title"  onClick={handleToggle}>
        <>
          <TextRow name={item?.title} textWidth={11}/>
          {/*<p className="small-desk">*/}
          {/*  {smallSizeText(item)}*/}
          {/*</p>*/}
          {/*<p className="big-desk">*/}
          {/*  {bigSizeText(item)}*/}
          {/*</p>*/}
        </>
        <button
          className="item-button"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <img
            src={open ? Images.up : Images.down }
            alt={'filter icon'}
          />
        </button>
      </div>

      <Popper
        className="popper-menu"
        style={{ zIndex: 20, maxHeight: 300, overflow:'auto' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow{...TransitionProps}
               style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <p className="filter-type-text">{item?.filterTitle ? item?.filterTitle : 'Filter the Type'}</p>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="menu-list-box"
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {item?.filterList?.map((i, j) => (
                    <RadioGroup
                      row
                      style={{ margin: 0 }}
                      key={j}
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        onChange={(e) => handleChange(e, i.id)}
                        value={i.id}
                        name={item?.name}
                        control={
                          <Radio
                            style={{ color: '#00C851' }}
                            checked={!selected && i.id === 'All' ? true : selected === i.id}
                            color="primary"
                          />
                        }
                        label={i.name}
                        labelPlacement="End"
                      />
                    </RadioGroup>
                  ))}

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};