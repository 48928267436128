
/** Create office */
export const CREATE_OFFICE = 'CREATE_OFFICE';

/** Edit office */
export const EDIT_OFFICE = 'EDIT_OFFICE'

/** Get offices */
export const GET_OFFICES = 'GET_OFFICES';
export const GET_OFFICES_SUCCESS = 'GET_OFFICES_SUCCESS';
export const GET_OFFICES_FOR_AGENTS = 'GET_OFFICES_FOR_AGENTS';

/** Get office by id */
export const GET_OFFICE_BY_ID = 'GET_OFFICE_BY_ID';
export const GET_OFFICE_BY_ID_SUCCESS = 'GET_OFFICE_BY_ID_SUCCESS';

/** Activate Inactivate office */
export const ACTIVATE_OFFICE = 'ACTIVATE_OFFICE';
export const INACTIVATE_OFFICE = 'INACTIVATE_OFFICE';

/** Office Management */
export const CREATE_OFFICE_MANAGERS = 'CREATE_OFFICE_MANAGERS';
export const CREATE_OFFICE_MANAGERS_SUCCESS = 'CREATE_OFFICE_MANAGERS_SUCCESS';
export const SELECT_OFFICE_MANAGERS = 'SELECT_OFFICE_MANAGERS';
export const REMOVE_OFFICE_MANAGERS = 'REMOVE_OFFICE_MANAGERS';

/** Get Office Branches */
export const GET_OFFICE_BRANCHES = 'GET_OFFICE_BRANCHES';
export const GET_OFFICE_BRANCHES_SUCCESS = 'GET_OFFICE_BRANCHES_SUCCESS';
export const REMOVE_OFFICE_BRANCHES = 'REMOVE_OFFICE_BRANCHES';

/** Office Expenses */
export const ADD_OFFICE_EXPENSES = 'ADD_OFFICE_EXPENSES';
export const ADD_OFFICE_EXPENSES_SUCCESS = 'ADD_OFFICE_EXPENSES_SUCCESS';
export const SUBTRACT_OFFICE_EXPENSES = 'SUBTRACT_OFFICE_EXPENSES';
export const SUBTRACT_OFFICE_EXPENSES_SUCCESS = 'SUBTRACT_OFFICE_EXPENSES_SUCCESS';