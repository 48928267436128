import {buttonsStyle} from "./styles";
import {Images} from "utils";

export const GenerateButton = ({handleGenerate}) => {
    const classes = buttonsStyle()

    return (
        <button onClick={handleGenerate} className={classes.generateButton}>
            <img
                style={{ filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)',}}
                src={Images.generateIcon} alt="icon"/>
            Generate
        </button>
    )
}