import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {SystemTable} from "fragments";
import {systemActions, termActions} from 'store';

export const System = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(systemActions.getDefaultBracket())
        dispatch(termActions.getTerms())
    }, []);

    return (
        <SystemTable/>
    )

}