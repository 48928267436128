import React, {useEffect} from "react";
import {useState} from "react";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {createOfficeStyle} from "./styles";

export const RadioButtons = ({disabled, handleSelect, one, label, value, check}) => {
    const classes = createOfficeStyle()
    const [selectedValue, setSelectedValue] = useState( 'Other');

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        handleSelect(event.target.value)
    };

    return (
        <div className={one === true ? classes : classes.radioButtonStyle}>
            <FormControl component="fieldset">
                <RadioGroup    disabled={disabled} row aria-label="position" name="position" defaultValue="top">
                    {one === true ?
                        <FormControlLabel
                            disabled={disabled}
                            checked={check}
                            onChange={handleChange}
                            value={value}
                            control={<Radio color="primary"/>}
                            label={label}
                            labelPlacement="End"
                        />

                        : <>
                            <FormControlLabel
                                disabled={disabled}
                                checked={selectedValue === 'Same'}
                                onChange={handleChange}
                                value="Same"
                                control={<Radio color="primary"/>}
                                label="Same as Address"
                                labelPlacement="End"
                            />

                            <FormControlLabel
                                disabled={disabled}
                                checked={selectedValue === 'Other'}
                                onChange={handleChange}
                                value="Other"
                                control={<Radio color="primary"/>}
                                label="Other"
                                labelPlacement="End"
                            /></>
                    }
                </RadioGroup>
            </FormControl>

        </div>
    )
}