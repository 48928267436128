import React, {useEffect, useState} from "react";
import {Paper, Tab, Tabs} from "@material-ui/core";
import {buttonsStyle} from "./styles";
import {SavePage} from "../../utils";
import {useHistory} from "react-router-dom";

export const ButtonsTab = ({first, second, width, maxWidth, type}) => {
    const classes = buttonsStyle();
    const [value, setValue] = React.useState(0);
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        if (info) {
            const tabStatusType = type === 'report' ? 'Current' : 'ACTIVE'
            const statusType =
                info?.status === tabStatusType ? 0 : 1
            setValue(info?.status ? statusType : 0)
        }
    }, [info])

    const handleChange = (event, newValue) => {
        const tabStatusTypeFirst = type === 'report' ? 'Current' : 'ACTIVE'
        const tabStatusTypeSecond = type === 'report' ? 'Past Reports' : 'INACTIVE'
        const statusType = newValue === 0 ? tabStatusTypeFirst : tabStatusTypeSecond
        setValue(newValue)
        const infoItem = type === 'report' ? {} : {...info, page: 1, skip: 0, limit: 100}
        infoItem.status = statusType
        SavePage(history, info, {...infoItem})
    }

    return (
        <Paper
            style={{
                maxWidth: maxWidth ? maxWidth : "244px",
                width: 'auto',
                border: 'none',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #8A8A8A29',
                borderRadius: '18px',
                padding: '0 2px',
            }}
            square
        >
            <Tabs
                className={classes.buttonsTab}
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab style={{width: width ? width : "100px"}} label={first}/>
                <Tab style={{width: width ? width : "100px"}} label={second}/>
            </Tabs>
        </Paper>
    );
};
