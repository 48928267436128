import React, { useState } from "react";
import { CreateAuthoritiesInputs } from "./core";
import { CreateWrapper } from "components";
import { Images, useGlobalStyles } from "utils";

export const CreateAuthorities = ({ }) => {
  const globalStyle = useGlobalStyles();
    const [name, setName] = useState('')

  return (
    <div>
      <CreateWrapper
        head={
              <div className={globalStyle.createOfficeTableHead}>
                <img src={Images.authorityBlueFill} alt={"authorityBlueFill"} />
                <p>{name ? name : 'MC AUTHORITY NAME'}</p>
              </div>
        }
        body={
          <CreateAuthoritiesInputs
              handleChangeName ={setName}
          />
        }
        parentLink={'/authorities'}
        parent={'MC Authorities'}
        child={'Add MC Authority'}
      />
    </div>
  );
};
