
/**Create Equipment */
export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT';

/**Edit Equipment */
export const EDIT_EQUIPMENT = 'EDIT_EQUIPMENT'
export const EDIT_EQUIPMENT_FROM_LIST = 'EDIT_EQUIPMENT_FROM_LIST'

/**Get Equipment */
export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';

/** Activate or Inactivate Equipment */
export const SET_EQUIPMENT_STATUS = 'SET_EQUIPMENT_STATUS';

/**Equipment Carrier by id */
export const GET_EQUIPMENT_BY_ID = 'GET_EQUIPMENT_BY_ID';
export const GET_EQUIPMENT_BY_ID_SUCCESS = 'GET_EQUIPMENT_BY_ID_SUCCESS';

/**Delete Equipment */
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';

/** Favorite */
export const SET_FAVORITE = 'SET_FAVORITE';
export const UNSET_FAVORITE = 'UNSET_FAVORITE';
