import React, {useState} from "react";
import {CreateAgentInputs} from "./core";
import {CreateWrapper} from "components";
import {Images, useGlobalStyles} from "utils";

export const CreateAgent = ({ info }) => {
    const globalStyle = useGlobalStyles();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    return (
        <div>
            <CreateWrapper
                head={
                    <div className={globalStyle.createOfficeTableHead}>
                        <img src={Images.agentsBlue} alt={"humanResourcesYellow"}/>
                        <p>{
                            firstName && lastName ? `${firstName} ${lastName}` :
                                firstName ? firstName :
                                    lastName ? lastName : 'AGENT NAME'
                        }</p>
                    </div>
                }
                body={
                    <CreateAgentInputs
                        handleChangeFirstName = { setFirstName }
                        handleChangeLastName = { setLastName }
                        info={info}
                    />
                }
                parentLink={'/agents'}
                parent={'Agents'}
                child={'Add Agent'}
            />
        </div>
    );
};
