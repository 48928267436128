import {
    ACTIVATE_MC,
    CREATE_MC,
    EDIT_MC,
    GET_MCS,
    GET_MC_BY_ID,
    INACTIVATE_MC,
    ASSIGNED_MC,
    GET_ASSIGNED_MC,
    DELETE_ASSIGNED_MC,
    BRANCH_ASSIGNED_MC,
    GET_ASSIGNED_MCS,
    GET_OFFICE_ASSIGNED_MCS,
    GET_AGENT_ASSIGNED_MCS
} from "./mc.types";

                                           /** Mc Requests, here is All requests for Mc page */

/** Create, Edit Mc */

export const createMc = ( body ) => {
  return {
    type: CREATE_MC,
    payload: { body }
  }
}

export const editMc = (id, body ) => {
  return {
    type: EDIT_MC,
    payload: {id, body }
  }
}

/** End */

/** Get Mcs */

export const getMcs = ( params ) => {
  return {
    type: GET_MCS,
    payload: { params }
  }
}

export const getMcById =( id, load )=>{
  return{
    type: GET_MC_BY_ID,
    payload: { id, load }
  }
}

export const getAssignedMcs = ( id, type ) => {
    return {
        type: GET_ASSIGNED_MCS,
        payload:{ id, type }
    }
}
export const getOfficeAssignedMcs = ( id, type ) => {
    return {
        type: GET_OFFICE_ASSIGNED_MCS,
        payload:{ id, type }
    }
}
export const getAgentAssignedMcs = ( id, type ) => {
    return {
        type: GET_AGENT_ASSIGNED_MCS,
        payload:{ id, type }
    }
}

/** End */

/** Activate or Inactivate Mc */

export const activateMc = ( id ) =>{
  return{
    type: ACTIVATE_MC,
    payload:{ id }
  }
}

export const inactivateMc = ( id ) =>{
  return{
    type: INACTIVATE_MC,
    payload:{ id }
  }
}

/** End */

/**  Assigned Mc */

export const assignMs =(officeId, mcId, type)=>{
  return{
    type:ASSIGNED_MC,
    payload:{officeId, mcId, type }
  }
}

export const getBranchAssignedMs =( mcId, type)=>{
  return{
    type:BRANCH_ASSIGNED_MC,
    payload:{ mcId, type }
  }
}

export const getAssignedMs =(mcId, type)=>{
  return{
    type:GET_ASSIGNED_MC,
    payload:{mcId, type}
  }
}

export const deleteAssignedMs =(officeId, mcId, type)=>{
  return{
    type:DELETE_ASSIGNED_MC,
    payload:{officeId, mcId, type}
  }
}

/** End */
