import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  DeleteElement, History,
  Loader, NoteDrawer, Notes,
  SimpleModal,
  SimpleTabs,
} from 'components';
import { BillingDetails, CreateFactoredLoadTable, FactoredTransactions } from 'fragments';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions } from 'store';
import {
  FindErrorItem,
  FindLoad,
  FindSuccess,
  Images, isFactored,
} from 'utils';
import { loadsStyle } from './styles';
import { CANCELLED, COMPLETED, DENIED, PENDING, REJECTED } from '../accounting/constants';
import { isAdmin } from '../../utils/constants';
import { Transactions } from '../../fragments/loads/transactions';
import { PaymentVerificationDetails } from '../../fragments/paymentVerification';

const ACTION_TYPE = 'GET_LOAD_BY_ID';
const CANCEL_ACTION_TYPE = 'CANCEL_LOAD';

export const FactoredLoadDetails = () => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const classes = loadsStyle();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const cancelLoader = FindLoad(CANCEL_ACTION_TYPE);
  const successCancel = FindSuccess(CANCEL_ACTION_TYPE);
  const backError = FindErrorItem(CANCEL_ACTION_TYPE);
  const info = history?.location?.state;
  const [verification, setVerification] = useState(false);
  const [revision, setRevision] = useState(false);
  const time = loadById && loadById?.expiryCountFrom;
  const [open, setOpen] = useState(false);
  let now = moment(new Date());
  let end = moment(time);
  let duration = moment.duration(now.diff(end));
  let hours = duration.asHours() > 0 ? 24 - duration.asHours() : Math.abs(duration.asHours());
  let minutes = duration.asMinutes();
  let min = Math.abs(minutes % 60);
  const check = hours <= 0 && (minutes < 0 ? min : 60 - min) <= 0;

  useEffect(() => {
    if (backError) {
      dispatch(httpRequestsOnErrorsActions.removeError(CANCEL_ACTION_TYPE));
      setOpen(false);
    }
  }, [backError]);

  useEffect(() => {
    if (loadById?.id) {
      setRevision(!!loadById?.isRevision);
      setVerification(!!loadById?.isVerification);
    }
  }, [loadById]);


  useEffect(() => {
    if (successCancel?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CANCEL_ACTION_TYPE));
      setOpen(false);
    }
  }, [successCancel]);

  const handleOpenCloseDel = () => {
    setOpen(!open);
  };

  const handleRemoveDoc = () => {
    dispatch(loadActions.cancelLoad(params?.id));
  };

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'Transactions',
    },
    isAdmin &&
    {
      label: 'Billing Details',
    },
    isAdmin &&
    {
      label: 'Payable Details',
    },
    isAdmin &&
    {
      label: 'History',
    },
    // isAdmin &&
    // {
    //   label: 'Notes',
    // },
  ];

  const tabsContent = [
    {
      tabComponent: loader.length ? <Loader /> : <CreateFactoredLoadTable bredcrump={'noBread'} />,
      tab: 'Details',
    },
    isFactored &&
    {
      tabComponent: <FactoredTransactions load={loadById} />,
      tab: 'Transactions',
    },
    isAdmin &&
    {
      tabComponent: <Transactions load={loadById} />,
      tab: 'Transactions',
    },
    isAdmin &&
    {
      tabComponent: <BillingDetails loadById={loadById} />,
      tab: 'Billing Details',
    },
    isAdmin &&
    {
      tabComponent: <PaymentVerificationDetails loadById={loadById} />,
      tab: 'Payable Details',
    },
    isAdmin &&
    {
      tabComponent: <History onModel={'load'} resourceId={params.id} />,
      tab: 'History',
    },
    // isAdmin &&
    // {
    //   tabComponent: <Notes/>,
    //   tab: 'Notes'
    // }

  ];

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div>
      <>
        <div className={classes.breadAndTime}>
          {loadById && loadById.status === 'PENDING' && loadById?.expiryCountFrom &&
            <p className={classes.timing}>
              {check ? 'Expired' : `Remaining ${hours > 1 ? Math.trunc(hours) : 0}h ${Math.trunc(minutes < 0 ? min : 60 - min)}m`}
            </p>
          }
        </div>

        <SimpleTabs
          verification={verification || revision}
          headerWidth={'480px'}
          head={
            <div className="space-between" style={{ gap: 15 }}>
              <div className={classes.loadIdWrapper}>
                <img src={Images.loadFillBlue} alt={'icon'} />
                <p>{loadById?.displayId} </p>
              </div>
                <NoteDrawer title={'Load'} />
            </div>
          }
          noBread={true}
          border={'blue'}
          height={'full'}
          tabsLabels={tabsLabels}
          tabsContent={tabsContent}
          defaultTab={'Details'}
        />

        {
          loadById?.bill?.totalPaid === 0 && loadById?.payable?.totalPaid === 0 &&
          loadById?.status !== COMPLETED &&
          loadById?.status !== CANCELLED &&
          loadById?.status !== DENIED &&
          loadById?.status !== REJECTED &&
          <div className={classes.cancelLoadWrapper}>
            <button onClick={() => handleOpenCloseDel()} className={classes.cancelLoad}>
              Cancel Load
            </button>
          </div>
        }

        <SimpleModal
          handleOpenClose={() => handleOpenCloseDel()}
          openDefault={open}
          content={
            <DeleteElement
              cancelButton={'Cancel'}
              closeButton={'Go Back'}
              modalText={'Are you sure you want to cancel this load?'}
              loading={!!cancelLoader?.length}
              text={'Cancel Load?'}
              handleClose={() => handleOpenCloseDel()}
              handleDel={handleRemoveDoc}
            />
          }
        />
      </>
    </div>
  );
};