import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const loadCommonStyle = makeStyles(() => ({
    timeInput: {
        width: '100%',
        height: '48px',
        borderRadius: '6px',
        outline: 'none',
        border: '1px solid rgba(0, 0, 0, 0.87)',
        padding: '0 12px',
    },
    accordion: {
        background: '#E6ECF3 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        height: '48px',
        padding: '12px 16px !important',
        '& .MuiSvgIcon-root': {
            color: Colors.ThemeBlue,
            fontSize: '28px'
        },
        '& .MuiAccordionSummary-expandIcon': {
            transform: 'rotate(-91deg)',
        },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(0deg)',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px!important',
        },
    },
    accordionHead: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    accordionHeadLeftSections: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '24px',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            background: '#00BCD4 0% 0% no-repeat padding-box',
            marginRight: '8px',
        },
        '& p': {
            fontWeight: 600,
            fontSize: '14px',
            color: Colors.TextPrimary,
        }
    },
    accordionHeadRightSections: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& button': {
            border: 'none',
            background: 'none',
            marginRight: '16px',
            height: '18px',
            width: '18px',
            '& img': {
                height: '18px',
                width: '18px',
            }
        },
    },
    detail: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '0px 0px 4px 4px',
        padding: '16px !important',
        height: '100%',
        overflow: 'auto',
    },
    itemWrapper: {
        display: 'flex',
        marginBottom: '25px',
        '& img': {
            marginTop: '-25px'
        }
    },
    itemText: {
        marginLeft: '8px',
        '& p': {
            color: Colors.TextLight,
            fontSiz: '14px',
            fontWeight: 600,
            margin: '0 0 8px 0',
        },
        '& span': {
            fontSiz: '14px',
            color: Colors.TextPrimary,
        },
    },
    dateAndTime: {
        display: 'flex',
    },
    smallText: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextSecondary,
        marginBottom: '16px',
        marginTop: '20px',
    },
    maxCharacters: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& p': {
            fontSize: '12px',
            color: Colors.TextLight,
            marginRight: '20px',
            marginTop: '4px',
        },
    },
    buttonsStyle: {
        marginTop: '16px',
    },
    closeButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '-50px',
        marginBottom: '-30px',
    },
    paperWrapper: {
        width: '800px',
        height: '90vh',
        background: 'white',
        overflow: 'auto',
        padding: '10px 24px 60px 24px',
        display: "flex",
        flexDirection: 'column',
    },
    paperFooter: {
        height: '112px',
        background: '#1C2331 0% 0% no-repeat padding-box',
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        bottom: 0
    },
    carrierEmail: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#000000',
        margin:'6px auto 16px auto',
    },
}))