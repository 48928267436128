import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {Images, useGlobalStyles, useGlobalText} from "utils";
import {NoYet} from "components";
import {LoadTable} from "./loadTable";
import {customerPaymentInfo} from "../../../customerPayment/customerPaymentInfo/core/styles";

export const Carriers = ({carrierPmts}) => {
    const classes = customerPaymentInfo()
    const globalStyles = useGlobalStyles();

    return (
        <div className={classes.customersWrapper}>
            { carrierPmts?.length ?
                carrierPmts?.map((item, j) => (
                    <div className='loadItems' key={j}>
                        <Accordion>
                            <AccordionSummary
                                className={classes.accordion}
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={globalStyles.spaceBetween}>
                                    <div className={classes.imageAndTitle}>
                                        <img src={Images.carrier} alt='icon'/>
                                        <p>{item[0].carrierName}</p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{boxShadow: '0px 0px 6px #8A8A8A29',}}>
                                <div className={classes.bodyWrapper}>

                                    <LoadTable loadInfo={item}/>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))
                :
                <NoYet position={'screenCenter'} text={'No Carriers Yet'}/>
            }
        </div>
    )
}