import React, {Component} from 'react';
import RichTextEditor from "react-rte";

class RichTextEditorEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorValue: RichTextEditor.createEmptyValue(),
            newValue: '',
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.state.newValue !== this.props.text) {
            const note = nextProps?.text
            let editorValue = this.state.editorValue
            if (editorValue.toString('html') !== note) {
                editorValue = RichTextEditor.createValueFromString(note, 'html')
            }
            this.setState({editorValue})
        } else if (!this.state.newValue) {
            const note = nextProps?.text
            let editorValue = this.state.editorValue
            if (editorValue.toString('html') !== note) {
                editorValue = RichTextEditor.createValueFromString(note, 'html')
            }
            this.setState({editorValue})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this?.props?.success?.length && this.state.newValue ){
            this.setState({ editorValue:RichTextEditor.createValueFromString(this.props.text, 'html'),})
        }
        if(this?.props?.forEdit?.length && this.state.newValue){
            this.setState({ editorValue:RichTextEditor.createValueFromString(this.props.text, 'html'),})
        }
    }

    onChange = (editorValue) => {
        this.setState({editorValue});
        this.setState({newValue: editorValue.toString('html')});
        this.props.onChange(editorValue.toString('html'));
    };

    render() {
       const toolbarConfig = {
            display: [
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                'BLOCK_ALIGNMENT_BUTTONS',
                'LINK_BUTTONS'
            ],
            INLINE_STYLE_BUTTONS: [
                {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                {label: 'Italic', style: 'ITALIC'},
                {label: 'Underline', style: 'UNDERLINE'},
            ],
            BLOCK_TYPE_BUTTONS: [
                {label: 'UL', style: 'unordered-list-item'},
                {label: 'OL', style: 'ordered-list-item'},
            ],
            BLOCK_ALIGNMENT_BUTTONS: [
                {label: 'AL', style: 'ALIGN_LEFT'},
                {label: 'AC', style: 'ALIGN_CENTER'},
                {label: 'AR', style: 'ALIGN_RIGHT'},
            ],
            LINK_BUTTONS: [
                {label: 'Link!', style: 'unstyled', className: 'rte-button rte-button-link'},
                {label: 'Remove link', style: 'unstyled', className: 'rte-button rte-button-link-remove'}
            ]
        };

        return (
            <div>
                <RichTextEditor
                    disabled={this.props.disabled}
                    placeholder={'Enter the Description here...'}
                    name='body'
                    value={this.state.editorValue}
                    onChange={this.onChange}
                    toolbarConfig={toolbarConfig}
                />
            </div>

        );
    }
}

export default RichTextEditorEdit
