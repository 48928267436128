import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FindLoad, FindSuccessItem, Images, SendPageSave, useModal} from "utils";
import {Loader, PaginationItem, TableNoInfo, TableWrapper, TextRow} from "components";
import {CreateTemplate} from "./fragments";
import {httpRequestsOnSuccessActions, templateActions} from "store";
import {CustomDeleteModal} from "fragments";
import {renderIcon} from "./constants";

const ACTION_TYPE = 'GET_TEMPLATES'
const DELETE_ACTION_TYPE = 'DELETE_TEMPLATE'

export const Templates = ({}) => {
    const {templates} = useSelector((state) => ({
        templates: state.templates.templates,
    }));
    const {open, close} = useModal()
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)
    const history = useHistory()
    const info = history?.location?.state
    const delSuccess = FindSuccessItem(DELETE_ACTION_TYPE)

    useEffect(() => {
        if (delSuccess) {
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE))
        }
    }, [delSuccess])

    useEffect(() => {
        dispatch(templateActions.getTemplates({}))
    }, [])

    const changePage = (number) => {
        SendPageSave(number, info, history)
    };

    const handleDelete = (item) => {
        dispatch(templateActions.deleteTemplate(item?.id, info))
    }

    if (loader?.length) {
        return <Loader/>
    }

    return (
        <div>
            <TableWrapper
                addButton={"Create Template"}
                buttonsTab={true}
                buttonsTabAddButton={true}
                href={'/createTemplate'}
                link={false}
                handleClick={() => open(<CreateTemplate/>)}
                tab={'none'}
                type={'All'}
                createPermisson={true}
            >
                <div>
                    <div className='template-cards-body'>
                        {templates?.items?.length ?
                            templates?.items?.map((i, j) => (
                                <div className='template-card-wrapper' key={j}>
                                    <div className='template-image-and-text'
                                         onClick={() => history.push(`/template/${i?.id}`)}>
                                        {renderIcon(i.color)}
                                        <div className='full-width' style={{marginLeft: '12px'}}>
                                            <p className='template-title'>
                                                <TextRow name={i?.name} textWidth={10}/>
                                            </p>
                                            <p className='template-description'>
                                                <TextRow
                                                    name={i?.description ? i?.description : 'Not Set'}
                                                    textWidth={10}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className='action-buttons-wrapper'>
                                        <button>
                                            <img src={Images.edit} alt="icon"
                                                 onClick={() => open(<CreateTemplate template={i}/>)}/>
                                        </button>
                                        <button
                                               className='remove-template-btn'
                                                onClick={() => open(<CustomDeleteModal
                                                    modalText={'Are you sure you wont delete this template'}
                                                    text={'Delete this Template ?'}
                                                    actionType='DELETE_TEMPLATE'
                                                    handleDel={() => handleDelete(i)}
                                                />)}
                                        >
                                            <img src={Images.removeRed} alt="icon"/>
                                        </button>
                                    </div>
                                </div>
                            ))
                            :
                            <div style={{height: '100px'}}>
                                <TableNoInfo text={`No Template Yet`}/>
                            </div>
                        }
                    </div>
                    <div>
                        {templates?.count > 0 ?
                            <div style={{background: 'white', border: '0 0 8px 8px'}}>
                                <PaginationItem
                                    listInfo={templates?.items}
                                    handleReturn={(number) => changePage(number)}
                                    count={templates?.count}
                                    styles={{margin: '0 0 21px 0'}}
                                />
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </TableWrapper>
        </div>
    )
}