import {GET_RMIS_UPDATES, INVITE_RMIS, REFRESH_RMIS, UPDATE_RMIS_REQUIRED, UPDATE_RMIS_SETUP} from "./rmis.types";

/**Rmis Settings */

export const inviteRMIS = (id) => {
    return {
        type: INVITE_RMIS,
        payload: {id}
    }
}

export const refreshRMIS = (id) => {
    return {
        type: REFRESH_RMIS,
        payload: {id}
    }
}

export const getRMISSetup = () => {
    return {
        type: GET_RMIS_UPDATES,
    }
}

export const updateRMISSetup = ( body ) => {
    return {
        type: UPDATE_RMIS_SETUP,
        payload: {body}
    }
}

export const requiredRMIS = ( body ) => {
    return {
        type: UPDATE_RMIS_REQUIRED,
        payload: {body}
    }
}

/** End */
