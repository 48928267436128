import { CircularProgress } from "@material-ui/core";
import React from "react";

export const MinLoader = ({color, margin, style = {}, position }) => {
  const styles = {
    loaderStyle: {
      position:position ? position : "absolute",
      color: color,
      width: "17px",
      height: "17px",
      margin: margin ? margin :  "5px 0 0 10px",
    },
  };
  return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};
