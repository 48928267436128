import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginPage } from './style';
import { authActions } from 'store';
import { CopyRight, ForgotModal, LoginModal, ResetModal, } from 'fragments';
import { MessageScreen, Toast } from 'components';
import { ToastFail, Images } from 'utils';

export function LoginPage(props) {
  const { httpOnError, resetSuccess, closeResetSuccess, httpOnSuccess } =
    useSelector((state) => ({
      httpOnError: state.httpOnError,
      httpOnSuccess: state.httpOnSuccess,
      getLinkSuccess: state.auth.getLinkSuccess,
      resetSuccess: state.auth.resetSuccess,
      closeResetSuccess: state.auth.closeResetSuccess,
    }));
  const classes = loginPage();
  const dispatch = useDispatch();
  const history = useHistory();
  const [forgot, setForgot] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [registerToken, setRegisterToken] = useState('');
  const link = window.location.search;

  const handleChangePage = () => {
    history.push('/');
  };

  const handleForgot = () => {
    setForgot(true);
  };

  useEffect(() => {
    if (link.search('resetToken=') !== -1) {
      const pos = link.slice(link.search('resetToken='));
      const resetToken = pos.substring(11);
      setResetToken(resetToken);
    }
    if (link.search('token=') !== -1) {
      const pos = link.slice(link.search('token='));
      const registerToken = pos.substring(6);
      setRegisterToken(registerToken);
    }
  }, []);

  useEffect(() => {
    if (resetSuccess) {
      setTimeout(() => dispatch(authActions.removeSuccess()), 3000);
    }
  }, [resetSuccess]);

  const resetLink = httpOnSuccess.length && httpOnSuccess[0].type === 'GET_RECOVERY_LINK';
  const token = resetToken ? resetToken : registerToken ? registerToken : '';
  const error = httpOnError.length && httpOnError[0].type;
  const toastFail = ToastFail(error);

  return (
    <div className="login-fragment">
      {/* <LoginHeader /> */}
      <div className="new-container">
        {/* Left Side */}
        <div className="left-side">
          <div className="site-log">
            <p style={{ cursor: 'pointer' }} onClick={handleChangePage}>
              Polo TMS
            </p>
          </div>
          <div className="box-title">
            <div className="loginPageTitle">
              <p>
                Transportation
                <br />
                Management <br />
                System
              </p>
            </div>
          </div>
        </div>
        {/* Right Side */}

        <div className="right-side">
          <div className="site-phone">
            <div className="login-phone">
              <a href={`tel:+15623910015`}>
                <img src={Images.PhoneOrange} alt={'Phone'} />
                <span>(562) 391-0015</span>
              </a>
            </div>
          </div>
          <div className="form-container">
            {resetLink === true ? (
              <MessageScreen type={resetLink} />
            ) : closeResetSuccess !== 'close' && token ? (
              <ResetModal
                reset={!!resetToken}
                register={!!registerToken}
                resetToken={token}
              />
            ) : forgot === false ? (
              <LoginModal handleForgot={handleForgot} />
            ) : (
              <ForgotModal />
            )}
          </div>
        </div>
      </div>
      <div className="smal-container">
        <div className="image-side">
          <div className="side-header">
            <div className="site-log ">
              <p style={{ cursor: 'pointer' }} onClick={handleChangePage}>
                Polo TMS
              </p>
            </div>
            <div className="login-phone">
              <a href={`tel:+15623910015`}>
                <img src={Images.PhoneOrange} alt={'Phone'} />
                <span>(562) 391-0015</span>
              </a>
            </div>
          </div>
          <div className="side-center">
            <div className="form-container">
              {resetLink === true ? (
                <MessageScreen type={resetLink} />
              ) : closeResetSuccess !== 'close' && token ? (
                <ResetModal
                  reset={!!resetToken}
                  register={!!registerToken}
                  resetToken={token}
                />
              ) : forgot === false ? (
                <LoginModal handleForgot={handleForgot} />
              ) : (
                <ForgotModal />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className={classes.newContainer}>
        <div className={classes.leftSide}>
          <div className={classes.siteLog}>
            <p style={{ cursor: "pointer" }} onClick={handleChangePage}>
              Polo TMS
            </p>
          </div>
          <div className={classes.boxTitle}>
            <div className={classes.loginPageTitle}>
              <p>
                Transportation
                <br />
                Management <br />
                System
              </p>
            </div>
          </div>
        </div>
        <div className={classes.rigthSide}>
          <div className={classes.sitePhone}>
            <div className={classes.LoginHeadPhoneNumber}>
              <a href={`tel:+15623910015`}>
                <img src={Images.PhoneOrange} alt={"Phone"} />
                <span>(562) 391-0015</span>
              </a>
            </div>
          </div>
          <div className={classes.formContainer}>
            {resetLink === true ? (
              <MessageScreen type={resetLink} />
            ) : closeResetSuccess !== "close" && token ? (
              <ResetModal
                reset={!!resetToken}
                register={!!registerToken}
                resetToken={token}
              />
            ) : forgot === false ? (
              <LoginModal handleForgot={handleForgot} />
            ) : (
              <ForgotModal />
            )}
          </div>
        </div>
      </div> */}

      {/* <div className={classes.loginPageBody}>
        <div className={classes.loginPageBodyPosition}>
          <div className={classes.loginPageTitle}>
            <p className="brd">
              Transportation
              <br />
              Management <br />
              System
            </p>
          </div>
          {resetLink === true ? (
            <MessageScreen type={resetLink} />
          ) : closeResetSuccess !== "close" && token ? (
            <ResetModal
              reset={!!resetToken}
              register={!!registerToken}
              resetToken={token}
            />
          ) : forgot === false ? (
            <LoginModal handleForgot={handleForgot} />
          ) : (
            <ForgotModal />
          )}
          {resetSuccess === true && (
            <MessageMiniScreen text={"Reset password success"} />
          )}
        </div>
      </div> */}
      <div className={classes.CopyRightPos}>
        <CopyRight />
      </div>
      <Toast type={'error'} text={toastFail} info={toastFail} />
    </div>
  );
}