export const AuthorityTypes = [
  { name: 'Default', id: 'DEFAULT' },
  { name: 'Brokerage', id: 'BROKERAGE' },
  { name: 'Carrier', id: 'CARRIER' },
  { name: 'Freight Forwarder', id: 'FREIGHT_FORWARDER' },
];

export const LpfFeeEnums = {
    DEFAULT: 'DEFAULT',
    CUSTOM: 'CUSTOM',
};
export const LpfFee = [
  { name: 'Default', id: LpfFeeEnums.DEFAULT },
  { name: 'Custom', id: LpfFeeEnums.CUSTOM },
];

