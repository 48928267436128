import axios from "axios";

export const authService = {

  /** System Brackets */

  addServiceBracketService: ( body ) => axios.post(`/system/brackets`, body, {auth:true}),

  editServiceBracketService: ( id, body ) => axios.patch(`/system/brackets/${id}`, body, {auth:true}),

  getServiceBracketService: ( ) => axios.get(`/system/brackets`, {auth:true}),

  deleteServiceBracket: ( id ) => axios.delete(`/system/brackets/${id}`, {auth:true}) ,

  /** End */
};