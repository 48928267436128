import React, {useEffect, useState} from "react";
import {CheckEdit} from "utils";
import {
    AddCircle,
    Line, NoYet,
    SimpleModal,
} from "components";
import {createLoadStyle} from "./styles";
import {Items} from "./common/items";
import {DropPickModal} from "./common/dropPickModal";
import { StopType } from "utils/types";

export const Destination = ({ info }) => {
    const classes = createLoadStyle();
    const [open, setOpen] = useState(false)
    const [modalType, setModalType] = useState('')
    const [modalInfo, setModalInfo] = useState(null)

    useEffect(() => {
        if (info?.stops) {
            info?.stops.sort(custom_sort)
        }
    }, [info])

    const handleOpenClose = (type) => {
        setModalType(type)
        setOpen(!open)
        setModalInfo(null)
    }

    const handleEditOpen = (info, type) => {
        setOpen(true)
        setModalType(type)
        setModalInfo(info)
    }

    function custom_sort(a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    }

    return (
        <div className='full-width-flex-able'>
            <div className={'MuiAccordionDetails-root'}>
                <Line height={'460px'}/>
            </div>

            <div className='load-row--column-wrapper' style={{marginTop: '0'}}>
                <div className={classes.destinationWrapper}>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            <p className={classes.cardtTitle}>Pickups</p>
                                <AddCircle
                                    handleCLic={() => handleOpenClose('pickup')}
                                    text={'Add Pickup'}
                                />
                        </div>
                        <div className={classes.cardBody}>
                            {info && info?.stops && info?.stops ? info.stops.map((i, k) => (
                                    i.type === StopType.PICKUP &&
                                    <React.Fragment key={k}>
                                        <Items
                                            info={info}
                                            item={i}
                                            type={'pickup'}
                                            handleEditOpen={handleEditOpen}
                                        />
                                    </React.Fragment>
                                )) :
                                <NoYet position={'center'} text={'No Pickup Yet'}/>
                            }
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            <p className={classes.cardtTitle}>Dropoffs</p>
                                <AddCircle
                                    handleCLic={() => handleOpenClose('dropoff')}
                                    text={'Add Dropoff'}
                                />
                        </div>
                        <div className={classes.cardBody}>
                            {info && info?.stops && info?.stops ? info.stops.map((i, k) => (
                                    i.type === StopType.DROPOFF &&
                                    <React.Fragment key={k}>
                                        <Items
                                            info={info}
                                            item={i}
                                            type={'dropoff'}
                                            handleEditOpen={handleEditOpen}
                                        />
                                    </React.Fragment>
                                )) :
                                <NoYet position={'center'} text={'No Dropoff Yet'}/>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DropPickModal
                        modalInfo={modalInfo}
                        type={modalType}
                        handleClose={handleOpenClose}
                    />
                }
            />
        </div>
    )
}