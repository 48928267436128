import React, { Fragment } from "react";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Images } from "utils";

export const RevolutionizeCore = () => {
  /**
   * Header Core.
   */

  const history = useHistory();

  const handleLogIn = () => {
    history.push("/login");
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className="order-content">
          <div className="column">
            <h2 className="title-we">
              Revolutionize Your Logistics Operations with Our Advanced TMS
              Software
            </h2>
            <div className="box-text">
              <p className="type-text">
                Our powerful TMS software is designed to simplify and automate
                your logistics operations, from transportation planning and
                execution to real-time tracking and reporting
              </p>
            </div>
            <div className="btn-box">
              <Button
                variant="contained"
                className="dark-link blue-btn"
                onClick={() => handleLogIn()}
              >
                Get Start
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="order-img ">
          <img src={Images.heroVisual} alt="Hero-Visual" className="full-img" />
        </Grid>
      </Grid>
    </Fragment>
  );
};
