import axios from "axios";

export const noteService = {
    getGlobalNotesService: (id, params) => axios.get(`/comments`, {auth: true, params: {resourceId: id, ...params}}),

    createGlobalNoteService: (body) => axios.post(`/comments`, body, {auth: true}),

    editGlobalNoteService: (id, body) => axios.patch(`/comments/${id}`, body, {auth: true}),

    deleteGlobalNoteService: (id) => axios.delete(`/comments/${id}/comments`, {auth: true}),
};