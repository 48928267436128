import { buttonsStyle } from './styles';
import { Images } from 'utils';
import { MinLoader } from '../loader';
import React from 'react';

export const ExportButton = ({ handleExport, loader }) => {
  const classes = buttonsStyle();

  return (
    <button onClick={handleExport} className={classes.exportButton}>
      {loader ?
        <MinLoader style={{ margin: 0 }} position={'relative'} color={'white'} />
        :
        <>
          <img
            style={{ filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)' }}
            src={Images.exportIcon} alt='icon' />
          Export
        </>
      }
    </button>
  );
};