import React from "react";
import { Images } from "utils";
import { logoStyle } from "./styles";

export const GlobalLogo = ({}) => {
  const classes = logoStyle();

  return (
    <div className={classes.GlobalLogo}>
      <img src={Images.logo} alt="tracker" />
    </div>
  );
};
