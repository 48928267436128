import axios from "axios";


export const UploadServices = {

    UploadImage: (formData) => axios.post('/files/upload?includeThumbnail=false', formData, { auth: true, params: '' }),

    deleteFile: (id) => axios.delete(`/files/${id}`, { auth: true })

}
