export const getMenuTitle = (url = '') => {

  if (url.startsWith('/humanResource/')) return 'detail';
  if (url.startsWith('/createHumanResources')) return 'detail';

  if (url.startsWith('/office/')) return 'detail';
  if (url.startsWith('/createOffice')) return 'detail';

  if (url.startsWith('/branch/')) return 'detail';
  if (url.startsWith('/createBranch')) return 'detail';

  if (url.startsWith('/agent/')) return 'detail';
  if (url.startsWith('/createAgent')) return 'detail';

  if (url.startsWith('/authority/')) return 'detail';
  if (url.startsWith('/createAuthorities')) return 'detail';

  if (url.startsWith('/customer/')) return 'detail';
  if (url.startsWith('/createCustomer')) return 'detail';

  if (url.startsWith('/carrier/')) return 'detail';
  if (url.startsWith('/createCarriers')) return 'detail';

  if (url.startsWith('/factoring/')) return 'detail';
  if (url.startsWith('/createFactoring')) return 'detail';

  if (url.startsWith('/load/')) return 'detail';
  if (url.startsWith('/createLoad')) return 'detail';

  if (url.startsWith('/carrierPayment/')) return 'detail';

  if (url.startsWith('/customerPayment/')) return 'detail';

  if (url.startsWith('/equipment/')) return 'detail';

  if (url.startsWith('/template/')) return 'detail';


  switch (url) {

    case '/humanResources':
      return 'Human Resources';

    case '/offices':
      return 'Offices';

    case '/branches':
      return 'Branches';

    case '/agents':
      return 'Agents';

    case '/authorities':
      return 'Authorities';

    case '/customers':
      return 'Customers';

    case '/carriers':
      return 'Carriers';

    case '/factorings':
      return 'Factoring Companies';

    case '/loads':
      return 'Loads';

    case '/trucks':
      return 'Truck Board';

    case '/credit':
      return 'Credit Check';

    case '/rejected':
      return 'Rejected';

    case '/consign':
      return 'Consignment';

    case '/processing':
      return 'Processing';

    case '/loadVerification':
      return 'Load Verifications';

    case '/paymentVerification':
      return 'Payment Verifications';

    case '/payables':
      return 'Payables';

    case '/billing':
      return 'Billing';

    case '/receivables':
      return 'Receivables';

    case '/carrierPayments':
      return 'Carrier Payments';

    case '/customerPayments':
      return 'Customer Payments';

    case '/bonds':
      return 'Bonds';

    case '/claims':
      return 'Claims';

    case '/payroll':
      return 'Payroll';

    // case '/management':
    //   return 'Access Management';

    case '/system':
      return 'System';

    case '/ar':
      return 'Ar Report';

    case '/ap':
      return 'Ap Report';

    case '/templates':
      return 'Load Templates';

    case '/directory':
      return 'Directory';

    default:
      return '';
  }
};
