import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Colors,
    ErrorText,
    FindLoad,
    FindSuccessItem,
    useGlobalTextStyles
} from "utils";
import {CloseButton, MinLoader, ValidationInput} from "components";
import {Button} from "@material-ui/core";
import {customerActions, httpRequestsOnSuccessActions} from "store";
import {customerInformation} from "./styles";

export const InactiveMcAuthority = ({handleClose, info, param}) => {
    const classes = customerInformation()
    const globalText = useGlobalTextStyles();
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const success = FindSuccessItem('ADD_CUSTOMER_BLACKLIST')
    const loader = FindLoad('ADD_CUSTOMER_BLACKLIST')

    const handleChange = (ev) => {
        setMessage(ev.target.value)
        setError('')
    }

    const handleInactivate =()=>{
        if(message){
            dispatch(customerActions.assignBlackList(param, info, param, message))
        }else{
            setError('message')
        }
    }

    useEffect(() => {
        if(success){
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ADD_CUSTOMER_BLACKLIST'))
        }
    }, [success])

    return (
        <div className={classes.inactiveModal}>
            <div className={classes.closeButton}>
                <CloseButton handleCLic={handleClose}/>
            </div>

            <div className={classes.modalPaddings}>
                <p className={globalText.modalTitle}>Inactivate MC Authority?</p>
                <p className={globalText.modalText}>MC Authority will be notified about the inactivation reason after inactivation.</p>
                <ValidationInput
                    multiline={true}
                    style={classes.inactiveInput}
                    onChange={handleChange}
                    value={message}
                    typeError={error === 'message' && ErrorText.field}
                    label={"Write inactiavtion reason here..."}
                    type={"text"}
                    variant={"outlined"}
                    name={"message"}
                />
                <div className={classes.lineWrapper}>
                    <Button
                        style={{
                            textTransform: "capitalize",
                            width: "100%",
                            height: "48px",
                            background: Colors.ThemeGreen,
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: Colors.TextWhite
                        }}
                        onClick={ handleInactivate }
                    >
                        {loader?.length ?
                            <MinLoader margin={'0'} color={Colors.TextWhite}/>
                            :
                            'Inactivate'
                        }

                    </Button>
                </div>
            </div>
        </div>
    )
}