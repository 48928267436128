import React, { Fragment } from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Images } from "utils";
import LinkedinWhite from "../../../assets/images/landing/linkedin-white.svg";

export const FooterCore = ({ handleContact }) => {
  /**
   * Header Core.
   */

  return (
    <Fragment>
      {/* Footer */}
      <footer className="footer">
        <Container maxWidth="lg">
          <div className="footer-section">
            <div className="footer-img">
              <button className="contact-btn" onClick={() => handleContact()}>
                <span className="privacy-text ">Contact Us</span>
              </button>
              <div className="social-box">
                <a href="https://www.instagram.com/polo_tms/" target="_blank">
                <img src={Images.InstagramWhite} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/polotms" target="_blank">
                  <img src={Images.FacebookWhite} alt="Facebook" />
                </a>
                <a href="https://www.linkedin.com/in/polo-tms-165180258" target="_blank">
                  <img src={Images.LinkedinWhite} alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="footer-content">
              <div className="footer-title">
                <Link to="/terms" className="terms-link">
                  <span className="privacy-text">Terms and Condition</span>
                </Link>
                <span className="space" />
                <Link to="/privacy" className="privacy-link">
                  <span className="privacy-text">Privacy Policy</span>
                </Link>
              </div>
              <div className="footer-info">
                2023 ©Polo TMS. All rights reserved.
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </Fragment>
  );
};
