import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const paymentVerificationFragments = makeStyles(() => ({
    blueButton: {
        width: '141px',
        height: '32px',
        background: '#438AFE 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    correctionNeeded: {
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '600',
        color: '#51566D',
    },
    correctionList: {
        display: "flex",
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: Colors.ThemeBlue,
        },
        '& div': {
            background: '#E6ECF380 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            padding: '9px 11px',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '18px',
                lineHeight: '24px',
                color: Colors.TextSecondary,
            },
        },
    },
    tableHead: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
        padding: '13px 0 13px 32px',
        marginTop: '16px',
        '& p': {
            width: '200px',
        },
        '& span': {
            width: '116px'
        },
    },
    tableBodyWrapper: {
        maxHeight: '250px',
        minHeight: '250px',
        overflow: 'auto',
    },
    tableBody: {
        display: 'flex',
        marginBottom: '8px',
        '& input': {
            width: '210px',
            height: '48px',
            marginRight: '8px',
            border: '1px solid #51566DB3',
            borderRadius: '4px',
            color: '#51566DB3',
            fontSize: '16px',
            padding: '0 16px',
            '& :placeholder': {
                color: '#51566D80',
                fontSize: '16px',
                padding: '0 16px',
            },
        },
    },
    row:{
            width: '200px',
            background: '#E6ECF380 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            padding: '13px 32px',
            marginRight: '8px',
            '& p': {
                fontSize: '16px',
                fontWeight: '600',
                color: Colors.TextPrimary,
                lineHeight: '22px'
            },
    },
    collectButton:{
        width: '116px',
        height: '48px',
        background: '#438AFE 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
        border: 'none',
        '&:hover': {
            background: Colors.ThemeDarkBlue
        },
    },
    doneButtonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));
