import {GET_HISTORY} from "./history.types";

/** Get History */

export const getHistory = (onModel, queryParams, type) => {
    return {
        type: GET_HISTORY,
        payload: { onModel, queryParams, type },
    };
};

/** End */
