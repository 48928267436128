import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from './templates.service'
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    CREATE_TEMPLATE,
    DELETE_TEMPLATE,
    EDIT_TEMPLATE,
    GET_TEMPLATE_BY_ID, GET_TEMPLATE_BY_ID_SUCCESS,
    GET_TEMPLATES,
    GET_TEMPLATES_SUCCESS
} from "./templates.types";

/** Templates Workers */

function* createTemplate({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createTemplateService, payload?.body);
        yield put({
            type: GET_TEMPLATES,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* editTemplate({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {

        yield call(authService.editTemplateService, payload?.id, payload?.body);
        if (payload?.byId) {
            yield put({
                type: GET_TEMPLATE_BY_ID,
                payload: {id: payload?.id, load: 'noLoad'},
            });
        } else {
            yield put({
                type: GET_TEMPLATES,
                payload: {params: payload?.params, load: 'noLoad'},
            });
        }
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* deleteTemplate({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.deleteTemplatesService, payload?.id);
        yield put({
            type: GET_TEMPLATES,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* getTemplates({payload, type}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getTemplatesService, payload?.body);
        yield put({
            type: GET_TEMPLATES_SUCCESS,
            payload: res?.data
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* getTemplateById({payload, type}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getTemplateByIdService, payload?.id);
        yield put({
            type: GET_TEMPLATE_BY_ID_SUCCESS,
            payload: res?.data
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}


/** End */


export const watchTemplate = function* watchTemplateSaga() {
    /** Templates */
    yield takeLatest(CREATE_TEMPLATE, createTemplate);
    yield takeLatest(EDIT_TEMPLATE, editTemplate);
    yield takeLatest(DELETE_TEMPLATE, deleteTemplate);

    yield takeLatest(GET_TEMPLATES, getTemplates);
    yield takeLatest(GET_TEMPLATE_BY_ID, getTemplateById);

    /** End */


};
