import {DateRow, PriceRow, TextRow, TypeRow} from "components";
import React from "react";

export const customerPmtHead = [
    {name: '', title: 'Created Date', smallSize: 6, icon: 'date', type: 'date'},
    {name: 'factoringName', title: 'Factoring/Customer', custom: false},
    {name: 'amount', title: 'Amount', custom: false},
    {name: '', title: 'Distributed Amount', custom: false, smallSize: 8,},
    {name: '', title: 'Remaining Amount', custom: false, smallSize: 8,},
    {
        name: '', title: 'Type',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Check', id: 'CHECK'},
            {name: 'Ach', id: 'ACH'},
            {name: 'Cod', id: 'COD'},
            {name: 'Lockbox', id: 'LOCKBOX'},
            {name: 'Wire', id: 'WIRE'},
        ]
    },
    {name: 'reference', title: 'Ref. Number', custom: false, smallSize: 6},
    {
        name: 'status', title: 'Status',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Applied', id: 'APPLIED'},
            {name: 'Open', id: 'OPEN'},
            {name: 'Voided', id: 'VOIDED'},
        ]
    },
]

export const customerPmtBody = [
    {rowText: (item) => <DateRow date={item?.pmtDate}/>},
    {rowText: (item) => <TextRow name={item?.factoringName ? item?.factoringName : item?.customerName}/>},
    {rowText: (item) => <PriceRow info={item?.amount}/>},
    {rowText: (item) => <PriceRow info={item?.used}/>},
    {rowText: (item) => <PriceRow info={item?.amount - item?.used}/>},
    {rowText: (item) => <TypeRow text={item?.pmtMethod}/>},
    {rowText: (item) => <TextRow name={item?.reference}/>},
    {rowText: (item) => <TypeRow text={item?.status}/>},
]

export const ACTION_TYPE = 'GET_CUSTOMER_PAYMENT'

export const customerPaymentsStatuses = [
    { id: 'OVERVIEW', name: 'Overview' },
    { id: 'DETAILED', name: 'Detailed' }
];

export const customerPaymentCsv = {
    type:'customerPayment',
    statusList: customerPaymentsStatuses,
    dateFilter: true,
    requiredDateRange: true,
    requiredStatus: true,
}