import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    FindLoad,
    FindSuccessItem,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {carrierInfoFragments} from "./styles";
import {DeleteButton, DeleteElement, Line, NoYet, SelectInput, SimpleModal} from "components";
import {carrierActions, httpRequestsOnSuccessActions} from "store";

export const Blacklist = ({}) => {
    const {blackListBranch, branchesList,} = useSelector((state) => ({
        blackListBranch: state.carriers.blackListBranch,
        branchesList: state.branches.branchesList,
    }));
    const globalText = useGlobalText();
    const classes = carrierInfoFragments()
    const globalStyles = useGlobalStyles();
    const params = useParams()
    const dispatch = useDispatch()
    const [assignId, setId] = useState('')
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [factoring, setFactoring] = useState('')
    const loader = FindLoad('ASSIGN_CARRIER_ACCESS')
    const success = FindSuccessItem('UNBLACKLIST_BRANCH')

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('UNBLACKLIST_BRANCH'))
            setOpen(!open)
        }
    }, [success])

    const handleChangeFromList = (ev) => {
        setFactoring(ev.target.value)
        const id = params.id
        const entityId = ev.target.value
        dispatch(carrierActions.assignCarrierAccess(id, entityId, 'BLACKLIST_BRANCH'))
    }

    const handleOpenClose = (item) => {
        setName(item.name)
        setId(item.id)
        setOpen(!open)
    }

    const handleOpenCloseRemoveModal = () => {
        setOpen(!open)
        setName('')
        setId('')
    }

    const handleRemove = () => {
        const id = params.id
        dispatch(carrierActions.assignCarrierAccess(id, assignId, 'UNBLACKLIST_BRANCH'))
    }

    const filteredOfficeList = branchesList?.branches?.length && branchesList.branches.filter(function (array_el) {
        return blackListBranch && blackListBranch.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    const checkRole = RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code])

    return (
        <div className={classes.access}>
            <Line height={'430px'}/>
            <div className={classes.blackWrapper}>
                <p className={globalText.smallText}>Blacklisted Branches</p>
                <div className={classes.authoritiesSelect}>
                    <SelectInput
                        disabled={!checkRole}
                        type={'name'}
                        name={"selectOffices"}
                        label={"Select Authorities"}
                        handleSelect={(ev) => handleChangeFromList(ev)}
                        value={factoring}
                        list={filteredOfficeList && filteredOfficeList}
                    />
                </div>
                <div>
                    {blackListBranch.length ?
                        blackListBranch.map((i, j) => (
                            <div key={j} className={'authorites'}>
                                {i.name}
                                {checkRole &&
                                    <DeleteButton
                                        toolTipTitle={`Remove ${i.name}`}
                                        handleClick={() => handleOpenClose(i)}
                                    />
                                }
                            </div>
                        ))
                        :
                        <NoYet text={'No Branches Yet'}/>
                    }
                </div>
                <SimpleModal
                    handleOpenClose={handleOpenCloseRemoveModal}
                    openDefault={open}
                    content={
                        <DeleteElement
                            loading={!!loader?.length}
                            text={`Remove ${name}?`}
                            className={globalStyles}
                            handleClose={handleOpenCloseRemoveModal}
                            handleDel={handleRemove}
                            info={name}
                        />
                    }
                />
            </div>
        </div>
    )
}