import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, TextField } from '@material-ui/core';
import { inputsStyle } from './styles';
import { ErrorText, FindLoad, useGlobalStyles } from 'utils';
import { ErrMessage } from '../messages';

export const SelectTransaction = ({
                                    list,
                                    label,
                                    type,
                                    title,
                                    defaultValue,
                                    name,
                                    handleSelect,
                                    style,
                                    disabled,
                                    error,
                                    typeError,
                                    handleType,
                                    loadType,
                                  }) => {
  const classes = inputsStyle();
  const globalStyles = useGlobalStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const loader = FindLoad(loadType);

  const defaultProps = {
    options: value ? list?.length && list?.filter((i) => i.id !== value.id) : list?.length ? list : [],
    getOptionLabel: (option) => label && type ? `${option[label]} (${option[type]})` : option[label] || '',
  };

  const handleClick = (e, ev) => {
    setValue(ev);
    if (ev) {
      let info = { target: { value: ev.id, name: name } };
      handleSelect(info);
      setValue(ev.id);
    } else {
      let info = { target: { value: '', name: name } };
      handleSelect(info);
      setValue('');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      list?.find((i, k) => {
        i.id === defaultValue ? setValue(i) : '';
      });
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  return (
    <>
        <div className={style ? style : ''}>
          <Autocomplete
            loading={!!loader?.length}
            className={
              error === name ? globalStyles.inputTextFieldError :
                defaultValue ? globalStyles.inputTextFieldBlue :
                  classes.inputTextField
            }
            value={value}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disabled={disabled}
            {...defaultProps}
            style={{ width: '100%', marginRight: '56px' }}
            onChange={(event, newValue) => {
              handleClick(event, newValue);
            }}
            id="disable-close-on-select"
            name={name}
            renderOption={(option, { selected }) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <p>{option?.name}</p>
                </div>
                {option?.warn &&
                  <div style={{ color: '#FFA330' }}>
                    Admin Only
                  </div>
                }
              </div>
            )}

            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                variant="outlined"
                onChange={(e) => handleType && handleType(e)}
              />
            )}
          />
          {error === name &&
            <ErrMessage text={typeError ? typeError : ErrorText.field} style={{ position: 'relative' }} />}
        </div>
    </>
  );
};