import {
  addLoadToCustomerPayment,
  createCustomerPayment,
  createCustomerPaymentLoad,
  deleteCustomer,
  editCustomerPayment,
  getCustomerPayment,
  getCustomerPaymentById,
  getCustomerPaymentLoad,
  voidCustomerPaymentLoad,
} from "./customerPayment.action";

export {customerPaymentReducer} from './customerPayment.reducer';
export {watchCustomerPayments} from './customerPayment.saga';


export const customerPaymentActions = {
  createCustomerPayment,
  editCustomerPayment,
  getCustomerPayment,
  getCustomerPaymentById,
  deleteCustomer,
  // addLoadToCustomerPayment,

  createCustomerPaymentLoad,
  getCustomerPaymentLoad,
  voidCustomerPaymentLoad,

}

