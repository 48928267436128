import React, {useState} from "react";
import {CreateCarriersInput} from "./core";
import { CreateWrapper } from "components";
import { Images, useGlobalStyles } from "utils";

export const CreateCarrier = ({ }) => {
  const globalStyle = useGlobalStyles();
  const [name, setName] = useState('')
  return (
    <div>
      <CreateWrapper
        head={
              <div className={globalStyle.createOfficeTableHead}>
                <img src={Images.carrierBold} alt={"Carrier"} />
                <p>{name ? name : 'CARRIER NAME'}</p>
              </div>
        }
        body={
          <CreateCarriersInput
            handleChangeName ={setName}
          />
        }
        parentLink={'/carriers'}
        parent={'Carriers'}
        child={'Add Carrier'}
      />
    </div>
  );
};
