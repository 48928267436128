import React, { useEffect, useState } from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { buttonsStyle } from './styles';
import { SavePage } from '../../utils';
import { useHistory } from 'react-router-dom';

export const CustomButtonsTab = ({ first, second, firstName, secondName, width, maxWidth, defaultTab }) => {
  const classes = buttonsStyle();
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 0);
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    if (info) {
      const statusType =
        info?.tabType === firstName ? 0 : 1;
      setValue(info?.tabType ? statusType : 0);
    }
  }, [info]);

  const handleChange = (event, newValue) => {
    const statusType = newValue === 0 ? firstName : secondName;
    setValue(newValue);
    const infoItem = { ...info, page: 1, skip: 0, limit: 100 };
    infoItem.tabType = statusType;
    SavePage(history, info, { ...infoItem });
  };

  return (
    <Paper
      style={{
        maxWidth: maxWidth ? maxWidth : '244px',
        width: 'auto',
        border: 'none',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '18px',
        padding: '0 2px',
      }}
      square
    >
      <Tabs
        className={classes.buttonsTab}
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {first && <Tab style={{ width: width ? width : '100px' }} label={first} />}
        {second && <Tab style={{ width: width ? width : '100px' }} label={second} />}
      </Tabs>
    </Paper>
  );
};
