import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {TableWrapper, FullTable} from "components";
import {branchActions} from "store";
import {ACTION_TYPE, branchesHead, branchesBody} from "./constants";
import {PermissionsList} from "utils";

export const Branches = ({}) => {
    const {branchesList} = useSelector((state) => ({
        branchesList: state.branches.branchesList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
            const filteredInfo = {
                ...info
            }
            filteredInfo.skip = info?.skip ? info?.skip : 0
            filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
            filteredInfo.limit = 100
            delete filteredInfo.page
            delete filteredInfo.activeTab
            dispatch(branchActions.getBranches( { ...filteredInfo } ))
    }, [info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/branch/${id}`,
            state: {branchParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add Branch"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createBranch'}
            link={true}
            createPermisson={PermissionsList.CREATE_UPDATE_BRANCH}
                type={'officeManager'}
        >
            <FullTable
                head={branchesHead}
                body={branchesBody}
                loadingType={ACTION_TYPE}
                list={branchesList?.branches}
                listCount={branchesList?.count}
                handleClick={pushPageDetails}
                noText={'Branches'}
            />
        </TableWrapper>
    );
};