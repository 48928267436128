import React from "react";
import {useSelector} from "react-redux";
import {renderIcon} from "../constants";
import {TextRow} from "components";
import {Images, useModal} from "utils";
import {CreateTemplate} from "../fragments";

export const TemplateCard = ({}) => {
    const {templateById} = useSelector((state) => ({
        templateById: state.templates.templateById,
    }));
    const {open} = useModal()

    return (
        <div className='template-card-wrapper' style={{alignItems: 'flex-start', height: 'auto'}}>
            <div className='template-image-and-text' style={{cursor: 'unset', alignItems: 'flex-start'}}>
                {renderIcon(templateById?.color)}
                <div className='full-width' style={{marginLeft: '12px'}}>
                    <p className='template-title'>
                        <TextRow name={templateById?.name} textWidth={10}/>
                    </p>
                    <p className='template-description'>
                        <TextRow
                            name={templateById?.description ? templateById?.description : 'Not Set'}
                            textWidth={10}
                        />
                    </p>
                </div>
            </div>
            <div className='action-buttons-wrapper'>
                <button>
                    <img
                        src={Images.edit}
                        alt="icon"
                        onClick={() => open(<CreateTemplate template={templateById} byId={true}/>)}
                    />
                </button>
            </div>
        </div>
    )
}