import React from "react";
import {FactoringInfoTable} from "./core";

export const FactoringInfo = ({info, setCustomerName}) => {
    return (
        <FactoringInfoTable
            handleChangeName={setCustomerName}
            info={info}
        />
    );
}