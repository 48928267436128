
export const ToastSuccess =(success)=>{

    if(success){
       return (
           success === 'EDIT_OFFICE' ? 'Office Was Edited' :
           success === 'EDIT_ADMIN' ? 'Admin Was Edited' :
           success === 'EDIT_BRANCH' ? 'Branch Was Edited' :
           success === 'DELETE_ROLE' ? 'Role Was Deleted' :
           success === 'EDIT_CUSTOMER' ? 'Customer Was Edited' :
           success === 'EDIT_FACTORING' ? 'Factoring Was Edited' :
           success === 'EDIT_CARRIER' ? 'Carrier Was Edited' :
           success === 'EDIT_AGENT' ? 'Agent Was Edited' :
           success === 'RESEND_AGENT_INVITE' ? 'Invite was resented' :
           success === 'RESEND_ADMIN_INVITE' ? 'Invite was resented' :
           success === 'CREATE_OFFICE_MANAGERS' ? 'Office Manager Was Created' :
           success === 'SELECT_OFFICE_MANAGERS' ? 'Office Manager Was Created' :
           success === 'REMOVE_OFFICE_MANAGERS' ? 'Office Manager Was Deleted' :
           success === 'SUBTRACT_OFFICE_EXPENSES' ? 'Expenses was subtracted' :
           success === 'ACTIVATE_OFFICE' ? 'Office Was Activated' :
           success === 'INACTIVATE_OFFICE' ? 'Office Was Inactivated' :
           success === 'ACTIVATE_BRANCH' ? 'Branch Was Activated' :
           success === 'INACTIVATE_BRANCH' ? 'Branch Was Inactivated' :
           success === 'CREATE_BRANCH_MANAGERS' ? 'Branch Agent Was Created' :
           success === 'SELECT_BRANCH_MANAGERS' ? 'Branch Manager Was Created' :
           success === 'REMOVE_BRANCH_MANAGERS' ? 'Manager Was Deleted' :
           success === 'INACTIVATE_AGENT' ? 'Agent Was Inactivated' :
           success === 'ACTIVATE_AGENT' ? 'Agent Was Activated' :
           success === 'INACTIVATE_ADMIN' ? 'Admin Was Inactivated' :
           success === 'ACTIVATE_ADMIN' ? 'Admin Was Activated' :
           success === 'ADD_ADMIN_ROLE' ? 'Role Was Added' :
           success === 'REMOVE_ADMIN_ROLE' ? 'Role Was Deleted' :
           success === 'CREATE_ROLE' ? 'Role Was Created' :
           success === 'DELETE_ROLE' ? 'Role Was Deleted' :
           success === 'ADD_ROLE_PERMISSION' ? 'Role Permission Was Added' :
           success === 'INACTIVATE_CUSTOMER' ? 'Customer Was Inactivated' :
           success === 'ACTIVATE_CUSTOMER' ? 'Customer Was Activated' :
           success === 'INACTIVATE_MC' ? 'Mc Was Inactivated' :
           success === 'ACTIVATE_MC' ? 'Mc Was Activated' :
           success === 'EDIT_MC' ? 'Mc Was Edited' :
           success === 'DELETE_ASSIGNED_MC' ? 'Assigned Office Was Deleted' :
           success === 'ASSIGNED_MC' ? 'Mc Was Added' :
           success === 'INACTIVATE_ACTIVATE_CARRIER' ? 'Carrier Status Was Edited' :
           success === 'ADD_CARRIER_FACTORING' ? 'Factoring Was Assigned' :
           success === 'CARRIER_FUEL' ? 'Fuel Was Edited' :
           success === 'CARRIER_PAYMENT' ? 'Payment Was Edited' :
           success === 'CARRIER_PAYMENT_TERM' ? 'Payment Term Was Edited' :
           success === 'CREATE_EQUIPMENT' ? 'Equipment Was Created' :
           success === 'ACTIVATE_EQUIPMENT' ? 'Equipment Was Activated' :
           success === 'INACTIVATE_EQUIPMENT' ? 'Equipment Was Inactivated' :
           success === 'REMOVE_EQUIPMENT' ? 'Equipment Was Deleted' :
           success === 'ASSIGN_CARRIER_ACCESS' ? 'Success' :
           success === 'ADD_CUSTOMER_BLACKLIST' ? 'Mc Was Added in Black List' :
           success === 'REMOVE_CUSTOMER_BLACKLIST' ? 'Mc Was Removed from Black List' :
           success === 'ASSIGN_OFFICE' ? 'Office Was Assigned' :
           success === 'ASSIGN_AGENT' ? 'Agent Was Assigned' :
           success === 'BLACKLIST_BRANCH' ? 'Branch Was Assigned' :
           success === 'UNASSIGN_OFFICE' ? 'Office Was Unassigned' :
           success === 'UNASSIGN_AGENT' ? 'Agent Was Unassigned' :
           success === 'UNBLACKLIST_BRANCH' ? 'Branch Was Unassigned' :
           success === 'CREATE_UPLOAD' ? 'File Was Uploaded' :
           success === 'DELETE_ROLE_PERMISSION' ? 'Role Was Deleted' :
           success === 'CHANGE_PASSWORD_REQUEST' ? 'Password Was Edited' :
           success === 'ADD_BRACKET' ? 'Agent Bracket Was Created' :
           success === 'DELETE_UPLOADS' ? 'File Was Deleted' :
           success === 'ADD_SYSTEM_BRACKET' ? 'Bracket Was Created' :
           success === 'EDIT_SYSTEM_BRACKET' ? 'Bracket Was Edited' :
           success === 'EDIT_AGENT_BRACKET' ? 'Agent Bracket Was Edited' :
           success === 'DELETE_SYSTEM_BRACKET' ? 'Bracket Was Deleted' :
           success === 'DELETE_BRACKET' ? 'Bracket Was Deleted' :
           success === 'ACTIVATE_DENY' ? 'Customer Auto Deny Was Activated' :
           success === 'INACTIVATE_DENY' ? 'Customer Auto Deny Was Inactivated' :
           success === 'EDIT_LOAD' ? 'Load Was Edited' :
           success === 'AWAIT_CARRIER' ? 'Carrier was awaiting' :
           success === 'LOAD_STATUS' ? 'Status was edited' :
           success === 'RATE_CONE' ? 'Rate Confirmation was edited' :
           success === 'CUSTOMER_NON_FACTORING' ? 'Non Factoring was edited' :
           success === 'TRANSACTION_VOID' ? 'Transaction was Voided' :
           success === 'LOAD_WAS_SUBMITTED' ? 'Your Load was submitted' :
           success === 'LOAD_DETAILS' ? 'Load Details was updated' :
           success === 'REQUEST_CARRIER' ? 'Load Carrier was updated' :
           success === 'DO_NOT_PAY' ? 'Do not pay was changed' :
           success === 'ALLOW_FUEL' ? 'Allow fuel was changed' :
           success === 'IS_CONNECTED_LOAD' ? 'Connecting Loads was changed' :
           success === 'SEND_BILL' ? 'Send billing success' :
           success === 'SEND_TO_BILLING' ? 'Load send to billing' :
           success === 'LOAD_VERIFICATION' ? 'Load verification was edited' :
           success === 'ON_CLAIM' ? 'Load claim was edited' :
           success === 'ON_BOUND' ? 'Load bond was edited' :
           success === 'CORRECTION_SENDED' ? 'Correction was sent' :
           success === 'EDIT_CUSTOMER_PAYMENT' ? 'Customer payment was edited' :
           success === 'ADD_CUSTOMER_PAYMENT_CUSTOMER' ? 'Customer was added' :
           success === 'DELETE_CUSTOMER_PAYMENT_CUSTOMER' ? 'Customer was deleted' :
           success === 'VOID_CUSTOMER_PAYMENT_SUCCESS' ? 'Customer Payment was Voided' :
           success === 'VOID_CARRIER_PAYMENT_SUCCESS' ? 'Carrier Payment was Voided' :
           success === 'DEBT_COLLECTED' ? 'Debt was collected' :
           success === 'SEND_EMAIL' ? 'Email was sent' :
           success === 'SET_LOAD_DOC_STATUS' ? 'Status was edited' :
           success === 'INACTIVATE_FACTORING' ? 'Factoring Was Inactivated' :
           success === 'ACTIVATE_FACTORING' ? 'Factoring Was Activated' :
           success === 'ADD_OFFICE_EXPENSES' ? 'Expense was added' :
           success === 'SUBTRACT_TYPE' ? 'Expenses was subtracted' :
           success === 'DO_NOT_LOAD' ? 'Carrier Do not load was edited' :
           success === 'BILLING_VERIFY' ? 'Load sent to verify' :
           success === 'LOAD_REVISION' ? 'Load send to revision' :

           success === 'INVITE_RMIS' ? 'Rmis invite was sent' :
           success === 'UPDATE_RMIS_SETUP' ? 'Rmis setup was edited' :
           success === 'REFRESH_RMIS' ? 'Rmis is active' :
           success === 'UPDATE_RMIS_REQUIRED' ? 'Rmis require was edited' :

           success === 'CANCEL_LOAD' ? 'Load was canceled' :
           success === 'EDIT_CARRIER_FUEL_CARD' ? 'Fuel Card Number was edited':
           success === 'CONTACT_US' ? 'Message was sent':
           success === 'CONSIGN_CUSTOMER' ? 'Customer Consignment was edited':
           success === 'RE_BILL' ? 'Re bill was sent':
           success === 'REMOVE_LOAD_SUCCESS' ? 'Load Was Deleted':
           success === 'CUSTOMER_NO_POD' ? 'POD status was edited':
           success === 'FEE_EXEMPT' ? 'Fee Exempt was edited':
           success === 'EDIT_EQUIPMENT' ? 'Equipment was edited' :

           success === 'INVITE_CARRIER_TO_APP' ? 'Carrier was Invted' :
           success === 'RE_INVITE_CARRIER_TO_APP' ? 'Reinvite was send' :

           false
       )
    }
}
