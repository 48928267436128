import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    DeleteButton,
    DeleteElement,
    Line,
    Management,
    NoYet,
    SelectInput,
    SimpleModal
} from "components";
import {FindLoad, FindSuccessItem, Images, useGlobalStyles, useGlobalText} from "utils";
import {customerActions, httpRequestsOnSuccessActions} from "store";
import {customerInformation} from "./styles";
import {InactiveMcAuthority} from "./InactiveMcAuthority";

export const CustomerManagement = ({info}) => {
    const {mcsList} = useSelector((state) => ({
        mcsList: state.mcs.mcsList,
    }));
    const classes = customerInformation()
    const globalText = useGlobalText()
    const globalClass = useGlobalStyles()
    const params = useParams()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [openInactivate, setOpenInactivate] = useState(false)
    const [item, setItem] = useState('')
    const [mcId, setMcId] = useState('')
    const [reasonInfo, setReasonInfo] = useState('')
    const [reasoneName, serReasoneName] = useState('')
    const loader = FindLoad('REMOVE_CUSTOMER_BLACKLIST')
    const success = FindSuccessItem('REMOVE_CUSTOMER_BLACKLIST')

    useEffect(() => {
        if(success){
            dispatch(httpRequestsOnSuccessActions.removeSuccess('REMOVE_CUSTOMER_BLACKLIST'))
            setOpen(!open)
            setItem('')
        }
    }, [success])

    const deleteOffice = ( ) => {
        dispatch(customerActions.removeBlackList(params.id, item.mcId._id, params.id))
    }

    const handleChangeFromList =( ev, type ) =>{
        if(type === 'close'){
            setOpenInactivate(!openInactivate)
        }
        if(ev && ev.target.value) {
            setOpenInactivate(!openInactivate)
            setMcId(ev.target.value)
        }
    }

    // const inactiveMc = () =>{
    //     setOpenInactivate(!openInactivate)
    // }

    const handleOpenClose = (d) => {
        setOpen(!open)
        setItem(d)
    }

    const handleSaveInfo = (item) =>{
        setReasonInfo(item._id)
        serReasoneName(item.reason)
    }

    const newList = mcsList?.mcs && mcsList.mcs.filter(function (array_el) {
        return info && info.blackList && info.blackList.filter(function (anotherOne_el) {
            return anotherOne_el.mcId._id === array_el.id;
        }).length === 0
    });

    return (
        <div className='flex'>
            <Line height={'560px'}/>


        <div className={classes.accessWrapper}>

            <Management
                height={524}
                type={'big'}
                head={
                    <div className={classes.managementHead}>
                        <p className={globalText.smallText}>Inactivated Authorities</p>
                        {/*<EditSaveButtons*/}
                        {/*    butt={'done'}*/}
                        {/*    type={'EDIT_MC'}*/}
                        {/*    handleChancel={() => setDisabled(true)}*/}
                        {/*    handleSetEdit={() => setDisabled(false)}*/}
                        {/*    handleSaveInfo={handleCreate}*/}
                        {/*/>*/}
                    </div>
                }
                body={
                    <div>
                        <div className={classes.officeSelectInput}>
                            <SelectInput
                                type={'name'}
                                name={"selectMcAuthorities"}
                                label={"Select Authorities"}
                                handleSelect={(ev) => handleChangeFromList(ev)}
                                list={newList && newList}
                                value={mcId}
                            />
                        </div>


                        <div className={classes.accessBody}>
                            <div className={classes.halfItem}>
                                {info && info.blackList && info.blackList.length ?
                                     info.blackList.map((d, j) => (
                                        <div key={j} className={globalClass.item}>
                                         <div className={classes.clickableArrow} onClick={() => handleSaveInfo(d)}>
                                            <p>{d.mcId.name}</p>
                                         </div>
                                            <div >
                                            <DeleteButton
                                                toolTipTitle={"Remove MC Authority"}
                                                handleClick={( ) => handleOpenClose(d)}
                                            />
                                                <img
                                                    onClick={() => handleSaveInfo(d)}
                                                    className={classes.arrow}
                                                     src={d._id === reasonInfo ? Images.arrowLeft : Images.arrowRight}
                                                     alt="arrow"/>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoYet position={'center'} text={'No Authority Selected Yet'}/>
                                }
                            </div>


                            <div className={classes.roleNamePer}>
                                <p className={classes.roleNamTitle}>Inactivation Reason</p>
                                <div  className={classes.roleNamePerBack}>
                                    <p>{reasoneName ? reasoneName : info && info.blackList && info.blackList.length ? 'Select Mc' : 'No Authority Selected Yet' }</p>
                                {/*{item ? httpOnLoad && httpOnLoad[0] === 'BRANCH_ASSIGNED_MC' ?*/}

                                {/*    <Loader*/}
                                {/*        height={'33vh'}*/}
                                {/*        style={'relative'}/>*/}
                                {/*    :*/}
                                {/*    <div>*/}

                                {/*        <div className={classes.roleWrapper}>*/}
                                {/*            {assignedMcList.length ? assignedMcList.map((i, j) => (*/}
                                {/*                    i.office === item.id &&*/}
                                {/*                    <div key={j} className={classes.roleItem}>*/}
                                {/*                        <img src={Images.checked} alt="checked"/>*/}
                                {/*                        <p>{i.name}</p>*/}
                                {/*                    </div>*/}
                                {/*                )) :*/}
                                {/*                <NoYet position={'center'} text={'Not Assigned Branches'}/>*/}
                                {/*            }*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div className={classes.select}>*/}
                                {/*        <NoYet position={'center'} text={'Select Office'}/>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />


            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader?.length}
                        text={'Remove Mc Authority?'}
                        handleClose={handleOpenClose}
                        handleDel={deleteOffice}
                        info={item && item.mcId.name}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={() => handleChangeFromList('', 'close')}
                openDefault={openInactivate}
                content={
                    <InactiveMcAuthority
                        handleClose={() => handleChangeFromList('', 'close')}
                        // handleInactive={inactiveMc}
                        info = {mcId}
                        param ={params.id}
                    />
                }
            />
        </div>
        </div>
    )
}