import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const breadcrumbsStyle = makeStyles((theme) => ({
  root: {
    margin: "5px 0 30px 0"
  },
  parent: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: Colors.TextDarkGrey,
    textDecoration:'none',
  },
  child: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: Colors.TextSecondary
  }
}));
