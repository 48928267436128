import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, TableWrapper} from "components";
import {agentActions} from "store";
import {agentsBody, agentsHead, ACTION_TYPE} from "./constants";
import {PermissionsList} from "utils";

export const Agents = ({}) => {
    const {agentList, agentListReserve} = useSelector((state) => ({
        agentList: state.agents.agentList,
        agentListReserve: state.agents.agentListReserve,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    useEffect(() => {
        const filteredInfo = {
            ...info
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
        filteredInfo.limit = 100
        if (filteredInfo?.sortType === 'firstName') {
            filteredInfo.sortType = 'name'
        }
        delete filteredInfo.page
        delete filteredInfo.activeTab
        dispatch(agentActions.getAgents({...filteredInfo}))
    }, [info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/agent/${id}`,
            state: {agentParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Agents"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createAgent'}
            link={true}
            createPermisson={PermissionsList?.CREATE_UPDATE_AGENT}
            type={'officeManager'}
        >
            <FullTable
                head={agentsHead}
                body={agentsBody}
                loadingType={ACTION_TYPE}
                list={agentList?.agents}
                listCount={agentList?.count}
                handleClick={pushPageDetails}
                noText={'Agents'}
            />
        </TableWrapper>
    );
};
