import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CloseButton, MaskInput, MaskNoChar, ValidationInput} from "components";
import {ErrorText, FindErrorItem, FindLoad, FindSuccessItem, globalModals, useGlobalTextStyles} from "utils";
import {carrierInfoFragments} from "./styles";
import {carrierActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from "store";

export const FuelCard = ({id, info, handleClose}) => {
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const classes = carrierInfoFragments()
    const [inputs, setInputs] = useState(info ? info : {});
    const [error, setError] = useState('');
    const dispatch = useDispatch()
    const ACTION_TYPE = info ? 'EDIT_CARRIER_FUEL_CARD' : 'ADD_CARRIER_FUEL_CARD'
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccessItem(ACTION_TYPE)
    const backError = FindErrorItem(ACTION_TYPE)

    useEffect(() => {
        if(success){
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            handleClose()
        }
    }, [success])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),);
        error === e.target.name && setError('')
        backError && dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
    }


    const handleCreate = () => {
        const cardToString = inputs?.cardNumber + ''
        let str = inputs?.cardNumber + ''
        str = str.replaceAll('-', ' ');
        str = str.replaceAll('_', '')
        str = str.replaceAll(' ', '')

        if (cardToString ) {
            const sendInfo = {
                "card": str + '',
                "comment": inputs?.comment ? inputs?.comment : null
            }
            if(info){
                dispatch(carrierActions.editFuelCard(id, info?._id, sendInfo))
            }else {
                dispatch(carrierActions.addFuelCard(id, sendInfo))
            }
        } else {
            setError(
                !inputs?.cardNumber ? 'cardNumber' :
                           ''
            )
        }
    }

    return (
        <div>
            <div className={globalModalsClasses.smallModalWrapper}>
                <div className={globalModalsClasses.smallModalClose}>
                    <CloseButton handleCLic={handleClose}/>
                </div>
                <div className={globalModalsClasses.modalWrapperContent}>
                    <p className={globalText.modalTitle}>{info ? 'Edit Fuel Card' : 'Add Fuel Card'}</p>
                    <p className={globalText.modalText}>{'Please fill out the fields below fields to add  fuel card.'}</p>

                    <MaskNoChar
                        mask={"9999 9999 9999 9999 9999 9999 9999 9999"}
                        name='cardNumber'
                        label="Card Number*"
                        value={inputs?.cardNumber}
                        handleChange={handleChange}
                        info={info?.cardNumber}
                        error={error === 'cardNumber' ? ErrorText.field :
                            backError?.error?.[0] === 'card must be a credit card' ? 'Must be a credit card' :
                            backError?.error?.[0] === 'card must be longer than or equal to 16 characters' ? 'Must be a credit card' :
                            ''}
                    />

                    <ValidationInput
                        style={{marginTop: 0}}
                        onChange={handleChange}
                        typeError={error === 'comment' ?  ErrorText.field: ''}
                        className={info ? classes.inputDescriptionField : classes.inputDescription}
                        variant={"outlined"}
                        name={"comment"}
                        Length={100}
                        value={inputs?.comment}
                        label={"Comment"}
                        type={"text"}
                        multiline={true}
                    />

                    <AddModalButton
                        handleClick={handleCreate}
                        text={info ? 'Save' : 'Add'}
                        styles={{marginTop: '32px'}}
                        loading={!!loader?.length}
                    />

                </div>
            </div>
        </div>
    )
}