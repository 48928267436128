import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {httpRequestsOnSuccessActions, loadActions, loadAuthActions} from "store";
import {AddModalButton, CloseButton, modalsStyle, ValidationInput} from "components";
import {ErrorText, globalModals, useGlobalTextStyles} from "utils";
import {LOAD_REVISION} from "../../pages/accounting/constants";

export const RevisionModal = ({handleClose, info}) =>{
    const globalModalsClasses = globalModals();
    const dispatch = useDispatch()
    const globalText = useGlobalTextStyles();
    const classes = modalsStyle();
    const [instruction, setInstruction] = useState('')
    const [error, setError] = useState('')
    const [loader, setLoader] = useState( false)

    const handleChange = (e) =>{
        setInstruction(e.target.value)
        error && setError('')
    }

    const handleRevision = async ( ) =>{
        if(instruction) {
            setLoader(true)
            try {
                await loadAuthActions.loadToRevision(info.id, instruction)
                setLoader(false)
                handleClose()
                dispatch(httpRequestsOnSuccessActions.appendSuccess(LOAD_REVISION))
                dispatch(loadActions.getLoadById(info.id, 'noLoad'))
            } catch (e) {
                setLoader(false)
            }
        }else{
            setError('instruction')
        }
    }

    return(
        <div>

            <div className={globalModalsClasses.smallModalWrapper}>
                <div className={globalModalsClasses.smallModalClose}>
                    <CloseButton handleCLic={handleClose}/>
                </div>
                <div className={globalModalsClasses.modalWrapperContent}>

                    <p className={globalText.modalTitle}>Corrections</p>
                    <p className={globalText.modalText}>Please, leave notes on corrections below.</p>

                    <ValidationInput
                        className={instruction ? classes.inputTextFieldBlue : classes.inputTextField}
                        variant={"outlined"}
                        name={"instruction"}
                        label={"Add Notes"}
                        type={'text'}
                        multiline={true}
                        Length={200}
                        typeError={error === 'instruction' && ErrorText.field}
                        onChange={handleChange}
                        value={instruction}
                    />
                    <AddModalButton
                        loading={loader}
                        styles={{marginTop: '16px',}}
                        handleClick={ handleRevision}
                        text={'Add'}
                    />
                </div>
            </div>
        </div>
    )
}