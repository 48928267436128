import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {customerPaymentInfo} from "../../../customerPayment/customerPaymentInfo/core/styles";
import {useGlobalStyles, useGlobalText, useModal} from "utils";
import {PaymentTableModal} from "fragments";
import {PriceConvertor} from "../../../../components";

export const LoadTable = ({loadInfo}) => {
    const classes = customerPaymentInfo()
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const {open} = useModal()

    const handleAmount = () => {
        let price = 0
        loadInfo?.forEach((i) => {
            price = price + i.amountPaid
        })
        return <PriceConvertor price={price ? price : 0}/>
    }

    const openTransactionsModal = (item) => {
        open( <PaymentTableModal loadId={item?.loadId}/>)
    }

    return (
        <div>
            <div className={globalStyles.spaceBetween}>
                <p className={globalText.smallText}>Loads</p>
                <div className='items-style'>
                    <p>Amount: </p>
                    <span>{handleAmount(loadInfo)}</span>
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <TableContainer component={Paper}>
                    <Table sx={{width: '100%'}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow className={classes.loadTableHead}>
                                <TableCell style={{width: '30%'}}>Load ID</TableCell>
                                <TableCell style={{width: '30%'}} align="left">Amount</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody className={classes.tableBody}>
                            {loadInfo?.length ? loadInfo.map((item, k) => (
                                <TableRow
                                    key={k}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <div onClick={() => openTransactionsModal(item)} className={classes.loadItem}>
                                            <p className='modal-hyper-link'>{item?.displayId ? item?.displayId : item?.loadId}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <PriceConvertor price={item?.amountPaid ? item?.amountPaid : 0}/>
                                    </TableCell>
                                </TableRow>
                            )) : ''}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}