import { AgentProfile } from './agentProfile';
import { FactoredProfile } from './factoredProfile';
import { isAgent, isFactored } from 'utils';


export const MyProfilePage = () => {
  return(
    <div>
      {isFactored && <FactoredProfile/>}
      {isAgent &&  <AgentProfile/>}
    </div>
  )
}