import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const history = makeStyles(() => ({
    globalHistory: {
        height: 'auto',
        // height: 'calc(100vh - 380px)',
        overflow:'auto',
    },
    filterButtons:{
        display:'flex',
        width:'100%',
        justifyContent: 'flex-end',
        marginBottom:'24px',
        marginTop:'8px',
    },
    dateFiled: {
        marginRight: '16px',
        background: 'white',
        width: '180px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${Colors.ThemeBorder}!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px'
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color:  `${Colors.ThemeBlue}!important`
        },
    },
    search: {
        width: '92px',
        height: '36px',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        background: Colors.ThemeBlue,
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        // marginRight: '40px',
        textTransform: 'capitalize',
        "&:hover": {
            background: Colors.ThemeBorderBlue,
        },
    },
}))