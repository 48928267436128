import {
    CREATE_TEMPLATE,
    DELETE_TEMPLATE,
    EDIT_TEMPLATE,
    GET_TEMPLATE_BY_ID,
    GET_TEMPLATES
} from "./templates.types";

/** Templates */

export const createTemplate = (body, params) => {
    return {
        type: CREATE_TEMPLATE,
        payload: {body, params}
    }
}

export const editTemplate = (id, body, params, byId) => {
    return {
        type: EDIT_TEMPLATE,
        payload: {id, body, params, byId}
    }
}

export const deleteTemplate = (id, params) => {
    return {
        type: DELETE_TEMPLATE,
        payload: {id, params}
    }
}

export const getTemplates = ( params ) => {
    return {
        type: GET_TEMPLATES,
        payload: { params }
    }
}

export const getTemplateById = ( id, load ) => {
    return {
        type: GET_TEMPLATE_BY_ID,
        payload: {id, load}
    }
}


/** End */

