import {
    ADD_ADMIN_ROLE_SUCCESS,
    GET_ADMIN_BY_ID_SUCCESS,
    GET_ADMIN_INFO_BY_ID_SUCCESS,
    GET_ADMINS_SUCCESS,
} from "./admin.types";

const initialState = {
    adminsList: [],
    adminInfoById: '',
    assignedRoleList:[]
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Create, Edit Admin */
        case GET_ADMINS_SUCCESS:
            return {
                ...state,
                adminsList: action.payload
            }
        /** End */

        /** Get Admins */

        case  GET_ADMIN_BY_ID_SUCCESS:
            return {
                ...state,
                adminInfoById: action.payload,
            }

        case GET_ADMIN_INFO_BY_ID_SUCCESS :
            return {
                ...state,
                assignedRoleList: action.payload
            }
        /** End */


        /** Assigned Role */
        case ADD_ADMIN_ROLE_SUCCESS:
            return {
                ...state ,
                assignedRoleList: [...state.assignedRoleList, action.payload]
            }
        /** End */

        default:
            return state;
    }
};
