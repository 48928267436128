import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    AntSwitch,
    Colors,
    createInputsWrapper,
    ErrorText,
    useGlobalStyles,
    useGlobalText,
    userType
} from "utils";
import {
    Circle,
    CreateChancel, ErrMessage,
    Line,
    SelectTypeAutocomplete,
    ValidationInput
} from "components";
import {createLoadStyle} from "./styles";
import {Button} from "@material-ui/core";
import {loadActions, LoadServices} from "store";
import {useDispatch} from "react-redux";
import {CANCELLED, CREDITCHECK, DENIED, REJECTED} from "../../../../pages/accounting/constants";
import {CONSIGNMENT, CONSIGNMENT_REQUEST} from "../../../../pages/credit/constants";

export const CustomerCredit = ({
                                   info,
                                   handleChangeScreen,
                                   loadDetails,
                                   mcsListReserve,
                                   handleSwitchTonu,
                                   customers,
                                   tonu,
                               }) => {
    const globalScreens = createInputsWrapper();
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const classes = createLoadStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState('')
    const [backError, setBackError] = useState('')
    const [loader, setLoader] = useState(false)
    const [disables, setDisabled] = useState(!!info)
    const [inputs, setInputs] = useState({});

    const selectedCustomer = [
        {
            name: info?.customer?.name,
            id: info?.customer?.id,
            mc: info?.customer?.mc,
        }
    ]

    const selectedMc = [
        {
            name: info?.mc?.name,
            id: info?.mc?.id,
            mcNumber: info?.mc?.mc,
        }
    ]

    useEffect(() => {
        if (info && info.mc.id) {
            setInputs({
                "mc": info.mc.id,
                "customer": info.customer,
                "customerRate": +info.customerRate,
            })
        }
    }, [info])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),)

        if (error === e.target.name) {
            setError('')
            setBackError(null)
        }
        error === 'tonuValue' && setError('')
        setError('')
    };

    const handleTonu = () => {
        handleSwitchTonu(!tonu)
    }

    const handleCreate = async () => {
        if (inputs.mc && inputs.customer && inputs.customerRate) {
            const info = {
                "mc": inputs.mc,
                "customer": inputs.customer.id ? inputs.customer.id : inputs.customer,
                "customerRate": +inputs.customerRate,
                "isTonu": tonu,
            }
            if (tonu === true ? inputs.customerRate <= 1000 : true) {
                setLoader(true)
                try {
                    let res = await LoadServices.CreateLoad(info)
                    history.push(`/load/${res.data.id}`)
                    setLoader(false)
                    handleChangeScreen()
                    setDisabled(true)
                } catch (e) {
                    if (e?.data?.message === 'The MC is not assigned to the agent') {
                        setError('notAssigned')
                    } else {
                        setBackError(e?.data?.message)
                    }
                    setLoader(false)
                }
            } else {
                setError('tonuValue')
            }
        } else {
            setError(
                !inputs.mc ? 'mc' :
                    !inputs.customer ? 'customer' :
                        !inputs.customerRate ? 'customerRate' : ''
            )
        }
    }

    const handle = (e) => {
        if (e.target.value.length > 3) {
            setTimeout(() => {
                dispatch(loadActions.searchList(e.target.value))
            }, 500)
        }
    }

    const checkAwaiting =
        info?.status === CREDITCHECK ||
        info?.status === REJECTED ||
        info?.status === CONSIGNMENT ||
        info?.status === CONSIGNMENT_REQUEST ||
        info?.status === DENIED

    return (
        <div>
            <div className={classes.titleAndSwitcher}>
                <div className={globalStyles.centerItem}>
                    <Circle number={1} back={Colors.ThemeAqua}/>
                    <p className={globalText.title}>Customer Credit Request</p>
                </div>
                <div className={globalStyles.centerItem}>
                    <div className={globalStyles.centerItem}>
                        <span style={{width: 'auto'}} className={globalText.smallSwitchText}>{'TONU'}</span>
                        <AntSwitch
                            disabled={info && info.isTonu ? true :
                                info?.customer?.id ? true :
                                    inputs.customerRate ? inputs.customerRate > 1000 : true
                            }
                            onClick={handleTonu}
                            checked={tonu}
                        />
                    </div>
                </div>
            </div>
            <div className={globalStyles.flexAndFullWidth}>
                {loadDetails &&
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'100px'}/>
                    </div>
                }
                <div style={loadDetails === false ? {width: '100%', marginLeft: '48px'} : {width: '100%'}}>
                    <div className={globalScreens.basicInfoInputs}>
                        <SelectTypeAutocomplete
                            style={globalStyles.simpleInput}
                            name={'mc'}
                            label={'name'}
                            type={'mcNumber'}
                            title={'Booked As Authority*'}
                            handleSelect={handleChange}
                            defaultValue={inputs.mc}
                            list={
                                info ? selectedMc :
                                    mcsListReserve && mcsListReserve?.filter((i) => i?.authorityType !== "DEFAULT") || []
                            }
                            disabled={disables}
                            typeError={
                                error === 'mc' ? ErrorText.field :
                                    error === 'notAssigned' ? 'The MC is not assigned to the agent' :
                                        ''}
                            error={error === 'mc' && 'mc' || error === 'notAssigned' ? 'mc' : ''}
                        />
                        <SelectTypeAutocomplete
                            style={globalStyles.simpleInput}
                            loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
                            name={'customer'}
                            label={'name'}
                            type={'mc'}
                            title={'Customer (Name/MC)*'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.customer?.id ? inputs.customer.id : inputs.customer}
                            list={info ? selectedCustomer : customers?.length ? customers : []}
                            handleType={handle}
                            disabled={disables}
                            typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
                            error={error === "customer" ? 'customer' :
                                backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
                            }
                        />
                        <ValidationInput
                            className={inputs.customerRate && globalStyles.inputTextFieldBlue}
                            variant={"outlined"}
                            name={"customerRate"}
                            label={"Customer Rate*"}
                            type={'number'}
                            typeError={
                                error === 'customerRate' ? ErrorText.field :
                                    error === 'tonuValue' ? 'Customer Rate mut be less or equal 1000' :
                                        ''}
                            onChange={handleChange}
                            value={inputs.customerRate === 0 ? '0' : inputs.customerRate}
                            disabled={disables}
                        />
                    </div>

                    {backError && <ErrMessage text={backError}/>}

                    {info?.status !== CANCELLED && userType !== 'ADMIN' &&
                        <>
                            {checkAwaiting ?
                                <Button className={classes.greyButton}>
                                    {info?.status === CONSIGNMENT || info?.status === REJECTED || info?.status === CONSIGNMENT_REQUEST ?
                                        'Awaiting for Consignment'
                                        :
                                        info?.status === DENIED ?
                                            'Denied'
                                            :
                                            'Awaiting for Credit Check'
                                    }</Button>
                                :
                                loadDetails === false &&
                                <CreateChancel
                                    loader={loader}
                                    classes={globalStyles.buttonsStyle}
                                    create={tonu === true ? "Continue" : "Request Credit"}
                                    chancel={"Cancel"}
                                    onCreate={handleCreate}
                                    onClose={() => history.push('/loads')}
                                />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}