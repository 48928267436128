import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Circle, Line, Loader, NoYet } from 'components';
import {
  Colors,
  FindLoad,
  useGlobalStyles,
  useGlobalText,
  userInfo,
} from 'utils';
import { Payroll } from '../../fragments/agents/agentInfo/core/payroll';
import { agentActions, mcActions } from 'store';
import { agentInformation, agentInformationAddress } from './constants';

const ACTION_TYPE = 'GET_AGENT_BY_ID';
export const AgentProfile = () => {
  const { agentById, assignedAgentMc } = useSelector((state) => ({
    agentById: state.agents.agentById,
    assignedAgentMc: state.mcs.assignedAgentMc,
  }));
  const globalStyle = useGlobalText();
  const globalInputs = useGlobalStyles();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const globalClass = useGlobalStyles();

  useEffect(() => {
    dispatch(agentActions.getAgentById(userInfo.id));
    dispatch(mcActions.getAgentAssignedMcs(userInfo.id, 'AGENT'));
    dispatch(agentActions.getDefaultBracket(userInfo.id));
  }, []);

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div className="agent-profile-page">
      <div className="accordion-style">
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={1} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>My Information</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <Line height={'100%'} />
              <div className="line-fragment-box" >
                <div className="my-information-wrapper">
                  {agentInformation(agentById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
                <p className="information-box-title">Address</p>
                <div className="my-information-wrapper" style={{ marginBottom: 40 }}>
                  {agentInformationAddress(agentById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={2} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>MC Authorities</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <Line height={'100%'} />
              <div className="line-fragment-box" style={{ width: '100%' }}>
                {assignedAgentMc.length ? assignedAgentMc.map((i, j) => (
                    <div key={j} className={globalClass.item}>
                      <p>{i.name} - <span style={{ fontWeight: 'bold' }}>{i.mcNumber}</span></p>
                    </div>
                  )) :
                  <NoYet position={'center'} text={'No Authorities Yet'} />
                }
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={3} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>Payment Setup</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Payroll profileDetail={true} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};