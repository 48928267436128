import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PermissionsList, RolePermission, useGlobalStyles,} from "utils";
import {officesInfoFragments} from "./index";
import {Line, SimpleModal} from "components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {ExpensesModal} from "./expensesModal";

export const Expenses = ({}) => {
    const {officeById} = useSelector((state) => ({
            officeById: state.offices.officeById,
        })
    )
    const classes = officesInfoFragments()
    const globalStyle = useGlobalStyles()
    const [ open, setOpen ] = useState(false)
    const [ modalType, setModalType ] = useState('')
    const [ info, setInfo ] = useState('')
    const [ reqType, setReqType ] = useState('')


    const expensesList = [
        {
            title: 'Phone Lines:',
            value: officeById?.expenses?.phoneLines,
            action: 'phone',
            actionSub: 'phoneSub'
        },
        {
            title: 'DAT Account:',
            value: officeById?.expenses?.datAccounts,
            action: 'dat',
            actionSub: 'datSub'
        },
        {
            title: 'Miscellaneous:',
            value: officeById?.expenses?.miscellaneous,
            action: 'miscellaneous',
            actionSub: 'miscellaneousSub'
        },
    ]

    const handleOpenModal = ( type, value, req ) =>{
        setModalType(type)
        setOpen(!open)
        setInfo(value)
        setReqType(req)
    }

    const handleOpenClose = ( ) =>{
        setModalType('')
        setOpen(false)
        setInfo('')
        setReqType('')
    }

    return (
        <div className={classes.officeManagement}>
            <div>
                <div className={classes.lineWrapper}>
                    <Line height='200px'/>
                    <div className={globalStyle.fullScreen}>
                        <div className={classes.expensesWrapper}>
                            {expensesList && expensesList?.map((i,k) => (
                                <div key={k} className={classes.expensesItem}>
                                    <p>{`${i?.title} ${i?.value}`}</p>

                                    {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
                                        <div className={classes.buttonsWrapper}>
                                            <div className={classes.buttonsWrapper}>
                                                <button onClick={() => handleOpenModal(i?.action)}
                                                        className={classes.addButton}>
                                                    <AddIcon style={{color: "white", width: "15px", height: "15px"}}/>
                                                </button>
                                                <p className={classes.buttonText}>Add</p>
                                            </div>

                                            <div className={classes.buttonsWrapper}>
                                                <button
                                                    onClick={() => handleOpenModal(i?.actionSub, i.value, 'subtract')}
                                                    className={classes.subtractButton}>
                                                    <RemoveIcon
                                                        style={{color: "white", width: "15px", height: "15px"}}/>
                                                </button>
                                                <p className={classes.buttonText}>Subtract</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ExpensesModal
                        reqType={reqType}
                        value={info}
                        modalType={modalType}
                        handleClose={handleOpenClose}
                    />
                }
            />

        </div>
    )
}