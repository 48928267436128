import {
  ADD_CUSTOMER_PAYMENT_LOAD_INFO,
  CREATE_CUSTOMER_PAYMENT,
  CREATE_CUSTOMER_PAYMENT_LOAD,
  DELETE_CUSTOMER_PAYMENT_CUSTOMER,
  EDIT_CUSTOMER_PAYMENT,
  GET_CUSTOMER_PAYMENT,
  GET_CUSTOMER_PAYMENT_BY_ID,
  GET_CUSTOMER_PAYMENT_LOAD,
  VOID_CUSTOMER_PAYMENT_LOAD,
} from "./customerPayment.types";

export const createCustomerPayment = (body) => {
  return {
    type: CREATE_CUSTOMER_PAYMENT,
    payload: { body }
  }
}

export const editCustomerPayment = (body, id, load) => {
  return {
    type: EDIT_CUSTOMER_PAYMENT,
    payload: { body, id, load }
  }
}

export const getCustomerPayment = (params) => {
  return {
    type: GET_CUSTOMER_PAYMENT,
    payload: { params }
  }
}

export const getCustomerPaymentById = (id, load) => {
  return {
    type: GET_CUSTOMER_PAYMENT_BY_ID,
    payload: { id, load }
  }
}

export const deleteCustomer = ( id, customerId ) =>{
  return{
    type: DELETE_CUSTOMER_PAYMENT_CUSTOMER,
    payload: { id, customerId}
  }
}

export const addLoadToCustomerPayment = (info) =>{
  return{
    type:ADD_CUSTOMER_PAYMENT_LOAD_INFO,
    payload: { info }
  }
}


export const createCustomerPaymentLoad = (info, id) =>{
  return{
    type:CREATE_CUSTOMER_PAYMENT_LOAD,
    payload: { info, id }
  }
}

export const getCustomerPaymentLoad = (id) =>{
  return{
    type:GET_CUSTOMER_PAYMENT_LOAD,
    payload: { id }
  }
}

export const voidCustomerPaymentLoad = (id, loadId) =>{
  return{
    type:VOID_CUSTOMER_PAYMENT_LOAD,
    payload: { id, loadId }
  }
}

