
/**Create Customer */
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';

/**Edit Customer */
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'

/**Get Carriers */
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';

export const GET_CUSTOMERS_SEARCHED = 'GET_CUSTOMERS_SEARCHED';

/**Get Customer by id */
export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';

/**Activate Inactivate Customer */
export const ACTIVATE_CUSTOMER= 'ACTIVATE_CUSTOMER';
export const INACTIVATE_CUSTOMER = 'INACTIVATE_CUSTOMER';

export const CUSTOMER_NON_FACTORING = 'CUSTOMER_NON_FACTORING';

export const CUSTOMER_NO_POD = 'CUSTOMER_NO_POD';

/**Customer Mc BlackList */
export const ADD_CUSTOMER_BLACKLIST = 'ADD_CUSTOMER_BLACKLIST';
export const REMOVE_CUSTOMER_BLACKLIST = 'REMOVE_CUSTOMER_BLACKLIST';
export const CUSTOMER_BLACKLIST_SUCCESS = 'CUSTOMER_BLACKLIST_SUCCESS';
export const GET_CUSTOMER_DEBT = 'GET_CUSTOMER_DEBT';
export const GET_CUSTOMER_DEBT_SUCCESS = 'GET_CUSTOMER_DEBT_SUCCESS';
export const GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS = 'GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS';

/** AutoDeny  */
export const GET_CUSTOMER_DENY = 'GET_CUSTOMER_DENY'
export const GET_CUSTOMER_DENY_SUCCESS = 'GET_CUSTOMER_DENY_SUCCESS'
export const ACTIVATE_DENY = 'ACTIVATE_DENY'
export const INACTIVATE_DENY = 'INACTIVATE_DENY'

/** Customer Documents */
export const ADD_CUSTOMER_DOCK = 'ADD_CUSTOMER_DOCK'
export const DELETE_CUSTOMER_DOCK = 'DELETE_CUSTOMER_DOCK'
export const EDIT_CUSTOMER_DOCK = 'EDIT_CUSTOMER_DOCK'

export const CONSIGN_CUSTOMER = 'CONSIGN_CUSTOMER'