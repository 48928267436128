import React from "react";
import {RolePermissions, Role, managementFragments} from "./core";

export const AccessManagement = ({rolesList, permissionsList, handleEdit}) => {
    const classes = managementFragments();

    return (
        <div className={classes.managementFragmentsStyle}>
            <div className={classes.managementFragmentsRole}>
                <Role roleInfo={rolesList} permissionsList={permissionsList} handleEdit={handleEdit}/>
            </div>
            <div className={classes.managementFragmentsPermissions}>
                <RolePermissions permissionsList={permissionsList}/>
            </div>
        </div>
    );
};
