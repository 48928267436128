import React, {useState, Fragment, useContext, createContext} from 'react';
import {CloseButton, SimpleModal} from 'components';


export const ModalContext = createContext();


export const ModalProvider = ({children}) => {
    const [activeModal, setActiveModal] = useState('');
    const [params, setParams] = useState({});

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    activeModal,
                    setActiveModal,
                    params,
                    setParams,
                }}>

                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModal = () => {
    const {setActiveModal, params, setParams} = useContext(ModalContext);

    return {
        open: ( params = {}) => {
            setActiveModal(true);
            setParams(params);
        },
        close: () => {
            setActiveModal('');
            setParams({});
        },
        params,
    };
};


export const AllModals = () => {
    const {activeModal} = useContext(ModalContext);
    const {close, params} = useModal();

    return (

        <SimpleModal
            handleOpenClose={() => close('')}
            openDefault={activeModal ? activeModal : false}
            content={
                <div style={{outline:'none'}} className='modal-wrapper-style'>
                    <div className='modal-header-style'>
                        <CloseButton handleCLic={close}/>
                    </div>
                    <div className='modal-body-style'>
                        {params}
                    </div>
                </div>
            }
        />
    );
};