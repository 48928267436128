import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CustomSelect, ValidationInput} from "components";
import {ErrorText, FindLoad, FindSuccess, useModal, userType} from "utils";
import {managementFragments} from "../../management/core";
import {carrierActions, httpRequestsOnSuccessActions} from "store";

const serviceType =
    [
        {name: 'Freight guard', id: 'FREIGHT_GUARD'},
        {name: 'Often late', id: 'OFTEN_LATE'},
        {name: 'Warning', id: 'WARNING'},
        {name: 'Other', id: 'OTHER'},
    ]

export const AddAlert = ({item, carrierId}) => {
    const ACTION_TYPE = item?.id ? 'EDIT_ALERT' : 'ADD_ALERT'
    const classes = managementFragments();
    const dispatch = useDispatch()
    const {close} = useModal()
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState('')
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)

    useEffect(() => {
        if (item?.id) {
            setInputs({
                "type": item?.type,
                "description": item?.description,
            })
        }
    }, [item])

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            close()
        }
    }, [success])


    const handleChange = (e) => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}));
        e.target.name === error && setError('')
    }

    const create = () => {
        if (inputs?.type) {
            const params = {
                "type": inputs?.type,
                "description": inputs?.description,
            }
            if (item?.id) {
                dispatch(carrierActions.editAlert(carrierId, item?.id, params, {}))
            } else {
                dispatch(carrierActions.addAlert(carrierId, params, {}))
            }
        } else {
            setError(
                !inputs?.type ? 'type' : ''
            )
        }
    }

    return (
        <div>
            <p className='modal-title'>{item?.id ? 'Edit Alert' : 'Add Alert'}</p>
            <p className='modal-subtitle'>Please fill out the fields below fields to add alert.</p>
            <div className='alert-inputs'>
                <ValidationInput
                    onChange={handleChange}
                    // className={classes.inputDescriptionNoTop}
                    variant={"outlined"}
                    name={"type"}
                    Length={250}
                    value={inputs?.type}
                    label={"Type*"}
                    type={"text"}
                    typeError={error === 'type' && ErrorText.field}
                />


                {/*<CustomSelect*/}
                {/*    selectedValue={'id'}*/}
                {/*    showValue={'name'}*/}
                {/*    name={"type"}*/}
                {/*    label={"Choose Type*"}*/}
                {/*    handleSelect={handleChange}*/}
                {/*    value={inputs.type}*/}
                {/*    list={serviceType}*/}
                {/*    typeError={error === 'type' && ErrorText.field}*/}
                {/*/>*/}
                <ValidationInput
                    onChange={handleChange}
                    typeError={error === 'description'}
                    className={classes.inputDescriptionNoTop}
                    variant={"outlined"}
                    name={"description"}
                    Length={100}
                    value={inputs?.description}
                    label={"Role Description"}
                    type={"text"}
                    multiline={true}
                />
                <div style={{marginTop: '32px'}}>
                    <AddModalButton
                        loading={!!loader?.length}
                        handleClick={create}
                        text={item ? 'Save' : 'Add'}
                    />
                </div>
            </div>
        </div>
    )
}