import {getRMISSetup, inviteRMIS, refreshRMIS, requiredRMIS, updateRMISSetup} from "./rmis.action";
export {rmisReducer} from './rmis.reducer';
export {watchRmis} from './rmis.saga';

export const rmisActions = {
    /** Rmis Settings */
    inviteRMIS,
    refreshRMIS,
    getRMISSetup,
    updateRMISSetup,
    requiredRMIS,
    /** End */
}

