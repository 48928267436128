import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { AddModalButton, ErrMessage, ValidationInput } from 'components';
import { modalsStyle } from 'components';
import {
  Colors,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccessItem,
  useGlobalTextStyles,
  useModal,
} from 'utils';
import { agentsInfoFragments } from './styles';
import { agentActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const PayrollModal = ({ params, info, selectedIndex }) => {
  const ACTION_TYPE = info ? 'EDIT_AGENT_BRACKET' : 'ADD_BRACKET';
  const { close } = useModal();
  const classes = modalsStyle();
  const agentsStyle = agentsInfoFragments();
  const globalText = useGlobalTextStyles();
  const dispatch = useDispatch();
  const [revenue, setRevenue] = useState(info ? info.start : '');
  const [percentage, setPercentage] = useState(info ? info.percent : 'not');
  const [error, setError] = useState('');
  const loader = FindLoad(ACTION_TYPE);
  const backError = FindErrorItem(ACTION_TYPE);
  const successEditAction = FindSuccessItem(ACTION_TYPE);

  useEffect(() => {
    if (successEditAction?.type === ACTION_TYPE) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
    if (backError?.type === ACTION_TYPE) {
      dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
      setError(backError?.error);
    }
  }, [successEditAction, backError]);

  const addBracket = () => {
    const data = {
      start: parseInt(revenue),
      percent: parseInt(percentage),
    };
    const rev =
      revenue === 0 ? true :
        revenue === '0' ? true :
          revenue ? revenue : '';

    if (rev && percentage !== 'not' && +percentage !== 0) {
      setError('');
      if (info) {
        dispatch(agentActions.editBracket(params?.id, data, info.id));
      } else {
        dispatch(agentActions.addBracket(params?.id, data));
      }
    } else {
      setError(
        !revenue && revenue !== 0 ? 'revenue' :
          !percentage ? 'percentage' :
            percentage === 'not' ? 'percentage' :
              +percentage === '0' ? 'percentage' :
                +percentage === 0 ? 'percentage' :
                  '',
      );
    }
  };

  const handleChangeRevenue = (ev) => {
    error === 'revenue' && setError('');
    setRevenue(ev.target.value);
  };

  const handleChangePercentage = (ev) => {
    if (ev.target.value <= 100) {
      error === 'percentage' && setError('');
      setPercentage(ev.target.value);
    }
  };

  return (
    <div>
      <p className={globalText.modalTitle}>{info ? 'Edit Payroll Bracket' : 'Want to Add Payroll Bracket?'}</p>
      <p className={globalText.modalText}>{!info && 'Please fill out the fields below fields to add a Payroll Bracket.'}</p>
      <div className={agentsStyle.payrollInputs}>
        <ValidationInput
          onChange={handleChangeRevenue}
          value={revenue === 0 ? '0' : revenue}
          typeError={error === 'revenue' ? ErrorText.field : ''}
          variant={'outlined'}
          name={'revenue'}
          label={'Revenue*'}
          type={'number'}
          disabled={selectedIndex === 0}
        />
        <ValidationInput
          onChange={handleChangePercentage}
          typeError={error === 'percentage' ? ErrorText.field : ''}
          value={percentage === 'not' ? '' : percentage}
          style={agentsStyle.percentage}
          variant={'outlined'}
          name={'percentage'}
          label={'Percentage*'}
          type={'number'}
        />
      </div>

      <ErrMessage text={(error !== 'revenue' && error !== 'percentage' && error) ? error : ''} />

      <div className={classes.editSave}>
        <div style={{ width: '100%' }}>
          <AddModalButton
            loading={!!loader?.length}
            styles={info ? { width: '100%', marginTop: '0' } : { marginTop: '0' }}
            handleClick={addBracket}
            text={info ? 'save' : 'Add Payroll Bracket'}
          />
        </div>
        {!!info &&
          <Button
            style={{
              textTransform: 'capitalize',
              width: '100%',
              height: '48px',
              background: Colors.ThemeGray,
              borderRadius: '8px',
              marginLeft: '16px',
              fontSize: '16px',
              fontWeight: '600',
              color: Colors.TextPrimary,
            }}
            onClick={() => close()}
          >
            {'Chancel'}
          </Button>
        }
      </div>
    </div>
  );
};