import { Images } from 'utils';
import { IconAndTitle, PhoneRow, TextRow } from 'components';

export const factoringHead = [
  { name: 'name', title: 'Name' },
  { name: 'address', title: 'Address' },
  { name: 'email', title: 'Email Address' },
  { name: 'phone', title: 'Phone Number', custom: false },
];

export const factoringBody = [
  { rowText: (item) => <IconAndTitle icon={Images.factoringOutline} title={item?.name} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.email} textWidth={10} /> },
  { rowText: (item) => <PhoneRow phone={item?.phone} /> },
];

export const ACTION_TYPE = 'GET_FACTORINGS';