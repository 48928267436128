import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const billingDetailsStyle = makeStyles(() => ({
    closeButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '-50px',
        marginBottom: '-30px',
    },

    closeBill: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '24 0',
        '& button': {
            width: '109px',
            height: '36px',
            background: '#D72525 0% 0% no-repeat padding-box',
            borderRadius: '18px',
            color: Colors.TextWhite,
            fontSize: '14px',
            fontWeight: '600',
            border: 'none',
        },
        '& button:hover': {
            background: '#ac0707',
        },
    },

    itemsSection: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px',
    },

    addButtonsWrapper: {
        display: 'flex',
        marginTop: '24px',
    },

    requestCorrection: {
        width: '238px',
        height: '48px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '2px solid #438AFE',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '600',
        color: Colors.ThemeBlue,
    },

    itemsWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        maxHeight: '50px',
        padding: '16px',
        background: '#E6ECF3 0% 0% no-repeat padding-box',
        borderRadius: '8px',
    },

    billingHistory: {
        marginLeft: '50px'
    },

    paperWrapper: {
        width: '800px',
        height: '90vh',
        background: 'white',
        overflow: 'auto',
        padding: '10px 24px 60px 24px',
        display: "flex",
        flexDirection:'column',
    },

    paperFooter: {
        height: '112px',
        background: '#1C2331 0% 0% no-repeat padding-box',
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        bottom: 0
    }

}))