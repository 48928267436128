import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from "store";
import {loginFragments} from "./style";
import {ErrMessage, PasswordInput, SignIn} from "components";
import {ErrorText, FindErrorItem, FindLoad, FindSuccessItem, PasswordValidator} from "utils";
import {useHistory} from "react-router-dom";

export const ResetModal = ({resetToken, register}) => {
    const classes = loginFragments();
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [inputs, setInputs] = useState({});
    const backError = FindErrorItem('RESET_PASSWORD_REQUEST')
    const loader = FindLoad('RESET_PASSWORD_REQUEST')
    const success = FindSuccessItem('RESET_PASSWORD_REQUEST')
    const history = useHistory()


    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('RESET_PASSWORD_REQUEST'))
            window.location.replace('/')
        }
    }, [success])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setError('')
        backError?.error && dispatch(httpRequestsOnErrorsActions.removeError('RESET_PASSWORD_REQUEST'))
    }

    const resetPassword = () => {
        if (inputs.newPassword && inputs.confirmPassword) {
            const passwords = {
                newPassword: inputs.newPassword,
                confirmation: inputs.confirmPassword,
                token: resetToken,
                type: register ? 'register' : 'reset'
            };
            if (inputs.newPassword === inputs.confirmPassword) {
                dispatch(authActions.resetPassword(passwords));
            } else {
                setError("Password and confirm password does not match");
            }
        } else {
            if (!inputs.newPassword) {
                setError("New password is not field");
            } else if (!inputs.confirmPassword) {
                setError("Confirm password is not field");
            }
        }
    };

    const handleCheck = (bool, name) => {
        if (bool === true) {
            setError(name);
        } else {
            setError("");
        }
    };

    const errorNewPassword =
        error === "newPasswordNotValid"
            ? ErrorText.passwordValid
            : error === "New password is not field"
                ? "New password is not field"
                : "";
    const errorConfirmPassword =
        error === "confirmationNotValid"
            ? ErrorText.passwordValid
            : error === "Password and confirm password does not match"
                ? "Password and confirm password does not match"
                : error === "Confirm password is not field"
                    ? "Confirm password is not field"
                    : "";

    return (
        <div className={classes.LoginModalWrapper}>
            <div>
                <p>{register === true ? 'Welcome PoloTMS' : 'Reset Password'}</p>
                <span className={classes.LoginModalForgotText}>
          {register === true ?
              <span>You have been invited to join PoloTMS as a an admin.<br/>
                  Please create password for your account</span>
              :
              <span> Enter your new password.<br/>
                  bv Use at least 8 characters, 1 upper case and 1 digit.</span>
          }
      </span>

                <PasswordInput
                    styles={{margin: "10px 0"}}
                    handleChangePassword={handleChange}
                    validator={PasswordValidator}
                    sendBoolean={(bool) => handleCheck(bool, "newPasswordNotValid")}
                    type={"password"}
                    name={"newPassword"}
                    disabled={false}
                    value={inputs.newPassword}
                    onChange={handleChange}
                    typeError={errorNewPassword}
                    placeholder={register ? "Password" : "New Password"}
                />

                <PasswordInput
                    validator={PasswordValidator}
                    sendBoolean={(bool) => handleCheck(bool, "confirmationNotValid")}
                    handleChangePassword={handleChange}
                    type={"password"}
                    name={"confirmPassword"}
                    disabled={false}
                    value={inputs.confirmPassword}
                    onChange={handleChange}
                    typeError={errorConfirmPassword}
                    placeholder={register ? "Confirm Password" : "Confirm New Password"}
                />
            </div>

            {backError?.error &&
                <ErrMessage style={{position: 'relative'}} textStyle={{position: 'relative'}} text={backError?.error}/>}
            <SignIn
                loader={!!loader.length}
                handleClick={resetPassword}
                width={"100%"}
                text={register ? "Complete Registration" : "Reset Password"}
            />
        </div>
    );
};
