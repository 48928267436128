import React from "react";
import {useGlobalTextStyles} from "utils";
import {cardStyle} from "./style";
import {Download} from "../buttons";
import {PriceRow, TextRow} from "../table";

export const Items = ({title, text, type, styles, price, file}) => {
    const classes = cardStyle()
    const globalText = useGlobalTextStyles()

    return (
        <div style={type === 'download' ? {...styles, justifyContent: 'space-between'} : {...styles}}
             className={classes.itemsWrapper}
        >
            <p className={globalText.itemTitle}>{title}</p>
            {type === 'download' ?
                <Download
                  fullFile={file}
                />
                :
                <p style={{width: '100%'}} className={globalText.itemText}>
                    {price ?
                        <PriceRow info={text}/>
                        :
                        <TextRow name={title ? text ? text : 'N/A' : ''}/>
                    }
                </p>
            }
        </div>
    )
}