import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, TableWrapper} from "components";
import {loadActions} from "store";
import {ACTION_TYPE, LOAD_VERIFICATION, LoadVerificationBody, LoadVerificationHead} from "./constants";

export const LoadVerification = ({}) => {
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const history = useHistory()
    const info = history?.location?.state
    const dispatch = useDispatch()

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        return filteredInfo
    }

    useEffect(() => {
        handleGet('loading')
        const interval = setInterval(() => {
            handleGet('noLoad')
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad'
        const filteredInfo = renderParams()
        dispatch(loadActions.getLoadsByStatus({...filteredInfo, department: LOAD_VERIFICATION}, loader))
    }

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/load/${id}`,
            state: {loadParams: {...info}, parent: '/loadVerification'}
        })
    }

    return (
        <div>
            <TableWrapper
                buttonsTab={true}
                link={true}
                tab={'none'}
                buttonsTabAddButton={false}
            >
                <FullTable
                    head={LoadVerificationHead}
                    body={LoadVerificationBody}
                    loadingType={ACTION_TYPE}
                    list={loadsList?.loads}
                    listCount={loadsList?.count}
                    handleClick={pushPageDetails}
                    noText={'Processing'}
                    dateFilter={true}
                />
            </TableWrapper>
        </div>
    )
}