import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const adminInfoFragments = makeStyles(() => ({
    accessWrapper: {
        width: '100%',
        marginTop:'24px'
    },

    accessBody: {
        display: "flex",
        justifyContent: 'space-between',
    },

    managementHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    half: {
        width: '49.1%',
    },

    halfItem: {
        width: '50%',
        marginTop: '16px',
        height: '216px',
        overflow: 'auto',
    },

    roleNamePer: {
        background: Backgrounds.tableActive,
        borderRadius: '4px',
        padding: '16px',
        width: '50%',
        marginLeft: '16px',
        marginTop: '16px',
        height: '216px',
        "@media (min-width: 1920px)": {
            marginLeft: '24px',
        },
    },
  roleWrapper:{
    height:'150px',
    overflow:'auto',
  },
    roleNamTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextPrimary,
        marginBottom: '24px',
    },

    roleItem: {
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            color: Colors.TextPrimary,
        },
        '& img': {
            width: '18px',
            height: '18px',
            marginRight: '8px',
        }
    },

    select:{
        marginTop:'-15px',
    },

    arrow:{
       marginLeft: '10px',
        marginTop:'2px'
    }
}))