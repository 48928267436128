import React, {useState} from "react";
import {Container} from "@material-ui/core";
import {
    AcctionCore,
    BenefitsCore,
    EfficiencyCore,
    FooterCore,
    HeaderCore,
    RevolutionizeCore,
    TransportCore,
    TrustedCore,
} from "fragments";
import {SimpleModal, ContactModal} from "components";

export const LandingPage = () => {
    const [openDel, setOpenDel] = useState(false);

    const handleOpenCloseDel = () => {
        setOpenDel(!openDel);
    };

    return (
        <>
            <div className="landing-page">
                <HeaderCore handleOpenCloseDel={handleOpenCloseDel}/>
                <main>
                    <Container className="global-container">
                        <section className="section-revolutionize">
                            <RevolutionizeCore/>
                        </section>
                        <section className="section-efficiency" id="services">
                            <EfficiencyCore/>
                        </section>
                        <section className="section-trusted" id="aboutUs">
                            <TrustedCore/>
                        </section>
                    </Container>
                    <section className="section-call">
                        <AcctionCore/>
                    </section>
                    <Container>
                        <section className="section-benefits" id="fetures">
                            <BenefitsCore/>
                        </section>
                        <section className="section-transport">
                            <TransportCore/>
                        </section>
                    </Container>
                </main>
                <FooterCore handleContact={handleOpenCloseDel}/>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenCloseDel}
                openDefault={openDel}
                content={<ContactModal text={""} handleClose={handleOpenCloseDel}/>}
            />
        </>
    );
};
