import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const agentsInfoFragments = makeStyles(() => ({

    officeSelectInput:{
        marginBottom:'24px',
        width:'49.1%',
        marginTop:'32px',
    },

    buttonsTab: {
        '& .MuiPaper-root': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 0px 6px #8A8A8A29 !important',
        },
        '& span': {
            background: 'none'
        },


        "& .MuiTabs-flexContainer": {
            borderRadius: "18px",
            display: 'flex',
            alignItems: 'center',
        },

        "& .MuiTabs-root": {
            minHeight: "36px !important",
            height: "36px  !important",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between'
        },

        "& .MuiTabs-fixed": {
            minHeight: "36px",
            display: 'flex',
            alignItems: 'center'
        },
        "& .PrivateTabIndicator-colorPrimary-30": {
            display: "none",
        },
        "& .MuiTab-root": {
            minWidth: '50px',
            maxWidth: '50px',
            minHeight: '36px',
            padding:'6px',
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            minWidth: "90px",
            minHeight: "32px",
            maxHeight: "32px",
            background: "#438AFE 0% 0% no-repeat padding-box",
            borderRadius: "18px",
            fontSize: "14px",
            lineHeight: "19px",
            color: Colors.TextWhite,
            textTransform: "capitalize",
            marginLeft: '2px',

            '& :hover': {
                background: '#0000001A 0% 0% no-repeat padding-box',
                minWidth: "90px",
                minHeight: "32px",
                maxHeight: "32px",
                borderRadius: "18px",
                fontSize: "14px",
                lineHeight: "19px",
                color: Colors.TextWhite,
                textTransform: "capitalize",
            }
        },

        "& .MuiTab-textColorPrimary": {
            minWidth: "90px",
            minHeight: "32px",
            maxHeight: "32px",
            borderRadius: "18px",
            fontSize: "14px",
            lineHeight: "19px",
            color: Colors.TextSecondary,
            textTransform: "capitalize",
            marginLeft: '2px',

            '& :hover': {
                background: '#E6ECF380 0% 0% no-repeat padding-box',
                minWidth: "90px",
                minHeight: "32px",
                maxHeight: "32px",
                borderRadius: "18px",
                fontSize: "14px",
                lineHeight: "19px",
                color: Colors.TextSecondary,
                textTransform: "capitalize",
            }
        },
    },

    lineWrapper: {
        display: 'flex',
    },

    managementHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    inputsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px',
    },

    officeManagement: {
        width: '100%',
    },

    officeManagementHead: {

        height: '335px',
        overflow: 'auto',
        marginTop: '24px',
        "@media (min-width: 1920px)": {
            marginTop: '32px'
        }
    },
    authoritesManagementHead: {
        marginTop: '34px',
        "@media (min-width: 1920px)": {
            marginTop: '40px'
        }
    },
    authoritiesManagementHeadTitle: {
        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },
    officeManagementHeadTitle: {
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginBottom: '8px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',

        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },

    branchesWrapper: {
        width: '100%',
        marginLeft: '24px',
        "@media (min-width: 1920px)": {
            marginLeft: '32px',
        }
    },

    tableWrapper: {
        "@media (min-width: 1920px)": {
            marginLeft: '32px',
        },
        marginLeft: '24px',
        height: '100%',
        minHeight: '80vh',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '16px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
    },

    setupWrapper:{
      display:'flex',
      justifyContent:'space-between',
      width:'100%',
      alignItems:'center',
      marginBottom:'32px',
    },
    weekTable:{
      marginBottom:'18px',
    },
    payroll:{
        width:'100%',
        display:'flex',
    },

    payrollWrapper: {
        height: '386px',
        width: '100%',
        background: '#F7F9FC 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        padding:'16px',
    },

    payrollHead:{
        width:'100%',
        background:Colors.BackgroundPrimary,
        borderRadius:'8px',
        marginBottom:'8px',
        padding:'16px 32px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        '& p':{
            fontSize:'14px',
            fontWeight:'600',
            color:Colors.TextSecondary,
        },
        '& span':{
            fontSize:'14px',
            color:Colors.TextPrimary,
            fontWeight:'normal',
            marginLeft:'8px',
        },
    },
    payrollItemsWrapper:{
        height:'200px',
        overflow:'auto'
    },
    payrollItem:{
        width:'100%',
        background:Colors.TextWhite,
        borderRadius:'8px',
        marginBottom:'8px',
        padding:'16px 32px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        '& p':{
            width:'100px',
        }
    },

    accessWrapper: {
        width: '100%',
        marginLeft: '24px',
    },

    accessBody: {
        display: "flex",
        justifyContent: 'space-between',
    },


    fullWidth: {
        width: '100%',
        height: '216px',
        overflow: 'auto'
    },
    fullWidthWrapper: {
        width: '100%',
        height: '200px',
        overflow: 'auto'
    },
    tittle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextSecondary,
    },
    authoritiesHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },

    authoritiesWrapper: {
        display: 'flex',
        width: '100%'
    },
    itemsWrapper: {
        width: '100%',
        marginTop: '24px',
    },


    halfItem: {
        width: '50%',
        marginTop: '16px',
        height: '216px',
        overflow: 'auto',
    },

    roleNamePer: {
        background: Backgrounds.tableActive,
        borderRadius: '4px',
        padding: '16px',
        width: '50%',
        marginLeft: '16px',
        marginTop: '16px',
        height: '216px',
        "@media (min-width: 1920px)": {
            marginLeft: '24px',
        },
    },
    roleWrapper: {
        height: '150px',
        overflow: 'auto',
    },
    roleNamTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextPrimary,
        marginBottom: '24px',
    },

    roleItem: {
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            color: Colors.TextPrimary,
        },
        '& img': {
            width: '18px',
            height: '18px',
            marginRight: '8px',
        }
    },

    select: {
        marginTop: '-15px',
    },

    arrow: {
        marginLeft: '10px',
    },

    appBar: {

        // width:'40%'
    },

    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
    },

    custom:{
        display:'flex',
        '& span':{
            marginTight:'8px',
        },
    },
    addButton:{
        marginBottom:'16px',
        display:'flex',
        justifyContent:'flex-end',
    },

    payrollInputs:{
        display:'flex',
    },

    percentage:{
        marginLeft:'16px',
        width:'100%'
    },

    editButton:{
        display:'flex',
        alignItems:'center',
        border:'none',
        background:'none',
        outline:'none',
        '& span':{
            color:'#438AFE80',
            fontSize:'14px',
            marginLeft:'8px',
        },
    },
    action:{
        marginRight:'60px',
        width:'70px'
    }

}))