import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./terms.service";
import {
    CREATE_QUICKBOOKS,
    CREATE_TERM, DELETE_TERM, GET_QUICKBOOKS, GET_QUICKBOOKS_SUCCESS,
    GET_TERMS,
    GET_TERMS_SUCCESS,
} from "./terms.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Create, Edit, Get, Delete, Term */

function* createTerm({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.createTermService, payload.body);
        yield put({
            type: GET_TERMS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

function* getTerms({type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getTermsService);
        yield put({
            type: GET_TERMS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteTerm(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.activateTermService, action.payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    }
}

/** End */

/** Quickbook */

function* createQuickbooks({ type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.addQuickbooksService );
        window.location.replace(res.data)
        // yield put({
        //     type: GET_QUICKBOOKS,
        //     payload: {load: 'noLoad'},
        // });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* getQuickbooks({payload, type}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getQuickbooksService );
        yield put({
            type: GET_QUICKBOOKS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

/** End */

export const watchTerm = function* watchTermSaga() {
    /** Create, Edit, Get, Delete Term */
    yield takeLatest(CREATE_TERM, createTerm);
    yield takeLatest(GET_TERMS, getTerms);
    yield takeLatest(DELETE_TERM, deleteTerm)
    /** End */

    /** Quickbook */
    yield takeLatest(CREATE_QUICKBOOKS, createQuickbooks);
    yield takeLatest(GET_QUICKBOOKS, getQuickbooks);
    /** End */
};
