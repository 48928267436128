import React, { useState } from 'react';
import { CheckboxesTags, CustomSelect, ErrMessage, MinLoader, modalsStyle } from 'components';
import { ErrorText, useGlobalTextStyles, useModal } from 'utils';
import { reportServices } from 'store';

export const CsvModal = ({ params }) => {
  const classes = modalsStyle();
  const globalText = useGlobalTextStyles();
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState('');
  const { close } = useModal();
  const [loadStatuses, setLoadStatuses] = useState([]);

  const handleExport = async () => {
    const check =
      params?.requiredDateRange ? !inputs?.from :
        params?.requiredStatus ? !inputs?.status : false;

    if (!check) {
      setLoader(true);

      try {
        const date = {};

        if (inputs?.status) {
          if (inputs?.status === 'All') {
            delete date.statusEnum;
          } else {
            date[params?.statusEnum] = inputs?.status;
          }
        } else {
          delete date.statusEnum;
        }

        if (inputs?.from) {
          if (params?.type === 'customerPayment' || params?.type === 'carrierPayment') {
            date.startDate = new Date(inputs?.from);
          } else {
            date.from = new Date(inputs?.from);
          }
        }

        if (inputs?.to) {
          if (params?.type === 'customerPayment' || params?.type === 'carrierPayment') {
            inputs?.to ? date.endDate = new Date(inputs?.to) : '';
          } else {
            inputs?.to ? date.to = new Date(inputs?.to) : '';
          }
        }

        if (loadStatuses?.length && params?.type === 'load') {
          let newList = [];
          loadStatuses?.forEach((i) => {
            newList.push(i?.id);
          });
          date.statuses = newList;
        } else {
          delete date.statuses;
        }

        let response = '';

        if (params?.type === 'carrier') {
          const carrierParams = {
            statuses: inputs?.status === 'All' ? [params?.statusesEnum?.ACTIVE, params?.statusesEnum?.INACTIVE, params?.statusesEnum?.PENDING] : [inputs?.status],
          };

          if (params?.csvType === 'w9') {
            response = await reportServices.getCarriersW9Reports(carrierParams);
          } else {
            response = await reportServices.getCarriersReports(carrierParams);
          }
        }
        if (params?.type === 'customer') {
          response = await reportServices.getCustomersReports(date);
        }
        if (params?.type === 'load') {
          response = await reportServices.getLoadsReports(date);
        }
        if (params?.type === 'receivables') {
          response = await reportServices.getReceivablesReports();
        }
        if (params?.type === 'customerPayment') {
          delete date[params?.statusEnum];
          if (inputs?.status === 'OVERVIEW') {
            response = await reportServices.getCustomerPmtReports(date);
          } else {
            response = await reportServices.getCustomerPmtDetailsReports(date);
          }
        }

        if (params?.type === 'carrierPayment') {
          delete date[params?.statusEnum];
          if (inputs?.status === 'OVERVIEW') {
            response = await reportServices.getCarrierPmtReports(date);
          } else {
            response = await reportServices.getCarrierPmtDetailsReports(date);
          }
        }

        const fileName =
          params?.type === 'customerPayment' ? inputs?.status === 'OVERVIEW' ? 'customer-payment' : 'customer-payment-details' :
            params?.type === 'carrierPayment' ? inputs?.status === 'OVERVIEW' ? 'carrier-payment' : 'carrier-payment-details' :
              params?.type;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        setLoader(false);
        close();

      } catch (err) {
        if (err?.data?.message === 'Load was not found') {
          setError('Load was not found');
        }
        setLoader(false);
      }
    } else {


      const checkErrorType =
        params?.requiredStatus && !inputs?.status ? 'status' :
          params?.requiredDateRange && !inputs?.from ? 'from' :
            '';

      setError(checkErrorType);
    }
  };

  const handleSelectDate = (e) => {

    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    setError('');
  };


  const handleSelectStatuses = (e) => {
    setLoadStatuses(e);
    // setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    // setError('');
  };


  return (
    <div className={classes.loadCsvModal}>
      <div>
        <p
          className={globalText.modalTitle}>{(params?.statusList && params?.dateFilter) ? 'Export Details' : 'Export Date Range'}</p>

        <p className={globalText.modalText}>
          {(params?.statusList && params?.dateFilter) ? 'All data within the selected date range and Carriers group.' :
            params?.statusList ? 'All data within the selected group.' :
              'All data within the selected date range.'
          }
          {/*{params?.filter === 'status' ?*/}
          {/*  'All data within the selected group.'*/}
          {/*  :*/}
          {/*  params?.filter === 'dateStatus' ?*/}
          {/*    'All data within the selected date range and Carriers group.'*/}
          {/*    :*/}
          {/*    'All data within the selected date range.'*/}
          {/*}*/}
        </p>

        {params?.statusList &&
          <div>
            {params?.type === 'load' ?
              <div style={{ marginBottom: '20px' }}>
                <CheckboxesTags
                  type={'mc'}
                  handleChange={handleSelectStatuses}
                  permissionsList={params?.statusList}
                  value={loadStatuses}
                  label={
                    params?.requiredStatus ? 'Select Group*' : 'Select Group'
                  }
                />
              </div>
              :
              <CustomSelect
                selectedValue={'id'}
                showValue={'name'}
                name={'status'}
                label={
                  params?.requiredStatus ? 'Select Group*' : 'Select Group'
                }
                handleSelect={handleSelectDate}
                value={inputs?.status}
                list={params?.statusList}
                typeError={error === 'status' && ErrorText.field}
              />
            }
          </div>
        }

        {params?.dateFilter &&
          <div className="flex">
            <div className={classes.inputWrapper}>
              <p>{params?.requiredDateRange ? 'Choose start month*' : 'Choose start month'}</p>
              <input
                name={'from'}
                style={error ? { borderColor: '#F07379' } : {}}
                onChange={handleSelectDate}
                className={classes.exportCsvDate}
                type={'month'}
              />
              <ErrMessage text={
                error === 'from' ? ErrorText.field :
                  error === 'Load was not found' ? 'Sorry but we don’t have load data for this month!' :
                    ''}
              />
            </div>

            <div style={{ marginLeft: '8px' }} className={classes.inputWrapper}>
              <p>Choose end month</p>
              <input
                name={'to'}
                style={error === 'from' ? { borderColor: '#F07379' } : {}}
                onChange={handleSelectDate}
                className={classes.exportCsvDate}
                type={'month'}
              />
            </div>
          </div>
        }

        <div className={classes.customClassWrapper}>
          <button onClick={handleExport} className={classes.exportButton}>
            {loader ?
              <MinLoader position={'relative'} color={'white'} />
              :
              'Export CSV'
            }
          </button>
        </div>
      </div>
    </div>
  );
};