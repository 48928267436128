import {Images} from "utils";
import {screensStyle} from "./styles";

export const InfoSection = ({ text, styles, note }) =>{
    const classes = screensStyle()
    return(
        <div style={{...styles}} className={classes.infoSectionWrapper}>
            <img src={Images.info} alt='info'/>
            <p>
                {note && <b>Verification Note: </b>}
                {text}</p>
        </div>
    )
}