import {call, put, takeLatest} from "redux-saga/effects";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {agentActions} from "../agents";
import {authService} from "./branches.service";
import {
    ACTIVATE_BRANCH, CREATE_BRANCH, CREATE_BRANCH_MANAGERS,
    EDIT_BRANCH, GET_BRANCH_BY_ID, GET_BRANCH_BY_ID_SUCCESS,
    GET_BRANCHES, GET_BRANCHES_SUCCESS, INACTIVATE_BRANCH, REMOVE_BRANCH_MANAGERS,
    SELECT_BRANCH_MANAGERS
} from "./branches.types";

/** Create, Edit Branch */

function* createBranch({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    try {
        yield call(authService.createBranchService, payload.body);
        window.location.replace('/branches')
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

function* editBranch({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    try {
        yield call(authService.editBranchService, payload?.body, payload?.id);
        yield put({
            type: GET_BRANCH_BY_ID,
            payload: {id: payload?.id, load: 'noLoad'}
        })
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

/** End */

/** Get Branches */

function* getBranch({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getBranchesService, payload.params);
        yield put({
            type: GET_BRANCHES_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* getBranchById({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getBranchByIdService, payload.id);
        yield put({
            type: GET_BRANCH_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

/** End */

/** Activate or Inactivate Branch */

function* activateBranch(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        yield call(authService.activateBranchService, action.payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* inactivateBranch(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        yield call(authService.inactivateBranchService, action.payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

/** End */

/** Branch Management */

function* createBranchManager({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createBranchManagerService, payload.data);
        yield put(agentActions.getAgentByBranch(payload.data.branchId))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

function* selectBranchManager({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.selectBranchManagerService, payload.data);
        const data = {
            officeId: payload.data.officeId,
            branchId: payload.data.branchId
        }
        yield put(agentActions.getManagersByBranch(data))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

function* removeBranchManager({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.removeBranchManagerService, payload.data, payload.officeId);
        const data = {
            officeId: payload.data.officeId,
            branchId: payload.data.branchId
        }
        yield put(agentActions.getManagersByBranch(data))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

/** End */

export const watchBranch = function* watchBrancheseSaga() {
    /** Create, Edit Branch */
    yield takeLatest(CREATE_BRANCH, createBranch);
    yield takeLatest(EDIT_BRANCH, editBranch);
    /** End */

    /** Get Branches */
    yield takeLatest(GET_BRANCHES, getBranch);
    yield takeLatest(GET_BRANCH_BY_ID, getBranchById);
    /** End */

    /** Activate or Inactivate Branch */
    yield takeLatest(ACTIVATE_BRANCH, activateBranch)
    yield takeLatest(INACTIVATE_BRANCH, inactivateBranch)
    /** End */

    /** Branch Management */
    yield takeLatest(CREATE_BRANCH_MANAGERS, createBranchManager)
    yield takeLatest(SELECT_BRANCH_MANAGERS, selectBranchManager)
    yield takeLatest(REMOVE_BRANCH_MANAGERS, removeBranchManager)
    /** End */

};

