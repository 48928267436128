import { Images } from '../../utils';
import React from 'react';
import { screensStyle } from './styles';


export const ToastScreen = ({ text, type }) => {
  const classes = screensStyle();

  return (
    <div className={classes.toastWrapper}>
      <img src={type === 'error' ? Images.toastError : Images.success} alt="success" />
      <p>{text}</p>
    </div>
  );
};