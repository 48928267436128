import React from "react";
import Modal from "@material-ui/core/Modal";
import {Backdrop} from "@material-ui/core";

export const SimpleModal = ({
                                openDefault,
                                handleOpenClose,
                                content,
                                backdropCustom,
                                disableScrollLock,
                            }) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        if (handleOpenClose) {
            handleOpenClose();
        } else {
            setOpen(false);
        }
    };

    const body = <div>{content}</div>;

    return (
        <div>
            <Modal
                disableScrollLock={disableScrollLock}
                open={openDefault ? openDefault : open}
                onClose={handleClose}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={backdropCustom === true ? "my-profile" : ""}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 2500,
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
              <div className='modal-body-style-no-p'>
                {body}
              </div>
            </Modal>
        </div>
    );
};
