import {ScrollToTop, Toast} from "components";
import {RouterSwitcher} from "./root/routerSwitcher";
import React, {useEffect} from "react";
import {ToastFail, ToastSuccess} from "./utils";
import {httpRequestsOnSuccessActions} from "./store";
import {useDispatch, useSelector} from "react-redux";

function App() {
    const {httpOnError, httpOnSuccess} = useSelector((state) => ({
        httpOnLoad: state.httpOnLoad,
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
    }));
    const dispatch = useDispatch();
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error);

    useEffect(() => {
        if (toastSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    // useEffect(() => {
    //     if (toastFail) {
    //         // dispatch(httpRequestsOnErrorsActions.removeError( error ))
    //     }
    // }, [toastFail]);


    return (
        <div>
            <ScrollToTop/>
            <RouterSwitcher/>
            <Toast
                type={toastSuccess ? "success" : toastFail ? "error" : ""}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ""}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ""}
            />
        </div>
    );
}

export default App;
