import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {customerActions} from "store";
import {FindLoad} from "utils";
import {Loader, PhoneRow, TypeRow} from "components";

const ACTION_TYPE = 'GET_CUSTOMER_BY_ID'

export const CustomerInformation = ({id}) => {
    const {customerById} = useSelector((state) => ({
            customerById: state.customers.customerById,
        })
    )
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)

    useEffect(() => {
        dispatch(customerActions.getCustomerById(id))
    }, [])

    const returnTypeText = (type, text, convert) => {
        return (
            <div className='type-and-text'>
                <p>{type}</p>

                <span>{
                    convert ?
                        (
                            type === 'Phone Number' ?
                                <PhoneRow phone={text}/>
                                :
                                <TypeRow text={text}/>
                        )
                        :
                        text
                }</span>
            </div>
        )
    }

    return (
        <div className='customer-information-wrapper'>
            {loader?.length ?
                <Loader height={'100%'} style={'relative'}/>
                :

                <>
                    <p className='customer-title'>{customerById?.name}</p>
                    <div>
                        <p className='basic-info'>Basic Information</p>
                        <div className='basic-info-wrapper'>
                            {returnTypeText('Type', customerById?.type, true)}
                            {returnTypeText('MC Number', customerById?.mc)}
                            {returnTypeText('Dot Number', customerById?.dot)}
                        </div>

                        <div className='basic-info-wrapper'>
                            {returnTypeText('Email Address', customerById?.email)}
                            {returnTypeText('Billing Email', customerById?.billingEmail)}
                            {returnTypeText('Phone Number', customerById?.phone, true)}
                        </div>

                        <div className='basic-info-wrapper'>
                            {returnTypeText('Payment term (days)', customerById?.pmtTerm)}
                        </div>
                    </div>

                    <hr className='bottom-line-style'/>

                    <div>
                        <p className='basic-info'>Address</p>
                        <div className='basic-info-wrapper'>
                            {returnTypeText('Physical Address', customerById?.address?.formattedAddress)}
                            {returnTypeText('Suit', customerById?.address?.unitNumber ? customerById?.address?.unitNumber : 'Not Set')}
                            {returnTypeText('City', customerById?.address?.city ? customerById?.address?.city : 'Not Set')}
                        </div>

                        <div className='basic-info-wrapper'>
                            {returnTypeText('Country', customerById?.address?.country ? customerById?.address?.country : 'Not Set')}
                            {returnTypeText('State', customerById?.address?.state ? customerById?.address?.state : 'Not Set')}
                            {returnTypeText('Zip Code', customerById?.address?.zip ? customerById?.address?.zip : 'Not Set')}
                        </div>
                    </div>

                    {customerById?.secondAddress &&
                        <>
                            <hr className='bottom-line-style'/>
                            <div>
                                <p className='basic-info'>Second Address</p>
                                <div className='basic-info-wrapper'>
                                    {returnTypeText('Physical Address', customerById?.secondAddress?.formattedAddress)}
                                    {returnTypeText('Suit', customerById?.secondAddress?.unitNumber ? customerById?.secondAddress?.unitNumber : 'Not Set')}
                                    {returnTypeText('City', customerById?.secondAddress?.city ? customerById?.address?.city : 'Not Set')}
                                </div>

                                <div className='basic-info-wrapper'>
                                    {returnTypeText('Country', customerById?.secondAddress?.country ? customerById?.secondAddress?.country : 'Not Set')}
                                    {returnTypeText('State', customerById?.secondAddress?.state ? customerById?.secondAddress?.state : 'Not Set')}
                                    {returnTypeText('Zip Code', customerById?.secondAddress?.zip ? customerById?.secondAddress?.zip : 'Not Set')}
                                </div>
                            </div>
                        </>
                    }

                </>
            }
        </div>
    )
}