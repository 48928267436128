import moment from 'moment'
import {historyStyle} from "./styles";

export const HistoryCard = ({key, data}) => {

    let correctDate = 'Saturday, September 25, 2021'
        // moment(data.date).format('dddd, MMMM DD, YYYY')
    const classes = historyStyle()

    return (
        <div className={classes.historyCardStyle} key={key}>
            <p className={classes.historyCardDateStyle}>{correctDate}</p>

            <div className={classes.historyCardBoxStyle}>
                <p className={classes.historyCardBoxTimeStyle}>{'09:00 AM'}</p>
                <p>{'Action Description'}</p>
            </div>
            <div className={classes.historyCardBoxStyle}>
                <p className={classes.historyCardBoxTimeStyle}>{'09:00 AM'}</p>
                <p>{'Action Description'}</p>
            </div>
        </div>
    )
}