import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Checkbox, TableCell} from "@material-ui/core";
import {
    AddModalButton,
    CloseButton,
    CorrectionsModal,
    FullTable,
    SimpleModal,
} from "components";
import {PayableCreatePayment} from "fragments";
import {RolePermission, PermissionsList, Colors} from "utils";
import {payablesStyles} from "./styles";
import {loadActions, LoadServices} from "store";
import {payablesBody, payablesHead, ACTION_TYPE, PAYABLE} from "./constants";

export const Payables = ({}) => {
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const classes = payablesStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state
    const [selectedLoads, setSelectedLoads] = useState([])
    const [correctionLoad, setCorrectionLoad] = useState(false)
    const [correction, setCorrection] = useState('')
    const [data, setData] = useState('')
    const [open, setOpen] = useState('')

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        return filteredInfo
    }

    useEffect(() => {
        handleGet('loading')
        const interval = setInterval(() => {
            handleGet('noLoad')
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);


    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad'
        const filteredInfo = renderParams()
        dispatch(loadActions.getLoadsByStatus({...filteredInfo, department: PAYABLE}, loader))
    }

    const handleOpenClose = (type) => {
        setOpen(type ? type : '')
    }

    const handleClearAllSelected = () => {
        handleGet()
        setSelectedLoads([])
        setOpen('')
    }

    const removeAllSelects = () => {
        setSelectedLoads([])
        setOpen('')
    }

    const handleClick = (item, type,) => {
        setData(item)
        if (type === 'correction') {
            handleOpenClose('correction')
        }
    }

    const handleSend = async () => {
        setCorrectionLoad(true)
        try {
            const date = {"correction": correction}
            await LoadServices.PayableCorrections(data.id, date)
            setCorrectionLoad(false)
            handleGet()
            handleOpenClose()
        } catch (e) {
            setCorrectionLoad(false)
        }
    }

    const handleSelect = (e, item) => {
        if (!selectedLoads?.length) {
            dispatch(loadActions.getLoadsByPayable(item?.id))
        }
        if (e.target.checked === true) {
            setSelectedLoads([...selectedLoads, item])
        } else {
            const newArr = []
            selectedLoads.filter((k, key) => (k.id !== item.id && newArr.push(k)))
            setSelectedLoads(newArr)
            if (newArr.length === 0) {
                handleGet()
            }
        }
    }

    const customChildren = (item) => {
        return (
            RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_CARRIER_PAYMENT?.code]) &&
            <TableCell style={{padding: '8px 2px'}}>
                <Checkbox
                    style={{padding: '0 0 0 4px', color: Colors.ThemeBlue}} name={item?.id}
                    checked={selectedLoads && !!selectedLoads.find((i) => i?.id === item?.id)}
                    onClick={(e) => handleSelect(e, item)}
                />
            </TableCell>
        )
    }

    return (
        <div style={{position: 'relative'}}>
            {selectedLoads?.length ?
                <div className='fromTo'>
                    <div className={classes.selectedItemsWrapper}>
                        <div className={classes.itemsInfo}>
                            <CloseButton handleCLic={handleClearAllSelected}/>
                            <p>{selectedLoads?.length} item selected</p>
                        </div>

                        <AddModalButton
                            buttonStyle={{height: '36px'}}
                            styles={{marginTop: '0'}}
                            handleClick={() => handleOpenClose('selected')}
                            text={'Create Payment'}
                        />
                    </div>
                </div>
                : ''
            }
            <FullTable
                checkBox={true}
                head={payablesHead}
                body={payablesBody}
                loadingType={ACTION_TYPE}
                list={loadsList?.loads}
                listCount={!selectedLoads?.length ? loadsList?.count : ''}
                handleClick={(id) =>
                    history.push({
                        pathname: `/load/${id}`,
                        state: {detail: 'payableDetails', parent: '/payables'}
                    })
                }
                noText={'Payables'}
                handleClickButton={handleClick}
                dateFilter={true}
                handleCheckBox={handleSelect}
                customBodyItem={customChildren}
            />

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={!!open}
                content={
                    <>
                        {open === 'correction' ?
                            <CorrectionsModal
                                handleChangeText={(e) => setCorrection(e)}
                                loadingCor={correctionLoad}
                                handleSend={handleSend}
                                type={'notVerified'}
                                handleClose={() => handleOpenClose()}
                            />
                            :
                            open === 'selected' &&
                            <PayableCreatePayment
                                removeAllSelects={removeAllSelects}
                                handleClose={() => handleOpenClose()}
                                selectedLoads={selectedLoads}
                            />
                        }
                    </>
                }
            />
        </div>
    )
}