import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./directory.service";
import {
  GET_DIRECTORY, CREATE_DIRECTORY, EDIT_DIRECTORY, DELETE_DIRECTORY, GET_DIRECTORY_SUCCESS,
} from './directory.types';
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Directory */

function* getDirectory({ payload, type }) {
  if(payload?.load !== 'noLoad'){
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call( authService.getDirectoryService, payload?.params );
    yield put({
      type: GET_DIRECTORY_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}


function* createDirectory({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.createDirectoryService, payload?.body );
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}


function* editDirectory({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.editDirectoryService, payload.id, payload.body );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

function* deleteDirectory({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.deleteDirectoryService, payload.id, payload.body );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

/** End */

export const watchDirectory = function* watchDirectorySaga() {

  /** Directory */
  yield takeLatest( GET_DIRECTORY, getDirectory );
  yield takeLatest( CREATE_DIRECTORY, createDirectory );
  yield takeLatest( EDIT_DIRECTORY, editDirectory );
  yield takeLatest( DELETE_DIRECTORY, deleteDirectory );
  /** End */

};
