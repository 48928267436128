import axios from "axios";

export const authService = {
    /** Create, Edit, Get, Delete Term */

    createTermService: (body) => axios.post('/mc-conf', body, {auth: true}),

    getTermsService: () => axios.get('/mc-conf', {auth: true}),

    /** End */

    /** Quickbook */

    addQuickbooksService: () => axios.get(`/qb/authorize`, {auth: true}),

    getQuickbooksService: () => axios.get(`/qb/status`, {auth: true}),

    /** End */
};
