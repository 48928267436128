
export const LOAD_TYPES = {
  DRY: 'DRY',
  DRAYAGE: 'DRAYAGE',
  FLATBED: 'FLATBED',
  REEFER: 'REEFER',
  BOX_TRUCK: 'BOX_TRUCK',
  AIR: 'AIR',
  SEA: 'SEA',
  RAIL: 'RAIL',
  POWER: 'POWER',
  OTHER: 'OTHER',
}
