import axios from "axios";

export const authService = {
  /** History */

  getHistoryService: (onModel, queryParams) => axios.get(`/history`, { auth: true, params: { ...queryParams } }),

  /** End */

};
