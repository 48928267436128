import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    createInputsWrapper, ErrorText,
    FindErrorItem, FindSuccess,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
} from "utils";
import {EditSaveButtons, Line, ValidationInput} from "components";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, officeActions} from "store";
import {officesInfoFragments} from "./styles";

const actionType = 'EDIT_OFFICE'

export const PayrollSetup = ({info}) => {
    const globalStyles = useGlobalStyles();
    const globalScreens = createInputsWrapper();
    const classes = officesInfoFragments()
    const [disables, setDisabled] = useState(!!info)
    const dispatch = useDispatch();
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const backError = FindErrorItem(actionType)
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error
    const success = FindSuccess(actionType)

    useEffect(() => {
        if (success?.length) {
            setDisabled(true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType))
        }
    }, [success])

    const removeError = () => {
        errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType))
    }


    const handleCreate = () => {
        const editData = {
            "name": inputs.name,
            "email": inputs.email,
            "phoneNumber": info?.phoneNumber,
            "address": { address: info?.address?.formattedAddress},
            "officeId": info ? info.id : '',
            'payrollPercent': +inputs?.payrollPercent,
            'payrollFixedCost': +inputs?.payrollFixedCost,
        };
        if (info && +inputs?.payrollPercent !== 0) {
            dispatch(officeActions.editOffice(editData, info?.id))
        } else {
            setError(
                +inputs?.payrollPercent === 0 ? 'payrollPercent' : ''
            )
        }
    }

    const handleChange = e => {

        if (e.target.name === 'payrollPercent' && e.target.value <= 100) {
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}));
        } else if (e.target.name === 'payrollFixedCost') {
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}));
        }
        error === e.target.name && setError(''),
            removeError()
    }

    const handleChancel = () => {
        setDisabled(true)
        setInputs({...info})
        setError('')
        removeError()
    }

    return (
        <div>
            <div className={classes.lineWrapper}>
                <Line height={'100px'}/>
                <div className={globalScreens.createInputsWrapper}>

                    <div className={globalStyles.spaceBetween}>
                        <div className={globalStyles.centerItem}>
                            {!info && <p>Office Information</p>}
                        </div>
                        {info && RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
                            <EditSaveButtons
                                margin={true}
                                type={'EDIT_OFFICE'}
                                handleChancel={handleChancel}
                                handleSetEdit={() => setDisabled(false)}
                                handleSaveInfo={handleCreate}
                            />
                        }
                    </div>

                    <div className={globalStyles.flexAble}>
                        <div className={globalStyles.fullWidth}>
                            <div className={globalScreens.basicInfo}>
                                <div className={globalScreens.basicInfoInputs}>

                                    <ValidationInput
                                        className={info && globalStyles.inputTextFieldBlue}
                                        style={globalStyles.simpleInput}
                                        variant={"outlined"}
                                        name={"payrollPercent"}
                                        type={"text"}
                                        label={"Percent %"}
                                        onChange={handleChange}
                                        value={inputs.payrollPercent}
                                        disabled={disables}
                                        typeError={error === 'payrollPercent' ? ErrorText.field : ''}
                                    />

                                    <ValidationInput
                                        className={info && globalStyles.inputTextFieldBlue}
                                        variant={"outlined"}
                                        name={"payrollFixedCost"}
                                        type={"email"}
                                        label={"Fixed Cost"}
                                        onChange={handleChange}
                                        value={inputs.payrollFixedCost}
                                        disabled={disables}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}