import {
  createAdmin,
  getAdminById,
  getAdmins,
  activateAdmin,
  inactivateAdmin,
  search,
  editAdmin,
  addAdminRole,
  removeAdminRole,
  getAdminInfo, resendAdminInvite,
} from "./admin.action";
export { adminReducer } from './admin.reducer';
export { watchAdmin } from './admin.saga';
export { adminAuthService } from './admin.service';

export const adminActions = {
  /** Create, Edit Admin */
  createAdmin,
  editAdmin,
  resendAdminInvite,
  /** End */

  /** Get Admins */
  getAdmins,
  getAdminById,
  getAdminInfo,
  /** End */

  /** Activate or Inactivate Admin */
  activateAdmin,
  inactivateAdmin,
  /** End */

  /** Admins Filters */
  search,
  /** End */

  /** Add and Remove Role */
  addAdminRole,
  removeAdminRole
  /** End */
}



