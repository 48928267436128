import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CloseButton} from "components";
import {httpRequestsOnSuccessActions, LoadServices} from "store";
import {loadCommonStyle} from "./styles";

export const CarrierEmail = ({handleClose, loadById}) => {
    const classes = loadCommonStyle()
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)

    const handleSendEmail = async () => {
        setLoad(true)
        try {
            await LoadServices.SendEmail(loadById?.id)
            dispatch(httpRequestsOnSuccessActions.appendSuccess('SEND_EMAIL'))
            handleClose()
            setLoad(false)
        } catch (e) {
            setLoad(false)
        }
    }

    return (
        <div>
            <div className={classes.closeButtonWrapper}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div style={{overflow: 'scroll', height: '700px'}} className={classes.paperWrapper}>
                <p className={classes.carrierEmail}>The rate con will be sent to {loadById?.carrier?.email} </p>
                <div style={{height: '702px', overflow: 'scroll'}}>
                    <object
                        style={{overflow: 'hidden'}}
                        data={loadById?.carrierRateConPdf?.url}
                        width="100%" height="100%"
                    >
                    </object>
                </div>
            </div>
            <div className={classes.paperFooter}>
                <AddModalButton
                    loading={load}
                    styles={{width: '238px', marginRight: '24px',}}
                    handleClick={handleSendEmail}
                    text={'Send'}
                />
            </div>
        </div>
    )
}