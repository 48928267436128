import axios from "axios";

export const authService = {

  createCustomerPaymentService: ( body ) => axios.post(`/customer-pmts`, body, {auth:true}),

  editCustomerPaymentService: ( id, body ) => axios.patch(`/customer-pmts/${id}`, body, {auth:true}),

  getCustomerPaymentsService: ( params ) => axios.get(`/customer-pmts`, {auth:true, params:{...params}}),

  getCustomerPaymentsByIdService: ( id ) =>  axios.get(`/customer-pmts/${id}`, {auth:true}),

  deleteCustomerPayment: ( id, customerId ) =>  axios.delete(`/customer-pmts/${id}/customers/${customerId}`, {auth:true}),

  voidCustomerService: ( id ) => axios.patch(`/customer-pmts/${id}/void`, null, {auth: true}),



  createCustomerPaymentLoadService: (data, id ) => axios.post(`/customer-pmts/${id}/loadPmts`, data, {auth: true}),

  getCustomerPaymentLoadService: (id) => axios.get(`/customer-pmts/${id}/loadPmts`, {auth: true}),

  voidCustomerPaymentLoadService: (id, loadId) => axios.patch(`/customer-pmts/${id}/loadPmts/${loadId}/void`, null,{auth: true}),

  // Customer Payment Documents

  addCustomerPaymentDocument: (doc, id) => axios.post(`/customer-pmts/${id}/documents`, {...doc, status:'ACTIVE'}, {auth: true}),

  deleteCustomerPaymentDocument: (id, docId) => axios.delete(`/customer-pmts/${id}/documents/${docId}`, {auth: true}),

  setPmtDocumentStatus: (id, docId, info) => axios.patch(`/customer-pmts/${id}/documents/${docId}`, {...info}, {auth: true}),

};