import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    EmailValidator,
    useGlobalStyles,
    useGlobalText,
    createInputsWrapper,
    ErrorText,
    FindLoad, FindErrorItem, FindSuccessItem, PermissionsList, RolePermission, format,
} from "utils";
import {
    CreateChancel,
    EditSaveButtons,
    FormatAddress,
    Line,
    MaskInput,
    ValidationInput,
} from 'components';
import {adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from "store";
import moment from "moment";

export const CreateAdminInputs = ({handleChangeFirstName, handleChangeLastName, info}) => {
    const globalStyle = useGlobalText();
    const globalWrappers = useGlobalStyles();
    const globalScreens = createInputsWrapper();
    const globalStyles = useGlobalStyles();
    const dispatch = useDispatch()
    const history = useHistory();
    const [disables, setDisabled] = useState(!!info)
    const [error, setError] = useState("");
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const [fullAddress, setFullAddress] = useState(info?.address ? info?.address : null);
    const [email, setEmail] = useState('')
    const actionType = info ? 'EDIT_ADMIN' : 'CREATE_ADMIN'
    const loader = FindLoad('CREATE_ADMIN')
    const success = FindSuccessItem('EDIT_ADMIN')
    const backError = FindErrorItem(actionType)
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error

    const removeError = () => {
        errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType))
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
        error === e.target.name && setError('')
        e.target.name === 'firstName' && handleChangeFirstName && handleChangeFirstName(e.target.value)
        e.target.name === 'lastName' && handleChangeLastName && handleChangeLastName(e.target.value)
        removeError()
    }

    const handleCheck = (bool) => {
        if (bool === true) {
            setEmail("Not valid email");
        } else {
            setEmail("");
        }
    };

    const handleFullAddress = (ev) => {
        setFullAddress(ev)
        removeError()
        if (error === 'address') setError('')
    }

    const handleCreate = () => {
        const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + ''
        const addressIsValid = fullAddress?.street && fullAddress?.city && fullAddress?.country

        const data = {
            "firstName": inputs.firstName,
            "lastName": inputs.lastName,
            "username": inputs.username,
            "email": inputs.email,
            "phoneNumber": phoneNumber,
            "ssn": inputs?.ssn,
            "dl": inputs.dl,
            "role": inputs.role,
            "address": fullAddress,
            "dob": new Date(inputs.dob),
        }

        const id = info && info.id
        if (!email.length) {
            if (inputs.firstName && inputs.lastName && inputs.username && inputs.dob && inputs.email && inputs.phoneNumber && inputs.ssn &&
                inputs.dl && addressIsValid) {
                if (info) {
                    dispatch(adminActions.editAdmin(id, data))
                } else {
                    dispatch(adminActions.createAdmin(data))
                }
            } else {
                setError(
                    !inputs.firstName ? 'firstName' :
                        !inputs.lastName ? 'lastName' :
                            !inputs.username ? 'username' :
                                !inputs.dob ? 'dob' :
                                    !inputs.email ? 'email' :
                                        !inputs.phoneNumber ? 'phoneNumber' :
                                            !inputs.ssn ? 'ssn' :
                                                !inputs.dl ? 'dl' :
                                                  !addressIsValid ? 'address' :
                                                    '',
                )
            }
        }
    }

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType))
            setDisabled(true)
        }
    }, [success]);

    useEffect(() => {
        return () => removeError()
    }, [])

    const handleChancel = () => {
        setDisabled(true)
        setInputs({...info})
        setFullAddress(info?.address)
        setError('')
        removeError()
    }

    return (
        <div className={globalScreens.createInputsWrapper}>
            <div className={globalWrappers.spaceBetween}>
                <div className={globalWrappers.centerItem}>
                    {!info && <p className={globalStyle.title}>Person Information</p>}
                </div>
                {info && RolePermission([PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
                    <EditSaveButtons
                        margin={true}
                        type={'EDIT_ADMIN'}
                        handleChancel={handleChancel}
                        handleSetEdit={() => setDisabled(false)}
                        handleSaveInfo={handleCreate}
                    />}
            </div>

            <div className={globalWrappers.flexAble}>
                {info && <Line height={'390px'}/>}
                <div className={globalWrappers.fullWidth}>
                    <div className={globalScreens.basicInfo}>
                        <p className={globalStyle.smallText}>Basic Information</p>
                        <div>
                            <div className={globalScreens.basicInfoInputs}>
                                <ValidationInput
                                    className={info && globalStyles.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    variant={"outlined"}
                                    name={"firstName"}
                                    label={"First Name*"}
                                    value={inputs.firstName}
                                    onChange={handleChange}
                                    disabled={disables}
                                    typeError={error === 'firstName' ? ErrorText.field : ''}
                                />
                                <ValidationInput
                                    className={info && globalStyles.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    variant={"outlined"}
                                    name={"lastName"}
                                    label={"Last Name*"}
                                    value={inputs.lastName}
                                    onChange={handleChange}
                                    disabled={disables}
                                    typeError={error === 'lastName' && ErrorText.field}
                                />
                                <ValidationInput
                                    className={info && globalStyles.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    variant={"outlined"}
                                    name={"username"}
                                    label={"Username"}
                                    value={inputs.username}
                                    onChange={handleChange}
                                    disabled={disables}
                                    typeError={error === 'username' && ErrorText.field}
                                />
                                <ValidationInput
                                    className={info && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"dob"}
                                    type={"date"}
                                    typeError={error === 'dob' ? ErrorText.field : ''}
                                    label={"Date of Birth*"}
                                    onChange={handleChange}
                                    value={info ? moment(inputs?.dob).format('YYYY-MM-DD') : inputs.dob}
                                    disabled={disables}
                                />
                            </div>
                            <div className={globalScreens.basicInfoInputs}>
                                <ValidationInput
                                    className={
                                        errorText === "User already exists" ? globalStyles.inputTextFieldError : info && globalStyles.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    validator={EmailValidator}
                                    variant={"outlined"}
                                    name={"email"}
                                    type={"email"}
                                    label={"Email Address*"}
                                    typeError={
                                        errorText === "User already exists" ? ErrorText.user :
                                            error === 'email' ? ErrorText.field :
                                                email === 'Not valid email' ? 'Not valid email' : ''
                                    }
                                    sendBoolean={handleCheck}
                                    value={inputs.email}
                                    onChange={handleChange}
                                    disabled={disables}
                                />
                                <MaskInput
                                    name='phoneNumber'
                                    label="Phone Number*"
                                    value={inputs.phoneNumber}
                                    disables={disables}
                                    handleChange={handleChange}
                                    info={info}
                                    error={
                                        error === 'phoneNumber' ? ErrorText.field :
                                            errorText === "phoneNumber must be a valid phone number" && ErrorText.phone}
                                />
                                {RolePermission([PermissionsList.CREATE_UPDATE_ADMIN?.code, PermissionsList.ADMIN_SSN?.code]) &&
                                    <MaskInput
                                        mask="999-99-9999"
                                        name='ssn'
                                        label='SSN*'
                                        value={inputs.ssn}
                                        disables={disables}
                                        handleChange={handleChange}
                                        info={info}
                                        error={
                                            error === 'ssn' && ErrorText.field ||
                                            errorText === 'Social security number is incorrect' && 'Social security number is incorrect'
                                        }
                                    />
                                }
                                <ValidationInput
                                    className={info && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"dl"}
                                    label={"Driver License*"}
                                    value={inputs.dl}
                                    onChange={handleChange}
                                    disabled={disables}
                                    typeError={error === 'dl' && ErrorText.field}
                                    Length={15}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={globalScreens.basicInfo}>
                        <p className={globalStyle.smallText}>Address</p>
                        <div className={globalScreens.basicInfoInputs}>
                            <FormatAddress
                              noBlue={!info?.address}
                              setCurrentAddress={handleFullAddress}
                              selectedAddress={info?.address}
                              errorBoolean={error === 'address'}
                              disabled={disables}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {!info &&
                <CreateChancel
                    loader={!!loader.length}
                    classes={globalWrappers.buttonsStyle}
                    create={"Create"}
                    chancel={"Cancel"}
                    onCreate={handleCreate}
                    onClose={() => history.push("/humanResources")}
                />
            }
        </div>
    );
};
