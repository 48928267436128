import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import {
    AddModalButton, AddNotes,
    Circle,
    CorrectionsModal,
    DeleteElement, FullTable,
    Line,
    NoYet,
    SimpleModal
} from "components";
import {
    Colors,
    FindLoad,
    FindSuccess,
    Images,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText, useModal
} from "utils";
import {billingDetailsStyle, Items, SubmitBillModal} from "./core";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {
    billingHisBody,
    billingHisHead,
    ACTION_TYPE,
    NOT_BILLABLE,
    CLOSE_BILL,
    CORRECTION_SENDED, BILLED
} from "./core/constants";
import { CANCELLED, PAID } from '../../../pages/accounting/constants';
import {createLoadStyle} from "../../loads/createLoad/core";

const RE_BILL = 'RE_BILL'
export const BillingDetails = ({loadById}) => {
    const {pastBill} = useSelector((state) => ({
        pastBill: state.loads.pastBill,
    }));
    const globalStyles = useGlobalStyles()
    const globalText = useGlobalText()
    const classes = billingDetailsStyle()
    const loadStyles = createLoadStyle()
    const history = useHistory()
    const dispatch = useDispatch()
    const [openModal, setOpen] = useState(false)
    const [openCorrection, setOpenCorrection] = useState(false)
    const [openSubmitBill, setOpenSubmitBill] = useState(false)
    const [closeBill, setCloseBill] = useState(false)
    const [loading, setLoading] = useState(false)
    const [correction, setCorrection] = useState('')
    const success = FindSuccess(CLOSE_BILL)
    const loader = FindLoad(RE_BILL)
    const pageInfo = history.location.state && history.location.state.detail && history.location.state.detail === "payableDetails"
    const billingStatus = loadById?.bill?.status !== NOT_BILLABLE && loadById?.bill?.status !== BILLED && loadById?.bill?.status !== "CLOSED"
    const correctionStatus = loadById?.bill?.status !== NOT_BILLABLE && loadById?.bill?.status !== "CLOSED"
    const submittedBill = ( loadById?.bill?.status === BILLED  || loadById?.bill?.status === PAID ) &&  loadById?.bill?.status !== "CLOSED"
    const { open } = useModal()
    const params = useParams()

    const handleGetPastBills = () => {
        dispatch(loadActions.getPastBill(loadById?.id))
    }
    useEffect(() => {
        handleGetPastBills()
    },[])

    useEffect(() => {
        if (success.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CLOSE_BILL))
            setOpen(false)
        }
    }, [success])

    const submitBill = () => {
        setOpenSubmitBill(!openSubmitBill)
    }

    const handleReBill = () => {
        dispatch(loadActions.reBill(loadById?.id))
    }

    const reBill = () => {
        open(<AddNotes onResource={params.id} handleSuccess={handleReBill}/>)
    }

    const handleOpenCloseCorrection = () => {
        setOpenCorrection(!openCorrection)
    }

    const handleCloseBill = async () => {
        setCloseBill(true)
        try {
            await LoadServices.CloseBillingLoad(loadById.id)
            dispatch(loadActions.getLoadById(loadById.id, 'noLoad'))
            handleGetPastBills()
            dispatch(httpRequestsOnSuccessActions.appendSuccess(CLOSE_BILL))
            setCloseBill(false)
        } catch (e) {
            setCloseBill(false)
        }
    }

    const handleSend = async () => {
        setLoading(true)
        try {
            await LoadServices.SendBillingCorrection(loadById.id, correction)
            dispatch(loadActions.getLoadById(loadById.id, 'noLoad'))
            handleGetPastBills()
            dispatch(httpRequestsOnSuccessActions.appendSuccess(CORRECTION_SENDED))
            setLoading(false)
            setOpenCorrection(false)
            setOpenSubmitBill(false)
        } catch (e) {
            setLoading(false)
        }
    }

    const renderDate = (date) => {
        if(date){
            return moment.utc(date).format('MM/DD/YYYY')
        }else{
            return 'N/A'
        }
    }

    return (
        <div>
            <div>

                {loadById?.customer?.nonFactoring === true  &&
                    <div style={{width:'265px'}} className={loadStyles.canceledLoad}>
                        <img src={Images.nonFactoring} alt="revisionNote"/>
                        <div className={loadStyles.revisionNeededWrapper}>
                            <p style={{fontWeight:600}}> Non-Factoring Customer </p>
                        </div>
                    </div>
                }


                <div className={globalStyles.spaceBetween}>
                    <div style={{width: '100%'}} className={globalStyles.centerItem}>
                        <Circle number={1} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Current Bill</p>
                    </div>
                    {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CLOSE_BILL?.code]) &&
                        loadById?.status !== CANCELLED &&
                        <div className={classes.closeBill}>
                            {loadById?.bill?.status === BILLED ?
                                <button onClick={() => setOpen(!openModal)}>Close Bill</button> : ''}
                        </div>
                    }
                </div>



                <div className={globalStyles.flexAndFullWidth}>
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'170px'}/>
                    </div>
                    {loadById?.bill?.status ?
                        <div style={{width: '100%'}}>
                            <div style={{width: '100%'}}>

                                <div className={classes.itemsSection}>
                                <Items styles={{marginRight: '16px'}} title='Bill Amount:' text={loadById?.bill?.totalRate}  price={true}/>
                                <Items styles={{marginRight: '16px'}} title='Paid Amount:' text={loadById?.bill?.totalPaid} price={true}/>
                                <Items styles={{marginRight: '16px'}} title='Paid Date:' text={renderDate(loadById?.bill?.lastPaidDate)} />
                                <Items styles={{marginRight: '0'}} title='Balance:' text={loadById?.bill?.totalRate - loadById?.bill?.totalPaid} price={true}/>
                                </div>

                                <div className={classes.itemsSection}>
                                <Items styles={{marginRight: '16px'}} title='Billed Date:' text={renderDate(loadById?.bill?.lastInvoiceDate)}/>
                                <Items styles={{marginRight: '16px'}} title='Term:' text={loadById?.bill?.term} width={40}/>

                                <Items styles={{marginRight: '16px'}} title='Due Date:' text={
                                    renderDate(new Date().setDate(new Date( loadById?.bill?.lastInvoiceDate).getDate() + loadById?.bill?.term))} width={70}/>
                                        <Items
                                            title='Billing Docs'
                                            type='download'
                                            show={true}
                                            file={{file:loadById?.bill?.lastInvoiceDocs, name:loadById?.bill?.lastInvoiceDocs?.originalName}}
                                            url={loadById?.bill?.lastInvoiceDocs?.url}
                                        />
                                </div>
                            </div>

                            {loadById?.bill?.status === PAID &&
                              <div className="sendTo" style={{marginTop:'20px'}}>
                                  <img src={Images.greenCheckmark} alt="icon" />
                                  <p className="needed"> Paid</p>
                              </div>
                            }
                            {
                              loadById?.status !== CANCELLED &&
                              <div style={{ marginBottom: '15px' }} className={classes.addButtonsWrapper}>
                                  {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code]) && correctionStatus &&
                                    billingStatus && pageInfo !== true && loadById?.bill?.status !== PAID &&
                                    <AddModalButton
                                      styles={{ width: '238px', marginRight: '24px' }}
                                      handleClick={submitBill}
                                      text={'Submit Bill'}
                                    />
                                  }
                                  {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code]) && correctionStatus &&
                                    submittedBill && pageInfo !== true &&
                                    <AddModalButton
                                      loading={!!loader?.length}
                                      styles={{ width: '238px', marginRight: '24px' }}
                                      handleClick={reBill}
                                      text={'Re-Bill '}
                                      color={'blue'}
                                    />
                                  }

                                  {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.REQUEST_CORRECTION_BILLING?.code]) && correctionStatus &&
                                    <button
                                      onClick={handleOpenCloseCorrection}
                                      className={classes.requestCorrection}
                                    >
                                        Request Corrections
                                    </button>
                                  }
                              </div>
                            }
                        </div>
                      :
                      <NoYet position={'screenCenter'} text={'No Bill Found'}/>
                    }
                </div>
            </div>
            <div>
                <div>
                    <div style={{marginBottom: '24px'}} className={globalStyles.centerItem}>
                        <Circle number={2} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Billing History</p>
                    </div>
                </div>
                <div className={classes.billingHistory}>
                    <FullTable
                        head={billingHisHead}
                        body={billingHisBody}
                        loadingType={ACTION_TYPE}
                        list={pastBill}
                        listCount={[]}
                        noText={'Billing History'}
                        margin={true}
                        height={'small'}
                    />
                </div>
            </div>
            <SimpleModal
                handleOpenClose={() => setOpen(!openModal)}
                openDefault={openModal}
                content={
                    <DeleteElement
                        loading={closeBill}
                        type={'close'}
                        text={'Close Bill'}
                        handleClose={() => setOpen(!openModal)}
                        handleDel={handleCloseBill}
                        info={'Bill'}
                        modalText={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text.'}
                    />
                }
            />
            <SimpleModal
                handleOpenClose={handleOpenCloseCorrection}
                openDefault={openCorrection}
                content={
                    <CorrectionsModal
                        handleChangeText={(e) => setCorrection(e)}
                        loadingCor={loading}
                        handleSend={handleSend}
                        type={'notVerified'}
                        handleClose={handleOpenCloseCorrection}
                    />
                }
            />
            <SimpleModal
                handleOpenClose={submitBill}
                openDefault={openSubmitBill}
                content={
                    <SubmitBillModal
                        loaderBill={loading}
                        handleSend={handleOpenCloseCorrection}
                        loadById={loadById}
                        handleClose={submitBill}
                    />
                }
            />
        </div>
    )
}