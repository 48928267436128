import {addQuickbook, createTerm, deleteTerm, getQuickbook, getTerms} from "./terms.action";
export {termsReducer} from './terms.reducer';
export {watchTerm} from './terms.saga';

export const termActions = {
    /** Create, Edit, Get, Delete,  Term */
    createTerm,
    getTerms,
    deleteTerm,
    /** End */

    /** Quickbook */
    addQuickbook,
    getQuickbook,
    /** End */
}

