import React from "react";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {ErrMessage} from "../messages";
import {ErrorText} from "utils";

const hourArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',]
const minuteArray = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
]

export const TimePicker = ({changeHour, changeMinute, err, hour, minute}) => {

    const handeChangeHour = (e) => {
        changeHour(e)
    }

    const handeChangeMinute = (e) => {
        changeMinute(e)
    }

    return (
        <div>
            <div style={err ? {borderColor: '#F07379'} : hour && minute ? {borderColor:'#438AFE'} : {}} className='time-picker'>
                <div className='time-picker-wrapper'>
                    <select
                        value={hour}
                        onChange={handeChangeHour}
                    >
                        <option value=""> hh</option>
                        {hourArray?.map((i, k) => (
                            <option key={k} value={i}>{i}</option>
                        ))}
                    </select>


                    <select
                        value={minute}
                        onChange={handeChangeMinute}
                    >
                        <option value=""> mm</option>
                        {minuteArray?.map((i, k) => (
                            <option key={k} value={i}>{i}</option>
                        ))}
                    </select>
                </div>

                <AccessTimeIcon/>
            </div>

            {err &&
                <div style={{position: 'relative'}}>
                    <ErrMessage text={ErrorText.field}/>
                </div>
            }

        </div>
    )
}