import { DateRow, PhoneRow, TextRowNotSet, TypeRow } from 'components';


export const agentInformation = (item) => {
  return [
    { title: 'First Name:', value: <TextRowNotSet name={item?.firstName} /> },
    { title: 'Last Name:', value: <TextRowNotSet name={item?.lastName} /> },
    { title: 'Username:', value: <TextRowNotSet name={item?.username} /> },
    { title: 'Office:', value: <TextRowNotSet name={item?.office?.name} /> },
    { title: 'Branch:', value: <TextRowNotSet name={item?.branch?.name} /> },
    { title: 'Email:', value: <TextRowNotSet name={item?.email} /> },
    { title: 'Phone Number:', value: <PhoneRow phone={item?.phoneNumber} /> },
  ];
};

export const agentInformationAddress = (item) => {
  return [
    { title: 'Physical Address:', value: <TextRowNotSet name={item?.address?.formattedAddress} /> },
    { title: 'Suit:', value: <TextRowNotSet name={item?.address?.unitNumber} /> },
    { title: 'City:', value: <TextRowNotSet name={item?.address?.city} /> },
    { title: 'Country:', value: <TextRowNotSet name={item?.address?.country} /> },
    { title: 'State:', value: <TextRowNotSet name={item?.address?.state} /> },
    { title: 'Zip Code:', value: <TextRowNotSet name={item?.address?.zip} /> },
  ];
};

export const factoredInformation = (item) => {
  return [
    { title: 'First Name:', value: <TextRowNotSet name={item?.firstName} /> },
    { title: 'Last Name:', value: <TextRowNotSet name={item?.lastName} /> },
    { title: 'Date Of Birth:', value: <DateRow date={item?.birthdate} /> },
    { title: 'Load Mc:', value: <TextRowNotSet name={`${item?.loadMcName} (${item?.loadMcNumber})`} /> },
    { title: 'Email:', value: <TextRowNotSet name={item?.email} /> },
    { title: 'Secondary Email:', value: <TextRowNotSet name={item?.remittanceEmail} /> },
    { title: 'Phone Number:', value: <PhoneRow phone={item?.phone} /> },
    { title: 'Secondary Phone:', value: <PhoneRow phone={item?.secondaryPhone} /> },
    { title: 'Driver’s License:', value: <TextRowNotSet name={item?.dl} /> },
    { title: 'Issuing State:', value: <TextRowNotSet name={item?.dlState} /> },
  ];
};

export const factoredInformationAddress = (item) => {
  return [
    { title: 'Physical Address:', value: <TextRowNotSet name={item?.shippingAddress?.formattedAddress} /> },
    { title: 'Suit:', value: <TextRowNotSet name={item?.shippingAddress?.unitNumber} /> },
    { title: 'City:', value: <TextRowNotSet name={item?.shippingAddress?.city} /> },
    { title: 'Country:', value: <TextRowNotSet name={item?.shippingAddress?.country} /> },
    { title: 'State:', value: <TextRowNotSet name={item?.shippingAddress?.state} /> },
    { title: 'Zip Code:', value: <TextRowNotSet name={item?.shippingAddress?.zip} /> },
  ];
};

export const factoredMailingInformationAddress = (item) => {
  return [
    { title: 'Physical Address:', value: <TextRowNotSet name={item?.mailingAddress?.formattedAddress} /> },
    { title: 'Suit:', value: <TextRowNotSet name={item?.mailingAddress?.unitNumber} /> },
    { title: 'City:', value: <TextRowNotSet name={item?.mailingAddress?.city} /> },
    { title: 'Country:', value: <TextRowNotSet name={item?.mailingAddress?.country} /> },
    { title: 'State:', value: <TextRowNotSet name={item?.mailingAddress?.state} /> },
    { title: 'Zip Code:', value: <TextRowNotSet name={item?.mailingAddress?.zip} /> },
  ];
};


export const factoredCompanyInformation = (item) => {
  return [
    { title: 'Company Name:', value: <TextRowNotSet name={item?.companyName} /> },
    { title: 'Company Type:', value: <TypeRow text={item?.companyType ? item?.companyType : 'Not Set'} /> },
    { title: 'Type:', value: <TypeRow text={item?.docketType ? item?.docketType : 'Not Set'} /> },
    { title: 'Authority Number:', value: <TextRowNotSet name={item?.docketNumber} /> },
    { title: 'USDOT Number:', value: <TextRowNotSet name={item?.taxId} /> },
    { title: 'Tax ID:', value: <TextRowNotSet name={item?.dot} /> },
    { title: 'Email:', value: <TextRowNotSet name={item?.email} /> },
    { title: 'Insurance Expiration Date:', value: <DateRow date={item?.insuranceExpiry} /> },
    { title: 'Phone Number:', value: <PhoneRow phone={item?.phoneNumber} /> },
  ];
};

export const factoredCompanyInformationAddress = (item) => {
  return [
    { title: 'Physical Address:', value: <TextRowNotSet name={item?.address?.formattedAddress} /> },
    { title: 'Suit:', value: <TextRowNotSet name={item?.address?.unitNumber} /> },
    { title: 'City:', value: <TextRowNotSet name={item?.address?.city} /> },
    { title: 'Country:', value: <TextRowNotSet name={item?.address?.country} /> },
    { title: 'State:', value: <TextRowNotSet name={item?.address?.state} /> },
    { title: 'Zip Code:', value: <TextRowNotSet name={item?.address?.zip} /> },
  ];
};


export const convertPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case 'CHECK':
      return 'Check';
    case 'EFS':
      return 'EFS';
    case 'ACH':
      return 'ACH';
    case 'WIRE':
      return 'Wire';
    default:
      return 'Not Set';
  }
};

export const renderPaymentTerm = (paymentTerm) => {
  switch (paymentTerm) {
    case 'CUSTOM_QUICKPAY':
      return 'Custom Quick Pay';
    case 'THIRTYDAY':
      return '30 Day (No Percent)';
    case 'QUICKPAY':
      return 'Quick Pay (3.5%)';

    default:
      return 'Not Set';
  }

};