import React, { Fragment, useState } from "react";
import { Container, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Images } from "utils";

export const EfficiencyCore = () => {
  /**
   * Header Core.
   */

  const handleLogIn = () => {};

  return (
    <Fragment>
      <div className="section-title">
        <h2 className="title font-semiBold text-h2">
          Efficiency in motion: Your
          <br />
          freight, our focus
        </h2>
      </div>
      <div className="features-card">
        <Grid container className="spacing">
          <Grid item xs={12} sm={6} lg={3}>
            <div className="card-features column">
              <div className="img-box">
                <img src={Images.planning} alt="" className="feature-img" />
              </div>
              <div className="card-title ">
                <h5 className="font-semiBold text-xl">Planning</h5>
              </div>
              <div className="card-description ">
                <p className="features-parapgraphy">
                  It involves determining the most efficient route for
                  transportation, selecting the appropriate mode of
                  transportation.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className="card-features column">
              <div className="img-box">
                <img
                  src={Images.execution}
                  alt="Users-Img"
                  className="feature-img"
                />
              </div>
              <div className="card-title ">
                <h5 className="font-semiBold text-xl">Execution</h5>
              </div>
              <div className="card-description ">
                <p className="features-parapgraphy">
                  Ensuring the availability of the required transportation mode
                  and monitoring the movement.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className="card-features column">
              <div className="img-box">
                <img
                  src={Images.compliance}
                  alt="Loaded-Truck"
                  className="feature-img"
                />
              </div>
              <div className="card-title">
                <h5 className="font-semiBold text-xl">Compliance</h5>
              </div>
              <div className="card-description">
                <p className="features-parapgraphy">
                  It involves ensuring that all transportation activities comply
                  with relevant regulations.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className="card-features column">
              <div className="img-box">
                <img
                  src={Images.collaboration}
                  alt="Page-Img"
                  className="feature-img"
                />
              </div>
              <div className="card-title">
                <h5 className="font-semiBold text-xl">Collaboration</h5>
              </div>
              <div className="card-description">
                <p className="features-parapgraphy">
                  Working with suppliers, carriers, and customers to coordinate
                  the transportation process.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};
