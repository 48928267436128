import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {equipmentActions, httpRequestsOnSuccessActions} from "store";
import {
    AntSwitch,
    createInputsWrapper,
    ErrorText,
    FindErrorItem,
    FindSuccessItem, PermissionsList, RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {Line, ValidationInput, EditSaveButtons, CustomSelect, SelectTypeAutocomplete, AddressInput} from "components";
import {equipmentType, serviceType, trailerLength} from "./addEquipmentModal";
import {homeBaseById} from "../../../constants";
import moment from "moment";

const actionType = 'EDIT_EQUIPMENT'

export const EquipmentDetailInputs = ({info}) => {
    const globalTextStyle = useGlobalText();
    const globalStyles = useGlobalStyles();
    const globalScreens = createInputsWrapper();
    const dispatch = useDispatch();
    const [disables, setDisabled] = useState(!!info)
    const [fullAddress, setFullAddress] = useState({})
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const [returnToHomeBase, setReturnToHomeBase] = useState(false);
    const success = FindSuccessItem(actionType)
    const params = useParams()

    useEffect(() => {
        if (info?.address) {
            const sendAddress = {
                address: info?.address?.formattedAddress,
            }
            if (info?.address?.unitNumber) {
                sendAddress.unitNumber = info?.address?.unitNumber
            }
            setFullAddress(sendAddress)
        }
        if (info) {
            setReturnToHomeBase(info?.returnToHomebase)
        }
    }, [info])

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType))
            setDisabled(true)
        }
    }, [success]);

    useEffect(() => {
        return () => {
            removeError()
        }
    }, [])

    const removeError = () => {
        // errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType))
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}));
        error === e.target.name && setError('')
    }

    const handleCreate = () => {
        const data = {
            "name": inputs?.name,
            "carrierId": params?.carrierId,
            "equipmentType": inputs?.equipmentType,
            "serviceType": inputs?.serviceType,
            "trailerLength": inputs?.trailerLength,
            "origin": inputs?.origin || inputs?.originCity,
            "returnToHomebase": returnToHomeBase,
            // "availableDate": inputs?.availableDate ? new Date(inputs?.availableDate) : null
        };
        inputs?.homebaseState ? data.homebaseState = inputs?.homebaseState : delete data.homebaseState
        inputs?.homebaseCity ? data.homebaseCity = inputs?.homebaseCity : delete data.homebaseCity
        inputs?.originCity ? data.originCity = inputs?.originCity : delete data.originCity
        inputs?.originState ? data.originState = inputs?.originState : delete data.originState
        info?.regions ? data.regions = info?.regions : delete data.regions
        inputs?.availableDate ? data.availableDate = new Date(inputs?.availableDate)  : delete inputs?.availableDate
        if (inputs.name && inputs.serviceType && inputs.equipmentType && inputs.trailerLength) {
            dispatch(equipmentActions.editEquipment(info.id, data))
        } else {
            setError(
                !inputs.name ? 'name' :
                    !inputs.serviceType ? 'serviceType' :
                        !inputs.equipmentType ? 'equipmentType' :
                            !inputs.trailerLength ? 'trailerLength' :
                                ''
            )
        }
    }

    const handleChancel = () => {
        setDisabled(true)
        setInputs({...info})

        const sendAddress = {
            address: info?.address?.formattedAddress,
        }
        if (info?.address?.unitNumber) {
            sendAddress.unitNumber = info?.address?.unitNumber
        }
        setFullAddress(sendAddress)
        // setFullAddress(info?.address?.formattedAddress)
        setError('')

        removeError()
    }


    return (
        <div className={globalScreens.createInputsWrapper}>
            <div className={globalStyles.spaceBetween}>
                <div className={globalStyles.centerItem}/>
                {/*{info && RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&*/}
                <EditSaveButtons
                    margin={true}
                    type={actionType}
                    handleChancel={handleChancel}
                    handleSetEdit={() => setDisabled(false)}
                    handleSaveInfo={handleCreate}
                />
                {/*}*/}
            </div>

            <div className={globalStyles.flexAble}>
                <Line height={'340px'}/>
                <div className={globalStyles.fullWidth}>
                    <div className={globalScreens.basicInfo}>
                        <p className={globalTextStyle.smallText}>Basic Information</p>
                        <div className={globalScreens.basicInfoInputs} style={{marginTop: '24px'}}>
                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInput}
                                variant={"outlined"}
                                name={"name"}
                                type={"text"}
                                label={"Name*"}
                                typeError={error === 'name' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs?.name}
                                disabled={disables}
                            />
                            <CustomSelect
                                className={info && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInput}
                                disabled={disables}
                                selectedValue={'id'}
                                showValue={'name'}
                                name={"serviceType"}
                                label={"Service Type*"}
                                handleSelect={handleChange}
                                value={inputs.serviceType}
                                list={serviceType}
                                typeError={error === 'serviceType' && ErrorText.field}
                            />
                            <CustomSelect
                                className={info && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInput}
                                disabled={disables}
                                selectedValue={'id'}
                                showValue={'name'}
                                name={"equipmentType"}
                                label={"Equipment Type*"}
                                handleSelect={handleChange}
                                value={inputs.equipmentType}
                                list={equipmentType}
                                typeError={error === 'equipmentType' && ErrorText.field}
                            />
                            <CustomSelect
                                className={info && globalStyles.inputTextFieldBlue}
                                disabled={disables}
                                selectedValue={'id'}
                                showValue={'name'}
                                name={"trailerLength"}
                                label={"Trailer Length*"}
                                handleSelect={handleChange}
                                value={inputs.trailerLength}
                                list={trailerLength}
                                typeError={error === 'trailerLength' && ErrorText.field}
                            />
                        </div>
                    </div>

                    <div className={globalScreens.basicInfoInputs}>
                        <div className={'flex'} style={{marginRight: '16px',}}>
                            <div style={{marginRight: '16px', width: '100%'}}>
                                <SelectTypeAutocomplete
                                    style={globalStyles.loadSimpleInputFull}
                                    name={'originState'}
                                    label={'name'}
                                    title={'Origin State'}
                                    handleSelect={handleChange}
                                    defaultValue={inputs?.originState ? inputs?.originState : ''}
                                    list={homeBaseById}
                                    right={'1px'}
                                    disabled={disables}
                                />
                            </div>
                            <ValidationInput
                                className={info?.originCity && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInputFull}
                                variant={"outlined"}
                                name={"originCity"}
                                type={"text"}
                                typeError={error === 'originCity' ? ErrorText.field : ''}
                                label={"Origin City"}
                                onChange={handleChange}
                                value={inputs?.originCity}
                                disabled={disables}
                            />
                        </div>
                        <div className={'flex'}>
                            <div style={{marginRight: '16px', width: '100%'}}>
                                <SelectTypeAutocomplete
                                    style={globalStyles.loadSimpleInputFull}
                                    name={'homebaseState'}
                                    label={'name'}
                                    title={'Home Base Region'}
                                    handleSelect={handleChange}
                                    defaultValue={inputs?.homebaseState ? inputs?.homebaseState : ''}
                                    list={homeBaseById}
                                    right={'1px'}
                                    disabled={disables}
                                />
                            </div>
                            <ValidationInput
                                className={info?.homebaseCity && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInputFull}
                                variant={"outlined"}
                                name={"homebaseCity"}
                                type={"text"}
                                typeError={error === 'homebaseCity' ? ErrorText.field : ''}
                                label={"Home Base City"}
                                onChange={handleChange}
                                value={inputs?.homebaseCity}
                                disabled={disables}
                            />
                        </div>
                    </div>
                    <div className={globalScreens.basicInfoInputs}>
                        <div className='date-input-wrapper'>
                            <ValidationInput
                                className={info?.availableDate && globalStyles.inputTextFieldBlue}
                                style={globalStyles.loadSimpleInput}
                                variant={"outlined"}
                                name={"availableDate"}
                                type={"date"}
                                label={"Available Date"}
                                typeError={error === 'availableDate' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs?.availableDate ?
                                    moment(inputs?.availableDate).format('YYYY-MM-DD') :
                                    null}
                                disabled={disables}
                            />
                        </div>
                        <div style={{width: '100%'}}/>
                    </div>
                    <div className='return-home-base-selector'>
                        <div>
                            <span className='home-base-selector-title'>{'Return to Home Base'}</span>
                            {RolePermission([PermissionsList.CARRIER_STATUS?.code]) &&
                                <AntSwitch
                                    onClick={() => setReturnToHomeBase(!returnToHomeBase)}
                                    checked={returnToHomeBase}
                                    disabled={disables}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
