import {Images} from "utils";
import React from "react";
import {errMessageStyle} from "./styles";

export const ErrWarn = ({ screenType, text, styles }) =>{
    const classes = errMessageStyle();
    return(
        <div style={{...styles}}>
            <div
                style={screenType === 'warn' ? {background: '#FFF6EA 0% 0% no-repeat padding-box'} : {background: '#FBE8E8 0% 0% no-repeat padding-box'}}
                className={classes.toastWorn}>
                <img src={
                    screenType === 'warn' ?
                        Images.warning
                        :
                        Images.toastError
                } alt="success"/>
                <p>{text}</p>
            </div>
        </div>
    )
}