import {
  CREATE_FACTORING,
  EDIT_FACTORING,
  GET_FACTORINGS,
  GET_FACTORING_BY_ID,
  GET_CARRIER_FACTORING,
  ACTIVATE_FACTORING,
  INACTIVATE_FACTORING,
} from "./factoring.types";

                                           /** Factoring Requests, here is All requests for Factoring page */


/** Create, Edit Factoring */

export const createFactoring = ( body ) => {
  return {
    type: CREATE_FACTORING,
    payload: { body }
  }
}

export const editFactoring = ( id, body ) => {
  return {
    type: EDIT_FACTORING,
    payload: {id, body }
  }
}

/** End */

/** Get Factorings */

export const getFactorings = ( params ) => {
  return {
    type: GET_FACTORINGS,
    payload: { params }
  }
}

export const getFactoringById =( id )=>{
  return{
    type: GET_FACTORING_BY_ID,
    payload: { id }
  }
}

export const getCarrierFactoring =( id, params )=>{
  return{
    type: GET_CARRIER_FACTORING,
    payload: { id, params }
  }
}

/** End */

/** Activate or Inactivate Factoring */

export const activateFactoring = ( id ) =>{
  return{
    type: ACTIVATE_FACTORING,
    payload:{ id }
  }
}

export const inactivateFactoring = ( id ) =>{
  return{
    type: INACTIVATE_FACTORING,
    payload:{ id }
  }
}

/** End */