import tracker from "assets/images/tracker.jpg";
import phone from "assets/images/phone.svg";
import offices from "assets/images/offices.svg";
import officesGray from "assets/images/officesGray.svg";
import error from "assets/images/error.svg";
import success from "assets/images/icons/success.svg";
import toastError from "assets/images/icons/error.svg";
import aToZ from "assets/images/icons/aToZ.svg";
import zToA from "assets/images/icons/zToA.svg";
import yellowOffices from "assets/images/icons/yellowOffices.svg";
import branches from "assets/images/icons/branches.svg";
import branchesGray from "assets/images/icons/branchesGray.svg";
import branchGreen from "assets/images/icons/branchGreen.svg";
import branchFillIcon from "assets/images/icons/branchesFill.svg";
import branchesBold from "assets/images/icons/branchesBold.svg";
import humanResources from "assets/images/icons/humanResources.svg";
import humanResourcesGray from "assets/images/icons/humanResourcesGray.svg";
import humanResourcesYellow from "assets/images/icons/humanResourcesFill.svg";
import customer from "assets/images/icons/customer.svg";
import human from "assets/images/icons/human.svg";
import customerGray from "assets/images/icons/customerFill.svg";
import customerFill from "assets/images/icons/customerGray.svg";
import customerBold from "assets/images/icons/customerBold.svg";
import factoring from "assets/images/icons/factoring.svg";
import factoringOutline from "assets/images/icons/factoringOutline.svg";
import accessManagement from "assets/images/icons/accessManagement.svg";
import accessManagementGray from "assets/images/icons/accessManagementGray.svg";
import accessManagementUser from "assets/images/icons/accessManagementUser.svg";
import checked from "assets/images/icons/checked.svg";
import officeFillBold from "assets/images/icons/officeFillBold.svg";
import factoringGray from "assets/images/icons/factoringGray.svg";
import authorityFill from "assets/images/icons/authorityFill.svg";
import authorityBlue from "assets/images/icons/authorityBlue.svg";
import authorityBlueFill from "assets/images/icons/authorityBlueFill.svg";
import userProfile from "assets/images/icons/userProfile.svg";
import email from "assets/images/icons/email.svg";
import bluePhone from "assets/images/icons/phone.svg";
import location from "assets/images/icons/location.svg";
import edit from "assets/images/icons/edit.svg";
import signOut from "assets/images/icons/signOut.svg";
import authorityGray from "assets/images/icons/authorityGray.svg";
import agents from "assets/images/icons/agents.svg";
import carrierFill from "assets/images/icons/carrierFill.svg";
import carrierGray from "assets/images/icons/carrierGray.svg";
import agentsBlue from "assets/images/icons/agentsBlue.svg";
import agentsGray from "assets/images/icons/agentsGray.svg";
import agentsFill from "assets/images/icons/agentsFill.svg";
import factoringBold from "assets/images/icons/factoringBold.svg";
import carrier from "assets/images/icons/carrier.svg";
import carrierBold from "assets/images/icons/carrierBold.svg";
import card from "assets/images/icons/card.svg";
import upload from "assets/images/icons/upload.svg";
import download from "assets/images/icons/download.svg";
import arrowLeft from "assets/images/icons/arrowLeft.svg";
import arrowRight from "assets/images/icons/arrowRight.svg";
import up from "assets/images/icons/up.svg";
import down from "assets/images/icons/down.svg";
import loadFillBlue from "assets/images/icons/loadFillBlue.svg";
import loadFillBlueBig from "assets/images/icons/loadFillBlueBig.svg";
import loadOutline from "assets/images/icons/loadOutline.svg";
import systemFill from "assets/images/icons/systemFill.svg";
import loadGray from "assets/images/icons/loadGray.svg";
import systemGray from "assets/images/icons/systemGray.svg";
import checkmark from "assets/images/checkmark.svg";
import loadFill from "assets/images/loadFill.svg";
import logo from "assets/images/logo.png";
import logoBlack from "assets/images/icons/logoBlack.svg";
import googlePlay from "assets/images/icons/googlePlay.svg";
import playMarket from "assets/images/icons/playMarket.svg";
import logoWhite from "assets/images/P8olo2.jpg";
import approved from "assets/images/icons/approved.png";
import rejected from "assets/images/icons/rejected.png";
import userProfileNot from "assets/images/icons/userProfile.png";
import profilesFill from "assets/images/icons/profilesFill.svg";
import pickup from "assets/images/icons/pickup.svg";
import dropoff from "assets/images/icons/dropoff.svg";
import clear from "assets/images/icons/clear.svg";
import time from "assets/images/icons/time.svg";
import loadLocation from "assets/images/icons/loadLocation.svg";
import instruction from "assets/images/icons/instruction.svg";
import calendar from "assets/images/icons/calendar.svg";
import approveIcon from "assets/images/icons/approveIcon.svg";
import decrease from "assets/images/icons/decrease.svg";
import increase from "assets/images/icons/increase.svg";
import logoUpload from "assets/images/icons/Image.svg";
import creditFillBold from "assets/images/icons/creditFillBold.svg";
import creditFill from "assets/images/icons/creditFill.svg";
import profilesGray from "assets/images/icons/profilesGray.svg";
import remove from "assets/images/icons/remove.svg";
import accountingGray from "assets/images/icons/accountingGray.svg";
import accountingFill from "assets/images/icons/accountingFill.svg";
import processingFill from "assets/images/icons/processingFill.svg";
import processingGray from "assets/images/icons/processingGray.svg";
import billingFill from "assets/images/icons/billingFill.svg";
import billingGray from "assets/images/icons/billingGray.svg";
import greenCheckmark from "assets/images/greenCheckmark.svg";
import approvedNote from "assets/images/notifications/approved.svg";
import rejectedNote from "assets/images/notifications/rejected.svg";
import userNote from "assets/images/notifications/userProfile.svg";
import warning from "assets/images/warning.svg";
import info from "assets/images/Info.svg";
import receivables from "assets/images/icons/receivables.svg";
import receivablesFill from "assets/images/icons/receivablesFill.svg";
import customerPayment from "assets/images/icons/customerPayment.svg";
import customerPaymentFill from "assets/images/icons/customerPaymentFill.svg";
import paymentVerification from "assets/images/paymentVerification.svg";
import paymentVerificationFill from "assets/images/paymentVerificationFill.svg";
import payableFill from "assets/images/icons/payableFill.svg";
import payable from "assets/images/icons/payable.svg";
import carrierPayment from "assets/images/icons/carrierPayment.svg";
import carrierPaymentFill from "assets/images/icons/carrierPaymentFill.svg";
import name from "assets/images/name.svg";
import payments from "assets/images/icons/payments.svg";
import payroll from "assets/images/icons/payroll.svg";
import payrollActive from "assets/images/icons/payrollActive.svg";
import earliestToLatest from "assets/images/icons/earliestToLatest.svg";
import latestToEarliest from "assets/images/icons/latestToEarliest.svg";
import loadVerification from "assets/images/icons/loadVerification.svg";
import loadVerificationFill from "assets/images/icons/loadVerificationFill.svg";
import revisionNote from "assets/images/icons/revisionNote.svg";
import rmisActive from "assets/images/icons/rmisActive.svg";
import rmisInactive from "assets/images/icons/rmisInctive.svg";
import reports from "assets/images/icons/reports.svg";
import activeReports from "assets/images/icons/activeReports.svg";
import arIcon from "assets/images/icons/arIcon.svg";
import exportCalendar from "assets/images/icons/exportCalendar.svg";
import exportIcon from "assets/images/icons/exportIcon.svg";
import generateIcon from "assets/images/icons/generateIcon.svg";
import arActive from "assets/images/icons/arActive.svg";
import apPassive from "assets/images/icons/apPassive.svg";
import apActive from "assets/images/icons/apActive.svg";
import creditCheck from "assets/images/icons/creditCheck.svg";
import creditCheckActive from "assets/images/icons/creditCheckActive.svg";
import consignActive from "assets/images/icons/consignActive.svg";
import consign from "assets/images/icons/consign.svg";
import rejectedCredit from "assets/images/icons/rejectedCredit.svg";
import rejectedCreditActive from "assets/images/icons/rejectedCreditActive.svg";
import rejectedLoad from "assets/images/icons/rejectedLoad.svg";
import searchIcon from "assets/images/icons/searchIcon.svg";

import quickbooks from "assets/images/icons/quickbooks.svg";
import consignementPassive from "assets/images/icons/consignementPassive.svg";
import consignementActive from "assets/images/icons/consignementActive.svg";
import onClaimPassive from "assets/images/icons/onClaimPassive.svg";
import onClaimActive from "assets/images/icons/onClaimActive.svg";
import onBondPassive from "assets/images/icons/onBondPassive.svg";
import onBondActive from "assets/images/icons/onBondActive.svg";

import settings from "assets/images/icons/settings.svg";


import templateActive from "assets/images/icons/templateActive.svg";
import templatePassive from "assets/images/icons/templatePassive.svg";
import templaeIcon from "assets/images/icons/templaeIcon.svg";
import removeRed from "assets/images/icons/removeRed.svg";
import downWhite from "assets/images/icons/downWhite.svg";

import truckBoard from "assets/images/icons/truckBoard.svg";
import truckBoardFill from "assets/images/icons/truckBoardFill.svg";
import loads from "assets/images/icons/loads.svg";
import checkGreen from "assets/images/icons/checkGreen.svg";
import pluseIcon from "assets/images/icons/pluseIcon.svg";
import favorite from "assets/images/icons/favorite.svg";
import notFavorite from "assets/images/icons/notFavorite.svg";
import star from "assets/images/icons/star.svg";
import searchIconWhite from "assets/images/icons/searchIconWhite.svg";
import pencil from "assets/images/icons/pencil.svg";
import downBlue from "assets/images/icons/downBlue.svg";
import upWhite from "assets/images/icons/upWhite.svg";
import activeLoad from "assets/images/icons/activeLoad.svg";
import claimGray from "assets/images/icons/claimGray.svg";
import claimFill from "assets/images/icons/claimFill.svg";
import directoryActive from "assets/images/icons/directoryActive.svg";
import directory from "assets/images/icons/directory.svg";
// Landing Page Images.
import LogoSvg from "assets/images/landing/polo-logo.svg";

import collaboration from "assets/images/landing/collaboration.png";
import compliance from "assets/images/landing/compliance.png";
import endingVisual from "assets/images/landing/ending-visual.png";
import execution from "assets/images/landing/execution.png";
import heroVisual from "assets/images/landing/hero-visual-web.svg";
import planning from "assets/images/landing/planning.png";
import screenVisual from "assets/images/landing/screen-visual.svg";
import yourTrusted from "assets/images/landing/your-trusted.svg";
import Facebook from "assets/images/landing/Facebook.svg";
import Instagram from "assets/images/landing/Instagram.svg";
import Twiter from "assets/images/landing/Twiter.svg";
import Flag from "assets/images/landing/flag.svg";
import BenefitVisul from "assets/images/landing/benefit-visual.svg";
import Transportation from "assets/images/landing/transportation.svg";
import FacebookWhite from "assets/images/landing/Facebook-White.svg";
import InstagramWhite from "assets/images/landing/Instagram-White.svg";
import TwiterWhite from "assets/images/landing/Twiter-White.svg";
import Linkedin from "assets/images/landing/linkedin.svg";
import LinkedinWhite from "assets/images/landing/linkedin-white.svg";
import SendDateIcon from "assets/images/landing/send-data.svg";
import BurgerMenu from "assets/images/landing/burger-menu.svg";
import CloseIcon from "assets/images/landing/close-icon.svg";

// Sing In Images.
import SignLeft from "assets/images/sign-visual.png";
import PhoneOrange from "assets/images/phone-orange.svg";
import show from "assets/images/icons/show.svg";
import nonFactoring from "assets/images/icons/nonFactoring.svg";
import dropdownArrow from "assets/images/icons/dropdownArrow.svg";
import trash from "assets/images/icons/trash.svg";
import more from "assets/images/icons/more.svg";


import manageNotes from "assets/images/icons/manageNotes.svg";
import addWhiteIcon from "assets/images/icons/addWhiteIcon.svg";

import noteCreator from "assets/images/icons/noteCreator.svg";
import noteCreatedTime from "assets/images/icons/noteCreatedTime.svg";
import noteCreatedDate from "assets/images/icons/noteCreatedDate.svg";
import notes from "assets/images/icons/notes.svg";

export const Images = {
  directory,
  directoryActive,
  noteCreator,
  noteCreatedTime,
  noteCreatedDate,
  notes,
  addWhiteIcon,
  manageNotes,
  more,
  googlePlay,
  playMarket,
  logoBlack,
  claimGray,
  claimFill,
  trash,
  upWhite,
  downBlue,
  pencil,
  searchIconWhite,
  star,
  favorite,
  notFavorite,
  pluseIcon,
  checkGreen,
  loads,
  activeLoad,
  truckBoardFill,
  truckBoard,
  downWhite,
  removeRed,
  templaeIcon,
  templateActive,
  templatePassive,
  settings,
  consignementPassive,
  consignementActive,
  onClaimPassive,
  onClaimActive,
  onBondPassive,
  onBondActive,
  searchIcon,
  rejectedLoad,
  rejectedCredit,
  rejectedCreditActive,
  consignActive,
  consign,
  creditCheck,
  creditCheckActive,
  quickbooks,
  dropdownArrow,
  nonFactoring,
  show,
  rmisInactive,
  rmisActive,
  apActive,
  apPassive,
  arActive,
  generateIcon,
  exportCalendar,
  exportIcon,
  arIcon,
  activeReports,
  reports,
  logoWhite,
  card,
  tracker: tracker,
  phone: phone,
  offices: offices,
  officesGray: officesGray,
  error: error,
  aToZ: aToZ,
  zToA: zToA,
  earliestToLatest,
  latestToEarliest,
  yellowOffices: yellowOffices,
  branches: branches,
  branchesGray: branchesGray,
  branchGreen: branchGreen,
  branchFill: branchFillIcon,
  branchesBold: branchesBold,
  humanResources: humanResources,
  humanResourcesGray: humanResourcesGray,
  humanResourcesYellow: humanResourcesYellow,
  human: human,
  customer: customer,
  customerFill: customerFill,
  customerGray: customerGray,
  customerBold: customerBold,
  factoring: factoring,
  factoringOutline: factoringOutline,
  accessManagement: accessManagement,
  accessManagementGray: accessManagementGray,
  accessManagementUser: accessManagementUser,
  checked: checked,
  officeFillBold: officeFillBold,
  factoringGray: factoringGray,
  authorityFill: authorityFill,
  authorityBlue: authorityBlue,
  authorityBlueFill: authorityBlueFill,
  userProfile: userProfile,
  email: email,
  bluePhone: bluePhone,
  location: location,
  edit: edit,
  signOut: signOut,
  authorityGray: authorityGray,
  agents: agents,
  carrierFill: carrierFill,
  carrierGray: carrierGray,
  agentsBlue: agentsBlue,
  agentsFill: agentsFill,
  factoringBold: factoringBold,
  carrier: carrier,
  carrierBold: carrierBold,
  upload: upload,
  download: download,
  arrowRight: arrowRight,
  arrowLeft: arrowLeft,
  up: up,
  down: down,
  success: success,
  toastError: toastError,
  agentsGray: agentsGray,
  checkmark: checkmark,
  loadFill: loadFill,
  logo: logo,
  loadFillBlue: loadFillBlue,
  loadFillBlueBig: loadFillBlueBig,
  loadOutline: loadOutline,
  systemFill: systemFill,
  loadGray: loadGray,
  systemGray: systemGray,
  userProfileNot: userProfileNot,
  rejected: rejected,
  approved: approved,
  profilesFill: profilesFill,
  dropoff: dropoff,
  pickup: pickup,
  clear: clear,
  time: time,
  loadLocation: loadLocation,
  instruction: instruction,
  calendar: calendar,
  approveIcon: approveIcon,
  decrease: decrease,
  increase: increase,
  logoUpload: logoUpload,
  creditFillBold: creditFillBold,
  creditFill: creditFill,
  profilesGray: profilesGray,
  remove: remove,
  accountingGray: accountingGray,
  accountingFill: accountingFill,
  processingFill: processingFill,
  processingGray: processingGray,
  billingFill: billingFill,
  billingGray: billingGray,
  greenCheckmark: greenCheckmark,
  approvedNote: approvedNote,
  rejectedNote: rejectedNote,
  userNote: userNote,
  warning: warning,
  info: info,
  receivables: receivables,
  receivablesFill: receivablesFill,
  customerPayment: customerPayment,
  customerPaymentFill: customerPaymentFill,
  paymentVerification: paymentVerification,
  paymentVerificationFill: paymentVerificationFill,
  payableFill: payableFill,
  payable: payable,
  carrierPaymentFill: carrierPaymentFill,
  carrierPayment: carrierPayment,
  name: name,
  payments,
  payroll,
  payrollActive,
  loadVerification,
  loadVerificationFill,
  revisionNote,

  //   Landing Images.
  LogoSvg: LogoSvg,
  collaboration: collaboration,
  compliance: compliance,
  endingVisual: endingVisual,
  execution: execution,
  heroVisual: heroVisual,
  planning: planning,
  screenVisual: screenVisual,
  yourTrusted: yourTrusted,
  Facebook: Facebook,
  Instagram: Instagram,
  Twiter: Twiter,
  Linkedin,
  LinkedinWhite,
  Flag: Flag,
  BenefitVisul: BenefitVisul,
  Transportation: Transportation,
  FacebookWhite: FacebookWhite,
  InstagramWhite: InstagramWhite,
  TwiterWhite: TwiterWhite,
  SendDateIcon: SendDateIcon,
  BurgerMenu: BurgerMenu,
  CloseIcon: CloseIcon,

  // Sign In
  SignLeft: SignLeft,
  PhoneOrange: PhoneOrange,
};
