import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AddModalButton,
  Circle,
  RevisionModal,
  SimpleModal,
  ValidationInput,
} from 'components';
import {
  Colors,
  FindLoad,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText, userType,
} from 'utils';
import { httpRequestsOnSuccessActions, loadActions, LoadServices } from 'store';
import { InfoSection } from 'components';
import { createLoadStyle } from './styles';
import { NOT_BILLABLE } from '../../../billing/billingDetails/core/constants';
import { NOT_PAYABLE, NOTBILLABLE, SEND_TO_BILLING } from '../../../../pages/accounting/constants';

export const Processing = ({ info }) => {
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();
  const dispatch = useDispatch();
  const payableLoader = FindLoad('SEND_TO_PAYABLE');
  const [loader, setLoader] = useState('');
  const [openRevision, setOpenRevision] = useState(false);

  const handlePay = () => {
    dispatch(loadActions.sendToPayable(info.id));
  };

  const sendToBilling = async () => {
    setLoader('bill');
    try {
      await LoadServices.LoadToBilling(info.id);
      dispatch(httpRequestsOnSuccessActions.appendSuccess(SEND_TO_BILLING));
      dispatch(loadActions.getLoadById(info.id, 'noLoad'));
      setLoader('');
    } catch (e) {
      setLoader('');
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetween}>
        <div className={globalStyles.centerItem}>
          <Circle
            number={3}
            back={Colors.ThemeAqua} />
          <p className={globalText.title}>Processing</p>
        </div>
        <div className={globalStyles.centerItem}>
          {info?.bill?.status === NOT_BILLABLE && info?.payable?.status === NOT_PAYABLE &&
            RolePermission([
                PermissionsList.ALL_ACCESS?.code,
                PermissionsList.SEND_LOAD_TO_REVISION?.code,
                PermissionsList.SEND_TO_PROCESSING?.code,
              ],
            ) &&
            <button
              onClick={() => setOpenRevision(true)}
              className={classes.sendToRevision}
            >
              Send to Revision
            </button>
          }
        </div>
      </div>
      <div className={classes.processingInputsWrapper}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }} className={classes.margins}>
            <ValidationInput
              style={globalStyles.simpleInput}
              className={info?.bill?.notes ? classes.inputTextFieldBlue : classes.inputTextField}
              variant={'outlined'}
              name={'billingCorrection'}
              label={'Billing Corrections'}
              type={'text'}
              disabled={true}
              value={info?.bill?.notes ? info?.bill?.notes : 'No Corrections Yet'}
            />
                <>
                  {info?.bill?.status !== NOTBILLABLE ?
                    <InfoSection
                      styles={{marginTop: '28px'}}
                      text={'This Load has been sent to billing.'}
                    />
                    :
                    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_BILLING?.code]) &&
                    <AddModalButton
                      loading={loader === 'bill'}
                      styles={{marginTop: '28px', width: '238px'}}
                      handleClick={sendToBilling}
                      text={'Send to Billing'}
                    />
                  }
                </>
          </div>

          <div style={{ width: '50%' }}>
            <ValidationInput
              className={info?.payable?.notes ? classes.inputTextFieldBlue : classes.inputTextField}
              variant={'outlined'}
              name={'payablesCorrection'}
              label={'Payables Corrections'}
              type={'text'}
              disabled={true}
              value={info?.payable?.notes ? info?.payable?.notes : 'No Corrections Yet'}
            />


              <>
                <div className={classes.statusAndButton}>
                  {info?.payable?.status !== NOT_PAYABLE ?
                    <InfoSection text={'This Load has been sent to payables.'}/>
                    :
                    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_PAYABLE?.code]) &&
                    <AddModalButton
                      loading={!!payableLoader?.length}
                      styles={{width: '238px'}}
                      handleClick={handlePay}
                      text={'Send to Payables'}
                    />
                  }
                </div>
              </>
          </div>
        </div>
      </div>

      <SimpleModal
        handleOpenClose={() => setOpenRevision(false)}
        openDefault={openRevision}
        content={
          <RevisionModal
            handleClose={() => setOpenRevision(false)}
            info={info}
          />
        }
      />
    </div>
  );
};