import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {Button, TextField} from "@material-ui/core";
import {loadList} from "../../pages/loads/constants";
import {format, RolePermission, SavePage, useGlobalStyles} from "utils";
import {AddButton} from "../buttons";
import {tableStyle} from "./styles";
import {filtersFragment} from "../filters/styles";

import {LoadStatus} from "../inputs/autocompleteSelectInput";
import {LOAD_REVISION, LOAD_VERIFICATION} from "../../pages/accounting/constants";


export const FilterForTable = ({
                                   startEnd,
                                   typeForFilter,
                                   addButton,
                                   createPermisson,
                                   handleOpenModal,
                                   type,
                                   dateFrom,
                                   searchBtnStyles
                               }) => {
    const classes = tableStyle();
    const globalStyle = useGlobalStyles();
    const filterClasses = filtersFragment()
    const history = useHistory()
    const info = history?.location?.state
    const [inputs, setInputs] = useState({})

    const handleChangeDate = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),);
    }

    const handleSearch = () => {
        const pushInfo = {
            ...info
        }
        pushInfo.skip = 0
        pushInfo.page = 1
        if (startEnd) {
            inputs.startDate ? pushInfo.startDate = moment(inputs?.startDate).format("YYYY-MM-DDT00:00:01") : delete pushInfo.startDate
            inputs.endDate ? pushInfo.endDate = moment(inputs.endDate).format("YYYY-MM-DDT23:59:59") : delete pushInfo.endDate
        } else {
            inputs.startDate ? pushInfo.from = moment(inputs?.startDate).format("YYYY-MM-DDT00:00:01") : delete pushInfo.from
            inputs.endDate ? pushInfo.to = moment(inputs.endDate).format("YYYY-MM-DDT23:59:59") : delete pushInfo.to
        }
        SavePage(history, info, {...pushInfo})
    }

    useEffect(() => {
        if (info) {
            const startEndDate = {}
            if (startEnd) {
                info.startDate ? startEndDate.startDate = moment(info.startDate).format("YYYY-MM-DDT00:00:01") : ''
                info.endDate ? startEndDate.endDate = moment(info.endDate).format("YYYY-MM-DDT23:59:59") : ''
            } else {
                info.from ? startEndDate.startDate = moment(info.from).format("YYYY-MM-DDT00:00:01") : ''
                info.to ? startEndDate.endDate = moment(info.to).format("YYYY-MM-DDT23:59:59") : ''
            }
            setInputs(startEndDate)
        }
    }, [info]);


    const handleSelect = (items, v) => {
        // handleSelectStatus && handleSelectStatus()
        const revision = items?.length && items.find((i) => i.id === LOAD_REVISION)
        const verification = items?.length && items.find((i) => i.id === LOAD_VERIFICATION)
        let statusList = [];

        const newObj = {
            ...info
        }

        items?.length && items.forEach(function (status) {
            // if (status.id !== LOAD_REVISION && status.id !== LOAD_VERIFICATION) {
                statusList.push(status.id);
            // }
        });


        if (revision) {
            newObj.isRevision = true
        } else {
            delete newObj.isRevision
        }

        if (verification) {
            newObj.isVerification = true
        } else {
            delete newObj.isVerification
        }

        newObj.statuses = statusList
        SavePage(history, info, {...newObj})
    }

    return (
        <div>
            <div className={globalStyle.filtersWrapper}>
                <div className={filterClasses.filterWrapper}>
                    <TextField
                        className={filterClasses.dateFiled}
                        type={'date'}
                        name={'startDate'}
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                max:
                                    inputs?.endDate ?
                                        moment(inputs?.endDate).format('YYYY-MM-DD')
                                        :
                                      ''
                                        // moment().format('YYYY-MM-DD')
                            }
                        }}
                        value={inputs?.startDate ? moment(inputs?.startDate).format('YYYY-MM-DD') : ''}
                        onChange={handleChangeDate}
                    />
                    <TextField
                        className={filterClasses.dateFiled}
                        type={'date'}
                        name={'endDate'}
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                min: inputs?.startDate ?
                                    format(new Date(inputs?.startDate))
                                    : ''
                            }
                        }}
                        defaultValue={dateFrom ? moment('').format('YYYY-MM-DD') : ''}
                        value={dateFrom ?
                            !inputs?.endDate ? moment().format('YYYY-MM-DD') : moment(inputs?.endDate).format('YYYY-MM-DD')
                            :
                            inputs?.endDate ?
                                moment(inputs?.endDate).format('YYYY-MM-DD') : ''
                        }

                        onChange={handleChangeDate}
                    />
                    <Button
                        onClick={handleSearch}
                        className={filterClasses.search}
                        style={{...searchBtnStyles}}
                    >
                        Search
                    </Button>

                    {typeForFilter &&


                        <div className={classes.selectInput}>
                            <LoadStatus
                                handleChange={handleSelect}
                                permissionsList={loadList}
                                label={"Select Status"}
                                info={info}
                            />
                        </div>
                    }
                </div>

                <div className={classes.alignCenter}>
                    {addButton &&
                        RolePermission([createPermisson], type) &&
                        <AddButton
                            handleClick={handleOpenModal}
                            text={addButton}
                        />
                    }
                </div>
            </div>
        </div>
    )
}