import React from "react";
import { Button } from "@material-ui/core";
import { Colors } from "utils";
import { MinLoader } from "components";

export const SignIn = ({
  text,
  width,
  handleClick,
  loader,
  margin,
  background,
}) => {
  return (
    <Button
      onClick={handleClick}
      style={{
        width: `${width}`,
        background: `${Colors.ThemeYellow}`,
        height: "48px",
        borderRadius: "8px",
        color: `${Colors.TextWhite}`,
        fontSize: "16px",
        lineHeight: "22px",
        fontWeight: "600",
        textTransform: "capitalize",
        margin: margin,
      }}
    >
      {loader === true ? <MinLoader color={Colors.TextWhite} /> : text}
    </Button>
  );
};
