import {
  ACTIVATE_AGENT,
  ADD_BRACKET, CHANGE_DEFAULT_BRACKET,
  CREATE_AGENT,
  DELETE_BRACKET,
  EDIT_AGENT,
  EDIT_AGENT_BRACKET,
  FILTER_AGENTS_BY_ALPHABETICAL,
  FILTER_AGENTS_BY_DEFAULT,
  GET_AGENT_BY_BRANCH,
  GET_AGENT_BY_ID,
  GET_AGENT_BY_OFFICE,
  GET_AGENTS, GET_BRACKET,
  GET_BY,
  GET_DEFAULT_BRACKET,
  GET_MANAGER_BY_BRANCH,
  GET_MANAGER_BY_OFFICE,
  INACTIVATE_AGENT,
  REMOVE_AGENT_BY_BRANCH, RESEND_AGENT_INVITE,
  SEARCH_AGENT_BY_TYPE,
} from "./agent.types";

/** Create, Edit Agent */

export const createAgent = (body) => {
  return {
    type: CREATE_AGENT,
    payload: { body }
  }
}

export const editAgent = (body, id, type) => {
  return {
    type: EDIT_AGENT,
    payload: { body,id, type }
  }
}

export const resendAgent = (id) => {
  return{
    type: RESEND_AGENT_INVITE,
    payload: { id }
  }
}

/** End */

/** Get Agents */

export const getAgents = ( params ) => {
  return {
    type: GET_AGENTS,
    payload: { params }
  }
}

export const getAgentById = (agent) => {
  return {
    type: GET_AGENT_BY_ID,
    payload: { agent }
  }
}

/** End */

/** Activate or Inactivate Office */

export const activateAgent = ( id, branchId ) =>{
  return{
    type: ACTIVATE_AGENT,
    payload:{ id, branchId }
  }
}

export const inactivateAgent = ( id, branchId ) =>{
  return{
    type: INACTIVATE_AGENT,
    payload:{ id, branchId }
  }
}

/** End */

/** Offices Filters */

export const searchAgent =( value, type, listStatus )=>{
  return{
    type:SEARCH_AGENT_BY_TYPE,
    payload:{value, type, listStatus }
  }
}

export const filterByAlphabetical =( name, listStatus )=>{
  return{
    type:FILTER_AGENTS_BY_ALPHABETICAL,
    payload:{name, listStatus }
  }
}

export const filterByDefault =( name, listStatus )=>{
  return{
    type:FILTER_AGENTS_BY_DEFAULT,
    payload:{name, listStatus }
  }
}

/** End */

/** Get Agents and Managers */

export const getAgentByOffice = (officeId)=>{
  return{
    type: GET_AGENT_BY_OFFICE,
    payload: { officeId }
  }
}

export const getManagersByOffice = (officeId)=>{
  return{
    type: GET_MANAGER_BY_OFFICE,
    payload: { officeId }
  }
}

export const getAgentByBranch = (branchId)=>{
  return{
    type: GET_AGENT_BY_BRANCH,
    payload: { branchId }
  }
}

export const removeAgentByBranch = ( )=>{
  return{
    type: REMOVE_AGENT_BY_BRANCH,
  }
}

export const getManagersByBranch = ( data )=>{
  return{
    type: GET_MANAGER_BY_BRANCH,
    payload: { data }
  }
}

/** End */

/** Create Edit, Payroll Brackets */

export const addBracket =(id ,data)=>{
  return{
    type: ADD_BRACKET,
    payload: {id, data}
  }
}

export const editBracket =(id ,data, bracketId)=>{
  return{
    type: EDIT_AGENT_BRACKET,
    payload: {id, data, bracketId}
  }
}

export const deleteBracket =(id ,bracketId)=>{
  return{
    type: DELETE_BRACKET,
    payload: {id, bracketId}
  }
}

export const getBracket =(id)=>{
  return{
    type: GET_BRACKET,
    payload: {id}
  }
}

export const getDefaultBracket =(id)=>{
  return{
    type: GET_DEFAULT_BRACKET,
    payload: {id}
  }
}

export const changeBracket =(id, idDefault)=>{
  return{
    type: CHANGE_DEFAULT_BRACKET,
    payload: {id, idDefault}
  }
}

/** End */


export const getBy = (id) =>{
  return{
    type:GET_BY,
    payload: { id }
  }
}
