import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, TableWrapper} from "components";
import {customerActions} from "store";
import { customersBody, customersHead, ACTION_TYPE, customersCsv } from './constants';
import { PermissionsList, RolePermission } from 'utils';

export const Customers = ({}) => {
    const {customersList} = useSelector((state) => ({
        customersList: state.customers.customersList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    const getList = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.status = info?.status ? info?.status : 'ACTIVE'
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        delete filteredInfo.name
        return filteredInfo
    }

    useEffect(() => {
        dispatch(customerActions.getCustomers( getList() ))
    }, [ info]);

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/customer/${id}`,
            state: {customerParams: {...info}}
        })
    }

    return (
        <TableWrapper
            firstButton={"Active"}
            secondButton={"Inactive"}
            addButton={"Add Customer"}
            buttonsTab={true}
            buttonsTabAddButton={true}
            href={'/createCustomer'}
            link={true}
            createPermisson={PermissionsList.CREATE_UPDATE_CUSTOMER}
            csvExport={RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_CUSTOMER_CSV?.code]) ? customersCsv : ''}
        >
            <FullTable
                head={customersHead}
                body={customersBody}
                loadingType={ACTION_TYPE}
                list={customersList?.customers}
                listCount={customersList?.count}
                handleClick={pushPageDetails}
                noText={'Customers'}
            />
        </TableWrapper>
    );
}
