import {userInfo, userType} from "../constants";

export const officeManager = userType && userType === 'AGENT' ? userInfo?.managedOffices?.length && true : true
export const branchManager = userType && userType === 'AGENT' ? userInfo?.managedBranches?.length && true : true
const permissions = localStorage.getItem('permissions') && JSON.parse(localStorage.getItem('permissions'));

export const RolePermission = ( neededPermissions, type ) => {
    const userPermission = [ ]

    permissions && permissions.map((i) => userPermission.push({code: i}))

    if(userType === 'AGENT'){
        if(type === 'officeOrBranchManager'){
            if(officeManager || branchManager) {
                return true
            }
        }
        else if(type === 'officeManager' && officeManager){
            return true
        }
        else if(type === 'branchManager' && branchManager){
            return true
        }
        else if(type === 'agentView'){
            return true
        }
        else if(type === 'bmAgent'){
            return true
        }
        else {
            return false
        }
    }else {


        if(neededPermissions?.length) {
            const uniqSet = new Set()
            neededPermissions?.forEach((permissions) => uniqSet.add(permissions))

            for (let i of userPermission) {
                if (i?.code === 0) {
                    return true
                } else if (uniqSet.has(i?.code)) {
                    return true
                }
            }
        }else {
            return false
        }
    }
}



