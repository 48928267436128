import React from 'react';
import { Images } from 'utils';
import { DateRow, PriceRow, TextRow, LoadIconAndTitle, TypeRow } from 'components';
import { customerStatuses } from '../customers/constants';

export const loadsHead = [
  { name: 'displayId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false, width: '120px' },
  { name: 'createdDate', title: 'Date', icon: 'date', smallSize: 6, type: 'date', width: '90px' },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'carrier', searchKey: 'CARRIER', title: 'Carrier', custom: false },
  { name: 'agent', searchKey: 'AGENT', nameRow: 'name', title: 'Agent', custom: false },
  { name: '', title: 'Pick Up', custom: false },
  { name: '', title: 'Drop Off', custom: false },
  { name: 'customerRate', title: 'Customer Rate', smallSize: 4, custom: false },
  { name: 'carrierRate', title: 'Carrier Rate', smallSize: 4, custom: false },
  { name: 'profit', title: 'Profit', custom: false },
  { name: 'status', title: 'Status', custom: false, width: '110px' },
];

export const loadsBody = [
  { rowText: (item) => <LoadIconAndTitle icon={Images.loadOutline} title={item?.displayId} item={item} /> },
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  {
    rowText: (item) =>
      <div>
        <TextRow name={item?.customer?.name} textWidth={8} />
        <div className={'table-second-item'}>
          <TextRow name={item?.customerReference ? item?.customerReference : ''} textWidth={8}
                   color={'#9C9EA9'} />
        </div>
      </div>,
  },
  {
    rowText: (item) =>
      <div>
        <TextRow name={item?.carrier?.name ? item?.carrier?.name : 'N/A'} textWidth={8} />
        <div className={'table-second-item'}>
          <TextRow name={item?.carrierReference ? item?.carrierReference : ''} textWidth={8}
                   color={'#9C9EA9'} />
        </div>
      </div>,
  },
  {
    rowText: (item) =>
      <div>
        <TextRow name={item?.agent?.name} textWidth={8} />
        <div className={'table-second-item'}>
          <TextRow name={item?.branch?.name} textWidth={8} color={'#9C9EA9'} />
        </div>
      </div>,
  },

  {
    rowText: (item) => {
      const pick = item?.stops?.find((i) => i.type === 'PICKUP');

      return pick ? <div>
          <TextRow
            name={`${pick?.address?.city ? pick?.address?.city : ''} ${pick?.address?.state ? pick?.address?.state : ''}`}
            textWidth={8} />
          <div className={'table-second-item'}>
            <DateRow date={pick?.date} color={'#9C9EA9'} />
          </div>
        </div>
        :
        '...';
    },
  },
  {
    rowText: (item) => {
      const pick = item?.stops?.find((i) => i.type === 'DROPOFF');

      return pick ? <div>
          <TextRow
            name={`${pick?.address?.city ? pick?.address?.city : ''} ${pick?.address?.state ? pick?.address?.state : ''}`}
            textWidth={8} />
          <div className={'table-second-item'}>
            <DateRow date={pick?.date} color={'#9C9EA9'} />
          </div>
        </div>
        :
        '...';
    },
  },

  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
  { rowText: (item) => <PriceRow info={item?.carrierRate} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate - item?.carrierRate - item?.agentCharges} />,
  },
  {
    rowText: (item) =>
      <p
        className={item?.status === 'PROCESSING' ? 'processing-icon-color' :
          item?.status === 'PENDING' ? 'pending-icon-color' :
            item?.status === 'INTRANSIT' ? 'inTransit-icon-color' :
              item?.status === 'REJECTED' ? 'rejected-icon-color' :
                item?.status === 'COMPLETED' ? 'completed-icon-color' :

                  ''}
      >
        {
          item?.status === 'INTRANSIT' ? 'In Transit' :
            item?.status === 'CREDITCHECK' ? 'Credit Check' :
              item?.status === 'CONSIGNMENT_REQUEST' ? 'Consignment Request' :

                <TypeRow text={item?.status} />
        }
      </p>,
  },
];

export const ACTION_TYPE = 'GET_LOADS_BY_STATUS';

export const loadList = [
  { name: `Pending`, id: 'PENDING' },
  { name: `In Transit`, id: 'INTRANSIT' },
  { name: `Rejected`, id: 'REJECTED' },
  { name: `Processing`, id: 'PROCESSING' },
  { name: 'Cancelled', id: 'CANCELLED' },
  { name: `Completed`, id: 'COMPLETED' },
  { name: `Credit Check`, id: 'CREDITCHECK' },
  { name: `Verification`, id: 'LOAD_VERIFICATION' },
  { name: `Revision`, id: 'LOAD_REVISION' },
];

export const renderStatus = (status) => {
  switch (status) {
    case 'INTRANSIT':
      return 'In Transit';
    case 'PENDING':
      return 'Pending';
    case 'REJECTED':
      return 'Rejected';
    case 'PROCESSING':
      return 'Processing';
    case 'CANCELLED':
      return 'Cancelled';
    case 'COMPLETED':
      return 'Completed';
    case 'CREDITCHECK':
      return 'Credit Check';
    case 'LOAD_VERIFICATION':
      return 'Verification';
    case 'LOAD_REVISION':
      return 'Revision';

    default:
      return status;
  }
};


export const renderIcon = (color) => {
  const fillColor = color ? color : '#438AFE';

  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Group_2">
        <g id="Group_3">
          <g id="Group_4">
            <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.14583 0.639659C0.860089 0.639659 0.625 0.880262 0.625 1.17271V18.8273C0.625 19.1197 0.860089 19.3603 1.14583 19.3603H13.6875C13.9732 19.3603 14.2083 19.1197 14.2083 18.8273V13.1983C14.2083 13.0217 14.3482 12.8785 14.5208 12.8785C14.6934 12.8785 14.8333 13.0217 14.8333 13.1983V18.8273C14.8333 19.473 14.3184 20 13.6875 20H1.14583C0.514911 20 0 19.473 0 18.8273V1.17271C0 0.526988 0.514911 0 1.14583 0H10.6042C10.7768 0 10.9167 0.143192 10.9167 0.319829V4.34968H14.5208C14.6934 4.34968 14.8333 4.49287 14.8333 4.66951V8.37953C14.8333 8.55617 14.6934 8.69936 14.5208 8.69936C14.3482 8.69936 14.2083 8.55617 14.2083 8.37953V4.98934H10.6042C10.4316 4.98934 10.2917 4.84615 10.2917 4.66951V0.639659H1.14583Z"
                  fill={fillColor} />
            <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3917 0.0846426C10.5187 -0.0350609 10.7164 -0.0268023 10.8333 0.103089L14.75 4.45277C14.8669 4.58266 14.8589 4.785 14.732 4.9047C14.605 5.0244 14.4073 5.01614 14.2904 4.88625L10.3737 0.536573C10.2568 0.406682 10.2648 0.204346 10.3917 0.0846426Z"
                  fill={fillColor} />
          </g>
          <path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 3.09131C1.625 2.91468 1.76491 2.77148 1.9375 2.77148H6.9375C7.11009 2.77148 7.25 2.91468 7.25 3.09131C7.25 3.26795 7.11009 3.41114 6.9375 3.41114H1.9375C1.76491 3.41114 1.625 3.26795 1.625 3.09131Z"
                fill={fillColor} />
          <path id="Vector (Stroke)_4" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 5.05323C1.625 4.87659 1.76491 4.7334 1.9375 4.7334H8.9375C9.11009 4.7334 9.25 4.87659 9.25 5.05323C9.25 5.22986 9.11009 5.37306 8.9375 5.37306H1.9375C1.76491 5.37306 1.625 5.22986 1.625 5.05323Z"
                fill={fillColor} />
          <path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 7.05713C1.625 6.8805 1.76491 6.7373 1.9375 6.7373H12.8958C13.0684 6.7373 13.2083 6.8805 13.2083 7.05713C13.2083 7.23377 13.0684 7.37696 12.8958 7.37696H1.9375C1.76491 7.37696 1.625 7.23377 1.625 7.05713Z"
                fill={fillColor} />
          <path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 16.9087C1.625 16.7321 1.76491 16.5889 1.9375 16.5889H6.9375C7.11009 16.5889 7.25 16.7321 7.25 16.9087C7.25 17.0853 7.11009 17.2285 6.9375 17.2285H1.9375C1.76491 17.2285 1.625 17.0853 1.625 16.9087Z"
                fill={fillColor} />
          <g id="Group_5">
            <path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.625 9.01905C1.625 8.84241 1.76491 8.69922 1.9375 8.69922H4.3125C4.48509 8.69922 4.625 8.84241 4.625 9.01905C4.625 9.19569 4.48509 9.33888 4.3125 9.33888H1.9375C1.76491 9.33888 1.625 9.19569 1.625 9.01905Z"
                  fill={fillColor} />
            <path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.66602 9.01905C5.66602 8.84241 5.80593 8.69922 5.97852 8.69922H11.6452C11.8178 8.69922 11.9577 8.84241 11.9577 9.01905C11.9577 9.19569 11.8178 9.33888 11.6452 9.33888H5.97852C5.80593 9.33888 5.66602 9.19569 5.66602 9.01905Z"
                  fill={fillColor} />
          </g>
        </g>
        <g id="Group_6">
          <path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 10.981C1.625 10.8043 1.76491 10.6611 1.9375 10.6611H8.85417C9.02676 10.6611 9.16667 10.8043 9.16667 10.981C9.16667 11.1576 9.02676 11.3008 8.85417 11.3008H1.9375C1.76491 11.3008 1.625 11.1576 1.625 10.981Z"
                fill={fillColor} />
        </g>
        <g id="Group_7">
          <path id="Vector (Stroke)_10" fill-rule="evenodd" clip-rule="evenodd"
                d="M1.625 14.9468C1.625 14.7701 1.76491 14.627 1.9375 14.627H4.3125C4.48509 14.627 4.625 14.7701 4.625 14.9468C4.625 15.1234 4.48509 15.2666 4.3125 15.2666H1.9375C1.76491 15.2666 1.625 15.1234 1.625 14.9468Z"
                fill={fillColor} />
          <path id="Vector (Stroke)_11" fill-rule="evenodd" clip-rule="evenodd"
                d="M5.66602 14.9468C5.66602 14.7701 5.80593 14.627 5.97852 14.627H7.35352C7.52611 14.627 7.66602 14.7701 7.66602 14.9468C7.66602 15.1234 7.52611 15.2666 7.35352 15.2666H5.97852C5.80593 15.2666 5.66602 15.1234 5.66602 14.9468Z"
                fill={fillColor} />
        </g>
        <g id="Group_8">
          <g id="Group_9">
            <path id="Vector (Stroke)_12" fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.33398 12.9429C5.33398 12.7662 5.4739 12.623 5.64648 12.623H8.27148C8.44407 12.623 8.58398 12.7662 8.58398 12.9429C8.58398 13.1195 8.44407 13.2627 8.27148 13.2627H5.64648C5.4739 13.2627 5.33398 13.1195 5.33398 12.9429Z"
                  fill={fillColor} />
          </g>
          <g id="Group_10">
            <path id="Vector (Stroke)_13" fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.625 12.9429C1.625 12.7662 1.76491 12.623 1.9375 12.623H4.5625C4.73509 12.623 4.875 12.7662 4.875 12.9429C4.875 13.1195 4.73509 13.2627 4.5625 13.2627H1.9375C1.76491 13.2627 1.625 13.1195 1.625 12.9429Z"
                  fill={fillColor} />
          </g>
        </g>
      </g>
      <g id="Group_11">
        <path id="Vector (Stroke)_14" fill-rule="evenodd" clip-rule="evenodd"
              d="M16.3069 6.14297C16.4291 6.02489 16.6206 6.02682 16.7405 6.14735L19.1155 8.53541C19.175 8.5952 19.2086 8.67682 19.209 8.76214C19.2094 8.84745 19.1764 8.92938 19.1175 8.98971L18.3675 9.7573C18.2454 9.8822 18.0476 9.8822 17.9255 9.7573L15.5505 7.32659C15.4908 7.26549 15.4578 7.18227 15.459 7.09587C15.4602 7.00947 15.4955 6.92725 15.5569 6.86792L16.3069 6.14297ZM16.2198 7.10699L18.1465 9.07884L18.4526 8.76556L16.5172 6.81955L16.2198 7.10699Z"
              fill={fillColor} />
        <path id="Vector (Stroke)_15" fill-rule="evenodd" clip-rule="evenodd"
              d="M18.9595 6.29542C18.4618 5.75208 17.6239 5.74124 17.0751 6.30283L17.0745 6.30345L9.75505 13.7532L11.6464 15.689L18.9671 8.23795C18.9693 8.23574 18.9715 8.23356 18.9738 8.23141C19.5047 7.72203 19.5152 6.86442 18.9665 6.30283C18.9654 6.30168 18.9643 6.30051 18.9632 6.29934C18.9619 6.29805 18.9607 6.29674 18.9595 6.29542ZM19.412 5.85416C18.6595 5.03682 17.417 5.04873 16.6336 5.85015C16.6334 5.85027 16.6333 5.8504 16.6332 5.85052L9.09214 13.5258C9.03327 13.5857 9.00012 13.6672 9 13.7521C8.99989 13.8371 9.03282 13.9186 9.09153 13.9787L11.4249 16.3668C11.5467 16.4915 11.7441 16.4917 11.8662 16.3674L19.4047 8.69468C20.2024 7.92556 20.192 6.65621 19.412 5.85416Z"
              fill={fillColor} />
        <path id="Vector (Stroke)_16" fill-rule="evenodd" clip-rule="evenodd"
              d="M16.4091 8.87883C16.5312 9.00373 16.5312 9.20624 16.4091 9.33114L11.4925 14.3631C11.3704 14.488 11.1726 14.488 11.0505 14.3631C10.9285 14.2382 10.9285 14.0357 11.0505 13.9108L15.9672 8.87883C16.0892 8.75393 16.2871 8.75393 16.4091 8.87883Z"
              fill={fillColor} />
        <path id="Vector (Stroke)_17" fill-rule="evenodd" clip-rule="evenodd"
              d="M9.38637 13.4418C9.55406 13.4835 9.65694 13.6565 9.61615 13.8281L8.98749 16.4732L11.572 15.8298C11.7397 15.7881 11.9087 15.8934 11.9495 16.065C11.9903 16.2366 11.8874 16.4096 11.7197 16.4513L8.63637 17.2189C8.5304 17.2453 8.41865 17.2133 8.34154 17.1343C8.26442 17.0554 8.23308 16.941 8.25886 16.8326L9.00886 13.6769C9.04965 13.5053 9.21867 13.4 9.38637 13.4418Z"
              fill={fillColor} />
      </g>
    </g>
  </svg>;

};


export const loadStatuses = [
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'RECEIVABLE', name: 'Receivable' },
  { id: 'PMT_VERIFICATION', name: 'Payment Verification' },
  { id: 'PAYABLE', name: 'Payable' },
  { id: 'BILLABLE', name: 'Billable' },
  { id: 'LOAD_VERIFICATION', name: 'Load Verification' },
  { id: 'CONSIGNMENT', name: 'Consignment' },
  { id: 'CREDIT_CHECK', name: 'Credit Check' },
  { id: 'ON_CLAIM', name: 'On Claim' },
  { id: 'ON_BOND', name: 'On Bond' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'PREVIOUS', name: 'Previous' },
];

export const loadCsv = {
  type: 'load',
  statusList: [
    { name: `Pending`, id: 'PENDING' },
    { name: `In Transit`, id: 'INTRANSIT' },
    { name: `Rejected`, id: 'REJECTED' },
    { name: `Processing`, id: 'PROCESSING' },
    { name: 'Cancelled', id: 'CANCELLED' },
    { name: `Completed`, id: 'COMPLETED' },
    { name: `Credit Check`, id: 'CREDITCHECK' },
    { name: `Verification`, id: 'LOAD_VERIFICATION' },
    { name: `Revision`, id: 'LOAD_REVISION' },
  ],
  statusEnum: 'department',
  dateFilter: true,
  requiredStatus: false,
  requiredDateRange: true,
};