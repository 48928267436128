import {TableCell, TableRow} from "@material-ui/core";
import {screensStyle} from "./styles";

export const TableNoInfo = ({ text }) =>{
    const classes = screensStyle()
    return(
        <TableRow  className={classes.noInfoWrapper} >
            <TableCell className={classes.noInfoText} >
                {text}
            </TableCell>
        </TableRow>
    )
}