import { getPayrollLoads, getPayrolls, getPayrollSummary } from './payroll.action';
export {payrollReducer} from './payroll.reducer';
export {watchPayroll} from './payroll.saga';

export const payrollActions = {

    /** Get Payrolls */
    getPayrolls,
    getPayrollSummary,
    getPayrollLoads
    /** End */

}
