import {useSelector} from "react-redux";
import {FindLoad, Images, RenderEquipmentType} from "utils";
import {DateRow, HtmlTooltip, Loader, NoYet, PhoneRow, TextRow} from "components";
import React from "react";

export const Detail = ({item}) => {
    const {carrierById} = useSelector((state) => ({
            carrierById: state.carriers.carrierById,
        })
    )
    const loader = FindLoad('GET_CARRIER_BY_ID')

    if (loader?.length) return <Loader/>

    return (
        <div>
            <p className='truck-detail-title'>Carrier Information</p>
            <div className='truck-information-wrapper'>
                <div className='information-title-value'>
                    <p className='light-text'>First name</p>
                    <p className='black-text'>{carrierById?.firstName}</p>
                </div>
                <div className='information-title-value'>
                    <p className='light-text'>Last name</p>
                    <p className='black-text'>{carrierById?.lastName}</p>
                </div>
                <div className='information-title-value'>
                    <p className='light-text'>Company name</p>
                    <p className='black-text'>{carrierById?.companyName}</p>
                </div>
            </div>
            <div className='truck-information-wrapper' style={{marginBottom: '32px'}}>
                <div className='information-title-value'>
                    <p className='light-text'>Phone number</p>
                    <p className='black-text'>
                        <PhoneRow phone={carrierById?.phone}/>
                    </p>
                </div>
                <div className='information-title-value'>
                    <p className='light-text'>Email</p>
                    <p className='black-text'>
                        {carrierById?.email}
                    </p>
                </div>
                <div className='information-title-value'>
                    <p className='light-text'/>
                    <p className='black-text'/>
                </div>
            </div>
            <div className='equipment-details-wrapper'>
                <p className='truck-detail-title'>Equipment Details</p>
                <div className='truck-information-wrapper'>
                    <div className='information-title-value-big'>
                        <p className='light-text'>Available Date</p>
                        <p className='black-text'>
                            {item?.availableDate ?
                                <DateRow date={item?.availableDate}/>
                                :
                                'Not Set'
                            }
                        </p>
                    </div>
                    <div className='information-title-value-big'>
                        <p className='light-text'>Service Type</p>
                        <p className='black-text'>{
                            item?.serviceType === 'SOLO' ? "Solo" :
                                item?.serviceType === 'TEAM' ? "Team" : ''
                        }</p>
                    </div>
                </div>
                <div className='truck-information-wrapper' style={{marginBottom: '32px'}}>
                    <div className='information-title-value-big'>
                        <p className='light-text'>Equipment type</p>
                        <p className='black-text'>{RenderEquipmentType(item?.equipmentType)}</p>
                    </div>
                    <div className='information-title-value-big'>
                        <p className='light-text'>Origin City</p>
                        <p className='black-text'>{item?.originCity ? item?.originCity : 'Not Set'} </p>
                    </div>
                </div>
            </div>
            <div>
                <p className='truck-detail-title'>Desired destinations</p>
                <div className='destination-card-body'>
                    {
                        item?.returnToHomebase ?
                            <div  className='destination-card-wrapper'>
                                <img src={Images.location} alt="icon"/>
                                <p>{`${item?.homebaseCity ? `${item?.homebaseCity} ,` : ''}  ${item?.homebaseState}`}</p>
                            </div>
                            :
                            item?.regions?.length ?
                                item?.regions?.map((i, j) => (
                                    <div key={j} className='destination-card-wrapper'>
                                        <img src={Images.location} alt="icon"/>
                                        <p>{i}</p>
                                    </div>
                                ))
                                :
                                <NoYet text={'No destinations yet'}/>
                    }
                </div>
            </div>
        </div>
    )
}
