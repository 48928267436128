import React from "react";
import {cardStyle} from "./style";

export const ItemPriceTitle = ({name, value}) =>{
    const classes = cardStyle()
    return(
        <div className={classes.items}>
            <p>{name}</p>
            <span>{value}</span>
        </div>
    )
}