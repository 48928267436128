import React, {useState} from "react";
import { CreateCustomerInputs } from "./core";
import { CreateWrapper } from "components";
import { Images, useGlobalStyles } from "utils";

export const CreateCustomerTable = ({ }) => {
  const globalStyle = useGlobalStyles();
  const [name, setName] = useState('')
  return (
    <div>
      <CreateWrapper
        head={
              <div className={globalStyle.createOfficeTableHead}>
                <img src={Images.customerBold} alt={"customer"} />
                <p>{ name ? name : 'CUSTOMER NAME' }</p>
              </div>
        }
        body={
          <CreateCustomerInputs
            handleChangeName ={ setName }
          />
        }
        parentLink={'/customers'}
        parent={'Customers'}
        child={'Add Customer'}
      />
    </div>
  );
};
