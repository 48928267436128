import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import {Detail} from "./detail";
import {TruckLastLoads} from "./truckLastLoads";
import {Alerts, ReviewModal} from "fragments";
import {carrierActions} from "store";

export const TruckModal = ({ item }) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(  0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        dispatch(carrierActions.getCarrierById(item?.carrierId))
    }, [dispatch])

    return (
        <div className='truck-modal-wrapper'>
            <p className='truck-modal-title'>Equipment Details</p>
            <div className='truck-modal-tabs-wrapper'>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Detail" {...a11yProps(0)} />
                    <Tab label="Last Loads" {...a11yProps(1)} />
                    <Tab label="Rating and Reviews" {...a11yProps(2)} />
                    <Tab label="Alerts" {...a11yProps(3)} />
                </Tabs>
            </div>
            {value === 0 && <Detail item={item}/>}
            {value === 1 && <TruckLastLoads id={item?.carrierId}/>}
            {value === 2 && <ReviewModal flexDirection={'column-reverse'} carrierId={item?.carrierId}/>}
            {value === 3 && <div className='alerts-in-modal'><Alerts carrierId={item?.carrierId}/></div>}
        </div>
    )
}