import {
    GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS,
    GET_CUSTOMER_PAYMENT_LOAD_SUCCESS,
    GET_CUSTOMER_PAYMENT_SUCCESS,
} from "./customerPayment.types";

const initialState = {
    customerPayments: [],
    customerPaymentsReserve: [],
    customerPaymentById: null,
    customerPaymentLoad: null
};

export const customerPaymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case  GET_CUSTOMER_PAYMENT_SUCCESS:
            return {
                ...state,
                customerPayments: action.payload,
            }

        case  GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS:
            return {
                ...state,
                customerPaymentById: action.payload,
            }

        case  GET_CUSTOMER_PAYMENT_LOAD_SUCCESS:
            return {
                ...state,
                customerPaymentLoad: action.payload,
            }


        default:
            return state;
    }
};
