import React, {useEffect, useState} from "react";
import { CloseButton, CreateChancel, PriceRow, SlicedText, ValidationInput } from 'components';
import {ErrorText, globalModals, useGlobalStyles, useGlobalTextStyles} from "utils";
import {payableFragmentStyles} from "./styles";
import {Checkbox} from "@material-ui/core";
import {authService} from "../../../store/carriers/carrier.service";
import moment from "moment";
import {useDispatch} from "react-redux";
import {loadActions} from "store";

export const PayableCreatePayment = ({handleClose, selectedLoads, removeAllSelects}) => {
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const globalWrappers = useGlobalStyles();
    const classes = payableFragmentStyles()
    const dispatch = useDispatch()
    const [inputs, setInputs] = useState({});
    const [loader, setLoader] = useState(false)
    const [loadList, setLoadList] = useState([...selectedLoads])
    const [selectedIds, setSelectedIds] = useState([])
    const [error, setError] = useState('')
    const [finalAmount, setFinalAmount] = useState(0)

    const handleChange = e => {
        setInputs(
            prevState => ({...prevState, [e.target.name]: e.target.value}),
        );
        error === 'referenceNumber' && setError('')
    }

    useEffect(() => {
        if (loadList.length) {
            const arr = []
            loadList.map((i) => arr.push(i.id))
            setSelectedIds(arr)
        } else {
            setSelectedIds([])
        }
    }, [loadList])

    const handleCreate = async () => {
        if (selectedIds && selectedIds.length && inputs.referenceNumber) {
            setLoader(true)
            const date = {
                "payee": '',
                "loadIds": [
                    ...selectedIds
                ],
                "referenceNumber": inputs.referenceNumber
            }
            if (selectedLoads && selectedLoads.length) {
                date.payee = selectedLoads[0].payable.payeeId
            }
            try {
                await authService.createCarrierPaymentService(date)
                const params = {department: 'PAYABLE'}
                dispatch(loadActions.getLoadsByStatus(params))
                removeAllSelects()
                setLoadList([])
                setLoader(false)
                handleClose()
            } catch (e) {
                setLoader(false)
            }
        } else {
            setError(
                !selectedIds.length ? 'selectedIds' :
                    !inputs.referenceNumber ? 'referenceNumber' : ''
            )
        }
    }

    const handleRemoveLoad = (e, it) => {
        if (e.target.checked === true) {
            setLoadList([...loadList, it])
            error === 'selectedIds' && setError('')
        } else {
            const newArr = []
            loadList.filter((k, key) => (k.id !== it.id && newArr.push(k)))
            setLoadList(newArr)
        }
    }

    useEffect(() => {
        if (loadList) {
            let amount = 0
            loadList.forEach((load) => (
                // amount +=  LoadService.getPayableBalance(load?.payable)
                amount +=  load?.payable?.finalAmount
            ))
            setFinalAmount(amount)
        }
    }, [loadList])

    return (
        <div>
            <div style={{width: '680px'}} className={globalModalsClasses.smallModalWrapper}>
                <div className={globalModalsClasses.smallModalClose}>
                    <CloseButton handleCLic={handleClose}/>
                </div>
                <div className={globalModalsClasses.modalWrapperContent}>
                    <p className={globalText.modalTitle}>Create Payment</p>
                    <p className={globalText.modalText}>To create Payment please, fill out the below fields.</p>
                    <div className={classes.correctionList}>
                        <div>
                            <span>Payee:&nbsp;</span>
                            <span
                                style={{fontWeight: 'bold'}}>{selectedLoads?.length && selectedLoads[0].payable.payeeName}</span>
                        </div>
                        <div>
                            <span style={{whiteSpace:'nowrap'}}>Final Amount:&nbsp;</span>
                            <p style={{fontWeight: 'bold', width:'100%'}} className={classes.finalAmount}>
                                   <PriceRow info={finalAmount}/>
                            </p>
                        </div>
                        <div>
                            <span>Payment Method:&nbsp;</span>
                            <span
                                style={{fontWeight: 'bold'}}>{selectedLoads?.length && selectedLoads[0].payable.pmtMethod.toLowerCase()}</span>
                        </div>
                    </div>
                    <p className={classes.payablesText}>Payables</p>
                    <div>
                        <div className={classes.tableHead}>
                            <p style={{width: '105px', marginLeft: '27px'}}>Load ID</p>
                            <p style={{width: '180px'}}>Carrier Name</p>
                            <p style={{width: '124px'}}>Due Date</p>
                            <p style={{width: '135'}}>Final Amount</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}} className={classes.tableBodyWrapper}>
                            {selectedLoads && selectedLoads.length && selectedLoads.map((load, i) => (
                                <div style={{display: 'flex', alignItems: 'center'}} key={i}>
                                    <Checkbox defaultChecked={true} onClick={(e) => handleRemoveLoad(e, load)}/>
                                    <div className={classes.tableBody}>
                                        <p style={{width: '108px'}}><SlicedText type={'id'} size={10} data={load?.displayId ? load?.displayId : load?.id}/></p>
                                        <p style={{width: '178px'}}>
                                            <SlicedText type={'name'} size={10} data={load?.carrier?.name}/>
                                        </p>
                                        <p style={{width: '124px'}}>
                                            {moment.utc(load?.payable?.dueDate).format('MM/DD/YYYY')}
                                        </p>
                                        <p style={{width: '116px'}}>
                                            <PriceRow info={load?.payable?.finalAmount}/>
                                            {/*${load?.payable?.finalAmount}*/}
                                        </p>
                                        {/*<p style={{width: '116px'}}>${LoadService.getPayableBalance(load?.payable)}</p>*/}
                                    </div>
                                </div>
                            ))}

                        </div>

                        <ValidationInput
                            onChange={handleChange}
                            className={classes.referenceNumber}
                            value={inputs.referenceNumber}
                            typeError={error === 'referenceNumber' && ErrorText.field}
                            variant={"outlined"}
                            name={"referenceNumber"}
                            label={"Reference Number*"}
                            type={"text"}
                        />

                    </div>
                    <div className={classes.doneButtonWrapper}>
                        <CreateChancel
                            loader={loader}
                            classes={globalWrappers.buttonsStyle}
                            create={"Create Payment"}
                            chancel={"Cancel"}
                            onCreate={handleCreate}
                            onClose={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}