import React from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Circle, FullTable} from "components";
import {branchesBody, branchesHead} from "../../../../pages/branches/constants";
import {Colors, useGlobalStyles, useGlobalText} from "utils";

export const Branches = () => {
    const {officeBranchesList} = useSelector((state) => ({
        officeBranchesList: state.offices.officeBranchesList,
    }));
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();
    const history = useHistory()

    return (
        <div >
            <div className={globalInputs.centerItem}>
                <Circle number={1} back={Colors.ThemeOrange}/>
                <p className={globalStyle.title}>Branches</p>
            </div>
            <div style={{width:'auto'}} className='info-table-no-line'>
                <FullTable
                    height={'medium'}
                    head={branchesHead}
                    body={branchesBody}
                    loadingType={'GET_OFFICE_BRANCHES'}
                    list={officeBranchesList?.branches}
                    listCount={officeBranchesList?.count}
                    handleClick={(id) => history.push(`/branch/${id}`)}
                    noText={'Branches'}
                    margin={true}
                />
            </div>
        </div>
    )
}