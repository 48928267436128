import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const customerPaymentInfo = makeStyles(() => ({
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
        marginBottom: '8px',
    },
    checkbox: {
        marginBottom: '16px',
        '& .MuiSvgIcon-root': {
            color: Colors.ThemeGreen,
        }
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px'
    },
    imageAndTitle:{
        display: 'flex',
        alignItems: 'center',
        '& p':{
            marginLeft:'8px',
            fontWeight: '600',
            fontSize: '16px',
            color: Colors.TextPrimary,
        }
    },
    addCustomerButton: {
        width: '160px',
        height: '36px',
        borderRadius: '4px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundWhite,
    },
    simpleSelect: {
        marginRight: '16px',
        width: '400px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${Colors.ThemeBorder}!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px'
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-10px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color: Colors.ThemeBorder,
            marginTop: '-3px'
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBorder
        },
    },
    customersWrapper: {
        // marginLeft: '48px',
        padding:'0 2px',
        marginTop: '24px',
        marginBottom: '8px',
        maxHeight:'567px',
        height:'auto',
        overflow:'auto',
        width:'100%',
    },
    accordion: {
        width: '100%',
        padding: '0 16px !important',
        background: '#E6ECF3 0% 0% no-repeat padding-box',
        borderRadius: '4px 4px 0px 0px',
        boxShadow: '0px 0px 6px #8A8A8A29',
        '& .MuiSvgIcon-root': {
            color: Colors.ThemeBlue,
            fontSize: '32px',
        },
        '& .MuiIconButton-root': {
            padding: 0,
            marginRight: '0px',
        }
    },
    bodyWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 16px 16px 16px',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '13px 32px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
    },
    loadTableHead: {
        "& > td:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
        },
        "& > td:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
        },
        '& .MuiTableHead-root': {
            width: '100%',
        },
        '& .MuiTableCell-head': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextPrimary,
        },
        '& .MuiTableCell-root': {
            padding: '13px 32px',
            border: 'none',
        },
    },
    tableBody: {
        '& .MuiTableRow-root': {
            background: '#E6ECF380 0% 0% no-repeat padding-box',
            borderRadius: '4px 0px 0px 4px',
            height:'48px',
        },
        '& .MuiTableCell-root':{
            padding: '0 32px',
            fontSize:'16px',
            color:Colors.TextPrimary,
            border:'none',
        },
    },
    loadItem:{
        display:'flex',
        alignItems:'center',
        '& img':{
            marginRight:'8px',
        }
    },
    tableWrapper: {
        marginTop: '30px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
        '& .MuiTable-root':{
            borderCollapse: 'separate',
            borderSpacing: '0 4px',
        },
    },
    tableLastRow:{
        display:'flex',
        alignItems:'center',
    },
    customerPaymentDock:{
        paddingLeft:'50px',
        marginTop:'24px',
    }

}))