import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const useNoteContStyles = makeStyles((theme) => ({
    notWrapper: {
        marginRight: '24px'
    },
    backdrop: {
        width: '100%',
        position: 'fixed',
        height: '100vh',
        top: 0,
        left: 0,
        '@media (min-width: 320px)': {
            background: 'white',
        },
        '@media (min-width: 768px)': {
            background: 'white',
        },
        '@media (min-width: 1240px)': {
            background: 'transparent',
        },
        '@media (min-width: 1920px)': {
            background: 'transparent',
        },
    },
    closeNotificationButton: {
        right: '24px',
        position: 'absolute',
        top: '15px',
        background: 'none',
        border: 'none',
        outline: 'none',
        '@media (min-width: 320px)': {
            display: 'block',
        },
        '@media (min-width: 768px)': {
            display: 'block',
        },
        '@media (min-width: 1240px)': {
            display: 'none',
        },
        '@media (min-width: 1920px)': {
            display: 'none',
        },
    },

    notificationsCont: {
        overflow: 'hidden',
        padding:'16px',
        '@media (min-width: 320px)': {
            zIndex: 9,
            top: 0,
            height: '100vh',
            width: '100%',
            opacity: '1',
            position: 'fixed',
            borderRadius: 0,
        },
        '@media (min-width: 768px)': {
            zIndex: 9,
            top: 0,
            height: '100vh',
            width: '100%',
            opacity: '1',
            position: 'fixed',
            borderRadius: 0,
        },
        '@media (min-width: 1240px)': {
            width: '500px',
            maxHeight: '500px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 6px #8A8A8A29',
            borderRadius: '8px',
            opacity: 1,
            position: 'absolute',
            top: 76,
            right: 160,
        },
        '@media (min-width: 1920px)': {
            width: '500px',
            maxHeight: '700px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 6px #8A8A8A29',
            borderRadius: '8px',
            opacity: 1,
            position: 'absolute',
            top: 76,
            right: 180,
        },
    },

    markAll:{
        display:'flex',
        justifyContent:'flex-end',
        marginBottom:'18px',
    },

    headerText: {
        color: Colors.ThemeBlue,
        fontSize: '32px',
        margin: 0,
        fontWeight: 'bold',
        marginBottom:'24px',
        lineHeight:'36px',
    },
    headerText1: {
        border: 'none',
        background: 'none',
        outline: 'none',
        color: Colors.ThemeBlue,
        fontSize: '16px',
        lineHeight:'36px',
        margin: 0,
        cursor: 'pointer',
    },
    notificationItemCont: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '0 0 20px 0',

        '@media (min-width: 320px)': {
            maxHeight: '100vh',
            height: '100vh',
        },
        '@media (min-width: 768px)': {
            maxHeight: '100vh',
            height: '100vh',
        },
        '@media (min-width: 1240px)': {
            maxHeight: '100%',
            height: '400px',
        },
        '@media (min-width: 1920px)': {
            maxHeight: '100vh',
            height: '600px',
        },
    },
    noNotesCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            width: '500px',
            maxHeight: '700px',
            height: '600px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 12px #0052E01F',
            borderRadius: '16px',
            opacity: 1,
            position: 'absolute',
            top: 54,
            left: -443,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '72px',
            width: '100%',
            height: '100vh',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 12px #0052E01F',
            opacity: 1,
            position: 'inherit',
            borderRadius: 0,
        },
    },
    noNotes: {
        margin: 0,
        fontSize: 30,
        color: '#387DFF80',
        fontWeight: 'bold',
        marginTop: '40px',
    },

    infinitiScrollStyle: {
        margin: '0 auto',
        display: 'flex',
        padding: '20px',
    },
    noNotificationBody: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        maxHeight: '600px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (min-width: 320px)': {
            maxHeight: '100%',
        },
        '@media (min-width: 768px)': {
            maxHeight: '100%',
        },
        '@media (min-width: 1240px)': {
            maxHeight: '600px',
        },
        '@media (min-width: 1920px)': {
            maxHeight: '600px',
        },
    },

    NotificationItem: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
    },

    hoverStyle:{
        '& .hoverBack:hover': {
            background: '#438AFE1A 0% 0% no-repeat padding-box',
            borderRadius: '8px',
        },
        '& div':{
            background:'transparent'
        }
    },
    noteWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    notImg: {
        width: '40px',
        height: '40px',
    },
    iconCont: {
        marginRight: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    messageCont: {
        // width: '70%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    review: {
        border: 'none',
        background: 'none',
        height: '19px',
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.ThemeBlue,
        padding: 0,
        marginTop: '4px',
    },
    message: {
        color: Colors.TextPrimary,
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '24px',
    },
    delIconCont: {
        display: 'flex',
        flexDirection: ' column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        width:'auto',
        minWidth:'105px',
        '& button': {
            background: 'none',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            padding: 0,
        },
    },
    unreadCircle: {
        width: '14px',
        height: '14px',
        background: '#438AFE 0% 0% no-repeat padding-box !important',
        borderRadius: '40px',
        marginBottom: '3px',
        marginRight: '20px',
        cursor: 'pointer',
        border: '2px solid #438AFE1A'
    },
    date: {
        fontSize: '14px',
        color: '#252E4880',
        margin: '0',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },

    linkButton: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        color: '#387DFF',
    },
    notificationBadge: {
        '& .MuiBadge-badge': {
            background: Colors.ThemeGreen,
            marginTop: '8px',
            marginRight: '8px',
            width: '9px',
            height: '9px',
            border: '0.5px solid white',
        },
    },
    notificationBadgeNone: {
        '& .MuiBadge-badge': {
            background:'none',
            marginTop: '8px',
            marginRight: '8px',
            width: '9px',
            height: '9px',
            border: 'none',
        },
    },

    modal: {
        '& .MuiBackdrop-root': {
            background: 'transparent !important',
        },
    },
}));
