import { GET_PAYROLL_LOADS_SUCCESS, GET_PAYROLL_SUMMARY_SUCCESS, GET_PAYROLLS_SUCCESS } from './payroll.types';

const initialState = {
  payrolls: [],
  payrollSummary: [],
  payrollLoads: [],
};

export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {

    /** Get Payrolls */

    case GET_PAYROLLS_SUCCESS:
      return {
        ...state,
        payrolls: action.payload,
      };

    case GET_PAYROLL_SUMMARY_SUCCESS:
      return {
        ...state,
        payrollSummary: action.payload,
      };

    case GET_PAYROLL_LOADS_SUCCESS:
      return {
        ...state,
        payrollLoads: action.payload,
      };

    /** End */

    default:
      return state;
  }
};
