import React from "react";
import {CreateBranchInputs} from "../createBranch";
import {Colors, useGlobalStyles, useGlobalText} from "utils";
import {BranchManagement} from "./core/branchManagement";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {Circle} from "components";
import { useSelector } from 'react-redux';

export const BranchInfoTable = ({ handleChangeName }) => {
    const {branchById} = useSelector((state) => ({
          branchById: state.branches.branchById,
      })
    )
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();
    const info = branchById

    return (
        <div className='accordion-style'>
            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={1} back={Colors.ThemeGreen}/>}
                        <p className={globalStyle.title}>Branch Information</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateBranchInputs
                        handleChangeName={handleChangeName}
                        info={info}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={2} back={Colors.ThemeGreen}/>}
                        <p className={globalStyle.title}>Branch Management</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='info-table-no-line'>
                        <BranchManagement forId={info} branchById={branchById}/>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}