
/**Create Edit roles */
export const CREATE_ROLE = 'CREATE_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';

/**Get roles */
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';

/**Get roles by id */
export const GET_ROLE_BY_ID = 'GET_ROLE_BY_ID';
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS';

/**Search role*/
export const SEARCH_ROLE = 'SEARCH_ROLE';

/**Open role*/
export const REMOVE_ROLE ='REMOVE_ROLE'

/**Delete role*/
export const DELETE_ROLE ='DELETE_ROLE'

/** Add permission */
export const ADD_ROLE_PERMISSION ='ADD_ROLE_PERMISSION'
export const DELETE_ROLE_PERMISSION ='DELETE_ROLE_PERMISSION'