import React, {useCallback, useRef, useState} from 'react'
import {HtmlTooltip, SlicedText} from "../messages";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {NumericFormat} from 'react-number-format'

export const IconAndTitle = ({icon, title, textWidth, imgClass, width}) => {
    const ref = useRef()
    const currentWidth = width ? width : ref?.current?.offsetWidth ? ref?.current?.offsetWidth : 200

    return (
        <div ref={ref} style={{width: '100%'}} className='info-and-image'>
            <img className={imgClass ? `${imgClass}` : ''} src={icon} alt="Icon"/>
            <div style={{
                fontWeight: 'bold',
                width: '100%'
            }}>{RenderText(currentWidth, title, textWidth)}</div>
        </div>
    )
}

export const LoadIconAndTitle = ({icon, title, item}) => {

    return (
        <div style={{width: '100%'}} className='info-and-image'>
            <img className={
                item?.status === "PROCESSING" ? 'processing-icon-color' :
                    item?.status === "PENDING" ? 'pending-icon-color' :
                        item?.status === "INTRANSIT" ? 'inTransit-icon-color' :
                            item?.status === "REJECTED" ? 'rejected-icon-color' :
                              item?.status === "COMPLETED" ? 'completed-icon-color' :
                                ''
            } src={icon} alt="Icon"/>
            <div style={{fontWeight: 'bold'}}>{title}</div>
        </div>
    )
}

export const IdRow = ({id}) => {
    return (
        <SlicedText type={'id'} size={10} data={id}/>
    )
}

export const LinkRow = ({id, loadId, anotherLink, target}) => {
    const history = useHistory()

      if(target) {
         return <a
            style={ {}}
            className="hyper-link"
            target={'_blank'}
            href={anotherLink}
          >
              <p>{id ? id : '...'}</p>
          </a>;
      } else {
          return <button
            style={!id ? { textDecoration: 'none' } : {}}
            className="hyper-link"
            onClick={() => loadId ? history.push(anotherLink ? anotherLink : `/load/${loadId}`)
              :
              {}
            }
          >
              <p>{id ? id : '...'}</p>
          </button>;
      }


}

export const DateRow = ({ date, color }) => {
    return (
      <p style={color ? { color: color } : {}}>
          {date ? moment.utc(date).format('MM/DD/YYYY') : 'N/A'}
      </p>
    );
};

export const TextRow = ({ name, textWidth, color }) => {
    const [currentWidth, setCurrentWidth] = useState(null)

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto'

    const text = (name ? name : '...') + ''

    return (
        <div ref={curr} className='text-style'>{RenderText(width, text, textWidth, color)}</div>
    )
}

export const TextRowNotSet = ({ name, textWidth, color }) => {
    const [currentWidth, setCurrentWidth] = useState(null)

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto'

    const text = (name ? name : 'Not Set') + ''

    return (
      <div ref={curr} className='text-style'>{RenderText(width, text, textWidth, color)}</div>
    )
}



export const PriceRow = ({info, textWidth}) => {
    const [currentWidth, setCurrentWidth] = useState(null)

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250
    const price = (info ? info.toFixed(2) : 0) + ''

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11))
        const slicedText = '' + price?.slice(0, count)
        return <div ref={curr} className='text-style'>
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                        </p>
                    }
                    placement="top-start"
                >
                    <p style={{textOverflow: 'ellipsis',}}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} prefix={'$'}/>...
                    </p>
                </HtmlTooltip>
                :
                <p>{
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                }</p>
            }
        </div>
    } else {
        return '...'
    }
}

export const PhoneRow = ({phone}) => {
    if (phone) {
        const converted = '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6);
        return (
          <a onClick={(e) => {
              e.stopPropagation();
          }} href={`tel:+1${phone}`} className='call-to-action'>
              {converted}
          </a>
        )
    } else {
        return 'Not Set'
    }
}

export const TypeRow = ({text}) => {
    if (text !== undefined) {
        return (
            text?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                return g1.toUpperCase() + g2.toLowerCase();
            })
        )
    }else{
        return '...'
    }
}

const RenderText = (width, text, textWidth,color) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11))
        const slicedText = '' + text?.slice(0, count)
        return <div className='text-style' style={color ? {color:color}: {}}>
            {text?.length > count ?
                <HtmlTooltip
                    title={<p>{text}</p>}
                    placement="top-start"
                >
                    <p style={{textOverflow: 'ellipsis',}}>
                        {`${slicedText}...`}
                    </p>
                </HtmlTooltip>
                :
                <p>{text}</p>
            }
        </div>
    } else {
        return '...'
    }
}

export const PriceConvertor = ({price} ) => {
    const convertedPrice = price ? price.toFixed(2) : 0
    return <NumericFormat value={convertedPrice ? convertedPrice : '0'} displayType={'text'} thousandSeparator={true} prefix={'$'} />

}