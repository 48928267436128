import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    CheckboxesTags,
    DeleteButton, DeleteElement,
    EditSaveButtons, Line,
    Management,
    NoYet, SimpleModal
} from "components";
import {FindLoad, FindSuccessItem, Images, useGlobalStyles} from "utils";
import React, {useEffect, useState} from "react";
import {adminInfoFragments} from "./styles";
import {adminActions, httpRequestsOnSuccessActions} from "store";

export const Access = ({list, rolesList}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const [disables, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)
    const [role, setRole] = useState('')
    const classes = adminInfoFragments()
    const globalClass = useGlobalStyles()
    const [item, setItem] = useState('')
    const success = FindSuccessItem('ADD_ADMIN_ROLE')
    const successRemove = FindSuccessItem('REMOVE_ADMIN_ROLE')
    const removeLoader = FindLoad('REMOVE_ADMIN_ROLE')

    const deleteRole = () => {
        const id = params.id
        dispatch(adminActions.removeAdminRole(id, role.id))
    }

    const addAssess = (permission) => {
        if (permission.length) {
            const id = params.id
            const last = permission[permission.length - 1]
            dispatch(adminActions.addAdminRole(id, last.id))
        }
    }

    const handleOpen = (i) => {
        if (i) {
            setItem(i)
        }
    }

    const handleCreate = () => {
        setDisabled(!disables)
    }

    const handleOpenClose = (id) => {
        setOpen(!open)
        if (id) {
            setRole(id)
        }
    }

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ADD_ADMIN_ROLE'))
        }
        if (successRemove) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('REMOVE_ADMIN_ROLE'))
            setOpen(!open)
            setRole('id')
        }
    }, [success, successRemove]);

    const filteredList = rolesList.length && rolesList.filter(function (array_el) {
        return list.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    return (
        <div className={globalClass.flexAble}>
            <Line height={'390px'}/>

            <div className={classes.accessWrapper}>
                <Management
                    type={'access'}
                    styles={{margin: 0}}
                    head={
                        <div className={classes.managementHead}>
                            <div className={classes.half}>
                                <CheckboxesTags
                                    handleChange={addAssess}
                                    permissionsList={filteredList.length ? filteredList : []}
                                    label={"Select Role*"}
                                    placeholder={'Roles'}
                                />
                            </div>
                            <EditSaveButtons
                                butt={'done'}
                                type={'EDIT_ADMIN'}
                                handleChancel={() => setDisabled(true)}
                                handleSetEdit={() => setDisabled(false)}
                                handleSaveInfo={handleCreate}
                            />
                        </div>
                    }
                    body={
                        <div className={classes.accessBody}>
                            <div className={classes.halfItem}>
                                {list && list.length ?
                                    list.map((i, j) => (
                                        <div key={j}
                                             className={item && item.id && item.id === i.id ? globalClass.activeItem : globalClass.item}
                                             onClick={() => handleOpen(i)}
                                        >
                                            <p>{i.title}</p>
                                            <div>
                                                {!disables &&
                                                    <DeleteButton
                                                        toolTipTitle={"Remove Role"}
                                                        handleClick={() => handleOpenClose(i)}
                                                    />
                                                }
                                                <img className={classes.arrow}
                                                     src={item && item.id && item.id === i.id ? Images.arrowLeft : Images.arrowRight}
                                                     alt="arrow"/>
                                            </div>
                                        </div>
                                    )) :
                                    <NoYet position={'center'} text={'No Access Yet'}/>
                                }
                            </div>
                            <div className={classes.roleNamePer}>
                                {item ?
                                    <>
                                        <p className={classes.roleNamTitle}>{`${item && item.title && item.title} Permissions`}</p>
                                        <div className={classes.roleWrapper}>
                                            {item ? item.permissions && item.permissions.length ?
                                                    item.permissions.map((l, k) => (
                                                        <div key={k} className={classes.roleItem}>
                                                            <img src={Images.checked} alt="checked"/>
                                                            <p>{l.title}</p>
                                                        </div>
                                                    ))
                                                    : '' :
                                                <NoYet position={'center'} text={'No Permissions Yet'}/>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className={classes.select}>
                                        <NoYet position={'center'} text={'No Permissions Yet'}/>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                />
                <SimpleModal
                    handleOpenClose={handleOpenClose}
                    openDefault={open}
                    content={
                        <DeleteElement
                            loading={!!removeLoader.length}
                            text={'Delete Access?'}
                            handleClose={handleOpenClose}
                            handleDel={deleteRole}
                            info={role && role.title}
                        />
                    }
                />
            </div>
        </div>
    )
}