import React, {useState} from "react";
import {CreateFactoringInputs} from "./core";
import {CreateWrapper} from "components";
import {Images, useGlobalStyles} from "utils";

export const CreateFactoringTable = ({}) => {
    const globalStyle = useGlobalStyles();
    const [name, setName] = useState('')

    return (
        <div>
            <CreateWrapper
                head={
                    <div className={globalStyle.createOfficeTableHead}>
                        <img src={Images.factoringBold} alt={"factoring"}/>
                        <p>{name ? name : 'FACTORING COMPANY NAME'}</p>
                    </div>
                }
                body={
                    <CreateFactoringInputs
                        handleChangeName={setName}
                    />
                }
                parentLink={'/factorings'}
                parent={'Factoring Companies'}
                child={'Add Factoring Company'}
            />
        </div>
    );
};
