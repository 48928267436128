import {adminInfoFragments} from "./styles";
import React, {useState} from "react";
import {Colors, FindLoad, Images, useGlobalStyles, useGlobalText} from "utils";
import axios from "axios";
import {mcActions, httpRequestsOnLoadActions} from "store";
import {useDispatch} from "react-redux";
import {Line, MinLoader} from "components";
import Box from "@material-ui/core/Box";
import {LinearProgress} from "@material-ui/core";
import {FileUploader} from "react-drag-drop-files";

export const Logo = ({info}) => {
    const dispatch = useDispatch()
    const classes = adminInfoFragments()
    const globalText = useGlobalText()
    const global = useGlobalStyles()
    const [loader, setLoader] = useState(false)
    const [imageSrc, setImgSrc] = useState(info ? info.logo && info.logo.url : '')
    const [progress, setProgress] = React.useState(0);
    const loaderDel = FindLoad('REMOVE_LOGO')

    const handleChange = async (e) => {
        const image = e && URL.createObjectURL(e);
        setImgSrc(image);

        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        setLoader(true)
        let formData = new FormData();
        formData.append('files', e)
        axios.post(`/files/upload?includeThumbnail=true`, formData, {auth: true}).then((res) => {
            const data = {
                "name": info.name,
                "mcNumber": info.mcNumber,
                "phone": info.phone,
                "address": {
                    "address":  info?.address?.formattedAddress,
                    "unitNumber":  info?.address?.unitNumber,
                },
                "logo": {
                    "id": res.data.id,
                    "url": res.data.url,
                    "thumbUrl": res.data.thumbUrl,
                },
            }

            dispatch(mcActions.editMc(info.id, data))
            setProgress(0)
            clearInterval(timer);
            setLoader(false)
        }).catch((e) => {
            setProgress(0)
            setLoader(false)
            clearInterval(timer);
        })
    }


    const handleRemove = (e) => {
        const data = {
            "name": info.name,
            "mcNumber": info.mcNumber,
            "phone": info.phone,
            "address": info.address.formattedAddress,
            "logo": {
                "id": info.logo.id,
                "url": info.logo.url,
                "thumbUrl": info.logo.thumbUrl,
            },
            "removeLogo": true
        }
        setImgSrc('')
        dispatch(httpRequestsOnLoadActions.appendLoading('REMOVE_LOGO'))
        dispatch(mcActions.editMc(info.id, data))
    }

    return (
        <div className='flex'>
            <Line height={300}/>
            <div style={{marginTop: '25px'}} className={classes.logoWrapper}>
                <div style={{flexDirection: 'column'}} className={global.allCenter}>
                    {info && info.logo && info.logo.url &&

                        <button onClick={handleRemove} className={classes.removeButton}>
                            {loaderDel.length ?
                                <MinLoader margin={'-10px -11px'} color={Colors.ThemeBlue}/>
                                :
                                <img src={Images.remove} alt='remove'/>
                            }
                        </button>
                    }
                    {imageSrc ?
                        <div className={classes.logoUpload}>
                            <img
                                style={imageSrc ? {width: '80px', height: '80px', borderRadius: '8px'} : {}}
                                src={imageSrc} alt='icon'
                            />
                        </div>
                        :
                        <FileUploader
                            name="file"
                            handleChange={handleChange}
                        >
                        <div className={classes.logoUpload}>
                            <img
                                style={imageSrc ? {width: '80px', height: '80px', borderRadius: '8px'} : {}}
                                src={ Images.logoUpload} alt='icon'
                            />
                        </div>
                        </FileUploader>
                    }

                    <Box className={classes.loader}>
                        {loader &&
                            <LinearProgress variant="determinate" value={progress}/>
                        }
                    </Box>

                    <div style={{height: '30px'}}>
                        <div>
                            <FileUploader
                                name="file"
                                handleChange={handleChange}
                            >

                            <label className="custom-file-upload">
                                <i/><p className={globalText.drag}>


                                {info && info.logo ? 'Remove or ' : 'Drag & Drop Logo or'}</p>
                                <input
                                    onClick={event => event.target.value = null}
                                    onChange={handleChange}
                                    type="file"
                                    id="file"
                                    // accept={'image/png, image/gif, image/jpeg'}
                                />

                                <i className="fa fa-cloud-upload"/>
                                <span className={globalText.upload}> Upload </span>


                                <p className={globalText.drag} style={{marginLeft: '8px'}}>{info && info.logo ? ` New Logo` : ''}</p>
                            </label>
                            </FileUploader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}