import {Images} from "utils";
import { IconAndTitle, PhoneRow, TextRow} from "components";

export const branchesHead = [
    {name: 'name', title: 'Branch Name',},
    {name: 'office', nameRow:'officeName', title: 'Office Name', custom: false,},
    {name: 'email', title: 'Email Address',},
    {name: 'address', title: 'Address', custom:false},
    {name: 'phoneNumber', title: 'Phone Number', custom:false},
]

export const branchesBody = [
    {rowText: (item) => <IconAndTitle icon={ Images.branchGreen} title={item?.name}/>} ,
    {rowText:  (item) => <TextRow name={item?.office?.officeName} textWidth={10}  /> },
    {rowText:  (item) => <TextRow name={item?.email} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10}  />},
    {rowText: (item) => <PhoneRow phone={item?.phoneNumber}/>},
]

export const ACTION_TYPE = 'GET_BRANCHES'