import {buttonsStyle} from "./styles";
import {MinLoader} from "../loader";
import {Colors} from "utils";
import React from "react";

export const VoidedButton = ({handleClick, loader}) => {
    const classes = buttonsStyle()
    return (
        <button onClick={handleClick} className={classes.voided}>
            {loader === true ?
                <MinLoader margin={'3px 0 0 0'} position={'relative'} color={Colors.ThemeBlue}/>
                :
                'Voided'
            }
        </button>
    )
}