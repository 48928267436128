import {makeStyles} from "@material-ui/core/styles";


export const CarrierStyles = makeStyles(() => ({
    flexAble:{
        display:'flex',
        width:'100%',
    },

    alertWrapper:{
        width:'100%',
        marginTop:'24px'
    }
}))