import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";


export const historyStyle = makeStyles(() => ({
    historyCardStyle: {
        width: '100%',
        padding: '16px',
        background: '#F2F4F8',
        marginBottom: 16,
        borderRadius: 8
    },

    historyCardDateStyle: {
        color: Colors.ThemeBlue,
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 24
    },

    historyCardBoxStyle: {
        width: '100%',
        background: Colors.BackgroundWhite,
        borderRadius: 8,
        padding: '16px 32px',
        display: "flex",
        alignItems: 'center',
        marginTop: 8,
        fontSize: 14,
        color: Colors.TextPrimary
    },

    historyCardBoxTimeStyle: {
        minWidth: 200
    },

    cardsWrapper:{
        padding:'32px 24px',
    },

    tittleWrapper:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'31px',
        alignItems:'center',
    },

    flaxAble:{
        display:'flex',
        alignItems:'center',
    },

    historyTitle:{
        fontSize:'24px',
        fontWeight:'bold',
        color:Colors.BackgroundDark,
        marginLeft:'11px',
    },

    textField:{
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:Colors.ThemeBorder
        },
        '& .MuiOutlinedInput-root':{
            height:'38px'
        },
        '& .MuiInputLabel-outlined':{
            marginTop:'-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)'
        },
    }

}))