import axios from "axios";
import { ACCOUNT_ENUMS } from '../../utils';

export const authService = {
  signIn: (body) => axios.post("/authn/signin", body),

  logOut: () => axios.post(`/authn/logout`, {}, { auth: true }),

  getLink: (email) => axios.get(`/authn/forgotPassword/${email}`),

  confirmAccount: (passwords) => {
    const res = axios.post(
      `/authn`,

      {
        newPassword: passwords.newPassword,
        confirmation: passwords.confirmation,
      },
      { headers: { "registration-token": passwords.token } }
    );
    return res;
  },

  resetPass: (passwords) => {
    let endpoint = `/authn/resetPassword`;
    const res = axios.post(
      endpoint,
      {
        newPassword: passwords.newPassword,
        confirmation: passwords.confirmation,
      },
      { headers: { "reset-token": passwords.token } }
    );
    return res;
  },

  changePasswordService: (data) =>
    axios.post("/authn/changePassword", data, { auth: true }),

  muAuthnService: () => axios.get(`/authn/myAuth`, { auth: true }),

  myProfileService: (type) => axios.get(
     type === ACCOUNT_ENUMS.ADMIN ? `/admins/myProfile` :
           type === ACCOUNT_ENUMS.AGENT ? `/agents/myProfile` :
             type === ACCOUNT_ENUMS.FACTORED_CARRIER ? `/carriers/profile` : '',

    { auth: true }),

  constactUs: (body) => axios.post(`/mailer/contactus`, body),
};
