
/** Templates */
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';

export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';

export const GET_TEMPLATE_BY_ID = 'GET_TEMPLATE_BY_ID';
export const GET_TEMPLATE_BY_ID_SUCCESS = 'GET_TEMPLATE_BY_ID_SUCCESS';


