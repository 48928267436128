import {Button, Link} from "@material-ui/core";
import {buttonsStyle} from "./styles";
import React from "react";
import {useHistory} from "react-router-dom";

export const AddButton = ({text, handleClick, href, link}) => {
  const classes = buttonsStyle();
  const history = useHistory()

  return (
    <div className='button-style'>
      {link === true ?
        <button style={{border:'none'}} onClick={() => window.location.replace(href)} className={classes.addButtonStyle} >
          <p> + </p>
          {text}
        </button>
        :
        <Button className={classes.addButtonStyle} onClick={handleClick}>
          <p> + </p>
          {text}
        </Button>
      }
    </div>
  );
};
