import {makeStyles} from "@material-ui/core/styles";

export const officesInfoFragments = makeStyles (() => ({
    
    lineWrapper:{
        display:'flex',
    },

    managementHead:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },

    inputsWrapper:{
        display:'flex',
        justifyContent:'space-between',
        width:'100%',
        marginTop:'24px',
    },

    officeManagement:{
        width:'100%',
    },

    officeManagementHead:{
        marginTop:'24px',
        "@media (min-width: 1920px)": {
            marginTop:'32px'
        }
    },
    authoritesManagementHead:{
        marginTop:'34px',
        "@media (min-width: 1920px)": {
            marginTop:'40px'
        }
    },
    officeManagementHeadTitle:{
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginBottom:'8px',
        justifyContent:'space-between',
        display:'flex',
        alignItems:'center',
        padding:'16px',

        '& div':{
            display:'flex',
            alignItems:'center'
        },
        '& button':{
            border:'none',
            outline:'none',
            background:'none'
        }
    },

    tableWrapper: {
        "@media (min-width: 1920px)": {
            marginLeft:'32px',
        },
        marginLeft:'24px',
        height: '100%',
        minHeight: '80vh',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '16px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
    },

}))