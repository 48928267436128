import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadPayroll, PayrollSummary } from 'fragments';
import { FullTable, TableWrapper } from 'components';
import { ACTION_TYPE, payrollBody, payrollHead, payrollHeadAgent } from './constants';
import { agentActions, branchActions, officeActions, payrollActions } from 'store';
import { userType, userInfo, SavePage, officeManager, branchManager } from 'utils';
import moment from 'moment/moment';

export const Payroll = () => {
  const { payrolls, payrollSummary, payrollLoads } = useSelector((state) => ({
    payrolls: state.payroll.payrolls,
    payrollSummary: state.payroll.payrollSummary,
    payrollLoads: state.payroll.payrollLoads,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const checkUser = userType === 'ADMIN' ? true : officeManager ? true : branchManager;
  const checkBranchAndOfficeManager = userType === 'ADMIN' && userInfo?.managedOffices?.length > 0 ? false : userInfo?.managedBranches?.length > 0 ? false : true;
  const checkUserForList = info?.agent ? false : userType === 'ADMIN' ? true : officeManager ? true : branchManager;
  const [inActiveAgents, setInActiveAgents] = useState(false);

  useEffect(() => {
    dispatch(officeActions.getOffices({ status: 'ACTIVE' }));
    dispatch(branchActions.getBranches({ status: 'ACTIVE' }));
    dispatch(agentActions.getAgents(info?.inActiveAgents ? {} : { status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    setInActiveAgents(info?.inActiveAgents);
    const date = new Date();
    const yearMonth = {
      year: info?.date ? +info?.date.slice(0, 4) : date.getFullYear(),
      month: info?.date ? +info?.date.slice(5, 7) : date.getMonth() + 1,
    };

    const filteredInfo = {
      ...info,
      ...yearMonth,
    };

    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    delete filteredInfo.inActiveAgents;
    delete filteredInfo.viewType;

    if (info?.type === 'OTHER') {
      filteredInfo.types = ['OFFICE_EXPENSE', 'SOFTWAREFEE'];
      delete filteredInfo.type;
    } else if (info?.type && info?.type !== 'ALL') {
      filteredInfo.types = [info?.type];
    } else {
      delete filteredInfo.types;
    }
    delete filteredInfo.type;


    if (info?.viewType === 'details') {
      dispatch(payrollActions.getPayrolls({ ...filteredInfo }));
    }


    if (info?.viewType === 'load' || !info?.viewType) {
      const date = info?.date ? moment(info?.date) : moment();
      const startOfMonth = date.startOf('month');
      const lastDayOfMonth = date.endOf('month');

      const params = {
        from: moment(startOfMonth).format('YYYY-MM-01T00:00:01'),
        to: moment(lastDayOfMonth).format('YYYY-MM-DDT23:59:59'),
        limit: 100,
        ...filteredInfo,
      };

      delete params.date;
      delete params.types;
      delete params.year;
      delete params.month;

      params.skip = info?.skip ? info?.skip : 0;

      dispatch(payrollActions.getPayrollLoads(params));
    }


    const payroll = {
      ...info,
      ...yearMonth,
    };
    delete payroll.skip;
    delete payroll.limit;
    delete payroll.page;
    delete payroll.types;
    delete payroll.type;
    delete payroll.inActiveAgents;

    if (userType === 'AGENT' && userInfo?.managedOffices?.length === 0 && userInfo?.managedBranches?.length === 0) {
      payroll.agent = userInfo?.id;
    }

    delete payroll.viewType;
    dispatch(payrollActions.getPayrollSummary({ ...payroll }));

  }, [info]);

  useEffect(() => {
    if (userInfo?.managedOffices?.length > 0 && userType === 'AGENT') {
      const anotherInfo = {
        ...info,
        office: userInfo?.managedOffices[0],
      };
      SavePage(history, info, { ...anotherInfo });
    }
  }, []);

  const getAgents = (state) => {
    const params = {};
    if (state) {
      delete params.status;
    } else {
      params.status = 'ACTIVE';
    }
    dispatch(agentActions.getAgents({ ...params }));
  };

  const handlePast = () => {
    const anotherInfo = {
      ...info,
    };
    anotherInfo.skip = 0;
    anotherInfo.page = 1;
    anotherInfo.inActiveAgents = !inActiveAgents;
    delete anotherInfo.agent;
    setInActiveAgents(!inActiveAgents);
    SavePage(history, info, { ...anotherInfo });
    getAgents(!inActiveAgents);
  };

  const handleChangeView = (type) => {
    const params = {
      ...info,
    };
    delete params.searchKey;
    delete params.searchValue;

    history.push({
      state: {
        ...params,
        skip: 0,
        page: 1,
        viewType: type,
      },
    });
  };

  return (
    <div className="payroll-page">
      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <PayrollSummary
          checkUser={checkUser}
          checkBranchAndOfficeManager={checkBranchAndOfficeManager}
          checkUserForList={checkUserForList}
          payrollSummary={payrollSummary}
          handlePast={handlePast}
        />
        <div className="space-between">
          <div>
            <div className="buttons-tab-wrapper">
              <button
                onClick={() => handleChangeView('details')}
                className={info?.viewType === 'details' ? 'active-btn' : 'passive-btn'}
              >
                Details
              </button>
              <button
                onClick={() => handleChangeView('load')}
                className={!info?.viewType ? 'active-btn' : info?.viewType === 'load' ? 'active-btn' : 'passive-btn'}
              >
                By Load
              </button>
            </div>
          </div>
        </div>
        {(info?.viewType === 'details') &&
          <FullTable
            head={checkUser ? payrollHead : payrollHeadAgent}
            body={payrollBody}
            loadingType={ACTION_TYPE}
            list={payrolls?.payrolls?.filter((i) => i?.type !== 'LPF')}
            listCount={payrolls?.count}
            noText={'Details'}
            defaultType={info?.type ? info?.type : 'GROSSPROFIT'}
          />
        }
        {(!info?.viewType || info?.viewType === 'load') &&
          <LoadPayroll />
        }
      </TableWrapper>
    </div>
  );
};