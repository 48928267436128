import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {CreateBranchInputs} from "./core";
import {CreateWrapper} from "components";
import {Images, useGlobalStyles} from "utils";
import {officeActions} from "store";

export const CreateBranchTable = ({}) => {
    const globalStyle = useGlobalStyles();
    const dispatch = useDispatch()
    const [name, setName] = useState('')

    useEffect(() => {
        dispatch(officeActions.getOffices({status:'ACTIVE'}))
    }, []);

    return (
        <div>
            <CreateWrapper
                head={
                    <div className={globalStyle.createOfficeTableHead}>
                        <img src={Images.branchFill} alt={"branch"}/>
                        <p>{name ? name : 'BRANCH NAME'}</p>
                    </div>
                }
                body={
                    <CreateBranchInputs
                        handleChangeName={setName}
                    />
                }
                parentLink={'/branches'}
                parent={'Branches'}
                child={'Add Branch'}
            />
        </div>
    );
};
