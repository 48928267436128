import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const receivablesFragments = makeStyles(() => ({
    blueButton: {
        width: '141px',
        height: '32px',
        background: '#438AFE 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    needed: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.TextPrimary,
    },

    sendTo: {
        display: 'flex',
        width: '160px',
        '& img': {
            marginRight: '8px',
        },
    },

    awaiting: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.ThemeRed,
    },

    title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
        marginBottom:'8px',
    },

    checkbox:{
        marginBottom:'16px',
        '& .MuiSvgIcon-root':{
            color:Colors.ThemeGreen,
        }
    },

    inputBottom:{
        marginBottom:'24px'
    }

}));
