
/**Create branch */
export const CREATE_BRANCH = 'CREATE_BRANCH';

/**Edit branch */
export const EDIT_BRANCH= 'EDIT_BRANCH'

/**Get branches */
export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';

/**Get branch by id */
export const GET_BRANCH_BY_ID = 'GET_BRANCH_BY_ID';
export const GET_BRANCH_BY_ID_SUCCESS = 'GET_BRANCH_BY_ID_SUCCESS';

/**Activate Inactivate branch */
export const ACTIVATE_BRANCH = 'ACTIVATE_BRANCH';
export const INACTIVATE_BRANCH = 'INACTIVATE_BRANCH';

/**Branch Management */
export const CREATE_BRANCH_MANAGERS = 'CREATE_BRANCH_MANAGERS';
export const CREATE_BRANCH_MANAGERS_SUCCESS = 'CREATE_BRANCH_MANAGERS_SUCCESS';
export const SELECT_BRANCH_MANAGERS = 'SELECT_BRANCH_MANAGERS';
export const REMOVE_BRANCH_MANAGERS = 'REMOVE_BRANCH_MANAGERS';
