import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./brackets.service";
import {
    ADD_SYSTEM_BRACKET, DELETE_SYSTEM_BRACKET, EDIT_SYSTEM_BRACKET,
    GET_SYSTEM_DEFAULT_BRACKET, GET_SYSTEM_DEFAULT_BRACKET_SUCCESS,
} from "./brackets.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Payroll Brackets */

function* addBracket(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        yield call(authService.addServiceBracketService, action.payload.data);
        yield put({
            type: GET_SYSTEM_DEFAULT_BRACKET,
            payload: { load:'noLoad'}
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err.data.message));
    }
}

function* deleteBracket(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));

    try {
        yield call(authService.deleteServiceBracket, action.payload.id);
        yield put({
            type: GET_SYSTEM_DEFAULT_BRACKET,
            payload: { load:'noLoad'}
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    }
}

function* editBracket(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));

    try {
        yield call(authService.editServiceBracketService, action.payload.id, action.payload.data);
        yield put({
            type: GET_SYSTEM_DEFAULT_BRACKET,
            payload: { load:'noLoad'}
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err.data.message));
    }
}

function* getDefaultBracket(action) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if(action?.payload?.load !== 'noLoad'){
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getServiceBracketService);
        yield put({
            type: GET_SYSTEM_DEFAULT_BRACKET_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

/** End */


export const watchBracket = function* watchBracketSaga() {
    /** Payroll Brackets */
    yield takeLatest(ADD_SYSTEM_BRACKET, addBracket)
    yield takeLatest(DELETE_SYSTEM_BRACKET, deleteBracket)
    yield takeLatest(EDIT_SYSTEM_BRACKET, editBracket)
    yield takeLatest(GET_SYSTEM_DEFAULT_BRACKET, getDefaultBracket)
    /** End */
};
