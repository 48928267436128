import {Images} from "utils";
import {DateRow, IconAndTitle, PhoneRow, TextRow} from "components";

export const officesHead = [
    {name: 'name', title: 'Office Name'},
    {name: 'address', title: 'Address'},
    {name: 'email', title: 'Email Address'},
    {name: 'phoneNumber', title: 'Phone Number', custom: false},
    {name: 'establishedDate', title: 'Establish', icon: 'date', type:'date'},
]

export const officesBody = [
    {rowText: (item) => <IconAndTitle icon={Images.yellowOffices} title={item?.name} />},
    {rowText:  (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.email} textWidth={10}  />},
    {rowText: (item) => <PhoneRow phone={item?.phoneNumber}/>, width:'150px'},
    {rowText: (item) => <DateRow date={item?.establishedDate}/>, width:'100px'},
]

export const ACTION_TYPE = 'GET_OFFICES'