import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { Images } from "utils";

export const TrustedCore = () => {
  /**
   * Header Core.
   */

  return (
    <Fragment>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6} className="order-img">
          <img src={Images.yourTrusted} alt="" className="full-img" />
        </Grid>
        <Grid item xs={12} md={6} className="order-content">
          <div className="column">
            <h2 className="title-we">
              Your Trusted Partner for Innovative Transportation Management
              Solutions
            </h2>
            <div className="box-text">
              <p className="type-text">
                At our logistics company, we understand that time is of the
                essence when it comes to shipping your goods. That's why we
                offer a range of services to ensure that your shipments arrive
                at their destination in a timely and cost-effective manner. From
                air freight to ocean freight, ground transportation to
                warehousing and distribution, we have the expertise and
                resources to handle all of your logistics needs.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
