import {
    ACTIVATE_BRANCH,
    CREATE_BRANCH,
    CREATE_BRANCH_MANAGERS,
    EDIT_BRANCH,
    GET_BRANCH_BY_ID,
    GET_BRANCHES,
    INACTIVATE_BRANCH,
    REMOVE_BRANCH_MANAGERS,
    SELECT_BRANCH_MANAGERS,
} from "./branches.types";

/** Branches Requests, here is All requests for Branch page */

/** Create, Edit Branch */

export const createBranch = (body) => {
    return {
        type: CREATE_BRANCH,
        payload: {body}
    }
}

export const editBranch = (body, id) => {
    return {
        type: EDIT_BRANCH,
        payload: {body, id}
    }
}

/** End */

/** Get Branches */

export const getBranches = (params) => {
    return {
        type: GET_BRANCHES,
        payload: { params }
    }
}

export const getBranchById = (id) => {
    return {
        type: GET_BRANCH_BY_ID,
        payload: {id}
    }
}

/** End */

/** Activate or Inactivate Branch */

export const activateBranch = (id) => {
    return {
        type: ACTIVATE_BRANCH,
        payload: {id}
    }
}

export const inactivateBranch = (id) => {
    return {
        type: INACTIVATE_BRANCH,
        payload: {id}
    }
}

/** End */

/** Branch Management */

export const createBranchManager = (data) => {
    return {
        type: CREATE_BRANCH_MANAGERS,
        payload: {data}
    }
}

export const selectBranchManager = (data) => {
    return {
        type: SELECT_BRANCH_MANAGERS,
        payload: {data}
    }
}

export const removeBranchManager = (data) => {
    return {
        type: REMOVE_BRANCH_MANAGERS,
        payload: {data}
    }
}

/** End */