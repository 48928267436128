import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {useParams} from "react-router-dom";
import {Colors, FindLoad, PermissionsList, RolePermission, useGlobalStyles, useGlobalText} from "utils";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {AddButton, AddModalButton, Circle} from "components";
import {CreateAgentInputs} from "../createAgent/core";
import {Authorities} from "./core/authorities";
import {Payroll} from "./core/payroll";
import {agentActions} from "store";

export const AgentInfoTable = ({handleChangeFirstName, handleChangeLastName}) => {
    const {agentById} = useSelector((state) => ({
          agentById: state.agents.agentById,
      })
    )
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();
    const dispatch = useDispatch()
    const params = useParams()
    const loader= FindLoad('RESEND_AGENT_INVITE')
    const info = agentById

    useEffect(() => {
        dispatch(agentActions.getDefaultBracket(params.id))
    }, []);

    const resendInvite = () => {
        dispatch(agentActions.resendAgent(params.id))
    }

    return (
        <div className='accordion-style'>
            <div className={globalStyle.resendInvite}>
                <AddModalButton
                    loading={!!loader?.length}
                    handleClick={resendInvite}
                    text={'Resend Invite'}
                />
            </div>
            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={1} back={Colors.ThemeDarkBlue}/>}
                        <p className={globalStyle.title}>Agent Information</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateAgentInputs
                        handleChangeFirstName={handleChangeFirstName}
                        handleChangeLastName={handleChangeLastName}
                        info={info}
                    />
                </AccordionDetails>
            </Accordion>

            {RolePermission([PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeManager') &&
                <Accordion>
                    <AccordionSummary
                        className={'accordion'}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={globalInputs.centerItem}>
                            {info && <Circle number={2} back={Colors.ThemeDarkBlue}/>}
                            <p className={globalStyle.title}>MC Authorities</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Authorities info={info}/>
                    </AccordionDetails>
                </Accordion>
            }

            {RolePermission([PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeManager') &&
                <Accordion>
                    <AccordionSummary
                        className={'accordion'}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={globalInputs.centerItem}>
                            {info && <Circle number={3} back={Colors.ThemeDarkBlue}/>}
                            <p className={globalStyle.title}>Payroll</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Payroll/>
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    )
}