import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {billingDetailsStyle} from "./styles";
import {AddModalButton, CloseButton} from "components";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {PermissionsList, RolePermission} from "utils";

const REQUEST_TYPE = 'SEND_TO_BILLING'

export const SubmitBillModal = ({handleClose, loadById, handleSend, bill, handleGet}) => {
    const classes = billingDetailsStyle()
    const [loadBill, setLoadBill] = useState(false)
    const dispatch = useDispatch()
    const [disable, setDisable] = useState(true)
    const loader = useRef(null);

    const handleSubmitBill = async () => {
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE))
        setLoadBill(true)
        try {
            await LoadServices.SubmitBillingLoad(loadById.id)
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(REQUEST_TYPE))
            setLoadBill(false)
            if (bill) {
                handleGet()
            } else {
                dispatch(loadActions.getLoadById(loadById.id, 'noLoad'))
            }
            handleClose()
        } catch (e) {
            dispatch(httpRequestsOnErrorsActions.appendError(REQUEST_TYPE))
            setLoadBill(false)
        }
    }

    useEffect(() => {
        let options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, []);

    const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting && disable) {
            setDisable(false)
        }
    };

    return (
        <div>
            <div className={classes.closeButtonWrapper}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div style={{overflow: 'scroll', height: '700px'}} className={classes.paperWrapper}>
                <div style={{height: '702px', overflow: 'scroll'}}>
                    <object
                        style={{overflow: 'hidden'}}
                        data={loadById?.bill?.lastInvoiceDocs?.url}
                        width="100%" height="100%"
                    >
                    </object>
                    <div className="loading" ref={loader}/>
                </div>
            </div>
            <div className={classes.paperFooter}>
                <div style={{display: 'flex',}}>
                    <AddModalButton
                        disabled={disable}
                        loading={loadBill}
                        styles={{width: '238px', marginRight: '24px',}}
                        handleClick={handleSubmitBill}
                        text={'Submit Bill'}
                    />
                    {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.REQUEST_CORRECTION_BILLING?.code]) &&
                        <button onClick={handleSend} className={classes.requestCorrection}>
                            Request Corrections
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}