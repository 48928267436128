import React from "react";
import InputMask from "react-input-mask";
import {TextField} from "@material-ui/core";
import {useGlobalStyles} from "utils";
import {ErrMessage} from "../messages";
import {inputsStyle} from "./styles";

export const MaskNoChar = ({name, label, value, disables, handleChange, info, error, mask, styles, border}) => {
    const globalWrappers = useGlobalStyles();
    const classes = inputsStyle();

    return(
        <div
            className={globalWrappers.simpleInput}
            style={{display: 'flex', flexDirection: 'column', width: '100%', ...styles}}
        >
            <InputMask
                maskChar={null}
                mask={mask ? mask : "(999) 999-9999"}
                value={value}
                disabled={disables}
                onChange={handleChange}
            >
                {() => <TextField
                    disabled={disables}
                    variant={'outlined'}
                    label={label}
                    name={name}
                    className={
                        border === 'black' ? classes.inputTextField :
                            info ? globalWrappers.inputTextFieldBlue : classes.inputTextField}
                    error={error}
                />
                }
            </InputMask>
            <div style={{position:'relative'}}>
                <ErrMessage text={error}/>
            </div>
        </div>
    )
}