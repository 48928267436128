import React from 'react';
import { AddButton, ButtonsTab, CsvFile } from '../buttons';
import { wrapperStyle } from './styles';
import { RolePermission } from 'utils';
import { FilterForTable } from '../table/filtereForTable';

export const TableWrapper = ({
                               buttonsTab,
                               buttonsTabAddButton,
                               children,
                               firstButton,
                               handleClick,
                               secondButton,
                               addButton,
                               href,
                               link,
                               tab,
                               createPermisson,
                               type,
                               csvExport,
                               dateFilter,
                               startEnd,
                               tabMaxWidth,
                               tabButtonWidth
                             }) => {
  const classes = wrapperStyle();
  const checkPermission = type === 'All' ? true : RolePermission([createPermisson?.code], type);

  return (
    <div>
      {buttonsTab && (
        <div className={classes.buttonsTabStyle}>
          {
            tab !== 'none' &&
            <ButtonsTab
              first={firstButton}
              second={secondButton}
              maxWidth={tabMaxWidth}
              width={tabButtonWidth}
            />
          }
          <>
          {buttonsTabAddButton && (
            <div className={classes.addButton} style={{ gap: '24px', alignItems:'center' }}>
              {csvExport &&
                  <CsvFile params={csvExport}/>
              }

              {checkPermission &&
                <AddButton handleClick={handleClick} link={link} href={href} text={addButton} />
              }


            </div>
          )}
            {dateFilter &&
              <FilterForTable
                startEnd={startEnd}
                createPermisson={false}
                type={type}
                dateFrom={true}
              />
            }

          </>
        </div>
      )}
      {children}
    </div>
  );
};
