import { makeStyles } from "@material-ui/core/styles";
import {Colors} from "utils";

export const errMessageStyle = makeStyles(() => ({
  errMessageCenterPosition: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "10px",
  },

  errMessageLeftPosition: {
    display: "flex",
    width: "100%",
    margin: '10px 0 15px 0',
  },

  errMessageStyleText: {
    fontSize: "14px",
    fontWeight: "600",
    color: Colors.ThemeError,
    position:'absolute',
    marginTop:'-5px',
  },

  DoneMessage: {
    width: "420px",
    height: "62px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 12px #0052E01F",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: "20px 16px",
    position: "fixed",
    bottom: "50px",
    right: "40%",
    left: "40%",

    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#252E48",
    },
  },

  notYetText:{
    fontSize: "16px",
    lineHeight: "24px",
    color:Colors.TextLight,
    fontWeight:'bold'
  },

  notYetCenter:{
    width:'100%',
    display:'flex',
    margin:'16px',
    "@media (min-width: 1918px)": {
      margin:'24px',
    }
  },
  notYetScreenCenter:{
    width:'100%',
    display:'flex',
    margin:'16px',
    justifyContent:'center',
    alignItems:'center',
    "@media (min-width: 1918px)": {
      margin:'24px',
    }
  },

  toastWorn:{
    width: '500px',
    height: '78px',
    borderRadius: '8px',
    padding:'16px',
    // position:'absolute',
    // right:'30px',
    display:'flex',
    '& img':{
      width: '30px',
      height: '30px',
      marginRight:'16px'
    },
    '& p':{
      fontSize:'16px',
      color:Colors.TextPrimary,
      lineHeight:'24px',
    }
  }
}));
