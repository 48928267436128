import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AddButton, SimpleModal} from "components";
import {AccessManagement, AddRoleModal} from "fragments";
import {useGlobalStyles} from "utils";
import {permissionsActions, roleActions} from "store";

export const Management = ({}) => {
    const {permissionsList, rolesList} = useSelector((state) => ({
        permissionsList: state.permissions.permissionsList,
        rolesList: state.roles.rolesList,
    }));
    const classes = useGlobalStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState('')

    useEffect(() => {
        dispatch(permissionsActions.getPermissions());
        dispatch(roleActions.getRole());
    }, []);

    const handleOpenClose = () => {
        setOpen(!open)
        setItem('')
    }

    const handleEdit = ( info ) => {
        setOpen(!open)
        setItem(info)
    }

    return (
        <div>
            <div className={classes.spaceBetween}>
                <div/>
                <AddButton
                    handleClick={handleOpenClose}
                    text={"Add Role"}
                />
            </div>

            <AccessManagement
                handleEdit={handleEdit}
                rolesList={rolesList}
                permissionsList={permissionsList}
            />

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <AddRoleModal
                        info={item}
                        permissionsList={permissionsList}
                        handleClose={handleOpenClose}
                    />
                }
            />
        </div>
    );
}
