import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile,
} from 'react-icons/md';

class WidgetTree extends Component {
    constructor() {
        super();

        this.state = {
            checked: [],
            expanded: [],
        };
    }

    componentDidMount() {
        if (this.props.selectedId !== undefined) {
            this.setState({ checked: this.props.selectedId });
        }
    }

    render() {
        const handleCheck = (checked) => {
            this.setState({ checked });
            this.props.handleNode(checked);
        };

        const handleAll = () => {
            this.setState({ checked: [], expanded: [] });
            this.props.handleNode([]);
        };

        const icons = {
            check: <MdCheckBox className="rct-icon-rct-icon-check" />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon-rct-icon-uncheck" />,
            halfCheck: <MdIndeterminateCheckBox className="rct-icon-rct-icon-half-check" />,
            expandClose: <MdChevronRight className="rct-icon-rct-icon-expand-close" />,
            expandOpen: <MdKeyboardArrowDown className="rct-icon-rct-icon-expand-open" />,
            expandAll: <MdAddBox className="rct-icon-rct-icon-expand-all" />,
            collapseAll: <MdIndeterminateCheckBox className="rct-icon-rct-icon-collapse-all" />,
            parentClose: <MdFolder className="rct-icon-rct-icon-parent-close" />,
            parentOpen: <MdFolderOpen className="rct-icon-rct-icon-parent-open" />,
            leaf: <MdInsertDriveFile className="rct-icon-rct-icon-leaf-close" />,
        };

        return (
            <div>
                <CheckboxTree
                    checkModel={'all'}
                    nodes={this?.props?.orgCateg}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={(checked) => handleCheck(checked)}
                    onExpand={(expanded) => this.setState({ expanded })}
                    icons={icons}
                />
            </div>
        );
    }
}

export default WidgetTree;
