import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reportActions} from "store";
import {ButtonsTab, CalendarInput, ExportButton, FullTable, GenerateButton, Loader} from "components";
import {AR_SUMMARY_REQUEST_TYPE, arSummaryBody, arSummaryHead} from "./constants";
import {reportStyles} from "../styles";
import { DownloadFile, FindLoad } from 'utils';
import {Row, TotalRow} from "../core";

export const ArSummary = ({arSummary}) => {
    const classes = reportStyles()
    const [date, selectedDate] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const loader = FindLoad(AR_SUMMARY_REQUEST_TYPE)
    const info = history?.location?.state
    const { loadDownload, handleDownload, } = DownloadFile()

    useEffect(() => {
        if (info?.status) {
            dispatch(reportActions.cleanArSummaryReport())
        }
    }, [info])

    const handleGetDate = (sendInfo) => {
        dispatch(reportActions.getArSummaryReport(sendInfo, info?.status))
    }

    const handleChangeDate = (e) => {
        selectedDate(e.target.value)
        const sendInfo = {}
        if (e.target.value) {
            sendInfo.month = e.target.value.slice(6, 8)
            sendInfo.year = e.target.value.slice(0, 4)
        } else {
            delete sendInfo.month
            delete sendInfo.year
        }
        handleGetDate(sendInfo)
    }

    return (
        <div>
            <div className={classes.actionsWrapper}>
                <ButtonsTab
                    maxWidth={'320px'}
                    width={'150px'}
                    first={'Current'}
                    second={'Past Reports'}
                    type={'report'}
                />
                <div className={classes.exportAndCalendar}>
                    {info?.status === 'Past Reports' ?
                        <CalendarInput
                            value={date}
                            handleChangeDate={handleChangeDate}
                        />
                        :
                        <GenerateButton handleGenerate={() => handleGetDate()}/>
                    }
                    {arSummary?.file?.url &&
                      <ExportButton
                        loader={!!loadDownload}
                        handleExport={() => handleDownload({file:arSummary?.file, name: 'ARSummaryReportMontly.csv'})}
                      />
                    }
                </div>
            </div>

            {loader?.length ?
                <Loader/>
                :
                <>
                    <TotalRow
                        rows={
                            <>
                                <Row name={'Total'}
                                     value={arSummary?.grandTotal ? arSummary.grandTotal : 0}
                                />
                                <Row name={'Current'}
                                     value={arSummary?.totalCurrent ? arSummary.totalCurrent : 0}
                                />
                                <Row name={'31-60 Days'}
                                     value={arSummary?.total31to60 ? arSummary.total31to60 : 0}
                                />
                                <Row name={'61-90 Days'}
                                     value={arSummary?.total61to90 ? arSummary.total61to90 : 0}
                                />
                                <Row name={'91+ Days'}
                                     value={arSummary?.total90plus ? arSummary.total90plus : 0}
                                />
                            </>
                        }
                    />
                    <FullTable
                        head={arSummaryHead}
                        body={arSummaryBody}
                        list={arSummary?.data?.length ? arSummary?.data : []}
                        noText={'AR Summary'}
                    />
                </>
            }
        </div>
    )
}