import { makeStyles } from "@material-ui/core";
import {Backgrounds} from "./globalColors";

export const globalModals = makeStyles({
    smallModalWrapper:{
        width: "556px",
        height: "auto",
        background: Backgrounds.whiteModal,
        borderRadius: "8px",
        padding:'8px 0 40px 0',
        maxHeight:'590px',
        overflow:'auto',
        "@media (min-width: 1919px)": {
            width: "582px",
            maxHeight:'650px',
        }
    },
    bigModalWrapper:{
        width: "800px",
        height: "auto",
        background: Backgrounds.whiteModal,
        borderRadius: "8px",
        padding:'8px 0 40px 0',
        maxHeight:'675px',
        overflow:'auto',
        "@media (min-width: 1919px)": {
            width: "800px",
            maxHeight:'650px',
        }
    },

    smallModalClose:{
        display:"flex",
        justifyContent:"flex-end",
    },

    modalWrapperContent:{
        padding:'8px 32px 0 32px',

        "@media (min-width: 1920px)": {
            padding:'8px 40px 0 40px',
        }
    },
    suiteInput:{
        marginTop:'18px'
    }
})
