import React, {useState, useEffect} from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {Backdrop, Badge, Box} from '@material-ui/core';
import {useNoteContStyles} from './styles';
import Modal from '@material-ui/core/Modal';
import {NotificationWrapper} from "./notifications";
import {Colors} from "utils";

export const Notification = ({notifications, userInfo}) => {
    const classes = useNoteContStyles();
    const [open, setOpen] = useState(false)
    const notes = notifications && notifications.filter((n) => n.status === 'UNREAD');
    const [count, setCount] = useState(notes);

    useEffect(() => {
        if (count && count > 9) {
            setCount(`${9}+`);
        } else {
            setCount(notes);
        }
    }, [notifications && notifications.length]);

    return (
        <div className={classes.notWrapper}>

            <Badge
                color="default"
                variant="dot"
                className={count && count.length ? classes.notificationBadge  :classes.notificationBadgeNone}>

                <NotificationsIcon style={{fontSize: '30px', color: Colors.ThemeBlue, cursor: 'pointer'}}
                                   onClick={() => setOpen(!open)}/>
                    <Modal
                        onClose={() => setOpen(!open)}
                        open={open}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <Box>
                        <NotificationWrapper userInfo={userInfo}
                                             notifications={notifications && notifications}
                                             handleClose={() => setOpen(!open)}
                        />
                        </Box>
                    </Modal>
            </Badge>
        </div>
    );
};

export default Notification;
