import React from "react";
import {CANCELLED, DENIED, REJECTED} from "../../../../pages/accounting/constants";
import {DateRow, InfoSection} from "components";
import {Images} from "utils";
import { createLoadStyle } from './styles';

export const LoadErrAndNotes = ({loadById}) => {
    const classes = createLoadStyle()

    return (
        <div>

            {loadById?.isVerification &&
                <div className={classes.notePadding}>
                    <InfoSection text={loadById?.verificationNote} note={true}/>
                </div>
            }

            {(loadById?.status === REJECTED || loadById?.status === DENIED) && loadById?.bill?.notes &&
                <div className={classes.rejectedLoad}>
                    <img src={Images.rejectedLoad} alt="revisionNote"/>
                    <div className={classes.revisionNeededWrapper}>
                        <p style={{whiteSpace: 'nowrap'}}>Credit Rejected:&nbsp; </p>&nbsp;
                        <p style={{fontWeight: 400}}> {loadById?.bill?.notes}</p>
                    </div>
                </div>
            }

            {loadById?.revisionComment && loadById?.isRevision &&
                <div className={classes.revisionNeeded}>
                    <img src={Images.revisionNote} alt="revisionNote"/>
                    <div className={classes.revisionNeededWrapper}>
                        <p style={{whiteSpace: 'nowrap'}}><b>Revision needed: </b></p>&nbsp;
                        <p> {loadById?.revisionComment} </p>
                    </div>
                </div>
            }

            {loadById?.status === CANCELLED &&
                <div className={classes.canceledLoad}>
                    <img src={Images.error} alt="revisionNote"/>
                    <div className={classes.revisionNeededWrapper} >
                        <p><b> Canceled </b></p>

                        {loadById?.cancelledDate &&
                               <p style={{marginLeft:'16px'}}>
                                   <DateRow date={loadById?.cancelledDate}/>
                               </p>
                        }
                    </div>
                </div>
            }

        </div>
    )
}