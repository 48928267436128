import {
  addRolePermission,
  createRole,
  deleteRole,
  deleteRolePermission,
  editRole,
  getRole,
  getRoleById,
  removeRole,
  searchRoles
} from "./role.action";

export { roleReducer } from './role.reducer';
export { watchRole } from './role.saga';

export const roleActions = {
  /**Create Edit roles */
  createRole,
  editRole,
  /** End */
  /**Get roles */
  getRole,
  getRoleById,
  /** End */
  deleteRole,
  searchRoles,
  removeRole,
  addRolePermission,
  deleteRolePermission
}

