import React from "react";
import { Button } from "@material-ui/core";
import { CloseButton } from "../buttons";
import { Colors, useGlobalTextStyles } from "utils";
import { modalsStyle } from "./styles";
import { MinLoader } from "../loader";

export const ActiveInactive = ({ className, text, textInfo, handleDel, handleClose, loading, name }) => {
    const classes = modalsStyle()
    const globalText = useGlobalTextStyles();

    return (
        <div className={className ? className.deleteModal : classes.deleteModal}>
            <div className={classes.closeButton}>
                <CloseButton handleCLic={handleClose} />
            </div>
            <div className={classes.deleteModalWrapper}>
                <p className={globalText.modalTitle}>{text}</p>
                <p className={globalText.modalText}>
                    { `${name} will be notified about the inactivation reason after inactivation.`}
                </p>
                <div className={classes.buttons}>
                    <Button
                        style={{
                            textTransform: "capitalize",
                            width: "100%",
                            height: "48px",
                            background: Colors.ThemeGreen,
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: Colors.TextWhite
                        }}
                        onClick={handleDel}
                    >
                        { loading === true ?
                            <MinLoader margin={'0'} color={Colors.TextWhite}/>
                            :
                            textInfo
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};