import React, { Fragment, useState } from "react";
import { Container, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Images } from "utils";

export const AcctionCore = () => {
  /**
   * Header Core.
   */

  const handleLogIn = () => {};

  return (
    <Fragment>
      <Container>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6} className="order-content">
            <div className="content-list">
              <div className="banner-step">
                {/* Step One */}
                <div className="how-step">
                  <div className="step-content">
                    <div className="account">
                      <Grid container display={"flex"} spacing={2}>
                        <Grid item>
                          <img src={Images.Flag} alt="Step-1" />
                        </Grid>
                        <Grid item xs={8}>
                          <h4 className="title font-semiBold text-h4">
                            Improved cash flow
                          </h4>
                          <div className="account-text">
                            Billing helps businesses improve their cash flow by
                            ensuring that payments are collected on time.
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* Step Two */}
                <div className="how-step">
                  <div className="step-content">
                    <div className="account">
                      <Grid container display={"flex"} spacing={2}>
                        <Grid item>
                          <img src={Images.Flag} alt="Step-2" />
                        </Grid>
                        <Grid item xs={8}>
                          <h4 className="title font-semiBold text-h4">
                            Better customer relationships
                          </h4>
                          <div className="account-text">
                            Billing also helps businesses maintain better
                            relationships with their customers.
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* Step Three */}
                <div className="how-step">
                  <div className="step-content">
                    <div className="account">
                      <Grid container display={"flex"} spacing={2}>
                        <Grid item>
                          <img src={Images.Flag} alt="Step-3" />
                        </Grid>
                        <Grid item xs={8}>
                          <h4 className="title font-semiBold text-h4">
                            Efficient record-keeping
                          </h4>
                          <div className="account-text">
                            Billing also helps businesses keep accurate records
                            of their financial transactions.
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="order-img">
            <img src={Images.screenVisual} alt="" className="full-img" />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
