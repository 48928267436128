import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {carrierInfoFragments} from "./styles";
import {
    AddButton,
    DeleteButton,
    DeleteElement, EditButton,
    EditSaveButtons,
    Line,
    NoYet,
    SelectInput,
    SimpleModal,
    TextRow,
    ValidationInput
} from "components";
import {
    ErrorText, FindError, FindErrorItem, FindLoad,
    FindSuccessItem, Images,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText,
} from 'utils';
import {RadioButtons} from "../../createCarrier/core/radioButtons";
import { carrierActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import {authService} from "../../../../store/carriers/carrier.service";
import {FuelCard} from "./fuelCard";

const ACTION_TYPE = 'CARRIER_PAYMENT'
export const PaymentSetup = ({info}) => {
    const {factoringsList} = useSelector((state) => ({
        factoringsList: state.factorings.factoringsList,
    }));
    const [inputs, setInputs] = useState({
        fuel: true,
        factoring: true,
        paymentMethod: true,
        paymentTerm: true,
    });
    const dispatch = useDispatch()
    const params = useParams()
    const classes = carrierInfoFragments();
    const globalText = useGlobalText();
    const globalStyles = useGlobalStyles();
    const [fuelCheck, setFuelCheck] = useState(info ? info.fuel && info.fuel.status : '')
    const [payment, setPayment] = useState(info ? info?.pmtInfo?.method && info.pmtInfo.method : '')
    const [cardNumber, setCardNumber] = useState(info ? info.fuel && info.fuel.number : '')
    const [accNumber, setAccNumber] = useState(info ? info?.pmtInfo?.accountNumber && info?.pmtInfo?.accountNumber : '')
    const [routNumber, setRoutNumber] = useState(info ? info?.pmtInfo?.routingNumber && info?.pmtInfo?.routingNumber : '')
    const [facInfo, setFacInfo] = useState('')
    const [term, setTerm] = useState(info ? info?.pmtInfo?.term && info.pmtInfo.term : '')
    const [error, setError] = useState('')
    const [open, setOpen] = useState('')
    const [factoring, setFactoring] = useState('')
    const [percent, setPercent] = useState(info ? info?.pmtInfo?.quickpayPercent && info.pmtInfo.quickpayPercent : '')
    const [loader, setLoader] = useState(false)
    const [cardInfo, setCardInfo] = useState('')
    const fuelSuccess = FindSuccessItem('CARRIER_FUEL')
    const carrierSuccess = FindSuccessItem(ACTION_TYPE)
    const paymentTermSuccess = FindSuccessItem('CARRIER_PAYMENT_TERM')
    const factoringSuccess = FindSuccessItem('ADD_CARRIER_FACTORING')
    const checkRole = RolePermission([PermissionsList.CARRIER_PAYMENT_SETUP?.code])
    const loaderCard = FindLoad('DELETE_CARRIER_FUEL_CARD')
    const deleteSuccess = FindSuccessItem('DELETE_CARRIER_FUEL_CARD')
    const beckError = FindErrorItem(ACTION_TYPE)

    useEffect(() => {
        if(info?.factoring && info?.pmtInfo?.method === 'FACTORED'){
            setFactoring(info?.factoring?._id)
        }
    }, [info])

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_CARRIER_FUEL_CARD'))
            setOpen('')
        }
        if (fuelSuccess) {
            handleChange('fuel', true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CARRIER_FUEL'))
        }
        if (paymentTermSuccess) {
            handleChange('paymentTerm', true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CARRIER_PAYMENT_TERM'))
        }
        if (carrierSuccess) {
            handleChange('paymentMethod', true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
        if (factoringSuccess) {
            handleChange('factoring', true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ADD_CARRIER_FACTORING'))
        }
    }, [paymentTermSuccess, fuelSuccess, carrierSuccess, factoringSuccess, deleteSuccess]);

    const handleSave = (type) => {
        if (type === 'paymentMethod') {
            const data = {
                "method": payment,
            }
            factoring ? data.factoringId = factoring : ''
            accNumber ? data.accountNumber = accNumber : data.accNumber = ''
            routNumber ? data.routingNumber = routNumber : data.routNumber = ''
            dispatch(carrierActions.carrierPayment(info.id, data))
        } else if (type === 'paymentTerm') {
            const data = {
                'term': term
            }
            percent ? data.percent = +percent : ''
            dispatch(carrierActions.carrierPaymentTerm(info.id, data))
        }
        else if (type === 'fuel') {
            const data = {
                "status": fuelCheck,
            }
            cardNumber ? data.number = parseInt(cardNumber) : ''
            dispatch(carrierActions.carrierFuel(info.id, {...data}))
        }
    }

    const handleOpenClose = (it, type) => {
        setFacInfo(it)
        setCardInfo('')
        setOpen(type ? type : '')
    }

    const deleteFactoring = async () => {
        if (open === 'deleteCard') {
            dispatch(carrierActions.deleteFuelCard(params?.id, cardInfo?._id))
        } else {
            setLoader(true)
            try {
                await authService.deleteFactoringFromPmt(params.id)
                dispatch(carrierActions.getCarrierById(params.id, 'noLoad'))
                setFactoring('')
                handleOpenClose()
                setLoader(false)
            } catch (e) {
                setLoader(false)
            }
        }
    }

    const handleChange = (name, value) => {
        setInputs(prevState => ({...prevState, [name]: value,}),);
    }

    const handleChancel = (name) => {
        if(beckError?.type === ACTION_TYPE){
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
        }
        setInputs(prevState => ({...prevState, [name]: true,}),);
        if (name === 'fuel') {
            setFuelCheck(info ? info.fuel && info.fuel.status : '')
            setCardNumber(info ? info.fuel && info.fuel.number : '')
        } else if (name === 'paymentMethod') {
            setPayment(info ? info?.pmtInfo?.method && info.pmtInfo.method : '')
            setAccNumber(info ? info?.pmtInfo?.accountNumber && info?.pmtInfo?.accountNumber : '')
            setRoutNumber(info ? info?.pmtInfo?.routingNumber && info?.pmtInfo?.routingNumber : '')
        } else if (name === 'factoring') {
            setFactoring('')
        } else if (name === 'paymentTerm') {
            setTerm(info ? info?.pmtInfo?.term && info.pmtInfo.term : '')
            setPercent(info ? info?.pmtInfo?.quickpayPercent && info.pmtInfo.quickpayPercent : '')
        }
    }

    const handleOpenCardDel = (type, card) => {
        setOpen(type ? 'edit' : 'deleteCard')
        setCardInfo(card)
    }

    const returnCard = (text) => {
        return `${text?.slice(0, 4)}  ${text?.slice(4, 8)} ${text?.slice(8, 12)} ${text?.slice(12, 16)} ${text?.slice(16, 20)} ${text?.slice(20, 24)} ${text?.slice(24, 28)} ${text?.slice(28, 32)}`
    }

    return (
        <div className={classes.paymentWrapper}>
            <div style={{position: 'absolute', right: 0, top: 24}}>
                <AddButton text={'Add Card'} handleClick={() => handleOpenClose('', 'add')}/>
            </div>
            <Line height={'960px'}/>
            <div className={classes.paymentContentWrapper}>
                <div className={classes.paymentItemWrapper}>
                    <div className={classes.paymentHeader}>
                        <p style={{width: '40%'}} className={globalText.smallText}>Fuel Card Number</p>
                        <p style={{width: '50%'}} className={globalText.smallText}>Comment</p>
                        <p style={{width: '10%'}} className={globalText.smallText}>Action</p>
                    </div>
                    <div style={{padding: 0}} className={classes.paymentBody}>
                        {info?.fuels?.length ? info?.fuels.map((i, k) => (
                                <div key={k} className={classes.bodyItem}>
                                    <div className={classes.cardRow}>
                                        <img src={Images.card} alt="card"/>
                                        <span>
                                            <TextRow name={returnCard(i?.cardNumber + '')}/>
                                        </span>
                                    </div>
                                    <div className={classes.commentRow}>
                                        <TextRow name={i?.comment}/>
                                    </div>
                                    <div className={classes.actionsRow}>
                                        <EditButton
                                            handleClick={() => handleOpenCardDel('edit', i)}
                                            icon='edit'
                                        />
                                        <DeleteButton
                                            styles={{marginLeft: '12px'}}
                                            toolTipTitle={"Remove "}
                                            handleClick={() => handleOpenCardDel('', i)}
                                        />
                                    </div>
                                </div>
                            ))
                            :
                            <div className={classes.noInfoYet}>
                                <NoYet text={'No Cards Yet'}/>
                            </div>
                        }
                    </div>
                </div>

                <div className={classes.spaceBetween}>
                    <div style={{width: '49%'}} className={classes.paymentItemWrapper}>
                        <div className={classes.paymentHeader}>
                            <p className={globalText.smallText}>Payment Method</p>
                            {checkRole &&
                                <EditSaveButtons
                                    type={'CARRIER_PAYMENT'}
                                    icon={'edit'}
                                    handleChancel={() => handleChancel('paymentMethod')}
                                    handleSetEdit={() => handleChange('paymentMethod', false)}
                                    handleSaveInfo={() => handleSave('paymentMethod')}
                                />
                            }
                        </div>
                        <div className={classes.paymentBodyHeight}>
                            <div className={classes.radioBorder}>
                                <RadioButtons
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    handleSelect={() => setPayment("WIRE")}
                                    one={true}
                                    check={payment === 'WIRE'}
                                    label={'Wire'}
                                />
                            </div>
                            <div className={classes.radioBorder}>
                                <RadioButtons
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    handleSelect={() => setPayment("EFS")}
                                    one={true}
                                    check={payment === 'EFS'}
                                    label={'EFS'}
                                />
                            </div>
                            <div className={classes.radioBorder}>
                                <RadioButtons
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    handleSelect={() => setPayment("CHECK")}
                                    one={true}
                                    check={payment === 'CHECK'}
                                    label={'Check'}
                                />
                            </div>
                            <div className={classes.radioBorder}>
                                <RadioButtons
                                    handleSelect={() => setPayment('ACH')}
                                    one={true}
                                    check={payment === 'ACH'}
                                    label={'ACH'}
                                    disabled={inputs?.paymentMethod || !accNumber ? true : !routNumber || !checkRole}
                                />
                                <ValidationInput
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    className={info && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"accountNumber*"}
                                    label={"Account Number"}
                                    type={'text'}
                                    typeError={
                                      error === 'accountNumber' ? ErrorText.field :
                                        beckError?.error === "If the payment method is ACH, the routing and account numbers are requred" ? ErrorText.field :
                                        ''}
                                    onChange={(ev) => setAccNumber(ev.target.value)}
                                    value={accNumber}

                                />
                                <ValidationInput
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    className={info && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"routingNumber*"}
                                    label={"Routing Number"}
                                    type={'number'}
                                    typeError={
                                       error === 'routingNumber' ? ErrorText.field :
                                         beckError?.error === "If the payment method is ACH, the routing and account numbers are requred" ? ErrorText.field :
                                         ''}
                                    onChange={(ev) => setRoutNumber(ev.target.value)}
                                    value={routNumber}
                                />
                            </div>

                            <div className={classes.radioBorder}>
                                <RadioButtons
                                    handleSelect={() => setPayment('FACTORED')}
                                    one={true}
                                    check={payment === 'FACTORED'}
                                    label={'Factoring Company'}
                                    disabled={inputs?.paymentMethod || !factoring ? true : !checkRole}
                                />
                                <SelectInput
                                    disabled={inputs?.paymentMethod || !checkRole}
                                    name={"factoringId"}
                                    label={"Factoring Company"}
                                    handleSelect={(ev) => setFactoring(ev.target.value)}
                                    value={factoring}
                                    list={factoringsList?.factorings ? factoringsList?.factorings : []}
                                    typeError={error === 'factoringId' ? ErrorText.field : ''}
                                    className={info && globalStyles.inputTextFieldBlue}
                                    type={'id'}
                                />
                            </div>

                        </div>
                    </div>

                    <div style={{width: '49%'}} className={classes.paymentContent}>
                        <div className={classes.paymentItemWrapper}>
                            <div className={classes.paymentHeader}>
                                <p className={globalText.smallText}>Payment Term </p>

                                {checkRole &&
                                    <EditSaveButtons
                                        type={'CARRIER_PAYMENT_TERM'}
                                        icon={'edit'}
                                        handleChancel={() => handleChancel('paymentTerm')}
                                        handleSetEdit={() => handleChange('paymentTerm', false)}
                                        handleSaveInfo={() => handleSave('paymentTerm')}
                                    />
                                }
                            </div>
                            <div className={classes.paymentBodyHeight} style={{height:'auto'}}>
                                <div className={classes.radioBorder}>
                                    <RadioButtons
                                        disabled={inputs?.paymentTerm || !checkRole || !percent}
                                        handleSelect={() => setTerm('CUSTOM_QUICKPAY')}
                                        check={term === 'CUSTOM_QUICKPAY'}
                                        one={true}
                                        label={'Custom Quick Pay'}
                                    />
                                    <ValidationInput
                                        disabled={inputs?.paymentTerm || !checkRole}
                                        className={info && globalStyles.inputTextFieldBlue}
                                        variant={"outlined"}
                                        name={"percent*"}
                                        label={"Percent"}
                                        type={'number'}
                                        typeError={error === 'percent' ? ErrorText.field : ''}
                                        onChange={(ev) => ev.target.value <= 100 && setPercent(ev.target.value)}
                                        value={percent}

                                    />
                                </div>
                                <div className={classes.radioBorder}>
                                    <RadioButtons
                                        disabled={inputs?.paymentTerm || !checkRole}
                                        handleSelect={() => setTerm('THIRTYDAY')}
                                        check={term === 'THIRTYDAY'}
                                        one={true}
                                        label={'30 Day (No Percent)'}
                                    />
                                </div>
                                <div className={classes.radioBorder}>
                                    <RadioButtons
                                        disabled={inputs?.paymentTerm || !checkRole}
                                        handleSelect={() => setTerm('QUICKPAY')}
                                        check={term === 'QUICKPAY'}
                                        one={true}
                                        label={'Quick Pay (3.5%)'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <div>
                        {open === 'delete' || open === 'deleteCard' ?
                            <DeleteElement
                                loading={open === 'deleteCard' ? !!loaderCard?.length : loader}
                                text={open === 'deleteCard' ? 'Remove Fuel Card?' : 'Remove Factoring?'}
                                handleClose={handleOpenClose}
                                handleDel={deleteFactoring}
                                info={open === 'deleteCard' ? '' : facInfo?.name}
                            />
                            :
                            <FuelCard
                                id={params?.id}
                                info={cardInfo}
                                handleClose={handleOpenClose}
                            />
                        }
                    </div>
                }
            />
        </div>
    )
}