import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {FullTable, TableWrapper} from "components";
import {ACTION_TYPE, PROCESSING, processingBody, processingHead, SEND_TO_BILLING} from "./constants";
import {authService} from "../../store/loads/loads.service";

export const Processing = ({}) => {
    const MINI_LOAD = 'MINI_LOAD'
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        return filteredInfo
    }

    useEffect(() => {
        handleGet('loading')
        const interval = setInterval(() => {
            handleGet('noLoad')
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad'
        const filteredInfo = renderParams()
        dispatch(loadActions.getLoadsByStatus({...filteredInfo, department: PROCESSING}, loader))
    }

    const handleSubmit = async (item, name) => {
        dispatch(httpRequestsOnSuccessActions.appendSuccess(MINI_LOAD, item.id))
        if (name === 'sendToBilling') {
            try {
                await LoadServices.LoadToBilling(item.id)
                dispatch(httpRequestsOnSuccessActions.removeSuccess(MINI_LOAD))
                dispatch(httpRequestsOnSuccessActions.appendSuccess(SEND_TO_BILLING))
                handleGet('noLoad')
            } catch (e) {
                dispatch(httpRequestsOnSuccessActions.removeSuccess(MINI_LOAD))
            }
        } else {
            try {
                await authService.sendToPayableService(item.id)
                dispatch(httpRequestsOnSuccessActions.removeSuccess(MINI_LOAD))
                handleGet('noLoad')
            } catch (e) {
                dispatch(httpRequestsOnSuccessActions.removeSuccess(MINI_LOAD))
            }
        }
    }

    const pushPageDetails = (id) => {
        history.push({
            pathname: `/load/${id}`,
            state: {loadParams: {...info}, parent: '/processing'}
        })
    }

    return (
        <div>
            <TableWrapper
                buttonsTab={true}
                link={true}
                tab={'none'}
                buttonsTabAddButton={false}
            >
                <FullTable
                    head={processingHead}
                    body={processingBody}
                    loadingType={ACTION_TYPE}
                    list={loadsList?.loads}
                    listCount={loadsList?.count}
                    handleClick={pushPageDetails}
                    noText={'Processing'}
                    dateFilter={true}
                    handleClickButton={handleSubmit}
                />
            </TableWrapper>
        </div>
    )
}