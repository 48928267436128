import React, { useEffect,  } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable, TableWrapper } from 'components';
import { ACTION_TYPE, consignBody, consignHead, CONSIGNMENT, navigateToLoad } from './constants';
import { loadActions } from 'store';
import { userType } from 'utils';

export const Consign = ({}) => {
  const { loadsList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    const filteredInfo = {
      ...info,
      department: CONSIGNMENT,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }));
    const interval = setInterval(() => {
      dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, 'noLoad'));
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  return (
    <div>
      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={consignHead}
          body={consignBody}
          loadingType={ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          clickType={'item'}
          handleClick={(item) => navigateToLoad(item, history, info, '/consign')}
          noText={'Credits'}
          dateFilter={true}
        />
      </TableWrapper>
    </div>
  );
};