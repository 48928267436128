import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./equipment.service";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    CREATE_EQUIPMENT, DELETE_EQUIPMENT,
    EDIT_EQUIPMENT, EDIT_EQUIPMENT_FROM_LIST,
    GET_EQUIPMENT,
    GET_EQUIPMENT_BY_ID, GET_EQUIPMENT_BY_ID_SUCCESS,
    GET_EQUIPMENT_SUCCESS, SET_EQUIPMENT_STATUS, SET_FAVORITE, UNSET_FAVORITE,
} from "./equipment.types";
import {GET_MY_PROFILE} from "../auth/auth.types";

/** Create, Edit Carrier */

function* createEquipment({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createEquipmentService, payload?.body);
        yield put({
            type: GET_EQUIPMENT,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}


function* editEquipment({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editEquipmentService, payload.id, payload.body);
        yield put({
            type: GET_EQUIPMENT_BY_ID,
            payload: {id: payload.id, load: 'noLoad'},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

function* editEquipmentList({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editEquipmentService, payload.id, payload.body);
        yield put({
            type: GET_EQUIPMENT,
            payload: { load: 'noLoad', params: payload?.params },
        });
        yield put(httpRequestsOnSuccessActions.removeSuccess("MINI_LOAD"));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess("MINI_LOAD"));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

/** End */

/** Get Carriers */

function* getEquipment({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getEquipmentsService, payload?.params);
        yield put({
            type: GET_EQUIPMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* getEquipmentById({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getEquipmentsByIdService, payload?.id, payload?.params);
        yield put({
            type: GET_EQUIPMENT_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

/** End */

/** Activate or Inactivate Equipment */

function* setEquipmentStatus(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        yield call(authService.setEquipmentStatusService, action?.payload?.id, action?.payload?.status);
        yield put({
            type: GET_EQUIPMENT,
            payload: { load: 'noLoad', params: action?.payload?.params },
        });
        yield put(httpRequestsOnSuccessActions.removeSuccess("MINI_LOAD"));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess("MINI_LOAD"));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

/** End */

/** Remove Carriers */

function* deleteEquipment({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.deleteEquipmentService, payload?.id);
        yield put({
            type: GET_EQUIPMENT,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

/** End */

/** Favorite */

function* setFavorite({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.setFavoriteService, payload?.id);
        yield put({
            type: GET_MY_PROFILE,
        });
        yield put({
            type: GET_EQUIPMENT,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* unsetFavorite({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.unsetFavoriteService, payload?.id);
        yield put({
            type: GET_MY_PROFILE,
        });
        yield put({
            type: GET_EQUIPMENT,
            payload: {params: payload?.params, load: 'noLoad'},
        });
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

/** End */

export const watchEquipment = function* watchEquipmentSaga() {
    /** Create, Edit Equipment */
    yield takeLatest(CREATE_EQUIPMENT, createEquipment);
    yield takeLatest(EDIT_EQUIPMENT, editEquipment);
    yield takeLatest(EDIT_EQUIPMENT_FROM_LIST, editEquipmentList);
    /** End */

    /** Activate or Inactivate Office */
    yield takeLatest(SET_EQUIPMENT_STATUS, setEquipmentStatus)
    /** End */

    /** Get Equipments */
    yield takeLatest(GET_EQUIPMENT, getEquipment);
    yield takeLatest(GET_EQUIPMENT_BY_ID, getEquipmentById);
    /** End */

    yield takeLatest(DELETE_EQUIPMENT, deleteEquipment)

    /** Favorite */
    yield takeLatest(SET_FAVORITE, setFavorite)
    yield takeLatest(UNSET_FAVORITE, unsetFavorite)
    /** End */
};
