import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, FindLoad, PermissionsList, RolePermission, useGlobalStyles, useGlobalText } from 'utils';
import { adminActions, adminAuthService, httpRequestsOnLoadActions, httpRequestsOnSuccessActions } from 'store';
import { AddModalButton, Circle, DocumentsComponent } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { CreateAdminInputs } from '../createAdmin';
import { Access } from './core/access';

const ADD_HR_DOCK = 'ADD_HR_DOCK';
const DELETE_HR_DOCK = 'DELETE_HR_DOCK';

export const AdminInfoTable = ({ handleChangeName, handleChangeLast }) => {
  const { adminInfoById, assignedRoleList, rolesList } = useSelector((state) => ({
      adminInfoById: state.admins.adminInfoById,
      assignedRoleList: state.admins.assignedRoleList,
      rolesList: state.roles.rolesList,
    }),
  );
  const dispatch = useDispatch();
  const globalStyle = useGlobalText();
  const globalInputs = useGlobalStyles();
  const loader = FindLoad('RESEND_ADMIN_INVITE');
  const [docs, setDocks] = useState([])
  const info = adminInfoById;

  useEffect(() => {
    if (info && info.documents) {
      setDocks(info.documents);
    }
  }, [info]);

  const handleChange = async (img) => {
    try {
      await adminAuthService.addHrDocument(img, info.id);
      dispatch(httpRequestsOnLoadActions.removeLoading(ADD_HR_DOCK));
      dispatch(adminActions.getAdminById(info.id, 'noLoad'));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(ADD_HR_DOCK));
    }
  };

  const handleRemoveDoc = async (doc) => {
    try {
      dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_HR_DOCK));
      await adminAuthService.deleteHrDocument(info.id, doc?.id);
      dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_HR_DOCK));
      dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_HR_DOCK));
      dispatch(adminActions.getAdminById(info.id, 'noLoad'));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_HR_DOCK));
    }
  };

  const resendInvite = () => {
    dispatch(adminActions.resendAdminInvite(info.id));
  };


  return (
    <div className='accordion-style'>
      <div className={globalStyle.resendInvite}>
        <AddModalButton
          loading={!!loader?.length}
          handleClick={resendInvite}
          text={'Resend Invite'}
        />
      </div>
      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div className={globalInputs.centerItem}>
            {info && <Circle number={1} back={Colors.ThemeYellow} />}
            <p className={globalStyle.title}>User Information</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <CreateAdminInputs
            handleChangeFirstName={handleChangeName}
            handleChangeLastName={handleChangeLast}
            info={info}
          />
        </AccordionDetails>
      </Accordion>

      {RolePermission([PermissionsList.ADMIN_ACCESS?.code]) &&
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className={globalInputs.centerItem}>
              {info && <Circle number={2} back={Colors.ThemeYellow} />}
              <p className={globalStyle.title}>User Access</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Access list={assignedRoleList} rolesList={rolesList} />
          </AccordionDetails>
        </Accordion>
      }

      {RolePermission([PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className={globalInputs.centerItem}>
              {info && <Circle number={3} back={Colors.ThemeYellow} />}
              <p className={globalStyle.title}>Documents</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='info-table-no-line'>
              <DocumentsComponent
                actionType={ADD_HR_DOCK}
                removeActionType={DELETE_HR_DOCK}
                handleDeleteFile={handleRemoveDoc}
                handleUpload={handleChange}
                documents={docs}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      }
    </div>
  );
};