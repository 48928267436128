import { useState } from 'react';
import axios from 'axios';

const token = localStorage.getItem('access-token');

export const DownloadFile = () => {
    const [loadDownload, setLoadDownload] = useState('');

    const handleDownload = async (fullFile) => {
        const fileName = fullFile?.name ? fullFile?.name : '';
        setLoadDownload(fullFile?.file?._id);
        try {
            const response = await axios({
                url: `/files/download`,
                method: 'POST',
                responseType: 'blob',
                data: fullFile?.file,
            });
            setLoadDownload('');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (e) {
            setLoadDownload('');
        }
    };

    const handleDownloadAnotherUrl = async (fullFile) => {
        const fileName = fullFile?.name ? fullFile?.name : '';
        setLoadDownload(fullFile?.file?._id);
        try {
             axios.get(fullFile?.link, { responseType: 'blob', auth:true }).then((response) => {
                 setLoadDownload('');
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            })
        } catch (e) {
            setLoadDownload('');
        }
    };


    return {
        loadDownload,
        handleDownload,
        handleDownloadAnotherUrl,
    };
};