import {
    ADD_OFFICE_EXPENSES_SUCCESS,
    CREATE_OFFICE_MANAGERS_SUCCESS,
    GET_OFFICE_BRANCHES_SUCCESS,
    GET_OFFICE_BY_ID_SUCCESS,
    GET_OFFICES_SUCCESS,
    REMOVE_OFFICE_BRANCHES, SUBTRACT_OFFICE_EXPENSES_SUCCESS,
} from "./offices.types";
import {paginate} from "utils";

const initialState = {
    officesList: [],
    officeById: null,
    officeManagerList: [],
    officeBranchesList: [],
};

export const officeReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Offices */

        case GET_OFFICES_SUCCESS:
            return {
                ...state,
                officesList: action.payload,
            }

        case GET_OFFICE_BY_ID_SUCCESS:
            return {
                ...state,
                officeById: action.payload
            }

        /** End */


        /** Office Management */


        case CREATE_OFFICE_MANAGERS_SUCCESS: {
            return {
                officeManagerList: [...state.officeManagerList, ...action.payload]
            }
        }

        /** End */


        /** Get Office Branches */
        case GET_OFFICE_BRANCHES_SUCCESS:
            return {
                ...state,
                officeBranchesList: action.payload,
            }

      case REMOVE_OFFICE_BRANCHES:
            return {
                ...state,
                officeBranchesList: null,
            }

        /** End */

      //   /** Get Office Branches */
      //   case ADD_OFFICE_EXPENSES_SUCCESS:
      //       return {
      //           ...state,
      //           officeBranchesList: action.payload,
      //       }
      //
      // case SUBTRACT_OFFICE_EXPENSES_SUCCESS:
      //       return {
      //           ...state,
      //           officeBranchesList: null,
      //       }
      //
      //   /** End */




        default:
            return state;
    }
};
