import {reportTotalStyles} from "./styles";


export const TotalRow = ({rows}) => {
    const classes = reportTotalStyles()

    return (
        <div className={classes.totalRowWrapper}>
            <h1 className={classes.title}>Totals</h1>
            <div className={classes.rowBody}>{rows}</div>
        </div>
    )
}