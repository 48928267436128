import axios from "axios";

export const authService = {

  /** Create, Edit Branch */

  createBranchService: ( body ) => axios.post('/branches', body,{auth:true}),

  editBranchService: ( body, id ) => {
    const data ={
      "name": body.name,
      "email": body.email,
      "phoneNumber": body.phoneNumber,
      "establishedDate": body?.establishedDate,
      "address": body.address ,
    }
    return axios.patch(`/branches/${id}`, data, {auth:true});
  },

  /** End */

  /** Get Branches */

  getBranchesService: ( info ) => axios.get('/branches', {auth:true, params: { ...info } }  ),

  getBranchByIdService: ( id ) => axios.get(`/branches/${id}`, {auth:true}),

  /** End */

  /** Activate or Inactivate Branch */

  activateBranchService: ( id ) => axios.patch(`/branches/${id}/activate`, null, {auth:true}),

  inactivateBranchService: ( id ) => axios.patch(`/branches/${id}/inactivate`,null,{auth:true}),

  /** End */

  /** Branch Management */

  createBranchManagerService: ( data ) => axios.post(`/agents`, data, {auth:true}),

  selectBranchManagerService: ( data ) => axios.post(`/agents/${data.managerId}/managedBranch/${data.branchId}`, {
    // agentId:data.managerId,
    // branchId: data.branchId

  },{auth:true}),

  removeBranchManagerService: ( data ) => axios.delete(`/agents/${data.managerId}/managedBranch/${data.branchId}`, {auth:true})

  /** End */


};
