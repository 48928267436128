import {Images} from "utils";
import {IconAndTitle} from "components";

export const factoringCarriersHead = [
    {name: 'companyName', title: 'Carrier'},
    {name: 'displayId', title: 'ID', custom: false, notSearch: true},
]

export const factoringCarriersBody = [
    {rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName}/>},
    {rowText: (item) => <div>{item?.displayId || item?.id}</div>},
]

export const ACTION_TYPE = 'GET_CARRIER_FACTORING'