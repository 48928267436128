import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FindLoad } from 'utils';
import { loadActions } from 'store';
import { Loader } from 'components';
import { FactoredLoadDetails } from '../factored';
import { CreateLoadById } from './createLoadById';

const ACTION_TYPE = 'GET_LOAD_BY_ID'

export const LoadDetailSwitcher = ({}) => {
  const {loadById} = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const loader  = FindLoad(ACTION_TYPE)
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(loadActions.getLoadById(params.id));
    const interval = setInterval(() => {
      dispatch(loadActions.getLoadById(params.id, 'noLoad'));
    }, 30000);
    return () => clearInterval(interval);
  }, []);


  if(loader?.length){
    return <Loader />
  }

  return(
    <div>
      {loadById?.isFactored ? (
        <FactoredLoadDetails />
      ) : (
        <CreateLoadById />
      )}
    </div>
  )
}