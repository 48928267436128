import React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomButtonsTab } from '../../components/buttons';
import { CREDITCHECK, CONSIGNMENT } from '../accounting/constants';
import { FilterForTable } from '../../components/table/filtereForTable';
import { ConsignmentRequest } from './consignmentRequest';
import { Credit } from './credit';
import { isFactored, PermissionsList, RolePermission } from 'utils';

export const CreditPageWrapper = ({}) => {
  const history = useHistory();
  const info = history?.location?.state;
  const pushInfo = isFactored ? CREDITCHECK :
    RolePermission([
      PermissionsList.CREDIT_TAB?.code,
      PermissionsList.ACCEPT_DENY_LOAD?.code,
      PermissionsList.CREDIT_CONSIGN_TAB?.code,
      PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code,],'agentView') ? CREDITCHECK :
      RolePermission([PermissionsList.CREDIT_TAB?.code, PermissionsList.ACCEPT_DENY_LOAD?.code], 'agentView') ? CREDITCHECK :
        RolePermission([PermissionsList.CREDIT_CONSIGN_TAB?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code], 'agentView') ?
          CONSIGNMENT: '';

  return (
    <div>
      <div className="space-between">
        <CustomButtonsTab
          first={
            isFactored ? 'Credit Check' :
          RolePermission([PermissionsList.CREDIT_TAB?.code, PermissionsList.ACCEPT_DENY_LOAD?.code], 'agentView') ? 'Credit Check' : null}
          second={
            isFactored ? 'Consign. Request' :
          RolePermission([PermissionsList.CREDIT_CONSIGN_TAB?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code], 'agentView') ? 'Consign. Request' : null}
          width={'150px'}
          maxWidth={'320px'}
          firstName={CREDITCHECK}
          secondName={CONSIGNMENT}
        />
        <FilterForTable
          createPermisson={false}
          searchBtnStyles={{marginRight: 0}}
        />
      </div>

      <div>
        { (info?.tabType ? info?.tabType === CREDITCHECK : pushInfo === CREDITCHECK)  &&
          (isFactored ? true : RolePermission([PermissionsList.CREDIT_TAB?.code, PermissionsList.ACCEPT_DENY_LOAD?.code], 'agentView')) &&
          <Credit />
        }
        { (info?.tabType ? info?.tabType === CONSIGNMENT : pushInfo === CONSIGNMENT)  &&
          (isFactored ? true : RolePermission([PermissionsList.CREDIT_CONSIGN_TAB?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code], 'agentView')) &&
          <ConsignmentRequest />
        }
      </div>
    </div>
  );
};