import {
    FILTER_AGENTS_BY_ALPHABETICAL,
    FILTER_AGENTS_BY_DEFAULT,
    GET_AGENT_BY_BRANCH_SUCCESS,
    GET_AGENT_BY_ID_SUCCESS,
    GET_AGENT_BY_OFFICE_SUCCESS,
    GET_AGENTS,
    GET_AGENTS_SUCCESS,
    GET_BRACKET_SUCCESS,
    GET_BY_SUCCESS,
    GET_DEFAULT_BRACKET_SUCCESS,
    GET_MANAGER_BY_BRANCH_SUCCESS,
    GET_MANAGER_BY_OFFICE_SUCCESS, REMOVE_AGENT_BY_BRANCH,
    SEARCH_AGENT_BY_TYPE
} from "./agent.types";
import {activeInactive, paginate, searchByType, sortByAz} from "utils";

const initialState = {
    agentList: [],
    // agentListReserve: [],
    agentById: null,
    officeAgentList: [],
    officeManagersList: [],
    branchManagersList: [],
    branchAgentsList: [],
    bracket: [],
    defaultBracket: [],
    byList: []
};

export const agentReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Agents */

        case GET_BY_SUCCESS:
            return {
                ...state,
                byList: action.payload
            }
        case GET_AGENTS:
            return {
                ...state,
                adminsList: '',
            }

        case GET_AGENTS_SUCCESS:
            return {
                ...state,
                agentList: action.payload
            }

        case  GET_AGENT_BY_ID_SUCCESS:
            return {
                ...state,
                agentById: action.payload,
            }
        /** End */

        /** Activate or Inactivate Office */

        case SEARCH_AGENT_BY_TYPE:
            return {
                ...state,
                agentList: searchByType(activeInactive(state.agentListReserve, action.payload.listStatus, 'customers'), action.payload.value, action.payload.type,)
            }
        case FILTER_AGENTS_BY_ALPHABETICAL:
            return {
                ...state,
                agentList: sortByAz(activeInactive(state.agentListReserve, action.payload.listStatus, 'customers'), action.payload.name),
            }

        case FILTER_AGENTS_BY_DEFAULT:
            return {
                ...state,
                agentList: paginate((activeInactive(state.agentListReserve, action.payload.listStatus, 'customers')), 10),
            }

        /** End */

        /** Get Agents and Managers */

        case  GET_MANAGER_BY_OFFICE_SUCCESS:
            return {
                ...state,
                officeManagersList: action.payload,
            }

        case GET_MANAGER_BY_BRANCH_SUCCESS:
            return {
                ...state,
                branchManagersList: action.payload
            }


        case GET_AGENT_BY_OFFICE_SUCCESS:
            return {
                ...state,
                officeAgentList: action.payload
            }

        case GET_AGENT_BY_BRANCH_SUCCESS:
            return {
                ...state,
                branchAgentsList: action.payload
            }

        case REMOVE_AGENT_BY_BRANCH:
            return {
                ...state,
                branchAgentsList: null
            }

        /** End */

        /** Payroll Brackets */

        case GET_BRACKET_SUCCESS:
            return {
                ...state,
                bracket: action.payload
            }

        case GET_DEFAULT_BRACKET_SUCCESS:
            return {
                ...state,
                defaultBracket: action.payload
            }

        /** End */

        default:
            return state;
    }
};
