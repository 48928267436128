import React from "react";
import {Button} from "@material-ui/core";
import {Images} from "utils";
import {buttonsStyle} from "./styles";

export const RemoveButton = ({ text, handleClick }) =>{
    const classes = buttonsStyle()
    return(
        <button onClick={handleClick} className={classes.removeButton}>
            <img src={Images.clear} alt='icon'/>
            <p>{text}</p>
        </button>
    )
}