import React from 'react';
// import { useRouter } from 'next/router';

export const Text = ({ type, info, classes, handleClose, handleSeeComment }) => {
    // const router = useRouter();
    const handleGetLink = () => {
        // router.push(`singleOrganization?orgid=${info.org.id}`);
        handleClose();
    };

    return (
        <>
            {type === "CREDIT_APPROVED" ? (
                <span>
                    Your load has been approved.
                </span>
            ) : type === "CREDIT_REJECTED" ? (
                    <span>
                    Your load has been disapproved.
                    {/*<span*/}
                    {/*    style={{color: '#387DFF', cursor: 'pointer'}}*/}
                    {/*    onClick={() => (info.event && info.event.eventId ? handleSeeComment(info.event.eventId) : '')}>*/}
                    {/*    &nbsp;See the comment*/}
                    {/*</span>*/}
                </span>
                ) :
                ''
            }
        </>
    );
}
