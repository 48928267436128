import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover } from '@material-ui/core';
import { Loader, MinLoader, TextRow } from 'components';
import { loadActions } from 'store';
import { FindLoad, Images } from 'utils';
import { renderIcon } from '../../../../pages/loads/constants';

const TEMPLATE_ACTIONS = 'GET_TEMPLATES';
const CREATE_LOAD_TEMPLATE = 'CREATE_LOAD_BY_TEMPLATE';
export const CreateLoadBtn = ({}) => {
  const { templates } = useSelector((state) => ({
    templates: state.templates.templates,
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [list, setList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();
  const loader = FindLoad(CREATE_LOAD_TEMPLATE);
  const loadTemplates = FindLoad(TEMPLATE_ACTIONS);

  useEffect(() => {
    if (templates?.items) {
      setList(templates?.items);
    }
  }, [templates]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateLoad = (item) => {
    setSelectedId(item?.id);
    dispatch(loadActions.createLoadTemplate(item?.id));
  };

  const handleChange = (e) => {
    if (e.target.value) {
      const newList = templates?.items?.filter((el) => el?.name?.toString().toLowerCase().indexOf(e.target.value?.toString().toLowerCase()) > -1);
      setList(newList);
    } else {
      setList(templates?.items);
    }
  };

  return (
    <div>
      <div className="add-load-btn">
        <button onClick={() => window.location.replace('/createLoad')}>
          <p className="plus"> + </p>
          <p className="add-text">Add Load</p>
        </button>
        <Button
          style={{
            width: '50px',
            padding: 0,
            minWidth: '50px',
            borderLeft: ' 1px solid #03BD4E',
            height: '36px',
          }}
          aria-describedby={id} onClick={handleClick} className="popper-btn"
        >
          <img src={Images.downWhite} alt="icon" />
        </Button>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="load-templates-popper">
          <p className="choose-title">Choose from Templates</p>
          <div className="search-input-wrapper">
            <input type="text" onChange={handleChange} />
            <div className="search-input-image">
              <img src={Images.searchIcon} alt="icon" />
            </div>
          </div>
          <div className="templates-row-body">
            {loadTemplates?.length ?
              <Loader height={'100%'} />
              :
              list.length ?
                list?.map((i, j) => (
                  <div key={j} className="templates-row" onClick={() => handleCreateLoad(i)}>
                    <div>
                      {loader?.length && selectedId === i?.id ?
                        <MinLoader color={'#00C851'} position={'relative'} margin={'0 3px 0 0'} />
                        :
                        renderIcon(i?.color)
                      }
                    </div>
                    <p>
                      <TextRow name={i?.name} />
                    </p>
                  </div>
                ))
                :
                <p>No Templates </p>
            }
          </div>
        </div>
      </Popover>
    </div>
  );
};