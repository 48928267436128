import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CustomSelect, ValidationInput} from "components";
import {ErrorText, FindLoad, FindSuccess, useGlobalTextStyles, useModal} from 'utils'
import {equipmentActions, httpRequestsOnSuccessActions} from "store";

export const serviceType = [
    {name: 'Solo', id: 'SOLO'},
    {name: 'Team', id: 'TEAM'},
]

export const equipmentType = [
    {name: 'Dry Van', id: 'DRYVAN'},
    {name: 'Reefer', id: 'REEFER'},
    {name: 'Flatbed', id: 'FLATBED'},
    {name: 'Power Only', id: 'POWER_ONLY'},
    {name: 'Drayage', id: 'DRAYAGE'},
    {name: 'Box Truck', id: 'BOX_TRUCK'},
    {name: 'Sprinter Van', id: 'SPRINTER_VAN'},
    {name: 'Warehouse', id: 'WAREHOUSE'},
    {name: 'Hot Shot', id: 'HOT_SHOT'},
    {name: 'Step Deck', id: 'STEP_DECK'},
]


export const trailerLength = [
    {name: 20, id: 20},
    {name: 24, id: 24},
    {name: 26, id: 26},
    {name: 40, id: 40},
    {name: 48, id: 48},
    {name: 53, id: 53},
]

export const AddEquipmentModal = ({info, sendParams, id}) => {
    const actionType = info ? 'EDIT_EQUIPMENT' : 'CREATE_EQUIPMENT'
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch()
    const loading = FindLoad(actionType)
    const success = FindSuccess(actionType)
    const {close} = useModal()
    const [inputs, setInputs] = useState(info ? info : {});
    const [error, setError] = useState('')

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType))
            close()
        }
    }, [success])

    const create = () => {
        const data = {
            "name": inputs?.name,
            "carrierId": id,
            "serviceType": inputs?.serviceType,
            "equipmentType": inputs?.equipmentType,
            // "trailerLength": inputs?.trailerLength,
        }

        inputs?.trailerLength ? data.trailerLength = inputs?.trailerLength : delete data?.trailerLength

        const checkTrailerAge = (inputs?.equipmentType === 'DRAYAGE' || inputs?.equipmentType === 'POWER_ONLY' || inputs?.equipmentType === 'SPRINTER_VAN') ? true : inputs?.trailerLength

        if (inputs?.name && inputs?.serviceType && inputs?.equipmentType && checkTrailerAge) {
            if (info) {
                dispatch(equipmentActions.editEquipment(info.id, data, 'noLoad'))
            } else {
                dispatch(equipmentActions.createEquipment(id, data, sendParams))
            }
        } else {
            setError(
                !inputs.name ? 'name' :
                    !inputs.serviceType ? 'serviceType' :
                        !inputs.equipmentType ? 'equipmentType' :
                            !inputs.trailerLength ? 'trailerLength' :
                                ''
            )
        }
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
        error && setError('')
    }

    return (
        <div>
            <p className={globalText.modalTitle}>{info ? 'Edit Equipment' : 'Want to Add Equipment?'}</p>
            <p className={globalText.modalText}>{!info && 'Please fill out the fields below fields to add equipment.'}</p>
            <ValidationInput
                onChange={handleChange}
                value={inputs.name}
                typeError={error === 'name' && ErrorText.field}
                label={"Name*"}
                type={"text"}
                variant={"outlined"}
                name={"name"}
            />
            <CustomSelect
                selectedValue={'id'}
                showValue={'name'}
                name={"serviceType"}
                label={"Service Type*"}
                handleSelect={handleChange}
                value={inputs.serviceType}
                list={serviceType}
                typeError={error === 'serviceType' && ErrorText.field}
            />
            <CustomSelect
                selectedValue={'id'}
                showValue={'name'}
                name={"equipmentType"}
                label={"Equipment Type*"}
                handleSelect={handleChange}
                value={inputs.equipmentType}
                list={equipmentType}
                typeError={error === 'equipmentType' && ErrorText.field}
            />
            <CustomSelect
                selectedValue={'id'}
                showValue={'name'}
                name={"trailerLength"}
                label={"Trailer Length*"}
                handleSelect={handleChange}
                value={inputs.trailerLength}
                list={trailerLength}
                typeError={error === 'trailerLength' && ErrorText.field}
            />
            {/*<CustomSelect*/}
            {/*    selectedValue={'id'}*/}
            {/*    showValue={'name'}*/}
            {/*    name={"trailerLength"}*/}
            {/*    label={"Trailer Length*"}*/}
            {/*    handleSelect={handleChange}*/}
            {/*    value={inputs.trailerLength}*/}
            {/*    list={trailerLength}*/}
            {/*    typeError={error === 'trailerLength' && ErrorText.field}*/}
            {/*/>*/}
            <AddModalButton
                loading={!!loading?.length}
                handleClick={create}
                text={info ? 'Save' : 'Add'}
            />
        </div>
    )
}