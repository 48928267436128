import moment from "moment";
import React from "react";
import {inputsStyle} from "./styles";


export const CalendarInput = ({ handleChangeDate, value, name, type }) => {
    const classes = inputsStyle()

    return(
            <input
                value={value ? moment(value).format('YYYY-MM' ) : ''}
                name={name}
                className={classes.calendarInput}
                type={type ? type : 'month'}
                onChange={handleChangeDate}
            />
    )
}