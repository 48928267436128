
/**Create Agent */
export const CREATE_AGENT = 'CREATE_AGENT';
export const EDIT_AGENT = 'EDIT_AGENT';

export const RESEND_AGENT_INVITE = 'RESEND_AGENT_INVITE';

/**Get Agents */
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';

/**Get Agent by id */
export const GET_AGENT_BY_ID = 'GET_AGENT_BY_ID';
export const GET_AGENT_BY_ID_SUCCESS = 'GET_AGENT_BY_ID_SUCCESS';

/**Get Agent by id */
export const ACTIVATE_AGENT = 'ACTIVATE_AGENT';
export const INACTIVATE_AGENT = 'INACTIVATE_AGENT';

/**Get Agent by Office Id */
export const GET_AGENT_BY_OFFICE = 'GET_AGENT_BY_OFFICE';
export const GET_AGENT_BY_OFFICE_SUCCESS = 'GET_AGENT_BY_OFFICE_SUCCESS';

export const GET_MANAGER_BY_OFFICE = 'GET_MANAGER_BY_OFFICE';
export const GET_MANAGER_BY_OFFICE_SUCCESS = 'GET_MANAGER_BY_OFFICE_SUCCESS';

/**Get Agent by Branch Id */
export const GET_AGENT_BY_BRANCH = 'GET_AGENT_BY_BRANCH';
export const GET_AGENT_BY_BRANCH_SUCCESS = 'GET_AGENT_BY_BRANCH_SUCCESS';
export const REMOVE_AGENT_BY_BRANCH = 'REMOVE_AGENT_BY_BRANCH';
export const GET_MANAGER_BY_BRANCH = 'GET_MANAGER_BY_BRANCH';
export const GET_MANAGER_BY_BRANCH_SUCCESS = 'GET_MANAGER_BY_BRANCH_SUCCESS';

/**Agents Filters*/
export const SEARCH_AGENT_BY_TYPE = 'SEARCH_AGENT_BY_TYPE';
export const FILTER_AGENTS_BY_ALPHABETICAL = 'FILTER_AGENTS_BY_ALPHABETICAL';
export const FILTER_AGENTS_BY_DEFAULT = 'FILTER_AGENTS_BY_DEFAULT';

/** Payroll Brackets */
export const ADD_BRACKET = 'ADD_BRACKET'
export const EDIT_AGENT_BRACKET = 'EDIT_AGENT_BRACKET'
export const GET_BRACKET = 'GET_BRACKET'
export const DELETE_BRACKET = 'DELETE_BRACKET'
export const GET_AGENT_BY_ID_BRACKET = 'GET_AGENT_BY_ID_BRACKET'
export const GET_BRACKET_SUCCESS = 'GET_BRACKET_SUCCESS'
export const GET_DEFAULT_BRACKET = 'GET_DEFAULT_BRACKET'
export const GET_DEFAULT_BRACKET_SUCCESS = 'GET_DEFAULT_BRACKET_SUCCESS'
export const CHANGE_DEFAULT_BRACKET = 'CHANGE_DEFAULT_BRACKET'

export const GET_BY = 'GET_BY'
export const GET_BY_SUCCESS = 'GET_BY_SUCCESS'