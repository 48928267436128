import React, {Fragment, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Container, Button} from "@material-ui/core";
import {Images} from "utils";
import {SideMenu} from "./sideMenu";

export const HeaderCore = ({handleOpenCloseDel}) => {
    const history = useHistory();
    const [said, setSaid] = useState(false);
    const [state, setState] = useState({
        top: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({...state, [anchor]: open});
        setSaid(!said);
    };

    return (
        <Fragment>
            <header className="header-section">
                <Container maxWidth="lg">
                    <div className="header-container">
                        <div className="header-box">
                            <div className="box-logo">
                                <Link to={"/"}>
                                    <img src={Images.LogoSvg} alt="Logo"/>
                                </Link>
                            </div>
                            <div className="header-links">
                                <Button
                                    onClick={() => window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })}
                                    variant="text"
                                    className="dark-link not-hover"
                                >
                                    Home
                                </Button>
                                <a href="#aboutUs">
                                    <Button variant="text" className="dark-link not-hover">
                                        About us
                                    </Button>
                                </a>
                                <a href="#services">
                                    <Button variant="text" className="dark-link not-hover">
                                        Services
                                    </Button>
                                </a>
                                <a href="#fetures">
                                    <Button variant="text" className="dark-link not-hover">
                                        Features
                                    </Button>
                                </a>
                                <Button
                                    variant="text"
                                    className="dark-link not-hover"
                                    onClick={handleOpenCloseDel}
                                >
                                    Contact us
                                </Button>
                                <Button
                                    variant="contained"
                                    className="dark-link blue-btn"
                                    onClick={() => history.push("/logIn")}
                                >
                                    Sign In
                                </Button>
                                <div className="social-box">
                                    <a href="https://www.instagram.com/polo_tms/" target="_blank">
                                        <img src={Images.Instagram} alt="Instagram"/>
                                    </a>
                                    <a href="https://www.facebook.com/polotms" target="_blank">
                                        <img src={Images.Facebook} alt="Facebook"/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/polo-tms-165180258" target="_blank">
                                        <img src={Images.Linkedin} alt="Twitter"/>
                                    </a>
                                </div>
                            </div>
                            <div className="side-burger">
                                <Button onClick={toggleDrawer("top", true)}>
                                    {said ? (
                                        <img src={Images.CloseIcon} alt="Close-Icon"/>
                                    ) : (
                                        <img src={Images.BurgerMenu} alt="Burger-Menu"/>
                                    )}
                                </Button>
                                <div className="side-menu">
                                    <SideMenu
                                        anchor={"top"}
                                        saidOpen={said}
                                        toggleDrawer={toggleDrawer}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
        </Fragment>
    );
};
