import React from "react";
import {AuthorityInfoTable} from "./core/authorityInfoTable";

export const AuthorityInfo = ({setName}) => {

    const handleChangeName = (ev) => {
        setName(ev)
    }

    return (
        <div>
            <AuthorityInfoTable
                handleChangeName={handleChangeName}
            />
        </div>
    );
}
