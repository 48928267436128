import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./role.service";
import {
    ADD_ROLE_PERMISSION,
    CREATE_ROLE,
    DELETE_ROLE,
    DELETE_ROLE_PERMISSION,
    EDIT_ROLE,
    GET_ROLE,
    GET_ROLE_BY_ID,
    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_SUCCESS
} from "./role.types";
import {roleActions} from "./index";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

function* createRole(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createRoleService, action.payload.body);
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_ROLE,
            payload: {load: 'noLoad'},
        });
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err.data.message));
    }
}

function* editRole(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editRoleService, action.payload.body, action.payload.id);
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_ROLE,
            payload: {load: 'noLoad'},
        });
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err.data.message));
    }
}

function* getRole(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getRoleService, action.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_ROLE_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* deleteRole(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteRoleService, action.payload.id);
        yield put({
            type: GET_ROLE,
            payload: {load: 'noLoad'},
        })
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getRoleById(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getRoleByIdService, action.payload.id);
        yield put({
            type: GET_ROLE_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}


function* addRolePermission(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    try {
        const res = yield call(authService.addRolePermissionService, action.payload.body);
        yield put({
            type:GET_ROLE_BY_ID_SUCCESS,
            payload: res.data
        })
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    }
}

function* deleteRolePermission(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.deleteRolePermissionService, action.payload.data);
        yield put({
            type:GET_ROLE_BY_ID_SUCCESS,
            payload: res.data
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    }
}

export const watchRole = function* watchRoleSaga() {
    yield takeLatest(CREATE_ROLE, createRole);
    yield takeLatest(EDIT_ROLE, editRole);
    yield takeLatest(GET_ROLE, getRole);
    yield takeLatest(DELETE_ROLE, deleteRole);
    yield takeLatest(GET_ROLE_BY_ID, getRoleById);
    yield takeLatest(ADD_ROLE_PERMISSION, addRolePermission)
    yield takeLatest(DELETE_ROLE_PERMISSION, deleteRolePermission)
};
