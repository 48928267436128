import React from "react";
import { CarrierInfoTable} from "./core";

export const CarriersInfo = ({info, setCarrierName}) => {
    return (
        <CarrierInfoTable
            handleChangeName={setCarrierName}
            info={info}
        />
    );
}
