import React from "react";
import {useSelector} from "react-redux";
import {ArDetailed, ArSummary, ArActivity, ArApplication} from "fragments";
import {ReportTabs} from "components";
import {PermissionsList, RolePermission} from "utils";

export const ArReport = ({}) => {
    const {arDetailed, arSummary, arActivity, arApplication} = useSelector((state) => ({
        arDetailed: state.reports.arDetailed,
        arSummary: state.reports.arSummary,
        arActivity: state.reports.arActivity,
        arApplication: state.reports.arApplication,
    }))


    const tabsLabels = [
        RolePermission([PermissionsList?.READ_AR_DETAILED_REPORT?.code]) &&
        {
            label: 'AR Detailed Report'
        },
        RolePermission([PermissionsList?.READ_AR_SUMMARY_REPORT?.code]) &&
        {
            label: 'AR Summary'
        },
        RolePermission([PermissionsList?.READ_AR_ACTIVITY_REPORT?.code]) &&
        {
            label: 'AR Activity'
        },
        RolePermission([PermissionsList?.READ_AR_PAYMENT_APPLICATION_REPORT?.code]) &&
        {
            label: 'Payment Application'
        },
    ]

    const tabsContent = [
        RolePermission([PermissionsList?.READ_AR_DETAILED_REPORT?.code]) &&
        {
            tabComponent: <ArDetailed arDetailed={arDetailed}/>,
            tab: 'AR Detailed Report'
        },
        RolePermission([PermissionsList?.READ_AR_SUMMARY_REPORT?.code]) &&
        {
            tabComponent: <ArSummary arSummary={arSummary}/>,
            tab: 'AR Summary'
        },
        RolePermission([PermissionsList?.READ_AR_ACTIVITY_REPORT?.code]) &&
        {
            tabComponent: <ArActivity arActivity={arActivity}/>,
            tab: 'AR Activity'
        },
        RolePermission([PermissionsList?.READ_AR_PAYMENT_APPLICATION_REPORT?.code]) &&
        {
            tabComponent: <ArApplication arApplication={arApplication}/>,
            tab: 'Payment Application'
        }
    ];

    return (
        <div>
            <ReportTabs
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                defaultTab={
                    RolePermission([PermissionsList?.READ_AR_DETAILED_REPORT?.code]) ? 'AR Detailed Report' :
                        RolePermission([PermissionsList?.READ_AR_SUMMARY_REPORT?.code]) ? 'AR Summary' :
                            RolePermission([PermissionsList?.READ_AR_ACTIVITY_REPORT?.code]) ? 'AR Activity' :
                                RolePermission([PermissionsList?.READ_AR_PAYMENT_APPLICATION_REPORT?.code]) ? 'Payment Application' :
                                    'AR Detailed Report'
                }
            />
        </div>
    )
}