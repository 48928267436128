import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reportActions} from "store";
import {ButtonsTab, CalendarInput, ExportButton, FullTable, GenerateButton, Loader, useWidth} from "components";
import {AR_DETAILED_REQUEST_TYPE, arDetailBody, arDetailHead} from "./constants";
import {reportStyles} from "../styles";
import { DownloadFile, FindLoad } from 'utils';
import {Row, TotalRow} from "../core";

export const ArDetailed = ({arDetailed}) => {
    const classes = reportStyles()
    const [date, selectedDate] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const loader = FindLoad(AR_DETAILED_REQUEST_TYPE)
    const info = history?.location?.state
    const width = useWidth();
    const max = width > 1900 ? width - 320 : width - 250
    const tableWidth = width > 1900 ? width + 500 : width + 1000
    const { loadDownload, handleDownload, } = DownloadFile()

    useEffect(() => {
        if (info?.status) {
            dispatch(reportActions.cleanArDetailReport())
        }
    }, [info])

    const handleGetDate = (sendInfo) => {
        dispatch(reportActions.getArDetailReport(sendInfo, info?.status))
    }

    const handleChangeDate = (e) => {
        selectedDate(e.target.value)
        const sendInfo = {}
        if (e.target.value) {
            sendInfo.month = e.target.value.slice(6, 8)
            sendInfo.year = e.target.value.slice(0, 4)
        } else {
            delete sendInfo.month
            delete sendInfo.year
        }
        handleGetDate(sendInfo)
    }

    return (
        <div>
            <div>
                <div className={classes.actionsWrapper}>
                    <ButtonsTab
                        maxWidth={'320px'}
                        width={'150px'}
                        first={'Current'}
                        second={'Past Reports'}
                        type={'report'}
                    />
                    <div className={classes.exportAndCalendar}>
                        {info?.status === 'Past Reports' ?
                            <CalendarInput
                                value={date}
                                handleChangeDate={handleChangeDate}
                            />
                            :
                            <GenerateButton handleGenerate={() => handleGetDate()}/>
                        }
                        {arDetailed?.file?.url &&
                             <ExportButton
                               loader={!!loadDownload}
                               handleExport={() => handleDownload({file:arDetailed?.file, name: 'ARDetailCurrent.csv'})}
                             />
                        }
                    </div>
                </div>
            </div>
            {loader?.length ?
                <Loader/>
                :
                <div style={{maxWidth: max}} className={'report'}>
                    <div style={{width: tableWidth}} className={'report-detail-wrapper'}>
                        <TotalRow
                            rows={
                                <>
                                    <Row name={'Invoice Total'}
                                         value={arDetailed?.totalInvoiced ? arDetailed.totalInvoiced : 0}
                                    />
                                    <Row name={'Paid'}
                                         value={arDetailed?.totalPaid ? arDetailed.totalPaid : 0}
                                    />
                                    <Row name={'Balance'}
                                         value={arDetailed?.totalBalance ? arDetailed.totalBalance : 0}
                                    />
                                </>
                            }
                        />
                        <div className={'full-table'}>
                            <FullTable
                                head={arDetailHead}
                                body={arDetailBody}
                                list={arDetailed?.data?.length ? arDetailed?.data : []}
                                noText={'AR Detailed'}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}