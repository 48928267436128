import React from "react";
import {CustomerInfoTable} from "./core";

export const CustomerInfo = ({ setCustomerName}) => {
    return (
        <div>
            <CustomerInfoTable
              handleChangeName={setCustomerName}
            />
        </div>
    );
}
