import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const loginFragments = makeStyles(() => ({
  LoginHead: {
    height: "60px",
    background: `${Colors.BackgroundDark} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 3px 6px #1C23314D",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 42px",

    "@media (min-width: 1919px)": {
      padding: "18px 100px",
    },

    "& p": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "900",
      color: `${Colors.TextWhite}`,
    },
  },

  LoginHeadPhoneNumber: {
    "& a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },

    "& img": {
      marginRight: "8px",
    },

    "& span": {
      fontSize: "16px",
      lineHeight: "22px",
      color: `${Colors.TextWhite}`,
    },
  },

  LoginModalWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: `${Colors.TextWhite} 0% 0% no-repeat padding-box`,
    borderRadius: "8px",
    overflow: "auto",

    width: "464px",
    height: "400px",
    padding: "20px 16px 20px 16px",

    "@media (min-width: 1800px)": {
      width: "600px ",
      padding: "30px",
      height: "430px",
    },

    "@media (max-width: 1280px)": {
      width: "464px",
      padding: "16px",
    },

    "@media (max-width: 767px)": {
      height: "335px",
      width: "343px",
      padding: "16px",
    },

    "& p": {
      fontSize: "32px",
      lineHeight: "48px",
      fontWeight: "bold",
      color: Colors.TextSecondary,
      marginBottom: "10px",

      "@media (max-width: 767px)": {
        fontSize: "24px",
        lineHeight: "36px",
      },
    },
  },
  inputMargins: {
    marginTop: "10px",
  },
  LoginModalForgotText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: Colors.TextPrimary,
  },

  LoginModalForgot: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 600,
    color: Colors.TextPrimary,
    background: "none",
    border: "none",
    outline: "none",
    width: "200px",
  },

  LoginModalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  CopyRight: {
    fontSize: "14px",
    lineHeight: "19px",
    color: Colors.TextWhite,
  },
}));