import { DateRow, PhoneRow, TextRow } from 'components';
import { Images, PermissionsList, RolePermission } from '../../utils';

export const directoryHead = [
  { name: 'department', title: 'Department' },
  { name: 'name', title: 'Name' },
  { name: 'title', title: 'Title' },
  { name: '', title: 'Phone Number', custom: false },
  { name: 'email', title: 'Email Address' },
  { name: '', title: 'Extension', custom: false },
  RolePermission([PermissionsList.MANAGE_DIRECTORY?.code]) &&
  { name: '', title: 'Action', custom: false, width: '100px' },
];

export const directoryBody = (handleClick) => [
  { rowText: (item) => <TextRow name={item?.department} /> },
  { rowText: (item) => <TextRow name={item?.name} /> },
  { rowText: (item) => <TextRow name={item?.title ? item?.title : 'Not Set'} /> },
  { rowText: (item) => <PhoneRow phone={item?.phone} /> },
  { rowText: (item) => <TextRow name={item?.email ? item?.email : 'Not Set'} /> },
  { rowText: (item) => <TextRow name={item?.extension ? item?.extension : 'Not Set'} /> },
  RolePermission([PermissionsList.MANAGE_DIRECTORY?.code]) &&
  {
    rowText: (item) => <div className="align-center" style={{ gap: 16 }}>
      <button className="reset-btn" onClick={() => handleClick(item, 'edit')}>
        <img src={Images.edit} alt="icon" />
      </button>
      <button className="reset-btn" onClick={() => handleClick(item, 'delete')}>
        <img src={Images.removeRed} alt="icon" />
      </button>
    </div>,
  },
];

export const ACTION_TYPE = 'GET_DIRECTORY';