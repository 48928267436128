
/**Create Edit Customer Payment */
export const CREATE_CUSTOMER_PAYMENT = 'CREATE_CUSTOMER_PAYMENT';
export const EDIT_CUSTOMER_PAYMENT = 'EDIT_CUSTOMER_PAYMENT';
/**Get Customer Payment */
export const GET_CUSTOMER_PAYMENT = 'GET_CUSTOMER_PAYMENT';
export const GET_CUSTOMER_PAYMENT_SUCCESS = 'GET_CUSTOMER_PAYMENT_SUCCESS';

/**Get Customer Payment by id */
export const CREATE_CUSTOMER_PAYMENT_LOAD = 'CREATE_CUSTOMER_PAYMENT_LOAD';

export const GET_CUSTOMER_PAYMENT_LOAD = 'GET_CUSTOMER_PAYMENT_LOAD';
export const GET_CUSTOMER_PAYMENT_LOAD_SUCCESS = 'GET_CUSTOMER_PAYMENT_LOAD_SUCCESS';

export const VOID_CUSTOMER_PAYMENT_LOAD = 'VOID_CUSTOMER_PAYMENT_LOAD';

export const GET_CUSTOMER_PAYMENT_BY_ID = 'GET_CUSTOMER_PAYMENT_BY_ID';
export const GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS = 'GET_CUSTOMER_PAYMENT_BY_ID_SUCCESS';
export const GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD = 'GET_CUSTOMER_PAYMENT_BY_ID_NO_LOAD';

/**Delete Customer */
export const DELETE_CUSTOMER_PAYMENT_CUSTOMER = 'DELETE_CUSTOMER_PAYMENT_CUSTOMER';

export const ADD_CUSTOMER_PAYMENT_LOAD_INFO = 'ADD_CUSTOMER_PAYMENT_LOAD_INFO'