import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    AddModalButton,
    Circle,
    CorrectionsModal,
    Line,
    NoYet,
    SimpleModal,
    FullTable,
} from "components";
import {Colors, FindLoad, Images, useGlobalStyles, useGlobalText} from "utils";
import {CollectDebt} from "../paymentVerificationTable/core/collectDebt";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {ACTION_TYPE, paymentVerBody, paymentVerHead, PMT_VERIFICATION} from "./constants";
import {paymentVerificationDetailsStyle} from "./styles";
import {PmtVerificationRows} from "./pmtVerificationRows";
import {CANCELLED} from "../../../pages/accounting/constants";

export const PaymentVerificationDetails = ({loadById}) => {
    const {pastPayable} = useSelector((state) => ({
        pastPayable: state.loads.pastPayable,
    }));
    const globalStyles = useGlobalStyles()
    const globalText = useGlobalText()
    const classes = paymentVerificationDetailsStyle()
    const dispatch = useDispatch()
    const loader = FindLoad('APPROVE_PAYABLE')
    const [openCorrection, setOpenCorrection] = useState(false)
    const [loading, setLoading] = useState(false)
    const [correction, setCorrection] = useState('')
    const [openCollect, setOpenCollect] = useState(false)
    const checkCorrection = loadById?.payable?.notes

    useEffect(() => {
        dispatch(loadActions.getPastPayable(loadById?.id))
    }, [])

    const handleApprove = () => {
        dispatch(loadActions.approvePayable(loadById?.id, 'byId'))
    }

    const handleSend = async () => {
        setLoading(true)
        try {
            const date = {"correction": correction}
            await LoadServices.PayableCorrections(loadById.id, date)
            dispatch(loadActions.getLoadById(loadById.id, 'noLoad'))
            dispatch(httpRequestsOnSuccessActions.appendSuccess('CORRECTION_SENDED'))
            setLoading(false)
            setOpenCorrection(false)
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <div>
            <div>
                <div className={globalStyles.spaceBetween}>
                    <div style={{width: '100%'}} className={globalStyles.centerItem}>
                        <Circle number={1} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Active Payables</p>
                    </div>
                </div>
                <div className={globalStyles.flexAndFullWidth}>
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'auto'}/>
                    </div>
                    {loadById?.payable ?
                        <div style={{width: '100%'}}>
                            <PmtVerificationRows loadById={loadById} classes={classes}/>
                            <>
                                {checkCorrection &&
                                    <div className={classes.warnWrapper}>
                                        <img src={Images.warning} alt='icon'/>
                                        <div className={classes.text}>
                                            <p style={{fontWeight: 'bold'}}>Corrections:  &nbsp;</p>
                                            <span>{loadById?.payable?.notes ? loadById?.payable?.notes : ''}</span>
                                        </div>
                                    </div>
                                }

                                <div className={classes.pmtButtonsWrapper}>
                                    {loadById?.payable?.status === PMT_VERIFICATION && loadById?.status !== CANCELLED &&
                                        <div className={globalStyles.centerItem}>
                                            {!loadById?.doNotPay &&
                                            <AddModalButton
                                                loading={!!loader.length}
                                                styles={{width: '238px', marginRight: '24px',}}
                                                handleClick={handleApprove}
                                                text={'Approve'}
                                            />
                                            }

                                            <button style={{marginRight: '24px'}}
                                                    onClick={() => setOpenCorrection(!openCorrection)}
                                                    className={classes.requestCorrection}
                                            >
                                                Request Corrections
                                            </button>
                                        </div>
                                    }
                                    {loadById?.payable?.status === PMT_VERIFICATION && loadById?.carrier?.debt?.length > 0 && loadById?.status !== CANCELLED &&
                                        <AddModalButton
                                            color={'blue'}
                                            styles={{width: '238px'}}
                                            handleClick={() => setOpenCollect(!openCollect)}
                                            text={'Collect'}
                                        />
                                    }
                                </div>
                            </>
                        </div>
                        :
                        <NoYet position={'screenCenter'} text={'No Payable Found'}/>
                    }
                </div>
            </div>
            <div>
                <div style={{marginBottom: '24px'}} className={globalStyles.centerItem}>
                    <div className={globalStyles.centerItem}>
                        <Circle number={2} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Past Payables</p>
                    </div>
                </div>
                <div className={classes.pastTable}>
                    <div>
                        <FullTable
                            head={paymentVerHead}
                            body={paymentVerBody}
                            loadingType={ACTION_TYPE}
                            list={pastPayable}
                            listCount={[]}
                            noText={'Payable History'}
                            margin={true}
                            height={'small'}
                        />
                    </div>
                </div>
            </div>
            <SimpleModal
                handleOpenClose={() => setOpenCollect(!openCollect)}
                openDefault={openCollect}
                content={
                    <CollectDebt
                        handleClose={() => setOpenCollect(!openCollect)}
                        data={loadById}
                    />
                }
            />
            <SimpleModal
                handleOpenClose={() => setOpenCorrection(!openCorrection)}
                openDefault={openCorrection}
                content={
                    <CorrectionsModal
                        handleChangeText={(e) => setCorrection(e)}
                        loadingCor={loading}
                        handleSend={handleSend}
                        type={'notVerified'}
                        handleClose={() => setOpenCorrection(!openCorrection)}
                    />
                }
            />
        </div>
    )
}