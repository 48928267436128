import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./payroll.service";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {
    GET_PAYROLL_LOADS, GET_PAYROLL_LOADS_SUCCESS,
    GET_PAYROLL_SUMMARY,
    GET_PAYROLL_SUMMARY_SUCCESS,
    GET_PAYROLLS,
    GET_PAYROLLS_SUCCESS,
} from './payroll.types';

/** Get Payrolls */

function* getPayrolls({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getPayrollService, payload.params);
        yield put({
            type: GET_PAYROLLS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getPayrollSummary({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getPayrollSummaryService, payload.params);
        yield put({
            type: GET_PAYROLL_SUMMARY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* getPayrollLoads({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getPayrollLoadsService, payload.params);
        yield put({
            type: GET_PAYROLL_LOADS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */


export const watchPayroll = function* watchPayrollSaga() {
    /** Get Payrolls */
    yield takeLatest(GET_PAYROLLS, getPayrolls);
    yield takeLatest(GET_PAYROLL_SUMMARY, getPayrollSummary);
    yield takeLatest(GET_PAYROLL_LOADS, getPayrollLoads);
    /** End */
};
