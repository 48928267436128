import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableWrapper, FullTable } from 'components';
import { directoryActions, equipmentActions, officeActions } from 'store';
import { PermissionsList, useModal } from 'utils';
import { ACTION_TYPE, directoryBody, directoryHead } from './constants';
import { CustomDeleteModal } from '../../fragments';
import { CreateDirectory } from './createDirectory';

export const Directory = () => {
  const { directoryList } = useSelector((state) => ({
    directoryList: state.directory.directoryList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const { open } = useModal();

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    getDirectory();
  }, [info]);

  const getDirectory = (loading) => {
    dispatch(directoryActions.getDirectory(renderParams(), loading));
  };

  const handleClick = (item, name) => {
    if (name === 'edit') {
      open(<CreateDirectory
        info={item}
        afterSuccess={() => getDirectory('noLoad')}
      />);
    }
    if (name === 'delete') {
      open(<CustomDeleteModal
        afterSuccess={() => getDirectory('noLoad')}
        actionType={'DELETE_DIRECTORY'}
        text="Delete Directory?"
        info={'Directory'}
        handleDel={() => dispatch(directoryActions.deleteDirectory(item?.id))}
      />);
    }
  };


  return (
    <TableWrapper
      addButton={'Add New Directory'}
      tab={'none'}
      buttonsTab={true}
      buttonsTabAddButton={true}
      handleClick={() => open(<CreateDirectory afterSuccess={() => getDirectory('noLoad')} />)}
      createPermisson={PermissionsList.MANAGE_DIRECTORY}
    >
      <FullTable
        head={directoryHead}
        body={directoryBody(handleClick)}
        loadingType={ACTION_TYPE}
        list={directoryList?.directories}
        listCount={directoryList?.count}
        noText={'Directories'}
      />
    </TableWrapper>
  );
};