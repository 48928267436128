import axios from "axios";

export const authService = {

  createPermissionService: ( body ) => axios.post(`/authz/permissions`, body, {auth:true}),

  getPermissionsService: ( ) => axios.get(`/authz/permissions`, {auth:true}),

  deletePermission: ( id ) =>  axios.delete(`/authz/permissions/${id}`, {auth:true})

};
