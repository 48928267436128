
/**Create Mc */
export const CREATE_TERM = 'CREATE_TERM';
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS';

/**Get Mcs */
export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';

/**Delete Mc */
export const DELETE_TERM = 'DELETE_TERM';

/** Quickbook */
export const CREATE_QUICKBOOKS = 'CREATE_QUICKBOOKS'

export const GET_QUICKBOOKS = 'GET_QUICKBOOKS'
export const GET_QUICKBOOKS_SUCCESS = 'GET_QUICKBOOKS_SUCCESS'