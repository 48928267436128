import { Images } from 'utils';

export const MobileApp = ( ) => {
  return(
    <div className='mobile-app-page'>
      <div>
        <p className='mobile-app-page-title'>Download Our App</p>
      </div>
      
      <div className='mobile-box'>
        <img className='logo' src={Images.logoBlack} alt='icon' />
        <button onClick={() => open('https://play.google.com/store/apps/details?id=com.polotms.carrierapp')}>
          <img src={Images.googlePlay} alt='markets' />
        </button>
        <button onClick={() => open('https://apps.apple.com/us/app/polotms/id6478936123')}>
          <img src={Images.playMarket} alt='markets' />
        </button>
      </div>
    </div>
  )
}