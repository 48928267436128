import {
  CREATE_DIRECTORY, DELETE_DIRECTORY, EDIT_DIRECTORY, GET_DIRECTORY,
} from './directory.types';


/** Directory */

export const getDirectory = (params, load) => {
  return {
    type: GET_DIRECTORY,
    payload: { params, load },
  };
};

export const createDirectory = (body) => {
  return {
    type: CREATE_DIRECTORY,
    payload: { body },
  };
};

export const editDirectory = (id, body) => {
  return {
    type: EDIT_DIRECTORY,
    payload: { id, body },
  };
};

export const deleteDirectory = (id, body) => {
  return {
    type: DELETE_DIRECTORY,
    payload: { id, body },
  };
};

/** End */
