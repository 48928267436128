import React from "react";
import { buttonsStyle } from "./styles";
import AddIcon from "@material-ui/icons/Add";
import { Images } from '../../utils';

export const AddCircle = ({ handleCLic, text, btnStyle, textStyle }) => {
  const classes = buttonsStyle();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <button className={classes.addCircleStyle} onClick={handleCLic} style={{...btnStyle}}>
        <img src={Images.addWhiteIcon} alt='add'/>
      </button>

      {text && <p className={classes.addCircleTextStyle} style={{...textStyle}}>{text}</p>}
    </div>
  );
};
