export const fileTypeList =[
    {name:'PERSONAL', id:'PERSONAL'},
    {name:'AVATAR', id:'AVATAR'},
    {name:'DOCUMENT', id:'DOCUMENT'},
]

export const officeDockTypes = [
    {name: 'All Documents', id: 'All DocumentsSection'},
    {name: 'Agent P&P', id: 'AGENTPANDP'},
    {name: 'Service Agreement', id: 'SERVICEAGREEMENT'},
    {name: 'W9', id: 'W9'},
    {name: 'Other', id: "OTHER"},
]
export const officeUploadDockTypes = [
    {name: 'Agent P&P', id: 'AGENTPANDP'},
    {name: 'Service Agreement', id: 'SERVICEAGREEMENT'},
    {name: 'W9', id: 'W9'},
    {name: 'Other', id: "OTHER"},
]