import {
    GET_CARRIER_FACTORING_SUCCESS,
    GET_FACTORING_BY_ID_SUCCESS,
    GET_FACTORINGS_SUCCESS,
} from "./factoring.types";

const initialState = {
    factoringsList: [],
    factoringById: null,
    factoringCarriers: [],
};

export const factoringReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Factorings */

        case GET_FACTORINGS_SUCCESS:
            return {
                ...state,
                factoringsList: action.payload,
            }

        case GET_FACTORING_BY_ID_SUCCESS:
            return {
                ...state,
                factoringById: action.payload
            }

        case GET_CARRIER_FACTORING_SUCCESS:
            return {
                ...state,
                factoringCarriers: action.payload,
            }

        /** End */

        default:
            return state;
    }
};
