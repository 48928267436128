import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  DateRow,
  FullTable,
  Loader,
  PaginationItem,
  PriceRow,
  SearchAndFilter,
  TableNoInfo,
  TextRow,
} from 'components';
import { FindLoad, SendPageSave, useGlobalStyles, SaveParams } from 'utils';
import { payrollActions } from 'store';
import { headList, loadPayrollBody, loadPayrollHead } from './core/constants';
import { GET_PAYROLL_LOADS } from '../../store/payroll/payroll.types';
import { filterFromTable } from '../../components/table/constants';
import { useDebouncedCallback } from 'use-debounce';

export const LoadPayroll = ({}) => {
  const { payrolls, payrollLoads } = useSelector((state) => ({
    payrolls: state.payroll.payrolls,
    payrollLoads: state.payroll.payrollLoads,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const globalStyle = useGlobalStyles();
  const loader = FindLoad('GET_PAYROLL_LOADS');
  const [expanded, setExpanded] = React.useState(null);
  const [list, setList] = useState([]);
  const [backSearched, setBackSearched] = useState({ searchKey: '', searchValue: '' });
  const [searched, setSearched] = useState({ name: '', text: '' });


  useEffect(() => {
    if (payrollLoads?.loads && !searched?.value) {
      setList(payrollLoads?.loads);
    }
  }, [payrollLoads]);

  useEffect(() => {
    if (info?.searchKey) {
      setBackSearched({
        searchKey: info?.searchKey,
        searchValue: info?.searchValue,
      });
    }
  }, [info]);

  const handleChange = (id) => {
    if (id === expanded) {
      setExpanded(null);
    } else {
      dispatch(payrollActions.getPayrolls({ load: id }));
      setExpanded(id);
    }
  };

  const debounced = useDebouncedCallback((name, value, searchKey) => {
      const params = {
        ...info,
      };
      params.limit = 100;
      params.skip = 0;
      params.page = 1;
      if (value) {
        params.searchKey = searchKey;
        params.searchValue = value;
      } else {
        delete params.searchKey;
        delete params.searchValue;
      }
      SaveParams(history, params);
    },
    1000,
    { maxWait: 2000 },
  );

  const handleSearch = (e, nameRow, searchKey, type) => {
    if (searchKey) {
      setBackSearched({
        searchKey: searchKey,
        searchValue: e.target.value,
      });
      // setSearched({ name: '', text: '' });
      debounced(e.target.name, e.target.value, searchKey);
    } else {
      setSearched({
        name: e.target.name,
        value: e.target.value,
      });

      if (e.target.name) {
        if (!e.target.value) {
          setList(list);
        } else {
          const info = filterFromTable(e, nameRow, list, type);
          setList(info);
        }
      }
    }
  };
  const changePage = (number) => {
    SendPageSave(number, info, history);
  };

  return (
    <div className="load-payroll-wrapper">
      <div className={globalStyle.tableWrapper} style={{ background: 'white' }}>
        <div className="accordion-table-head-wrapper">
          {headList?.map((i, j) => (
            <div className="accordion-table-head" key={j} style={{ width: i?.rowWidth }}>
              <SearchAndFilter
                handleSearch={(e) => handleSearch(e, i?.nameRow, i?.searchKey, i?.icon)}
                item={i}
                searched={searched}
                backSearched={backSearched}
              />

            </div>
          ))}
        </div>


        {loader?.length ?
          <Loader />
          :
          <>
            <div className="accordion-table-body-scroll">
              {list?.length ? list?.map((i, j) => (
                  <React.Fragment key={i?.id}>
                    <Accordion expanded={expanded === i?.id} onChange={() => handleChange(i?.id)} key={j}>
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="accordion-table-body-wrapper">
                          <div style={{ width: '7%' }} className="accordion-table-body">
                            {i?.displayId}
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <DateRow date={i?.submittedAt} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <TextRow name={i?.office?.name} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <TextRow name={i?.agent?.name} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <TextRow name={i?.mc?.name} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <TextRow name={i?.customer?.name} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <TextRow name={i?.carrier?.name} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <PriceRow info={i?.carrierRate} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <PriceRow info={i?.customerRate} />
                          </div>
                          <div style={{ width: '10%' }} className="accordion-table-body">
                            <PriceRow info={i?.customerRate - i?.carrierRate - i?.agentCharges} />
                          </div>
                          <div style={{ width: '3%' }} className="accordion-table-body">
                            {i?.id === expanded ?
                              <ExpandLessIcon />
                              :
                              <ExpandMoreIcon />
                            }
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {expanded === i?.id &&
                          <div className="loaf-payroll-table">
                            <FullTable
                              head={loadPayrollHead}
                              body={loadPayrollBody}
                              loadingType={'GET_PAYROLLS'}
                              list={payrolls?.payrolls}
                              noText={'Details'}
                              height={'small'}
                            />
                          </div>
                        }
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                )) :
                <div style={{ height: '100px' }}>
                  <TableNoInfo text={`No Load Yet`} />
                </div>
              }
            </div>
            {list?.length > 0 ?
              <div style={{ background: 'white', border: '0 0 8px 8px' }}>
                <PaginationItem
                  listInfo={list}
                  handleReturn={(number) => changePage(number)}
                  count={payrollLoads?.count}
                  styles={{ margin: '0 0 21px 0' }}
                />
              </div>
              : ''
            }
          </>
        }
      </div>
    </div>
  );
};
