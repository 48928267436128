import {GET_RMIS_UPDATES_SUCCESS} from "./rmis.types";

const initialState = {
    rmisSetups: null,
};

export const rmisReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Rmis Settings */
        case GET_RMIS_UPDATES_SUCCESS:
            return {
                ...state,
                rmisSetups: action.payload
            }
        /** End */

        default:
            return state;
    }
};
