import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AntSwitch, Colors, Images, PermissionsList, RolePermission, useGlobalStyles, useGlobalText} from "utils";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {Circle, DocumentsComponent} from "components";
import {CreateCustomerInputs} from "../../createCustomer";
import {CreditDebit, customerInformation, CustomerManagement} from "./index";
import {customerActions, httpRequestsOnSuccessActions} from "store";
import {authService} from "../../../../store/customers/customers.service";

const ADD_CUSTOMER_DOCK = 'ADD_CUSTOMER_DOCK'
const DELETE_CUSTOMER_DOCK = 'DELETE_CUSTOMER_DOCK'

export const CustomerInfoTable = ({handleChangeName}) => {
    const {customerById} = useSelector((state) => ({
          customerById: state.customers.customerById,
      })
    )
    const classes = customerInformation()
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();
    const dispatch = useDispatch()
    const [docs, setDocks] = useState([])
    const [rate, setRate] = useState(false)
    const [nonFactoring, setNonFactoring] = useState(false)
    const [noPod, setNoPod] = useState(false)
    const  info = customerById

    useEffect(() => {
        if (info && info.documents) {
            setDocks(info.documents)
            setRate(info?.rateCon ? info.rateCon : false)
            setNonFactoring(info?.nonFactoring ? info.nonFactoring : false)
            setNoPod(info?.requirePOD ? info.requirePOD : false)
        }
    }, [info])

    const handleChange = (img) => {
        const sendInfo = {
            id: info?.id,
            img: {...img}
        }
        dispatch(customerActions.addCustomerDocument(sendInfo))
    }

    const handleRemoveDoc = (doc) => {
        dispatch(customerActions.deleteCustomerDocument(info?.id, doc?.id))
    }

    const handleSwitch = (doc) => {
        const params = {
            ...doc,
            status: doc?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        }
        dispatch(customerActions.editCustomerDocument(info?.id, doc?.id, params))
    }

    const handleSetNonFactoring = () => {
        setNonFactoring(!nonFactoring)
        dispatch(customerActions.nonFactoringCustomer(info?.id, !nonFactoring))
    }

    const handleSetNonPod = () => {
        setNoPod(!noPod)
        dispatch(customerActions.nonNoPodCustomer(info?.id, !noPod))
    }

    const handleSetRate = async () => {
        const statusType = rate !== true
        setRate(statusType)
        try {
            await authService.rateCon(info?.id, statusType)
            dispatch(customerActions.getCustomerById(info?.id, 'noLoad'))
            dispatch(httpRequestsOnSuccessActions.appendSuccess('RATE_CONE'))
        } catch (e) {

        }
    }

    return (
        <div className='accordion-style'>

            <div style={{marginBottom:'24px'}} className={classes.settingWrapper}>
                <div className={globalInputs.flexAlign}>
                    <img src={Images.settings} alt="icon"/>
                    <p className={classes.settingsTitle}>Settings</p>
                </div>
                <div className={globalInputs.flexAlign}>
                    {RolePermission([PermissionsList.CUSTOMER_STATUS?.code]) &&
                        <div style={{marginRight: '24px'}} className={globalInputs.centerItem}>
                            <span style={{width: '53px'}} className={globalStyle.smallSwitchText}>{'No POD'}</span>
                            <AntSwitch
                                onClick={handleSetNonPod}
                                checked={!noPod}
                            />
                        </div>
                    }
                {RolePermission([PermissionsList.CUSTOMER_STATUS?.code]) &&
                    <div style={{marginRight: '24px'}} className={globalInputs.centerItem}>
                                    <span style={{width: '95px'}} className={globalStyle.smallSwitchText}>{'Non-Factoring'}</span>
                        <AntSwitch
                            onClick={handleSetNonFactoring}
                            checked={nonFactoring}
                        />
                    </div>
                }
                {RolePermission([PermissionsList.CUSTOMER_STATUS?.code]) &&
                    <div  className={globalInputs.centerItem}>
                                    <span style={{width: '125px'}} className={globalStyle.smallSwitchText}>{'Rate Confirmation'}</span>
                        <AntSwitch
                            onClick={handleSetRate}
                            checked={rate}
                        />
                    </div>
                }
                </div>
            </div>
            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={1} back={Colors.ThemeBlue}/>}
                        <p className={globalStyle.title}>Customer Information</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateCustomerInputs
                        handleChangeName={handleChangeName}
                        info={info}
                    />
                </AccordionDetails>
            </Accordion>

            {RolePermission([PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
                <Accordion>
                    <AccordionSummary
                        className={'accordion'}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={globalInputs.centerItem}>
                            {info && <Circle number={2} back={Colors.ThemeBlue}/>}
                            <p className={globalStyle.title}>Customer Debt</p>
                            {/*<p className={globalStyle.title}>Customer Credit & MC Authority Debt</p>*/}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CreditDebit info={info}/>
                    </AccordionDetails>
                </Accordion>
            }

            {RolePermission([PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
                <Accordion>
                    <AccordionSummary
                        className={'accordion'}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={globalInputs.centerItem}>
                            {info && <Circle number={3} back={Colors.ThemeBlue}/>}
                            <p className={globalStyle.title}>Customer Management</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomerManagement info={info}/>
                    </AccordionDetails>
                </Accordion>
            }

            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={4} back={Colors.ThemeBlue}/>}
                        <p className={globalStyle.title}>Documents</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='info-table-no-line'>
                        <DocumentsComponent
                            actionType={ADD_CUSTOMER_DOCK}
                            removeActionType={DELETE_CUSTOMER_DOCK}
                            handleDeleteFile={handleRemoveDoc}
                            handleUpload={handleChange}
                            handleSwitch={handleSwitch}
                            documents={docs}
                            editActionType={'EDIT_CUSTOMER_DOCK'}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}