import React from 'react';
import { Images, useGlobalText, useModal } from 'utils';
import { buttonsStyle } from './styles';
import { CsvModal } from '../modal';
import { Box, Popper } from '@material-ui/core';

export const CarrierCsvFile = ({ params }) => {
  const classes = buttonsStyle()
  const globalText = useGlobalText();
  const { open } = useModal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBool = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModal = (type) => {
    handleClose()
    const newParams = {
      ...params
    }
    type === 'w9' ? newParams.csvType = 'w9' : delete newParams.csvType
    open(<CsvModal params={newParams}  />)
  }

  return(
      <div>
        <button
          aria-describedby={id} type="button" onClick={handleClick}
          className={classes.csvExportBtn}
        >
          <img src={Images.download} alt="icon" />
          <p
            className={globalText.downloadText}
          >
            Export CSV File
          </p>
        </button>

        <Popper
          id={id}
          open={openBool}
          anchorEl={anchorEl}
          className={"status-select-paper-box"}
          onClose={handleClose}
          style={{ position: "absolute", zIndex: 1200 }}
        >
          <Box >
            <div className={classes.carrierCsvButtonsBox}>
              <button onClick={() => openModal('all')}>
                All Information
              </button>
              <button onClick={() => openModal('w9')}>
                W9 Information
              </button>
            </div>
          </Box>
        </Popper>

        {openBool && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}

    </div>
  )
}