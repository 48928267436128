import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./mc.service";
import {
  ACTIVATE_MC,
  ASSIGNED_MC,
  BRANCH_ASSIGNED_MC,
  BRANCH_ASSIGNED_MC_SUCCESS,
  CREATE_MC,
  DELETE_ASSIGNED_MC,
  EDIT_MC, GET_AGENT_ASSIGNED_MCS, GET_AGENT_ASSIGNED_MCS_SUCCESS,
  GET_ASSIGNED_MC,
  GET_ASSIGNED_MC_SUCCESS,
  GET_ASSIGNED_MCS,
  GET_ASSIGNED_MCS_SUCCESS,
  GET_BRANCH_ASSIGNED_MCS_SUCCESS,
  GET_MC_BY_ID,
  GET_MC_BY_ID_SUCCESS,
  GET_MCS,
  GET_MCS_SUCCESS, GET_OFFICE_ASSIGNED_MCS,
  GET_OFFICE_ASSIGNED_MCS_SUCCESS,
  INACTIVATE_MC
} from "./mc.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Create, Edit Mc */

function* createMc({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.createMcService, payload.body );
    window.location.replace('/authorities')
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

function* editMc({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.editMcService, payload.id, payload.body );
    yield put({
      type: GET_MC_BY_ID,
      payload: { id: payload.id, load:'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnLoadActions.removeLoading('REMOVE_LOGO'));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnLoadActions.removeLoading('REMOVE_LOGO'));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

/** End */

/** Get Mcs */

function* getMcs({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call( authService.getMcsService, payload.params );
    yield put({
      type: GET_MCS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getAssignedByTypedMcs({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(payload.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));

  try {
    const res = yield call( authService.getAssignedSectionMcService, payload.id ,payload.type );
    if(payload.type === 'BRANCH'){
      yield put({
        type: GET_BRANCH_ASSIGNED_MCS_SUCCESS,
        payload: res.data,
      });
    }
    else{
      yield put({
        type: GET_ASSIGNED_MCS_SUCCESS,
        payload: res.data,
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    if(payload.type === 'BRANCH'){
      yield put({
        type: GET_BRANCH_ASSIGNED_MCS_SUCCESS,
        payload: [],
      });
    }
    else{
      yield put({
        type: GET_ASSIGNED_MCS_SUCCESS,
        payload: [],
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getAgentAssignedByTypedMcs({ payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(payload.type));
  yield put(httpRequestsOnLoadActions.removeLoading(payload.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));
  try {
    const res = yield call( authService.getAssignedSectionMcService, payload.id ,payload.type );
      yield put({
        type: GET_AGENT_ASSIGNED_MCS_SUCCESS,
        payload: res.data,
      });
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));
  }
}

function* getOfficeAssignedByTypedMcs({type, payload }) {
  yield put(httpRequestsOnErrorsActions.removeError(payload.type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));
  try {
    const res = yield call( authService.getAssignedSectionMcService, payload.id ,payload.type );
      yield put({
        type: GET_OFFICE_ASSIGNED_MCS_SUCCESS,
        payload: res.data,
      });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));
  }
}

function* getMcById({payload, type}) {
  if(payload?.load !== 'noLoad'){
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call( authService.getMcByIdService, payload.id );
    yield put({
      type: GET_MC_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Activate or Inactivate Mc */

function* activateMc(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call( authService.activateMcService, action.payload.id );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* inactivateMc(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call( authService.inactivateMcService, action.payload.id );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

/**  Assigned Mc */

function* assignMc(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  try {
    yield call(authService.assignMcService, action.payload.officeId, action.payload.mcId, action.payload.type);
    const mcId = {mcId: action.payload.mcId, type: action.payload.type}
    yield put({
      type: GET_ASSIGNED_MC,
      payload: mcId,
    });
    if (action.payload.type === 'BRANCH'){
      yield put({
        type: GET_ASSIGNED_MCS,
        payload: {id: action.payload.officeId, type: 'BRANCH'}
      })
  }   if (action.payload.type === 'AGENT'){
      yield put({
        type: GET_AGENT_ASSIGNED_MCS,
        payload: {id: action.payload.officeId, type: action.payload.type}
      })
  }
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* getAssignedMc(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    const res = yield call( authService.getAssignedMcService, action.payload.mcId, action.payload.type );
    yield put({
      type: GET_ASSIGNED_MC_SUCCESS,
      payload: res.data,
    });
  } catch (err) {}
}

function* getBranchAssignedMc(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    const res = yield call( authService.getAssignedMcService, action.payload.mcId, action.payload.type );
    yield put({
      type: BRANCH_ASSIGNED_MC_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(payload.type));
  }
}

function* deleteAssignedMc(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    yield call( authService.deleteAssignedMcService, action.payload.officeId, action.payload.mcId, action.payload.type );
    const mcId = {mcId: action.payload.mcId, type: action.payload.type}
    if(action.payload.type === 'AGENT'){
        yield put({
          type: GET_AGENT_ASSIGNED_MCS,
          payload: {id: action.payload.officeId, type: action.payload.type}
        })
    }
    else {
      yield put({
        type: GET_ASSIGNED_MC,
        payload: mcId,
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

export const watchMc = function* watchMcSaga() {
  /** Create, Edit Mc */
  yield takeLatest( CREATE_MC, createMc );
  yield takeLatest( EDIT_MC, editMc);
  /** End */

  /** Get Mcs */
  yield takeLatest( GET_MCS, getMcs );
  yield takeLatest( GET_MC_BY_ID, getMcById );
  yield takeLatest( GET_ASSIGNED_MCS, getAssignedByTypedMcs );
  yield takeLatest( GET_OFFICE_ASSIGNED_MCS, getOfficeAssignedByTypedMcs );
  yield takeLatest( GET_AGENT_ASSIGNED_MCS, getAgentAssignedByTypedMcs );
  /** End */

  /** Activate or Inactivate Mc */
  yield takeLatest( ACTIVATE_MC, activateMc)
  yield takeLatest( INACTIVATE_MC, inactivateMc)
  /** End */

  /**  Assigned Mc */
  yield takeLatest( ASSIGNED_MC, assignMc)
  yield takeLatest( GET_ASSIGNED_MC, getAssignedMc)
  yield takeLatest( BRANCH_ASSIGNED_MC, getBranchAssignedMc)
  yield takeLatest( DELETE_ASSIGNED_MC, deleteAssignedMc)
  /** End */
};
