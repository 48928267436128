import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Circle, DocumentsComponent, Line, Loader } from 'components';
import {
  Colors,
  FindLoad,
  useGlobalStyles,
  useGlobalText,
  userInfo,
} from 'utils';
import { carrierActions } from 'store';
import {
  convertPaymentMethod,
  factoredCompanyInformation,
  factoredCompanyInformationAddress,
  factoredInformation,
  factoredInformationAddress,
  factoredMailingInformationAddress, renderPaymentTerm,
} from './constants';
import { RadioButtons } from '../../fragments/carriers/createCarrier/core/radioButtons';
import { carrierInfoFragments } from '../../fragments/carriers/carrierInfo/core';
import { carrierDockTypes, carrierUploadDockTypes } from '../../fragments/carriers/carrierInfo/core/constants';

const ACTION_TYPE = 'GET_CARRIER_BY_ID';
export const FactoredProfile = () => {
  const { carrierById } = useSelector((state) => ({
      carrierById: state.carriers.carrierById,
    }),
  );
  const globalStyle = useGlobalText();
  const globalInputs = useGlobalStyles();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const classes = carrierInfoFragments();
  const globalText = useGlobalText();

  useEffect(() => {
    dispatch(carrierActions.getCarrierById(userInfo?.id));
  }, []);

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div className="agent-profile-page">
      <div className="accordion-style">
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={1} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>My Information</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <Line height={'100%'} />
              <div className="line-fragment-box">
                <p className="information-box-title" style={{ marginTop: 0 }}>Basic information</p>
                <div className="my-information-wrapper">
                  {factoredInformation(carrierById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
                <p className="information-box-title">Address</p>
                <div className="my-information-wrapper" style={{ marginBottom: 40 }}>
                  {factoredInformationAddress(carrierById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>

                <p className="information-box-title">Mailing Address</p>
                <div className="my-information-wrapper" style={{ marginBottom: 40 }}>
                  {factoredMailingInformationAddress(carrierById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={2} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>Company Information</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <Line height={'100%'} />
              <div className="line-fragment-box">
                <p className="information-box-title" style={{ marginTop: 0 }}>Basic information</p>
                <div className="my-information-wrapper">
                  {factoredCompanyInformation(carrierById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
                <p className="information-box-title">Company Address</p>
                <div className="my-information-wrapper" style={{ marginBottom: 40 }}>
                  {factoredCompanyInformationAddress(carrierById)?.map((i) => {
                    return (
                      <div key={i.title} className="my-information-box">
                        <span>{i.title}</span>
                        <p>{i.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={3} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>Payment Setup</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <Line height={'100%'} />
              <div className="line-fragment-box full-width">

                <div className="flex-align-start" style={{ gap: 24 }}>
                  <div style={{ width: '49%' }} className={classes.paymentItemWrapper}>
                    <div className={classes.paymentHeader}>
                      <p className={globalText.smallText}>Payment Method</p>
                    </div>
                    <div className={classes.paymentBodyHeight} style={{ height: '100px' }}>
                      <div className="payment-info-box">
                        {carrierById?.pmtInfo?.method ? convertPaymentMethod(carrierById?.pmtInfo?.method) : 'Not Set'}
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '49%' }} className={classes.paymentItemWrapper}>
                    <div className={classes.paymentHeader}>
                      <p className={globalText.smallText}>Payment Term</p>
                    </div>
                    <div className={classes.paymentBodyHeight} style={{ height: '100px' }}>
                      <div className="payment-info-box">
                        {carrierById?.pmtInfo?.term ? renderPaymentTerm(carrierById?.pmtInfo?.term) : 'Not Set'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={globalInputs.centerItem}>
              <Circle number={4} back={Colors.TextPrimary} />
              <p className={globalStyle.title}>Documents</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
                <div className="info-table-no-line">
                  <DocumentsComponent
                    noUpload={true}
                    documents={carrierById?.documents}
                    name={'typeName'}
                  />
                </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};