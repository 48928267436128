import axios from "axios";

export const authService = {
  /** Create, Edit Equipment */

  createEquipmentService: ( body ) => axios.post('/equipment', body, {auth:true}),

  editEquipmentService: ( id, body ) => axios.patch(`/equipment/${id}`, body, {auth:true}),

  /** End */

  /** Get Equipment */

  getEquipmentsService: ( params ) => axios.get(`/equipment` , {auth:true, params: { ...params }}),

  getEquipmentsByIdService: ( id ) => axios.get(`/equipment/${id}`, {auth:true}),

  /** End */

  /** Activate or Inactivate Office */

  setEquipmentStatusService: ( id, status ) => axios.patch(`/equipment/${id}/setStatus/${status}`, { }, {auth:true}),

  /** End */

  /** Remove Equipment */

  deleteEquipmentService: ( id ) => axios.delete(`/equipment/${id}`, {auth:true}),

  /** End */

  /** Favorite */

  setFavoriteService: ( id ) => axios.post(`/agents/favoriteCarriers/${id}`, null,{auth:true}),

  unsetFavoriteService: ( id ) => axios.delete(`/agents/favoriteCarriers/${id}`, {auth:true}),

  /** End */


};
