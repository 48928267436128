import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {dropdownsStyle} from "./styles";
import {AntSwitch, Colors, FindLoad} from "utils";
import {MinLoader} from "../loader";

export const useWidth = () => {
    const [width, setWidth] = useState(window?.innerWidth);

    const onResize = useCallback(() => {
        setWidth(window?.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return width;
};


const getLimitedVal = (value = "", limit = 10) => {
    if (typeof value !== "string") return value;
    return value.trim().length > limit ? `${value.slice(0, limit)}...` : value;
};

export const UserInputsDropdown = ({
                                       label,
                                       dropdownOptions,
                                       onPass,
                                       selected,
                                       dropdownTitle,
                                       dropdownClassName, noText,
                                       firstText,
                                       secondText,
                                       type,
                                       handlePast
                                   }) => {
    const classes = dropdownsStyle();
    const history = useHistory()
    const info = history?.location?.state
    const width = useWidth();
    const loader = FindLoad('GET_AGENTS')
    const [currentList, setCurrentList] = useState([])
    const [currentOption, setCurrentOption] = useState('')

    useEffect(() => {
      if(dropdownOptions) {
        setCurrentList(dropdownOptions)
      }
    }, [dropdownOptions]);

    function getDisplayOf(givenValue, limit) {
        return width <= 1720 ? getLimitedVal(givenValue, limit) : givenValue;
    }

    const [dropdownIsShown, setDropdownIsShown] = useState(false);

    function chooseOptionHandler(option) {
        setCurrentOption('')
        setCurrentList(dropdownOptions)
        onPass && onPass(option);
        setDropdownIsShown(false);
    }

    const current = selected ? dropdownOptions?.find((i) => i?.id === selected) : ''
    const labelText = current ? firstText && secondText ? `${current[firstText]} ${current[secondText]}` : current[firstText] : ''

    const sectionDisplay =
        selected && selected !== "undefined undefined"
            ? getDisplayOf(labelText, 10) : dropdownTitle
                ? getDisplayOf(dropdownTitle, 10)
                : noText ? noText : "Not Set";


    const handleFilter = (option) => {
      setCurrentOption(option.target.value)
      const newList = dropdownOptions.filter((i) => i[firstText].toLowerCase().includes(option.target.value.toLowerCase()))
      setCurrentList(newList)
    }

    return (
        <>
            {dropdownIsShown && (
                <div
                    className={classes.dropOverlayStyle}
                    onClick={() => setDropdownIsShown(false)}
                />
            )}
            <div className={`${classes.userDropStyle} ${dropdownClassName}`}>
                {label && <p className="userDropLabel"> {label} </p>}
                <div className={`dropdown-box ${dropdownIsShown ? "focused" : ""}`}>
                    <div
                        className="show-dropdown-box"
                        onClick={() => setDropdownIsShown((prevState) => !prevState)}
                    >
                        <h6 className={`dropdown-selected ${classes.optionStyle}`}>
                          <input
                            value={currentOption}
                            onChange={handleFilter}
                            placeholder={sectionDisplay}
                            type="text"
                          />
                            {/*{sectionDisplay}*/}
                        </h6>
                        <i className={`${dropdownIsShown ? "active" : ""}`}/>
                    </div>
                    {dropdownIsShown && (
                        <ul className="dropdown-options">
                            <li
                                className={`${classes.optionStyle}`}
                                onClick={() => chooseOptionHandler()}
                            >
                              <span
                                  className={`dropdown-option-title ${!selected ? "selected-title" : ""}`}
                              >
                                 {noText}
                              </span>
                            </li>

                            {currentList?.map((option, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`${classes.optionStyle}`}
                                        onClick={() => chooseOptionHandler(option)}
                                    >
                              <span
                                  className={`dropdown-option-title ${option?.id === selected ? "selected-title" : ""}`}
                              >
                                 {`${firstText && option[firstText]} ${secondText ? option[secondText] : ''}`}
                              </span>
                                    </li>
                                );
                            })}

                            {type === 'agent' &&
                                <>
                                    <hr style={{margin:0}}/>

                                    <div className={classes.inactiveSwitch}>
                                        <p>All Agents</p>

                                        {loader?.length ?
                                            <MinLoader margin={'0'} style={{height:'15px', width:'15px'}} color={Colors.ThemeBlue} position={'relative'}/>
                                            :
                                            <AntSwitch
                                                onClick={handlePast}
                                                checked={info?.inActiveAgents}
                                            />
                                        }
                                    </div>
                                </>
                            }

                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};
