import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./auth.service";
import {
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_OUT,
  GET_RECOVERY_LINK,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  GET_MY_AUTHN,
  CONTACT_US, GET_MY_PROFILE, GET_MY_PROFILE_SUCCESS,
} from "./auth.types";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import {userType} from "../../utils";

function* logIn({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.signIn, payload);

    if(res.data.userType === 'CARRIER'){
      yield put(httpRequestsOnErrorsActions.appendError(type, "FROM_CARRIER"));
    } else {
      localStorage.setItem("userType", res.data.userType);
      localStorage.setItem("access-token", res.data.token);

      if (res?.data?.userType === "ADMIN") {
        localStorage.setItem("permissions", JSON.stringify(res.data?.permissions));
      }
      const info = yield call(authService.myProfileService, res.data.userType);
      localStorage.setItem("poloUserInfo", JSON.stringify(info.data));
      window.location.reload();

      yield put({
        type: LOG_IN_SUCCESS,
        payload: res.data,
      });
      yield put(httpRequestsOnErrorsActions.removeError(type));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));

  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* logOut({ type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(authService.logOut);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    localStorage.removeItem("access-token");
    localStorage.removeItem("poloUserInfo");
    localStorage.removeItem("userType");
    localStorage.removeItem("permissions");
    localStorage.removeItem("searches");
    sessionStorage.removeItem("open");
    window.location.replace("/login");
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    // if(err.response && err.response.data && err.response.data.statusCode === 401){
    localStorage.removeItem("access-token");
    localStorage.removeItem("poloUserInfo");
    localStorage.removeItem("userType");
    localStorage.removeItem("permissions");
    localStorage.removeItem("searches");
    sessionStorage.removeItem("open");
    window.location.replace("/login");
    // }
  }
}

function* getLink({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.getLink, payload.email);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* resetPassword(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    // if (action.payload.passwords.type === 'reset') {
    const res = yield call(
      action.payload.passwords.type === "reset"
        ? authService.resetPass
        : authService.confirmAccount,
      action.payload.passwords
    );
    localStorage.setItem("access-token", res.data.token);
    localStorage.setItem("userType", res.data.userType);
    if (res?.data?.userType === "ADMIN") {
      localStorage.setItem("permissions", JSON.stringify(res.data?.permissions));
    }
    const info = yield call(authService.myProfileService, res.data.userType);
    localStorage.setItem("poloUserInfo", JSON.stringify(info.data));

    // } else {
    //     const res = yield call(authService.confirmAccount, action.payload.passwords);
    //     localStorage.setItem('access-token', res.data.token);
    //     localStorage.setItem('userType', res.data.userType);
    //     const info = yield call(authService.myProfileService, res.data.userType);
    //     localStorage.setItem('poloUserInfo', JSON.stringify(info.data));
    //     if(res?.data?.userType === 'ADMIN') {
    //         localStorage.setItem('permissions', JSON.stringify(res.data?.permissions));
    //     }
    //     window.location.reload()
    // }
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));

    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
    );
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* changePassword(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    const res = yield call(
      authService.changePasswordService,
      action.payload.data
    );
    localStorage.setItem("access-token", res.data.accessToken);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* getMuAuthn(action) {
  try {
    const res = yield call(authService.muAuthnService);
    // localStorage.setItem('poloUserInfo', JSON.stringify(res.data.email) );
    // localStorage.setItem('permissions', JSON.stringify(res.data.roles) );
  } catch (err) {}
}

function* getMyProfile(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    const res = yield call(authService.myProfileService, userType);
    yield put({
      type: GET_MY_PROFILE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

/** Contact Us  */
function* contactUs({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.constactUs, payload.body);
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type, err?.data));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchAuth = function* watchUserAuth() {
  yield takeLatest(LOG_IN, logIn);
  yield takeLatest(LOG_OUT, logOut);
  yield takeLatest(GET_MY_AUTHN, getMuAuthn);
  yield takeLatest(GET_MY_PROFILE, getMyProfile);
  yield takeLatest(GET_RECOVERY_LINK, getLink);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);

  yield takeLatest(CONTACT_US, contactUs);
};
