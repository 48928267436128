import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./factoring.service";
import {
  CREATE_FACTORING,
  EDIT_FACTORING,
  GET_FACTORINGS,
  GET_FACTORING_BY_ID,
  GET_FACTORINGS_SUCCESS,
  GET_FACTORING_BY_ID_SUCCESS,
  GET_CARRIER_FACTORING,
  GET_CARRIER_FACTORING_SUCCESS,
  ACTIVATE_FACTORING,
  INACTIVATE_FACTORING,
} from "./factoring.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Create, Edit Factoring */

function* createFactoring({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.createFactoringService, payload.body );
    window.location.replace('/factorings')
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}


function* editFactoring({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call( authService.editFactoringService, payload.id, payload.body );
    yield put({
      type: GET_FACTORING_BY_ID,
      payload: { id:payload.id, load: 'noLoad' }
    })

    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

/** End */

/** Get Factorings */

function* getFactoring({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call( authService.getFactoringsService, payload.params );
    yield put({
      type: GET_FACTORINGS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getFactoringById({payload, type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  if(payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call( authService.getFactoringByIdService, payload.id );
    yield put({
      type: GET_FACTORING_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getFactoringCarrier({ type, payload }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call( authService.getFactoringCarrierService, payload.id, payload.params );
    yield put({
      type: GET_CARRIER_FACTORING_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** Activate or Inactivate Factoring */

function* activateFactoring(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {

    yield call( authService.activateFactoringService, action.payload.id );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* inactivateFactoring(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call( authService.inactivateFactoringService, action.payload.id );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

export const watchFactoring = function* watchFactoringSaga() {

  /** Create, Edit Factoring */
  yield takeLatest( CREATE_FACTORING, createFactoring );
  yield takeLatest( EDIT_FACTORING, editFactoring );
  /** End */

  /** Get Factorings */
  yield takeLatest( GET_FACTORINGS, getFactoring );
  yield takeLatest( GET_FACTORING_BY_ID, getFactoringById );
  yield takeLatest( GET_CARRIER_FACTORING, getFactoringCarrier );
  /** End */

  /** Activate or Inactivate Factoring */
  yield takeLatest( ACTIVATE_FACTORING, activateFactoring)
  yield takeLatest( INACTIVATE_FACTORING, inactivateFactoring)
  /** End */

};
