import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  CloseButton,
  CreateChancel, ErrMessage,
  modalsStyle,
  SelectTypeAutocomplete,
  ValidationInput,
} from 'components';
import { ErrorText, FindErrorItem, FindLoad, FindSuccess, Images, useGlobalStyles, useGlobalTextStyles } from 'utils';
import {
  customerPaymentActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnLoadActions,
  httpRequestsOnSuccessActions,
} from 'store';

const ACTION_TYPE = 'CREATE_CUSTOMER_PAYMENT_LOAD';
const GET_LOAD_ACTION_TYPE = 'GET_LOADS_BY_STATUS';
const PMT_AMOUNT_NOT_SUFFICIENT = 'PMT_AMOUNT_NOT_SUFFICIENT'
const LOAD_ALREADY_PAID = 'LOAD_ALREADY_PAID'
export const AddLoad = ({ handleOpenClose,loadDate, customerPaymentLoad }) => {
  const { customersList } = useSelector((state) => ({
    customersList: state.customers.customersList,
  }));
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const success = FindSuccess(ACTION_TYPE);
  const backError = FindErrorItem(ACTION_TYPE);
  const classes = modalsStyle();
  const globalWrappers = useGlobalStyles();
  const globalText = useGlobalTextStyles();
  const params = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [loads, setLoads] = useState([]);
  const loadAmount = loadDate && loadDate?.amount - loadDate?.used?.toFixed(3);
  const [loadList, setLoadList] = useState([{ loadId: '', amount: '' }]);

  function mergeArraysInObject(obj) {
    return Object.values(obj)
      .filter(value => Array.isArray(value))
      .reduce((acc, array) => acc.concat(array), []);
  }

  const mergedArray = mergeArraysInObject(customerPaymentLoad);
  const filteredLoads = loads?.loads?.filter(function(array_el) {
    return mergedArray?.filter(function(anotherOne_el) {
      return anotherOne_el?.load === array_el.id && anotherOne_el?.status === "APPLIED";
    }).length === 0;
  });

  useEffect(() => {
    dispatch(httpRequestsOnErrorsActions.removeError(LOAD_ALREADY_PAID));
    dispatch(httpRequestsOnErrorsActions.removeError(PMT_AMOUNT_NOT_SUFFICIENT))
    return () => dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
  }, []);

  useEffect(() => {
    if (success.length) {
      handleOpenClose();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }

    if(backError?.error === 'Pmt amount is not sufficient') {
      dispatch(httpRequestsOnErrorsActions.appendError(PMT_AMOUNT_NOT_SUFFICIENT));
    }
  }, [success, backError]);

  const handleCreate = () => {
    const errorKey = loadList?.findIndex((i) => !i?.loadId || !i?.amount);
    dispatch(httpRequestsOnErrorsActions.removeError(PMT_AMOUNT_NOT_SUFFICIENT))
    if (inputs?.customer && errorKey === -1) {
      const itemInfo = {
        'customer': inputs?.customer,
        'loadPmts': loadList,
      };
      dispatch(customerPaymentActions.createCustomerPaymentLoad(itemInfo, params.id));
    } else {
      setError(
        !inputs.customer ? 'customer' :
          errorKey ? errorKey :
            errorKey === 0 ? errorKey :
              '',
      );
    }
  };

  const handleChange = e => {
    setInputs({ customer: e.target.value });
    setLoadList([{ loadId: '', amount: '' }]);

    dispatch(httpRequestsOnLoadActions.appendLoading(GET_LOAD_ACTION_TYPE));
    axios.get(`/loads`, { auth: true, params: { customer: e.target.value } }).then((res) => {
      setLoads(res.data);
    }).finally(() => {
      dispatch(httpRequestsOnLoadActions.removeLoading(GET_LOAD_ACTION_TYPE));
    });

    if (error === e.target.name) {
      setError('');
    }
    if (backError?.error === 'This load was already paid by this payment' && e.target.name === 'loadId') {
      dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }
  };

  const changeLoads = (e, key) => {
    dispatch(httpRequestsOnErrorsActions.removeError(LOAD_ALREADY_PAID));
    if(e.target.name === 'loadId') {
      const check = loadList?.find((i) => i?.loadId === e.target.value);
      if (check) {
        dispatch(httpRequestsOnErrorsActions.appendError(LOAD_ALREADY_PAID));
        return
      }
    }

    if (error === key) {
      setError('');
    }

    const list = [...loadList];
    list[key][e.target.name] = e.target.name === 'amount' ?  +e.target.value : e.target.value;
    setLoadList(list);
  };

  const addNewRow = () => {
    const lastItem = loadList?.length && loadList[loadList?.length - 1];

    if (loadList?.length ? (lastItem?.loadId && lastItem?.amount) : true) {
      setLoadList([...loadList, { loadId: '', amount: '' }]);
    } else {
      setError(loadList?.length - 1);
    }
  };

  const removeRow = (key) => {
    if (error === key) {
      setError('');
    }
    const lister = loadList?.filter((i, k) => k !== key);
    setLoadList(lister);
  };

  // const filteredLoads = loads?.loads?.filter((i) => i?.mc?.id === inputs.mc)
  return (
    <div className={classes.addLoadCustomerPmt}>
      <div className={classes.closeButtonWithPaddings}>
        <CloseButton handleCLic={handleOpenClose} />
      </div>
      <div className={classes.deleteModalWrapper}>
        <>
          <p className={globalText.modalTitle}>{'Add Load'}</p>
          <p className={globalText.modalText}>{'To add Load please, fill out the below fields.'}</p>
          <div style={{ marginBottom: '24px' }}>
            <SelectTypeAutocomplete
              loadType={'GET_CUSTOMERS'}
              style={globalWrappers.simpleInput}
              name={'customer'}
              label={'name'}
              title={'Customer*'}
              handleSelect={handleChange}
              defaultValue={inputs.customer}
              list={customersList?.customers ?
                loadDate?.customerId ? customersList?.customers.filter((i) => i.id === loadDate?.customerId) :
                customersList?.customers : []}
              error={error}
            />
          </div>

          {/*<div style={{marginBottom: '24px'}}>*/}
          {/*    <SelectTypeAutocomplete*/}
          {/*        loadType={'GET_MCS'}*/}
          {/*        style={globalWrappers.simpleInput}*/}
          {/*        name={'mc'}*/}
          {/*        label={'name'}*/}
          {/*        title={'Select an MC*'}*/}
          {/*        handleSelect={handleChange}*/}
          {/*        defaultValue={inputs.mc}*/}
          {/*        list={mcsList?.mcs ? mcsList?.mcs : []}*/}
          {/*        disabled={!inputs?.customer}*/}
          {/*        error={error}*/}
          {/*    />*/}
          {/*</div>*/}

          <div className={classes.loadsListWrapper}>
            {loadList?.map((i, j) => (
              <div style={{ gap: 16 }} className="flex-align-start">
                <SelectTypeAutocomplete
                  loadType={GET_LOAD_ACTION_TYPE}
                  style={globalWrappers.simpleInputNoRight}
                  name={'loadId'}
                  label={'displayId'}
                  title={'Select a Load*'}
                  handleSelect={(e) => changeLoads(e, j)}
                  defaultValue={i?.loadId}
                  list={filteredLoads}
                  // list={filteredList || []}
                  disabled={!inputs?.customer}
                  typeError={
                    error === j ? !i?.loadId && ErrorText.field :
                      backError?.error === 'This load was already paid by this payment' ? 'This load was already paid by this payment' : ''
                  }
                  error={
                    error === j ? !i?.loadId && 'loadId' :
                      backError?.error === 'This load was already paid by this payment' ? 'loadId' :
                        error
                  }
                />
                <ValidationInput
                  className={i?.amount && globalWrappers.inputTextFieldBlue}
                  style={globalWrappers.simpleInputNoRight}
                  variant={'outlined'}
                  name={'amount'}
                  label={'Amount*'}
                  type={'number'}
                  typeError={
                    error === 'amount' ? !i?.amount && ErrorText.field :
                      error === j ? ErrorText.field :
                        ''}
                  onChange={(e) => changeLoads(e, j)}
                  value={i?.amount}
                  disabled={!inputs?.customer}
                />
                <button onClick={() => removeRow(j)} className="reset-btn" style={{ marginTop: 12 }}>
                  <img src={Images.remove} alt="icon" />
                </button>
              </div>
            ))}
          </div>


          <div className={classes.addMoreBox}>
            <button disabled={!inputs?.customer} onClick={addNewRow} className={classes.addMoreLoad}>
              <div>
                <img src={Images.addWhiteIcon} alt="icon" />
              </div>
              <p>Add More</p>
            </button>
          </div>

          <span style={backError?.error === 'Pmt amount is not sufficient' ? {color:'#F07379'} : {}} className={classes.available}>Available Amount: {loadAmount}$</span>
        </>

        {backError?.error === 'Something went wrong, could not add load to the customer payment' &&
          <ErrMessage text={
            backError?.error === 'Something went wrong, could not add load to the customer payment' ?
              'Something went wrong, could not add load to the customer payment' : ''
          } />
        }
        <CreateChancel
          loader={!!loader?.length}
          classes={globalWrappers.buttonsStyleFullWidth}
          create={'Add Load'}
          chancel={'Cancel'}
          onCreate={handleCreate}
          onClose={handleOpenClose}
        />
      </div>
    </div>
  );
};