import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {managementFragments} from "./style";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {Colors, FindLoad, FindSuccessItem, Images} from "utils";
import {
    AddCircle,
    DeleteButton,
    CloseButton,
    SimpleModal,
    DeleteElement,
    CheckboxesTags,
    NoInfoYet, SlicedText, Loader, TextRow
} from "components";
import {httpRequestsOnSuccessActions, roleActions} from 'store'

const DELETE_TYPE = 'DELETE_ROLE_PERMISSION'
export const RolePermissions = ({permissionsList}) => {
    const { role } = useSelector((state) => ({
            role: state.roles.role
        })
    )

    const dispatch = useDispatch()
    const [show, setShowInput] = useState(false);
    const [open, setOpen] = useState(false)
    const [permission, sePermission] = useState('')
    const classes = managementFragments();
    const getLoader = FindLoad('GET_ROLE_BY_ID')
    const loader = FindLoad(DELETE_TYPE)
    const success = FindSuccessItem(DELETE_TYPE)

    useEffect(() => {
        if (success) {
            setOpen(false)
            sePermission('')
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_TYPE))
        }
    }, [success])

    const handleOpenClose = (it) => {
        setOpen(!open)
        sePermission(it)
    }

    const deletePermissions = () => {
        const data = {
            roleId: role.id,
            permissionId: permission?.id,
        }
        dispatch(roleActions.deleteRolePermission(data))
    }

    const addPermissions = (permission) => {
        if (permission.length) {
            const body = {
                roleId: role.id,
                permissionId: permission[permission.length - 1].id
            }
            dispatch(roleActions.addRolePermission(body))
        }
    }

    const filter = permissionsList.filter(function (array_el) {
        return role && role.permissions && role.permissions.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    return (
        <div className={classes.tableStyle}>
            <div className={classes.tableHeadStyle}>
                <div className={classes.rolePermissionsStyle}>
                    <AccountCircleIcon
                        style={{
                            width: "40px",
                            height: "40px",
                            color: Colors.ThemePurple,
                        }}
                    />
                    <p>Role Permissions</p>
                </div>
            </div>

            {getLoader?.length ?
                <Loader style={'relative'}/>
                :
                <div>
                    <div className={classes.roleNameStyle}>
                        <div>
                            <span>{role && role.permissions ? role.title : ''}</span>
                        </div>
                        {role && role.title ?
                            <div>
                                {show === false ? (
                                    <AddCircle
                                        handleCLic={() => setShowInput(true)}
                                        text={"Add Permissions"}
                                    />
                                ) : (
                                    <CloseButton handleCLic={() => setShowInput(false)}/>
                                )}
                            </div>
                            : null}
                    </div>
                    {show === true && (
                        <div className={classes.tablePermissionsBodyStyle}>
                            <CheckboxesTags
                                name='permissions'
                                handleChange={addPermissions}
                                permissionsList={filter}
                                label={"Select Permissions*"}
                                placeholder={'Permissions'}
                                // value={role?.permissions}
                                // noChip={true}
                            />
                        </div>
                    )}
                    <div className={classes.scroll}>
                        {role?.permissions ? role.permissions.map((i, j) => (
                                <div key={j} className={classes.tablePermissionsBodyContentStyle}>
                                    <div>
                                        <img className={classes.perImg} src={Images.checked} alt={"checked"}/>
                                        <div>
                                            <SlicedText type={'name'} size={17} data={i?.title}/>
                                            <SlicedText type={'desc'} size={40} data={i.description}/>
                                            {/*<TextRow name={i?.title} textWidth={10}/>*/}
                                            {/*<TextRow name={i?.description} textWidth={10}/>*/}
                                        </div>
                                    </div>
                                    <div>
                                        <DeleteButton
                                            toolTipTitle={"Remove Permission"}
                                            handleClick={() => handleOpenClose(i)}
                                        />
                                    </div>
                                </div>
                            )) :
                            <NoInfoYet text={'Select Role'}/>
                        }
                    </div>
                </div>
            }
            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader?.length}
                        text={'Delete Permission?'}
                        className={classes}
                        handleClose={handleOpenClose}
                        handleDel={deletePermissions}
                        info={permission?.title}
                    />
                }
            />
        </div>
    );
};
