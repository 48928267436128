import React from "react";
import { DeleteButton, HtmlTooltip, MinLoader, TextRow, TypeRow } from 'components';
import {AntSwitch, Colors, PermissionsList, RenderEquipmentType, RolePermission, userType} from "utils";
import { equipmentType } from './addEquipmentModal';

export const equipmentHead = [
    {
        name: 'serviceType', title: 'Service Type',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Solo', id: 'SOLO'},
            {name: 'Team', id: 'TEAM'}
        ]
    },
    {
        name: 'equipmentType', title: 'Equipment Type',
        filterList: [
            {name: 'All', id: ''},
          ...equipmentType
            // {name: 'Dry Van', id: 'DRYVAN'},
            // {name: 'Reefer', id: 'REEFER'},
            // {name: 'Flatbed', id: 'FLATBED'},
            // {name: 'Power Only', id: 'POWER_ONLY'},
            // {name: 'Drayage', id: 'DRAYAGE'},
            // {name: 'Box Truck', id: 'BOX_TRUCK'},
            // {name: 'Sprinter Van', id: 'SPRINTER_VAN'},
        ]
    },
    {name: 'originCity', title: 'Origin City', custom: false},
    {name: 'desiredDestinations', title: 'Desired Destinations', custom: false},
    {
        name: 'trailerLength', title: 'Trailer Length',
        filterList: [
            {name: 'All', id: ''},
            {name: 20, id: '20'},
            {name: 24, id: '24'},
            {name: 26, id: '26'},
            {name: 40, id: '40'},
            {name: 48, id: '48'},
            {name: 53, id: '53'},
        ]
    },
    {name: '', title: 'Return to Home Base', custom: false},
    {name: '', title: 'Action', notSearch: true, custom: false,},
]

export const equipmentBody = [
    {rowText: (item) => <TypeRow text={item?.serviceType}/>},
    {
        rowText: (item) => <TypeRow text={ RenderEquipmentType(item?.equipmentType)}/>
    },
    {rowText: (item) => <TextRow name={item?.originCity ? item?.originCity : 'Not Set'} textWidth={10}/>},
    {
        rowText: (item) => {
            return item?.returnToHomebase? (
                <TextRow name={
                    item?.homebaseCity && item?.homebaseState ? `${item?.homebaseCity}, ${item?.homebaseState}` :
                      item?.homebaseCity ? `${item?.homebaseCity}` :
                        item?.homebaseState ? `${item?.homebaseState}` :
                          'N/A'}
                         textWidth={10}
                />
              )
              :
              <HtmlTooltip
                title={
                    <div>
                        {!!item?.regions?.length && item?.regions?.map((i, j) => (
                          <p key={j}>{i}</p>
                        ))}
                    </div>
                }
                placement="top-start"
              >
                  <p style={{textOverflow: 'ellipsis',}}>
                      {
                          item?.regions?.length > 0 ?
                            item?.regions?.length >= 4 ? `${item?.regions[0]}, ${item?.regions[1]},  ${item?.regions[2]},  ${item?.regions[3]}...` :
                              item?.regions?.length === 1 ? item?.regions[0] :
                                item?.regions?.length >= 2 ? `${item?.regions[0]}, ${item?.regions[1]}...` :
                                  item?.regions[0] :
                            'Not Set'
                      }
                  </p>
              </HtmlTooltip>

        }
          //
          // <TextRow name={item?.destinations?.length ? `${item?.destinations[0]} ...` : ''}
          //                           textWidth={10}/>
    },
    {rowText: (item) => <TextRow name={item?.trailerLength} textWidth={10}/>},
    {
        button: (fn, item, load) => <div className='align-center'>
            <div className={'align-center'} style={{marginLeft: '10px'}}>
                <span className='small-switch-text' style={{width:'100%'}}>
                     <TextRow name={`${item?.homebaseState ? item?.homebaseState : ''} ${item?.homebaseCity ? `/${item?.homebaseCity}` : ''}` } textWidth={10}/>
                </span>
                {load === true ?
                    <MinLoader position={'relative'} style={{margin: 0}} color={Colors.ThemeBlue}/>
                    :
                    <AntSwitch
                        disabled={!item?.homebaseState ||
                            !RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code])
                        }
                        onClick={() => fn('homeBase')}
                        checked={item?.returnToHomebase === true}
                    />
                }
            </div>
        </div>, notClickable: true
    },
    {
        button: (fn, item, load) => <div className='align-center'>
            <div className={'align-center'} style={{marginLeft: '10px'}}>
                <span style={{width: '50px'}}
                      className='small-switch-text'>{item?.status === 'ACTIVE' ? "Active" : "Inactive"}</span>
                {load === true ?
                    <MinLoader position={'relative'} style={{margin: 0}} color={Colors.ThemeBlue}/>
                    :
                    <AntSwitch
                        onClick={() => fn('status')}
                        checked={item?.status === 'ACTIVE'}
                        disabled={!RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code])}
                    />
                }
            </div>
            {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                <DeleteButton toolTipTitle={"Remove Role"} handleClick={() => fn('delete')}/>
            }
        </div>, notClickable: true
    },
]

export const ACTION_TYPE = 'GET_EQUIPMENT'