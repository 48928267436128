import {Images} from "utils";
import {IconAndTitle, PhoneRow, TextRow} from "components";

export const adminsHead = [
    {name: 'firstName', title: 'Name'},
    {name: 'email', title: 'Email Address',},
    {name: 'address', title: 'Address'},
    {name: 'phoneNumber', title: 'Phone Number', custom: false},
]

export const adminsBody = [
    {rowText: (item) => <IconAndTitle icon={Images.human} title={`${item?.firstName} ${item?.lastName ? item.lastName : ''}`}/>},
    {rowText: (item) => <TextRow name={item?.email} textWidth={10}/>},
    {rowText: (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10} />},
    {rowText: (item) => <PhoneRow phone={item?.phoneNumber}/>},
]

export const ACTION_TYPE = 'GET_ADMINS'