import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const payablesStyles = makeStyles(() => ({
    selectedItemsWrapper:{
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '8px 8px 0px 0px',
        height: '70px',
        width: '100%',
        position: 'relative',
        zIndex: 9,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'9px 32px 16px 32px',
    },

    itemsInfo:{
        display:'flex',
        alignItems:'center',
        '& p':{
            fontSize:'16px',
            fontWeight:'600',
            color:Colors.TextSecondary,
            marginLeft:'24px',
        }
    }

}))