import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./reports.service";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    GET_AP_ACTIVITY, GET_AP_ACTIVITY_SUCCESS, GET_AP_APPLICATION, GET_AP_APPLICATION_SUCCESS,
    GET_AP_DETAILED, GET_AP_DETAILED_SUCCESS, GET_AP_SUMMARY, GET_AP_SUMMARY_SUCCESS,
    GET_AR_ACTIVITY, GET_AR_ACTIVITY_SUCCESS,
    GET_AR_APPLICATION, GET_AR_APPLICATION_SUCCESS,
    GET_AR_DETAILED,
    GET_AR_DETAILED_SUCCESS,
    GET_AR_SUMMARY, GET_AR_SUMMARY_SUCCESS
} from "./reports.types";

/** Ar Reports */
function* arDetailed({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getArDetailedService, payload?.params, payload?.type);
        yield put({
            type: GET_AR_DETAILED_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put({
            type: GET_AR_DETAILED_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* arSummary({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const res = yield call(authService.getArSummaryService, payload?.params, payload?.type);
        yield put({
            type: GET_AR_SUMMARY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put({
            type: GET_AR_SUMMARY_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* arActivity({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getArActivityService);
        yield put({
            type: GET_AR_ACTIVITY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put({
            type: GET_AR_ACTIVITY_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* arApplication({payload, type}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.arApplicationService, payload?.param);
        yield put({
            type: GET_AR_APPLICATION_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put({
            type: GET_AR_APPLICATION_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    }
}

/** End */


/** Ap Reports */
function* apDetailed({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getApDetailedService, payload?.params, payload?.type);
        yield put({
            type: GET_AP_DETAILED_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put({
            type: GET_AP_DETAILED_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* apSummary({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getApSummaryService, payload?.params, payload?.type);
        yield put({
            type: GET_AP_SUMMARY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put({
            type: GET_AP_SUMMARY_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    }
}

function* apActivity({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getApActivityService);
        yield put({
            type: GET_AP_ACTIVITY_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put({
            type: GET_AP_ACTIVITY_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* apApplication({payload, type}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.apApplicationService, payload?.params);
        yield put({
            type: GET_AP_APPLICATION_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put({
            type: GET_AP_APPLICATION_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

/** End */

export const watchReport = function* watchReportSaga() {
    /** Ar Reports */
    yield takeLatest(GET_AR_DETAILED, arDetailed);
    yield takeLatest(GET_AR_SUMMARY, arSummary);
    yield takeLatest(GET_AR_ACTIVITY, arActivity)
    yield takeLatest(GET_AR_APPLICATION, arApplication)
    /** End */

    /** Ap Reports */
    yield takeLatest(GET_AP_DETAILED, apDetailed);
    yield takeLatest(GET_AP_SUMMARY, apSummary);
    yield takeLatest(GET_AP_ACTIVITY, apActivity)
    yield takeLatest(GET_AP_APPLICATION, apApplication)
    /** End */
};
