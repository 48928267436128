import React from "react";
import {PermissionsList, RolePermission} from "utils";
import {DateRow, PriceRow, TextRow, TypeRow, LinkRow, handleGetDate} from "components";
import { LoadService } from "fragments/loads/load.service";

export const payablesHead = [
    {name: '', title: ' ', notSearch: true, custom: false, width: '0'},
    {name: 'loadId',searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false, width: '120px'},
    {name: 'Carrier', searchKey:'CARRIER', title: 'Carrier', custom: false},
    {name: 'payable', nameRow:'payeeName', title: 'Payee', custom: false},
    {name: '', nameRow:'pmtMethod', title: 'Method',custom: false
        // filterList: [
        //     {name: 'All', id: ''},
        //     {name: 'Check', id: 'CHECK'},
        //     {name: 'Ach', id: 'ACH'},
        //     {name: 'Efs', id: 'EFS'},
        // ]
    },
    {name: '', nameRow:'pmtTerm', title: 'Term',custom: false
        // filterList: [
        //     {name: 'All', id: ''},
        //     {name: 'Custom Quick Pay', id: 'CHECK'},
        //     {name: '30 Day', id: 'ACH'},
        //     {name: 'Quick Pay', id: 'EFS'},
        // ]
    },
    {name: '', nameRow:'name', title: 'Aging', custom: false},
    {name: '', nameRow:'dueDate', title: 'Due Date', custom: false, type:'date'},
    {name: '', title: 'Final Amount', icon: 'date', smallSize: 6, type:'date', custom: false},
    {name: '', title: 'Actions', custom: false},
]

export const payablesBody = [
    {rowText: (item) => <LinkRow loadId={item?.id} id={item?.displayId}/>},
    {rowText:  (item) => <TextRow name={item?.carrier?.name} textWidth={12}  />},
    {rowText:  (item) => <TextRow name={item?.payable?.payeeName} textWidth={12}  />},
    {rowText: (item) => <TypeRow text={item?.payable?.pmtMethod ? item?.payable?.pmtMethod : ''}/>},
    {rowText: (item) => <TypeRow text={item?.payable?.pmtTerm ? item?.payable?.pmtTerm : ''} />},
    // {rowText: (item) => <div>{handleGetDate(item?.payable?.submittedAt)}</div>, type: 'returnColor'},
    {rowText: (item) => <div>{handleGetDate(item?.payable?.verifiedDate)}</div>,},
    {rowText: (item) => <DateRow date={item?.payable?.dueDate}/>},
    {rowText:  (item) => <PriceRow info={LoadService.getPayableBalance(item?.payable)}  />},
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code]) &&
    {button:  (item) =>  <button className={'request-correction'} onClick={() => item('correction')}>Request Corrections</button>, notClickable: true },
]

export const ACTION_TYPE = 'GET_LOADS_BY_STATUS'
export const PAYABLE = 'PAYABLE'