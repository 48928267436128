import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {CalendarInput, ExportButton, FullTable, Loader} from "components";
import {reportActions} from "store";
import {apApplicationBody, apApplicationHead, AP_APPLICATION_REQUEST_TYPE} from "./constants";
import {reportStyles} from "../styles";
import { DownloadFile, FindLoad } from 'utils';
import {Row, TotalRow} from "../core";

export const ApApplication = ({apApplication}) => {
    const dispatch = useDispatch()
    const classes = reportStyles()
    const loader = FindLoad(AP_APPLICATION_REQUEST_TYPE)
    const [date, selectedDate] = useState('')
    const { loadDownload, handleDownload, } = DownloadFile()

    const handleChangeDate = (e) => {
        selectedDate(e.target.value)
        const sendInfo = {}
        if (e.target.value) {
            sendInfo.month = e.target.value.slice(6, 8)
            sendInfo.year = e.target.value.slice(0, 4)
        } else {
            delete sendInfo.month
            delete sendInfo.year
        }
        dispatch(reportActions.getApApplication(sendInfo))
    }

    return (
        <div>
            <div className={classes.actionsWrapper}>
                <div/>
                <div className={classes.exportAndCalendar}>
                    <CalendarInput
                        value={date}
                        handleChangeDate={handleChangeDate}
                    />
                    <ExportButton
                      loader={!!loadDownload}
                      handleExport={() => handleDownload({file:apApplication?.file, name: 'APApplication.csv'})}
                    />
                </div>
            </div>
            {loader?.length ?
                <Loader/>
                :
                <>
                    <TotalRow
                        rows={
                            <>
                                <Row name={'Total'}
                                     value={apApplication?.totalPaid ? apApplication?.totalPaid : 0}
                                />
                            </>
                        }
                    />
                    <FullTable
                        head={apApplicationHead}
                        body={apApplicationBody}
                        list={apApplication?.items?.length ? apApplication?.items : []}
                        noText={'Payment Application'}
                    />
                </>
            }
        </div>
    )
}