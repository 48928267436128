import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {CloseButton, CreateChancel, ValidationInput} from "components";
import {ErrorText, FindLoad, FindSuccessItem, globalModals, useGlobalTextStyles} from 'utils'
import {httpRequestsOnSuccessActions, officeActions} from "store";

const ADD_TYPE = 'ADD_OFFICE_EXPENSES'
const SUBTRACT_TYPE = 'SUBTRACT_OFFICE_EXPENSES'

export const ExpensesModal = ({handleClose, modalType, reqType, value}) => {
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch()
    const params = useParams()
    const [number, setNumber] = useState('')
    const [error, setError] = useState('')
    const success = FindSuccessItem(ADD_TYPE)
    const successSubtract = FindSuccessItem(SUBTRACT_TYPE)
    const load = FindLoad(ADD_TYPE)
    const loadSubtract = FindLoad(SUBTRACT_TYPE)

    useEffect(() => {
        if(success || successSubtract){
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ADD_TYPE))
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SUBTRACT_TYPE))
        }
    }, [success, successSubtract])

    const renderTitle = () => {
        if (modalType === 'phone') {
            return 'Add Phone lines'
        } else if (modalType === 'dat') {
            return 'Add DAT Accounts'
        } else if (modalType === 'miscellaneous') {
            return 'Add Miscellaneous'
        } else if (modalType === 'phoneSub') {
            return 'Subtract Phone lines'
        } else if (modalType === 'datSub') {
            return 'Subtract DAT Accounts'
        } else if (modalType === 'miscellaneousSub') {
            return 'Subtract Miscellaneous'
        }
    }

    const handleCLick = () => {
        if(number) {
            const senderInfo = {}

            if (modalType === 'phone') {
                senderInfo.phoneLines = +number
            } else if (modalType === 'dat') {
                senderInfo.datAccounts =  +number
            } else if (modalType === 'miscellaneous') {
                senderInfo.miscellaneous = +number
            } else if (modalType === 'phoneSub') {
                senderInfo.phoneLines = +number
            } else if (modalType === 'datSub') {
                senderInfo.datAccounts = +number
            } else if (modalType === 'miscellaneousSub') {
                senderInfo.miscellaneous = +number
            }

            if(modalType === 'phoneSub' || modalType === 'datSub' || modalType === 'miscellaneousSub'){
                dispatch(officeActions.subtractOfficeExpense(params?.id, senderInfo))
            }else{
                dispatch(officeActions.addOfficeExpense(params?.id, senderInfo))
            }
        }else{
            setError('number')
        }
    }

    const handleSetNumber = (e) => {
        if(reqType !== 'subtract' && e.target.value > 0 || e.target.value === ''){
            setNumber(e.target.value)
            setError('')
        }
        if(reqType === 'subtract' && value >= e.target.value  && e.target.value > 0 || e.target.value === ''){
            setNumber(e.target.value)
            setError('')
        }
    }

    return (
        <div className={globalModalsClasses.smallModalWrapper}>
            <div className={globalModalsClasses.smallModalClose}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className={globalModalsClasses.modalWrapperContent}>
                <p className={globalText.modalTitle}>{renderTitle()}</p>
                <p className={globalText.modalText}>{'These numbers cannot be below 0 and have to be integer'}</p>

                <ValidationInput
                    onChange={ handleSetNumber }
                    value={number}
                    typeError={error === 'number' && ErrorText.field}
                    label={`${renderTitle()}*`}
                    type={"number"}
                    variant={"outlined"}
                    name={"number"}
                />

                <CreateChancel
                    loader={!!load?.length || !!loadSubtract?.length}
                    create={"Add"}
                    chancel={"Cancel"}
                    onCreate={handleCLick}
                    onClose={handleClose}
                />
            </div>
        </div>
    )
}