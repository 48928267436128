import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Colors, FindLoad, Images, PermissionsList, RolePermission} from "utils";
import {Loader, MinLoader} from "components";
import {termActions} from "store";
import {systemInfoFragments} from "./styles";

export const Quickbooks = ({quickStatus}) => {
    const {quickbook} = useSelector((state) => ({
        quickbook: state.terms.quickbook
    }))
    const classes = systemInfoFragments()
    const dispatch = useDispatch();
    const loader = FindLoad('GET_QUICKBOOKS')
    const createLoader = FindLoad('CREATE_QUICKBOOKS')

    useEffect(() => {
        dispatch(termActions.getQuickbook())
    }, [])

    const handleAdd = () => {
        dispatch(termActions.addQuickbook())
    }

    if (loader?.length) {
        return <Loader/>
    }


    return (
        <div className={classes.quickBooksWrapper}>
            <div className={classes.quickbooksNameIcon}>
                <img src={Images.quickbooks} alt="icon"/>
                <p>Quickbooks</p>
            </div>

            {(quickbook === true || quickStatus === '?status=true') &&
                <p className={classes.textStyle}>
                    <span style={{color: '#00C851'}}>Success:</span> Your data has been synced with QuickBooks.
                </p>
            }
            {(quickbook === false || quickStatus === '?status=false') &&
                <>
                <p className={classes.textErrorStyle}>
                    To sync your data with QuickBooks, please Log in to your <br/> account
                    on our system.
                </p>

            {RolePermission([PermissionsList.ADD_QUICKBOOKS?.code]) &&
                <button
                    className={classes.loginButton}
                    onClick={handleAdd}
                >
                    {createLoader?.length ?
                        <MinLoader margin={'0'} color={Colors.TextWhite} position={'relative'}/>
                        :
                        'Log in'
                    }
                </button>
            }
                </>
            }

            {/*{!quickbook && RolePermission([PermissionsList.ADD_QUICKBOOKS?.code]) &&*/}
            {/*    <>*/}
            {/*        <p className={classes.textStyle}>*/}
            {/*            To sync your data with QuickBooks, please Log in to your <br/> account*/}
            {/*            on our system.*/}
            {/*        </p>*/}
            {/*        <button*/}
            {/*            className={classes.loginButton}*/}
            {/*            onClick={handleAdd}*/}
            {/*        >*/}
            {/*            {createLoader?.length ?*/}
            {/*                <MinLoader margin={'0'} color={Colors.TextWhite} position={'relative'}/>*/}
            {/*                :*/}
            {/*                'Log in'*/}
            {/*            }*/}
            {/*        </button>*/}
            {/*    </>*/}
            {/*}*/}
        </div>
    )
}