import {
  ACTIVATE_ADMIN,
  ADD_ADMIN_ROLE,
  CREATE_ADMIN,
  EDIT_ADMIN,
  GET_ADMIN_BY_ID,
  GET_ADMIN_INFO_BY_ID,
  GET_ADMINS,
  INACTIVATE_ADMIN,
  REMOVE_ADMIN_ROLE, RESEND_ADMIN_INVITE,
  SEARCH_BY_TYPE,
} from "./admin.types";

/** Create, Edit Admin */
export const createAdmin = (body) => {
  return {
    type: CREATE_ADMIN,
    payload: { body }
  }
}

export const editAdmin = (id, body) => {
  return {
    type: EDIT_ADMIN,
    payload: { id, body }
  }
}

export const resendAdminInvite = (id) => {
  return {
    type: RESEND_ADMIN_INVITE,
    payload: { id }
  }
}
/** End */

/** Get Admins */
export const getAdmins = ( params ) => {
  return {
    type: GET_ADMINS,
    payload: { params }
  }
}

export const getAdminById = (adminId, load) => {
  return {
    type: GET_ADMIN_BY_ID,
    payload: { adminId, load }
  }
}

export const getAdminInfo = (adminId) => {
  return {
    type: GET_ADMIN_INFO_BY_ID,
    payload: { adminId }
  }
}
/** End */

/** Activate or Inactivate Admin */

export const activateAdmin = (id) =>{
  return{
    type: ACTIVATE_ADMIN,
    payload:{id}
  }
}

export const inactivateAdmin = (id) =>{
  return{
    type: INACTIVATE_ADMIN,
    payload:{id}
  }
}
/** End */

/** Admins Filters */
export const search =( value, type, listStatus )=>{
  return{
    type:SEARCH_BY_TYPE,
    payload:{value, type, listStatus }
  }
}


/** Add and Remove Role */
export const addAdminRole =(id, roleId )=>{
  return{
    type:ADD_ADMIN_ROLE,
    payload:{id, roleId}
  }
}

export const removeAdminRole =( id, roleId )=>{
  return{
    type:REMOVE_ADMIN_ROLE,
    payload:{id, roleId}
  }
}
/** End */
