import {makeStyles} from "@material-ui/core/styles";


export const systemInfoFragments = makeStyles(() => ({

    payrollItemsWrapper: {
        height: '50px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        padding: '0 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
        '& p': {
            width: '100px'
        }
    },
    delEditButtons: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        width: '10%',
    },
    addButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    inputsWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '24px',
    },
    inputsStyle: {
        marginRight: '16px',
        width: '100%'
    },
    quickBooksWrapper: {
        background: '#F7F9FC',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '24px 0'
    },
    quickbooksNameIcon: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontWeight: '700',
            fontSize: '24px',
            color: '#2A374E',
            marginLeft: '12px'
        },
    },
    textStyle: {
        fontWeight: '400',
        fontSize: '16px',
        color: '#2A374E',
        textAlign: 'center',
        margin: '18px 0 26px 0',
    },
    loginButton: {
        width: '238px',
        height: '48px',
        background: '#438AFE',
        boxShadow: '0px 0px 6px rgba(67, 138, 254, 0.3)',
        borderRadius: '8px',
        border: 'none',
        fontWeight: '600',
        fontSize: '16px',
        color: '#FFFFFF',
    },
    textErrorStyle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#D72525',
        margin: '18px 0 26px 0',
    }

}))