import { makeStyles } from "@material-ui/core/styles";
import { Images, Colors } from "utils";

export const loginPage = makeStyles((theme) => ({
  loginPageBodyPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  newContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
  },

  leftSide: {
    width: "50%",
    backgroundImage: `url(${Images.SignLeft})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    overflow: "hidden",
    height: "100%",
    minHeight: "100vh",
  },

  boxTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh",
    marginLeft: "100px",
  },

  siteLog: {
    marginTop: "16px",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "700",
    color: "#ffffff",
    marginLeft: "100px",
  },

  rigthSide: {
    width: "50%",
    background: "#ECECEC",
    height: "100%",
    minHeight: "100vh",
  },

  formContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "100vh",
  },

  sitePhone: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  },

  LoginHeadPhoneNumber: {
    marginRight: "100px",
    "& a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },

    "& img": {
      marginRight: "8px",
    },

    "& span": {
      fontSize: "16px",
      lineHeight: "22px",
      color: `#2A374E`,
    },
  },

  loginPageBody: {
    backgroundImage: `url(${Images.tracker})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    overflow: "hidden",
    height: "calc(100vh - 60px)",
    background: "linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,0.9))",

    padding: "90px 42px",
    // "@media (max-width: 1918px)": {
    //
    // },
    "@media (min-width: 1919px)": {
      padding: "152px 100px",
    },
  },

  loginPageTitle: {
    borderLeft: "12px solid #ECC711",
    height: "177px",
    "& p": {
      fontSize: "40px",
      lineHeight: "64px",
      fontWeight: "800",
      letterSpacing: "2.4px",
      color: "#FFFFFF",
      textTransform: "uppercase",
      // marginTop: "-8px",
      marginLeft: "12px",
      "@media (min-width: 1920px)": {
        marginLeft: "24px",
      },
    },
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: "100%",
  },

  submit: {
    height: "50px",
    background:
      "transparent linear-gradient(90deg, #5690FF 0%, #766DE8 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 16px #387DFF4D",
    color: "white",
    fontWeight: "bold",
    "& .Mui-disabled": {
      opacity: "0.5",
      color: "white",
      fontWeight: "bold",
    },
  },

  CopyRightPos: {
    position: "absolute",
    bottom: "24px",

    "@media (max-width: 1918px)": {
      marginLeft: "42px",
    },
    "@media (min-width: 1919px)": {
      marginLeft: "102px",
    },

    "@media (max-width: 767px)": {
      marginLeft: "16px",
    },
  },
}));
