import {
    CREATE_BRANCH_MANAGERS_SUCCESS,
    GET_BRANCH_BY_ID_SUCCESS,
    GET_BRANCHES_SUCCESS,
} from "./branches.types";

const initialState = {
    branchesList: [],
    // branchesListReserve:[],
    branchById: null,
    branchManagerList: []
};

export const branchReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Offices */

        case GET_BRANCHES_SUCCESS:
            return {
                ...state,
                branchesList: action.payload,
            }

        case GET_BRANCH_BY_ID_SUCCESS:
            return {
                ...state,
                branchById: action.payload
            }

        /** End */

        /** Office Management */

        // case GET_OFFICE_MANAGERS_SUCCESS:{
        //   return {
        //     ...state,
        //     officeManagerList:action.payload
        //   }
        // }

        /** End */


        case CREATE_BRANCH_MANAGERS_SUCCESS: {
            return {

                branchManagerList: [...state.branchManagerList, ...action.payload]
            }
        }
        default:
            return state;
    }
};
