import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    FindLoad,
    FindSuccessItem,
    Images as images, PermissionsList, RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {officesInfoFragments} from "./index";
import {
    AddButton,
    DeleteButton,
    DeleteElement, Line, Loader,
    Management, NoYet,
    SimpleModal,
} from "components";
import {AddManagerModal} from "./addManagerModal";
import {
    httpRequestsOnSuccessActions,
    officeActions
} from "store";

export const OfficeManagement = ({}) => {
    const {assignedOfficeMc, officeAgentList, assignedByType} = useSelector((state) => ({
        officeAgentList: state.agents.officeAgentList,
        assignedByType: state.mcs.assignedByType,
        assignedOfficeMc: state.mcs.assignedOfficeMc,
    }));
    const classes = officesInfoFragments()
    const globalTextStyle = useGlobalText()
    const globalStyle = useGlobalStyles()
    const dispatch = useDispatch()
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [removeOpen, setRemoveOpen] = useState(false)
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [info, setInfo] = useState('')
    const [type, setType] = useState('')
    const success = FindSuccessItem('CREATE_OFFICE_MANAGERS')
    const removeSuccess = FindSuccessItem('REMOVE_OFFICE_MANAGERS')
    const loadRemove = FindLoad('REMOVE_OFFICE_MANAGERS')
    const loader = FindLoad('GET_OFFICE_ASSIGNED_MCS')
    // const loader = FindLoad('GET_ASSIGNED_MCS')

    const handleOpenClose = (type) => {
        if (type === 'create') {
            setType(type)
            setOpen(!open)
            setInfo('')
        } else {
            setType('')
            setOpen(!open)
        }
    }

    const handleOpenCloseRemoveModal = (i) => {
        setRemoveOpen(!removeOpen)
        setId(i?.id)
        setName(`${i?.firstName} ${i?.lastName}`)
    }

    const handleRemove = () => {
        const data = {
            'officeId': params.id,
            'managerId': id
        }
        dispatch(officeActions.removeOfficeManager(data))
    }

    useEffect(() => {
        if (removeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('REMOVE_OFFICE_MANAGERS'))
            setRemoveOpen(false)
        }
    }, [removeSuccess])

    useEffect(() => {
        if (success) {
            setOpen(false)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CREATE_OFFICE_MANAGERS'))
        }
    }, [success]);

    const handleEditManager = (i) => {
        setInfo(i)
        handleOpenClose()
    }

    return (
        <div className={classes.officeManagement}>
            <div>
                <div className={classes.lineWrapper}>
                    <Line height={'480px'}/>
                    <div className={globalStyle.fullScreen}>

                        <div className={classes.managementWrapper}>
                            <Management

                                head={
                                    <div className={classes.managementHead}>
                                        <p className={globalTextStyle.smallText}>Office Managers</p>
                                        {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
                                            <AddButton
                                                handleClick={() => handleOpenClose('create')}
                                                text={"Add Office Manager"}
                                            />
                                        }
                                    </div>
                                }
                                body={
                                    <div className={classes.officeManagementHead}>{
                                        officeAgentList.length ?
                                            officeAgentList.map((i, j) => (
                                                <div key={j}>
                                                    <div className={classes.officeManagementHeadTitle}>
                                                        <p>{`${i.firstName} ${i.lastName}`}</p>

                                                        {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
                                                            <div>
                                                                <button onClick={() => handleEditManager(i)}>
                                                                    <img src={images.edit} alt="edit"/>
                                                                </button>
                                                                <DeleteButton
                                                                    toolTipTitle={"Remove Manager"}
                                                                    handleClick={() => handleOpenCloseRemoveModal(i)}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )) : <NoYet text={'No Managers Yet'}/>}
                                    </div>
                                }
                            /></div>


                        <Management
                            head={
                                <div>
                                    <div className={classes.managementHead}>
                                        <p style={{marginTop: '10px'}} className={globalTextStyle.smallText}>Authorities</p>
                                    </div>
                                </div>
                            }
                            body={
                                <div className={classes.authoritesManagementHead}>
                                    {loader?.length ?
                                        <Loader style={'relative'} height={'250px'}/>
                                        :
                                        <div>
                                            {assignedOfficeMc.length ? assignedOfficeMc.map((i, j) => (
                                                    <div key={j} className={classes.authorityRow}>
                                                        <div className={classes.authoritiesManagementHeadTitle}>
                                                            <p>{i?.name} - <span
                                                                style={{fontWeight: 'bold'}}>{i?.mcNumber}</span></p>
                                                        </div>
                                                    </div>
                                                )) :
                                                <NoYet text={'No Authorities Yet'}/>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <AddManagerModal
                        type={type}
                        info={info}
                        handleClose={handleOpenClose}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={handleOpenCloseRemoveModal}
                openDefault={removeOpen}
                content={
                    <DeleteElement
                        loading={!!loadRemove?.length}
                        text={'Delete Manager?'}
                        className={globalStyle}
                        handleClose={handleOpenCloseRemoveModal}
                        handleDel={handleRemove}
                        info={name}
                    />
                }
            />
        </div>
    )
}