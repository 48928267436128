export const mcUploadDockTypes = [
  { name: 'Notice of Assignment', id: 'NOA' },
  { name: 'Release letter', id: 'REL' },
  { name: 'Carrier Packet', id: 'CARRIER_PACKET' },
  { name: 'COI', id: 'COI' },
  { name: 'W-9', id: 'W9' },
  { name: 'Company packet', id: 'COMPANY_PACKET' },
  { name: 'LOR', id: 'LOR' },
  { name: 'UCC Filing', id: 'UCC_FILING' },
  { name: 'Factoring Contract', id: 'FACTORING_CONTRACT' },
  { name: 'Insurance Certificate', id: 'INSURACNE_CERTIFICATE' },
  { name: 'Other', id: 'OTHER' },
];

export const mcDockTypes = [
  { name: 'All Documents', id: 'All DocumentsSection' },
  ...mcUploadDockTypes,
];
