import {
    CheckboxesTags,
    DeleteButton, DeleteElement,
    EditSaveButtons, Line, Loader,
    Management,
    NoYet, SimpleModal
} from "components";
import {FindLoad, FindSuccessItem, Images, useGlobalStyles, useGlobalText} from "utils";
import React, {useEffect, useState} from "react";
import {adminInfoFragments} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {httpRequestsOnSuccessActions, mcActions} from "store";
import {useParams} from "react-router-dom";
import {DELETE_ASSIGNED_MC} from "../../../../store/mc/mc.types";

export const McManagement = ({}) => {
    const params = useParams()
    const dispatch = useDispatch()
    const [disables, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)
    const [office, setOffice] = useState('')
    const classes = adminInfoFragments()
    const globalText = useGlobalText()
    const globalClass = useGlobalStyles()
    const [item, setItem] = useState('')
    const removeSuccess = FindSuccessItem('DELETE_ASSIGNED_MC')
    const removeLoader = FindLoad('DELETE_ASSIGNED_MC')
    const assignLoader = FindLoad('BRANCH_ASSIGNED_MC')

    const {officesList, assignedOffices, assignedMcList} = useSelector((state) => ({
        officesList: state.offices.officesList,
        assignedOffices: state.mcs.assignedOffices,
        assignedMcList: state.mcs.assignedMcList,
    }));


    const deleteOffice = () => {
        const officeId = office.id
        const mcId = params.id
        dispatch(mcActions.deleteAssignedMs(officeId, mcId, 'OFFICE'))
    }

    const addMc = (office) => {
        if (office.length) {
            const officeId = office[office.length - 1].id
            const mcId = params.id
            dispatch(mcActions.assignMs(officeId, mcId, 'OFFICE'))
        }
    }
    const handleOpen = (info) => {
        dispatch(mcActions.getBranchAssignedMs(params.id, 'BRANCH'))
        setItem(info)
    }
    const handleCreate = () => {
        setDisabled(!disables)
    }
    const handleOpenClose = (id) => {
        setOpen(!open)
        setOffice(id)
    }

    const newList = officesList?.offices?.filter(function (array_el) {
        return assignedOffices.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    useEffect(() => {
        if (removeSuccess) {
            setOpen(!open)
            setOffice('id')
            dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_ASSIGNED_MC'))
        }
    }, [removeSuccess])

    return (
        <div className={classes.accessWrapper}>
            <div className={globalClass.flexAble} style={{display: 'flex'}}>
                {assignedOffices && <Line height={'550px'}/>}
                <Management
                    height={524}
                    type={'big'}
                    head={
                        <div className={classes.managementHead}>
                            <p className={globalText.smallText}>Assigned to: </p>
                            <EditSaveButtons
                                butt={'done'}
                                type={'EDIT_MC'}
                                handleChancel={() => setDisabled(true)}
                                handleSetEdit={() => setDisabled(false)}
                                handleSaveInfo={handleCreate}
                            />
                        </div>
                    }
                    body={
                        <div>
                            {!disables &&
                                <div className={classes.officeSelectInput}>
                                    <CheckboxesTags
                                        type={'mc'}
                                        handleChange={addMc}
                                        permissionsList={newList}
                                        label={"Add Office"}
                                        placeholder={'Add Office'}
                                    />
                                </div>
                            }
                            <div className={classes.accessBody}>
                                <div className={classes.halfItem}>
                                    {assignedOffices.length ?
                                        assignedOffices.map((i, j) => (
                                            <div key={j}
                                                 className={item.id === i.id ? globalClass.activeItem : globalClass.item}
                                                 onClick={() => handleOpen(i)}>
                                                <p>{i.name}</p>
                                                <div>{!disables &&
                                                    <DeleteButton
                                                        toolTipTitle={"Remove Office"}
                                                        handleClick={() => handleOpenClose(i)}
                                                    />}
                                                    <img className={classes.arrow}
                                                         src={item.id === i.id ? Images.arrowLeft : Images.arrowRight}
                                                         alt="arrow"/>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <NoYet position={'center'} text={'No Offices Yet'}/>
                                    }
                                </div>

                                <div className={classes.roleNamePer}>
                                    {item ?
                                        assignLoader?.length ?
                                            <Loader
                                                height={'33vh'}
                                                style={'relative'}/>
                                            :
                                            <div>
                                                <p className={classes.roleNamTitle}>Assigned to
                                                    the {item.name} branches:</p>
                                                <div className={classes.roleWrapper}>
                                                    {assignedMcList.length ? assignedMcList.map((i, j) => (
                                                            i.office === item.id &&
                                                            <div key={j} className={classes.roleItem}>
                                                                <img src={Images.checked} alt="checked"/>
                                                                <p>{i.name}</p>
                                                            </div>
                                                        )) :
                                                        <NoYet position={'center'} text={'No Assigned Branches'}/>
                                                    }
                                                </div>
                                            </div>
                                        :
                                        <div className={classes.select}>
                                            <NoYet position={'center'} text={'Select Office'}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!removeLoader.length}
                        text={'Delete Office?'}
                        handleClose={handleOpenClose}
                        handleDel={deleteOffice}
                        info={office && office.name}
                    />
                }
            />
        </div>
    )
}