import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const customerInformation = makeStyles(() => ({

    lineWrapper: {
        display: 'flex',
    },

    managementHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    inputsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px',
    },

    officeManagement: {
        width: '100%',
    },

    officeManagementHead: {
        marginTop: '24px',
        "@media (min-width: 1920px)": {
            marginTop: '32px'
        }
    },
    authoritesManagementHead: {
        marginTop: '34px',
        "@media (min-width: 1920px)": {
            marginTop: '40px'
        }
    },
    officeManagementHeadTitle: {
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginBottom: '8px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',

        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },


    accessWrapper: {
        width: '100%',
        marginTop: '24px'
    },

    accessBody: {
        display: "flex",
        justifyContent: 'space-between',
    },


    half: {
        width: '49.1%',
    },

    halfItem: {
        width: '50%',
        marginTop: '16px',
        height: '340px',
        overflow: 'auto',
    },

    officeSelectInput: {
        marginBottom: '24px',
        width: '50%',
        marginTop: '32px',
    },

    roleNamePer: {
        // background: Backgrounds.tableActive,
        // borderRadius: '4px',
        padding: '16px',
        width: '50%',
        marginLeft: '16px',
        marginTop: '-43px',
        // height: '328px',
        "@media (min-width: 1920px)": {
            marginLeft: '24px',
        },
    },

    roleNamePerBack: {
        background: Backgrounds.tableActive,
        borderRadius: '4px',
        padding: '16px',
        width: '100%',
        marginTop: '16px',
        height: '216px',

        '& p': {
            fontSize: '14px',
            lineHeight: '21px',
            color: Colors.TextPrimary
        }
    },
    roleWrapper: {
        marginTop: '30px',
        height: '260px',
        overflow: 'auto',
    },
    roleNamTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextPrimary,
        marginBottom: '24px',

    },

    roleItem: {
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            color: Colors.TextPrimary,
        },
        '& img': {
            width: '18px',
            height: '18px',
            marginRight: '8px',
        }
    },

    select: {
        marginTop: '-15px',
    },

    arrow: {
        marginLeft: '10px',
    },

    credit: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '8px',
        padding: '24px',
        width: '100%',
        marginTop: '26px',
    },

    infoRows: {
        display: 'flex',
        width: '100%',
    },
    titleInputRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '24px'
    },
    simpleInput: {
        width: '49%'
    },
    infoRowsWrapper: {
        width: '100%',
    },
    creditWrapper: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    creditLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    inputs: {
        display: "flex",
        marginTop: '24px'
    },
    creditInput: {
        marginLeft: '24px',
        width: '100%'
    },
    creditAuthority: {
        display: 'flex',
        width: '100%',
        // marginTop:'32px',
    },
    rows: {
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
    title: {
        fontSize: '16px',
        colo: Colors.TextSecondary
    },
    price: {
        fontSize: '16px',
        fontWeight: '600',
        color: Colors.TextSecondary,
    },
    mcRow: {
        fontSize: '16px',
        fontWeight: '600',
        color: Colors.ThemeBlue,
    },
    fullScreen: {
        display: 'flex',
        width: '100%'
    },

    creditDebitWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    modalDimensions: {
        width: '543px',
        background: 'white',
        position: 'relative',
        borderRadius: 8,
        height: '780px',
        overflow: 'hidden',
        padding: '8px 32px 0 32px',

        "@media (min-width: 1920px)": {
            padding: '8px 40px 0 40px',
        },
        "@media (max-width: 1400px)": {
            width: '614px',
        },
    },
    positionedButton: {
        position: 'absolute',
        right: '8px',
        top: '8px'
    },
    modalTitle: {
        textAlign: 'center',
        background: Backgrounds.headerLightBlue,
        paddingTop: '32px',
        paddingBottom: '10px'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    otherDetailsTitle: {
        fontSize: '16px',
        color: Colors.TextSecondary,
        fontWeight: '600',
        lineHeight: '22px',
        paddingBottom: '18px',
    },
    titlePadding: {
        paddingTop: '3px'
    },
    selectMargin: {
        width: "100%",
        marginRight: '16px',

        "& .MuiFormLabel-root": {
            fontSize: "16px",
            color: `${Colors.TextPrimary}`,
        },

        "& .MuiInput-underline.Mui-error:after": {
            borderBottomColor: `${Colors.ThemeRed}`,
        },
    },

    closeButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
    modalPaddings: {
        padding: '8px 40px 0 40px',
    },

    inactiveModal: {
        padding: '8px 0 40px 0',
        display: 'flex',
        flexDirection: 'column',
        // textAlign:'center',
        width: '580px',
        height: '481px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '8px'
    },

    inactiveInput: {
        height: '100px',
        marginTop: '16px',
        width: '100%',
        marginBottom: '80px',
        '& .MuiOutlinedInput-inputMultiline': {
            maxHeight: '100px',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '& .MuiOutlinedInput-root': {
            height: '128px',
            minHeight: '128px',
        },
    },
    clickableArrow: {
        width: '80%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },

    rateSwitch: {
        display: 'flex',
        alignItems: 'center',
        '@media (min-width: 1280px)': {
            marginRight: '32px',

        },
        '@media (min-width: 1920px)': {
            marginRight: '40px',
        },
    },

    notesInput: {
        width: '100%',
        height: '146px',
        padding: '16px',
        background: '#F2F5F9',
        borderRadius: '4px',
        color: '#51566D',
        fontSize: '14px',
        fontWeight: '400',
        border: 'none',
        outline: 'none',
        resize: 'none'
    },
    requestConsignment: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '24px',
    },
    settingWrapper: {
        background: '#E6ECF3',
        borderRadius: '8px',
        padding: '20px 26px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    settingsTitle: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '28px',
        color: '#2A374E',
        marginLeft: '16px'
    }
}))