import {GET_TEMPLATE_BY_ID_SUCCESS, GET_TEMPLATES_SUCCESS} from "./templates.types";

const initialState = {
    templates: null,
    templateById: null,
};

export const templatesReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Templates */

        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.payload
            }

        case GET_TEMPLATE_BY_ID_SUCCESS:
            return {
                ...state,
                templateById: action.payload
            }

        /** End */

        default:
            return state;
    }
};
