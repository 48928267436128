import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const customerRate = makeStyles(() => ({
    itemsWrapper: {
        display: 'flex',
        margin: '16px 0 16px 32px ',
    },

    modalWidth:{
        width:'580px'
    },

    transactionItems: {
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        height: '40px',
        padding: '0 16px',
        marginRight: '32px',
        display: 'flex',
        alignItems: 'center',
        width:'100%',

        '& p': {
            color: Colors.TextPrimary,
            fontSize: '16px',
        },
        '& span': {
            color: Colors.TextPrimary,
            fontSize: '16px',
            fontWeight: 'bold',
            marginLeft:'2px',
        }
    },

    items: {
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        height: '40px',
        padding: '0 16px',
        marginRight: '32px',
        display: 'flex',
        alignItems: 'center',

        '& p': {
            color: Colors.TextPrimary,
            fontSize: '16px',
        },
        '& span': {
            color: Colors.TextPrimary,
            fontSize: '16px',
            fontWeight: 'bold',
        }
    },

    tableRow: {
        cursor: 'pointer',
        "&:hover": {
            background: "#EAF2FF 0% 0% no-repeat padding-box",
        },
        height: "50px",
        "& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root": {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 8px 12px #0052E01A",
        },
        "& .MuiTableCell-sizeSmall": {
            fontSize: "14px",
            lineHeight: "22px",
            color: Colors.TextPrimary,

            padding: "8px 16px 8px 16px",
            "@media (min-width: 1920px)": {
                padding: "16px",
            },
        },

        "& .MuiTableCell-sizeSmall:last-child": {
            paddingTop: "11px",
            fontSize: "16px",
            lineHeight: "30px",
            color: "#545F7E",
        },

        "& MuiSwitch-track": {
            borderRadius: "12px",
            background: "lightgray",
        },

        "& .MuiSwitch-colorPrimary.Mui-checked": {
            color: "white",
        },

        "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
            background: "#387DFF",
            borderRadius: "12px",
            opacity: "1",
        },
    },

    positiveType: {
        fontWeight: 600,
        fontSize: '14px',
        color: Colors.ThemeBlue,
        marginLeft: '4px',
    },

    negativeType: {
        fontWeight: 600,
        fontSize: '14px',
        color: Colors.ThemeRed,
        marginLeft: '4px',
    },

    inputDescription: {
        height: '83px',
        width: '100%',
        '& .MuiOutlinedInput-inputMultiline': {
            maxHeight: '60px',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '& .MuiOutlinedInput-root': {
            height: '96px',
            minHeight: '96px',
        },
    },

    inputDescriptionBlue: {
        height: '83px',
        width: '100%',

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeWhiteBlue,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${Colors.ThemeWhiteBlue}!important`,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color:  `${Colors.ThemeBlue}!important`
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-inputMultiline': {
            maxHeight: '60px',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },

        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiOutlinedInput-root': {
            height: '96px',
            minHeight: '96px',
        },
    },

    ratesWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },

    profitStyle: {
        marginBottom: '40px',
        fontSize: '16px',
        color: Colors.TextSecondary,
        '& span': {
            fontWeight: 'bold'
        },
    },

    sectionTop: {
        marginTop: '16px',
    },

    submit: {
        marginTop: '16px',
        width: '100%',
        height: '48px',
        background: Colors.ThemeGreen,
        borderRadius: '8px',
        color: Colors.TextWhite,
        textTransform: "capitalize",
        fontSize: '16px',
        fontWeight: '600',
        padding: 0,
        '& :hover': {
            borderRadius: '8px',
            width: '100%',
            height: '48px',
            padding: 0,
            background: Backgrounds.hover,
        },
    },

    simpleInput: {
        marginRight: '16px',
        width: '100%',
    },
    simpleInputNoRight: {
        width: '100%',
    },
    quantity:{
      marginTop:'24px'
    },
    flexAbleColumn:{
        display:'flex',
        alignItems:'center',
        width:'100%',
        marginBottom:'16px',
    }

}));
