import ClearIcon from "@material-ui/icons/Clear";
import { Colors } from "utils";
import React from "react";
import { buttonsStyle } from "./styles";
import { HtmlTooltip } from "../messages";

export const DeleteButton = ({ handleClick, toolTipTitle, top, styles }) => {
  const classes = buttonsStyle();
  return (
      <button onClick={handleClick} className={classes.deleteButtonStyle}>
        <HtmlTooltip title={<div>{toolTipTitle}</div>} placement="top-end">
          <ClearIcon
            style={{
                ...styles,
              color: Colors.ThemeRed,
              width: "18px",
              height: "18px",
              marginTop:top ? top :  "4px"
            }}
          />
        </HtmlTooltip>
      </button>
  );
}
