export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';

export const MARK_READ_NOTIFICATIONS = 'MARK_READ_NOTIFICATIONS';
export const MARK_READ_NOTIFICATIONS_SUCCESS = 'MARK_READ_NOTIFICATIONS_SUCCESS';

export const MARK_READ_NOTIFICATION = 'MARK_READ_NOTIFICATION';

export const NOTIFICATIONS_SWITCHER = 'NOTIFICATIONS_SWITCHER';
