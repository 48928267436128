import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Colors, useGlobalStyles, useGlobalText} from "utils";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {Circle, FullTable} from "components";
import {CreateFactoringInputs} from "../../creareFactoring";
import {factoringCarriersBody, factoringCarriersHead, ACTION_TYPE} from "./constant";

export const FactoringInfoTable = ({info, handleChangeName}) => {
    const {factoringCarriers} = useSelector((state) => ({
            factoringCarriers: state.factorings.factoringCarriers,
        })
    )
    const history = useHistory()
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();

    return (
        <div className='accordion-style'>
            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={1} back={Colors.ThemeRed}/>}
                        <p className={globalStyle.title}>Factoring Company Information</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateFactoringInputs
                        handleChangeName={handleChangeName}
                        info={info}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={2} back={Colors.ThemeRed}/>}
                        <p className={globalStyle.title}>Connected Carriers</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='info-table-no-line'>
                        <FullTable
                            head={factoringCarriersHead}
                            body={factoringCarriersBody}
                            loadingType={ACTION_TYPE}
                            list={factoringCarriers?.carriers}
                            listCount={factoringCarriers?.count}
                            handleClick={(id) => history.push(`/carrier/${id}`)}
                            noText={'Carriers'}
                            margin={true}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}