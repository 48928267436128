import axios from "axios";

export const authService = {

  /** Create, Edit Agent */

  createAgentService: ( body ) => axios.post('/agents', body, {auth: true}),

  editAgentService: ( body, id ) => axios.patch(`/agents/${id}`, body, { auth:true }),

  resendInviteService: ( id ) => axios.post(`/authn/${id}/resendInvite`, null, { auth:true }),

  /** End */

  /** Get Agents */

  getAgentsService: ( info ) => axios.get('/agents', { auth:true, params: { ...info } }),

  getAgentByIdService: ( id ) =>  axios.get(`/agents/${id.agent}`, {auth:true}),

  /** End */

  /** Get Agents and Managers */

  getAgentByOfficeIdService: (id) =>  axios.get(`/agents/managedOffice/${id}`,{auth:true}),

  getManagersByOfficeIdService: (id) =>  axios.get(`/agents/officeManager/${id}`, {auth:true}),

  getAgentByBranchIdService: (id) =>  axios.get(`/agents/branchAgents/${id}`, {auth:true}),

  getManagersByBranchIdService: (data) =>  axios.get(`/agents/managedBranch/${data.officeId}/${data.branchId}`, {auth:true}),

  /** End */

  /** Activate or Inactivate Agent */

  activateAgentService: ( id ) => axios.patch(`/agents/${id}/activate`, {},{auth:true}),

  inactivateAgentService: ( id ) => axios.patch(`/agents/${id}/inactivate`, {},{auth:true}),

  /** End */

  getOfficeManagerBranch: ( id ) => axios.get(`/agents/byOffice/${id}`, {auth:true}),

  /** Payroll Brackets */


  addBracketService: ( id, body ) => axios.post(`/agent/${id}/brackets`, body, {auth:true}),

  editBracketService: ( id, body, bracketId ) => axios.patch(`/agent/${id}/brackets/${bracketId}`, body, {auth:true}),

  getBracketService: ( id ) => axios.get(`/agent/${id}/brackets`, {auth:true}),

  getDefaultBracketService: ( id ) => axios.get(`/agent/${id}/brackets`, {auth:true}),

  changeDefaultBracketService: ( id, isDefault ) => axios.patch(`/agent/${id}/brackets/toggleStatus`, {},{auth:true, params:{ isDefault: isDefault}}),

  deleteBracketService: ( id, bracketId ) => axios.delete(`/agent/${id}/brackets/${bracketId}`, {auth:true}),

  /** End */
};