import {InputLabel, Select, FormControl, FormHelperText} from "@material-ui/core";
import {inputsStyle} from "./styles";
import {InputMinLoader} from "./inputMiniLoader";
import React, {useEffect} from "react";
import {FindLoad, makeCapitalize} from "../../utils";

export const CustomSelect = ({
                                 errorFalse,
                                 className,
                                 loaderType,
                                 name,
                                 label,
                                 handleSelect,
                                 style,
                                 value = "",
                                 list,
                                 handleChangeCountryCode,
                                 typeError,
                                 disabled,
                                 secondValue,
                                 selectedValue,
                                 showValue,
                                 renderList,
                                 renderShowValue,
                                 styles,
                                 errorStyle = {},
                             }) => {
    const classes = inputsStyle();
    const [current, setCurrent] = React.useState("");
    const loader = FindLoad(loaderType ? loaderType : '')

    useEffect(() => {
        if (value) {
            setCurrent(value)
        } else {
            setCurrent('')
        }
    }, [value])

    const handleChange = (event) => {
        handleSelect(event);
        setCurrent(event.target.value);
        const selectedIndex = event.target.options.selectedIndex;
        if (handleChangeCountryCode) {
            handleChangeCountryCode(
                event.target.options[selectedIndex].getAttribute("data-key")
            );
        }
    };

    const renderValue = (val) => {
        if (renderShowValue) {
            return renderShowValue(val)
        } else {
            return val[showValue]
        }
    }

    const renderSecondValue = (val) => {
        if (secondValue) {
            if (val[secondValue]) {
                return ` (${makeCapitalize(val[secondValue])})`
            }
        } else {
            return null
        }
    }

    return (
        <>
            <div className={style ? style : classes.SignInInput}>
                <FormControl
                    variant="outlined"
                    className={className ? className : classes.inputTextField}
                    error={!!typeError}
                >
                    <InputLabel disabled={disabled === true} htmlFor="outlined-age-native-simple">{label}</InputLabel>
                    <Select
                        disabled={disabled === true}
                        native
                        value={loader?.length ? '' : current}
                        // defaultValue={current}
                        onChange={handleChange}
                        label={label}
                        name={name}
                        error={!!typeError}
                        style={{...styles}}
                        className={classes.select}
                        // multiple={Array.isArray(value) || Array.isArray(current)}
                        inputProps={{
                            name: name,
                            id: 'outlined-age-native-simple',
                        }}
                        endAdornment={loader?.length ? <InputMinLoader/> : ''}
                    >
                        <option aria-label="None" value=""/>
                        {list?.length ? list?.map((option, j) => (
                            <option
                                data-key={option?.code ? option?.code : j}
                                key={j}
                                value={option[selectedValue]}
                            >
                                {renderValue(option)} {renderSecondValue(option)}
                                {" "}

                                {renderList && renderList(option)}
                            </option>
                        )) : ''}
                    </Select>


                   <FormHelperText style={{height:'24px'}} className={classes.selectErrorText}>{typeError && typeError}</FormHelperText>


                </FormControl>
            </div>
        </>
    );
};

//generic
