import {
  CREATE_OFFICE,
  GET_OFFICE_BY_ID,
  GET_OFFICES,
  EDIT_OFFICE,
  ACTIVATE_OFFICE,
  INACTIVATE_OFFICE,
  CREATE_OFFICE_MANAGERS,
  SELECT_OFFICE_MANAGERS,
  REMOVE_OFFICE_MANAGERS,
  GET_OFFICE_BRANCHES,
  GET_OFFICES_FOR_AGENTS,
  REMOVE_OFFICE_BRANCHES, ADD_OFFICE_EXPENSES, SUBTRACT_OFFICE_EXPENSES
} from "./offices.types";

                                           /** Offices Requests, here is All requests for Office page */


/** Create, Edit Office */

export const createOffice = ( body ) => {
  return {
    type: CREATE_OFFICE,
    payload: { body }
  }
}

export const editOffice = ( body, id ) => {
  return {
    type: EDIT_OFFICE,
    payload: { body, id }
  }
}

/** End */

/** Get Offices */

export const getOffices = ( params ) => {
  return {
    type: GET_OFFICES,
    payload: { params }
  }
}

export const getOfficesForAgents = ( ) => {
  return {
    type: GET_OFFICES_FOR_AGENTS,
  }
}

export const getOfficeById =( id, load )=>{
  return{
    type: GET_OFFICE_BY_ID,
    payload: { id, load }
  }
}

/** End */

/** Activate or Inactivate Office */

export const activateOffice = ( id ) =>{
  return{
    type: ACTIVATE_OFFICE,
    payload:{ id }
  }
}

export const inactivateOffice = ( id ) =>{
  return{
    type: INACTIVATE_OFFICE,
    payload:{ id }
  }
}

/** End */

/** Office Management */

export const createOfficeManager =( data )=>{
  return{
    type:CREATE_OFFICE_MANAGERS,
    payload:{ data }
  }
}

export const selectOfficeManager =( agentId, officeId )=>{
  return{
    type: SELECT_OFFICE_MANAGERS,
    payload:{ agentId, officeId }
  }
}

export const removeOfficeManager =( data )=>{
  return{
    type: REMOVE_OFFICE_MANAGERS,
    payload:{ data }
  }
}

/** End */

/**Get Office Branches */

export const getOfficeBranches =( id, params )=>{
  return{
    type: GET_OFFICE_BRANCHES,
    payload:{ id, params }
  }
}

export const removeOfficeBranches =( )=>{
  return{
    type: REMOVE_OFFICE_BRANCHES,
  }
}

/** End */

/** Office Expenses */

export const addOfficeExpense =( id, params )=>{
  return{
    type: ADD_OFFICE_EXPENSES,
    payload:{ id, params }
  }
}

export const subtractOfficeExpense =( id, params )=>{
  return{
    type: SUBTRACT_OFFICE_EXPENSES,
    payload:{ id, params }
  }
}

/** End */