// Load Details

export const loadTypeList = [
    {name: 'Dry', id: 'DRY'},
    {name: 'Reefer', id: 'REEFER'},
    {name: 'Drayage', id: 'DRAYAGE'},
    {name: 'Box Truck', id: 'BOX_TRUCK'},
    {name: 'Air', id: 'AIR'},
    {name: 'Sea', id: 'SEA'},
    {name: 'Rail', id: 'RAIL'},
    {name: 'Power Only', id: 'POWER'},
    {name: 'Flatbed', id: 'FLATBED'},
    {name: 'Other', id: 'OTHER'}
]

export const loadStatusList = [
    {id: 'IN_TRANSIT', name: 'IN_TRANSIT'},
    {id: 'TONU', name: 'TONU'},
    {id: 'DELIVERED', name: 'DELIVERED'},
    {id: 'PAID', name: 'PAID'},
    {id: 'PENDING', name: 'PENDING'},
]

export const teamSolo = [
    {name: 'Solo', id: 'SOLO'},
    {name: 'Team', id: 'TEAM'},
]
export const bookedAs = [
    {name: 'Full', id: 'FULL'},
    {name: 'Partial', id: 'PARTIAL'},
]

// End

export const PicDropText = (modalInfo, type ) =>{
    if(modalInfo){
        return  type === 'pickup' ? 'Edit Pickup' :
            type === 'dropoff' ? 'Edit Dropoff' : ''
    }else{
        return type === 'pickup' ? 'Add Pickup' :
            type === 'dropoff' ? 'Add Dropoff' : ''
    }
}

export const PickDropType = ( type ) =>{
    return type === 'pickup' ? 'PICKUP' : type === 'dropoff' ? 'DROPOFF' : ''
}