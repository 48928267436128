import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemText } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { navBarStyles } from './style';

export const AccordionRow = ({ list, name, activeIcon, passiveIcon, open, accounting, setAccounting, activeCount }) => {
  const classes = navBarStyles();
  const location = useLocation();

  const renderMenuActiveClass = (item) => {
    if (location?.pathname === item?.path || location?.pathname === item?.create || location?.pathname.slice(0, 11) === item?.path.slice(0, 11)) {
      return classes.menuActiveItemsStyle;
    } else {
      return classes.menuItemsStyle;
    }
  };

  const renderMenuImageActiveClass = (item) => {
    if (location?.pathname === item?.path || location?.pathname === item?.create || location?.pathname.slice(0, 11) === item?.path.slice(0, 11)) {
      return classes.imageBackActive;
    } else {
      return classes.imageBackPassive;
    }
  };

  const renderMenuImage = (item) => {
    if (location?.pathname === item?.path || location?.pathname === item?.create || location?.pathname.slice(0, 11) === item?.path.slice(0, 11)) {
      return item?.activeIcon;
    } else {
      return item?.icon;
    }
  };

  return (
    <div>
      <Accordion style={{ marginBottom: 0 }}>
        <AccordionSummary
          className={classes.ActiveListItemAccordian}
          expandIcon={<ExpandMore style={{ color: 'white' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ marginLeft: '5px' }}
               className={accounting === name ? classes.imageBackActive : classes.imageBackPassive}>
            <img src={accounting === name ? activeIcon : passiveIcon} alt="profilesFill" />
          </div>
          {open && (
            <ListItemText
              style={{ marginTop: '10px' }}
              className={accounting === name ? classes.menuActiveItemsStyle : classes.menuItemsStyle}
              primary={name}
            />
          )}

          {activeCount > 0 &&
            <div>
              <div className={classes.countBox}>{activeCount}</div>
            </div>
          }
        </AccordionSummary>

        <AccordionDetails>
          <div className={classes.accordionDetails}>
            {list?.map((item, i) => item && (
              <Link onClick={() => setAccounting(name)} key={i} to={item.path}>
                <ListItem className={classes.ListItem} button>
                  <div className={
                    renderMenuImageActiveClass(item)
                  }>
                    <img
                      className={classes.imageWidth}
                      src={renderMenuImage(item)}
                      alt="icon"
                    />
                    {item?.activeCount > 0 &&
                      <div className={`${classes.countBox} ${classes.countBoxItemLine}`}>{item?.activeCount}</div>}
                  </div>

                  {open && (
                    <>
                      <ListItemText
                        className={renderMenuActiveClass(item)}
                        primary={item.name}
                      />

                    </>
                  )}
                </ListItem>
              </Link>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};