import {
    INACTIVATE_ACTIVATE_CARRIER,
    GET_CARRIER_BY_ID,
    GET_CARRIERS,
    EDIT_CARRIER,
    CREATE_CARRIER,
    ADD_CARRIER_FACTORING,
    CARRIER_FUEL,
    CARRIER_PAYMENT,
    ASSIGN_CARRIER_ACCESS,
    GET_CARRIER_OFFICE_ACCESS,
    GET_CARRIER_AGENT_ACCESS,
    GET_CARRIER_BRANCH_ACCESS,
    GET_CARRIER_PAYMENTS,
    GET_CARRIER_PAYMENT_BY_ID,
    GET_PAYMENTS_CARRIER,
    CARRIER_PAYMENT_TERM,
    ADD_CARRIER_DOCUMENT,
    EDIT_CARRIER_DOCUMENT,
    DELETE_CARRIER_DOCUMENT,
    ADD_CARRIER_FUEL_CARD,
    EDIT_CARRIER_FUEL_CARD,
    DELETE_CARRIER_FUEL_CARD,
    DO_NOT_LOAD,
    GET_CARRIER_PAYMENT_CARRIER,
    SEARCH_CARRIERS,
    FEE_EXEMPT,
    GET_ALERTS,
    ADD_ALERT,
    EDIT_ALERT,
    DELETE_ALERT,
    GET_REVIEWS, ADD_REVIEW, EDIT_REVIEW, DELETE_REVIEW, INVITE_CARRIER_TO_APP, RE_INVITE_CARRIER_TO_APP, EDIT_W9,
} from './carrier.types';

/** Carrier Requests, here is All requests for Carrier page */


/** Create, Edit Carrier */

export const createCarrier = (body) => {
    return {
        type: CREATE_CARRIER,
        payload: {body}
    }
}
export const inviteCarrierApp = (body) => {
    return {
        type: INVITE_CARRIER_TO_APP,
        payload: { body }
    }
}
export const reInviteCarrierApp = (body) => {
    return {
        type: RE_INVITE_CARRIER_TO_APP,
        payload: { body }
    }
}

export const editCarrier = (id, body) => {
    return {
        type: EDIT_CARRIER,
        payload: {id, body}
    }
}
export const editW9 = (id, body) => {
    return {
        type: EDIT_W9,
        payload: {id, body}
    }
}

/** End */

/** Get Carriers */

export const getCarriers = (params, load) => {
    return {
        type: GET_CARRIERS,
        payload: {params, load}
    }
}

export const searchCarriers = (params) => {
    return {
        type: SEARCH_CARRIERS,
        payload: { params }
    }
}

export const getCarrierById = (id, load) => {
    return {
        type: GET_CARRIER_BY_ID,
        payload: {id, load}
    }
}

/** End */

/** Activate or Inactivate Carrier */

export const inactivateActivateCarrier = (id, status) => {
    return {
        type: INACTIVATE_ACTIVATE_CARRIER,
        payload: {id, status}
    }
}

/** End */

/** Carrier Info */

export const addFactoring = (id, factoringId) => {
    return {
        type: ADD_CARRIER_FACTORING,
        payload: {id, factoringId}
    }
}

export const carrierFuel = (id, data) => {
    return {
        type: CARRIER_FUEL,
        payload: {id, data}
    }
}

export const carrierPayment = (id, data) => {
    return {
        type: CARRIER_PAYMENT,
        payload: {id, data}
    }
}

export const carrierPaymentTerm = (id, data) => {
    return {
        type: CARRIER_PAYMENT_TERM,
        payload: {id, data}
    }
}

export const carrierPaymentCarriers = ( id ) => {
    return {
        type: GET_CARRIER_PAYMENT_CARRIER,
        payload: { id }
    }
}

/** End */

/** Carrier Access */

export const getCarrierOfficeAccess = (id, type) => {
    return {
        type: GET_CARRIER_OFFICE_ACCESS,
        payload: {id, type}
    }
}

export const getCarrierAgentAccess = (id, type) => {
    return {
        type: GET_CARRIER_AGENT_ACCESS,
        payload: {id, type}
    }
}

export const getCarrierBranchAccess = (id, type) => {
    return {
        type: GET_CARRIER_BRANCH_ACCESS,
        payload: {id, type}
    }
}

export const assignCarrierAccess = (id, entityId, type) => {
    return {
        type: ASSIGN_CARRIER_ACCESS,
        payload: {id, entityId, type}
    }
}

/** End */

/** Carrier Payment */

export const getCarrierPayments = (params) => {
    return {
        type: GET_CARRIER_PAYMENTS,
        payload: {params}
    }
}

export const getCarrierPaymentById = (id) => {
    return {
        type: GET_CARRIER_PAYMENT_BY_ID,
        payload: id
    }
}

export const getPaymentsCarrierById = (id) => {
    return {
        type: GET_PAYMENTS_CARRIER,
        payload: id
    }
}

/** End */


/** Carrier Documents */

export const addCarrierDocument = (dock, id) => {
    return {
        type: ADD_CARRIER_DOCUMENT,
        payload: {dock, id}
    }
}

export const deleteCarrierDocument = (id, dockId) => {
    return {
        type: DELETE_CARRIER_DOCUMENT,
        payload: {id, dockId}
    }
}

export const editCarrierDocument = (id, params) => {
    return {
        type: EDIT_CARRIER_DOCUMENT,
        payload: { id, params }
    }
}

/** End */

/** Carrier Payment Fuel Card*/

export const addFuelCard = (id, info) => {
    return {
        type: ADD_CARRIER_FUEL_CARD,
        payload: {id, info}
    }
}

export const editFuelCard = (id,fuelId, info) => {
    return {
        type: EDIT_CARRIER_FUEL_CARD,
        payload: {id,fuelId, info}
    }
}

export const deleteFuelCard = (id, fuelId) => {
    return {
        type: DELETE_CARRIER_FUEL_CARD,
        payload: {id, fuelId}
    }
}

/** End */

/** Carrier Do not load */

export const doNotLoad = (id, info) => {
    return {
        type: DO_NOT_LOAD,
        payload: {id, info}
    }
}

/** End */

/** Carrier Fee */

export const feeExempt = (id, info) => {
    return {
        type: FEE_EXEMPT,
        payload: {id, info}
    }
}

/** End */

/** Alert */

export const getAlerts = (carrierId, params) => {
    return {
        type: GET_ALERTS,
        payload: { carrierId, params}
    }
}

export const addAlert = (carrierId, params, requestParams) => {
    return {
        type: ADD_ALERT,
        payload: {carrierId, params, requestParams}
    }
}

export const editAlert = (carrierId, alertId, params, requestParams) => {
    return {
        type: EDIT_ALERT,
        payload: {carrierId, alertId, params, requestParams}
    }
}

export const deleteAlert = (carrierId, alertId, requestParams) => {
    return {
        type: DELETE_ALERT,
        payload: {carrierId, alertId, requestParams}
    }
}

/** End */

/** Reviews */

export const getReviews = (carrierId) => {
    return {
        type: GET_REVIEWS,
        payload: { carrierId }
    }
}

export const addReview = (carrierId, params) => {
    return {
        type: ADD_REVIEW,
        payload: {carrierId, params}
    }
}

export const editReview = (carrierId, params) => {
    return {
        type: EDIT_REVIEW,
        payload: {carrierId, params}
    }
}

export const deleteReview = (carrierId, reviewId) => {
    return {
        type: DELETE_REVIEW,
        payload: {carrierId, reviewId}
    }
}


/** End */