import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {customerPaymentInfo} from "./styles";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {FindLoad, FindSuccess, Images, useGlobalStyles, useGlobalText} from "utils";
import {DeleteElement, NoYet, SimpleModal} from "components";
import {LoadTable} from "./loadTable";
import {customerPaymentActions, httpRequestsOnSuccessActions} from "store";

export const Customers = ({info}) => {
    const classes = customerPaymentInfo();
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const dispatch = useDispatch()
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [customId, setCustomId] = useState('')
    const modalTitle = modalType === 'delete' ? "Remove Customer" : 'Void Load'
    const loader = FindLoad('DELETE_CUSTOMER_PAYMENT_CUSTOMER')
    const success = FindSuccess('DELETE_CUSTOMER_PAYMENT_CUSTOMER')

    useEffect(() => {
        if (success.length) {
            setOpen(false)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_CUSTOMER_PAYMENT_CUSTOMER'))
        }
    }, [success])

    const handleClose = () => {
        setModalType('')
        setOpen(!open)
    }

    const handleDelCustomer = () => {
        dispatch(customerPaymentActions.deleteCustomer(params.id, customId))
    }

    return (
        <div className={classes.customersWrapper}>
            {info?.length ?
                info.map((item, j) => (
                    <div className='loadItems' key={j}>
                        <Accordion>
                            <AccordionSummary
                                className={classes.accordion}
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={globalStyles.spaceBetween}>
                                    <div className={classes.imageAndTitle}>
                                        <img src={Images.customer} alt='icon'/>
                                        <p>{item[0]?.customerName}</p>
                                    </div>
                                    {/*{*/}
                                    {/*    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CUSTOMER_PAYMENT_ADD_CUSTOMER_AND_LOAD?.code]) &&*/}
                                    {/*    item?.loads?.length === 0 && checkStatus(info) &&*/}
                                    {/*    <div style={{marginRight: '10px'}}>*/}
                                    {/*        <RemoveButton*/}
                                    {/*            text={'Delete'}*/}
                                    {/*            handleClick={() => handleOpenClosDel('delete', item?.customerId)}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{boxShadow: '0px 0px 6px #8A8A8A29',}}>
                                <div className={classes.bodyWrapper}>
                                    <div className={globalStyles.spaceBetween}>
                                        <p className={globalText.smallText}>Loads</p>
                                    </div>
                                    <LoadTable loadInfo={item}/>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))
                :
                <NoYet position={'screenCenter'} text={'No Loads Yet'}/>
            }

            <SimpleModal
                handleOpenClose={() => setOpen(!open)}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader.length}
                        type={modalType === 'delete' ? '' : 'void'}
                        text={modalTitle}
                        handleClose={handleClose}
                        handleDel={handleDelCustomer}
                        modalText={
                            modalTitle === 'Remove Customer' ? 'Are you sure?  You wont remove customer'
                                :
                        ' '}
                    />
                }
            />


        </div>
    )
}