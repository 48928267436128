import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const adminInfoFragments = makeStyles(() => ({
    accessWrapper: {
        width: '100%',
        // marginLeft: '24px',
    },

    accessBody: {
        display: "flex",
        justifyContent: 'space-between',
    },

    managementHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    half: {
        width: '49.1%',
    },

    halfItem: {
        width: '50%',
        marginTop: '16px',
        height: '340px',
        overflow: 'auto',
    },

    officeSelectInput: {
        marginBottom: '24px',
        width: '49.1%',
        marginTop: '32px',
    },

    roleNamePer: {
        background: Backgrounds.tableActive,
        borderRadius: '4px',
        padding: '16px',
        width: '50%',
        marginLeft: '16px',
        marginTop: '16px',
        height: '328px',
        "@media (min-width: 1920px)": {
            marginLeft: '24px',
        },
    },
    roleWrapper: {
        marginTop: '30px',
        height: '260px',
        overflow: 'auto',
    },
    roleNamTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextPrimary,
        marginBottom: '24px',
    },

    roleItem: {
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            color: Colors.TextPrimary,
        },
        '& img': {
            width: '18px',
            height: '18px',
            marginRight: '8px',
        }
    },

    select: {
        marginTop: '-15px',
    },

    arrow: {
        marginLeft: '10px',
    },

    logoWrapper: {
        width: '100%',
        height: '285px',
        background: '#F7F9FC 0% 0% no-repeat padding-box',
        border: '3px dashed #438AFE4D',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    removeButton: {
        background: 'none',
        border: 'none',
        zIndex: '999',
        height: '50px',
        width: '50px',
        position: 'absolute',
        marginBottom: '150px',
        marginLeft: '95px',
    },

    logoUpload: {
        width: '100px',
        height: '100px',
        background: '#438AFE1A 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '24px',
        '& img': {
            objectFit: 'cover'
        }
    },
    loader: {
        width: '350px',
        height: '5px',
        '& .MuiLinearProgress-colorPrimary': {
            background: '#438AFE4D',
            borderRadius: '4px',
        },
        '& .MuiLinearProgress-barColorPrimary': {
            background: Colors.ThemeBlue,
        }
    }
}))