import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { Circle, FullTable, Line, PriceConvertor} from "components";
import {Colors,useGlobalStyles, useGlobalText } from "utils";
import { loadActions} from "store";
import { LoadService } from "../load.service";
import { transactionStyles } from './styles';
import {ACTION_TYPE, factoredTransactionBody, factoredTransactionHead } from './constants';

export const FactoredTransactions = ({ }) => {
    const {loadTransactions, load} = useSelector((state) => ({
        loadTransactions: state.loads.loadTransactions,
        load: state.loads.loadById,
    }));
    const dispatch = useDispatch()
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const classes = transactionStyles()
    const history = useHistory()
    const pushInfo = history?.location?.state

    useEffect(() => {
        const txnsInfo = {
            ...pushInfo
        }
        delete txnsInfo.loadParams
        delete txnsInfo.activeTab
        delete txnsInfo.page
        delete txnsInfo.limit
        delete txnsInfo.skip
        dispatch(loadActions.getLoadTransactions(load?.id, '', txnsInfo))
    }, [pushInfo])

    return (
        <div>
            <div>
                <div className={globalStyles.centerItem}>
                    <div>
                        <div className={globalStyles.flexAble}>
                            <Circle number={1} back={Colors.ThemeAqua} />
                            <p className={globalText.title}>Totals</p>
                        </div>
                        <div className={classes.lineAndTable}>
                            <Line height={'auto'} />
                            <div>
                                <div style={{ width: '100%', marginBottom: 40 }} className={classes.itemsWrapper}>
                                    <p className={classes.title}>Customer Totals</p>
                                    <div className={globalStyles.flexAble} style={{flexWrap:'wrap', gap:'16px'}}>
                                        <div className={classes.factoredItems}>
                                            <p>Rate Total: </p>
                                            <span>
                                                <PriceConvertor price={load?.customerRate} />
                                            </span>
                                        </div>
                                        <div className={classes.factoredItems}>
                                            <p>Paid Total:</p>
                                            <span>
                                                  <PriceConvertor price={load?.customerPaid} />
                                            </span>
                                        </div>
                                        <div className={classes.factoredItems}>
                                            <p>Collected:</p>
                                            <span>
                                                  <PriceConvertor price={load?.bill?.corporateCharges} />
                                            </span>
                                        </div>
                                        <div className={classes.factoredItems}>
                                            <p>Charges: </p>
                                            <span>
                                                  <PriceConvertor price={LoadService.getBillBalance(load?.bill)} />
                                            </span>
                                        </div>
                                        <div style={{ margin: 0 }} className={classes.factoredItems}>
                                            <p>Balance: </p>
                                            <span>
                                                  <PriceConvertor price={LoadService.getBillBalance(load?.bill)} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={globalStyles.centerItem}>
                    <div className={globalStyles.spaceBetween}>
                        <div className={globalStyles.flexAble}>
                            <Circle number={2} back={Colors.ThemeAqua} />
                            <p className={globalText.title}> Transaction List</p>
                        </div>
                    </div>
                </div>
                <div className={classes.lineAndTable}>
                    <Line height={'auto'} />
                    <FullTable
                      height={'auto'}
                      head={factoredTransactionHead}
                      body={factoredTransactionBody}
                      loadingType={ACTION_TYPE}
                      list={loadTransactions?.txns}
                      noText={'transactions'}
                    />
                </div>
            </div>
        </div>
    )
}