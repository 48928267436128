import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader, MinLoader, NoYet } from 'components';
import { Colors, DownloadFile, FindLoad, Images } from 'utils';
import { reportActions } from 'store';
import { reportStyles } from '../styles';

export const ArActivity = ({ arActivity }) => {
  const classes = reportStyles();
  const dispatch = useDispatch();
  const getLoader = FindLoad('GET_AR_ACTIVITY');
  const { loadDownload, handleDownload } = DownloadFile();

  useEffect(() => {
    dispatch(reportActions.getArActivityReport());
  }, []);

  if (getLoader?.length) {
    return <Loader />;
  }

  return (
    <div className={classes.arActivityStyles}>
      {arActivity?.length ?
        arActivity.map((i, k) => (
          <div key={k} className={classes.arActivityRow}>
            <div className={classes.arActivityRowYear}>
              <img src={Images.exportCalendar} alt='' />
              <p>Report for {i?.year}</p>
            </div>
            <button onClick={() => handleDownload({ file: i?.file, name: 'ARActivityReport.csv' })}
                    className={classes.exportButton}>
              {loadDownload === i?.file?._id ?
                <MinLoader margin={'0 8px 0 0'} color={Colors.ThemeBlue} position={'relative'} />
                :
                <img src={Images.exportIcon} alt='' />
              }
              Export
            </button>
          </div>
        ))
        :
        <NoYet position={'center'} text={'No AR Activity Yet'} />
      }
    </div>
  );
};

