import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const screensStyle = makeStyles(() => ({
  messageScreenWrapper: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  dockLinkBox:{
    marginRight:'16px'
  },
  dockLink: {
    padding: 0,
    textDecoration: 'none',
    background:'transparent',
    border:'none',
    color: 'black',
    fontSize:'16px',
    wordWrap:'break-word',
    textAlign:'start',
    width:'100%',

  },

  messageScreenModal: {
    background: Backgrounds.white,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',

    '@media (min-width: 1240px)': {
      width: '332px',
      height: '241px',
      padding: '32px',
      marginTop: '70px',
    },
    '@media (min-width: 1919px)': {
      width: '348px',
      height: '257px',
      padding: '40px',
      marginTop: '212px',
    },
  },

  messageScreenText: {
    marginTop: '24px',
    marginBottom: '16px',
    fontSize: '32px',
    fontWeight: 'bold',
    color: Colors.TextSecondary,
  },

  messageScreenResponse: {
    fontSize: '15px',
    lineHeight: '24px',
    color: Colors.TextPrimary,
  },

  messageButton: {
    width: '100%',
    height: '48px',
    padding: '10px',
    background: '#438AFE 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600px',
    color: Colors.TextWhite,
    border: 'none',
    outline: 'none',
    marginTop: '16px',
  },

  errMessageScreenModal: {
    background: Backgrounds.white,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',

    '@media (min-width: 1240px)': {
      width: '332px',
      height: '305px',
      padding: '32px',
      marginTop: '70px',
    },
    '@media (min-width: 1919px)': {
      width: '348px',
      height: '321px',
      padding: '40px',
      marginTop: '212px',
    },
  },

  messageMiniScreen: {
    width: '345px',
    height: '78px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 12px #0000001A',
    borderRadius: '8px',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    position: 'absolute',
    bottom: '50px',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    right: 20,

    '@media (min-width: 1240px)': {
      right: 42,
    },
    '@media (min-width: 1919px)': {
      right: 100,
    },

    '& p': {
      color: Colors.TextSecondary,
      fontSize: '16px',
      lineHeight: '24px',
      marginLeft: '16px',
    },
  },

  selectRole: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '30px',
  },

  circleStyle: {
    width: '32px',
    height: '32px',
    borderRadius: '40px',
    color: Colors.TextWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '600',
    marginRight: '8px',
    '@media (min-width: 1920px)': {
      marginRight: '16px',
    },
  },

  lineStyle: {
    borderLeft: '1px dashed #51566D80',
    margin: '16px 24px 16px 16px',
    '@media (min-width: 1920px)': {
      margin: '24px 32px 24px 16px',
    },
  },
  toastWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginRight: '16px',
    },
    '& p': {
      fontSize: '16px',
      color: Colors.TextSecondary,
      lineHeight: '21px',
    },
  },

  infoSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    width: '100%',
    padding: '8px 16px',
    background: '#E5EEFC 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    '& p': {
      fontSize: '14px',
      lineHeight: '24px',
      color: Colors.TextPrimary,
      marginLeft: '8px',
    },
  },

  noInfoWrapper: {
    position: 'absolute',
    '& .MuiTableCell-sizeSmall': {
      padding: '26px',
      border: 'none',
      fontSize: '18px',
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      border: 'none',
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },

  noInfoText: {
    border: 'none',
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.TextLight,
    fontWeight: 'bold',
  },

  titleAndSwitcher: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  uploadedCard: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '8px',
    padding: '24px',
    height: '284px',
    width: '50%',
    '@media (min-width: 1280px)': {
      marginRight: '16px',
    },
    '@media (min-width: 1920px)': {
      marginRight: '40px',
    },
  },

  uploadedCardHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom:'20px',
    '& .MuiSelect-outlined.MuiOutlinedInput-root ':{
      height:'36px!important',
    }

  },

  filterInput: {
    width: '160px',
    height: '36px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '36px !important',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },
  },

  noItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    '& p': {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '50px',
      color: Colors.TextLight,
    },
  },

  loader: {
    paddingBottom: '18px',
    marginTop: '25px',
    borderBottom: '1px solid black',
    width: '100%',
  },

  uploadedDocumentsWrapper: {
    height: '200px',
    overflow: 'auto',
  },

  uploadedDocWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '15px',
    marginTop: '15px',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },

  downloadButton: {
    '& a': {
      width: '50px',
    },
    width: '50px',
    background: 'none',
    border: 'none',
    outline: 'none',
  },
  switchWrapper: {
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  documentDate: {
    color: '#9C9EA9',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
  },
  approveBtn:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    background:'transparent',
    border:'none',
    width:'65px',
    color: Colors.ThemeBlue,
    fontWeight:'600',
    fontSize:'16px',
    textDecoration:'underline',
    padding: 0
  },
  denyBtn:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    background:'transparent',
    border:'none',
    width:'40',
    color: '#D72525',
    fontWeight:'600',
    fontSize:'16px',
    textDecoration:'underline',
    padding: 0
  }
}));
