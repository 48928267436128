import React, {useState} from "react";
import { CreateOfficeInputs } from "./core";
import { CreateWrapper } from "components";
import { Images, useGlobalStyles } from "utils";

export const CreateOfficeTable = ({ }) => {
  const globalStyle = useGlobalStyles();
  const [name, setName] = useState('')
  return (
    <div>
      <CreateWrapper
        head={
              <div className={globalStyle.createOfficeTableHead}>
                <img src={Images.officeFillBold} alt={"Office"} />
                <p>{name ? name : 'OFFICE NAME'}</p>
              </div>
        }
        body={
          <CreateOfficeInputs
            handleChangeName ={setName}
          />
        }
        parentLink={'/offices'}
        parent={'Office'}
        child={'Add Office'}
      />
    </div>
  );
};
