import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {AddButton, FullTable} from "components";
import {CustomDeleteModal} from "fragments";
import {equipmentActions, httpRequestsOnSuccessActions} from "store";
import {FindSuccessItem, PermissionsList, RolePermission, useModal, userType} from "utils";
import {ACTION_TYPE, equipmentBody, equipmentHead} from "./core/constants";
import {AddEquipmentModal} from "./core/addEquipmentModal";

const DELETE_TYPE = 'DELETE_EQUIPMENT'

export const Equipments = ({}) => {
    const {equipmentList} = useSelector((state) => ({
        equipmentList: state.equipment.equipmentList,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state
    const params = useParams()
    const {open, close} = useModal()
    const success = FindSuccessItem(DELETE_TYPE)

    const renderParams = () => {
        const sendParams = {
            ...info,
            carrier: params?.id,
            favorites: false,
            assigned: false,
        }
        sendParams.skip = info?.skip ? info?.skip : 0
        sendParams.limit = 100

        delete sendParams.page
        delete sendParams.activeTab
        delete sendParams.carrierParams
        return sendParams
    }

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_TYPE))
            close()
        }
    }, [success])

    useEffect(() => {
        dispatch(equipmentActions.getEquipments(renderParams()))
    }, [dispatch, info]);

    const handleAction = (item, name) => {
        if (name === 'delete') {
            open(<CustomDeleteModal
                actionType={'DELETE_EQUIPMENT'}
                text="Delete Equipment?"
                info={'Equipment'}
                handleDel={() => dispatch(equipmentActions.deleteEquipment(item?.id, renderParams()))}
            />)
        }

        if (name === 'homeBase') {
            const body = {
              ...item,
                availableDate:new Date()
            }
            body.returnToHomebase = item?.returnToHomebase !== true
            dispatch(httpRequestsOnSuccessActions.appendSuccess("MINI_LOAD", item.id))
            dispatch(equipmentActions.editEquipmentList(item?.id, body, renderParams()))
        }

        if (name === 'status') {
            const status = item?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            dispatch(httpRequestsOnSuccessActions.appendSuccess("MINI_LOAD", item.id))
            dispatch(equipmentActions.setEquipmentStatus(item?.id, status, renderParams()))
        }
    }

    return (
        <div>

            {userType === 'ADMIN' && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
            <div className='flex-end'>
                <AddButton
                    handleClick={() => open(<AddEquipmentModal sendParams={renderParams()} id={params?.id}/>)}
                    text={'Add Equipment'}
                />
            </div>
            }
            <FullTable
                head={equipmentHead}
                body={equipmentBody}
                loadingType={ACTION_TYPE}
                list={equipmentList?.items}
                listCount={equipmentList?.count}
                handleClick={(id) => {
                    history.push(`/equipment/${id}/carrier/${params?.id}`)
                }}
                noText={'Equipments'}
                handleClickButton={(e, name) => handleAction(e, name)}
            />
        </div>
    )
}