import {
    GET_ALERTS_SUCCESS,
    GET_CARRIER_AGENT_ACCESS_SUCCESS,
    GET_CARRIER_BRANCH_ACCESS_SUCCESS,
    GET_CARRIER_BY_ID_SUCCESS,
    GET_CARRIER_OFFICE_ACCESS_SUCCESS,
    GET_CARRIER_PAYMENT_BY_ID_SUCCESS, GET_CARRIER_PAYMENT_CARRIER_SUCCESS,
    GET_CARRIER_PAYMENTS_SUCCESS,
    GET_CARRIERS_SUCCESS,
    GET_PAYMENTS_CARRIER_SUCCESS, GET_REVIEWS_SUCCESS,
} from "./carrier.types";

const initialState = {
    carriersList: [],
    carrierById: null,
    carrierFactoring: [],
    assignedOffices: [],
    assignedAgents: [],
    blackListBranch: [],
    carrierPayments: [],
    carrierPaymentById: null,
    paymentsByCarrier: [],
    paymentsByCarrierReserve: [],
    carrierPmtCarriers: null,
    alertsList : [],
    reviewsList : [],
};

export const carrierReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Carriers */
        case GET_CARRIERS_SUCCESS:
            return {
                ...state,
                carriersList: action.payload,
            }

        case GET_CARRIER_BY_ID_SUCCESS:
            return {
                ...state,
                carrierById: action.payload
            }

        case GET_PAYMENTS_CARRIER_SUCCESS:
            return {
                ...state,
                paymentsByCarrier:action.payload,
                paymentsByCarrierReserve: action.payload
            }
        /** End */


        case  GET_CARRIER_OFFICE_ACCESS_SUCCESS: {
            return {
                ...state,
                assignedOffices: action.payload
            }
        }
        case GET_CARRIER_AGENT_ACCESS_SUCCESS: {
            return {
                ...state,
                assignedAgents: action.payload
            }
        }
        case  GET_CARRIER_BRANCH_ACCESS_SUCCESS: {
            return {
                ...state,
                blackListBranch: action.payload
            }
        }

        case GET_CARRIER_PAYMENTS_SUCCESS: {
            return {
                ...state,
                carrierPayments: action.payload,
            }
        }

        case GET_CARRIER_PAYMENT_BY_ID_SUCCESS: {
            return {
                ...state,
                carrierPaymentById: action.payload,
            }
        }
        case GET_CARRIER_PAYMENT_CARRIER_SUCCESS: {
            return {
                ...state,
                carrierPmtCarriers: action.payload,
            }
        }

        /** Alerts */

        case GET_ALERTS_SUCCESS: {
            return {
                ...state,
                alertsList: action.payload,
            }
        }

        /** Get */

        /** Reviews */

        case GET_REVIEWS_SUCCESS: {
            return {
                ...state,
                reviewsList: action.payload,
            }
        }

        /** Get */

        default:
            return state;
    }
};
