import axios from "axios";

export const authService = {
    /**Rmis Settings */

    inviteRmisService: (id) => axios.post(`/carriers/${id}/rmis/invite`, {}, {auth: true}),

    refreshRmisService: (id) => axios.patch(`/carriers/${id}/rmis/sync`,{}, {auth: true}),

    getRmisUpdatesService: () => axios.get('/rmis/setup', {auth: true}),

    updateRmisSetupService: ( body ) => axios.post('/rmis/changeSetup', body,{auth: true}),

    requiredRmisService: ( body ) => axios.patch(`/carriers/${body?.id}/rmis/setRequirement`, {},{auth: true, params:{required:body?.required}}),

    /** End */
};