import {paginate} from "./paginate";
import moment from "moment";

export const sortByAz = (list, name,) => {
    if (name === 'name') {
        return paginate(list.sort((a, b) => a.name.localeCompare(b.name)), 10)
    }
    if (name === 'firstName') {
        return paginate(list.sort((a, b) => a.firstName.localeCompare(b.firstName)), 10)
    }
    if (name === 'fullName') {
        return paginate(list.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)), 10)
    }
    if (name === 'address') {
        return paginate(list.sort((a, b) => a.address.formattedAddress.localeCompare(b.address.formattedAddress)), 10)
    }
    if (name === 'email') {
        return paginate(list.sort((a, b) => a.email.localeCompare(b.email)), 10)
    }
    if (name === 'establish') {
        return paginate(list.sort((a, b) => moment(a.establishedDate).format('MM/DD/YYYY').localeCompare(moment(b.establishedDate).format('MM/DD/YYYY'))), 10)
    }
    if (name === 'type') {
        return paginate(list.sort((a, b) => a.type.localeCompare(b.type)), 10)
    }
    if (name === 'billAmount') {
        return paginate(list.sort((a, b) => a.customRate.toString().localeCompare(b.customRate.toString())), 10)
    }
    if(name === 'carrierTotalRate'){
        return paginate(list.sort((a, b) => a.carrierRate.toString().localeCompare(b.carrierRate.toString())), 10)
    }
    if(name === 'startDate'){
        return paginate(list.sort((a, b) => moment(a.createdDate).format('MM/DD/YYYY').localeCompare(moment(b.createdDate).format('MM/DD/YYYY'))), 10)
    }
    if(name === 'mcNumberId'){
        return paginate(list.sort((a, b) => a.mc._id.toString().localeCompare(b.mc._id.toString())), 10)
    }
    if(name === 'customer'){
        return paginate(list.sort((a, b) => a.customer.name.localeCompare(b.customer.name)), 10)
    }
    if(name === 'carrier'){
        return paginate(list.sort((a, b) => a.carrier.firstName.localeCompare(b.carrier.firstName)), 10)
    }
    if(name === 'customerRate'){
        return paginate(list.sort((a, b) => a.customRate.localeCompare(b.customRate)), 10)
    }
}