import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AntSwitch,
  FindLoad,
  FindSuccessItem,
  Images as images,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText, userType,
} from 'utils';
import { officesInfoFragments } from './index';
import {
  ActiveInactive,
  AddButton,
  CheckboxesTags,
  DeleteButton,
  DeleteElement,
  EditSaveButtons,
  Management,
  NoYet,
  SimpleModal,
} from 'components';
import { AddManagerModal } from './addManagerModal';
import { agentActions, branchActions, httpRequestsOnSuccessActions, mcActions } from 'store';

export const BranchManagement = ({ forId, branchById }) => {
  const { assignedBranchMc, assignedOfficeMc, branchManagersList, branchAgentsList } = useSelector((state) => ({
    branchManagersList: state.agents.branchManagersList,
    branchAgentsList: state.agents.branchAgentsList,
    assignedOfficeMc: state.mcs.assignedOfficeMc,
    assignedBranchMc: state.mcs.assignedBranchMc,
  }));
  const [open, setOpen] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [item, setItem] = useState('');
  const classes = officesInfoFragments();
  const globalTextStyle = useGlobalText();
  const globalStyle = useGlobalStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [info, setInfo] = useState('');
  const [modalType, setModalType] = useState('');
  const [openDel, setOpenDel] = useState(false);
  const [disable, setDisabled] = useState(false);
  const branchSuccess = FindSuccessItem('CREATE_BRANCH_MANAGERS');
  const selectSuccess = FindSuccessItem('SELECT_BRANCH_MANAGERS');
  const agentSuccess = FindSuccessItem('EDIT_AGENT');
  const removeSuccess = FindSuccessItem('REMOVE_BRANCH_MANAGERS');
  const successDeleteMc = FindSuccessItem('DELETE_ASSIGNED_MC');
  const agentActive = FindSuccessItem('ACTIVATE_AGENT');
  const agentInactive = FindSuccessItem('INACTIVATE_AGENT');
  const loadRemove = FindLoad('REMOVE_BRANCH_MANAGERS');
  const loadDeleteMc = FindLoad('DELETE_ASSIGNED_MC');
  const agentActiveLoad = FindLoad('ACTIVATE_AGENT');
  const agentInactiveLoad = FindLoad('INACTIVATE_AGENT');

  const handleOpenDel = (i, type) => {
    setItem(i);
    setOpenDel(!openDel);
    setModalType(type ? type : '');
  };

  const handleOpenClose = (type) => {
    setOpen(!open);
    setModalType(type);
    if (type === 'create') {
      setInfo('');
      setItem('');
    }
  };

  const handleOpenCloseActivate = (i) => {
    setOpenActive(!openActive);
    setItem(i);
  };

  const addMc = (branch) => {
    if (branch.length) {
      const branchId = params.id;
      const mcId = branch[branch.length - 1].id;
      dispatch(mcActions.assignMs(branchId, mcId, 'BRANCH'));
    }
  };

  useEffect(() => {
    if (branchSuccess) {
      setOpen(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess('CREATE_BRANCH_MANAGERS'));
      const data = branchById && branchById.office.id;
      dispatch(agentActions.getBy(data));
    }
    if (selectSuccess) {
      setOpen(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess('SELECT_BRANCH_MANAGERS'));
    }
    if (agentSuccess) {
      setOpen(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_AGENT'));
    }
    if (removeSuccess) {
      setOpenDel(!openDel);
      dispatch(httpRequestsOnSuccessActions.removeSuccess('REMOVE_BRANCH_MANAGERS'));
    }
  }, [branchSuccess, selectSuccess, agentSuccess, removeSuccess]);

  useEffect(() => {
    if (info) {
      dispatch(mcActions.getOfficeAssignedMcs(forId && forId.office.id, 'OFFICE'));
    }
  }, [info]);

  useEffect(() => {
    if (successDeleteMc) {
      setOpenDel(!openDel);
      setModalType('');
      dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_ASSIGNED_MC'));
      dispatch(mcActions.getAssignedMcs(params.id, 'BRANCH'));
    }
  }, [successDeleteMc]);


  const handleRemove = () => {
    if (modalType === 'authority') {
      dispatch(mcActions.deleteAssignedMs(params.id, item.id, 'BRANCH'));
    } else {
      const data = {
        'managerId': item.id,
        'branchId': params.id,
        'officeId': forId && forId.office.id,
      };
      dispatch(branchActions.removeBranchManager(data));
    }
  };

  const handleActiveInactive = () => {
    if (item.status === 'ACTIVE') {
      dispatch(agentActions.inactivateAgent(item.id, params.id));
    } else {
      dispatch(agentActions.activateAgent(item.id, params.id));
    }
  };

  useEffect(() => {
    if (agentActive || agentInactive) {
      setOpenActive(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(agentActive?.type));
    }
  }, [agentActive || agentInactive]);

  const newMcList = assignedOfficeMc.filter(function(array_el) {
    return assignedBranchMc.filter(function(anotherOne_el) {
      return anotherOne_el.id === array_el.id;
    }).length === 0;
  });

  const handleEditManager = (i, type) => {
    setInfo(i);
    handleOpenClose(type);
  };

  return (
    <div className={classes.officeManagement}>
      <div>
        <div className={globalStyle.fullScreen}>
          <div className={classes.managementFullWidth}>
            {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code, PermissionsList?.READ_BRANCH?.code], 'officeManager') &&
              <div className={classes.management}>
                <Management
                  type={'small'}
                  styles={{ margin: 0 }}
                  head={
                    <div className={classes.managementHead}>
                      <p className={globalTextStyle.smallText}>Branch Managers</p>
                      {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                        <AddButton
                          handleClick={() => handleOpenClose('select')}
                          text={'Add Branch Manager'}
                        />
                      }
                    </div>
                  }
                  body={
                    <div className={classes.officeManagementHead}>{
                      branchManagersList?.length ? branchManagersList.map((i, j) => (
                        <div key={j}>
                          <div className={classes.officeManagementHeadTitle}>
                            <p>{`${i.firstName} ${i.lastName}`}</p>
                            {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                              <div>
                                <button onClick={() => handleEditManager(i, 'edit')}>
                                  <img src={images.edit} alt="edit" />
                                </button>
                                <DeleteButton
                                  toolTipTitle={`Remove ${i.firstName} ${i.lastName}`}
                                  handleClick={() => handleOpenDel(i)}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      )) : <NoYet text={'No Managers Yet'} />}
                    </div>
                  }
                />
              </div>
            }
            <div className={classes.management}>
              <Management
                styles={
                  RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code, PermissionsList?.READ_BRANCH?.code], 'officeManager') ?
                    {} : { margin: 0, height: '688px' }}
                type={'small'}
                head={
                  <div className={classes.managementHead}>
                    <p className={globalTextStyle.smallText}>Agents</p>
                    {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                      <AddButton
                        handleClick={() => handleOpenClose('create')}
                        text={'Add Agent'}
                      />
                    }
                  </div>
                }
                body={
                  <div
                    style={
                      RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code, PermissionsList?.READ_BRANCH?.code], 'officeManager') ?
                        {} : { height: '600px' }
                    }
                    className={classes.officeManagementHead}>{
                    branchAgentsList.length ? branchAgentsList.map((i, j) => (
                      <div key={j}>
                        <div className={classes.officeManagementHeadTitle}>
                          <p>{`${i.firstName} ${i.lastName}`}</p>
                          {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                            <div>
                              <button onClick={() => handleEditManager(i, 'edit')}>
                                <img src={images.edit} alt="edit" />
                              </button>

                              <AntSwitch
                                onClick={() => handleOpenCloseActivate(i)}
                                checked={i.status === 'ACTIVE'}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    )) : <NoYet text={'No Agents Yet'} />}
                  </div>
                }
              />
            </div>
          </div>

          <Management
            type={'big'}
            head={
              <div>
                <div className={classes.managementHead}>
                  <p style={{ marginTop: '10px' }} className={globalTextStyle.smallText}>Branch Authorities</p>
                  {RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                    <EditSaveButtons
                      butt={'done'}
                      type={'EDIT_ADMIN'}
                      handleChancel={() => setDisabled(false)}
                      handleSetEdit={() => setDisabled(true)}
                      handleSaveInfo={() => setDisabled(false)}
                    />
                  }
                </div>
                {!!disable &&
                  <div className={classes.officeSelectInput}>
                    <CheckboxesTags
                      type={'mc'}
                      handleChange={addMc}
                      permissionsList={newMcList ? newMcList : []}
                      label={'Add Authorities'}
                    />
                  </div>
                }
              </div>
            }
            body={
              <div className={classes.authoritesManagementHead}>
                {assignedBranchMc.length ? assignedBranchMc.map((i, j) => (
                  <div key={j} className={classes.officeManagementHeadTitle}>
                    <p>{i.name} - <span style={{ fontWeight: 'bold' }}>{i.mcNumber}</span></p>
                    {!!disable && RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
                      <div>
                        <DeleteButton
                          toolTipTitle={`Remove ${i.name}`}
                          handleClick={() => handleOpenDel(i, 'authority')}
                        />
                      </div>
                    }
                  </div>
                )) : <NoYet text={'No Mc Yet'} />}
              </div>
            }
          />
        </div>
      </div>

      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={open}
        content={
          <AddManagerModal
            forId={forId}
            info={info}
            modalType={modalType}
            handleClose={handleOpenClose}
          />
        }
      />

      <SimpleModal
        handleOpenClose={handleOpenCloseActivate}
        openDefault={openActive}
        content={
          <ActiveInactive
            loading={!!agentActiveLoad?.length || !!agentInactiveLoad?.length}
            text={item ? item.status === 'ACTIVE' ? `Inactivate ${item.firstName} ${item.lastName}` : `Activate ${item.firstName} ${item.lastName}` : ''}
            textInfo={item ? item.status === 'ACTIVE' ? 'Inactivate' : 'Activate' : ''}
            name={item ? `${item.firstName} ${item.lastName}` : ''}
            handleClose={handleOpenCloseActivate}
            handleDel={handleActiveInactive}
          />
        }
      />

      <SimpleModal
        handleOpenClose={handleOpenDel}
        openDefault={openDel}
        content={
          <DeleteElement
            loading={!!loadRemove?.length || !!loadDeleteMc.length}
            text={'Remove Manager?'}
            handleClose={handleOpenDel}
            handleDel={handleRemove}
            info={item &&
            modalType === 'authority' ? item?.name
              :
              `${item?.firstName} ${item?.lastName}`
            }
          />
        }
      />
    </div>
  );
};