import { CreateChancel, SelectTypeAutocomplete, ValidationInput } from 'components';
import { ErrorText, Images, useGlobalStyles, useModal, userType } from 'utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadsStyle } from '../styles';
import { httpRequestsOnErrorsActions, loadActions, LoadServices, mcActions } from 'store';

export const CreditCheck = ({}) => {
  const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));

  const { customers } = useSelector((state) => ({
    customers: state.loads.customers,
  }));
  const globalStyles = useGlobalStyles();
  const classes = loadsStyle();
  const { close } = useModal();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [backError, setBackError] = useState('');
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkLimit, setCheckLimit] = useState(null);

  useEffect(() => {
    if (userInfo && userType !== 'ADMIN') {
      dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT'));
    }
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    setCheckLimit(null);
    if (error === e.target.name) {
      setError('');
      setBackError(null);
    }
  };

  const handleCreate = async () => {
    if (inputs?.customer && inputs?.amount) {
      setLoading(true);
      try {
        const res = await LoadServices.CheckCredit(inputs?.customer, inputs?.amount);
        setCheckLimit(res?.data);
        setLoading(false);
      } catch (e) {
        if (e?.data?.message === 'Terms and condition was not found') {
          dispatch(httpRequestsOnErrorsActions.appendError('TERMS_NOT_FOUND'));
        }
        setError(e?.data?.message);
        setLoading(false);
      }
    } else {
      setError(
        !inputs?.customer ? 'customer' :
          !inputs?.amount ? 'amount' : '',
      );
    }
  };

  const handle = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(loadActions.searchList(e.target.value));
      }, 500);
    }
  };

  return (
    <div>
      <p className="customer-title" style={{ marginBottom: 0 }}>Customer Credit Check</p>
      <p className={classes?.subTitle}>To check customer credit please, fill out the fields below fields.</p>
      <div>
        <SelectTypeAutocomplete
          style={globalStyles.simpleInput}
          loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
          name={'customer'}
          label={'name'}
          type={'mc'}
          title={'Customer (Name/MC)*'}
          handleSelect={handleChange}
          defaultValue={inputs.customer}
          list={customers?.customers?.length ? customers?.customers : []}
          handleType={handle}
          typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
          error={error === 'customer' ? 'customer' :
            backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
          }
        />
        <div style={{ marginTop: '24px' }}>
          <ValidationInput
            className={inputs.amount && globalStyles.inputTextFieldBlue}
            variant={'outlined'}
            name={'amount'}
            label={'Customer Rate*'}
            type={'number'}
            typeError={error === 'amount' ? ErrorText.field : ''}
            onChange={handleChange}
            value={inputs.amount}
          />
        </div>
      </div>
      {checkLimit &&
        <div className={classes.rejectOrSuccessWrapper}>
          {checkLimit?.isConsignment ?
            <>
              <img src={Images.info} alt="icon" />
              <p style={{ color: 'rgb(67, 138, 254)' }}>This customer is under consignment</p>
            </>
            :
            checkLimit?.isAutoDeny ?
              <>
                <img src={Images.rejected} alt="icon" />
                <p style={{ color: '#D72525' }}>Rejected ( Auto Deny )</p>
              </>
              :
              checkLimit?.hasCredit ?
                <>
                  <img src={Images.approved} alt="icon" />
                  <p style={{ color: '#00C851' }}>Success</p>
                </>
                :

                <>
                  <img src={Images.rejected} alt="icon" />
                  <p style={{ color: '#D72525' }}>Rejected</p>
                </>
          }
        </div>
      }
      <CreateChancel
        loader={loading}
        classes={globalStyles.buttonsStyle}
        create={'Check'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={() => close()}
      />
    </div>
  );
};