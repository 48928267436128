import {
    BRANCH_ASSIGNED_MC_SUCCESS,
    GET_AGENT_ASSIGNED_MCS_SUCCESS,
    GET_ASSIGNED_MC_SUCCESS,
    GET_ASSIGNED_MCS_SUCCESS,
    GET_BRANCH_ASSIGNED_MCS_SUCCESS,
    GET_MC_BY_ID_SUCCESS,
    GET_MCS_SUCCESS,
    GET_OFFICE_ASSIGNED_MCS_SUCCESS,
} from "./mc.types";

const initialState = {
    mcsList: [],
    // mcsListReserve: [],
    mcById: null,
    assignedOffices: [],
    assignedMcList: [],
    assignedByType: [],
    assignedOfficeMc: [],
    assignedBranchMc: [],
    assignedAgentMc: [],

};

export const mcReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Mcs */

        case GET_MCS_SUCCESS:
            return {
                ...state,
                mcsList: action.payload
            }

        case GET_MC_BY_ID_SUCCESS:
            return {
                ...state,
                mcById: action.payload
            }
        case GET_ASSIGNED_MCS_SUCCESS:
            return {
                ...state,
                assignedByType: action.payload
            }

        case GET_OFFICE_ASSIGNED_MCS_SUCCESS:
            return {
                ...state,
                assignedOfficeMc: action.payload
            }
        case GET_BRANCH_ASSIGNED_MCS_SUCCESS:
            return {
                ...state,
                assignedBranchMc: action.payload
            }
        case GET_AGENT_ASSIGNED_MCS_SUCCESS:
            return {
                ...state,
                assignedAgentMc: action.payload
            }

        /** End */

        /**  Assigned Mc */

        case GET_ASSIGNED_MC_SUCCESS:
            return {
                ...state,
                assignedOffices: action.payload
            }
        case BRANCH_ASSIGNED_MC_SUCCESS:
            return {
                ...state,
                assignedMcList: action.payload
            }

        /** End */


        default:
            return state;
    }
};
