import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { agentsInfoFragments } from './styles';
import { AddButton, DeleteButton, Loader, NoYet, PriceConvertor } from 'components';
import { AntSwitch, FindLoad, FindSuccessItem, Images, useGlobalText, useModal } from 'utils';
import { PayrollModal } from './payrollModal';
import { agentActions, httpRequestsOnSuccessActions } from 'store';
import { CustomDeleteModal } from '../../../modals';

const DELETE_TYPE = 'DELETE_BRACKET';
export const Payroll = ({ profileDetail }) => {
  const { defaultBracket } = useSelector((state) => ({
    defaultBracket: state.agents.defaultBracket,
  }));
  const classes = agentsInfoFragments();
  const globalText = useGlobalText();
  const dispatch = useDispatch();
  const [switchBoolean, setSwitchBoolean] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const loaderDefault = FindLoad('GET_DEFAULT_BRACKET');
  const changeDefault = FindLoad('CHANGE_DEFAULT_BRACKET');
  const success = FindSuccessItem(DELETE_TYPE);
  const { open, close } = useModal();
  const params = useParams();

  useEffect(() => {
    if (defaultBracket) {
      setSwitchBoolean(!defaultBracket?.isDefault);
    }
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_BRACKET'));
      close();
    }
  }, [success]);

  const handleOpenClose = () => {
    dispatch(agentActions.changeBracket(params.id, switchBoolean));
    setSwitchBoolean(!switchBoolean);
  };

  return (
    <div className="info-table-no-line">
      <div className={classes.payroll}>
        <div className={classes.payrollWrapper}>

          {!profileDetail &&
            <div className={classes.setupWrapper}>
              <p className={globalText.smallText}>Payroll Setup</p>
              <div className="align-center">
                <span style={{ width: '115px' }} className={globalText.smallSwitchText}>Custom Brackets</span>
                <AntSwitch
                  onClick={handleOpenClose}
                  checked={switchBoolean}
                />
              </div>
            </div>
          }
          {!defaultBracket?.isDefault && !profileDetail &&
            <div className={classes.addButton}>
              <AddButton
                handleClick={() => open(
                  <PayrollModal
                    selectedIndex={selectedIndex}
                    params={params}
                  />)
                }
                text={'Add Payroll Bracket'} />
            </div>
          }

          <div className={classes.payrollHead}>
            <p>Revenue Range</p>
            <p>Percentage</p>
            <p className={classes.action}>{!defaultBracket?.isDefault && !profileDetail ? 'Action' : ''} </p>
          </div>
          <div className={classes.payrollItemsWrapper}>
            {changeDefault?.length || loaderDefault?.length ?
              <Loader style={'relative'} height={'180px'} />
              :
              defaultBracket?.brackets?.length ? defaultBracket?.brackets?.map((i, j) => (
                  <div key={j} className={classes.payrollItem}>
                    <p><PriceConvertor price={i.start} /></p>
                    <p>{`${i.percent}%`}</p>
                    <p style={{ display: 'flex' }}>{
                      !profileDetail && !defaultBracket?.isDefault ?
                        <>
                          {j === 0 ?
                            <>
                              <div style={{ width: '29px' }} />
                              <button
                                className={classes.editButton}
                                onClick={() => open(
                                  <PayrollModal
                                    selectedIndex={j}
                                    info={i}
                                    params={params}
                                  />)}
                              >
                                <img src={Images.edit} alt={'icon'} />
                                <span>Edit</span>
                              </button>
                            </>
                            :

                            <>
                              <DeleteButton
                                toolTipTitle={'Remove Payroll'}
                                handleClick={() =>
                                  open(<CustomDeleteModal
                                    actionType={DELETE_TYPE}
                                    text="Delete Bracket?"
                                    info={'Bracket'}
                                    handleDel={() => dispatch(agentActions.deleteBracket(params.id, i?.id))}
                                  />)
                                }
                              />
                              <button
                                className={classes.editButton}
                                onClick={() => open(
                                  <PayrollModal
                                    selectedIndex={j}
                                    info={i}
                                    params={params}
                                  />)
                                }>
                                <img src={Images.edit} alt={'icon'} />
                                <span>Edit</span>
                              </button>
                            </>
                          }
                        </>
                        :
                        ''}
                    </p>
                  </div>
                )) :
                <NoYet position={'center'} text={'No Brackets Yet'} />}
          </div>
        </div>
      </div>
    </div>
  );
};