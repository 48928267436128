import React from "react";
import { Colors, DownloadFile, Images, useGlobalTextStyles } from 'utils';
import {MinLoader} from "../loader";

export const Download = ({text, type, noText, fullFile, customFile}) => {
    const globalText = useGlobalTextStyles()
    const { loadDownload, handleDownload, handleDownloadAnotherUrl} = DownloadFile()

    const download = () => {
      if(customFile){
        handleDownloadAnotherUrl(fullFile)
      }else{
        handleDownload(fullFile)
      }
    }

    return (
        <button onClick={download}
                style={{display: 'flex', alignItems: 'center', border: 'none', background: 'transparent'}}>
            <div style={{width: '30px', height: '20px'}}>
                {loadDownload === fullFile?.file?._id ?
                    <MinLoader margin={'0 8px 0 0'} style={{height:'20px'}} position={'relative'} color={Colors.ThemeGreen}/>
                    :
                    <img style={{marginRight: '8px'}} src={Images.download} alt="icon"/>
                }
            </div>
            {noText !== true &&
                <div>
                    {type === 'other' ?
                        <p className={globalText.downloadText}>{text}</p>
                        :
                        <p className={globalText.itemText}>{text ? text : 'Download'}</p>
                    }
                </div>
            }
        </button>
    );
}