import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {Colors, PermissionsList, RolePermission, useGlobalStyles, useGlobalText} from "utils";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {Circle, DocumentsComponent} from "components";
import {McManagement} from "./mcManagement";
import {CreateAuthoritiesInputs} from "../../createAuthorities/core";
import {Logo} from "./logo";
import {authService} from "../../../../store/mc/mc.service";
import {httpRequestsOnSuccessActions, httpRequestsOnLoadActions, mcActions } from "store";
import {mcDockTypes, mcUploadDockTypes} from "./constants";

const ADD_AUTHORITY = 'ADD_AUTHORITY_DOC'
const DELETE_AUTHORITY = 'DELETE_AUTHORITY_DOC'

export const AuthorityInfoTable = ({ handleChangeName }) => {
    const {mcById} = useSelector((state) => ({
          mcById: state.mcs.mcById,
      })
    )
    const dispatch = useDispatch()
    const globalStyle = useGlobalText();
    const globalInputs = useGlobalStyles();
    const [docs, setDocks] = useState([])
    const info = mcById

    useEffect(() => {
        if (info && info.documents) {
            setDocks(info.documents)
        }
    }, [info])

    const handleAddDocument = async (img) => {
        try {
            await authService.addMcDocument(img, info.id)
            dispatch(httpRequestsOnLoadActions.removeLoading(ADD_AUTHORITY))
            dispatch(mcActions.getMcById(info.id, 'noLoad'))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(ADD_AUTHORITY))
        }
    }

    const handleDeleteFile = async (doc) => {
        try {
            dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_AUTHORITY))
            await authService.deleteMcDocument(info.id, doc?.id)
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_AUTHORITY))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_AUTHORITY))
            dispatch(mcActions.getMcById(info.id, 'noLoad'))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_AUTHORITY))
        }
    }

    const handleFilter = e => {
        if (e.target.value === 'All DocumentsSection') {
            setDocks(info.documents)
        } else {
            const newList = info && info.documents.filter((i) => i.type === e.target.value)
            setDocks(newList)
        }
    };

    return (
        <div className='accordion-style'>
            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={1} back={Colors.ThemeTurquoise}/>}
                        <p className={globalStyle.title}>Authority Information</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateAuthoritiesInputs
                        handleChangeName={handleChangeName}
                        info={info}
                    />
                </AccordionDetails>
            </Accordion>

            {RolePermission([PermissionsList.CREATE_UPDATE_MC?.code]) &&
                <>
                <Accordion>
                    <AccordionSummary
                        className={'accordion'}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={globalInputs.centerItem}>
                            {info && <Circle number={2} back={Colors.ThemeTurquoise}/>}
                            <p className={globalStyle.title}>Authority Management</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <McManagement/>
                    </AccordionDetails>
                </Accordion>

            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={3} back={Colors.ThemeTurquoise}/>}
                        <p className={globalStyle.title}>Logo</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Logo info={info}/>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    className={'accordion'}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        {info && <Circle number={4} back={Colors.ThemeTurquoise}/>}
                        <p className={globalStyle.title}>Documents</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='info-table-no-line'>
                        <DocumentsComponent
                            // permissionForDelete={}
                            fileTypeList={mcUploadDockTypes}
                            docTypes={mcDockTypes}
                            actionType={ADD_AUTHORITY}
                            removeActionType={DELETE_AUTHORITY}
                            handleDeleteFile={handleDeleteFile}
                            handleUpload={handleAddDocument}
                            handleFilter={handleFilter}
                            documents={docs}
                            name={'typeName'}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
                </>
            }
        </div>
    )
}