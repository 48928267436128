import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const paymentVerificationDetailsStyle = makeStyles(() => ({
    itemsSection: {
        display: 'flex',
        width: '100%',
        marginTop: '24px',
    },
    warnWrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#FFF6EA 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        padding: '10px 16px',
        marginTop: '24px',
        fontSize: '14px',
        '& img': {
            marginRight: '8px',
        }
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.TextPrimary,
    },
    requestCorrection: {
        width: '238px',
        height: '48px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '2px solid #438AFE',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '600',
        color: Colors.ThemeBlue,
    },
    pastTable: {
        marginLeft: '50px'
    },
    pmtButtonsWrapper:{
        display: 'flex',
        marginTop: '24px',
        marginBottom:'20px',
    },

}))