import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddButton, DeleteButton, DeleteElement, NoYet, PriceConvertor, SimpleModal} from "components";
import {FindLoad, FindSuccessItem, Images, PermissionsList, RolePermission} from "utils";
import {agentsInfoFragments} from "../../agents/agentInfo/core";
import {SystemPayrollModal} from "./systemPayrollModal";
import {systemInfoFragments} from "./styles";
import {httpRequestsOnSuccessActions, systemActions} from "store";

export const PayrollBracket = ({data}) => {
    const classes = agentsInfoFragments()
    const systemClasses = systemInfoFragments()
    const dispatch = useDispatch()
    const [switchBoolean, setSwitchBoolean] = useState(false)
    const [item, setItem] = useState('')
    const [open, setOpen] = useState(false)
    const [disable, setDisable] = useState('')
    const loader = FindLoad('DELETE_SYSTEM_BRACKET')
    const successDel = FindSuccessItem('DELETE_SYSTEM_BRACKET')

    const openClose = (item, type, fist) => {
        if (type === 'edit') {
            setSwitchBoolean(!switchBoolean)
            setItem(item)
            setDisable(fist)
        } else {
            setSwitchBoolean(!switchBoolean)
            setItem('')
            setDisable('')
        }
    }

    const openDel = (item) => {
        setOpen(!open)
        setItem(item)
        setDisable('')
    }

    const deleteBracket = () => {
        dispatch(systemActions.deleteBracket(item.id))
    }

    useEffect(() => {
        if (successDel) {
            setOpen(!open)
            setItem('')
            dispatch(httpRequestsOnSuccessActions.removeSuccess(''))
        }
    }, [successDel])

    return (
        <div style={{background: 'none', padding: 0, margin: 0}} className={'white-items-wrapper'}>
            { RolePermission([PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,]) &&
            <div className={classes.addButton}>
                <AddButton
                    handleClick={openClose}
                    text={'Add Payroll Bracket'}/>
            </div>
            }
            <div className={classes.payrollHead}>
                <p style={{width:'45%'}}>Revenue Range</p>
                <p style={{width:'45%'}}>Percentage</p>
                {RolePermission([PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,]) &&
                    <p style={{width: '10%'}}>Action</p>
                }
            </div>
            <div>
                {data && data.length ? data.map((i, j) => (
                        <div key={j} className={systemClasses.payrollItemsWrapper}>
                            <p style={{width:'45%'}}><PriceConvertor price={i.start}/></p>
                            <p style={{width:'45%'}}>{`${i.percent}%`}</p>
                            {RolePermission([PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,]) &&
                                <div className={systemClasses.delEditButtons}>
                                    <div style={j === 0 ? {marginRight: '31px'} : {}}>
                                        <button
                                            onClick={() => openClose(i, 'edit', j === 0 ? 'first' : '')}
                                            className={classes.editButton}>
                                            <img src={Images.edit} alt={'icon'}/>
                                            <span>Edit</span>
                                        </button>
                                    </div>
                                    {j !== 0 &&
                                        <DeleteButton
                                            toolTipTitle={"Remove Bracket"}
                                            handleClick={() => openDel(i)}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    ))
                    :
                    <NoYet position={'center'} text={'No Brackets Yet'}/>
                }
            </div>

            <SimpleModal
                handleOpenClose={() => openClose()}
                openDefault={switchBoolean}
                content={
                    <SystemPayrollModal
                        handleClose={() => openClose()}
                        info={item}
                        disable={disable}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={openDel}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader?.length}
                        text={'Delete Bracket?'}
                        handleClose={openDel}
                        handleDel={deleteBracket}
                        info={'Bracket'}
                    />
                }
            />
        </div>
    )
}