import {Button} from "@material-ui/core";
import {buttonsStyle} from "./styles";

export const CloseBill = ({handleCLick, text, width, right}) => {
    const classes = buttonsStyle()
    return (
        <div className={classes.closeBill}>
            <Button
                style={{width:width, marginRight:right}}
                onClick={handleCLick}>
                {text}
            </Button>
        </div>
    )
}