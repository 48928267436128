import {
  activateEquipment,
  createEquipment,
  deleteEquipment,
  editEquipment, editEquipmentList,
  getEquipmentById,
  getEquipments,
  inactivateEquipment, setEquipmentStatus, setFavorite, unsetFavorite,
} from "./equipment.action";

export { equipmentReducer } from './equipment.reducer';
export { watchEquipment } from './equipment.saga';

export const equipmentActions = {
  /** Create, Edit Equipment */
  createEquipment,
  editEquipment,
  editEquipmentList,
  /** End */

  /** Get Equipment */
  getEquipments,
  getEquipmentById,
  /** End */

  /** Activate or Inactivate Equipment */
  setEquipmentStatus,
  /** End */

  /** Remove Equipment */
  deleteEquipment,
  /** End */

  /** Favorite */
  setFavorite,
  unsetFavorite,
  /** End */

}

