import React, {useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Images} from "utils";
import {screensStyle} from "./styles";

export const Toast = ({ info, text, type }) => {
    const classes = screensStyle()

    const screen =
        <div className={classes.toastWrapper}>
            <img src={type === 'error' ? Images.toastError : Images.success } alt="success"/>
            <p>{text}</p>
       </div>

    useEffect(() => {
        if (info) {
            toast(screen);
        }
    }, [info]);
    return (
        <div>
            <ToastContainer
                className={type === 'error' ? 'error' : 'success'  }
                position={'bottom-right'}
            />
        </div>
    );
}