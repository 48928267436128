import {call, put, takeLatest} from "redux-saga/effects";
import {authService} from "./rmis.service";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    GET_RMIS_UPDATES,
    GET_RMIS_UPDATES_SUCCESS,
    INVITE_RMIS,
    REFRESH_RMIS,
    UPDATE_RMIS_REQUIRED,
    UPDATE_RMIS_SETUP
} from "./rmis.types";
import {GET_CARRIER_BY_ID, GET_CARRIER_BY_ID_SUCCESS} from "../carriers/carrier.types";

/**Rmis Settings */

function* inviteRmis({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.inviteRmisService, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* refreshRmis({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnErrorsActions.removeError('RMIS_NOT_ACTIVATED'));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.refreshRmisService, payload.id);
        yield put({
            type: GET_CARRIER_BY_ID_SUCCESS,
            payload: res.data,
        });

        if (res?.data?.rmisStatus === "ACTIVE") {
            yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        }
        if (res?.data?.rmisStatus === "INACTIVE") {
            yield put(httpRequestsOnErrorsActions.appendError('RMIS_NOT_ACTIVATED', ''));
        }

        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnErrorsActions.removeError('RMIS_NOT_ACTIVATED'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* getRmisUpdates({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.getRmisUpdatesService);
        yield put({
            type: GET_RMIS_UPDATES_SUCCESS,
            payload: res.data,
        });
        // yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* updateRmisSetup({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.updateRmisSetupService, payload.body);
        yield put({
            type: GET_RMIS_UPDATES,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* requiredRmis({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.requiredRmisService, payload.body);
        yield put({
            type: GET_CARRIER_BY_ID,
            payload: {id: payload?.body?.id, load: 'noLoad'}
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

/** End */


export const watchRmis = function* watchRmisSaga() {
    /** Create, Edit, Get, Delete Term */
    yield takeLatest(INVITE_RMIS, inviteRmis);
    yield takeLatest(REFRESH_RMIS, refreshRmis);
    yield takeLatest(GET_RMIS_UPDATES, getRmisUpdates)
    yield takeLatest(UPDATE_RMIS_SETUP, updateRmisSetup)
    yield takeLatest(UPDATE_RMIS_REQUIRED, requiredRmis)
    /** End */


};
