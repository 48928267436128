
/**Create Factoring */
export const CREATE_FACTORING = 'CREATE_FACTORING';

/**Edit Factoring */
export const EDIT_FACTORING = 'EDIT_FACTORING'

/**Get Factorings */
export const GET_FACTORINGS = 'GET_FACTORINGS';
export const GET_FACTORINGS_SUCCESS = 'GET_FACTORINGS_SUCCESS';
export const GET_CARRIER_FACTORING = 'GET_CARRIER_FACTORING';
export const GET_CARRIER_FACTORING_SUCCESS = 'GET_CARRIER_FACTORING_SUCCESS';

/**Get Factoring by id */
export const GET_FACTORING_BY_ID = 'GET_FACTORING_BY_ID';
export const GET_FACTORING_BY_ID_SUCCESS = 'GET_FACTORING_BY_ID_SUCCESS';

/** Activate Inactivate Factoring */
export const ACTIVATE_FACTORING = 'ACTIVATE_FACTORING';
export const INACTIVATE_FACTORING = 'INACTIVATE_FACTORING';