
/**Create Upload */
export const CREATE_UPLOAD = 'CREATE_UPLOAD';
export const CREATE_UPLOAD_SUCCESS = 'CREATE_UPLOAD_SUCCESS';

/**Get Upload */
export const GET_UPLOADS = 'GET_UPLOADS';
export const GET_UPLOADS_SUCCESS = 'GET_UPLOADS_SUCCESS';
export const GET_UPLOADS_ERROR = 'GET_UPLOADS_ERROR';

/**Del Upload */
export const DELETE_UPLOADS = 'DELETE_UPLOADS';