import axios from 'axios';


export const LoadServices = {

  CreateLoad: (info) => axios.post('/loads', info, { auth: true }),

  CreateFactoredLoad: (info) => axios.post('/loads/factored', info, { auth: true }),

  EditFactoredLoad: (id, info) => axios.patch(`/loads/${id}/factored/details`, info, { auth: true }),

  CreateLoadDetails: (id, info) => axios.patch(`/loads/${id}/details`, info, { auth: true }),

  CreateLoadStops: (id, info) => axios.post(`/loads/${id}/stops`, info, { auth: true }),

  EditLoadStops: (id, dataId, info) => axios.patch(`/loads/${id}/stops/${dataId}`, info, { auth: true }),

  DeleteLoadStops: (id, stopId) => axios.delete(`/loads/${id}/stops/${stopId}`, { auth: true }),

  AssignCarrier: (id, info) => axios.patch(`/loads/${id}/carrier`, info, { auth: true }),

  AwaitCarrier: (id) => axios.post(`/loads/${id}/awaitCarrier`, null, { auth: true }),

  SendEmail: (id) => axios.post(`/loads/${id}/sendRateCon`, null, { auth: true }),

  SubmitLoad: (id) => axios.patch(`/loads/${id}/submit`, null, { auth: true }),

  SetLoadProcessing: (id, loadStatus) => axios.post(`/loads/${id}/setStatusProcessing`, null, { auth: true }),

  UploadLoadDocument: (id, info) => axios.post(`/loads/${id}/documents`, info, { auth: true }),

  SetLoadDocumentStatus: (id, docId, info) => axios.patch(`/loads/${id}/documents/${docId}`, { ...info }, { auth: true }),

  LoadToVerification: (id, info, boolean) => axios.patch(`/loads/${id}/setVerification?verification=${boolean}`, info, { auth: true }),


    SetToClaim: (id, boolean) => axios.patch(`/loads/${id}/setClaim`,  null, {auth: true, params:{onClaim: boolean}}),

    SetToBound: (id, boolean) => axios.patch(`/loads/${id}/setBond`,  null, {auth: true, params:{bond: boolean}}),

  LoadToBilling: (id) => axios.post(`/loads/${id}/billing/send`, null, { auth: true }),

  SubmitBillingLoad: (id) => axios.post(`/loads/${id}/billing/submit`, null, { auth: true }),

  CloseBillingLoad: (id) => axios.post(`/loads/${id}/billing/closeBill`, null, { auth: true }),

  SendBillingCorrection: (id, correction) => axios.patch(`/loads/${id}/billing/corrections`, { 'correction': correction }, { auth: true }),

  //

  // Consignment And Credit

  requestConsignment: (id) => axios.patch(`/loads/${id}/consignment/request`, null, { auth: true }),


  rejectConsignment: (id) => axios.delete(`/loads/${id}/remove`, { auth: true }),

  approveConsignment: (id) => axios.patch(`/loads/${id}/consignment/approve`, null, { auth: true }),

  disapproveConsignment: (id, text) => axios.patch(`/loads/${id}/consignment/reject`, { 'note': text }, { auth: true }),

  approveCredit: (id) => axios.patch(`/loads/${id}/credit/approve`, null, { auth: true }),

  disapproveCredit: (id, text) => axios.patch(`/loads/${id}/credit/reject`, { note: text }, { auth: true }),
  //

  //Collect Debt

  CollectDebt: (id, info) => axios.patch(`/loads/${id}/collectDebt`, { ...info }, { auth: true }),

  GetLoadById: (id) => axios.get(`/loads/${id}`, { auth: true }),

  PayableCorrections: (id, date) => axios.patch(`/loads/${id}/payables/correction`, date, { auth: true }),

  DoNotPay: (id, boolean) => axios.patch(`/loads/${id}/doNotPay?pay=${boolean}`, null, { auth: true }),

  AllowFuel: (id, boolean) => axios.patch(`/loads/${id}/requestFuel`, null, { auth: true, params: { requestFuel: boolean }, }),

  isConnLoads: (id, boolean) => axios.patch(`/loads/${id}/setConnLoads`, null, { auth: true, params: { connLoads: boolean }, }),

  CheckCredit: (id, amount) => axios.get(`/customers/${id}/credit/${amount}`, { auth: true }),

};