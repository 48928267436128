import {
  createFactoring,
  getFactorings,
  getFactoringById,
  editFactoring,
  getCarrierFactoring, activateFactoring, inactivateFactoring,
} from "./factoring.action";

export { factoringReducer } from './factoring.reducer';
export { watchFactoring } from './factoring.saga';

export const factoringActions = {

  /** Create, Edit Factoring */
  createFactoring,
  editFactoring,
  /** End */

  /** Get Factorings */
  getFactorings,
  getFactoringById,
  getCarrierFactoring,
  /** End */

  /** Activate or Inactivate Factoring */
  activateFactoring,
  inactivateFactoring,
  /** End */

}
