import axios from "axios";

export const authService = {
  /** Create, Edit Factoring */

  createFactoringService: ( body ) => axios.post('/factoring', body, {auth:true}),

  editFactoringService: ( id, body ) => axios.patch(`/factoring/${id}`, body, {auth:true}),

  /** End */

  /** Get Factorings */

  getFactoringsService: ( info ) => axios.get('/factoring', {auth:true, params: { ...info } } ),

  getFactoringByIdService: ( id ) => axios.get(`/factoring/${id}`, {auth:true}),

  getFactoringCarrierService: ( id, info ) => axios.get(`/carriers/factoring/${id}`, {auth:true, params: { ...info }}),

  /** End */

  /** Activate or Inactivate Factoring */

  activateFactoringService: ( id ) => axios.patch(`/factoring/factoring/${id}/activate`,null,{ auth:true }),

  inactivateFactoringService: ( id ) => axios.patch(`/factoring/factoring/${id}/inactivate`, null,{ auth:true }),

  /** End */

};
