export function paginate(arr, size) {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);
    return acc;
  }, []);
}

export const SavePage = (history, info, anotherInfo) => {
  history.push({
    state: {
      limit: 100,
      skip: info?.skip ? info?.skip : 0,
      page: info?.page ? info?.page : 1,
      ...anotherInfo
    }
  });
}

export const SaveParams = (history, anotherInfo) => {
  history.push({
    state: {
      ...anotherInfo
    }
  });
}

export const SendPageSave = (number, info, history ) =>{
  const _skip = getSkipCount(number, 100);
  const pushInfo = {...info}
  pushInfo.page = number
  pushInfo.skip = _skip
  SavePage(history, info, { ...pushInfo})
}

export const getSkipCount = (pageNumber = 0, limitNumber = 0) => {
  return pageNumber <= 1 ? 0 : (pageNumber - 1) * limitNumber;
};
