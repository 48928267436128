import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { customerActions, customerPaymentActions, mcActions } from 'store';
import { History, Loader, NoteDrawer, Notes, SimpleTabs } from 'components';
import { FindLoad } from 'utils';
import { CustomerPaymentInfoFragment } from 'fragments';

export const CustomerPaymentInfo = () => {
  const { customerPaymentById, customerPaymentLoad } = useSelector((state) => ({
      customerPaymentById: state.customerPayments.customerPaymentById,
      customerPaymentLoad: state.customerPayments.customerPaymentLoad,
    }),
  );
  const params = useParams();
  const dispatch = useDispatch();
  const loader = FindLoad('GET_CUSTOMER_PAYMENT_BY_ID');

  useEffect(() => {
    dispatch(customerActions.getCustomers({ status: 'ACTIVE' }));
    dispatch(mcActions.getMcs({ status: 'ACTIVE' }));
    dispatch(customerPaymentActions.getCustomerPaymentById(params.id));
    dispatch(customerPaymentActions.getCustomerPaymentLoad(params.id));
  }, []);

  const tabsLabels = [
    {
      label: 'Payment Details',
    },
    {
      label: 'History',
    },
    // {
    //   label: 'Notes',
    // },
  ];

  const tabsContent = [
    {
      tabComponent: <CustomerPaymentInfoFragment info={customerPaymentById}
                                                 customerPaymentLoad={customerPaymentLoad} />,
      tab: 'Payment Details',
    },
    {
      tabComponent: <History onModel={'customerPmt'} resourceId={params.id} />,
      tab: 'History',
    },
    // {
    //   tabComponent: <Notes />,
    //   tab: 'Notes',
    // },
  ];

  if (loader.length) return <Loader />;

  return (
    <>
      <SimpleTabs
        head={<NoteDrawer title={'Customer Payment'} />}
        border={'blue'}
        height={'full'}
        tabsLabels={tabsLabels}
        tabsContent={tabsContent}
        customerPaymentById={customerPaymentById}
        parentLink={'/customerPayments'}
        parent={'Customer Payments'}
        child={'Payment Details'}
        defaultTab={'Payment Details'}
        paramsName={'customerPaymentParams'}

      />
    </>
  );
};