import { call, put, takeLatest } from "redux-saga/effects";
import { adminAuthService } from "./admin.service";
import {
  ACTIVATE_ADMIN,
  ADD_ADMIN_ROLE,
  CREATE_ADMIN,
  EDIT_ADMIN,
  GET_ADMIN_BY_ID,
  GET_ADMIN_BY_ID_SUCCESS,
  GET_ADMIN_INFO_BY_ID,
  GET_ADMIN_INFO_BY_ID_SUCCESS,
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  INACTIVATE_ADMIN,
  REMOVE_ADMIN_ROLE, RESEND_ADMIN_INVITE,
} from "./admin.types";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";

/** Create, Edit Admin */
function* createAdmin({payload, type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type))
  try {
    yield call( adminAuthService.createAdminService, payload.body );
    window.location.replace('/humanResources')
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
  }
}

function* editAdmin({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type))
  try {
    yield call( adminAuthService.editAdmin, payload.id, payload.body );
    yield put({
      type:GET_ADMIN_BY_ID,
      payload:{ adminId:payload.id, load:'noLoad'}
    })
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}

function* resendAdmin({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type))
  try {
    yield call( adminAuthService.resendInviteService, payload.id );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    yield put(httpRequestsOnErrorsActions.appendError(type,err.data.message));
  }
}
/** End */

/** Get Admins */
function* getAdmins({payload, type}) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call( adminAuthService.getAdminsService, payload.params);
    yield put({
      type: GET_ADMINS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getAdminById({payload,type}) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if(payload?.load !== 'noLoad'){
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call( adminAuthService.getAdminByIdService, payload.adminId );
    yield put({
      type: GET_ADMIN_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type))
    yield put(httpRequestsOnErrorsActions.removeError(type));

  }
}

function* getAdmInfoById({payload,type}) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type))
  try {
    const res = yield call( adminAuthService.getAdminInfoByIdService, payload.adminId );
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put({
      type: GET_ADMIN_INFO_BY_ID_SUCCESS,
      payload: res.data.roles,
    });
  } catch (err) {
    yield put({
      type: GET_ADMIN_INFO_BY_ID_SUCCESS,
      payload: [],
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}
/** End */

/** Activate or Inactivate Admin */
function* activateAdmin(action) {
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  try {
    yield call( adminAuthService.activateAdminService, action.payload );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}
function* inactivateAdmin(action) {
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  try {
    yield call( adminAuthService.inactivateAdminService, action.payload );
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}
/** End */

/** Add and Remove Role */
function* addAdminRole(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    yield call( adminAuthService.addAdminRoleService, action.payload.id, action.payload.roleId );
    const id={adminId:action.payload.id}
    yield put({
      type:GET_ADMIN_INFO_BY_ID,
      payload: id
    })
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}


function* removeAdminRole(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call( adminAuthService.removeAdminRoleService, action.payload.id, action.payload.roleId );
    const id={adminId:action.payload.id}
    yield put({
      type:GET_ADMIN_INFO_BY_ID,
      payload: id
    })
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

export const watchAdmin = function* watchAdminSaga() {
  /** Create, Edit Admin */
  yield takeLatest( CREATE_ADMIN, createAdmin );
  yield takeLatest( EDIT_ADMIN, editAdmin );
  yield takeLatest( RESEND_ADMIN_INVITE, resendAdmin );
  /** End */

  /** Get Admins */
  yield takeLatest( GET_ADMINS, getAdmins );
  yield takeLatest( GET_ADMIN_BY_ID, getAdminById );
  yield takeLatest( GET_ADMIN_INFO_BY_ID, getAdmInfoById );
  /** End */

  /** Activate or Inactivate Admin */
  yield takeLatest( ACTIVATE_ADMIN, activateAdmin );
  yield takeLatest( INACTIVATE_ADMIN, inactivateAdmin );
  /** End */

  /** Add and Remove Role */
  yield takeLatest( ADD_ADMIN_ROLE, addAdminRole );
  yield takeLatest( REMOVE_ADMIN_ROLE, removeAdminRole );
  /** End */

};
