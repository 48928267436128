import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const filtersFragment = makeStyles(() => ({
    filterWrapper: {
        display: 'flex'
    },

    selectType: {
        width: '160px',

        '& .MuiSelect-outlined.MuiSelect-outlined': {
            background: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)'
        },
    },

    dateFiled: {
        marginRight: '16px',
        background: 'white',
        width: '180px',
        height: '36px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${Colors.ThemeBorder}!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px'
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color:  `${Colors.ThemeBlue}!important`
        },
    },

    search: {
        width: '92px',
        height: '36px',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '4px',
        background: Colors.ThemeBlue,
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        marginRight: '23px',
        textTransform: 'capitalize',
        "&:hover": {
            background: Colors.ThemeBorderBlue,
        },
    },

    consign: {
        width: '100px',
        height: '32px',
        background: '#438AFE 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        color:'white',
        fontSize:'14px',
        textTransform:'capitalize',
        border:'none',
        '& :hover':{
            width: '100px',
            height: '32px',
            background: '#3F51B5 0% 0% no-repeat padding-box',
            borderRadius: '18px',
        }
    }
}));
