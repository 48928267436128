import {
    GET_CARRIER_FACTORING_SUCCESS, GET_DIRECTORY_SUCCESS,
    GET_FACTORING_BY_ID_SUCCESS,
    GET_FACTORINGS_SUCCESS,
} from './directory.types';

const initialState = {
    directoryList: [],
};

export const directoryReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Directory */

        case GET_DIRECTORY_SUCCESS:
            return {
                ...state,
                directoryList: action.payload,
            }

        /** End */

        default:
            return state;
    }
};
