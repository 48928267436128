import {
  ADD_SYSTEM_BRACKET, DELETE_SYSTEM_BRACKET, EDIT_SYSTEM_BRACKET,
  GET_SYSTEM_DEFAULT_BRACKET,

} from "./brackets.types";


/** Create Edit, Payroll Brackets */

export const addBracket =(data)=>{
  return{
    type: ADD_SYSTEM_BRACKET,
    payload: {data}
  }
}

export const deleteBracket =(id)=>{
  return{
    type: DELETE_SYSTEM_BRACKET,
    payload: {id}
  }
}

export const editBracket =(id ,data)=>{
  return{
    type: EDIT_SYSTEM_BRACKET,
    payload: {id, data}
  }
}

export const getDefaultBracket =()=>{
  return{
    type: GET_SYSTEM_DEFAULT_BRACKET,
  }
}

/** End */

