import { Images } from 'utils';
import { IconAndTitle, TextRow } from 'components';

export const agentsHead = [
  { name: 'firstName', title: 'Name' },
  { name: 'username', title: 'Username', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  { name: 'email', title: 'Email Address' },
];

export const agentsBody = [
  {
    rowText: (item) => <IconAndTitle icon={Images.agents} title={`${item?.firstName} ${item?.lastName}`}
                                     textWidth={10} />,
  },
  {
    rowText: (item) => <TextRow name={item?.username ? item?.username : `${item?.firstName} ${item?.lastName}`}
                                textWidth={10} />,
  },
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.email} /> },
];

export const ACTION_TYPE = 'GET_AGENTS';