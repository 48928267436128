import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {carrierInfoFragments} from "./styles";
import {DeleteButton, DeleteElement, Line, NoYet, SelectInput, SimpleModal,} from "components";
import {
    FindLoad,
    FindSuccessItem,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {carrierActions, httpRequestsOnSuccessActions} from "store";

export const Access = ({}) => {
    const classes = carrierInfoFragments();
    const globalText = useGlobalText();
    const globalStyles = useGlobalStyles();
    const params = useParams()
    const dispatch = useDispatch()
    const [factoring, setFactoring] = useState('')
    const [assignId, setId] = useState('')
    const [open, setOpen] = useState(false)
    const [removeType, setRemoveType] = useState('')
    const [name, setName] = useState('')
    const loader = FindLoad('ASSIGN_CARRIER_ACCESS')
    const successOffice = FindSuccessItem('UNASSIGN_OFFICE')
    const successAgent = FindSuccessItem('UNASSIGN_AGENT')

    const {officesList, agentList, assignedOffices, assignedAgents} = useSelector((state) => ({
        officesList: state.offices.officesList,
        assignedOffices: state.carriers.assignedOffices,
        agentList: state.agents.agentList,
        assignedAgents: state.carriers.assignedAgents,
    }));

    const handleChangeFromList = (ev, assignType) => {
        setFactoring(ev.target.value)
        const id = params.id
        const entityId = ev.target.value
        dispatch(carrierActions.assignCarrierAccess(id, entityId, assignType))
    }

    const handleOpenClose = (item, type) => {
        setRemoveType(type)
        if (type === 'UNASSIGN_OFFICE') {
            setName(item.name)
            setId(item.id)
        }
        if (type === 'UNASSIGN_AGENT') {
            const firstLast = item && item.firstName ? `${item.firstName} ${item.lastName}` : ''
            setName(firstLast)
            setId(item.id)
        }
        setOpen(!open)
    }

    const handleOpenCloseRemoveModal = (i) => {
        setOpen(!open)
        setName('')
        setId('')
        setRemoveType('')
    }

    const handleRemove = () => {
        const id = params.id
        dispatch(carrierActions.assignCarrierAccess(id, assignId, removeType))
    }

    const handleClose = () => {
        setOpen(!open)
        setName('')
        setId('')
        setRemoveType('')
    }

    useEffect(() => {
        if (successOffice) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('UNASSIGN_OFFICE'))
            handleClose()
        }
        if (successAgent) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('UNASSIGN_AGENT'))
            handleClose()
        }
    }, [successOffice, successAgent])


    const filteredOfficeList = officesList?.offices?.length && officesList.offices.filter(function (array_el) {
        return assignedOffices && assignedOffices.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    const filteredAgentList = agentList?.agents?.length && agentList.agents.filter(function (array_el) {
        return assignedAgents && assignedAgents.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    const checkRole = RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code])

    return (
        <div className={classes.access}>
            <Line height={'500px'}/>
            <div style={{justifyContent:'space-between', marginTop:'24px'}} className={classes.accessWrapper}>
                <div style={{width:'48%'}} className={classes.paymentContent}>
                    <div className={classes.paymentItemWrapper}>
                        <div className={classes.paymentHeader}>
                            <p className={globalText.smallText}>Offices</p>
                        </div>
                        <div className={classes.paymentBodyBig}>
                            <div style={{marginBottom: '16px'}}>
                                <SelectInput
                                    disabled={!checkRole}
                                    type={'name'}
                                    name={"selectOffices"}
                                    label={"Select Offices"}
                                    handleSelect={(ev) => handleChangeFromList(ev, 'ASSIGN_OFFICE')}
                                    value={factoring}
                                    list={filteredOfficeList && filteredOfficeList.length && filteredOfficeList}
                                />
                            </div>

                            {assignedOffices.length ?
                                assignedOffices.map((i, j) => (
                                    <div key={j} className={'authorites'}>
                                        {i.name}
                                        {checkRole &&
                                            <DeleteButton
                                                toolTipTitle={`Remove ${i.name}`}
                                                handleClick={() => handleOpenClose(i, 'UNASSIGN_OFFICE')}
                                            />
                                        }
                                    </div>
                                )) :
                                <NoYet text={'No Offices Yet'}/>
                            }
                        </div>
                    </div>
                </div>

                <div style={{width:'48%'}} className={classes.paymentContent}>
                    <div className={classes.paymentItemWrapper}>
                        <div className={classes.paymentHeader}>
                            <p className={globalText.smallText}>Agents</p>
                        </div>
                        <div className={classes.paymentBodyBig}>
                            <div style={{marginBottom: '16px'}}>
                                <SelectInput
                                    disabled={!checkRole}
                                    type={'agent'}
                                    name={"selectAgents"}
                                    label={"Select Agents"}
                                    handleSelect={(ev) => handleChangeFromList(ev, 'ASSIGN_AGENT')}
                                    value={factoring}
                                    list={filteredAgentList && filteredAgentList.length && filteredAgentList}
                                />
                            </div>
                            {assignedAgents.length ?
                                assignedAgents.map((i, j) => (
                                    <div key={j} className={'authorites'}>
                                        {`${i.firstName} ${i.lastName}`}
                                        {checkRole &&
                                            <DeleteButton
                                                toolTipTitle={"Remove Agent"}
                                                handleClick={() => handleOpenClose(i, 'UNASSIGN_AGENT')}
                                            />
                                        }
                                    </div>
                                )) : <NoYet text={'No Agents Yet'}/>
                            }
                        </div>
                    </div>
                </div>

                <SimpleModal
                    handleOpenClose={handleOpenCloseRemoveModal}
                    openDefault={open}
                    content={
                        <DeleteElement
                            loading={!!loader.length}
                            text={`Remove ${name}?`}
                            className={globalStyles}
                            handleClose={handleOpenCloseRemoveModal}
                            handleDel={handleRemove}
                            info={name}
                        />
                    }
                />
            </div>
        </div>
    )
}