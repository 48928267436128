import {
    CLEAN_AP_DETAILED,
    CLEAN_AP_SUMMARY,
    CLEAN_AR_DETAILED, CLEAN_AR_SUMMARY,
    GET_AP_ACTIVITY_SUCCESS,
    GET_AP_APPLICATION_SUCCESS, GET_AP_DETAILED_SUCCESS, GET_AP_SUMMARY_SUCCESS,
    GET_AR_ACTIVITY_SUCCESS,
    GET_AR_APPLICATION_SUCCESS,
    GET_AR_DETAILED_SUCCESS,
    GET_AR_SUMMARY_SUCCESS,
} from "./reports.types";

const initialState = {
    arDetailed: null,
    arSummary: null,
    arActivity: null,
    arApplication: null,

    apDetailed: null,
    apSummary: null,
    apActivity: null,
    apApplication: null,
};

export const reportsReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Ar Reports */
        case GET_AR_DETAILED_SUCCESS:
            return {
                ...state,
                arDetailed: action.payload
            }

        case CLEAN_AR_DETAILED:
            return {
                ...state,
                arDetailed: null
            }

        case GET_AR_SUMMARY_SUCCESS:
            return {
                ...state,
                arSummary: action.payload
            }

        case CLEAN_AR_SUMMARY:
            return {
                ...state,
                arSummary: null
            }

        case GET_AR_ACTIVITY_SUCCESS:
            return {
                ...state,
                arActivity: action.payload
            }

        case GET_AR_APPLICATION_SUCCESS:
            return {
                ...state,
                arApplication: action.payload
            }
        /** End */

        /** Ap Reports */
        case GET_AP_DETAILED_SUCCESS:
            return {
                ...state,
                apDetailed: action.payload
            }

        case CLEAN_AP_DETAILED:
            return {
                ...state,
                apDetailed: null
            }

        case GET_AP_SUMMARY_SUCCESS:
            return {
                ...state,
                apSummary: action.payload
            }

        case CLEAN_AP_SUMMARY:
            return {
                ...state,
                apSummary: null
            }

        case GET_AP_ACTIVITY_SUCCESS:
            return {
                ...state,
                apActivity: action.payload
            }

        case GET_AP_APPLICATION_SUCCESS:
            return {
                ...state,
                apApplication: action.payload
            }
        /** End */

        default:
            return state;
    }
};
