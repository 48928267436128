import {
    GET_EQUIPMENT_BY_ID_SUCCESS,
    GET_EQUIPMENT_SUCCESS,
} from "./equipment.types";

const initialState = {
    equipmentList: [],
    equipmentById: null,
};

export const equipmentReducer = (state = initialState, action) => {

    switch (action.type) {

        /** Get Equipment */
        case GET_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipmentList: action.payload ,
            }

        case GET_EQUIPMENT_BY_ID_SUCCESS:
            return {
                ...state,
                equipmentById: action.payload
            }
        /** End */

        default:
            return state;
    }
};
