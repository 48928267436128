import {Button} from "@material-ui/core";
import {Colors} from "utils";
import {MinLoader} from "../loader";
import React from "react";

export const CreateChancel = ({
                                  create,
                                  chancel,
                                  onCreate,
                                  onClose,
                                  classes,
                                  loader,
                                  disabled,
                                  width
                              }) => {
    return (
        <div className={classes}>
            <Button
                style={{
                    textTransform: "capitalize",
                    width: width ? width : "238px",
                    height: "48px",
                    background: Colors.ThemeGreen,
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: Colors.TextWhite,
                }}
                onClick={onCreate}
                disabled={loader === true ? true : disabled}
            >
                {loader === true ?
                    <MinLoader margin={'0'} color={Colors.TextWhite}/>
                    :
                    create
                }
            </Button>
            <Button
                style={{
                    textTransform: "capitalize",
                    width: width ? width : "238px",
                    height: "48px",
                    background: Colors.ThemeGray,
                    borderRadius: "8px",
                    marginLeft: "16px",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: Colors.TextPrimary,
                }}
                onClick={onClose}
            >
                {chancel}
            </Button>
        </div>
    );
};
