import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CheckboxesTags, CloseButton, ValidationInput} from "components";
import {FindErrorItem, FindLoad, FindSuccessItem, globalModals, useGlobalTextStyles} from 'utils'
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, roleActions} from "store";
import {managementFragments} from "./style";

export const AddRoleModal = ({handleClose, permissionsList, info}) => {
    const REQUEST_TYPE = info ? 'EDIT_ROLE' : 'CREATE_ROLE'
    const classes = managementFragments();
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const [error, setError] = useState('')
    const [roleName, setRoleName] = useState('')
    const [permissions, setPermissions] = useState('')
    const [description, setDescription] = useState('')
    const dispatch = useDispatch()
    const success = FindSuccessItem(REQUEST_TYPE)
    const loader = FindLoad(REQUEST_TYPE)
    const errorText = FindErrorItem(REQUEST_TYPE)

    useEffect(() => {
        if (info) {
            setRoleName(info?.title)
            setDescription(info?.description)
        }
    }, [])
    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success?.type))
            handleClose()
        }
    }, [success]);

    useEffect(() => {
        return () => errorText && dispatch(httpRequestsOnErrorsActions.removeError(errorText?.type))
    }, [])

    const addRole = () => {
        const permissionsList = []
        for (let i of permissions) {
            permissionsList.push(i.id)
        }
        if (roleName && (info ? true : permissions) && description) {
            const body = {
                "title": roleName,
                "description": description,
                "permissions": permissionsList,
            }
            if (info) {
                dispatch(roleActions.editRole(body, info?.id))
            } else {
                dispatch(roleActions.createRole(body))
            }
        } else {
            !roleName ? setError('role') :
                !permissions ? setError('permissions') :
                    !description ? setError('description') : ''
        }
    }

    const handleChange = (ev) => {
        errorText && dispatch(httpRequestsOnErrorsActions.removeError(errorText?.type))
        setRoleName(ev.target.value)
        if (error === 'role') setError('')
    }

    const changePermissions = (ev) => {
        setPermissions(ev)
        if (error === 'permissions') setError('')
    }

    const changeDescription = (ev) => {
        setDescription(ev.target.value)
        if (error === 'description') setError('')
    }

    return (
        <div className={globalModalsClasses.smallModalWrapper}>
            <div className={globalModalsClasses.smallModalClose}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className={globalModalsClasses.modalWrapperContent}>
                <p className={globalText.modalTitle}>{info ? 'Edit Role' : 'Want to Add Role?'}</p>
                <p className={globalText.modalText}>
                    {info ?
                        ''
                        :
                        'To add new role in the system, please set the name and assign permissions to that role.'
                    }
                </p>
                <ValidationInput
                    onChange={handleChange}
                    typeError={error === 'role' ? true :
                        errorText?.error === 'A role with this title already exists' ? 'A role with this title already exists' : ''}
                    style={classes.input}
                    value={roleName}
                    variant={"outlined"}
                    name={"outlined"}
                    label={"Set Role Name*"}
                    type={"text"}
                />
                {!info &&
                    <CheckboxesTags
                        typeError={error === 'permissions'}
                        handleChange={changePermissions}
                        permissionsList={permissionsList}
                        label={"Select Permissions*"}
                        placeholder={'Permissions'}
                        name={'permissions'}
                        noChip={true}
                    />
                }
                <ValidationInput
                    onChange={changeDescription}
                    typeError={error === 'description'}
                    className={classes.inputDescription}
                    variant={"outlined"}
                    name={"description"}
                    Length={100}
                    value={description}
                    label={"Role Description"}
                    type={"text"}
                    multiline={true}
                />
                <p className={classes.maxCharacter}>{`Max 100/${description.length ? description.length : 0} characters`}</p>
                <AddModalButton
                    loading={!!loader?.length}
                    styles={{marginTop: '16px'}}
                    handleClick={addRole}
                    text={info ? 'Save' : 'Add'}
                />
            </div>
        </div>
    )
}
