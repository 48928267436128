import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Rating} from "@material-ui/lab";
import {FindLoad, FindSuccess, Images, PermissionsList, RolePermission, userType} from "utils";
import { CreateChancel, DateRow, DeleteElement, EditSaveButtons, Loader, NoYet, SimpleModal } from 'components';
import {carrierActions, httpRequestsOnSuccessActions} from "store";

export const ReviewModal = ({flexDirection, carrierId}) => {
    const ACTION_TYPE = 'ADD_REVIEW'
    const DELETE_REVIEW = 'DELETE_REVIEW'
    const {reviewsList} = useSelector((state) => ({
        reviewsList: state.carriers.reviewsList,
    }));
    const dispatch = useDispatch()
    const [openWrite, setOpenWrite] = useState(false)
    const [rate, setRate] = useState(1)
    const [comment, setComment] = useState('')
    const [show, setShow] = useState(false)
    const [error, setError] = useState('')
    const [editItem, setEditItem] = useState('')
    const getReviews = FindLoad("GET_REVIEWS")
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const deleteSuccess = FindSuccess(DELETE_REVIEW)
    const loadDelete = FindLoad(DELETE_REVIEW)
    const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
    const [openDelete, setOpenDelete] = useState(false)

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            setOpenWrite(false)
            setRate(1)
            setComment('')
        }
        if (deleteSuccess?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_REVIEW))
            closeDelete()
        }
    }, [success, deleteSuccess])

    useEffect(() => {
        if (carrierId) {
            dispatch(carrierActions.getReviews(carrierId))
        }
    }, [dispatch, carrierId])

    const closeDelete = ( ) => {
        setOpenDelete(null)
    }

    const handleSetComment = (e) => {
        setComment(e.target.value)
        error === 'comment' && setError('')
    }

    const handleClose = () => {
        setOpenWrite(false)
        setRate(1)
        setComment('')
    }

    const renderWidth = (count) => {
        return count ? `${count}%` : 0
    }

    const handleEditSave = () => {
        if (editItem?.star && editItem?.comment) {
            dispatch(carrierActions.editReview(carrierId, {
                "id": editItem?.id,
                "star": editItem?.star,
                "comment": editItem?.comment,
            }))
        }
    }

    const handleCreate = () => {
        if (rate && comment) {
            const params = {
                "star": rate,
                "comment": comment
            }
            dispatch(carrierActions.addReview(carrierId, params))
        } else {
            setError(
                !rate ? 'rate' :
                    !comment ? 'comment' : ''
            )
        }
    }

    const handleChange = (e) => {
        setEditItem(prevState => ({...prevState, [e.target.name]: e.target.value,}),)
    }

    const checkStarsArray = Array.isArray(reviewsList?.stars)
    const firstStar = checkStarsArray ? reviewsList?.stars[0] : 0
    const secondStar = checkStarsArray ? reviewsList?.stars[1] : 0
    const thirdStar = checkStarsArray ? reviewsList?.stars[2] : 0
    const fourthStar = checkStarsArray ? reviewsList?.stars[3] : 0
    const fifthStar = checkStarsArray ? reviewsList?.stars[4] : 0
    const starCount = firstStar + secondStar + thirdStar + fourthStar + fifthStar

    const calculateReviews = () => {
        const count =
            (firstStar * 1) +
            (secondStar * 2) +
            (thirdStar * 3) +
            (fourthStar * 4) +
            (fifthStar * 5)

        return count === 0 ? 0 : count / starCount
    }

    if (getReviews?.length) return <Loader/>

    return (
        <div>
            <div style={{display: 'flex', flexDirection: flexDirection ? flexDirection : 'column'}}>
                <div className='reviews-wrapper'>
                    <div className='stars-and-estimate'>
                        <p>{reviewsList?.items ?
                            calculateReviews() === 0 ? 0 :
                                isNaN(calculateReviews()) ? 0 :
                                    calculateReviews().toFixed(1) : 0}
                        </p>
                        <Rating
                            readOnly
                            name="simple-controlled"
                            value={reviewsList?.items?.length ? calculateReviews() : 0}
                        />
                    </div>
                    <div className='full-width'>
                        <div className='reviews-and-ratings'>
                            <p>{starCount ? starCount : 0} reviews</p>
                        </div>
                        <div className='full-width'>
                            <div className='star-count'>
                                <p>5 star</p>
                                <div className='star-line'>
                                    <div style={{width: renderWidth(fifthStar)}}/>
                                </div>
                                <p>{fifthStar}</p>
                            </div>
                            <div className='star-count'>
                                <p>4 star</p>
                                <div className='star-line'>
                                    <div style={{width: renderWidth(fourthStar)}}/>
                                </div>
                                <p>{fourthStar}</p>

                            </div>
                            <div className='star-count'>
                                <p>3 star</p>
                                <div className='star-line'>
                                    <div style={{width: renderWidth(thirdStar)}}/>
                                </div>
                                <p>{thirdStar}</p>
                            </div>
                            <div className='star-count'>
                                <p>2 star</p>
                                <div className='star-line'>
                                    <div style={{width: renderWidth(secondStar)}}/>
                                </div>
                                <p>{secondStar}</p>
                            </div>
                            <div className='star-count'>
                                <p>1 star</p>
                                <div className='star-line'>
                                    <div style={{width: renderWidth(firstStar)}}/>
                                </div>
                                <p>{firstStar}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {userType !== 'ADMIN' &&
                        <div className='write-review'>
                            <button className='write-review-button' onClick={() => setOpenWrite(true)}>
                                <img src={Images.pencil} alt="icon"/>
                                <p>Write Review</p>
                            </button>
                        </div>
                    }
                    {openWrite &&
                        <div className='overall-rating-wrapper'>
                            <p className='gray-text'>Your overall Rating*</p>
                            <div className='stars-wrapper'>
                                <Rating
                                    name="simple-controlled"
                                    value={rate}
                                    onChange={(event, newValue) => {
                                        setRate(newValue);
                                    }}
                                    size="large"
                                />
                            </div>
                            <p className='gray-text-small'>Your overall Rating*</p>
                            <div className='comment-and-actions'>
                                 <textarea
                                     style={error === 'comment' ? {borderColor: '#F07379'} : {}}
                                     className='comment-text-area'
                                     onChange={handleSetComment}
                                     value={comment}
                                 />
                                <div className='flex-end create-button'>
                                    <CreateChancel
                                        loader={!!loader.length}
                                        create={"Send"}
                                        chancel={"Cancel"}
                                        onCreate={handleCreate}
                                        onClose={handleClose}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='flex-end'>
                <button className='show-reviews' onClick={() => setShow(!show)}>
                    {show ? 'Hide Reviews' : 'Show Reviews'}
                </button>
            </div>
            {show &&
                <div className='reviews-body-wrapper'>
                    {reviewsList?.items?.length ? reviewsList?.items?.map((i, j) => (
                            <div className='review-card-wrapper' key={j}>
                                <div className='title-and-stars'>
                                    <p className='review-card-title'>{i?.reviewerName ? i?.reviewerName : 'Not Set'}</p>
                                    <div className='align-center'>
                                        <Rating
                                            readOnly
                                            name="simple-controlled"
                                            value={i?.star}
                                        />

                                        <div>
                                            {userType === 'ADMIN' &&
                                                RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                                                <button className='delete-btn'

                                                        onClick={() => setOpenDelete(i?.id)}

                                                        // onClick={() => open(
                                                                // actionType={DELETE_REVIEW}
                                                                // text="Delete Review?"
                                                                // info={'Review'}
                                                                // handleDel={() => dispatch(carrierActions.deleteReview(carrierId, i?.id))}
                                                        // )}
                                                >
                                                    <img src={Images.removeRed} alt='icon'/>
                                                </button>
                                            }

                                            {i?.reviewerId === userInfo?.id && userType === 'AGENT' &&

                                                <div style={{marginLeft: '16px'}}>
                                                    <EditSaveButtons
                                                        icon={'edit'}
                                                        type={'EDIT_REVIEW'}
                                                        handleChancel={() => setEditItem('')}
                                                        handleSetEdit={() => setEditItem(i)}
                                                        handleSaveInfo={handleEditSave}
                                                        afterSuccess={() => setEditItem('')}
                                                    />
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                                {editItem?.id === i?.id ?

                                    <textarea
                                        onChange={handleChange}
                                        className='edit-review-area'
                                        value={editItem?.comment}
                                        name={'comment'}
                                    />
                                    :
                                    <p className='description'>
                                        {i?.comment}
                                    </p>
                                }
                                <div className='date-row flex-end'>
                                    <DateRow date={i?.date}/>
                                </div>
                            </div>
                        ))
                        :
                        <NoYet position={'center'} text={'No Reviews Yet'}/>
                    }
                </div>
            }


            <SimpleModal
              handleOpenClose={closeDelete}
              openDefault={!!openDelete}
              content={
                  <DeleteElement
                    text="Delete Review?"
                    info={'Review'}
                    loading={!!loadDelete?.length}
                    handleClose={closeDelete}
                    handleDel={() => dispatch(carrierActions.deleteReview(carrierId, openDelete))}
                  />
              }
            />

        </div>
    )
}