import {Button} from "@material-ui/core"
import {buttonsStyle} from "./styles"
import {Backgrounds, Images} from "../../utils";
import React from "react";

export const EditButton = ({handleClick, text, icon}) => {
    const classes = buttonsStyle();
    return (
        <>
            {icon === 'edit' ?
                <button
                    onClick={handleClick}
                    className={classes.editButtonIcon}>
                    <img src={Images.edit} alt='edi'/>
                </button>
                :
                <Button
                    className={classes.editButtonStyle}
                    onClick={handleClick}
                    style={{
                        background: Backgrounds.theme,
                    }}
                >
                    {text ? text : 'Edit'}
                </Button>
            }
        </>

    )
}