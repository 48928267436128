import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const payableFragmentStyles = makeStyles(() => ({
    blueButton: {
        width: '145px',
        height: '32px',
        border: '1px solid #438AFE',
        borderRadius: '18px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #438AFE4D',
        color: Colors.ThemeBlue,
        fontSize: '12px',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        "@media (min-width: 1919px)": {
            width: '185px',
            fontSize: '14px',
        },
    },


    correctionList: {
        display: "flex",
        width: '100%',
        flexDirection: 'column',
        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: Colors.ThemeBlue,
        },
        '& div': {
            background: '#E6ECF380 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            padding: '9px 11px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            '& span': {
                fontSize: '18px',
                lineHeight: '24px',
                color: Colors.TextSecondary,
            },
        },
    },

    referenceNumber: {
        // background: '#FFFFFF 0% 0% no-repeat padding-box',
        // border: '1px solid #51566DB3',
        // borderRadius: '4px',
        // color: '#51566D80',
        // fontSize: '16px',
        // padding: '0 16px',
        // height: '48px',
        // width: '100%',
        margin:'24px 0 8px 0'
    },

    tableHead: {
        display: 'flex',
        fontSize: '16px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
        padding: '13px 0 13px 32px',
        marginTop: '24px 0 16px 0',
    },
    payablesText: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: Colors.TextPrimary,
        margin:'16px 0',
    },
    tableBodyWrapper: {
        display: 'flex',
    },
    tableBody: {
        display: 'flex',
        marginBottom: '8px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '0px 4px 4px 0px;',
        padding: '13px 16px',
        marginRight: '8px',
        justifyContent: 'space-between',
        '& p': {
            // width: '180px',
            fontSize: '16px',
            fontWeight: '600',
            color: Colors.TextPrimary,
            lineHeight: '22px'
        },
    },
    doneButtonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    finalAmount:{
        '& .text-style':{
            margin: '0',
            padding: 0,
            background: 'none',
        }
    }
}));
