import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CarriersInfo, LastLoads } from 'fragments';
import {
  agentActions,
  branchActions,
  carrierActions,
  factoringActions,
  httpRequestsOnSuccessActions,
  officeActions,
} from 'store';
import {
  ActiveInactive,
  CreateWrapperHead,
  History,
  Loader, NoteDrawer, Notes,
  SimpleModal,
  SimpleTabs,
  SlicedText,
} from 'components';
import {
  AntSwitch,
  FindLoad,
  FindSuccessItem,
  Images, PermissionsList,
  RenderStatus, RolePermission,
  useGlobalStyles,
  userType,
} from 'utils';
import { carrierInfoFragments, CarrierLoadsTable, CollectionsTable } from '../../fragments/carriers/carrierInfo/core';
import { Payments } from '../../fragments/carriers/carrierInfo/core/payments';
import { Equipments } from './fragments/equipments';
import { Other } from './fragments/other/other';

export const CarrierInfoPage = () => {
  const { carrierById } = useSelector((state) => ({
      carrierById: state.carriers.carrierById,
    }),
  );
  const dispatch = useDispatch();
  const params = useParams();
  const globalStyle = useGlobalStyles();
  const classes = carrierInfoFragments();
  const [switchBoolean, setSwitchBoolean] = useState(false);
  const [carrierName, setCarrierName] = useState('');
  const [open, setOpen] = useState(false);
  const loader = FindLoad('GET_CARRIER_BY_ID');
  const name = carrierName ? carrierName : carrierById && carrierById.companyName;
  const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate';
  const activeInactiveSuccess = FindSuccessItem('INACTIVATE_ACTIVATE_CARRIER');
  const activeInactiveLoader = FindLoad('INACTIVATE_ACTIVATE_CARRIER');

  useEffect(() => {
    if (carrierById) {
      setSwitchBoolean(carrierById?.status === 'ACTIVE');
    }
  }, [carrierById]);

  useEffect(() => {
    dispatch(carrierActions.getCarrierById(params.id));
    dispatch(agentActions.getAgents({ status: 'ACTIVE' }));
    dispatch(officeActions.getOffices({ status: 'ACTIVE' }));
    dispatch(branchActions.getBranches({ status: 'ACTIVE' }));
    dispatch(carrierActions.getCarrierOfficeAccess(params.id, 'ASSIGNED_OFFICES'));
    dispatch(carrierActions.getCarrierAgentAccess(params.id, 'ASSIGNED_AGENTS'));
    dispatch(carrierActions.getCarrierBranchAccess(params.id, 'BLACKLISTED_BRANHCES'));
    dispatch(carrierActions.getPaymentsCarrierById(params.id));
    dispatch(factoringActions.getFactorings({ status: 'ACTIVE' }));
  }, [params.id]);

  useEffect(() => {
    if (activeInactiveSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_ACTIVATE_CARRIER'));
      const status = carrierById.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
      setSwitchBoolean(status);
      setOpen(false);
    }
  }, [activeInactiveSuccess]);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleSwitch = () => {
    const status = carrierById.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    dispatch(carrierActions.inactivateActivateCarrier(carrierById.id, status));
  };

  const tabsLabels = [
    {
      label: 'Details',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code, PermissionsList.READ_CARRIER?.code]) &&
    {
      label: 'Equipment',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_LOADS?.code]) &&
    {
      label: 'Loads',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_LOADS?.code], 'agentView') &&
    {
      label: 'Last Loads',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_COLLECTION?.code]) &&
    {
      label: 'Collections',
    },
    userType === 'ADMIN' &&
    {
      label: 'Payments',
    },
    {
      label: 'History',
    },
    // {
    //   label: 'Notes',
    // },
    {
      label: 'Other',
    },
  ];

  const tabsContent = [
    {
      tabComponent: <CarriersInfo info={carrierById} setCarrierName={setCarrierName} />,
      tab: 'Details',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code, PermissionsList.READ_CARRIER?.code]) &&
    {
      tabComponent: <Equipments />,
      tab: 'Equipment',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_LOADS?.code]) &&
    {
      tabComponent: <CarrierLoadsTable />,
      tab: 'Loads',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_LOADS?.code], 'agentView') &&
    {
      tabComponent: <LastLoads />,
      tab: 'Last Loads',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_COLLECTION?.code]) &&
    {
      tabComponent: <CollectionsTable info={carrierById} />,
      tab: 'Collections',
    },
    userType === 'ADMIN' &&
    {
      tabComponent: <Payments info={carrierById} />,
      tab: 'Payments',
    },
    {
      tabComponent: <History onModel={'carrier'} resourceId={params.id} />,
      tab: 'History',
    },
    // {
    //   tabComponent: <Notes />,
    //   tab: 'Notes',
    // },
    {
      tabComponent: <Other />,
      tab: 'Other',
    },
  ];

  if (loader.length) return <Loader />;

  return (
    <>
      <SimpleTabs
        parentLink={'/Carriers'}
        parent={'Carriers'}
        child={'Carrier Info'}
        headerWidth={'470px'}
        head={
          <CreateWrapperHead>
            <div className="space-between" style={{ gap: 15 }}>
              <div className={classes.rateSwitch}>
                {RenderStatus(activeInactive)}
                {RolePermission([PermissionsList.CARRIER_STATUS?.code]) &&
                  <AntSwitch
                    onClick={handleOpenClose}
                    checked={switchBoolean}
                  />
                }
              </div>
              <NoteDrawer
                title={'Carrier'}
              />
              <div className={globalStyle.centerItem}>
                <img src={Images.carrierBold} alt={'authorityBlueFill'} />
                <SlicedText type={'tableName'} size={20} data={name && name} />
              </div>
            </div>
          </CreateWrapperHead>
        }
        border={'blue'}
        height={'full'}
        tabsLabels={tabsLabels}
        tabsContent={tabsContent}
        defaultTab={'Details'}
        paramsName={'carrierParams'}
      />
      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={open}
        content={
          <ActiveInactive
            loading={!!activeInactiveLoader?.length}
            text={switchBoolean === 'ACTIVE' ? `Inactivate ${name}` : `Activate ${name}`}
            textInfo={switchBoolean === 'ACTIVE' ? 'Inactivate' : 'Activate'}
            name={name}
            handleClose={handleOpenClose}
            handleDel={handleSwitch}
          />
        }
      />
    </>
  );
};