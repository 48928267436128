import {
  createOffice,
  getOfficeById,
  getOffices,
  editOffice,
  activateOffice,
  inactivateOffice,
  createOfficeManager,
  selectOfficeManager,
  removeOfficeManager,
  getOfficeBranches,
  getOfficesForAgents,
  removeOfficeBranches, addOfficeExpense, subtractOfficeExpense,
} from "./offices.action";

export { officeReducer } from './offices.reducer';
export { watchOffice } from './offices.saga';

export const officeActions = {
  /** Create, Edit Office */
  createOffice,
  editOffice,
  /** End */

  /** Get Offices */
  getOffices,
  getOfficesForAgents,
  getOfficeById,
  /** End */

  /** Activate or Inactivate Office */
  activateOffice,
  inactivateOffice,
  /** End */

  /** Office Management */
  createOfficeManager,
  selectOfficeManager,
  removeOfficeManager,
  /** End */

  /**Get Office Branches */
  getOfficeBranches,
  removeOfficeBranches,
  /** End */

  /** Office Expenses */
  addOfficeExpense,
  subtractOfficeExpense
  /** End */

}

