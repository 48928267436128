import { combineReducers } from 'redux';
import {httpRequestsOnLoadReducer} from "../http_requests_on_load";
import {httpRequestsOnSuccessReducer} from "../http_requests_on_success";
import {httpRequestsOnErrorsReducer} from "../http_requests_on_errors";
import { authReducer } from "../auth";
import {adminReducer} from "../admin";
import {officeReducer} from "../offices";
import {permissionsReducer} from "../permissions";
import {roleReducer} from "../role";
import {branchReducer} from "../branches";
import {agentReducer} from "../agents";
import {customerReducer} from "../customers";
import {factoringReducer} from "../factoring";
import {carrierReducer} from "../carriers";
import {mcReducer} from "../mc";
import {equipmentReducer} from "../equipment";
import {uploadReducer} from "../upload";
import {loadReducer} from "../loads";
import {bracketReducer} from "../brackets";
import {notificationsReducer} from "../notifications";
import {customerPaymentReducer} from "../customerPayment";
import {historyReducer} from "../history";
import {termsReducer} from "../system";
import {payrollReducer} from "../payroll";
import {noteReducer} from "../notes";
import {reportsReducer} from "../reports";
import {rmisReducer} from "../rmis";
import {templatesReducer} from "../templates";
import { directoryReducer } from '../directory';

export const appReducer = combineReducers({
    auth: authReducer,
    admins: adminReducer,
    offices: officeReducer,
    permissions: permissionsReducer,
    roles: roleReducer,
    branches: branchReducer,
    agents: agentReducer,
    customers: customerReducer,
    factorings:factoringReducer,
    mcs:mcReducer,
    carriers:carrierReducer,
    equipment:equipmentReducer,
    upload:uploadReducer,
    loads:loadReducer,
    brackets:bracketReducer,
    notifications: notificationsReducer,
    customerPayments: customerPaymentReducer,
    history: historyReducer,
    terms: termsReducer,
    payroll: payrollReducer,
    note: noteReducer,
    reports: reportsReducer,
    rmis: rmisReducer,
    templates:templatesReducer,
    directory: directoryReducer,

    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});
