import React, {useEffect, useState} from "react";
import {AddModalButton, CloseButton, MinLoader, NoYet, SlicedText} from "components";
import {globalModals, useGlobalTextStyles} from "utils";
import {paymentVerificationFragments} from "./styles";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {useDispatch} from "react-redux";
import {VERIFICATION} from "../../../../pages/accounting/constants";

export const CollectDebt = ({handleClose, data}) => {
    const dispatch = useDispatch()
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const classes = paymentVerificationFragments()
    const [inputs, setInputs] = useState({});
    const [modalDate, setModalDate] = useState( '' )
    const [loader, setLoader] = useState(false)
    const [selectedId, setSelectedId] = useState('')

    useEffect(() => {
        if (data) {
            setModalDate(data)
        }
    }, [data])

    const handleChange = (e, price) => {
        const positive = Math.abs(price)
        if (Number(e.target.value) <= Number(data?.payable?.finalAmount)) {
            if (Number(e.target.value) <= Number(positive)) {
                setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
            }
        }
    }

    const handleCollect = async (id) => {
        if(+inputs[id]) {
            setSelectedId(id)
            setLoader(true)
            try {
                const info = {
                    "debtId": id,
                    "amount": +inputs[id]
                }
                await LoadServices.CollectDebt(data.id, info)
                let res = await LoadServices.GetLoadById(data.id)
                dispatch(loadActions.getLoadById(data.id, 'noLoad'))
                dispatch(httpRequestsOnSuccessActions.appendSuccess('DEBT_COLLECTED'))
                const params = {
                    department: VERIFICATION
                }
                dispatch(loadActions.getLoadsByStatus(params, 'noLoad'))
                setModalDate(res.data)
                setInputs({})
                setLoader(false)
            } catch (e) {
                setLoader(false)
            }
        }
    }

    let finalAmount = data?.payable?.finalAmount ? data.payable.finalAmount - data?.payable?.debtCollected : 0

    return (
        <div>
            <div className={globalModalsClasses.bigModalWrapper}>
                <div className={globalModalsClasses.smallModalClose}>
                    <CloseButton handleCLic={handleClose}/>
                </div>
                <div className={globalModalsClasses.modalWrapperContent}>
                    <p className={globalText.modalTitle}>Collect Debt</p>
                    <p className={globalText.modalText}>To collect debt please, fill out the below fields.</p>
                    <div className={classes.correctionList}>
                        <p>Carrier Collection List</p>
                        <div>
                            <spann>Available Amount:</spann>
                            <span style={{fontWeight: 'bold'}}> $ {modalDate?.payable?.finalAmount ? modalDate.payable.finalAmount - modalDate?.payable?.debtCollected : 0}</span>
                        </div>
                    </div>
                    <div>
                        <div className={classes.tableHead}>
                            <p>Load ID</p>
                            <p>Owed Amount</p>
                            <p>Amount to Collect</p>
                            <span>Action</span>
                        </div>
                        <div className={classes.tableBodyWrapper}>
                            {modalDate?.carrier?.debt?.length ?
                                modalDate?.carrier?.debt.map((i, j) => (
                                    i.amount - i.collected  > 0  &&
                                    <div key={j} className={classes.tableBody}>
                                        <div className={classes.row}>
                                            <SlicedText
                                                type={'id'}
                                                size={10}
                                                data={i?.loadDisplayId}
                                            />
                                        </div>
                                        <div className={classes.row}>
                                            <p>$ {i.amount - i.collected} </p>
                                        </div>
                                        <input
                                            disabled={finalAmount < 0}
                                            style={inputs[i._id] ? {border: '1px solid #438AFE'} : {} }
                                            value={inputs[i._id] ? inputs[i._id] : ''}
                                            type={'number'}
                                            name={i._id}
                                            onChange={(ev) => handleChange(ev, i.amount)}
                                            placeholder={'Enter Amount'}
                                        />
                                        <button
                                            className={classes.collectButton}
                                            disabled={finalAmount < 0}
                                            onClick={() => handleCollect(i._id, i.loadId)}
                                        >
                                            {loader === true && selectedId === i._id ?
                                            <MinLoader margin={'5px 0 0 0'} color={'white'} position={'relative'}/>
                                                :
                                                'Collect'
                                            }
                                        </button>
                                    </div>
                                ))
                                :
                                <NoYet position={'center'} text={'No Carrier Collection yet'}/>
                            }
                        </div>
                    </div>
                    <div className={classes.doneButtonWrapper}>
                        <AddModalButton
                            loader={false}
                            styles={{marginTop: '16px', width: '334px'}}
                            handleClick={handleClose}
                            text={'Done'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}