import {wrapperStyle} from "./styles";


export const Management =({head,body,type,height, styles})=>{
    const classes = wrapperStyle()
    return(
        <div style={ height ? { height:height } : { ...styles } } className={
            type === 'small' ? classes.smallManagementWrapper  :
                type === 'big' ? classes.bigManagementWrapper :
                  type === 'doc' ? classes.docManagementWrapper :
                  type === 'access' ? classes.accessManagementWrapper :
                    classes.managementWrapper}
        >
            {type !== 'doc' &&  <div>{head}</div>}
            <div>{body}</div>
        </div>
    )
}