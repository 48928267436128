import React from 'react';
import { billingDetailsStyle } from './styles';
import { Images, useGlobalTextStyles } from 'utils';
import { Download, PriceRow, TextRow } from 'components';

export const Items = ({ title, text, type, styles, price, show, url, file }) => {
  const classes = billingDetailsStyle();
  const globalText = useGlobalTextStyles();

  return (
    <div style={type === 'download' ? { ...styles, justifyContent: 'space-between' } : { ...styles }}
         className={classes.itemsWrapper}>
      <p className={globalText.itemTitle}>{title}</p>
      {type === 'download' ?
        <div className={'align-center'}>
          {file?.file
            ?
            <>
              {show &&
                <a style={{ marginRight: '12px', height: '16px', cursor: 'pointer' }} href={url} target="_blank"><img
                  src={Images.show} alt={'icon'} /></a>
              }
              <Download
                fullFile={file}
              />
            </>
            :
            <TextRow name={'N/A'} />
          }
        </div>
        :
        <p style={{ width: '100%' }} className={globalText.itemText}>
          {price ?
            <PriceRow info={text} />
            :
            <TextRow name={title ? text ? text : 'N/A' : ''} />
          }
        </p>
      }
    </div>
  );
};