import axios from "axios";

export const authService = {
  /** Directory */

  getDirectoryService: ( params ) => axios.get('/directory', {auth:true, params: { ...params }}),

  createDirectoryService: ( body ) => axios.post('/directory', body, {auth:true}),

  editDirectoryService: ( id, body ) => axios.patch(`/directory/${id}`, body, {auth:true}),

  deleteDirectoryService: ( id ) => axios.delete(`/directory/${id}`, {auth:true}),

  /** End */

};
